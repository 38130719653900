import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import classes from './LoadingWidget.module.scss';

// interface Props {
// 	state: object;
// }

const LoadingWidget = (props) => {
	// const { state = { name: 'confirmada', id: 1 } } = props;

	return (
		<div className={classes.CircularProgressContainer}>
			<CircularProgress />
		</div>
	);
};

export default LoadingWidget;
