import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Button, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoTopbar from '../../../../assets/logo-white.png';
import Bgtoplogo from '../../../../assets/backlogo-top.png';
import classesModule from '../../Minimal.module.scss';
// import classesTopBar from './Topbar.module.scss';
import { IsDesktopHandler } from '../../../../helpers';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer - 11,
    // paddingLeft: theme.spacing(8) + 1,
    // [theme.breakpoints.up('sm')]: {
    // 	paddingLeft: theme.spacing(8) + 1,
    // },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  rightBar: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProfile: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.2,
    alignItems: 'flex-start',
    '&& div': {
      fontSize: 14,
      fontWeight: 700,
    },
    '&& span': {
      fontSize: 12,
    },
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  let history = useHistory();

  const handleTopClick = () => history.goBack();

  const renderBackButton = () => {
    return (
      <div className={classesModule.topBarDropdown}>
        {/* <Button
					id="profile-button"
					aria-controls="profile-menu"
					aria-haspopup="true"
					color="inherit"
					onClick={handleTopClick}
					aria-expanded={true}
					startIcon={<ArrowBackIosIcon />}
				>
					<div className={classes.buttonProfile}>
						<div>VOLVER</div>
					</div>
				</Button> */}
      </div>
    );
  };

  return (
    <AppBar
      {...rest}
      position='fixed'
      classes={{
        colorPrimary: classes.colortopbar,
      }}
      className={clsx(classes.appBar)}
    >
      <Toolbar
        style={{
          backgroundImage: `url(${Bgtoplogo})`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: '0px',
        }}
      >
        <RouterLink to='/'>
          <img
            className={clsx(classesModule.logoTop, 'ml-2')}
            alt='Logo'
            // src="/images/logos/logo-top-w.png"
            src={LogoTopbar}
          />
        </RouterLink>
        <div className={classes.rightBar}>
          <div className={classesModule.flexGrow} />
          {IsDesktopHandler('sm') && renderBackButton()}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
