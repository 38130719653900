// External libraries
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal modules
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import clsx from 'clsx';
import { LoadingWidget } from 'components';
import { alphabeticalSort, dashDelete } from 'helpers';
import {
  exportToExcelWeatherResume,
  transformDataWeather,
} from 'helpers/exportFunctions';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);

const useStyles = makeStyles((theme) => ({
  theadLightSmall: {
    '& thead tr:nth-child(2) th': {
      backgroundColor: 'white',
    },
    '& th': {
      // color: '#495057',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
  tbodyStyle: {
    '& td': {
      fontSize: 14,
    },
    '& td.greyBackground': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

const yearsArray = Array.from(
  { length: 4 },
  (_, i) => new Date().getFullYear() - i
)
  .map((year) => year.toString())
  .reverse();

const tableColdHourStructure = {
  years: yearsArray,
  months: ['4', '5', '6', '7'],
};

const tableColdPortionStructure = {
  years: yearsArray,
  months: ['4', '5', '6', '7'],
};

const tableDegreeDaysStructure = {
  years: yearsArray,
  months: ['8', '9', '10', '11', '12'],
};

const agroclimaticEndpoint = 'v1/weather/weather-agroclimatic-dashboard';

const EXPORT_HEADERS = {
  orchardValue: 'Huerto',
  year: 'Año',
  month: 'Mes',
  value: 'valor',
};

const WeatherParameters = ({ orchards: orchardsProp }) => {
  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const classes = useStyles();

  const theme = useTheme();

  const errorHandler = useErrorHandler();

  const [loadingState, setLoadingState] = useState(false);
  const [tableDataState, setTableDataState] = useState(null);

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      const objQuery = {
        orchard:
          dataQuery?.orchards && dataQuery?.orchards?.length
            ? dataQuery.orchards
            : userOrchard,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        agroclimaticEndpoint,
        objQuery
      );

      // // // let data = true;

      const { data, status } = response;

      // console.log(objQuery, '-----objQuery');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        console.log('pasa', data);

        setTableDataState(data);
      }
    } catch (err) {
      setTableDataState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    queryData(orchardsProp);
  }, [orchardsProp]);

  return (
    <>
      <div
        className='table-responsive px-3 pt-4'
        style={{
          border: '1px solid',
          borderColor: theme.palette.grey[300],
          borderRadius: theme.shape.borderRadius,
        }}
      >
        {!loadingState ? (
          <>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item>
                <Typography
                  variant='h5'
                  gutterBottom
                  component='h2'
                  sx={{ fontWeight: 700 }}
                >
                  Horas Frío
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  disabled={!tableDataState || loadingState}
                  onClick={() =>
                    exportToExcelWeatherResume(
                      transformDataWeather(tableDataState.coldHour),
                      EXPORT_HEADERS,
                      'Horas_frio'
                    )
                  }
                >
                  EXCEL
                </Button>
              </Grid>
            </Grid>
            <table className={clsx('table table-sm', classes.theadLightSmall)}>
              <thead>
                <tr>
                  <th scope='col' className='text-center' rowSpan={2}>
                    Huertos
                  </th>
                  {yearsArray.map((year) => (
                    <th
                      scope='col'
                      key={year}
                      colSpan={5}
                      className='text-center'
                    >
                      {year}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tbodyStyle}>
                {tableDataState &&
                  alphabeticalSort(tableDataState.coldHour, 'orchardValue').map(
                    ({ orchard, orchardValue, data }, index) => (
                      <tr key={`${index}_${orchardValue}`}>
                        <td className='text-left greyBackground'>
                          {dashDelete(orchardValue)}
                        </td>
                        {tableColdHourStructure.years.map((year, index) => {
                          const yearData = data.find(
                            (item) => item.year === year
                          );
                          let cols;
                          if (yearData) {
                            cols = tableColdHourStructure.months.map(
                              (month, index) => {
                                const monthData = yearData.months.find(
                                  (item) => item.month === month
                                );

                                if (monthData) {
                                  return (
                                    <td key={`${monthData.month}_${index}`}>
                                      {monthData.value}
                                    </td>
                                  );
                                } else {
                                  return <td key={`mes_${index}`}>0</td>;
                                }
                              }
                            );
                          } else {
                            cols = tableColdHourStructure.months.map(
                              (month, index) => {
                                return <td key={index}>0</td>;
                              }
                            );
                          }
                          return (
                            <React.Fragment key={`year_${index}_${year}`}>
                              {cols}
                              <td className='font-weight-bold'>
                                {yearData?.total || '-'}
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
      <div
        className='table-responsive px-3 pt-4 mt-3'
        style={{
          border: '1px solid',
          borderColor: theme.palette.grey[300],
          borderRadius: theme.shape.borderRadius,
        }}
      >
        {!loadingState ? (
          <>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item>
                <Typography
                  variant='h5'
                  gutterBottom
                  component='h2'
                  sx={{ fontWeight: 700 }}
                >
                  Porciones Frío
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  disabled={!tableDataState || loadingState}
                  onClick={() =>
                    exportToExcelWeatherResume(
                      transformDataWeather(tableDataState.coldPortion),
                      EXPORT_HEADERS,
                      'Porciones_frio'
                    )
                  }
                >
                  EXCEL
                </Button>
              </Grid>
            </Grid>
            <table className={clsx('table table-sm', classes.theadLightSmall)}>
              <thead>
                <tr>
                  <th scope='col' className='text-center' rowSpan={2}>
                    Huertos
                  </th>
                  {yearsArray.map((year) => (
                    <th
                      scope='col'
                      key={year}
                      colSpan={5}
                      className='text-center'
                    >
                      {year}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Abr
                  </th>
                  <th scope='col' className='text-center'>
                    May
                  </th>
                  <th scope='col' className='text-center'>
                    Jun
                  </th>
                  <th scope='col' className='text-center'>
                    Jul
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tbodyStyle}>
                {tableDataState &&
                  alphabeticalSort(
                    tableDataState.coldPortion,
                    'orchardValue'
                  ).map(({ orchard, orchardValue, data }, index) => (
                    <tr key={`${index}_${orchardValue}`}>
                      <td className='text-left greyBackground'>
                        {dashDelete(orchardValue)}
                      </td>
                      {tableColdPortionStructure.years.map((year, index) => {
                        const yearData = data.find(
                          (item) => item.year === year
                        );
                        let cols;
                        if (yearData) {
                          cols = tableColdPortionStructure.months.map(
                            (month, index) => {
                              const monthData = yearData.months.find(
                                (item) => item.month === month
                              );
                              if (monthData) {
                                return (
                                  <td key={`${monthData?.month}_${index}`}>
                                    {monthData.value}
                                  </td>
                                );
                              } else {
                                return <td key={`mes_${index}`}>0</td>;
                              }
                            }
                          );
                        } else {
                          cols = tableColdPortionStructure.months.map(
                            (month, index) => {
                              return <td key={index}>0</td>;
                            }
                          );
                        }
                        return (
                          <React.Fragment key={`year_${index}_${year}`}>
                            {cols}
                            <td className='font-weight-bold'>
                              {yearData?.total || '-'}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
      <div
        className='table-responsive px-3 pt-4 mt-3'
        style={{
          border: '1px solid',
          borderColor: theme.palette.grey[300],
          borderRadius: theme.shape.borderRadius,
        }}
      >
        {!loadingState ? (
          <>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item>
                <Typography
                  variant='h5'
                  gutterBottom
                  component='h2'
                  sx={{ fontWeight: 700 }}
                >
                  Grado Día
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  disabled={!tableDataState || loadingState}
                  onClick={() =>
                    exportToExcelWeatherResume(
                      transformDataWeather(tableDataState.degreeDays),
                      EXPORT_HEADERS,
                      'Grado_dia'
                    )
                  }
                >
                  EXCEL
                </Button>
              </Grid>
            </Grid>
            <table className={clsx('table table-sm', classes.theadLightSmall)}>
              <thead>
                <tr>
                  <th scope='col' className='text-center' rowSpan={2}>
                    Huertos
                  </th>
                  {yearsArray.map((year) => (
                    <th
                      scope='col'
                      key={year}
                      colSpan={6}
                      className='text-center'
                    >
                      {year}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th scope='col' className='text-center'>
                    Ago
                  </th>
                  <th scope='col' className='text-center'>
                    Sept
                  </th>
                  <th scope='col' className='text-center'>
                    Oct
                  </th>
                  <th scope='col' className='text-center'>
                    Nov
                  </th>
                  <th scope='col' className='text-center'>
                    Dic
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Ago
                  </th>
                  <th scope='col' className='text-center'>
                    Sept
                  </th>
                  <th scope='col' className='text-center'>
                    Oct
                  </th>
                  <th scope='col' className='text-center'>
                    Nov
                  </th>
                  <th scope='col' className='text-center'>
                    Dic
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Ago
                  </th>
                  <th scope='col' className='text-center'>
                    Sept
                  </th>
                  <th scope='col' className='text-center'>
                    Oct
                  </th>
                  <th scope='col' className='text-center'>
                    Nov
                  </th>
                  <th scope='col' className='text-center'>
                    Dic
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                  <th scope='col' className='text-center'>
                    Ago
                  </th>
                  <th scope='col' className='text-center'>
                    Sept
                  </th>
                  <th scope='col' className='text-center'>
                    Oct
                  </th>
                  <th scope='col' className='text-center'>
                    Nov
                  </th>
                  <th scope='col' className='text-center'>
                    Dic
                  </th>
                  <th scope='col' className='text-center font-weight-bold'>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tbodyStyle}>
                {tableDataState &&
                  alphabeticalSort(
                    tableDataState.degreeDays,
                    'orchardValue'
                  ).map(({ orchard, orchardValue, data }, index) => (
                    <tr key={`${index}_${orchardValue}`}>
                      <td className='text-left greyBackground'>
                        {dashDelete(orchardValue)}
                      </td>
                      {tableDegreeDaysStructure.years.map((year, index) => {
                        const yearData = data.find(
                          (item) => item.year === year
                        );
                        let cols;
                        if (yearData) {
                          cols = tableDegreeDaysStructure.months.map(
                            (month, index) => {
                              const monthData = yearData.months.find(
                                (item) => item.month === month
                              );
                              if (monthData) {
                                return (
                                  <td key={`${monthData?.month}_${index}`}>
                                    {monthData.value}
                                  </td>
                                );
                              } else {
                                return <td key={`mes_${index}`}>0</td>;
                              }
                            }
                          );
                        } else {
                          cols = tableDegreeDaysStructure.months.map(
                            (month, index) => {
                              return <td key={index}>0</td>;
                            }
                          );
                        }
                        return (
                          <React.Fragment key={`year_${index}_${year}`}>
                            {cols}
                            <td className='font-weight-bold'>
                              {yearData?.total || '-'}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    </>
  );
};

export default WeatherParameters;
