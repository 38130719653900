import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Modal, Backdrop, Fade, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
// import classes from './ModalWidgetDetailTable.module.scss';
import {
	DocumentsTableDetail,
	QuestionsTableDetail,
	TagsTableDetail,
} from '../';
import {
	dateConvertString,
	getYearsFromBirth,
	numberFormat,
} from '../../helpers';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paperModal: {
		backgroundColor: theme.palette.background.paper,
		// border: '2px solid #000',
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[3],
		padding: theme.spacing(1, 3, 3),
		width: '75%',
		minHeight: '60vh',
	},
	scrollAreaModalStyle: {
		overflow: 'auto',
		maxHeight: '95%',
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	tableStyle: {
		'& th': {
			fontSize: 18,
			lineHeight: 2,
		},
		'& tr td': {
			fontSize: 15,
			paddingTop: 5,
			paddingBottom: 8,
		},
	},
	tdListStyle: {
		listStyle: 'inside',
		'& li': {
			marginBottom: 10,
		},
	},
}));

const ModalWidgetDetailTable = (props) => {
	const { openDetail, detailModal, moduleName, size, closeFunc } = props;
	const [openStateDetail, setOpenStateDetail] = useState(openDetail);
	console.log(detailModal, 'carga modal');

	const classes = useStyles();

	const handleClose = () => {
		closeFunc(false);
	};

	useEffect(() => {
		setOpenStateDetail(openDetail);
	}, [openDetail]);

	const handleRowTable = (row) => {
		const detail = [];
		for (let fieldName in row) {
			switch (fieldName) {
				case 'rut':
					detail.push({
						label: 'Rut',
						value: row[fieldName],
						order: 1,
					});
					break;
				case 'lastName':
					detail.push({
						label: 'Apellidos',
						value: row[fieldName],
						order: 2,
					});
					break;
				case 'lastNames':
					detail.push({
						label: 'Apellidos',
						value: row[fieldName],
						order: 3,
					});
					break;
				case 'name':
					detail.push({
						label: 'Nombre',
						value: row[fieldName],
						order: 4,
					});
					break;
				case 'names':
					detail.push({
						label: 'Nombres',
						value: row[fieldName],
						order: 5,
					});
					break;
				case 'phone':
					detail.push({
						label: 'Teléfono',
						value: row[fieldName],
						order: 6,
					});
					break;
				case 'phoneNumber':
					detail.push({
						label: 'Teléfono',
						value: row[fieldName],
						order: 7,
					});
					break;
				case 'contractorTotes':
					detail.push({
						label: 'Valores Tote',
						value: row[fieldName],
						order: 9,
						map: true,
					});
					break;
				case 'orchard':
					detail.push({
						label: 'Huertos',
						value: row[fieldName],
						order: 10,
						map: true,
					});
					break;
				// case 'subcontrators':
				//   detail.push({
				//     label: 'Subcontratista',
				//     value: row[fieldName].name,
				//     order: 4
				//   });
				//   break;
				case 'email':
					detail.push({
						label: 'Email',
						value: row[fieldName],
						order: 8,
					});
					break;
				case 'codeSession':
					detail.push({
						label: 'Código',
						value: row[fieldName],
						order: 8,
					});
					break;
				case 'professional':
					moduleName === 'Pacientes'
						? detail.push({
								label: 'Profesional',
								value: row[fieldName],
								order: 8,
								map: row[fieldName] ? true : false,
						  })
						: detail.push({
								label: 'Profesional',
								value: `${row[fieldName].name} ${row[fieldName].lastName} | ${row[fieldName].rut}`,
								order: 8,
						  });
					break;
				case 'patient':
					moduleName === 'Profesionales'
						? detail.push({
								label: 'Pacientes',
								value: row[fieldName],
								order: 9,
								map: row[fieldName] ? true : false,
						  })
						: detail.push({
								label: 'Paciente',
								value: `${row[fieldName].name} ${row[fieldName].lastName} | ${row[fieldName].rut}`,
								order: 9,
						  });
					break;

				case 'professionalCertificate':
					detail.push({
						label: 'Certificado Profesional',
						value: (
							<a
								href={row[fieldName]}
								target="_blank"
								rel="noopener noreferrer"
							>
								{row[fieldName]}
							</a>
						),
						order: 11,
						map: false,
					});
					break;

				case 'tags':
					detail.push({
						label: 'Tags',
						value: row[fieldName],
						order: 12,
						map: true,
					});
					break;
				case 'specialty':
					detail.push({
						label: 'Especialidad',
						value: row[fieldName],
						order: 13,
						map: true,
					});
					break;
				case 'priceFinal':
					detail.push({
						label: 'Valor Final',
						value: numberFormat(row[fieldName]),
						order: 14,
						map: false,
					});
					break;
				case 'dateSession':
					detail.push({
						label: 'Fecha Sesión',
						value: row[fieldName],
						order: 12,
						map: false,
					});
					break;

				case 'phoneEmergency':
					detail.push({
						label: 'Fono Emergencias',
						value: row[fieldName],
						order: 12,
						map: false,
					});
					break;
				case 'birthDate':
					detail.push({
						label: 'Edad',
						value: row[fieldName]
							? getYearsFromBirth(
									dateConvertString(row[fieldName])
							  )
							: '',
						order: 12,
						map: false,
					});
					break;
				case 'photo':
					detail.push({
						label: 'Foto',
						value: (
							<img
								alt="img"
								src={row[fieldName]}
								width="100"
								height="100"
							/>
						),
						order: 12,
						map: false,
					});
					break;
				case 'bankNumberAccount':
					detail.push({
						label: 'Nº Cuenta',
						value: row[fieldName],
						order: 13,
					});
					break;
				case 'reviewSession':
					detail.push({
						label: 'Review Paciente',
						value: row[fieldName].review
							? `${row[fieldName].review} | ${row[fieldName].assessment} | ${row[fieldName].date}`
							: 'No Cerrada',
						order: 14,
					});
					break;

				default:
					break;
			}
		}
		return detail;
	};

	const showDetailTable = (moduleName) => {
		switch (moduleName) {
			case 'Usuarios':
				return null;

			case 'Proyectos':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Proyecto"
					/>
				);

			case 'Tipo de Tag':
				return (
					<TagsTableDetail dataDocs={detailModal.tag} title="Tags" />
				);

			case 'Tipo de Protocolos':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Tipo de Protocolos"
					/>
				);

			case 'Contratista':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Contratista"
					/>
				);

			case 'Tipo de Actividad':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Tipo de Actividad"
					/>
				);

			case 'Cuadrillas':
				return null;

			case 'CheckLists':
				return (
					<QuestionsTableDetail
						dataQuestions={detailModal.questions}
						title="CheckLists"
					/>
				);

			default:
				return null;
		}
	};

	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
			className={classes.modal}
			open={openStateDetail}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			{/* <Fade in={openStateDetail}> */}
			<div className={classes.paperModal}>
				<div>
					<BsContainer fluid>
						<BsRow>
							<BsCol class="col-md-12 text-right pr-0">
								<Button onClick={handleClose}>
									<CloseIcon />
								</Button>
							</BsCol>
						</BsRow>
					</BsContainer>
				</div>
				<div className={classes.scrollAreaModalStyle}>
					<BsContainer fluid>
						<BsRow>
							<BsCol class="col-12">
								<table
									className={clsx(
										'table table-borderless table-sm',
										classes.tableStyle
									)}
								>
									<thead>
										<tr>
											<th colSpan="2">
												<i className="far fa-address-card"></i>{' '}
												Información
											</th>
										</tr>
									</thead>
									<tbody>
										{detailModal &&
											_.orderBy(
												handleRowTable(detailModal),
												['order'],
												['asc']
											).map((item) => {
												if (
													item.map &&
													moduleName === 'Usuarios'
												) {
													return (
														<tr key={item.label}>
															<td
																className={
																	classes.labelTd
																}
															>
																{item.label}
															</td>
															<td
																className={
																	classes.tdListStyle
																}
															>
																<ul>
																	{item.value.map(
																		(
																			ele
																		) => {
																			return (
																				<li
																					key={
																						ele.id
																					}
																				>
																					{
																						ele.name
																					}{' '}
																					{ele.defaultProject
																						? ' (Principal)'
																						: null}
																				</li>
																			);
																		}
																	)}
																</ul>
															</td>
														</tr>
													);
												}
												if (
													item.map &&
													moduleName ===
														'Contratistas'
												) {
													return (
														<tr key={item.label}>
															<td
																className={
																	classes.labelTd
																}
															>
																{item.label}
															</td>
															<td
																className={
																	classes.tdListStyle
																}
															>
																<ul>
																	{item.value &&
																		item
																			.value
																			.length >
																			0 &&
																		item.value.map(
																			(
																				ele
																			) => {
																				return (
																					<li
																						key={
																							ele.id
																						}
																					>
																						Tote{' '}
																						{
																							ele.label
																						}

																						:
																						${' '}
																						{numberFormat(
																							ele.value
																						)}
																					</li>
																				);
																			}
																		)}
																</ul>
															</td>
														</tr>
													);
												}
												if (
													item.map &&
													moduleName ===
														'Supervisores'
												) {
													return (
														<tr key={item.label}>
															<td
																className={
																	classes.labelTd
																}
															>
																{item.label}
															</td>
															<td
																className={
																	classes.tdListStyle
																}
															>
																<ul>
																	{item.value &&
																		item
																			.value
																			.length >
																			0 &&
																		item.value.map(
																			(
																				ele
																			) => {
																				return (
																					<li
																						key={
																							ele.orchard
																						}
																					>
																						{
																							ele.orchard
																						}
																					</li>
																				);
																			}
																		)}
																</ul>
															</td>
														</tr>
													);
												}
												return (
													<tr key={item.label}>
														<td
															className={
																classes.labelTd
															}
														>
															{item.label}
														</td>
														<td>{item.value}</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</BsCol>
							<BsCol class="col-md-12">
								{showDetailTable(moduleName)}
							</BsCol>
						</BsRow>
					</BsContainer>
				</div>
			</div>
			{/* </Fade> */}
		</Modal>
	);
};
export default ModalWidgetDetailTable;
