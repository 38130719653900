import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import bellCurve from 'highcharts/modules/histogram-bellcurve';
import { findActualYear, numberFormat } from '../../../helpers';

// init the module Histogram
bellCurve(Highcharts);

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 3),
    width: '95%',
    height: '65vh',
    margin: 'auto',
    // marginTop: '5vh',
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
}));

const ModalGraph = ({ closeAction, dataModal }) => {
  const theme = useTheme();

  const classes = useStyles();

  console.log(dataModal, '----dataModal');

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12'>
              <Typography variant='h5' gutterBottom>
                Histograma
              </Typography>
              <BsRow>
                <BsCol class='col-md-12'>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dataModal}
                  />
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalGraph;
