export const intelligenceReducer = (state, action) => {
	switch (action.type) {
		case 'add_values':
			let newObj = {};

			Object.entries(action.payload).forEach(([key, value]) => {
				return (newObj = {
					...newObj,
					[key]: value,
				});
			});

			return {
				...state,
				isSet: true,
				...newObj,
			};

		case 'reset_values':
			return action.payload;

		case 'not_set_values':
			return {
				...state,
				isSet: false,
			};

		default:
			return state;
	}
};
