import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';
import PerformanceIcon from '../../../assets/icons/Grouprendimiento.png';
import ClearIcon from '@mui/icons-material/Clear';
import { useContext, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { AxiosContext } from '../../../context/AxiosContext';
import { SaveAction } from '../../../components';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 7),
    width: '85%',
    height: '45vh',
    margin: 'auto',
    // marginTop: '5vh',
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
}));

const ModalEdit = ({ closeAction, dataModal, isEditedFunc }) => {
  const theme = useTheme();

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const errorHandler = useErrorHandler();

  const [loadingState, setLoadingState] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  console.log(dataModal, '----dataModal');

  const onReset = (type) => {
    setValue(type, '');
    // setExpectedInfoState([]);
  };

  const onSubmit = async (values) => {
    setLoadingState(true);
    console.log(values, '----values');
    let yieldProducerNumber = values.yieldProducer.replace(/\./g, '');
    yieldProducerNumber = yieldProducerNumber.replace(',', '.');

    console.log(yieldProducerNumber, '----yieldProducerNumber');

    const bodyQuery = {
      orchardValue: dataModal.orchardValue,
      ccValue: dataModal.ccValue,
      quarterValue: dataModal.quarterValue,
      varietyValue: dataModal.varietyValue,
      rootStockValue: dataModal.rootStockValue,
      season: dataModal.season,
      yieldProducer: parseFloat(yieldProducerNumber),
    };

    console.log(bodyQuery, '----bodyQuery');

    try {
      const response = await axiosContext.authAxios.post(
        'v1/library/library-yield-calculator/add-yield-producer',
        bodyQuery
      );
      const { data, status } = response;

      console.log(data, status, '-<<-------response');

      if (status === 200) {
        // setExpectedInfoState(data);
        setLoadingState(false);
        setSuccessState(true);
        isEditedFunc();
      }
    } catch (error) {
      console.error(error);
      errorHandler(error);
      setLoadingState(false);
    }
  };

  const closeHandler = () => {
    setSuccessState(false);
    closeAction();
  };

  useEffect(() => {
    if (dataModal) {
      reset({
        yieldProducer: dataModal.yieldProducer,
      });
    }
  }, []);

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-center'>
              <Typography variant='h5' gutterBottom>
                Unidad Productiva: {dataModal.orchard} - {dataModal.cc} -{' '}
                {dataModal.quarter} - {dataModal.variety} - Temporada:{' '}
                {dataModal.season}
              </Typography>
              <BsRow class='mt-5'>
                <BsCol class='col-md-12'>
                  {/* {JSON.stringify(dataModal, null, 2)} */}

                  <form
                    autoComplete='false'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* clase bootrap 4 para centrar las columnas */}
                    <BsRow class=' justify-content-md-center'>
                      <BsCol class='col-md-4'>
                        <div className=''>
                          <Grid
                            container
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='center'
                          >
                            <Grid item>
                              <img
                                src={PerformanceIcon}
                                width='16'
                                className='img-fluid img-estimated'
                                alt='dart'
                              />
                            </Grid>
                            <Grid item className='ml-0 ml-lg-1'>
                              <Typography
                                variant='h6'
                                // gutterBottom
                              >
                                Rendimiento Productor
                              </Typography>
                            </Grid>
                          </Grid>
                          <div className='d-flex'>
                            <Controller
                              control={control}
                              rules={{
                                required: true,
                              }}
                              name='yieldProducer'
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <NumberFormat
                                  thousandsGroupStyle='thousand'
                                  value={value}
                                  onChange={onChange}
                                  disabled={false}
                                  error={Boolean(errors.yieldProducer)}
                                  helperText={
                                    errors.yieldProducer
                                      ? 'Complete cantidad'
                                      : ''
                                  }
                                  variant='standard'
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.rootHelper,
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      input: classes.inputText,
                                    },
                                    endAdornment: (
                                      <>
                                        {value && (
                                          <IconButton
                                            color='primary'
                                            aria-label='upload picture'
                                            component='span'
                                            onClick={() =>
                                              onReset('yieldProducer')
                                            }
                                          >
                                            <ClearIcon
                                              fontSize='small'
                                              style={{
                                                color: theme.palette.grey[400],
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                        <InputAdornment
                                          position='end'
                                          variant='filled'
                                          disableTypography
                                          style={{
                                            color: 'black',
                                            height: 'auto',
                                            maxHeight: '40px',
                                            padding: '14px 16px',
                                            borderRadius:
                                              theme.shape.borderRadius,
                                            backgroundColor:
                                              theme.palette.background.default,
                                          }}
                                        >
                                          kg/ha
                                        </InputAdornment>
                                      </>
                                    ),
                                  }}
                                  customInput={TextField}
                                  className='flex-grow-1'
                                  // prefix="$"
                                  decimalSeparator=','
                                  displayType='input'
                                  type='text'
                                  thousandSeparator='.'
                                  allowNegative={true}
                                  decimalScale={1}
                                  placeholder='en miles, 5.000-25.000'
                                />
                              )}
                            />
                          </div>
                        </div>
                      </BsCol>
                    </BsRow>
                    <BsRow class='justify-content-center mt-5'>
                      <BsCol class='col-md-3'>
                        <SaveAction
                          title='Registrar'
                          color='secondary'
                          size='large'
                          fullWidth={true}
                          disabled={false}
                          actionClose={closeHandler}
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingState}
                          success={successState}
                        />
                      </BsCol>
                    </BsRow>
                  </form>
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalEdit;
