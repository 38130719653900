import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
// import classes from './Tabs.module.scss';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tabPanelStyle: {
    minHeight: '50vh',
  },
  tabPanelNoHeightStyle: {
    height: 'auto',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, py, noMinHeight, px, ...other } = props;

  const paddingY = py || 'py-2';
  const paddingX = px || 'px-0';

  const classes = useStyles();

  const heightStyle = noMinHeight
    ? classes.tabPanelNoHeightStyle
    : classes.tabPanelStyle;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={[paddingX, paddingY, heightStyle].join(' ')}>
          {children}
        </div>
      )}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
