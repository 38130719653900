import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer } from '@mui/material';
import LogoDrawer from '../../../../assets/Logo-menu.png';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import classesModule from './Sidebar.module.scss';
import { Context } from '../../../../context/Context';
import { Profile, SidebarNav } from './components';
import { makeStyles, useTheme } from '@mui/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 111,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: parseInt(theme.spacing(8)) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const Sidebar = (props) => {
  const {
    open,
    variant = 'temporary',
    onClose,
    onSidebarOpen,
    className,
    ...rest
  } = props;

  const theme = useTheme();

  const classes = useStyles();

  const context = useContext(Context);
  const moduleUser = context.moduleUser;

  // console.log('<-----context', moduleUser);

  let icon;

  const modules = [
    // {
    // 	id: 100,
    // 	title: 'Inicio',
    // 	href: '/dashboard',
    // 	iconType: 'material',
    // 	icon: 'DashboardIcon',
    // },
  ];
  if (moduleUser !== null) {
    // console.log(moduleUser, '---');
    moduleUser.forEach((module) => {
      if (module.assigned) {
        if (module.subMenu) {
          const submenu = [];
          module.process.forEach((process) => {
            if (process.assigned) {
              const proceso = {
                id: process.id,
                title: process.name,
                href: '/' + process.href,
                iconType: process.iconType ? process.iconType : '',
                icon: process.icon,
              };
              submenu.push(proceso);
            }
          });
          let hrefValue = module.href;
          if (hrefValue === 'false') {
            hrefValue = false;
          }
          const item = {
            id: module.id,
            title: module.namePrint || module.name,
            href: hrefValue,
            iconType: module.iconType ? module.iconType : '',
            icon: module.icon,
            submenu: submenu,
            menuOrder: module?.menuOrder,
          };
          modules.push(item);
        } else {
          const item = {
            id: module.id,
            title: module.namePrint || module.name,
            href: module.href,
            icon: module.icon,
            iconType: module.iconType ? module.iconType : '',
            submenu: module.subMenu,
            menuOrder: module?.menuOrder,
          };
          modules.push(item);
        }
      }
    });
  }

  // console.log(modules, '<<---modules');

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      variant={variant}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {/* <div {...rest} className={clsx(classes.root, className)}> */}
      <div className={classes.toolbar}>
        <div className={classesModule.buttonDrawerStyle}>
          {!open && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={onSidebarOpen}
              // edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>
        <div>
          <img
            src={LogoDrawer}
            alt='analytics'
            className={clsx(!open ? 'ml-3' : '')}
          />
        </div>
        <IconButton onClick={onClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon color='primary' />
          ) : (
            <CloseIcon color='primary' />
          )}
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <Profile open={open} />
      {/* <Divider className={classes.divider} /> */}
      <SidebarNav open={open} className={classesModule.nav} pages={modules} />
      {/* </div> */}
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
