import { useContext } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import LogoDrawer from '../../../../assets/logo-top.png';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Context } from '../../../../context/Context';
import { Profile } from './components';
import { SidebarNav } from '../index';
import { makeStyles, useTheme } from '@mui/styles';
import { SimpleIconButton } from 'components';

const drawerWidth = 240;
const linkParams = '/params';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const useStyles = makeStyles((theme) => ({
  listItemStyle: {
    minHeight: 40,
    '&.Mui-selected': {
      backgroundColor: '#2A4C60',
      color: 'white',
      borderRadius: 8,
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },
  sideBarNavStyle: {
    flexGrow: 1,
  },
  // toolbar: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   padding: theme.spacing(0, 1.5),
  //   // necessary for content to be below app bar
  //   ...theme.mixins.toolbar,
  // },
}));

const Sidebar = (props) => {
  const {
    openState,
    variant = 'permanent',
    handleOnClose,
    handleOnOpen,
    className,
    ...rest
  } = props;

  const theme = useTheme();

  const classes = useStyles();

  const history = useHistory();

  const context = useContext(Context);
  const moduleUser = context.moduleUser;

  let icon;

  const modules = [];

  if (moduleUser !== null) {
    // console.log(moduleUser, '---');
    moduleUser.forEach((module) => {
      if (module.assigned) {
        if (module.subMenu) {
          const submenu = [];
          module.process.forEach((process) => {
            if (process.assigned) {
              const proceso = {
                id: process.id,
                title: process.name,
                href: '/' + process.href,
                iconType: process.iconType ? process.iconType : '',
                icon: process.icon,
              };
              submenu.push(proceso);
            }
          });
          let hrefValue = module.href;
          if (hrefValue === 'false') {
            hrefValue = false;
          }
          const item = {
            id: module.id,
            title: module.namePrint || module.name,
            href: hrefValue,
            iconType: module.iconType ? module.iconType : '',
            icon: module.icon,
            submenu: submenu,
            menuOrder: module?.menuOrder,
          };
          modules.push(item);
        } else {
          const item = {
            id: module.id,
            title: module.namePrint || module.name,
            href: module.href,
            icon: module.icon,
            iconType: module.iconType ? module.iconType : '',
            submenu: module.subMenu,
            menuOrder: module?.menuOrder,
          };
          modules.push(item);
        }
      }
    });
  }

  const paramsHandler = () => {
    history.push({
      pathname: linkParams,
    });
  };

  // console.log(modules, '<<---modules');

  return (
    <Drawer open={openState} variant={variant}>
      <DrawerHeader>
        <Box
          sx={{
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          <img src={LogoDrawer} className='img-fluid' />
        </Box>
        <IconButton onClick={handleOnClose} size='small'>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Profile open={openState} />
      <SidebarNav
        open={openState}
        className={classes.sideBarNavStyle}
        pages={modules}
        dense={true}
      />
      <Box
        sx={{
          display: openState ? 'block' : 'none',
          textAlign: 'center',
          px: 2,
        }}
      >
        <SimpleIconButton
          labelButton='Ajustes'
          variant='outlined'
          color='secondary'
          sx={{
            width: '100%',
            mb: 1,
          }}
          icon={<i className='fas fa-cog'></i>}
          actionOnClick={paramsHandler}
        />
        <Typography variant='caption' component='p' color='textSecondary'>
          Versión 2.1.0
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
