import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  SaveAction,
  CardHeaderWidget,
  TextFieldWidgetText,
} from '../../../components';
import { Card, CardContent, Divider } from '@mui/material';
import { dateFrontConvert } from '../../../helpers';
import { BsRow, BsCol } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';

const endPointEditName = 'v1/planningHarvest';

const EditDay = (props) => {
  const require = true;
  // console.log(props.rowEdit, ' llega row a edit');
  const { id, orchard, variety, date, kilos, version } = props.rowEdit;
  const { editFunc, closeFunc } = props;

  const axiosContext = useContext(AxiosContext);

  const [loadingQueryState, setLoadingQueryState] = useState(false);
  const [successQueryState, setSuccessQueryState] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    getValues,
    reset,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    reset({
      id: id,
      // orchard: orchard,
      // variety: variety,
      // date: date,
      harvest: kilos,
      // version: version,
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [id, orchard, variety, date, kilos, version, reset]);

  const onSubmit = async (values) => {
    setLoadingQueryState(true);

    const query = {
      kilos: parseFloat(values.harvest),
    };

    const objUpdate = values.id;

    try {
      const response = await axiosContext.authAxios.put(
        `${endPointEditName}/${objUpdate}`,
        query
      );

      console.log(response, 'response edit day');

      if (response.status === 200) {
        // alert('listo');
        editFunc(response.data);
        setSuccessQueryState(true);
      }
    } catch (err) {
      setLoadingQueryState(false);
    } finally {
      setLoadingQueryState(false);
    }
    // editFunc({
    //   id: values.id,
    //   orchard: 'Huerto 1',
    //   variety: 'Bing',
    //   date: '14-12-2023',
    //   harvest: values.harvest,
    //   version: 2,
    // });
    // setSuccessQueryState(true);
    // setLoadingQueryState(false);
  };

  return (
    <Card elevation={0} variant='outlined'>
      <CardHeaderWidget
        title={`Editar día ${dateFrontConvert(
          date
        )} - Huerto: ${orchard} - Variedad: ${variety}`}
      />
      <CloseButtonEditionArea closeFunc={props.closeFunc} />
      <Divider />
      <CardContent>
        <BsRow>
          <BsCol class='col-md-12'>
            <form autoComplete='on' onSubmit={handleSubmit(onSubmit)}>
              <BsRow>
                <BsCol class='col-md-12'>
                  <TextFieldWidgetText
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='id'
                    labeltext=''
                    control={control}
                    style={{
                      display: 'none',
                    }}
                    defaultValue={id}
                  />
                  <TextFieldWidgetText
                    name='harvest'
                    labeltext='Cosecha'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    req={true}
                    defaultValue=''
                    minLength={2}
                    maxLength={12}
                    control={control}
                    errors={errors.harvest}
                  />
                </BsCol>
              </BsRow>
              <BsRow class='mt-5'>
                <BsCol class='col-md-12 text-center'>
                  <SaveAction
                    title=' Guardar'
                    color='secondary'
                    size='large'
                    className='roundedButton ml-md-2'
                    variant='contained'
                    disabled={loadingQueryState}
                    messageType='edit'
                    actionClose={closeFunc}
                    mutationLoading={loadingQueryState}
                    success={successQueryState}
                  />
                </BsCol>
              </BsRow>
            </form>
          </BsCol>
        </BsRow>
      </CardContent>
    </Card>
  );
};

export default EditDay;
