export const FILTER = [
  {
    label: 'Santa Margarita',
    value: 'Santa_Margarita',
    cc: [
      {
        label: 'ccsm1',
        value: 'ccsm1',
        quarter: [
          {
            label: '10110',
            value: '10110',
            variety: [
              {
                label: 'Regina-a',
                value: 'Regina-a',
              },
              {
                label: 'Regina-b',
                value: 'Regina-b',
              },
            ],
          },
          {
            label: '10112',
            value: '10112',
            variety: [
              {
                label: 'Regina-c',
                value: 'Regina-c',
                graft: [
                  {
                    label: 'Injerto',
                    value: 'Injerto',
                  },
                ],
              },
              {
                label: 'Regina-d',
                value: 'Regina-d',
              },
            ],
          },
        ],
      },
      {
        label: 'ccsm2',
        value: 'ccsm2',
        quarter: [
          {
            label: '10111',
            value: '10111',
            variety: [
              {
                label: 'Flora',
                value: 'Flora',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Santa Laura',
    value: 'Santa_Laura',
    cc: [
      {
        label: 'ccSL',
        value: 'ccSL',
        quarter: [
          {
            label: '10000',
            value: '10000',
            variety: [
              {
                label: 'Regina',
                value: 'Regina',
              },
            ],
          },
          {
            label: '10001',
            value: '10001',
            variety: [
              {
                label: 'Regina2',
                value: 'Regina2',
              },
              {
                label: 'Regina3',
                value: 'Regina3',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const INFO = [
  {
    information: {
      variety: 'Regina',
      pattern: 'Patrón',
      dart: 800,
      dartFlower: 200,
      budDart: 200,
      twig: 200,
      flowerTwig: 200,
      flowerHectare: 200,
    },
    stimated: {
      fruitSet: 11234.456,
      weight: 123.56,
      performance: 234567.567,
    },
    graphicFruitSet: {
      name: 'Cuaja',
      year: [2012, 2013, 2014, 2015],
      data: [34, 304, 203, 456],
    },
    graphicFlower: {
      name: 'Flores',
      year: [2012, 2013, 2014, 2015, 2022],
      data: [34, 100, 67, 34567.34, 56],
    },
    graphicWeight: {
      name: 'Calibre',
      year: [2012, 2013, 2014, 2015, 2022],
      data: [34, 1000.56, 67, 34, 56],
    },
    graphicPerformance: {
      name: 'Rendimiento',
      year: [2012, 2013, 2014, 2015, 2016],
      data: [34, 100345.445, 67, 34, 56],
    },
  },
];

export const INFO2022 = [
  {
    information: {
      variety: 'Regina',
      pattern: 'Patrón',
      dart: 800,
      dartFlower: 200,
      budDart: 200,
      twig: 200,
      flowerTwig: 200,
      flowerHectare: 200,
    },
    stimated: {
      fruitSet: 10,
      weight: 10,
      performance: 10000,
    },
    graphicFruitSet: {
      name: 'Cuaja',
      year: [2012, 2013, 2014, 2022],
      data: [34, 304, 203, 456],
    },
    graphicFlower: {
      name: 'Flores',
      year: [2012, 2013, 2014, 2015, 2022],
      data: [34, 100, 67, 34, 56],
    },
    graphicWeight: {
      name: 'Calibre',
      year: [2012, 2013, 2014, 2015, 2022],
      data: [34, 100, 67, 34, 56],
    },
    graphicPerformance: {
      name: 'Rendimiento',
      year: [2012, 2013, 2014, 2015, 2022],
      data: [34, 100, 67, 34, 56],
    },
  },
];

export const INFONULL = [
  {
    information: {
      variety: null,
      pattern: null,
      dart: null,
      dartFlower: null,
      budDart: null,
      twig: null,
      flowerTwig: null,
      flowerHectare: null,
    },
    stimated: {
      fruitSet: null,
      weight: null,
      performance: null,
    },
    graphicFruitSet: {
      name: 'Cuaja',
      year: [],
      data: [],
    },
    graphicFlower: {
      name: 'Flores',
      year: [],
      data: [],
    },
    graphicWeight: {
      name: 'Calibre',
      year: [],
      data: [],
    },
    graphicPerformance: {
      name: 'Rendimiento',
      year: [],
      data: [],
    },
  },
];

export const RESPONSEFORM = [
  {
    dart: 800,
    twig: 200,
    flower: 200,
    fruitSet: 500,
    weight: 80,
    performance: 500,
  },
];

export const WEEKDAYS = [
  { id: 1, name: 'Lun' },
  { id: 2, name: 'Mar' },
  { id: 3, name: 'Mie' },
  { id: 4, name: 'Jue' },
  { id: 5, name: 'Vie' },
  { id: 6, name: 'Sab' },
  { id: 0, name: 'Dom' },
];

export const YEARS = [
  { id: 1, name: '2020' },
  { id: 2, name: '2021' },
  { id: 3, name: '2022' },
];

export const DATATABLE = [
  {
    id: 1,
    orchard: 'Santa Margarita',
    cc: '10012-1',
    quarter: '2001',
    variety: 'Bing',
    season: '2020',
    dart: 6800,
    twig: 31,
    flowerDart: 11.8,
    budDart: 4.91,
    flowerTwig: 15.4,
    flowerHa: 7549243.2,
    fruitSet: 10.4555,
    caliber: 10.15,
    date: '12-12-2020',
    performanceReal: 8159,
    estimatedFruitSet: 12,
    estimatedCaliber: 10,
    estimatedPerformance: 9323.567,
  },
  {
    id: 2,
    orchard: 'Santa Margarita',
    cc: '10012-2',
    quarter: '2002',
    variety: 'Bing',
    season: '2021',
    dart: 680,
    twig: 31,
    flowerDart: 11.8,
    budDart: 4.91,
    flowerTwig: 15.4,
    flowerHa: 7549243.2,
    fruitSet: 10.4555,
    caliber: 10.15,
    date: '12-12-2021',
    performanceReal: 8159,
    estimatedFruitSet: 12,
    estimatedCaliber: 10,
    estimatedPerformance: 9323.567,
  },
];

export const DATAMARKERS = [
  {
    name: 'Almahue | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.455944,
      lng: -71.291627,
    },
  },
  {
    name: 'Bodega | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -35.81092314,
      lng: -71.80201494,
    },
  },
  {
    name: 'Ceresur | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -39.370582,
      lng: -72.505756,
    },
  },
  {
    name: 'Chumpirro | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -37.77290943,
      lng: -72.5843856,
    },
  },
  {
    name: 'El Condor | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.88096199,
      lng: -71.01961733,
    },
  },
  {
    name: 'El Descanso | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -35.01339994,
      lng: -71.36233773,
    },
  },
  {
    name: 'El Espino | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -35.69074401,
      lng: -71.4423072,
    },
  },
  {
    name: 'El Parque | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -33.99713384,
      lng: -70.70206349,
    },
  },
  {
    name: 'El Recurso | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.00599633,
      lng: -70.71435889,
    },
  },
  {
    name: 'El Retiro | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.34474335,
      lng: -70.84801793,
    },
  },
  {
    name: 'El Rosario | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -35.91422153,
      lng: -71.49394827,
    },
  },
  {
    name: 'Entre Rios | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.7391402,
      lng: -70.80270549,
    },
  },
  {
    name: 'La Esperanza | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.31931041,
      lng: -71.26687654,
    },
  },
  {
    name: 'La Morera | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.10190105,
      lng: -70.67467837,
    },
  },
  {
    name: 'La Robleria | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -33.87383225,
      lng: -70.92042349,
    },
  },
  {
    name: 'Las Cabras | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.27657419,
      lng: -71.34238647,
    },
  },
  {
    name: 'Los Encinos | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968dba',
    location: {
      lat: -33.71408143,
      lng: -71.23883086,
    },
  },
  {
    name: 'Los Maitenes | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.21266843,
      lng: -71.25820505,
    },
  },
  {
    name: 'Los Toldos | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -37.85833076,
      lng: -73.00752786,
    },
  },
  {
    name: 'Mallorca | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -35.10284322,
      lng: -71.30962134,
    },
  },
  {
    name: 'Puente Negro | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.71333829,
      lng: -70.84152263,
    },
  },
  {
    name: 'Rio Claro | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.69819235,
      lng: -70.85646477,
    },
  },
  {
    name: 'San Hernán | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.059298,
      lng: -70.739569,
    },
  },
  {
    name: 'Santa Margarita | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -33.9963821,
      lng: -70.69268534,
    },
  },
  {
    name: 'Santa Mónica | Garces Fruit',
    id: '62ae66c1b2ebc5dd2b968d00',
    location: {
      lat: -34.117905,
      lng: -70.792457,
    },
  },
];

export const GANTT = [
  {
    name: 'Almahue',
    id: '62ae66c1b2ebc5dd2b968d00',
    categories: [
      'GP2',
      'Santina',
      'Kordia',
      'Rainier',
      'Bing',
      'Lapins',
      'Skeena',
    ],
    data: [
      {
        myData: 'GP2',
        x2: Date.parse('2022-11-08'),
        x: Date.parse('2022-11-01'),
        t: 19.28,
        degreeDays: 288.42601500000006,
        median: '287.755',
        y: 0,
      },
      {
        myData: 'Santina',
        x2: Date.parse('2022-11-23'),
        x: Date.parse('2022-11-16'),
        t: 573.74,
        degreeDays: 417.5561849999999,
        median: '410.61',
        y: 1,
      },
      {
        myData: 'Kordia',
        x2: Date.parse('2022-11-27'),
        x: Date.parse('2022-11-20'),
        t: 0,
        degreeDays: 454.83668499999993,
        median: '445.96',
        y: 2,
      },
      {
        myData: 'Rainier',
        x2: Date.parse('2022-12-01'),
        x: Date.parse('2022-11-24'),
        t: 45.53,
        degreeDays: 488.7611849999999,
        median: '483.3875',
        y: 3,
      },
      {
        myData: 'Bing',
        x2: Date.parse('2022-12-05'),
        x: Date.parse('2022-11-28'),
        t: 0,
        degreeDays: 524.7901849999998,
        median: '518.3934',
        y: 4,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-12-07'),
        x: Date.parse('2022-11-30'),
        t: 1037.25,
        degreeDays: 543.7151849999998,
        median: '539.2034',
        y: 5,
      },
      {
        myData: 'Skeena',
        x2: Date.parse('2022-12-10'),
        x: Date.parse('2022-12-03'),
        t: 0,
        degreeDays: 573.3446849999998,
        median: '572.8725',
        y: 6,
      },
    ],
  },
  {
    name: 'Bodega',
    id: '62ae66c1b2ebc5dd2b968d01',
    categories: ['Santina', 'Bing', 'Regina', 'Lapins', 'Skeena'],
    data: [
      {
        myData: 'Santina',
        x2: Date.parse('2022-11-27'),
        x: Date.parse('2022-11-20'),
        t: 515.03,
        degreeDays: 414.3240500000001,
        median: '410.61',
        y: 0,
      },
      {
        myData: 'Bing',
        x2: Date.parse('2022-12-09'),
        x: Date.parse('2022-12-02'),
        t: 62.55,
        degreeDays: 521.55555,
        median: '518.3934',
        y: 1,
      },
      {
        myData: 'Regina',
        x2: Date.parse('2022-12-11'),
        x: Date.parse('2022-12-04'),
        t: 617.05,
        degreeDays: 540.46905,
        median: '537.505',
        y: 2,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-12-11'),
        x: Date.parse('2022-12-04'),
        t: 25.47,
        degreeDays: 540.46905,
        median: '539.2034',
        y: 3,
      },
      {
        myData: 'Skeena',
        x2: Date.parse('2022-12-15'),
        x: Date.parse('2022-12-08'),
        t: 0,
        degreeDays: 577.14255,
        median: '572.8725',
        y: 4,
      },
    ],
  },
  {
    name: 'El_Recurso',
    id: '62ae66c1b2ebc5dd2b968d02',
    categories: [
      'Santina',
      'Rainier',
      'Bing',
      'Sweetheart',
      'Lapins',
      'Skeena',
    ],
    data: [
      {
        myData: 'Santina',
        x2: Date.parse('2022-11-16'),
        x: Date.parse('2022-11-09'),
        t: 28.53,
        degreeDays: 413.92117333333346,
        median: '410.61',
        y: 0,
      },
      {
        myData: 'Rainier',
        x2: Date.parse('2022-11-24'),
        x: Date.parse('2022-11-17'),
        t: 7.42,
        degreeDays: 493.5741733333335,
        median: '483.3875',
        y: 1,
      },
      {
        myData: 'Bing',
        x2: Date.parse('2022-11-27'),
        x: Date.parse('2022-11-20'),
        t: 0,
        degreeDays: 525.6959233333334,
        median: '518.3934',
        y: 2,
      },
      {
        myData: 'Sweetheart',
        x2: Date.parse('2022-11-28'),
        x: Date.parse('2022-11-21'),
        t: 0,
        degreeDays: 536.3529233333335,
        median: '527.6862',
        y: 3,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-11-29'),
        x: Date.parse('2022-11-22'),
        t: 93.07,
        degreeDays: 546.3909233333335,
        median: '539.2034',
        y: 4,
      },
      {
        myData: 'Skeena',
        x2: Date.parse('2022-12-02'),
        x: Date.parse('2022-11-25'),
        t: 60.62,
        degreeDays: 576.4159233333335,
        median: '572.8725',
        y: 5,
      },
    ],
  },
  {
    name: 'Rio Claro',
    id: '62ae66c1b2ebc5dd2b968d0z',
    categories: ['Bing', 'Regina', 'Lapins'],
    data: [
      {
        myData: 'Bing',
        x2: Date.parse('2022-12-29'),
        x: Date.parse('2022-12-22'),
        t: 79.03,
        degreeDays: 526.5981400000001,
        median: '518.3934',
        y: 0,
      },
      {
        myData: 'Regina',
        x2: Date.parse('2022-12-31'),
        x: Date.parse('2022-12-24'),
        t: 497.35,
        degreeDays: 545.2311400000001,
        median: '537.505',
        y: 1,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-12-31'),
        x: Date.parse('2022-12-24'),
        t: 148.53,
        degreeDays: 545.2311400000001,
        median: '539.2034',
        y: 2,
      },
    ],
  },
  {
    name: 'Los_Encinos',
    id: '62ae66c1b2ebc5dd2b968d04',
    categories: ['Santina', 'Lapins'],
    data: [
      {
        myData: 'Santina',
        x2: Date.parse('2022-11-23'),
        x: Date.parse('2022-11-16'),
        t: 0,
        degreeDays: 415.2413400000001,
        median: '410.61',
        y: 0,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-12-09'),
        x: Date.parse('2022-12-02'),
        t: 80.9,
        degreeDays: 544.59034,
        median: '539.2034',
        y: 1,
      },
    ],
  },
  {
    name: 'El Retiro',
    id: '62ae66c1b2ebc5dd2b968d05',
    categories: ['Santina', 'Bing', 'Lapins'],
    data: [
      {
        myData: 'Santina',
        x2: Date.parse('2022-11-29'),
        x: Date.parse('2022-11-22'),
        t: 0,
        degreeDays: 414.174135,
        median: '410.61',
        y: 0,
      },
      {
        myData: 'Bing',
        x2: Date.parse('2022-12-11'),
        x: Date.parse('2022-12-04'),
        t: 0,
        degreeDays: 518.986805,
        median: '518.3934',
        y: 1,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-12-14'),
        x: Date.parse('2022-12-07'),
        t: 41.78,
        degreeDays: 545.231305,
        median: '539.2034',
        y: 2,
      },
    ],
  },
  {
    name: 'La_Esperanza',
    id: '62ae66c1b2ebc5dd2b968d06',
    categories: ['Santina', 'Lapins', 'Regina'],
    data: [
      {
        myData: 'Santina',
        x2: Date.parse('2022-11-18'),
        x: Date.parse('2022-11-11'),
        t: 96.01,
        degreeDays: 419.0108333333333,
        median: '410.61',
        y: 0,
      },
      {
        myData: 'Lapins',
        x2: Date.parse('2022-12-02'),
        x: Date.parse('2022-11-25'),
        t: 0,
        degreeDays: 544.8858333333333,
        median: '539.2034',
        y: 1,
      },
      {
        myData: 'Regina',
        x2: Date.parse('2022-12-02'),
        x: Date.parse('2022-11-25'),
        t: 0,
        degreeDays: 544.8858333333333,
        median: '537.505',
        y: 2,
      },
    ],
  },
  {
    name: 'Los Toldos',
    id: '62ae66c1b2ebc5dd2b968d07',
    categories: ['Regina', 'Sweetheart'],
    data: [
      {
        x: Date.parse('2023-01-15'),
        x2: Date.parse('2023-01-21'),
        y: 0,
        t: 0,
        myData: 'Regina',
        color: '#afcb51',
      },
      {
        x: Date.parse('2023-01-19'),
        x2: Date.parse('2023-01-25'),
        y: 1,
        t: 0,
        myData: 'Sweetheart',
        color: '#bd0823',
      },
    ],
  },
];

export const SEASONS_MOCK = [
  { label: '2012', value: '2012' },
  { label: '2013', value: '2013' },
  { label: '2014', value: '2014' },
  { label: '2015', value: '2015' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
];

export const CALIBER_OPTIONS = [
  { id: 1, label: '9', value: 9 },
  { id: 2, label: '9,5', value: 9.5 },
  { id: 3, label: '10', value: 10 },
  { id: 4, label: '10,5', value: 10.5 },
  { id: 5, label: '11', value: 11 },
  { id: 6, label: '11,5', value: 11.5 },
  { id: 7, label: '12', value: 12 },
  { id: 8, label: '12,5', value: 12.5 },
  { id: 9, label: '13', value: 13 },
];

export const VARIETY_ARRAY = [
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c62c',
    },
    value: 'Lapins',
    label: 'Lapins',
    mean: 526.4844,
    median: 539.2034,
    color: 'fdbf6f',
    minDart: 103.551566037736,
    maxDart: 463,
    minTwig: 6.1,
    maxTwig: 60,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c636',
    },
    value: 'Silvia',
    label: 'Silvia',
    maxDart: 356.453,
    maxTwig: 26.475,
    minDart: 44.16,
    minTwig: 7.536,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c631',
    },
    value: 'Royal_Dawn',
    label: 'Royal Dawn',
    mean: 340.2409,
    median: 345.6037,
    color: 'ffff99',
    maxDart: 681.17,
    maxTwig: 93,
    minDart: 223.8,
    minTwig: 13,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c63a',
    },
    value: 'Stella',
    label: 'Stella',
    mean: 46.3408,
    median: 287.755,
    color: '52be80',
    maxDart: 558.8,
    maxTwig: 67.49,
    minDart: 218.5,
    minTwig: 1.91,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c630',
    },
    value: 'Regina',
    label: 'Regina',
    mean: 552.4495,
    median: 537.505,
    color: '6a3d9a',
    maxDart: 500,
    maxTwig: 59,
    minDart: 89.87875,
    minTwig: 4.94457142857143,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c62e',
    },
    value: 'Rainier',
    label: 'Rainier',
    mean: 511.7639,
    median: 483.3875,
    color: 'ff7f00',
    maxDart: 329.16,
    maxTwig: 60.7,
    minDart: 78.032,
    minTwig: 10.027,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c641',
    },
    value: 'Tulare',
    label: 'Tulare',
    maxDart: 800,
    maxTwig: 80,
    minDart: 218.5,
    minTwig: 1.91,
  },
  {
    _id: {
      $oid: '634999d5cce295ca497b8716',
    },
    value: 'Black_Pearl',
    label: 'Black Pearl',
    maxDart: 400,
    maxTwig: 70,
    minDart: 80,
    minTwig: 10,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c624',
    },
    value: 'Cherry_Moon',
    label: 'Cherry Moon',
    mean: 314.7017,
    median: 302.72,
    color: '1f78b4',
    maxDart: 532.2,
    maxTwig: 60.44,
    minDart: 74.5,
    minTwig: 30.56,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c62a',
    },
    value: 'Kordia',
    label: 'Kordia',
    mean: 482.9189,
    median: 445.96,
    color: 'fb9a99',
    maxDart: 678.942,
    maxTwig: 78.0875,
    minDart: 24.42,
    minTwig: 0,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c640',
    },
    value: 'Symphony',
    label: 'Symphony',
    maxDart: 465.6,
    maxTwig: 14.6938333333333,
    minDart: 184.284,
    minTwig: 0.993068181818182,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c639',
    },
    value: 'Staccato',
    label: 'Staccato',
    mean: 518.1511,
    median: 518.3934,
    color: 'e74c3c',
    maxDart: 384.418,
    maxTwig: 23.95,
    minDart: 103.733,
    minTwig: 6.969,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c63f',
    },
    value: 'Sweetheart_2',
    label: 'Sweetheart 2',
    maxDart: 502.3,
    maxTwig: 18.568,
    minDart: 369.6,
    minTwig: 10.98,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c63d',
    },
    value: 'Sweet_Aryana',
    label: 'Sweet Aryana',
    maxDart: 400,
    maxTwig: 70,
    minDart: 80,
    minTwig: 10,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c63b',
    },
    value: 'Sue',
    label: 'Sue',
    mean: 475.4355,
    median: 475.4355,
    color: 'b3de69',
    maxDart: 381.95,
    maxTwig: 56,
    minDart: 182.6,
    minTwig: 11,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c634',
    },
    value: 'Santina',
    label: 'Santina',
    mean: 423.0229,
    median: 410.61,
    color: 'b15928',
    maxDart: 467.171,
    maxTwig: 72.4,
    minDart: 82.84,
    minTwig: 8.05,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c637',
    },
    value: 'Skeena',
    label: 'Skeena',
    mean: 562.8197,
    median: 572.8725,
    color: 'fb8072',
    maxDart: 580.2,
    maxTwig: 74.0985,
    minDart: 28,
    minTwig: 1.45,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c621',
    },
    value: 'Brooks',
    label: 'Brooks',
    mean: 332.4399,
    median: '',
    color: 'a0785a',
    maxDart: 393.52,
    maxTwig: 37.613,
    minDart: 4.83000000000003,
    minTwig: 0,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c62d',
    },
    value: 'New_Star',
    label: 'New Star',
    mean: '',
    median: '',
    color: '',
    maxDart: 310.04,
    maxTwig: 60.161,
    minDart: 192.88,
    minTwig: 50.19,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c642',
    },
    value: 'Van',
    label: 'Van',
    mean: 332.4399,
    median: '',
    color: 'f42069',
    maxDart: 882.05,
    maxTwig: 73.77,
    minDart: 196.771,
    minTwig: 10.744,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c62f',
    },
    value: 'Rainier_2',
    label: 'Rainier 2',
    mean: 457.5213,
    median: 457.5213,
    color: 'cab2d6',
    maxDart: 400,
    maxTwig: 70,
    minDart: 80,
    minTwig: 10,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c620',
    },
    value: 'Bing',
    label: 'Bing',
    mean: 518.1511,
    median: 518.3934,
    color: 'a6cee3',
    maxDart: 813.0605,
    maxTwig: 112,
    minDart: 134.7,
    minTwig: 9.025,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c628',
    },
    value: 'Glen_Red',
    label: 'Glen Red',
    mean: 332.4399,
    median: 329.285,
    color: 'b2df8a',
    maxDart: 710.4,
    maxTwig: 142.55,
    minDart: 260,
    minTwig: 25.7800023027597,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c635',
    },
    value: 'Sentennial',
    label: 'Sentennial',
    mean: 511.7639,
    median: 483.3875,
    color: '884ea0',
    maxDart: 400,
    maxTwig: 70,
    minDart: 80,
    minTwig: 10,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c625',
    },
    value: 'Coral',
    label: 'Coral',
    mean: '',
    median: '',
    color: '',
    maxDart: 420.64,
    maxTwig: 76.7,
    minDart: 228,
    minTwig: 14.12,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c638',
    },
    value: 'Somerset',
    label: 'Somerset',
    mean: 475.4355,
    median: 475.4355,
    color: 'b3de69',
    maxDart: 432.4,
    maxTwig: 82.7,
    minDart: 318.4,
    minTwig: 40.6,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c633',
    },
    value: 'Royal_Rainier',
    label: 'Royal Rainier',
    mean: '',
    median: '',
    color: '',
    maxDart: 696.15,
    maxTwig: 197.05,
    minDart: 396.2,
    minTwig: 111.67,
  },
  {
    _id: {
      $oid: '63499ad7cce295ca497b8717',
    },
    value: 'Frisco',
    label: 'Frisco',
    mean: 526.4844,
    median: 539.2034,
    color: 'f7dc6f ',
    maxDart: 400,
    maxTwig: 70,
    minDart: 80,
    minTwig: 10,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c629',
    },
    value: 'GP2',
    label: 'GP2',
    mean: 46.3408,
    median: 287.755,
    color: '33a02c',
    maxDart: 315.95,
    maxTwig: 127,
    minDart: 69,
    minTwig: 34.2,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c63c',
    },
    value: 'Summit',
    label: 'Summit',
    maxDart: 0,
    maxTwig: 0,
    minDart: 0,
    minTwig: 0,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c626',
    },
    value: 'Cristalina',
    label: 'Cristalina',
    mean: '',
    median: '',
    color: '',
    maxDart: 759.25,
    maxTwig: 78.25,
    minDart: 514,
    minTwig: 25,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c632',
    },
    value: 'Royal_Lee',
    label: 'Royal Lee',
    mean: '',
    median: '',
    color: '',
    maxDart: 469.76,
    maxTwig: 91.2,
    minDart: 215.2,
    minTwig: 37.8,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c63e',
    },
    value: 'Sweetheart',
    label: 'Sweetheart',
    mean: 558.0974,
    median: 527.6862,
    color: 'bc80bd',
    maxDart: 633.116666666667,
    maxTwig: 69.91,
    minDart: 195.463366336634,
    minTwig: 6.87317491749175,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c62b',
    },
    value: 'Lambert',
    label: 'Lambert',
    mean: 588.6108,
    median: 593.8575,
    color: 'e31a1c',
    maxDart: 619,
    maxTwig: 84.933,
    minDart: 619,
    minTwig: 29.598,
  },
  {
    _id: {
      $oid: '631890ecd7e9006f5bf2c627',
    },
    value: 'Ensayos',
    label: 'Ensayos',
    maxDart: 400,
    maxTwig: 70,
    minDart: 80,
    minTwig: 10,
  },
];
export const SCHEDULE_TABLE = [
		{
			"technicBoss": "Nombre del Jefe Técnico 1",
			"group": "Nombre del Grupo 1",
			"agrobusiness": "Nombre de la Razón Social 1",
			"orchard": "Nombre del Huerto 1",
			"specie": "Tipo de Especie 1",
			"productionLine": "Nombre de la Línea de Producción 1",
			"variety": "Tipo de Variedad 1",
			"totalKilos": 10,
			"monday": 0,
			"tuesday": 0,
			"wednesday": 10,
			"thursday": 0,
			"friday": 0,
			"saturday": 0,
			"sunday": 0,
			"totalWeek1": 10,
			"totalWeek2": 0,
			"totalWeek3": 0,
			"totalWeek1History": 0,
			"totalWeek2History": 20,
			"totalWeek3History": 0,
			"totalWeeks": 10,
			"totalWeeksHistory": 20
		}
];

// MODULOS DISPONIBLES PARA CAROZO
export const MODULE_CAROZO = [
  '000000000000000000000000',
  '64234ac2f7b53d43704e067b',
  '6422e4d13f15d6fddadfcb8a',
  '651eab668dd2652ad27fc094',
];

export const COUNTING_TYPES_SPECIE = [
  {
    specie: 'Cerezo',
    type: [
      {
        label: 'Dardos y Ramillas',
        value: 'Conteo_Centro_Frutal',
        structure: ['dart', 'twig'],
      },
      { label: 'Frutos', value: 'Conteo_Frutal' },
    ],
  },
  {
    specie: 'Ciruelo',
    type: [
      {
        label: 'Dardos, Ramillas y Brindillas',
        value: 'Conteo_Centro_Frutal',
        structure: ['dart', 'twig', 'brindilla'],
      },
      { label: 'Frutos', value: 'Conteo_Frutal' },
    ],
  },
  {
    specie: 'Nectarines',
    type: [
      {
        label: 'Dardos y Ramillas',
        value: 'Conteo_Centro_Frutal',
        structure: ['dart', 'twig'],
      },
      { label: 'Frutos', value: 'Conteo_Frutal' },
    ],
  },
];

export const CAROZO_VARIETIES = [
  'Ciruelas',
  'Duraznos',
  'Nectarines',
  'Damasco',
];
