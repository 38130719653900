import React from 'react';
import { CardHeader } from '@mui/material';
import classes from './CardHeaderWidget.module.scss';

const CardHeaderWidget = (props) => {
	const { subheader, title } = props;
	return (
		<CardHeader
			subheader={subheader}
			title={title}
			className={classes.CardHeader}
			titleTypographyProps={{
				variant: 'h4',
				className: classes.titleStyle,
			}}
		/>
	);
};

export default CardHeaderWidget;
