import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { numberFormat, numberFormatDecimals } from '../../../helpers';
import { BsCol, BsRow } from '../../../layouts/components';

const useStyles = makeStyles((theme) => ({
  fieldBorder: {
    borderTop: 'none',
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #dee2e6 !important',
    },
  },
  fruitsDartEstimateBg: {
    fontWeight: '900',
    backgroundColor: theme.palette.grey[200],
  },
  tablesContainer: {
    '& table tr th': {
      [theme.breakpoints.down('sm')]: {
        width: '70%',
      },
    },
    '& table tr.group1': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const InfoBox = ({ infoIntelligence }) => {
  const classes = useStyles();

  console.log(infoIntelligence, '--------infoIntelligence');

  return (
    <div className={classes.tablesContainer}>
      <Typography variant='h5' gutterBottom>
        Información Basal
      </Typography>
      <BsRow>
        <BsCol class='col-lg-6'>
          <table className='table mb-0'>
            <tbody>
              <tr className='group1'>
                <th scope='row'>Variedad</th>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.variety
                    : '-'}
                </td>
              </tr>
              <tr className='group1'>
                <th scope='row'>Patrón</th>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.rootStock
                    : '-'}
                </td>
              </tr>
              <tr className='group1'>
                <th scope='row'>Superficie</th>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.surface
                    : '-'}
                </td>
              </tr>
              <tr className='group1'>
                <th scope='row'>Plantas/ha</th>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.plantsHa
                    : '-'}
                </td>
              </tr>
              <tr className='group1'>
                <th scope='row'>Edad</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(infoIntelligence.information?.age, 2)
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Porciones Frío Mayo</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfValueMay,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Porciones Frío Junio</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfValueJune,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Porciones Frío Julio</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfValueJuly,
                        2
                      )
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </BsCol>
        <BsCol class='col-lg-6'>
          <table className='table'>
            <tbody>
              <tr>
                <th scope='row'>Dardos</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.dart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Ramillas</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.twig,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Yemas/Dardo</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.budDart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row' className={classes.fieldBorder}>
                  Flores/Yema/Dardo
                </th>
                <td className={classes.fieldBorder}>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersBudDart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Flores/dardo</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersDart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th scope='row'>Flores/ramilla</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersTwig,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              {/* <tr>
								<th scope="row">
									Frutos/Dardo (conteo centros frutales)
								</th>
								<td className={classes.fruitsDartEstimateBg}>
									{fruitsDartEstimate
										? numberFormatDecimals(
												fruitsDartEstimate,
												2
										  )
										: '-'}
								</td>
							</tr> */}

              {/* <tr>
                <th scope='row'>Porciones Frío Nuevo</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfValue_Marzo,
                        2
                      )
                    : '-'}
                </td>
              </tr> */}
              <tr className='group1'>
                <th scope='row'>Arginina</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.arginine,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr className='group1'>
                <th scope='row'>Almidón</th>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.starch,
                        2
                      )
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </BsCol>
      </BsRow>
    </div>
  );
};

export default InfoBox;
