import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import bellCurve from 'highcharts/modules/histogram-bellcurve';
import { findActualYear, numberFormat } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 3),
    width: '95%',
    height: '65vh',
    [theme.breakpoints.up('md')]: {
      width: '55%',
      height: '75vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '95vh',
    },
    margin: 'auto',
    // marginTop: '5vh',
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '90%',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
}));

const ModalGraph = ({
  closeAction,
  optionsFlower,
  optionsFruitset,
  optionsWeight,
  optionsPerformance,
  optionsColdPortions,
  optionsFruitSetFlowers,
}) => {
  const theme = useTheme();

  const classes = useStyles();

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12'>
              <Typography variant='h5' gutterBottom>
                Data Histórica
              </Typography>
              {/* GRÁFICOS */}
              <BsRow class='mt-md-5'>
                <BsCol class='col-md-6'>
                  <Typography variant='h5' gutterBottom>
                    Flores (M./ha)
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsFlower}
                  />
                </BsCol>
                <BsCol class='col-md-6'>
                  <Typography variant='h5' gutterBottom>
                    Cuaja (%)
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsFruitset}
                  />
                </BsCol>
                <BsCol class='col-md-6 mt-md-3'>
                  <Typography variant='h5' gutterBottom>
                    Calibre (grs.)
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsWeight}
                  />
                </BsCol>
                <BsCol class='col-md-6 mt-md-3'>
                  <Typography variant='h5' gutterBottom>
                    Rendimiento (m. kg/ha)
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsPerformance}
                  />
                </BsCol>
                <BsCol class='col-md-6 mt-md-3'>
                  <Typography variant='h5' gutterBottom>
                    Porciones Frío
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsColdPortions}
                  />
                </BsCol>
                <BsCol class='col-md-6 mt-md-3'>
                  <Typography variant='h5' gutterBottom>
                    Cuaja/Flores
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsFruitSetFlowers}
                  />
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalGraph;
