import React from 'react';
import PropTypes from 'prop-types';
import classes from './MinimalNoTopbar.module.scss';

const Minimal = (props) => {
	const { children } = props;

	return (
		<div className={classes.root}>
			<main className={classes.content}>{children}</main>
		</div>
	);
};

Minimal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Minimal;
