import React, { useContext, useEffect } from 'react';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { Context } from '../../context/Context';
import { FiltersContext } from '../../context/filters/FiltersContext';
import classes from './Maintenance.module.scss';

const Maintenance = ({ status, dataMaintenance }) => {
  // const preventDefault = (event) => event.preventDefault();

  const { logOutFilters } = useContext(FiltersContext);

  const { logout } = useContext(Context);

  useEffect(() => {
    logOutFilters();
    logout();
  }, []);

  console.log('Maintenance', status, dataMaintenance);

  return (
    <div className={classes.root}>
      <BsContainer fluid>
        <BsRow class='justify-content-md-center'>
          <BsCol class='col-lg-8 mt-4'>
            <div className={classes.content}>
              <Typography variant='h1' gutterBottom>
                Plataforma en Mantenimiento.
              </Typography>
              <Typography variant='h3' gutterBottom>
                {dataMaintenance.subject}
              </Typography>
              <Typography variant='subtitle1' gutterBottom>
                {dataMaintenance.description}
              </Typography>
              {dataMaintenance.dateStart && (
                <Typography variant='subtitle1'>
                  <b>Fecha de inicio:</b> {dataMaintenance.dateStart}
                </Typography>
              )}
              {dataMaintenance.dateEnd && (
                <Typography variant='subtitle1'>
                  <b>Fecha de término:</b> {dataMaintenance.dateEnd}
                </Typography>
              )}
              <Typography variant='subtitle1' className='mt-3'>
                Cuando la Plataforma esté operativa podrá hacer click en el
                siguiente enlace{' '}
                {status ? (
                  <span style={{ fontStyle: 'italic' }}>Link inactivo</span>
                ) : (
                  <Link color='secondary' component={RouterLink} to='/'>
                    click aquí
                  </Link>
                )}{' '}
                para regresar al inicio.
              </Typography>
              {/* <img
                alt='Under development'
                className={classes.image}
                src='/images/undraw_page_not_found_su7k.svg'
              /> */}
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default Maintenance;
