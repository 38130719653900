import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Card, CardContent, Divider, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	SearchBasicTable,
	ModalWidget,
	ModalWidgetDetailTable,
	DataTableWidget,
	DialogDelete,
	DialogState,
	IconActionTableDelete,
	IconActionTableState,
	IconActionTableDetail,
	BreadCrumbs,
	CardHeaderWidget,
} from '../../../components';
import {
	// handleActionDetail,
	handleActionState,
	handleActionDelete,
	concatObjectConfigDatatable,
	numberFormatDecimals,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { useHistory } from 'react-router-dom';

// const CONTRACTORS = [
// 	{
// 		date: '21-11-2022',
// 		user: 'Usuario Plataforma',
// 		payment: 300000,
// 	},
// 	{
// 		date: '22-11-2022',
// 		user: 'Usuario Plataforma',
// 		payment: 400000,
// 	},
// 	{
// 		date: '23-11-2022',
// 		user: 'Usuario Plataforma',
// 		payment: 100000,
// 	},
// ];

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
}));

const HarvestList = () => {
	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	let history = useHistory();

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'orchard',
		option: 'asc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [rowToHandleState, setRowToHandleState] = useState('');

	const [busyState, setBusyState] = useState(false);

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	// useEffect(() => {
	// 	setTableDataState(CONTRACTORS);
	// 	setCountState(CONTRACTORS.length);
	// }, []);

	const columnsConfig = useMemo(
		() => [
			{
				name: 'Fecha',
				selector: (row) => row.date,
				// format: (row) =>
				// 	row.dateHarvest
				// 		? dateTimeParse(row.dateHarvest)
				// 		: '--',
				sortField: 'date',
				sortable: true,
				id: 1,
			},
			{
				name: 'Huerto',
				selector: (row) => row.orchard,
				sortField: 'orchard',
				sortable: false,
				id: 2,
			},
			{
				name: 'Responsable',
				selector: (row) => row.user.name,
				sortField: 'user',
				sortable: false,
				id: 3,
			},
			{
				name: 'Monto Pagado',
				selector: (row) => row.totalAmount,
				sortField: 'totalAmount',
				format: (row) => numberFormatDecimals(row.totalAmount, 0),
				// hide: 'md',
				sortable: false,
				id: 4,
			},
		],
		[]
	);

	const redirect = (link, type, orchard) => {
		history.push({
			pathname: link,
			state: {
				workers: false,
				orchard: orchard,
				button: type,
			},
		});
	};

	const showReport = (type, orchard) => {
		redirect('/harvest-report', type, orchard);
	};

	const actionsConfig = [
		{
			name: 'Acciones',
			width: '190px',
			cell: (row) => (
				<div className="actionColumn">
					<IconActionTableDetail
						handleActionDetail={() => {
							console.log('---------------> row', row);
							const html = row;
							showReport(false, html);
						}}
					/>
					{row.delete && (
						<IconActionTableDelete
							handleActionDelete={() => DialogDeleteHandler(row)}
						/>
					)}
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);

	async function queryData(page, sort = sortState) {
		setLoadingDataTableState(true);
		try {
			let objQuery = {
				page: page,
				limit: limitState,
				sort: sort ? sort : sortState,
				// filter: {
				// 	orchard: 'Bellavista',
				// },
			};

			// console.log(objQuery, '----------------------la query que va');

			const response = await axiosContext.authAxios.post(
				'v1/traceability/end-worker-day-list',
				objQuery
			);

			const { data, status } = response;

			// console.log(response, '<-----response query');

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				console.log('eeeeeeee', data);
				// setCountState(data.count);
				setTableDataState(data);
				setPageState(page);
				setSortState(sort);
				setBusyState(false);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setBusyState(false);
			setLoadingDataTableState(false);
			console.log('-----------', err);
		}
	}

	const getQueryCount = async () => {
		let objQueryCount = {
			// filter: {
			// 	orchard: 'Bellavista',
			// },
		};

		try {
			const response = await axiosContext.authAxios.post(
				'v1/traceability/end-worker-day-count',
				objQueryCount
			);

			console.log(response, '<-----response query count');

			if (response.status === 200) {
				// console.log(response.data, "<-------response.data")
				setCountState(response.data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		}
	};

	const DialogDeleteHandler = (row) => {
		setRowToHandleState(row);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			console.log(objDel, '----------------------la query que va');

			const responseDel = await axiosContext.authAxios.post(
				'v1/traceability/end-worker-day-delete',
				objDel
			);

			console.log(responseDel, '<-----response query');

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
				setLoadingDeleteState(false);
				setSuccessDeleteState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	//const searchTool = false;

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({ key: nameColumn, option: sortDirection });
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/traceability/end-worker-day-list',
				objQuery
			);

			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (data.data) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setCountState(data.count);
				setTableDataState(data.data);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	useEffect(() => {
		queryData(pageState);
		getQueryCount();
	}, []);

	// console.log(usersDataState,"la data");

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.harvest.list.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div style={{ minHeight: '300px' }}>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										sortField="1"
										filteredItems={tableDataState}
										// subHeaderComponentMemo={subHeaderComponentMemo}
										loading={loadingDataTableState}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Cosechas"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								<DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default HarvestList;
