import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import clsx from 'clsx';
import { addDays, subDays } from 'date-fns';
import _, { filter, reverse, set } from 'lodash';
import { useErrorHandler } from 'react-error-boundary';
import { useContext, useRef, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../../components';
import { FiltersContext } from '../../../context';
import {
  dateFormatIso,
  numberFormatDecimals,
  getOrchardsBySpecie,
} from '../../../helpers';

import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import './styles.css';
import MOCKDATA from './data.json';

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  ClientSideRowModelModule,
  RangeSelectionModule,
  MenuModule,
  MultiFilterModule,
  MasterDetailModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  RowGroupingModule,
]);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  subtitleDash: {
    fontSize: 17 + '!important',
    fontWeight: 700 + '!important',
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
  rootDayWeather: {
    flexGrow: 1,
  },
  dayWeatherBox: {
    backgroundColor: 'lightblue',
    padding: 6,
    textAlign: 'center',
    borderRadius: 6,
  },
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
  },
  dividerHeight: {
    minHeight: '70px',
  },
  progressBoxStyle: {
    '& h6': {
      textAlign: 'center',
      lineHeight: '20px',
    },
    '& h6.percentage': {
      fontSize: 30,
    },
  },
  yearDataStyle: {
    '& td': {
      fontSize: 14,
    },
    '& i': {
      fontSize: 10,
    },
  },
  varietyTableStyle: {
    '& td': {
      fontSize: 15,
    },
    '& thead': {
      backgroundColor: theme.palette.grey[50],
      '& th': {
        paddingTop: 10,
      },
    },
  },
}));

const EstimateTable = () => {
  // let history = useHistory();

  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { moduleUser, userOrchard, userClient } = useContext(Context);

  const errorHandler = useErrorHandler();

  const {
    state: { userOrchard_filter, specie_filter },
    addFilter,
  } = useContext(FiltersContext);

  // console.log(userOrchard, userClient, userOrchard_filter, '------');

  const clientValue = userClient?.value || 'Garces_Fruit';

  const moduleOrchardFilter = 'statusDashboard';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [loadingFiltersState] = useState(false);
  // console.log(userOrchard_filter, '-----userOrchard_filter');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);
  // const [dashDataState, setDashDataState] = useState(null);
  const [jsonDataState, setJsonDataState] = useState(null);
  const [noDataState, setNoDataState] = useState(false);

  // tabla ag-grid

  const [rowDataOrchard, setRowDataOrchard] = useState([]);
  const [rowDataVarietyInvernal, setRowDataVarietyInvernal] = useState([]);
  const [rowDataVarietyFrutal, setRowDataVarietyFrutal] = useState([]);
  const [visibleColumnsState, setVisibleColumnsState] = useState([]);
  const [visibleColumnsStateTable2, setVisibleColumnsStateTable2] = useState(
    []
  );
  const [visibleColumnsStateTable3, setVisibleColumnsStateTable3] = useState(
    []
  );
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  const gridApiRef = useRef(null);

  // const [fullScreenState, setFullScreenState] = useState(false);
  const [fullScreenStateOrchard, setFullScreenStateOrchard] = useState(false);
  const [fullScreenStateVarietyInvernal, setFullScreenStateVarietyInvernal] =
    useState(false);
  const [fullScreenStateVarietyFrutal, setFullScreenStateVarietyFrutal] =
    useState(false);

  const [styleOrchardState, setStyleOrchardState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
    // marginBottom: 45,
  });

  const [styleVarietyInvernalState, setStyleVarietyInvernalState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
  });
  const [styleVarietyFrutalState, setStyleVarietyFrutalState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
  });

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const onSubmit = async (values) => {
    setLoadingDashDataState(true);

    // console.log(values, '·-----asi llega');
    setJsonDataState(null);
    // setDashDataState(null);
    setNoDataState(false);

    let orchardFilter = userOrchard;

    if (values.orchard) {
      orchardFilter = [values?.orchard?.value];
    }

    // TODO: liberar esto

    console.log(values, '--------------------------values');

    const dataQuery = {
      // orchard: values?.orchard?.value,
      orchards: orchardFilter,
      clientValue: clientValue,
      specieValue: specie_filter,
      // orchard: '',
      // orchards: ['Almahue', 'Bodega', 'San_Hernan'],
      // clientValue: 'Garces_Fruit',
    };

    try {
      const response = await axiosContext.authAxios.post(
        '/v1/dashboard/get-dashboard-rend-tables',
        dataQuery
      );
      const { data, status } = response;

      console.log(
        data,
        '------------------------------------------DATA endpoint'
      );

      if (status === 200) {
        if (data.orchards?.length) {
          // setDashDataState(data);
          // const orchardRowData = processDataRowTableOrchard(MOCKDATA);
          // setRowDataOrchard(orchardRowData);
          const orchardRowData = processDataRowTableOrchard(data);
          setRowDataOrchard(orchardRowData);

          const varietyRowData = processDataRowTableVarietyInvernal(data);
          setRowDataVarietyInvernal(varietyRowData);

          const varietyFrutalRowData = processDataRowTableVarietyFrutal(data);
          setRowDataVarietyFrutal(varietyFrutalRowData);

          setVisibleColumnsState(
            processDataColumnsOrchard(data.list_estimate_by_orchard)
          );
          setVisibleColumnsStateTable2(
            processDataColumnsVarietyInvernal(
              data.list_estimate_by_variety_invernal
            )
          );
          setVisibleColumnsStateTable3(
            processDataColumnsVarietyFrutal(
              data.list_estimate_by_variety_frutal
            )
          );
          // setVisibleColumnsStateTable2(processDataColumnsVariety(MOCKDATA.list_estimate_by_variety));
        } else {
          setJsonDataState([]);
          setNoDataState(true);
        }
      }
      setLoadingDashDataState(false);
    } catch (error) {
      // console.log(error);
      // función del boundary, pasa el error
      // se aplica en los cach
      errorHandler(error);
      setLoadingDashDataState(false);
    }
  };

  // Funcionamiento tabla
  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const localeText = AG_GRID_LOCALE_ES;

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      // {
      //   id: 'custom',
      //   labelDefault: 'Personalizadas',
      //   labelKey: 'customStats',
      //   iconKey: 'columns',
      //   toolPanel: customPanel,
      //   toolPanelParams: {},
      // },

      // {
      //     id: 'filters',
      //     labelDefault: 'Filters',
      //     labelKey: 'filters',
      //     iconKey: 'filter',
      //     toolPanel: 'agFiltersToolPanel',
      //     minWidth: 180,
      //     maxWidth: 400,
      //     width: 250
      // }
    ],
    position: 'right',
    defaultToolPanel: 'customPanel',
  };

  // Funciones para procesar los datos de la tabla
  const processDataRowTableOrchard = (data) => {
    const { list_estimate_by_orchard } = data;
    const rows = [];

    list_estimate_by_orchard.forEach((item) => {
      const row = {
        id: `${item.orchard}_${item.surface}_${item.yieldSource}_${item.yieldSourceTotal}`,
        orchard: item.orchard,
        variety: item.variety,
        surface: item.surface,
        yieldSource: item.yieldSource,
        yieldSourceTotal: item.yieldSourceTotal,
        yieldTotalEstimateUtilsInvernalDelivery:
          item.yieldTotalEstimateUtilsInvernalDelivery,
        yieldEstimateCounterFruitsTotalDelivery:
          item.yieldEstimateCounterFruitsTotalDelivery,
        yieldEstimateIndustryTotalDelivery:
          item.yieldEstimateIndustryTotalDelivery,
      };

      rows.push(row);
    });
    // console.log(rows, '<<<<<--------------rowTableOrchard');
    return rows;
  };
  const processDataRowTableVarietyInvernal = (data) => {
    const { list_estimate_by_variety_invernal } = data;
    const rows = [];

    list_estimate_by_variety_invernal.forEach((item) => {
      const row = {
        id: `${item.orchard}_${item.variety}_${item.surface}_${item.cc}_${item.quarter}_${item.rootStock}`,
        orchard: item.orchard,
        cc: item.cc,
        quarter: item.quarter,
        variety: item.variety,
        rootStock: item.rootStock,

        pollinator: item.pollinator,
        surface: item.surface,
        plantsHa: item.plantsHa,
        dartPrePoda: item.dartPrePoda,
        twigPrePoda: item.twigPrePoda,
        flowersDart: item.flowersDart,
        budDart: item.budDart,
        flowersTwig: item.flowersTwig,
        flowersHaPrePoda: item.flowersHaPrePoda,
        dartPostPoda: item.dartPostPoda,
        twigPostPoda: item.twigPostPoda,
        disbudding: item.disbudding,
        flowersHaPostPoda: item.flowersHaPostPoda,
        fruitSet: item.fruitSet,
        caliber: item.caliber,
        yieldEstimateInvernal: item.yieldEstimateInvernal,
        yieldEstimateInvernalTotal: item.yieldEstimateInvernalTotal,
        deliveryPercentage: item.deliveryPercentage,
        yieldEstimateIndustryTotalDelivery:
          item.yieldEstimateIndustryTotalDelivery,
      };

      rows.push(row);
    });
    console.log(rows, '<<<<<--------------rowsTableVarietyInvernal');
    return rows;
  };
  const processDataRowTableVarietyFrutal = (data) => {
    const { list_estimate_by_variety_frutal } = data;
    const rows = [];

    list_estimate_by_variety_frutal.forEach((item) => {
      const row = {
        id: `${item.orchard}_${item.variety}_${item.surface}_${item.cc}_${item.quarter}_${item.rootStock}`,
        orchard: item.orchard,
        cc: item.cc,
        quarter: item.quarter,
        variety: item.variety,
        rootStock: item.rootStock,

        pollinator: item.pollinator,
        surface: item.surface,
        plantsHa: item.plantsHa,
        dartPostPoda: item.dartPostPoda,
        fruitsDartPreRaleo: item.fruitsDartPreRaleo,
        structureCountPreRaleo: item.structureCountPreRaleo,
        plantsCountedPreRaleo: item.plantsCountedPreRaleo,
        fruitsPlantPreRaleo: item.fruitsPlantPreRaleo,
        fruitsDartPostRaleo: item.fruitsDartPostRaleo,
        structureCountPostRaleo: item.structureCountPostRaleo,
        plantsCountedPostRaleo: item.plantsCountedPostRaleo,
        fruitsPlantPostRaleo: item.fruitsPlantPostRaleo,
        caliber: item.caliber,
        yieldEstimateCounterFruits: item.yieldEstimateCounterFruits,
        yieldEstimateCounterFruitsTotalDelivery:
          item.yieldEstimateCounterFruitsTotalDelivery,
        deliveryPercentage: item.deliveryPercentage,
        fruitsArmPreRaleo: item.fruitsArmPreRaleo,
        fruitsArmPostRaleo: item.fruitsArmPostRaleo,
        yieldEstimateIndustryTotalDelivery:
          item.yieldEstimateIndustryTotalDelivery,
      };

      rows.push(row);
    });
    console.log(rows, '<<<<<--------------rowsTableVarietyFrutal');
    return rows;
  };

  // Funciones para procesar las columnas de la tabla
  const processDataColumnsOrchard = () => {
    const columnsDef = [
      {
        headerName: 'Huerto',
        field: 'orchard',
        editable: false,
        suppressFillHandle: true,
        filter: 'agMultiColumnFilter',
        suppressMovable: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Variedad',
        field: 'variety',
        editable: false,
        // suppressFillHandle: true,
        filter: 'agMultiColumnFilter',
        // suppressMovable: true,
        rowGroup: true,
        // hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Kg/Ha',
        field: 'yieldSource',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Superficie',
        field: 'surface',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 2),
      },

      {
        headerName: 'Rend. Total Invernal',
        field: 'yieldTotalEstimateUtilsInvernalDelivery',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rend. Total Primaveral',
        field: 'yieldEstimateCounterFruitsTotalDelivery',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rend. Industria',
        field: 'yieldEstimateIndustryTotalDelivery',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Kilos Totales',
        field: 'yieldSourceTotal',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
    ];

    return columnsDef;
  };

  const processDataColumnsVarietyInvernal = () => {
    const columnsDef = [
      {
        headerName: 'Huerto',
        field: 'orchard',
        editable: false,
        // suppressFillHandle: true,
        filter: 'agMultiColumnFilter',
        // suppressMovable: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Variedad',
        field: 'variety',
        editable: false,
        // suppressFillHandle: true,
        filter: 'agMultiColumnFilter',
        // suppressMovable: true,
        rowGroup: true,
        // hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Cc',
        field: 'cc',
        editable: false,
        filter: 'agMultiColumnFilter',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Cuartel',
        field: 'quarter',
        editable: false,
        filter: 'agMultiColumnFilter',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },

      {
        headerName: 'Portainjerto',
        field: 'rootStock',
        editable: false,
        filter: 'agMultiColumnFilter',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Polinizador',
        field: 'pollinator',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'text',
        // hide: true,
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Superficie',
        field: 'surface',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 2),
      },
      {
        headerName: 'Plantas/Ha',
        field: 'plantsHa',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Dardos Pre Poda',
        field: 'dartPrePoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Ramillas Pre Poda',
        field: 'twigPrePoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Flores Dardo',
        field: 'flowersDart',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Yemas Dardo',
        field: 'budDart',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Flores Ramilla',
        field: 'flowersTwig',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Flores/Ha Pre Poda',
        field: 'flowersHaPrePoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Dardos Post Poda',
        field: 'dartPostPoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Ramillas Post Poda',
        field: 'twigPostPoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Desyeme',
        field: 'disbudding',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Flores/Ha Post Poda',
        field: 'flowersHaPostPoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos',
        field: 'fruitSet',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Calibre',
        field: 'caliber',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: '% de Entrega',
        field: 'deliveryPercentage',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        aggFunc: 'avg',
        cellDataType: 'number',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rend. Industria',
        field: 'yieldEstimateIndustryTotalDelivery',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rendimiento Estimado',
        field: 'yieldEstimateInvernal',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rendimiento Total',
        field: 'yieldEstimateInvernalTotal',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
    ];
    return columnsDef;
  };
  const processDataColumnsVarietyFrutal = () => {
    const columnsDef = [
      {
        headerName: 'Huerto',
        field: 'orchard',
        editable: false,
        // suppressFillHandle: true,
        filter: 'agMultiColumnFilter',
        // suppressMovable: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Variedad',
        field: 'variety',
        editable: false,
        // suppressFillHandle: true,
        filter: 'agMultiColumnFilter',
        // suppressMovable: true,
        rowGroup: true,
        // hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Cc',
        field: 'cc',
        editable: false,
        filter: 'agMultiColumnFilter',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Cuartel',
        field: 'quarter',
        editable: false,
        filter: 'agMultiColumnFilter',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Portainjerto',
        field: 'rootStock',
        editable: false,
        filter: 'agMultiColumnFilter',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        headerName: 'Polinizador',
        field: 'pollinator',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'text',
        // hide: true,
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Superficie',
        field: 'surface',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 2),
      },
      {
        headerName: 'Plantas/Ha',
        field: 'plantsHa',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Dardos Post Poda',
        field: 'dartPostPoda',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos Dardo Pre Raleo',
        field: 'fruitsDartPreRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Estructuras Contadas Pre Raleo',
        field: 'structureCountPreRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Plantas Contadas Pre Raleo',
        field: 'plantsCountedPreRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos/Planta Pre Raleo',
        field: 'fruitsPlantPreRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos Dardo Post Raleo',
        field: 'fruitsDartPostRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Estructuras Contadas Post Raleo',
        field: 'structureCountPostRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Plantas Contadas Post Raleo',
        field: 'plantsCountedPostRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos/Planta Post Raleo',
        field: 'fruitsPlantPostRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos Lateral Pre Raleo',
        field: 'fruitsArmPreRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Frutos Lateral Post Raleo',
        field: 'fruitsArmPostRaleo',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Calibre',
        field: 'caliber',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: '% de Entrega',
        field: 'deliveryPercentage',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rend. Industria',
        field: 'yieldEstimateIndustryTotalDelivery',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rendimiento Estimado',
        field: 'yieldEstimateCounterFruits',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'avg',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
      {
        headerName: 'Rendimiento Total',
        field: 'yieldEstimateCounterFruitsTotalDelivery',
        editable: false,
        suppressFillHandle: true,
        filter: false,
        cellDataType: 'number',
        aggFunc: 'sum',
        valueFormatter: (params) => numberFormatDecimals(params.value, 1),
      },
    ];
    return columnsDef;
  };

  // Funcion para cambiar el nombre de las columnas agrupadas
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Unidad Productiva',
      minWidth: 300,
    };
  }, []);

  const autoGroupColumnDefOrchard = {
    headerName: 'Huerto', // Aquí cambias el nombre de la cabecera
    minWidth: 200,
    // cellRendererParams: {
    //   suppressCount: true, // Esto es opcional, para no mostrar el conteo de ítems en el grupo
    // },
  };

  // Funciones para el fullScreen
  const setFullScreenOrchard = (fullScreenStateOrchard) => {
    if (fullScreenStateOrchard) {
      document.body.style.overflow = 'hidden';
      setStyleOrchardState({
        height: 'calc(100vh - 70px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleOrchardState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenStateOrchard((prevState) => !prevState);
  };
  const setFullScreenVarietyInvernal = (fullScreenStateVarietyInvernal) => {
    if (fullScreenStateVarietyInvernal) {
      document.body.style.overflow = 'hidden';
      setStyleVarietyInvernalState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleVarietyInvernalState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenStateVarietyInvernal((prevState) => !prevState);
  };
  const setFullScreenVarietyFrutal = (fullScreenStateVarietyFrutal) => {
    if (fullScreenStateVarietyFrutal) {
      document.body.style.overflow = 'hidden';
      setStyleVarietyFrutalState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleVarietyFrutalState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenStateVarietyFrutal((prevState) => !prevState);
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-8'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Estimación
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        <SelectMultipleWidget2
                          errors={errors.orchard}
                          loading={loadingFiltersState}
                          control={control}
                          menuPortalTarget={document.body}
                          labeltext=''
                          // req
                          name='orchard'
                          display='vertical'
                          variant='outlined'
                          isMulti={false}
                          isClearable={true}
                          isSearchable
                          onlyOptionValue={false}
                          defaultValue=''
                          options={getOrchardsBySpecie(
                            filterDataResponseState,
                            specie_filter
                          )}
                          placeholder='Huerto'
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'flex-start',
                          paddingTop: 5,
                        }}
                      >
                        <SaveAction
                          title=' Filtrar'
                          color='secondary'
                          size='large'
                          className='roundedButton ml-md-2'
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingDashDataState}
                          success={false}
                        />
                      </div>
                    </form>
                  </BsCol>
                  <BsCol class='col-md-4 align-self-end text-right'>
                    <Typography variant='h6' gutterBottom>
                      {new Date().toLocaleDateString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        {!loadingDashDataState ? (
          <>
            <BsRow class='mt-4'>
              <BsCol class='col-md-12'>
                <Card
                  elevation={0}
                  variant='outlined'
                  sx={{ borderColor: theme.palette.grey[200] }}
                >
                  <CardContent>
                    <BsRow class=''>
                      <BsCol class='col-md-12 pb-5'>
                        <div
                          className='ag-theme-quartz'
                          style={styleOrchardState}
                        >
                          <div
                            className='p-2'
                            style={{ backgroundColor: 'white' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Estimación por huerto
                              </Typography>
                              <IconButton
                                color='primary'
                                aria-label='fullscreen-toggle'
                                onClick={() =>
                                  setFullScreenOrchard(!fullScreenStateOrchard)
                                }
                                disabled={loadingSubmitFilterState}
                              >
                                {fullScreenStateOrchard ? (
                                  <FullscreenExitIcon />
                                ) : (
                                  <FullscreenIcon />
                                )}
                              </IconButton>
                            </Box>
                          </div>
                          <AgGridReact
                            rowData={rowDataOrchard}
                            columnDefs={visibleColumnsState}
                            pagination
                            paginationPageSize={50}
                            suppressMovableColumns={true}
                            suppressDragLeaveHidesColumns={true}
                            paginationPageSizeSelector={
                              paginationPageSizeSelector
                            }
                            groupDisplayType={'singleColumn'}
                            rowGroupPanelShow={'always'}
                            localeText={localeText}
                            defaultColDef={{
                              flex: 1,
                              minWidth: 150,
                              filter: false,
                              floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                            }}
                            // quickFilterText={quickFilterText}
                            onGridReady={onGridReady}
                            // sideBar={sideBar}
                            embedFullWidthRows={true}
                            // getRowId={(params) => params.data.id}
                            grandTotalRow={'bottom'}
                            autoGroupColumnDef={autoGroupColumnDefOrchard}
                          />
                        </div>
                      </BsCol>
                    </BsRow>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
            <BsRow class='mt-4'>
              <BsCol class='col-md-12'>
                <Card
                  elevation={0}
                  variant='outlined'
                  sx={{ borderColor: theme.palette.grey[200] }}
                >
                  <CardContent>
                    <BsRow class=''>
                      <BsCol class='col-md-12 pb-5'>
                        <div
                          className='ag-theme-quartz'
                          style={styleVarietyInvernalState}
                        >
                          <div
                            className='p-2'
                            style={{ backgroundColor: 'white' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Estimación Invernal
                              </Typography>
                              <IconButton
                                color='primary'
                                aria-label='fullscreen-toggle'
                                onClick={() =>
                                  setFullScreenVarietyInvernal(
                                    !fullScreenStateVarietyInvernal
                                  )
                                }
                                disabled={loadingSubmitFilterState}
                              >
                                {fullScreenStateVarietyInvernal ? (
                                  <FullscreenExitIcon />
                                ) : (
                                  <FullscreenIcon />
                                )}
                              </IconButton>
                            </Box>
                          </div>
                          <AgGridReact
                            rowData={rowDataVarietyInvernal}
                            columnDefs={visibleColumnsStateTable2}
                            pagination
                            paginationPageSize={50}
                            // suppressMovableColumns={true}
                            // suppressDragLeaveHidesColumns={true}
                            paginationPageSizeSelector={
                              paginationPageSizeSelector
                            }
                            localeText={localeText}
                            defaultColDef={{
                              flex: 1,
                              minWidth: 150,
                              filter: false,
                              floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                              // suppressAggFuncInHeader: true,
                            }}
                            // quickFilterText={quickFilterText}
                            onGridReady={onGridReady}
                            groupDisplayType={'singleColumn'}
                            rowGroupPanelShow={'always'}
                            sideBar={sideBar}
                            embedFullWidthRows={true}
                            grandTotalRow={'bottom'}
                            autoGroupColumnDef={autoGroupColumnDef}
                            suppressAggFuncInHeader={true}
                          />
                        </div>
                      </BsCol>
                    </BsRow>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
            <BsRow class='mt-4'>
              <BsCol class='col-md-12'>
                <Card
                  elevation={0}
                  variant='outlined'
                  sx={{ borderColor: theme.palette.grey[200] }}
                >
                  <CardContent>
                    <BsRow class=''>
                      <BsCol class='col-md-12 pb-5'>
                        <div
                          className='ag-theme-quartz'
                          style={styleVarietyFrutalState}
                        >
                          <div
                            className='p-2'
                            style={{ backgroundColor: 'white' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Estimación Primaveral
                              </Typography>
                              <IconButton
                                color='primary'
                                aria-label='fullscreen-toggle'
                                onClick={() =>
                                  setFullScreenVarietyFrutal(
                                    !fullScreenStateVarietyFrutal
                                  )
                                }
                                disabled={loadingSubmitFilterState}
                              >
                                {fullScreenStateVarietyFrutal ? (
                                  <FullscreenExitIcon />
                                ) : (
                                  <FullscreenIcon />
                                )}
                              </IconButton>
                            </Box>
                          </div>
                          <AgGridReact
                            rowData={rowDataVarietyFrutal}
                            columnDefs={visibleColumnsStateTable3}
                            pagination
                            paginationPageSize={50}
                            // suppressMovableColumns={true}
                            // suppressDragLeaveHidesColumns={true}
                            paginationPageSizeSelector={
                              paginationPageSizeSelector
                            }
                            localeText={localeText}
                            defaultColDef={{
                              flex: 1,
                              minWidth: 150,
                              filter: false,
                              floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                              // suppressAggFuncInHeader: true,
                            }}
                            // quickFilterText={quickFilterText}
                            onGridReady={onGridReady}
                            groupDisplayType={'singleColumn'}
                            rowGroupPanelShow={'always'}
                            sideBar={sideBar}
                            embedFullWidthRows={true}
                            grandTotalRow={'bottom'}
                            autoGroupColumnDef={autoGroupColumnDef}
                            suppressAggFuncInHeader={true}
                          />
                        </div>
                      </BsCol>
                    </BsRow>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
          </>
        ) : (
          <BsRow class='mt-3'>
            <BsCol class='col-md-12'>
              <Skeleton animation='wave' variant='rounded' height={350} />
            </BsCol>
            <BsCol class='col-md-12 mt-3'>
              <Skeleton animation='wave' variant='rounded' height={350} />
            </BsCol>
          </BsRow>
        )}
      </BsContainer>
    </div>
  );
};
export default EstimateTable;
