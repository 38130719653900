import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import '../style.scss';
const IconActionTableEdit = (props) => {
  const { handleActionEdit } = props;
  //<IconButton aria-label="delete" onClick={() => handleActionEdit(row)}>
  return (
    <Tooltip title='Editar' arrow className='buttonIconAction'>
      <IconButton
        classes={{
          label: 'buttonContainer buttonPrimary',
        }}
        aria-label='edit'
        color='primary'
        onClick={handleActionEdit}
      >
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export default IconActionTableEdit;
