import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { subDays } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import {
  CardPaper,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import {
  alterArrayGetFields,
  alterArrayGetValues,
  arrayGetStationIds,
} from '../../../helpers';
import { tabItems } from './tabList';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';
import {
  MainGraphsDetail,
  OrchardWeatherParameters,
} from '../../../containers/Weather';
import GraphTemperature from 'containers/Weather/DetailGraphs/GraphTemperature';
import GraphEvoTranspiration from 'containers/Weather/DetailGraphs/GraphEvoTranspiration';
import GraphPrecipitation from 'containers/Weather/DetailGraphs/GraphPrecipitation';
import GraphHumidity from 'containers/Weather/DetailGraphs/GraphHumidity';
import GraphRadiation from 'containers/Weather/DetailGraphs/GraphRadiation';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const alterArrayGetValue = (array) => {
  const newArray = array.map((item) => {
    return item.value;
  });
  return newArray;
};

const WeatherDetail = (props) => {
  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { orchard_filter, orchard_id_filter, userOrchard_filter } = filterState;

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const theme = useTheme();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusWeather';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const onSubmit = async (values) => {
    console.log(values, '<--------', userOrchard_filter);

    const data = {
      orchard: values.orchard,
    };

    console.log(data, '<----data en weaher resume');

    setInfoFilterTab(data);
  };

  useEffect(() => {
    if (orchard_filter) {
      const foundOrchard = filterDataResponseState.find(
        (element) => element.value === orchard_filter
      );

      if (foundOrchard) {
        setValue('orchard', foundOrchard);
      }
    }
  }, []);

  // console.log(errors, 'z----errors');

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group ml-md-2 hide-year'>
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={filterDataResponseState}
                  placeholder='Huerto'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-8'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Detalle Huerto
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                  <BsCol class='col-md-4 align-self-end text-right'>
                    <Typography variant='h6' gutterBottom>
                      {new Date().toLocaleDateString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                    {/* <Typography
												variant="body2"
												gutterBottom
											>
												Última actualización 17:45 hrs.
											</Typography> */}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <OrchardWeatherParameters filter={infoFilterTab} />
          </BsCol>
          <BsCol class='col-md-12 mt-3'>
            <MainGraphsDetail filter={infoFilterTab} />
          </BsCol>
        </BsRow>
        {infoFilterTab ? (
          <BsRow class='gutter_10 mt-2'>
            <BsCol class='col-md-6 mb-2'>
              <CardPaper
                variant='outlined'
                sx={{
                  borderColor: theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <GraphTemperature orchard={infoFilterTab} />
                </Box>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2'>
              <CardPaper
                variant='outlined'
                sx={{
                  borderColor: theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <GraphEvoTranspiration orchard={infoFilterTab} />
                </Box>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2'>
              <CardPaper
                variant='outlined'
                sx={{
                  borderColor: theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <GraphPrecipitation orchard={infoFilterTab} />
                </Box>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2'>
              <CardPaper
                variant='outlined'
                sx={{
                  borderColor: theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <GraphHumidity orchard={infoFilterTab} />
                </Box>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2'>
              <CardPaper
                variant='outlined'
                sx={{
                  borderColor: theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <GraphRadiation orchard={infoFilterTab} />
                </Box>
              </CardPaper>
            </BsCol>
          </BsRow>
        ) : null}
      </BsContainer>
    </div>
  );
};
export default WeatherDetail;
