import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { subDays } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import {
  alterArrayGetFields,
  alterArrayGetValues,
  arrayGetStationIds,
} from '../../../helpers';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';
import { ResumeOrchard } from '../../../containers/Weather';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const alterArrayGetValue = (array) => {
  const newArray = array.map((item) => {
    return item.value;
  });
  return newArray;
};

const WeatherResume = (props) => {
  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { orchard_filter, orchard_id_filter, userOrchard_filter } = filterState;

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const theme = useTheme();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusWeather';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const onSubmit = async (values) => {
    const data = {
      orchards: values.orchard ? alterArrayGetValues(values.orchard) : null,
    };

    if (values.orchard && values.orchard.length > 0) {
      const foundOrchard = userOrchard_filter.find(
        (element) => element.value === alterArrayGetValues(values.orchard)[0]
      );

      addFilter({
        orchard_filter: foundOrchard?.value,
        orchard_id_filter: foundOrchard?.id,
        quarter_filter: null,
        cc_filter: null,
        variety_filter: null,
        rootstock_filter: null,
        idUnitProductive: null,
      });
    } else {
      addFilter({
        orchard_filter: null,
        orchard_id_filter: null,
        quarter_filter: null,
        cc_filter: null,
        variety_filter: null,
        rootstock_filter: null,
        idUnitProductive: null,
      });
    }

    setInfoFilterTab(data);
  };

  // useEffect(() => {
  //   if (orchard_filter) {
  //     // console.log(orchard_filter, '<----orchard_filter');
  //     setValue('orchard', [{ value: orchard_filter, label: orchard_filter }]);
  //   }
  // }, []);

  // console.log(errors, 'z----errors');

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group ml-md-2 hide-year'>
                {/* <SelectMultipleWidget
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext='Huerto'
                  name='orchard'
                  isSearchable
                  req={false}
                  defaultValue=''
                  options={filterDataResponseState}
                  placeholder='Seleccione Huerto/s'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={true}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={filterDataResponseState}
                  placeholder='Huerto/s'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-8'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Resumen Huertos
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                  <BsCol class='col-md-4 align-self-end text-right'>
                    <Typography variant='h6' gutterBottom>
                      {new Date().toLocaleDateString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                    {/* <Typography
												variant="body2"
												gutterBottom
											>
												Última actualización 17:45 hrs.
											</Typography> */}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <ResumeOrchard filter={infoFilterTab} />
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default WeatherResume;
