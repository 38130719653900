import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
	DataTableWidget,
	SaveAction,
	SelectMultipleWidget,
	LoadingWidget,
	TextFieldWidgetSelectController,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
} from '../../../components';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Card,
	CardContent,
	Hidden,
	Typography,
	useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import { MobileFilters } from 'containers/Library';
import viewsTexts from '../../../common/viewsTexts.json';
import {
	numberFormat,
	isNumber,
	findActualYear,
	alterArrayForSelectVariety,
	getOrchardVarieties,
	getOrchardVarietiesDeep,
	alterArrayGetFields,
	formatRut,
	getRandomId,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const initialToteArray = [{ id: 1 }, { id: 2 }];

const ContractorAdd = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	const [toteArray, setToteArray] = useState(initialToteArray);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const {
		orchard_filter,
		cc_filter,
		quarter_filter,
		variety_filter,
		season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const [valueTabState, setValueTabState] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setValueTabState(newValue);
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();

	const watchRut = watch('rut');

	const onSubmit = async (values) => {
		console.log(values, '<<<-----');
		setLoadingQueryState(true);

		// console.log(getRandomId(), '--------');

		let toteArrayQuery = [];
		let obj;
		for (var i = 1; i <= toteArray.length; i++) {
			if (values[`tote-${i}`]) {
				obj = {
					value: values[`tote-${i}`],
					label: `tote-${i}`,
					id: getRandomId(),
				};
				toteArrayQuery = [...toteArrayQuery, obj];
			}
		}

		const query = {
			// profile: +values.profile,
			email: values.email,
			rut: formatRut(values.rut),
			names: values.name,
			lastNames: values.lastName,
			// maternalLastname: values.maternalLastname,
			phoneNumber: values.phone,
			contractorTotes: toteArrayQuery,
		};

		console.log(query, 'envia a create');
		try {
			const response = await axiosContext.authAxios.post(
				'v1/traceability/contractor-create',
				query
			);
			// const bodyInfo = response.data;

			// setProfileSelectedState('');
			// console.log(response, '<-----response');

			if (response.status === 200) {
				// alert('listo');
				setSuccessQueryState(true);
			}

			reset({
				email: '',
				rut: '',
				name: '',
				lastName: '',
				phone: '',
			});
			setToteArray(initialToteArray);
			setLoadingQueryState(false);
		} catch (err) {
			setLoadingQueryState(false);
		}
	};

	useEffect(() => {
		if (watchRut && watchRut.length > 3) {
			setValue('rut', formatRut(watchRut));
		}
	}, [watchRut, setValue]);

	// console.log(errors, '<----------errors');

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.contractor.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<TextFieldWidgetText
												name="rut"
												autoComplete="rut"
												labeltext={viewsTexts.forms.rut}
												placeholder="11.111.111-1"
												InputLabelProps={{
													shrink: true,
												}}
												req={true}
												defaultValue=""
												control={control}
												errors={errors.rut}
											/>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="name"
														labeltext={
															viewsTexts.forms
																.name
														}
														req={true}
														defaultValue=""
														InputLabelProps={{
															shrink: true,
														}}
														minLength={3}
														maxLength={100}
														control={control}
														errors={errors.name}
														autoComplete="given-name"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="lastName"
														labeltext={
															viewsTexts.forms
																.lastName
														}
														InputLabelProps={{
															shrink: true,
														}}
														req={true}
														defaultValue=""
														minLength={3}
														maxLength={100}
														control={control}
														autoComplete="family-name"
														errors={errors.lastName}
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="email"
														labeltext={
															viewsTexts.forms
																.email
														}
														InputLabelProps={{
															shrink: true,
														}}
														req={true}
														defaultValue=""
														control={control}
														errors={errors.email}
														autoComplete="email"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="phone"
														type="number"
														labeltext={
															viewsTexts.forms
																.phone
														}
														placeholder="56999999999"
														InputLabelProps={{
															shrink: true,
														}}
														req={true}
														defaultValue=""
														minLength={8}
														maxLength={12}
														control={control}
														autoComplete="phone"
														errors={errors.phone}
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												{toteArray.map(({ id }) => (
													<BsCol
														class="col-md-12"
														key={id}
													>
														<TextFieldWidgetText
															name={`tote-${id}`}
															type="number"
															labeltext={`${viewsTexts.forms.tote} ${id}`}
															req={false}
															defaultValue=""
															InputLabelProps={{
																shrink: true,
															}}
															control={control}
															errors={
																errors[
																	`tote-${id}`
																]
															}
														/>
													</BsCol>
												))}
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
													/>
												</BsCol>
											</BsRow>
										</form>
									</BsCol>
								</BsRow>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default ContractorAdd;
