import React from 'react';
import PropTypes from 'prop-types';
import classes from './MinimalBackButton.module.scss';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

const Minimal = (props) => {
  const { children } = props;
  const history = useHistory();

  const handleAction = () => {
    history.push('/home');
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          background: '#203543',
        }}
      >
        <Button
          color='primary'
          variant='outline'
          style={{ margin: '10px', color: 'white' }}
          onClick={handleAction}
        >
          Volver
        </Button>
      </div>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
