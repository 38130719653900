// Built-in modules
import { useContext, useEffect, useMemo, useState } from 'react';

// External modules
import { useErrorHandler } from 'react-error-boundary';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { Delete, Edit } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';

// Internal modules
import {
  colsBudAnalysis,
  headDefultStyles,
} from '../../../helpers/tableDefinitions';
import { AxiosContext } from '../../../context/AxiosContext';
import { BsCol, BsRow } from 'layouts/components';
import { setOrderForApi, setOrderForNewApi } from 'helpers';

const endPointName = 'v1/library/bud-analysis';
const endPointEditName = 'v1/library/edit-bud-analysis';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    //   paddingRight: 27,
    // },
  },
}));

/**
 * Downloads an Excel file (.xlsx) based on the provided data array and column configuration.
 * @param {Array} array - The data array to be exported to Excel.
 * @param {Array} cols - The column configuration array.
 * @param {string} [moduleName='data'] - The name of the module or sheet in the Excel file.
 * @returns {void}
 */
function downloadXLS(array, cols, moduleName = 'data') {
  // console.log(array, '-array---', cols);

  const exportCols = cols.map((col) => {
    return col.accessorKey;
  });

  const newArray = array.map((item) => _.pick(item, exportCols));

  const exportColsHeaders = cols.map((col) => {
    return col.header;
  });

  const newHeadersArray = [exportColsHeaders];

  const ws = XLSX.utils.json_to_sheet(newArray, {
    origin: 'A2',
    skipHeader: true,
  });
  const wb = XLSX.utils.book_new();

  XLSX.utils.sheet_add_aoa(ws, newHeadersArray, { origin: 'A1' });

  XLSX.utils.book_append_sheet(wb, ws, moduleName);
  XLSX.writeFile(wb, 'reports.xlsx');
}

/**
 * Trims the values of a given object.
 * @param {Object} obj - The object to trim the values of.
 * @returns {Object} - The object with trimmed values.
 */
const trimValues = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      newObj[key] = obj[key].trim();
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

const EditTable = ({ filter: filterProp, client }) => {
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  console.log(filterProp, '----filterProp en EditTable', client);

  const theme = useTheme();

  const axiosContext = useContext(AxiosContext);

  const [checkedState, setCheckedState] = useState(false);

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'season', desc: false }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colsArray =
    client?.id === '63ff5c7343f82fe93544ec64'
      ? colsBudAnalysis
      : colsBudAnalysis.slice(0, colsBudAnalysis.length - 1);

  const columns = useMemo(() => colsArray, []);

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    // console.log(row, '------', values);

    const rowEdited = row.original;

    const { id, budDart, budDartReal, flowersDart, flowersTwig, ...others } =
      rowEdited;

    // TODO: ejecutar mutacion
    const objQuery = {
      ...others,
      budDart: values?.budDart ? parseFloat(values?.budDart) : null,
      budDartReal: values?.budDartReal ? parseFloat(values?.budDartReal) : null,
      flowersDart: values?.flowersDart ? parseFloat(values?.flowersDart) : null,
      flowersTwig: values?.flowersTwig ? parseFloat(values?.flowersTwig) : null,
    };

    // console.log(objQuery, '------------save');

    try {
      const response = await axiosContext.authAxios.post(
        endPointEditName,
        objQuery
      );

      // console.log(response, '------response');
      const { status, data: dataEdit } = response;
      if (status === 200) {
        // console.log(dataEdit, '----dataEdit');

        data[row.index] = { ...values, ...others };
        setData([...data]);
      } else {
      }
    } catch (error) {
      console.log(error, '---error post');
      errorHandler(error);
    }

    exitEditingMode();
  };

  const resetPagination = () => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(null);

      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const { orchardValue, ccValue, quarterValue,specieValue } = filterProp;

      // DEBE AGREGAR SPECIE
      const objQuery = {
        orchardValue: orchardValue,
        ccValue: ccValue,
        quarterValue: quarterValue,
        specieValue: specieValue,
        noValues: checkedState,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        // sort: setOrderForApi(sorting),
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
      };

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointName,
          objQuery
        );

        const { data, status } = response;

        // hace trim a todos los valores string de cada objeto
        data.data = data.data.map((item) => trimValues(item));

        console.log(data, '----data');

        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    if (filterProp) {
      fetchData();
    }
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filterProp,
    checkedState,
  ]);

  const headStyles = {
    paddingTop: 1.3,
    paddingBottom: 1.3,
    // first column
    '&:first-of-type': {
      backgroundColor: theme.palette.primary.main,
      borderBottom: '4px solid',
      borderColor: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
      '& .Mui-TableHeadCell-Content': {
        justifyContent: 'center',
      },
    },
  };

  const customHeadStyles = {
    sx: (theme) => ({
      ...headDefultStyles,
      ...headStyles,
    }),
  };

  const handleChangeChecked = (event) => {
    resetPagination();
    setCheckedState(event.target.checked);
  };

  const tableOptions = useMaterialReactTable({
    columns: columns,
    data: data,
    editDisplayMode: 'row',
    enableEditing: true,
    enableGrouping: true,
    onEditingRowSave: handleSaveRow,
    initialState: {
      showColumnFilters: true,
    },
    // state
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      // densidad de las filas
      density: 'comfortable',
    },
    // cabecera de botones
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          marginRight: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip
          title='Permite dejar solo las filas que no tienen ningún valor cargado.'
          placement='top-start'
          arrow
          followCursor
        >
          <i className='fas fa-info-circle mr-3' style={{ fontSize: 18 }}></i>
        </Tooltip>
        <FormGroup
          sx={{
            marginRight: 2,
          }}
        >
          <FormControlLabel
            control={
              <Switch checked={checkedState} onChange={handleChangeChecked} />
            }
            label='Sin Valores'
          />
        </FormGroup>
        <Button
          color='primary'
          // export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => downloadXLS(data, columns, filterProp?.module)}
          startIcon={<FileDownloadIcon />}
          variant='contained'
        >
          Exportar
        </Button>
      </Box>
    ),
    // estilo a cabecera
    muiTableHeadCellProps: customHeadStyles,
    // estilos Container tabla
    muiTableBodyCellProps: ({ cell, column, row, table }) => ({
      sx: (theme) => ({
        ...theme.typography.cellTable,
        borderColor: theme.palette.divider,
      }),
    }),
    muiTableBodyRowProps: ({ row, table }) => {
      // console.log(row, '----row');
      return {
        sx: (theme) => ({
          backgroundColor:
            !row.original?.flowersDart &&
            !row.original?.budDart &&
            !row.original?.budDart
              ? theme.palette.warning.light
              : 'inherit',
        }),
      };
    },
    // estilo de contenedor Tabla
    muiTablePaperProps: ({ table }) => ({
      elevation: 0, // change the mui box shadow
      // custom zindex
      style: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
    }),
    // estilo del footer
    muiBottomToolbarProps: {
      sx: (theme) => ({
        backgroundColor: 'white',
      }),
    },
    // estilo del top tool bar
    muiTopToolbarProps: {
      sx: (theme) => ({
        backgroundColor: 'white',
        justifyContent: 'flex-end',
      }),
    },
    // filtrar col server side
    manualFiltering: true,
    // paginar server side
    manualPagination: true,
    // hacer sort server side
    manualSorting: true,
    // permitir fijar cols
    // enablePinning
    enableColumnPinning: false,
    // permitir fijar filas
    enableRowPinning: false,
    // habilitar sticky header
    enableStickyHeader: true,
    // altura máxima si es sticky
    muiTableContainerProps: {
      sx: { maxHeight: '700px' },
    },
    // click para copiar
    enableClickToCopy: false,
    // no poder cambiar padding filas
    enableDensityToggle: false,
    // no permite búsqueda global
    enableGlobalFilter: false,
    // no permite filtrar
    enableColumnFilters: false,
    // habilitar el multi Sort
    enableMultiSort: false,
    localization: MRT_Localization_ES,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error Cargando data',
        }
      : undefined,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,
    // position pagination
    positionPagination: 'bottom',
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 30, 50, 100, 200, 300, 500],
    },

    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Editar',
      },
    },

    // componente para la columna acciones
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip arrow placement='left' title='Edit'>
          <IconButton onClick={() => table.setEditingRow(row)}>
            <Edit color='secondary' />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    defaultColumn: {
      size: 150,
    },

    icons: {
      ViewColumnIcon: (props) => <FilterListIcon {...props} />,
    },
  });

  return (
    <BsRow>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          <MaterialReactTable table={tableOptions} />
        </div>
      </BsCol>
    </BsRow>
  );
};

export default EditTable;
