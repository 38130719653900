import clsx from 'clsx';
import { Typography, Link, Box } from '@mui/material';
// import classes from './Footer.module.scss';

const Footer = (props) => {
  const { className, ...rest } = props;

  return (
    <Box
      {...rest}
      className={clsx(className)}
      sx={{
        px: 2,
      }}
    >
      <Typography variant='caption' color='textPrimary' component='p'>
        Plataforma Analytics
      </Typography>
      <Typography variant='caption' color='textPrimary'>
        &copy;{' '}
        <Link
          component='a'
          color='secondary.main'
          href='http://ceranalytics.com/'
          target='_blank'
        >
          ceranalytics.com
        </Link>
        . 2024
      </Typography>
    </Box>
  );
};

export default Footer;
