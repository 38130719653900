import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { Context } from '../context/Context';
// import Secrets from '../utils/secrets';

const AxiosContext = createContext();
const { Provider } = AxiosContext;

const AxiosProvider = ({ children }) => {
	const { token } = useContext(Context);
	// const [secretState, setSecretState] = useState(null);

	// const getSecret = async () => {
	// 	const BFF_HOST = process.env.REACT_APP_BFF_HOST;
	// 	console.log('---------BFF_HOST---env-------------', BFF_HOST);
	// 	setSecretState(BFF_HOST);
	// };

	// useEffect(() => {
	// 	console.log('------');
	// 	//await getSecret();
	// 	const BFF_HOST = process.env.REACT_APP_BFF_HOST;
	// 	console.log('---------BFF_HOST---env-------------', BFF_HOST);
	// 	setSecretState(BFF_HOST);
	// }, []);

	// console.log(secretState, "----------------------BFF_HOST")

	const authAxios = axios.create({
		baseURL: process.env.REACT_APP_BFF_HOST,
	});

	const publicAxios = axios.create({
		baseURL: process.env.REACT_APP_BFF_HOST,
	});

	// console.log(authAxios, token, "en context");

	authAxios.interceptors.request.use(
		(config) => {
			if (!config.headers.authorization) {
				config.headers.authorization = `Bearer ${token}`;
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	return (
		<Provider
			value={{
				authAxios,
				publicAxios,
			}}
		>
			{children}
		</Provider>
	);
};

export { AxiosContext, AxiosProvider };
