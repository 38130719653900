// External libraries
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import {
  Box,
  Typography,
  useTheme,
  SvgIcon,
  Divider,
  Skeleton,
  Button,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal modules
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import clsx from 'clsx';
import { BsCol, BsRow } from 'layouts/components';
import iconJson from '../../../common/iconSVG.json';
import { CardPaper } from '../../../components';
import { numberFormatDecimals } from 'helpers';
import {
  exportToExcelWeatherMulti,
  transformDataWeather,
} from 'helpers/exportFunctions';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
    width: '90%',
    borderTop: 2,
  },
  theadLightSmall: {
    '& thead tr:nth-child(2) th': {
      backgroundColor: 'white',
    },
    '& th': {
      // color: '#495057',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
  tableDataStyle: {
    marginBottom: 0,
    '& td': {
      color: 'white',
      borderTop: 'none',
      '&:nth-child(1)': {
        textAlign: 'center',
      },
      '&:nth-child(2)': {
        fontWeight: 700,
      },
    },
  },
  tbodyStyle: {
    '& td': {
      fontSize: 14,
    },
    '& td.greyBackground': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

const yearsArray = Array.from(
  { length: 4 },
  (_, i) => new Date().getFullYear() - i
)
  .map((year) => year.toString())
  .reverse();

const tableColdHourStructure = {
  years: yearsArray,
  months: ['4', '5', '6', '7'],
};

const tableColdPortionStructure = {
  years: yearsArray,
  months: ['4', '5', '6', '7'],
};

const tableDegreeDaysStructure = {
  years: yearsArray,
  months: ['8', '9', '10', '11', '12'],
};

const EXPORT_HEADERS = {
  orchardValue: 'Huerto',
  year: 'Año',
  month: 'Mes',
  value: 'valor',
};

const OrchardWeatherParameters = ({ filter: orchardsProp }) => {
  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const classes = useStyles();

  const theme = useTheme();

  const errorHandler = useErrorHandler();

  const [loadingState, setLoadingState] = useState(false);
  const [tableDataState, setTableDataState] = useState(null);
  const [boxesDataState, setBoxesDataState] = useState(null);

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      //   TODO: ver cuando quiero ver mis huertos
      const { orchard } = dataQuery;

      const objQuery = {
        orchard: orchard.value,
      };

      const response = await axiosContext.authAxios.post(
        'v1/weather/weather-agroclimatic-dashboard-by-orchard',
        objQuery
      );

      // // let data = true;

      const { data, status } = response;

      console.log(data, '<----data en orchards parameters');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setTableDataState(data.agroClimaticData);
        setBoxesDataState(data.weatherBoxes);
      }
    } catch (err) {
      setTableDataState(null);
      setBoxesDataState(null);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    if (orchardsProp) {
      console.log(orchardsProp, '<----orchardsProp en orchards parameters');
      queryData(orchardsProp);
    }
  }, [orchardsProp]);

  if (!tableDataState && !loadingState) {
    return <p>Seleccione Huerto.</p>;
  }

  return (
    <>
      {loadingState ? (
        <>
          <BsRow>
            <BsCol class='col-md-6 mb-2 col-lg-3'>
              <Skeleton variant='rectangular' height={140} />
            </BsCol>
            <BsCol class='col-md-6 mb-2 col-lg-3'>
              <Skeleton variant='rectangular' height={140} />
            </BsCol>
            <BsCol class='col-md-6 mb-2 col-lg-3'>
              <Skeleton variant='rectangular' height={140} />
            </BsCol>
            <BsCol class='col-md-6 mb-2 col-lg-3'>
              <Skeleton variant='rectangular' height={140} />
            </BsCol>
            <BsCol class='col-md-12 mb-2 mt-3'>
              <Skeleton variant='rectangular' height={120} />
            </BsCol>
          </BsRow>
        </>
      ) : (
        <>
          <BsRow>
            {/* <BsCol class='col-md-6 col-lg-3'>
          <CardPaper
            elevation={0}
            topColor=''
            borderTop={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: theme.palette.boxes.dark,
              padding: theme.spacing(2),
            }}
          >
            <SvgIcon
              sx={{
                width: 24,
                height: 24,
                mb: 1,
              }}
              viewBox='0 0 24 24'
              color='#FFFFFF'
              htmlColor='#FFFFFF'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d={iconJson.icon.coldHour}
              />
            </SvgIcon>
            <Typography variant='h5' sx={{ fontWeight: 600, color: 'white' }}>
              Horas Frío
            </Typography>
            <Typography variant='body2' sx={{ fontSize: 12, color: 'white' }}>
              Comparativo de fecha actual
            </Typography>
            <Typography variant='h4' sx={{ fontWeight: 700, color: 'white' }}>
              200
            </Typography>
            <Divider className={classes.divider} />
            <table className={clsx('table table-sm', classes.tableDataStyle)}>
              <tbody>
                <tr>
                  <td>2023</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>2022</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>2021</td>
                  <td>100</td>
                </tr>
              </tbody>
            </table>
          </CardPaper>
        </BsCol> */}
            <BsCol class='col-md-6 mb-2 mb-lg-0 col-lg-3'>
              <CardPaper
                elevation={0}
                topColor=''
                borderTop={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: theme.palette.boxes.dark,
                  padding: theme.spacing(2),
                }}
              >
                <SvgIcon
                  sx={{
                    width: 24,
                    height: 24,
                    mb: 1,
                  }}
                  viewBox='0 0 24 24'
                  color='#FFFFFF'
                  htmlColor='#FFFFFF'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d={iconJson.icon.coldHour}
                  />
                </SvgIcon>
                <Typography
                  variant='h5'
                  sx={{ fontWeight: 600, color: 'white' }}
                >
                  Horas Frío
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ fontSize: 12, color: 'white' }}
                >
                  Comparativo de fecha actual
                </Typography>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 700, color: 'white' }}
                >
                  {boxesDataState &&
                  boxesDataState.coldHour.find(
                    (item) => item.year === new Date().getFullYear().toString()
                  )?.value
                    ? numberFormatDecimals(
                        boxesDataState.coldHour.find(
                          (item) =>
                            item.year === new Date().getFullYear().toString()
                        )?.value,
                        2
                      )
                    : '-'}
                </Typography>
                <Divider className={classes.divider} />
                <table
                  className={clsx('table table-sm', classes.tableDataStyle)}
                >
                  <tbody>
                    {boxesDataState.coldHour
                      .filter(
                        (item) =>
                          item.year !== new Date().getFullYear().toString()
                      )
                      .map((item) => (
                        <tr key={item.year}>
                          <td>{item.year}</td>
                          <td>{numberFormatDecimals(item.value, 2)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2 mb-lg-0 col-lg-3'>
              <CardPaper
                elevation={0}
                topColor=''
                borderTop={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: theme.palette.boxes.light,
                  padding: theme.spacing(2),
                }}
              >
                <SvgIcon
                  sx={{
                    width: 24,
                    height: 24,
                    mb: 1,
                  }}
                  viewBox='0 0 24 24'
                  color='#FFFFFF'
                  htmlColor='#FFFFFF'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d={iconJson.icon.coldPortion}
                  />
                </SvgIcon>
                <Typography
                  variant='h5'
                  sx={{ fontWeight: 600, color: 'white' }}
                >
                  Porciones Frío
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ fontSize: 12, color: 'white' }}
                >
                  Comparativo de fecha actual
                </Typography>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 700, color: 'white' }}
                >
                  {boxesDataState &&
                  boxesDataState.coldPortion.find(
                    (item) => item.year === new Date().getFullYear().toString()
                  )?.value
                    ? numberFormatDecimals(
                        boxesDataState.coldPortion.find(
                          (item) =>
                            item.year === new Date().getFullYear().toString()
                        )?.value,
                        2
                      )
                    : '-'}
                </Typography>
                <Divider className={classes.divider} />
                <table
                  className={clsx('table table-sm', classes.tableDataStyle)}
                >
                  <tbody>
                    {boxesDataState.coldPortion
                      .filter(
                        (item) =>
                          item.year !== new Date().getFullYear().toString()
                      )
                      .map((item) => (
                        <tr key={item.year}>
                          <td>{item.year}</td>
                          <td>{numberFormatDecimals(item.value, 2)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2 mb-lg-0 col-lg-3'>
              <CardPaper
                elevation={0}
                topColor=''
                borderTop={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: theme.palette.boxes.main,
                  padding: theme.spacing(2),
                }}
              >
                <SvgIcon
                  sx={{
                    width: 24,
                    height: 24,
                    mb: 1,
                  }}
                  viewBox='0 0 24 24'
                  color='#FFFFFF'
                  htmlColor='#FFFFFF'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d={iconJson.icon.degreeDay}
                  />
                </SvgIcon>
                <Typography
                  variant='h5'
                  sx={{ fontWeight: 600, color: 'white' }}
                >
                  Grados Día
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ fontSize: 12, color: 'white' }}
                >
                  Comparativo de fecha actual
                </Typography>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 700, color: 'white' }}
                >
                  {boxesDataState &&
                  boxesDataState.degreeDays.find(
                    (item) => item.year === new Date().getFullYear().toString()
                  )?.value
                    ? numberFormatDecimals(
                        boxesDataState.degreeDays.find(
                          (item) =>
                            item.year === new Date().getFullYear().toString()
                        )?.value,
                        2
                      )
                    : '-'}
                </Typography>
                <Divider className={classes.divider} />
                <table
                  className={clsx('table table-sm', classes.tableDataStyle)}
                >
                  <tbody>
                    {boxesDataState.degreeDays
                      .filter(
                        (item) =>
                          item.year !== new Date().getFullYear().toString()
                      )
                      .map((item) => (
                        <tr key={item.year}>
                          <td>{item.year}</td>
                          <td>{numberFormatDecimals(item.value, 2)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardPaper>
            </BsCol>
            <BsCol class='col-md-6 mb-2 mb-lg-0 col-lg-3'>
              <CardPaper
                elevation={0}
                topColor=''
                borderTop={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: theme.palette.boxes.lighter,
                  padding: theme.spacing(2),
                }}
              >
                <SvgIcon
                  sx={{
                    width: 24,
                    height: 24,
                    mb: 1,
                  }}
                  viewBox='0 0 30 30'
                  color='#FFFFFF'
                  htmlColor='#FFFFFF'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d={iconJson.icon.precipitation}
                  />
                </SvgIcon>
                <Typography
                  variant='h5'
                  sx={{ fontWeight: 600, color: 'white' }}
                >
                  Precipitación
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ fontSize: 12, color: 'white' }}
                >
                  Comparativo de fecha actual
                </Typography>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 700, color: 'white' }}
                >
                  {boxesDataState &&
                  boxesDataState.precipitation.find(
                    (item) => item.year === new Date().getFullYear().toString()
                  )?.value
                    ? numberFormatDecimals(
                        boxesDataState.precipitation.find(
                          (item) =>
                            item.year === new Date().getFullYear().toString()
                        )?.value,
                        2
                      )
                    : '-'}
                </Typography>
                <Divider className={classes.divider} />
                <table
                  className={clsx('table table-sm', classes.tableDataStyle)}
                >
                  <tbody>
                    {boxesDataState.precipitation
                      .filter(
                        (item) =>
                          item.year !== new Date().getFullYear().toString()
                      )
                      .map((item) => (
                        <tr key={item.year}>
                          <td>{item.year}</td>
                          <td>{numberFormatDecimals(item.value, 2)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardPaper>
            </BsCol>
          </BsRow>
          <BsRow class='mt-3'>
            <BsCol class='col-md-12'>
              <div
                className='table-responsive px-3 pt-4'
                style={{
                  border: '1px solid',
                  borderColor: theme.palette.grey[300],
                  borderRadius: theme.shape.borderRadius,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item>
                    <Typography
                      variant='h5'
                      gutterBottom
                      component='h2'
                      sx={{ fontWeight: 700 }}
                    >
                      Detalles
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color='primary'
                      variant='contained'
                      size='small'
                      disabled={!tableDataState || loadingState}
                      onClick={() =>
                        exportToExcelWeatherMulti(
                          transformDataWeather(tableDataState.coldHour),
                          transformDataWeather(tableDataState.coldPortion),
                          transformDataWeather(tableDataState.degreeDays),
                          EXPORT_HEADERS,
                          'Huerto_Agroclimaticos'
                        )
                      }
                    >
                      EXCEL
                    </Button>
                  </Grid>
                </Grid>

                <table
                  className={clsx('table table-sm', classes.theadLightSmall)}
                >
                  <thead>
                    <tr>
                      <th scope='col' className='text-center' rowSpan={2}>
                        Acumulado
                      </th>
                      {yearsArray.map((year) => (
                        <th
                          scope='col'
                          key={year}
                          colSpan={5}
                          className='text-center'
                        >
                          {year}
                        </th>
                      ))}
                    </tr>
                    <tr>
                      <th scope='col' className='text-center'>
                        Abr
                      </th>
                      <th scope='col' className='text-center'>
                        May
                      </th>
                      <th scope='col' className='text-center'>
                        Jun
                      </th>
                      <th scope='col' className='text-center'>
                        Jul
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                      <th scope='col' className='text-center'>
                        Abr
                      </th>
                      <th scope='col' className='text-center'>
                        May
                      </th>
                      <th scope='col' className='text-center'>
                        Jun
                      </th>
                      <th scope='col' className='text-center'>
                        Jul
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                      <th scope='col' className='text-center'>
                        Abr
                      </th>
                      <th scope='col' className='text-center'>
                        May
                      </th>
                      <th scope='col' className='text-center'>
                        Jun
                      </th>
                      <th scope='col' className='text-center'>
                        Jul
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                      <th scope='col' className='text-center'>
                        Abr
                      </th>
                      <th scope='col' className='text-center'>
                        May
                      </th>
                      <th scope='col' className='text-center'>
                        Jun
                      </th>
                      <th scope='col' className='text-center'>
                        Jul
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbodyStyle}>
                    <tr>
                      <td className='text-left greyBackground'>Horas Frío</td>
                      {tableDataState &&
                        tableDataState.coldHour.map(({ data }, index1) =>
                          tableColdHourStructure.years.map((year, index2) => {
                            const yearData = data.find(
                              (item) => item.year === year
                            );
                            let cols;
                            if (yearData) {
                              cols = tableColdHourStructure.months.map(
                                (month, index3) => {
                                  const monthData = yearData.months.find(
                                    (item) => item.month === month
                                  );

                                  if (monthData) {
                                    return (
                                      <td key={`${monthData.month}_${index3}`}>
                                        {monthData.value}
                                      </td>
                                    );
                                  } else {
                                    return <td key={`mes_${index3}_`}>0</td>;
                                  }
                                }
                              );
                            } else {
                              cols = tableColdHourStructure.months.map(
                                (month, index3) => {
                                  return <td key={`_${index3}_`}>0</td>;
                                }
                              );
                            }
                            return (
                              <React.Fragment key={`_${index2}_`}>
                                {cols}
                                <td className='font-weight-bold'>
                                  {yearData?.total || '-'}
                                </td>
                              </React.Fragment>
                            );
                          })
                        )}
                    </tr>
                    <tr>
                      <td className='text-left greyBackground'>
                        Porciones Frío
                      </td>
                      {tableDataState &&
                        tableDataState.coldPortion.map(({ data }, index1) =>
                          tableColdPortionStructure.years.map(
                            (year, index2) => {
                              const yearData = data.find(
                                (item) => item.year === year
                              );
                              let cols;
                              if (yearData) {
                                cols = tableColdPortionStructure.months.map(
                                  (month, index3) => {
                                    const monthData = yearData.months.find(
                                      (item) => item.month === month
                                    );

                                    if (monthData) {
                                      return (
                                        <td
                                          key={`${monthData.month}_${index3}`}
                                        >
                                          {monthData.value}
                                        </td>
                                      );
                                    } else {
                                      return <td key={`mes_${index3}`}>0</td>;
                                    }
                                  }
                                );
                              } else {
                                cols = tableColdPortionStructure.months.map(
                                  (month, index3) => {
                                    return <td key={index3}>0</td>;
                                  }
                                );
                              }
                              return (
                                <React.Fragment key={`_${index2}_`}>
                                  {cols}
                                  <td className='font-weight-bold'>
                                    {yearData?.total || '-'}
                                  </td>
                                </React.Fragment>
                              );
                            }
                          )
                        )}
                    </tr>
                  </tbody>
                </table>

                <table
                  className={clsx('table table-sm', classes.theadLightSmall)}
                >
                  <thead>
                    <tr>
                      <th scope='col' className='text-center' rowSpan={2}>
                        Acumulado
                      </th>
                      {yearsArray.map((year) => (
                        <th
                          scope='col'
                          key={year}
                          colSpan={6}
                          className='text-center'
                        >
                          {year}
                        </th>
                      ))}
                    </tr>
                    <tr>
                      <th scope='col' className='text-center'>
                        Ago
                      </th>
                      <th scope='col' className='text-center'>
                        Sep
                      </th>
                      <th scope='col' className='text-center'>
                        Oct
                      </th>
                      <th scope='col' className='text-center'>
                        Nov
                      </th>
                      <th scope='col' className='text-center'>
                        Dic
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                      <th scope='col' className='text-center'>
                        Ago
                      </th>
                      <th scope='col' className='text-center'>
                        Sep
                      </th>
                      <th scope='col' className='text-center'>
                        Oct
                      </th>
                      <th scope='col' className='text-center'>
                        Nov
                      </th>
                      <th scope='col' className='text-center'>
                        Dic
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                      <th scope='col' className='text-center'>
                        Ago
                      </th>
                      <th scope='col' className='text-center'>
                        Sep
                      </th>
                      <th scope='col' className='text-center'>
                        Oct
                      </th>
                      <th scope='col' className='text-center'>
                        Nov
                      </th>
                      <th scope='col' className='text-center'>
                        Dic
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                      <th scope='col' className='text-center'>
                        Ago
                      </th>
                      <th scope='col' className='text-center'>
                        Sep
                      </th>
                      <th scope='col' className='text-center'>
                        Oct
                      </th>
                      <th scope='col' className='text-center'>
                        Nov
                      </th>
                      <th scope='col' className='text-center'>
                        Dic
                      </th>
                      <th scope='col' className='text-center font-weight-bold'>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbodyStyle}>
                    <tr>
                      <td className='text-left greyBackground'>Grados Día</td>
                      {tableDataState &&
                        tableDataState.degreeDays.map(({ data }, index1) =>
                          tableDegreeDaysStructure.years.map((year, index2) => {
                            const yearData = data.find(
                              (item) => item.year === year
                            );
                            let cols;
                            if (yearData) {
                              cols = tableDegreeDaysStructure.months.map(
                                (month, index3) => {
                                  const monthData = yearData.months.find(
                                    (item) => item.month === month
                                  );

                                  if (monthData) {
                                    return (
                                      <td key={`${monthData.month}_${index3}`}>
                                        {monthData.value}
                                      </td>
                                    );
                                  } else {
                                    return <td key={`mes_${index3}`}>0</td>;
                                  }
                                }
                              );
                            } else {
                              cols = tableDegreeDaysStructure.months.map(
                                (month, index3) => {
                                  return <td key={index3}>0</td>;
                                }
                              );
                            }
                            return (
                              <React.Fragment key={`_${index2}_`}>
                                {cols}
                                <td className='font-weight-bold'>
                                  {yearData?.total || '-'}
                                </td>
                              </React.Fragment>
                            );
                          })
                        )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </BsCol>
          </BsRow>
        </>
      )}
    </>
  );
};

export default OrchardWeatherParameters;
