import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import {
  // Avatar,
  Card,
  CardContent,
  Typography,
  useTheme,
  Tooltip,
  Grid,
  Button,
} from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useHistory } from 'react-router-dom';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { useForm, useWatch } from 'react-hook-form';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import theme from '../../../theme';
import { FiltersContext } from '../../../context';
import {
  ButtonAction,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  TextFieldWidgetSelectController,
  TextFieldWidgetTextTypeFile,
} from '../../../components';
import {
  alterArrayForSelectVariety,
  alterArrayGetFields,
  arrayGetStationIds,
  setOrderForApi,
} from '../../../helpers';
import { MobileFilters } from '../../../containers/Library';
import { EditTable } from '../../../containers/DataAnalysis';

const Counting = (props) => {
  const { userClient } = useContext(Context);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const [loadingInfoState, setLoadingInfoState] = useState(false);

  const [loadingFiltersState] = useState(false);

  const [infoFilterState, setInfoFilterState] = useState(null);

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  // const [filterDataResponseState] = useState(
  //   alterArrayForSelectVariety(userOrchard_filter)
  // );
  const [filterDataResponseState] = useState(userOrchard_filter);

  const [ccDataState, setCcDataState] = useState([]);
  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // const params = {
  //   clientName: 'Rucaray',
  // };
  // const paramsAsString = JSON.stringify(params);
  // const encodedParams = encodeURIComponent(paramsAsString);

  console.log('client------', userClient);

  const clientName = userClient?.label;

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardContent>
                {/* <iframe
                  title='mapa de conteos'
                  style={{ border: 0, width: '100%', height: '900px' }}
                  src='https://app.powerbi.com/view?r=eyJrIjoiYmI3MTI1ODctYmZjYS00Y2FiLWExMWUtZmEwNjQ5NmQ2ODI4IiwidCI6IjQxZjhhZDQ3LTgyMTItNDhkMS04MzdiLTk1OGEzMTQyZWE3NSJ9'
                  frameborder='0'
                  allowFullScreen='true'
                ></iframe> */}
                <iframe
                  title='mapa de conteos grafico'
                  style={{ border: 0, width: '100%', height: '900px' }}
                  src='https://app.powerbi.com/view?r=eyJrIjoiMjQzZjQ2NTktNGVmMi00YWZkLTlmYjctMjVhMzEwNjZlMDIzIiwidCI6IjQxZjhhZDQ3LTgyMTItNDhkMS04MzdiLTk1OGEzMTQyZWE3NSJ9'
                  frameborder='0'
                  allowFullScreen='true'
                ></iframe>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Counting;
