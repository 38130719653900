import { useState, useEffect } from 'react';
import { Checkbox, FormHelperText } from '@mui/material';
import classes from '../CheckboxWidget.module.scss';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';

const CheckboxWidgetUncontrolled = (props) => {
  const { errors, req, control, name } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    console.log(errors, 'errores');
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  return (
    <span>
      <Controller
        render={({ field }) => (
          <Checkbox
            onChange={(e) => {
              console.log(
                e.target.checked,
                '----cambia',
                field.value,
                '---',
                name
              );
              field.onChange(e.target.checked);
            }}
            checked={field.value}
          />
        )}
        name={name}
        defaultValue={false}
        control={control}
        rules={{
          required: req,
        }}
      />
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
    </span>
  );
};

export default CheckboxWidgetUncontrolled;
