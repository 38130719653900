import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  // DataTableWidget,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  SelectMultipleWidget2,
  TextFieldWidgetSelectController,
  // TextFieldWidgetDateController,
} from '../../../components';
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  // Hidden,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useErrorHandler } from 'react-error-boundary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
// import { async } from 'validate.js';
// import { fetchConToken, fetchSinToken } from 'helpers/fetch';
import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  alterArrayGetFields,
  getOrchardVarietiesDeep,
  getOnlyValidCcQuartersWithPolygon,
  getSeasonActiveQuarters,
  alphabeticalSort,
  getOrchardsBySpecie,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';
import { PlantHeatMap } from '../../../containers/Counting';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const alterArrayGetValue = (array) => {
  const newArray = array.map((item) => {
    return item.value;
  });
  return newArray;
};

const COUNTER_TYPE_MOCK = [
  { value: 'Pre_Poda', label: 'Pre Poda' },
  { value: 'Post_Poda', label: 'Post Poda' },
  { value: 'Pre_Raleo', label: 'Pre Raleo' },
  { value: 'Post_Raleo', label: 'Post Raleo' },
];

const Heatmap = (props) => {
  const { userId, userHuerto, userName, moduleUser } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  // console.log(moduleUser, '------moduleUser');

  const errorHandler = useErrorHandler();

  const {
    orchard_filter,
    orchard_id_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    rootstock_filter,
    season_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [loadingInfoState, setLoadingInfoState] = useState(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const theme = useTheme();

  // console.log(theme)

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);
  const [rootStockDataState, setRootStockDataState] = useState([]);
  const [rootStockFullDataState, setRootStockFullDataState] = useState([]);

  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // loading quarter data
  const [loadingFilterQuarterState, setLoadingFilterQuarterState] =
    useState(false);

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusCounter';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // seasons
  const [counterTypeDataState, setCounterTypeDataState] =
    useState(COUNTER_TYPE_MOCK);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useCc = useWatch({
    control,
    name: 'cc',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const watchCounterType = watch('counterType', '');

  useEffect(() => {
    if (!watchCounterType) {
      setValue('counterType', 'Pre_Poda');
    }
  }, [watchCounterType]);

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);

    // console.log(values, 'values');

    let data = {
      orchard: values.orchard,
      cc: values.cc,
      quarter: values.quarter,
      variety: values.variety,
      counterType: values.counterType ? values.counterType : '',
    };

    // let idOrchard = userOrchard_filter.filter(
    //   (ele) => ele.value === values.orchard
    // );

    // let idOrchardFilter = idOrchard[0] ? idOrchard[0].id : null;

    console.log(data, '------------');

    addFilter({
      orchard_filter: values.orchard?.value,
      orchard_id_filter: values.orchard?.id,
      cc_filter: values.cc?.value,
      quarter_filter: values.quarter?.value,
      variety_filter: values.variety?.value,
      // rootstock_filter: values.rootStock,
      // season_filter: values.season,
    });
    setInfoFilterTab(data);
  };

  // --------NUEVO

  // const getFilterVarietyAsync = async () => {
  //   try {
  //     const response = await axiosContext.authAxios.get('v1/variety');

  //     if (response.data) {
  //       setVarietyFullDataState(response.data);
  //     }
  //   } catch (error) {
  //     errorHandler(error);
  //     // console.log(error);
  //     // setLoadingVarietyFilterState(false);
  //   }
  // };

  useEffect(() => {
    if (orchard_filter) {
      const foundOrchard = filterDataResponseState.find(
        (element) => element.value === orchard_filter
      );

      if (foundOrchard) {
        setValue('orchard', foundOrchard);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (varietyFullDataState.length) {
  //     setLoadingFiltersState(false);
  //   }
  // }, [varietyFullDataState]);

  // endpoints variety - rootstock
  useEffect(() => {
    setValue('counterType', COUNTER_TYPE_MOCK[0]);
  }, []);

  useEffect(() => {
    if (useOrchard) {
      setValue('cc', '');

      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      if (resultOrchard.length) {
        const ccSelect = getOnlyValidCcQuartersWithPolygon(
          resultOrchard[0],
          specie_filter
        ).cc;

        setCcDataState(alphabeticalSort(ccSelect, 'label'));
      }
    } else {
      setValue('cc', '');
    }
  }, [useOrchard]);

  useEffect(() => {
    if (ccDataState.length) {
      // si hay data de cc
      // reivsa si puede seleccionar el del context
      setLoadingFilterCcState(true);
      const found = ccDataState.find((element) => element.value === cc_filter);
      // SI LO ENCUENTRA PERO NO ES MISMO HUERTO NO SELECCIONA

      if (found && useOrchard?.value === orchard_filter) {
        setValue('cc', found);
      } else {
        setValue('cc', '');
        setValue('quarter', '');
      }
      setLoadingFilterCcState(false);
    }
  }, [ccDataState]);

  useEffect(() => {
    if (useCc) {
      setValue('quarter', '');
      const resultCc = ccDataState.filter(
        (ele) => ele.value.toLowerCase() === useCc?.value.toLowerCase()
      );

      let quarterArray = [];
      if (resultCc[0]?.quarter) {
        quarterArray = getSeasonActiveQuarters(resultCc[0]?.quarter);
      }

      setQuarterDataState(quarterArray);
    } else {
      setValue('quarter', '');
      setQuarterDataState([]);
    }
  }, [useCc]);

  useEffect(() => {
    if (quarterDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      setLoadingFilterQuarterState(true);
      const found = quarterDataState.find(
        (element) => element.value === quarter_filter
      );
      // console.log(found, '---found');
      if (found && useCc?.value === cc_filter) {
        setValue('quarter', found);
      } else {
        setValue('quarter', '');
      }
      setLoadingFilterQuarterState(false);
    } else {
      setValue('quarter', '');
    }
  }, [quarterDataState]);

  // VARIETY
  useEffect(() => {
    if (useOrchard) {
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      const orchardsCc = resultOrchard[0]?.cc;

      setVarietyDataState(getOrchardVarietiesDeep(orchardsCc));
    } else {
      // vuelve a dejar variety completo
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
      // vacía los cc y los quarters
      setQuarterDataState([]);
      setCcDataState([]);
    }
  }, [useOrchard]);

  // alimenta las varieties full
  // solo cuando no tiene nada
  useEffect(() => {
    if (varietyFullDataState.length && !varietyDataState.length) {
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [varietyFullDataState]);

  // cambios de cc
  // para ajustar variety
  useEffect(() => {
    if (useCc) {
      const resultCc = ccDataState.filter(
        (ele) => ele.value.toLowerCase() === useCc?.value.toLowerCase()
      );
      setVarietyDataState(getOrchardVarietiesDeep(resultCc));
      setValue('variety', '');
    } else {
      if (useOrchard && ccDataState.length) {
        setVarietyDataState(getOrchardVarietiesDeep(ccDataState));
      }
    }
  }, [useCc]);

  // cambios en quarter
  useEffect(() => {
    if (useQuarter) {
      const resultQuarter = quarterDataState.filter(
        (ele) => ele.value.toLowerCase() === useQuarter?.value.toLowerCase()
      );
      setVarietyDataState(
        resultQuarter[0]?.variety ? resultQuarter[0]?.variety : []
      );
      const found = resultQuarter[0]?.variety
        ? resultQuarter[0]?.variety.find(
            (element) => element.value === variety_filter
          )
        : false;

      if (found && useQuarter?.value === quarter_filter) {
        setValue('variety', found);
      } else {
        setValue('variety', '');
      }
    } else {
      setValue('variety', '');
    }
  }, [useQuarter, setValue, quarterDataState]);

  const renderFilters = () => {
    return (
      <MobileFilters>
        {errors.neitherItemlist && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.neitherItemlist.message}
          </p>
        )}
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group'>
                {/* <TextFieldWidgetSelectController
                  options={filterDataResponseState}
                  control={control}
                  titleOption='label'
                  name='orchard'
                  labeltext='Huerto'
                  errors={errors.orchard}
                  defaultValue=''
                  loading={loadingFiltersState}
                  // req
                  withDefault
                  variant='standard'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={loadingFilterCcState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='cc'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={ccDataState}
                  placeholder='Centro de Costo'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget2
                  errors={errors.quarter}
                  loading={loadingFilterQuarterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='quarter'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='Cuarteles'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget2
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='variety'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Variedad'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget2
                  errors={errors.counterType}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='counterType'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={false}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={counterTypeDataState}
                  placeholder='Tipo de Conteo'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Conteos Mapa de Calor
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardContent>
                <PlantHeatMap filter={infoFilterTab} />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Heatmap;
