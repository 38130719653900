import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  FILTER,
  INFO,
  INFONULL,
  INFO2022,
  RESPONSEFORM,
  SEASONS_MOCK,
} from '../../helpers/Mockups';
import MapIcon from '@mui/icons-material/Map';
import {
  DataTableWidget,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  SelectMultipleWidget2,
  TextFieldWidgetSelectController,
} from '../../components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
import { useErrorHandler } from 'react-error-boundary';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
// import { async } from 'validate.js';
// import { fetchConToken, fetchSinToken } from 'helpers/fetch';
import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  alterArrayGetFields,
  getOrchardRootStock,
  alterArrayForSelectRootstock,
  IsDesktopHandler,
  getOrchardVarietiesDeep,
  getOrchardRootStockFromCcDeep,
  dashAdd,
  alterArrayForSelectSeason,
  getOnlyValidCcs,
  alphabeticalSort,
  alterArrayGetValues,
  getOrchardsBySpecie,
} from 'helpers';
// import theme from 'theme';
import { tabItems } from './tabList';
import {
  FirstTab,
  FourthTab,
  SecondTab,
  ThirdTab,
  FifthTab,
  SixthTab,
  SeventhTab,
  EighthTab,
  NinthTab,
  TenthTab,
  MobileFilters,
} from 'containers/Library';
import { FiltersContext } from '../../context';
import _, { result } from 'lodash';
import clsx from 'clsx';
import { Map } from 'containers/Orchard';
import { useLocation } from 'react-router-dom';

// const StyledTypography = styled(Typography)(({ theme }) => ({
// 	color: theme.palette.secondary.dark,
// 	lineHeight: theme.spacing(0.2),
// }));

// const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
// 	background: theme.palette.gradient.main,
// 	color: theme.palette.white,
// 	lineHeight: theme.spacing(0.2),
// }));

const drawerWidth = '50vw';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
  // drawer
  rootContent: {
    // display: 'flex',
  },
  rightMarginWide: {
    // [theme.breakpoints.up('lg')]: {
    // 	marginRight: drawerWidth,
    // },
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // appBar: {
  // 	[theme.breakpoints.up('lg')]: {
  // 		width: `calc(100% - ${drawerWidth}px)`,
  // 		marginLeft: drawerWidth,
  // 	},
  // },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  openMapContainer: {
    width: 200,
    textAlign: 'center',
    marginBottom: 20,
  },
  menuButton: {
    // backgroundColor: theme.palette.secondary.main + '!important',
    // color: 'white !important',
    boxShadow: theme.shadows[2],
  },
}));

const Library = (props) => {
  const { userId, userHuerto, userName, userOrchard, moduleUser } =
    useContext(Context);

  const { window } = props;

  const { state: IntelligenceState } = useLocation();

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const theme = useTheme();
  // console.log(theme, '<<<<---context');

  // console.log(IntelligenceState, '-----IntelligenceState');

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    rootstock_filter,
    season_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  // console.log(
  //   '-----Contexto---',
  //   filterState,
  //   '----quarter_filter',
  //   quarter_filter,
  //   '---variety_filter',
  //   variety_filter
  // );

  const errorHandler = useErrorHandler();

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const classes = useStyles();

  const [valueTabState, setValueTabState] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // const watchOrchard = watch('orchard');
  // const watchCc = watch('cc');
  // const watchQuarter = watch('quarter');
  const watchVariety = watch('variety');
  const watchRootStock = watch('rootStock');
  const watchSeason = watch('season');

  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);
  const [rootStockDataState, setRootStockDataState] = useState([]);
  const [rootStockFullDataState, setRootStockFullDataState] = useState([]);

  const [mobileOpen, setMobileOpen] = useState(false);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useCc = useWatch({
    control,
    name: 'cc',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  const [infoFilterTab, setInfoFilterTab] = useState(null);
  const [tabsArrayState, setTabsArrayState] = useState([]);

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(true);

  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // loading quarter data
  const [loadingFilterQuarterState, setLoadingFilterQuarterState] =
    useState(false);

  // carga mis orchards

  const moduleOrchardFilter = 'statusLibrary';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // seasons
  const [seasonDataState, setSeasonDataState] = useState(SEASONS_MOCK);

  // endpoints variety - rootstock
  useEffect(() => {
    getFilterVarietyAsync();
    getFilterRootStockAsync();
  }, []);

  // si hay orchard lo selecciona
  useEffect(() => {
    if (orchard_filter) {
      const orchardFilterFullData = filterDataResponseState.find(
        (ele) => ele.value === orchard_filter
      );

      setValue('orchard', orchardFilterFullData);
    }
    // console.log('se ejecuta usf incial');
  }, []);

  // quita el loading terminado las queries
  // useEffect(() => {
  //   if (varietyFullDataState.length && rootStockFullDataState.length) {
  //     setLoadingFiltersState(false);
  //   }
  // }, [varietyFullDataState, rootStockFullDataState]);

  // console.log(filterDataResponseState, "-----------filterDataResponseState---")

  useEffect(() => {
    // console.log(useOrchard, 'useOrchard');
    // cada vez que hay un orchard
    // limpia cc
    // y carga la nueva data de cc
    if (useOrchard) {
      clearErrors('neitherItemlist');
      setValue('cc', '');
      setValue('season', '');
      // console.log(useOrchard, 'useOrchard -- para useOrchard');

      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      // console.log(resultOrchard, '------resultOrchard');

      // console.log(
      //   alterArrayForSelectSeason(resultOrchard[0]?.season),
      //   '----seasons'
      // );

      // seteo de season
      setSeasonDataState(
        alterArrayForSelectSeason(resultOrchard[0]?.season).sort(
          (a, b) => Number(b.value) - Number(a.value)
        ) || SEASONS_MOCK.sort((a, b) => Number(b.value) - Number(a.value))
      );

      if (resultOrchard.length) {
        // let ccSelect = resultOrchard[0].cc
        //   ? getOnlyValidCcs(resultOrchard[0].cc)
        //   : [];

        let ccSelect = resultOrchard[0].cc ? resultOrchard[0].cc : [];

        // TODO: filtrar ccSelect por specie
        ccSelect = ccSelect.filter((ele) => ele.specie === specie_filter);

        setCcDataState(alphabeticalSort(ccSelect, 'label'));
      }
    } else {
      setSeasonDataState(
        SEASONS_MOCK.sort((a, b) => Number(b.value) - Number(a.value))
      );
      setValue('season', '');
      setValue('cc', '');
    }
  }, [useOrchard]);

  // console.log(useOrchard, 'useOrchard');

  useEffect(() => {
    // console.log('entra en ccData', ccDataState);
    if (ccDataState && ccDataState.length) {
      // si hay data de cc
      // reivsa si puede seleccionar el del context
      setLoadingFilterCcState(true);
      const found = ccDataState.find((element) => element.value === cc_filter);
      // console.log(found, '---found');
      if (found && useOrchard?.value === orchard_filter) {
        setValue('cc', found);
      } else {
        setValue('cc', '');
        setValue('quarter', '');
      }
      setLoadingFilterCcState(false);
    }
  }, [ccDataState]);

  useEffect(() => {
    // console.log(useCc, 'useCc');
    // cada vez que hay un cc
    // limpia quarter
    // y carga la nueva data de quarter
    if (useCc) {
      setValue('quarter', '');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);

      let quarterArray = [];
      if (resultCc[0]?.quarter) {
        // quarterArray = getSeasonActiveQuarters(resultCc[0]?.quarter);
        quarterArray = resultCc[0]?.quarter;
      }

      setQuarterDataState(quarterArray);
    } else {
      setValue('quarter', '');
    }
  }, [useCc]);

  useEffect(() => {
    // console.log('entra en quarterData', quarterDataState);
    if (quarterDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      setLoadingFilterQuarterState(true);
      const found = quarterDataState.find(
        (element) => element.value === quarter_filter
      );
      // console.log(found, '---found quarter_filter', quarter_filter);
      if (found && useCc?.value === cc_filter) {
        setValue('quarter', found);
      } else {
        setValue('quarter', '');
      }
      setLoadingFilterQuarterState(false);
    } else {
      setValue('quarter', '');
    }
  }, [quarterDataState]);

  // VARIETY
  useEffect(() => {
    // console.log(useOrchard, 'useOrchard -- para variety');
    // cada vez que hay un orchard
    // carga la data de variety
    if (useOrchard) {
      // console.log(useOrchard, 'useOrchard -- para useOrchard');

      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      let orchardsCc = resultOrchard[0].cc;
      // console.log(orchardsCc, '<-<<<--------orchard');
      // TODO: filtrar por specie
      orchardsCc = orchardsCc.filter((ele) => ele.specie === specie_filter);

      // console.log(getOrchardVarieties(orchardsCc), '<<---las varieties al colocar huerto');
      setValue('variety', '');

      setVarietyDataState(getOrchardVarietiesDeep(orchardsCc));
    } else {
      // vuelve a dejar variety completo
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [useOrchard]);

  // alimenta las varieties full
  // solo cuando no tiene nada
  useEffect(() => {
    if (varietyFullDataState.length && !varietyDataState.length) {
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [varietyFullDataState]);

  // cambios de cc
  // para ajustar variety
  useEffect(() => {
    // console.log(useCc, 'useCc - para variety');
    // cada vez que hay un cc
    // y carga la nueva data de quarter
    if (useCc) {
      // console.log(useCc, 'useCc - para variety - CON CC');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);
      setVarietyDataState(getOrchardVarietiesDeep(resultCc));
    } else {
      if (useOrchard && ccDataState.length) {
        // console.log(
        // 	useOrchard,
        // 	'------useCc hay orchard',
        // 	getOrchardVarieties(ccDataState)
        // );
        setVarietyDataState(getOrchardVarietiesDeep(ccDataState));
      }
    }
  }, [useCc]);

  // ROOTSTOCK
  useEffect(() => {
    // console.log(useOrchard, 'useOrchard -- para rootstock');
    // cada vez que hay un orchard
    // carga la data de variety

    if (useOrchard) {
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      let orchardsCc = resultOrchard[0].cc;

      setValue('rootStock', '');

      console.log(orchardsCc, '<<---los rootstock');

      orchardsCc = orchardsCc.filter((ele) => ele.specie === specie_filter);

      setRootStockDataState(
        alterArrayForSelectRootstock(getOrchardRootStockFromCcDeep(orchardsCc))
      );
    } else {
      // vuelve a dejar rootstock completo
      setRootStockDataState(alterArrayForSelectVariety(rootStockFullDataState));
    }
  }, [useOrchard]);

  // alimenta las rootstock full
  // solo cuando no tiene nada
  useEffect(() => {
    if (rootStockFullDataState.length && !rootStockDataState.length) {
      setRootStockDataState(alterArrayForSelectVariety(rootStockFullDataState));
    }
  }, [rootStockFullDataState]);

  // cambios de cc
  // para ajustar rootstock
  useEffect(() => {
    // console.log(useCc, 'useCc - para rootstock');
    // cada vez que hay un cc
    // limpia quarter
    // y carga la nueva data de quarter
    if (useCc) {
      const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);
      setRootStockDataState(
        alterArrayForSelectRootstock(getOrchardRootStockFromCcDeep(resultCc))
      );
    } else {
      if (useOrchard && ccDataState.length) {
        setRootStockDataState(
          alterArrayForSelectRootstock(getOrchardRootStock(ccDataState))
        );
      }
    }
  }, [useCc]);

  useEffect(() => {
    if (useQuarter) {
      // console.log(quarterDataState, '----quarterDataState', useQuarter);

      const resultQuarter = quarterDataState.find(
        (ele) => ele.value === useQuarter.value
      );

      console.log(resultQuarter, '----resultQuarter');

      if (resultQuarter) {
        setVarietyDataState(alterArrayForSelectVariety(resultQuarter.variety));
      }

      const getuniqueRootstock = (data) => {
        const uniqueObjects = {};

        data.variety.forEach((item) => {
          if (!uniqueObjects[item.rootStockValue]) {
            uniqueObjects[item.rootStockValue] = {
              label: item.rootStock,
              value: item.rootStockValue,
            };
          }
        });

        return Object.values(uniqueObjects);
      };

      setRootStockDataState(getuniqueRootstock(resultQuarter));

      // setRootStockDataState(
      //   alterArrayForSelectRootstock(
      //     getOrchardRootStockFromCcDeep(resultQuarter)
      //   )
      // );
    } else {
      if (useOrchard && ccDataState.length) {
        if (useCc) {
          const resultCc = ccDataState.filter(
            (ele) => ele.value === useCc.value
          );

          setVarietyDataState(getOrchardVarietiesDeep(resultCc));

          setRootStockDataState(
            alterArrayForSelectRootstock(
              getOrchardRootStockFromCcDeep(resultCc)
            )
          );
        } else {
          setRootStockDataState(
            alterArrayForSelectRootstock(getOrchardRootStock(ccDataState))
          );
        }
      }
    }
  }, [useQuarter]);

  useEffect(() => {
    if (
      cc_filter ||
      quarter_filter ||
      (orchard_filter && !loadingFilterQuarterState)
    ) {
      setTimeout(() => {
        handleSubmit(onSubmit)();
      }, 1200);
    }
  }, []);

  useEffect(() => {
    // console.log('entra en varietyDataState', varietyDataState);
    if (varietyDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      const found = varietyDataState.find(
        (element) => element.value === variety_filter
      );
      // console.log(found, '---found entra en variety data', varietyDataState);
      // console.log(useCc, useQuarter, '---------selects', variety_filter);
      if (found && useCc && useQuarter) {
        // setValue('variety', [found]);
      }
      if (!found) {
        setValue('variety', '');
      }

      // if (found) {
      //   setValue('variety', variety_filter);
      // } else {
      //   setValue('variety', '');
      // }
    } else {
      setValue('variety', '');
    }
  }, [varietyDataState]);

  // --------NUEVO

  const getFilterVarietyAsync = async () => {
    try {
      const params = { 
        specieValue: specie_filter, 
      };
       const response = await axiosContext.authAxios.get('v1/variety', { params, }); 

      if (response.data) {
        setVarietyFullDataState(response.data);
      }
    } catch (error) {
      errorHandler(error);
      // console.log(error);
      // setLoadingVarietyFilterState(false);
    }
    finally { 
      // setLoadingVarietyFilterState(false); 
      setLoadingFiltersState(false); 
    }
  };

  const getFilterRootStockAsync = async () => {
    try {

      const params = {
        specieValue: specie_filter,
      };
    
      const response = await axiosContext.authAxios.get('v1/rootstock', { params,});

      // console.log(response.data, "------response.data rootstock")

      if (response.data) {
        setRootStockFullDataState(response.data);
      }
    } catch (error) {
      errorHandler(error);
      // console.log(error);
      // setLoadingRootStockFilterState(false);
    }
    finally { 
      // setLoadingVarietyFilterState(false); 
      setLoadingFiltersState(false); 
    }
  };

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);

    console.log(values, '------ejecuta submit');

    // ya no valida que haya algo
    // if (
    // 	!values.orchard &&
    // 	!values.season &&
    // 	!values.variety.length &&
    // 	!values.rootStock.length
    // ) {
    // 	setError('neitherItemlist', {
    // 		type: 'manual',
    // 		message:
    // 			'Debe seleccionar al menos Huerto, Variedad, Portainjerto y/o Temporada',
    // 	});
    // 	return;
    // }
    const data = {
      huerto: values.orchard?.value,
      huerto_user: userOrchard,
      centro_costo: values.cc?.value,
      cuartel: values.quarter?.value,
      variedad: values.variety.length
        ? alterArrayGetValues(values.variety)
        : '', // debe ir solo array nombres
      porta_injerto: values.rootStock.length
        ? alterArrayGetValues(values.rootStock)
        : '',
      temporada: values.season ? values.season?.value : '',
      specieValue: specie_filter,
    };

    // console.log(data, '<----del submit');

    if (values.orchard?.value) {
      const idOrchard = userOrchard_filter.filter(
        (ele) => ele.value === values.orchard.value
      );

      const idOrchardFilter = idOrchard[0] ? idOrchard[0].id : '';

      addFilter({
        orchard_filter: values.orchard?.value,
        orchard_id_filter: idOrchardFilter,
        cc_filter: values.cc?.value,
        quarter_filter: values.quarter?.value,
        variety_filter: values.variety ? values.variety[0]?.value : null,
        rootstock_filter: values.rootStock ? values.rootStock[0]?.value : null,
        season_filter: values.season?.value,
      });
    }

    // console.log(data, '------');

    setInfoFilterTab(data);

    // setInfoFilterTab(INFO);
  };

  useEffect(() => {
    if (watchVariety) {
      console.log(watchVariety, '----watchVariety', watchRootStock);
    }
  }, [watchVariety]);

  useEffect(() => {
    if (watchVariety) {
      clearErrors('neitherItemlist');
    }
  }, [watchVariety]);

  useEffect(() => {
    if (watchSeason) {
      clearErrors('neitherItemlist');
    }
  }, [watchSeason]);

  useEffect(() => {
    if (watchRootStock) {
      clearErrors('neitherItemlist');
    }
  }, [watchRootStock]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clickMapHandler = () => {
    // console.log(
    //   orchard_filter,
    //   cc_filter,
    //   quarter_filter,
    //   '----------',
    //   dashAdd(quarter_filter).trim(),
    //   '------------<llega el filter de library',
    //   dashAdd(quarter_filter).length,
    //   '-----------------------',
    //   dashAdd(quarter_filter).trim().length
    // );
    if (cc_filter) {
      const foundCcMap = ccDataState.find(
        (element) => element.value === cc_filter
      );

      setValue('cc', foundCcMap);
      if (quarter_filter) {
        const foundQuarterMap = quarterDataState.find(
          (element) => element.value === quarter_filter
        );

        setValue('quarter', foundQuarterMap);

        // variety
        // if (variety_filter) {
        //   console.log(variety_filter, '----variety_filter');
        //   // setValue('variety', dashAdd(variety_filter).trim());
        //   setValue('variety', ['Regina'], { shouldDirty: true });
        // } else {
        //   setValue('variety', '');
        // }
      } else {
        setValue('quarter', '');
      }
    } else {
      setValue('cc', '');
    }
    handleDrawerToggle();
    // ejecuta el submit tras cerrar el drawer
    setTimeout(() => {
      handleSubmit(onSubmit)();
    }, 100);
  };

  // console.log(
  //   ccDataState,
  //   '-<-------ccDataState',
  //   quarterDataState,
  //   '-<-----quarterDataState'
  // );

  const renderFilters = () => {
    return (
      <MobileFilters>
        {errors.neitherItemlist && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.neitherItemlist.message}
          </p>
        )}
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group mb-2'>
                {/* <TextFieldWidgetSelectController
                  options={filterDataResponseState}
                  control={control}
                  titleOption='label'
                  name='orchard'
                  labeltext='Huerto'
                  errors={errors.orchard}
                  defaultValue=''
                  loading={loadingFiltersState}
                  // req
                  withDefault
                  variant='standard'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={loadingFilterCcState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='cc'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={ccDataState}
                  placeholder='Centro de Costo'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.quarter}
                  loading={loadingFilterQuarterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='quarter'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='Cuarteles'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='variety'
                  display='vertical'
                  variant='outlined'
                  isMulti={true}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Variedad'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.rootStock}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='rootStock'
                  display='vertical'
                  variant='outlined'
                  isMulti={true}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={rootStockDataState}
                  placeholder='Portainjerto'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.season}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='season'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={seasonDataState}
                  placeholder='Temporada'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>

          {orchard_filter && orchard_filter === useOrchard?.value && (
            <div className={classes.openMapContainer}>
              <Tooltip title='Ver Mapa' aria-label='mapa'>
                <IconButton
                  color='primary'
                  aria-label='open drawer'
                  edge='start'
                  size='large'
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MapIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </form>
      </MobileFilters>
    );
  };

  const drawer = (
    <div>
      <Map
        buttonClick={false}
        drawerAction={() => {}}
        quarterSelected={null}
        library={true}
        libraryFilter={clickMapHandler}
      />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const noInfoTabs = null;

  useEffect(() => {
    const moduleCounter = moduleUser.filter(
      (ele) => ele.id === '6422e4d13f15d6fddadfcb8a'
    );

    // console.log(moduleCounter, '---', moduleUser);

    let menuTabs;
    if (moduleCounter[0].processBackend) {
      menuTabs = tabItems.map((tab) => {
        const enabledTab =
          _.findIndex(moduleCounter[0].processBackend, function (p) {
            return p.id === tab.id;
          }) >= 0;

        return {
          ...tab,
          disabled: !enabledTab,
        };
      });
    } else {
      menuTabs = tabItems;
    }

    setTabsArrayState(menuTabs);
  }, [moduleUser]);

  return (
    <div className={classes.rootContent}>
      <>
        {/* <Hidden lgUp implementation="css"> */}
        <Drawer
          container={container}
          variant='temporary'
          anchor='right'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          // ModalProps={{
          //   keepMounted: true, // Better open performance on mobile.
          // }}
        >
          {drawer}
        </Drawer>
        {/* </Hidden> */}
        {/* <Hidden mdDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						anchor="right"
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden> */}
      </>
      <div className={clsx('form-style', classes.rightMarginWide)}>
        <BsContainer fluid class='px-0 px-md-3'>
          <BsRow class='no-gutters mb-md-3'>
            <BsCol class='col-12 col-md-12'>
              <Card
                elevation={0}
                variant='outlined'
                // classes={{
                // 	root: 'MainCardRoot',
                // }}
              >
                <CardContent>
                  <BsRow class=''>
                    <BsCol class='col-md-12'>
                      <Typography gutterBottom variant='h4' component='h2'>
                        Biblioteca
                      </Typography>
                      {renderFilters()}
                    </BsCol>
                  </BsRow>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
          <BsRow class='no-gutters'>
            <BsCol class='col-md-12'>
              <Card elevation={0}>
                <CardContent>
                  <SimpleTabs
                    tabitems={tabsArrayState}
                    value={valueTabState}
                    toggler={handleChangeTab}
                    tabEnabled={true}
                  >
                    <TabPanel value={valueTabState} index={0}>
                      <FirstTab
                        filter={infoFilterTab}
                        fromPrune={IntelligenceState?.fromPrune}
                        scenarySaved={IntelligenceState?.IntelligenceState}
                      />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={1}>
                      <SecondTab filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={2}>
                      <ThirdTab filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={3}>
                      <FourthTab filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={4}>
                      <FifthTab filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={5}>
                      <SixthTab filter={infoFilterTab} />
                    </TabPanel>
                    {/* <TabPanel value={valueTabState} index={6}>
                      <SeventhTab filter={noInfoTabs} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={7}>
                      <EighthTab filter={noInfoTabs} />
                    </TabPanel> */}
                    {/* <TabPanel value={valueTabState} index={8}> */}
                    {/* no se ocuparía */}
                    {/* <NinthTab filter={noInfoTabs} /> */}
                    {/* </TabPanel> */}
                    {/* <TabPanel value={valueTabState} index={9}>
                      <TenthTab filter={noInfoTabs} />
                    </TabPanel> */}
                  </SimpleTabs>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};
export default Library;
