import axios from 'axios';
import { Context } from '../context/Context';
// import getConfig from 'next/config';
// import Router, { useRouter } from 'next/router';
import qs from 'qs';
import { useContext, useMemo, useState } from 'react';
// import useHotjar from 'react-use-hotjar'
// import useLocalStorage from './useLocalStorage'

import Secrets from '../utils/secrets';

// let runtimeConfig = {};

// if (getConfig()) {
// 	runtimeConfig = {
// 		server: getConfig().serverRuntimeConfig,
// 		public: getConfig().publicRuntimeConfig,
// 	};
// }

const useAuth = () => {
	// const router = useRouter();

	const context = useContext(Context);

	// const { identifyHotjar } = useHotjar();
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState(false);
	const [user, setUser] = useState(null);
	const [accessToken, setAccessToken] = useState(null);

	// useMemo(() => {
	// 	axios.interceptors.response.use(
	// 		(response) => response,
	// 		(error) => {
	// 			if (
	// 				error.response &&
	// 				401 === error.response.status &&
	// 				router.pathname !== '/auth/login'
	// 			) {
	// 				logout();
	// 			}
	// 			return Promise.reject(error);
	// 		}
	// 	);
	// }, []);

	// useMemo(() => {
	// 	setBusy(false);
	// 	if (accessToken) {
	// 		axios.defaults.headers.common[
	// 			'Authorization'
	// 		] = `Bearer ${accessToken}`;
	// 	} else {
	// 		axios.defaults.headers.common['Authorization'] = null;
	// 	}
	// }, [user, accessToken]);

	//const baseUrl = process.env.REACT_APP_BFF_HOST || 'https://api.ceranalytics.staging.movelab.cl';

	const login = async (username, password, scopes) => {
		setBusy(true);
		setError(false);

		const BFF_HOST = process.env.REACT_APP_BFF_HOST;
		const baseUrl = BFF_HOST;
		try {
			const response = await axios.post(
				`${baseUrl}/oauth/token`,
				qs.stringify({
					client_id: '0a86a2a65681677e8e90164e0d8b5e6f73a9dbfd',
					grant_type: 'password',
					username: username,
					password: password,
					scope: scopes || 'user:me',
				}),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);
			setUser(response.data.metadata.user);
			setAccessToken(response.data.access_token);
		} catch (err) {
			setError(
				err &&
					err.response &&
					err.response.data &&
					err.response.data.error
			);
			throw err;
		} finally {
			setBusy(false);
		}
	};

	const logout = async (err = null) => {
		setUser(false);
		context.logout();
		setAccessToken(false);
		// Router.push('/auth/login');
	};

	return {
		accessToken,
		busy,
		error,
		login,
		logout,
		user,
	};
};

export default useAuth;
