import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Tooltip } from '@mui/material';
import '../style.scss';
const IconActionTableDelete = (props) => {
  const { handleActionDelete } = props;
  return (
    <Tooltip title='Eliminar' arrow className='buttonIconAction'>
      <IconButton
        classes={{
          label: 'buttonContainer buttonDanger',
        }}
        aria-label='delete'
        color='error'
        onClick={handleActionDelete}
      >
        <ClearIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export default IconActionTableDelete;
