// import _ from "lodash";
import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react';
import { useTheme } from '@mui/material';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import { DataTableWidget, FilterColumnTableWidget } from 'components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from 'context/AxiosContext';
import { Context } from 'context/Context';
import {
	// alterArrayGetIds,
	IsDesktopHandler,
	// numberFormat,
	// dashDelete,
	numberFormatDecimals,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
}));

const TenthTab = ({ filter: filterProp }) => {
	const theme = useTheme();

	// console.log(theme);

	const classes = useStyles();

	const { userOrchard } = useContext(Context);

	const axiosContext = useContext(AxiosContext);

	const isDesktop = IsDesktopHandler('md');


	const columns = useMemo(
		() => [
			{
				name: 'Temporada',
				selector: (row) => row.season,
				sortField: 'season',
				// hide: 'sm',
				sortable: true,
				id: 1,
			},
			{
				name: 'Cliente',
				selector: (row) => row.client,
				sortField: 'client',
				// hide: 'sm',
				sortable: true,
				id: 2,
			},
			{
				name: 'Agrícola',
				selector: (row) => row.agroBusiness,
				sortField: 'agroBusiness',
				// hide: 'sm',
				sortable: true,
				id: 3,
			},
			{
				name: 'Huerto',
				selector: (row) => row.orchard,
				sortField: 'orchard',
				sortable: true,
				id: 4,
			},
			{
				name: 'Especie',
				selector: (row) => row.specie,
				sortField: 'specie',
				sortable: true,
				id: 5,
			},
			{
				name: 'Centro de Costos',
				selector: (row) => row.cc,
				sortField: 'cc',
				sortable: true,
				id: 6,
			},
			{
				name: 'Cuartel',
				selector: (row) => row.quarter,
				sortField: 'quarter',
				// hide: 'md',
				sortable: true,
				id: 7,
			},
			{
				name: 'Variedad',
				selector: (row) => row.variety,
				sortField: 'variety',
				// hide: 'sm',
				sortable: true,
				id: 8,
			},
			{
				name: 'Portainjerto',
				selector: (row) => row.rootStock,
				sortField: 'rootStock',
				// hide: 'sm',
				sortable: true,
				id: 9,
			},
			{
				name: 'Calibre embalado m (%)',
				selector: (row) => row.mPacked,
				sortField: 'mPacked',
				format: (row) => numberFormatDecimals(row.mPacked, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 10,
			},
			{
				name: 'Calibre embalado L (%)',
				selector: (row) => row.lPacked,
				sortField: 'lPacked',
				format: (row) => numberFormatDecimals(row.lPacked, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 11,
			},
			{
				name: 'Calibre embalado XL (%)',
				selector: (row) => row.xlPacked,
				sortField: 'xlPacked',
				format: (row) => numberFormatDecimals(row.xlPacked, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 12,
			},
			{
				name: 'Calibre embalado J (%)',
				selector: (row) => row.jPacked,
				sortField: 'jPacked',
				format: (row) => numberFormatDecimals(row.jPacked, 1),
				sortable: true,
				compact: true,
				id: 13,
			},
			{
				name: 'Calibre embalado SJ (%)',
				selector: (row) => row.sjPacked,
				sortField: 'sjPacked',
				format: (row) => numberFormatDecimals(row.sjPacked, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 14,
			},
			{
				name: 'Calibre embalado P (%)',
				selector: (row) => row.pPacked,
				sortField: 'pPacked',
				format: (row) => numberFormatDecimals(row.pPacked, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 15,
			},
			{
				name: 'Calibre embalado G (%)',
				selector: (row) => row.gPacked,
				sortField: 'gPacked',
				format: (row) => numberFormatDecimals(row.gPacked, 1),
				sortable: true,
				compact: true,
				id: 16,
			},
			{
				name: 'Suma kg exportados (variedad/huerto)',
				selector: (row) => row.kgExpVarOrchard,
				sortField: 'kgExpVarOrchard',
				format: (row) => numberFormatDecimals(row.kgExpVarOrchard, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 17,
			},
			{
				name: 'Suma kg cosecha',
				selector: (row) => row.kgHarvestingVarOrchard,
				sortField: 'kgHarvestingVarOrchard',
				format: (row) =>
					numberFormatDecimals(row.kgHarvestingVarOrchard, 1),
				// hide: 'md',
				compact: true,
				sortable: true,
				id: 18,
			},
			{
				name: 'Exportable real proceso (%)',
				selector: (row) => row.PercExpReal,
				sortField: 'PercExpReal',
				format: (row) =>
					numberFormatDecimals(row.PercExpReal, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 19,
			},
		],
		[]
	);

	// console.log(filterProp, "<---llega el filtro");

	const [tableColumnsState, setTableColumnsState] = useState(columns);

	const toggleColumn = (id) => {
		let columns = [...tableColumnsState];
		columns[id].omit = !columns[id].omit;
		setTableColumnsState(columns);
	};

	const toggleAllColumn = (state) => {
		// console.log(state, 'togle all', tableColumnsState);
		let columnsToogleAll = [...tableColumnsState];
		columnsToogleAll.map((col) => (col.omit = !state));
		setTableColumnsState(columnsToogleAll);
	};

	// table
	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [countState, setCountState] = useState(0);
	const [pageState, setPageState] = useState(1);
	const [sortState, setSortState] = useState({
		key: 'huerto',
		option: 'asc',
	});
	const [limitState, setLimitState] = useState(10);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);
	const [busyState, setBusyState] = useState(false);

	const [anchorElState, setAnchorElState] = useState(null);

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				// '&: nth-last-child(-n+3)': {
				//     backgroundColor: theme.palette.primary.main,
				//     borderBottom: '4px solid',
				//     borderColor: theme.palette.secondary.light
				// },
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	async function queryData(page, sort = sortState) {
		setLoadingDataTableState(true);
		try {
			// console.log("llega la data",limitState, "----",pageState,"---",filterProp);

			const {
				huerto,
				centro_costo,
				cuartel,
				variedad,
				porta_injerto,
				temporada,
			} = filterProp;

			let objQuery = {
				huerto: huerto,
				huerto_user: userOrchard, //orchard del login
				centro_costo: centro_costo,
				cuartel: cuartel,
				variedad: variedad,
				rootStock: porta_injerto,
				season: temporada,
				page: page,
				limit: limitState,
				sort: sort ? sort : sortState,
			};

			// console.log(objQuery, "---la query que va");

			const response = await axiosContext.authAxios.post(
				'v1/library/packing',
				objQuery
			);

			const { data, status } = response;

			// console.log(response, "<-----response query")

			// setCountState(data.totalPassengers);
			if (data.data) {
				// setTableDataState(data.data);
				console.log('eeeeeeee', data);
				setCountState(data.count);
				setTableDataState(data.data);
				setPageState(page);
				setSortState(sort);
				setBusyState(false);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setBusyState(false);
			setLoadingDataTableState(false);
		}
	}

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		// let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];
		let nameColumn = column.sortField;
		// nameColumn = undefined;
		// console.log(nameColumn, '<---nombre de la col que llega');
		if (nameColumn !== null) {
			console.log({ key: nameColumn, option: sortDirection });
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			// console.log("llega la data",limitState, "----",pageState,"---",filterProp);

			const {
				huerto,
				centro_costo,
				cuartel,
				variedad,
				porta_injerto,
				temporada,
			} = filterProp;

			let objQuery = {
				huerto: huerto,
				huerto_user: userOrchard, //orchard del login
				centro_costo: centro_costo,
				cuartel: cuartel,
				variedad: variedad,
				rootStock: porta_injerto,
				season: temporada,
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/library/packing',
				objQuery
			);

			const { data, status } = response;

			console.log(response, '<-----response change rows');

			// setCountState(data.totalPassengers);
			if (data.data) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setCountState(data.count);
				setTableDataState(data.data);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	const handleFilterClick = useCallback((event) => {
		setAnchorElState(event.currentTarget);
	}, []);

	const handleFilterClose = useCallback(() => {
		setAnchorElState(null);
	}, []);

	useEffect(() => {
		if (filterProp) {
			console.log('cambio filters');
			setResetPaginationToggleState(!resetPaginationToggleState);
			// setPageState(1);
			queryData(1);
			setBusyState(true);
		}
	}, [filterProp]);

	return (
		<BsRow class="justify-content-between" style={{ marginTop: -20 }}>
			<BsCol class="col-12">
				<div className={classes.tableContainerStyle}>
					{tableDataState.length > 0 && (
						<FilterColumnTableWidget
							anchorState={anchorElState}
							handleFilterClick={handleFilterClick}
							handleFilterClose={handleFilterClose}
							filterOptions={isDesktop ? columns : columns}
							toggleColumn={toggleColumn}
							toggleAllColumn={toggleAllColumn}
						/>
					)}
					<DataTableWidget
						totalRows={countState}
						handleOnSort={handleOnSort}
						// conditionalRowStyles={conditionalRowStyles}
						// sortFunction={customSort}
						fixedHeader={true}
						fixedHeaderScrollHeight={'590px'}
						changePagination={handleChangePagination}
						rowsChange={handleChangeRows}
						columns={tableColumnsState}
						sortField="1"
						filteredItems={tableDataState}
						// subHeaderComponentMemo={subHeaderComponentMemo}
						loading={loadingDataTableState}
						loadingCount={loadingDataTableState}
						customStyles={customStyles}
						moduleName="Packing"
						resetPaginationToggle={resetPaginationToggleState}
					/>
				</div>
			</BsCol>
		</BsRow>
	);
};

export default TenthTab;
