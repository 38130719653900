import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  IconButton,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { AxiosContext } from '../../../context/AxiosContext';
import { SaveAction, TextFieldWidgetDateController } from '../../../components';
import CjIcon from '../../../assets/icons/schedule/cj_icon.png';
import MexicoIcon from '../../../assets/icons/schedule/mexico_icon.png';
import QuarantineIcon from '../../../assets/icons/schedule/quarantine_icon.png';
import GraspIcon from '../../../assets/icons/schedule/grasp_icon.png';
import FsmaIcon from '../../../assets/icons/schedule/fsma_icon.png';
import GlobalGapIcon from '../../../assets/icons/schedule/globalgap_icon.png';
import Tn11TescoIcon from '../../../assets/icons/schedule/tn11_icon.png';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import clsx from 'clsx';
import { dateFormatIso } from 'helpers';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 7),
    width: '60%',
    height: '50vh',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  tableForm: {
    '& td': {
      width: '14%',
    },
  },
}));

const ModalEdit = ({ closeAction, dataModal, isEditedFunc }) => {
  const theme = useTheme();

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const errorHandler = useErrorHandler();

  const actualYear = new Date().getFullYear();

  const [loadingState, setLoadingState] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const [disabledCjState, setDisabledCjState] = useState(false);
  const [disabledMexicoState, setDisabledMexicoState] = useState(false);
  const [disabledQuarantineState, setDisabledQuarantineState] = useState(false);
  const [disabledGlobalGapState, setDisabledGlobalGapState] = useState(false);
  const [disabledTn11TescoState, setDisabledTn11TescoState] = useState(false);
  const [disabledGraspState, setDisabledGraspState] = useState(false);
  const [disabledFsmaState, setDisabledFsmaState] = useState(false);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  console.log(dataModal, '----dataModal');

  const onSubmit = async (values) => {
    setLoadingState(true);
    console.log(values, '----values');

    // crear en base a values recibidos el array de certificados
    const certificatesArray = [];
    if (dataModal.type === 'add') {
      if (values.cj && dataModal.cj !== 'add') {
        certificatesArray.push('c/j');
      }
      if (values.mexico && dataModal.mexico !== 'add') {
        certificatesArray.push('mexico');
      }
      if (values.quarantine && dataModal.quarantine !== 'add') {
        certificatesArray.push('quarantine');
      }

      if (values.globalGap && dataModal.globalGap !== 'add') {
        certificatesArray.push('globalGap');
      }

      if (values.tn11_Tesco && dataModal.tesco !== 'add') {
        certificatesArray.push('tesco');
      }

      if (values.grasp && dataModal.grasp !== 'add') {
        certificatesArray.push('grasp');
      }

      if (values.fsma && dataModal.fsma !== 'add') {
        certificatesArray.push('fsma');
      }
    } else {
      if (values.cj && dataModal.cj !== 'delete') {
        certificatesArray.push('c/j');
      }
      if (values.mexico && dataModal.mexico !== 'delete') {
        certificatesArray.push('mexico');
      }
      if (values.quarantine && dataModal.quarantine !== 'delete') {
        certificatesArray.push('quarantine');
      }
      if (values.globalGap && dataModal.globalGap !== 'delete') {
        certificatesArray.push('globalGap');
      }

      if (values.tn11_Tesco && dataModal.tesco !== 'delete') {
        certificatesArray.push('tesco');
      }

      if (values.grasp && dataModal.grasp !== 'delete') {
        certificatesArray.push('grasp');
      }

      if (values.fsma && dataModal.fsma !== 'delete') {
        certificatesArray.push('fsma');
      }
    }

    const bodyQuery = {
      client: dataModal.client,
      clientValue: dataModal.clientValue,
      orchard: dataModal.orchard,
      orchardValue: dataModal.orchardValue,
      variety: dataModal.variety,
      varietyValue: dataModal.varietyValue,
      specie: dataModal.specie || '',
      specieValue: dataModal.specieValue || '',
      productLine: dataModal.productLine || '',
      productLineValue: dataModal.productLineValue || '',
      type: dataModal.type,
      date: dateFormatIso(values.date),
      certificates: certificatesArray,
    };

    console.log(bodyQuery, '----bodyQuery');

    try {
      const response = await axiosContext.authAxios.post(
        'v1/planningHarvestCertificate',
        bodyQuery
      );
      const { data, status } = response;

      console.log(data, status, '-<<---------response register');

      if (status === 200) {
        // setExpectedInfoState(data);
        setLoadingState(false);
        setSuccessState(true);
        isEditedFunc(data);
      }
    } catch (error) {
      console.error(error);
      errorHandler(error);
      setLoadingState(false);
    }
  };

  const closeHandler = () => {
    setSuccessState(false);
    closeAction();
  };

  const textTitle = dataModal.type === 'add' ? 'Agregar' : 'Eliminar';
  const iconTitle =
    dataModal.type === 'add' ? 'fas fa-plus-square' : 'fas fa-minus-square';

  useEffect(() => {
    if (dataModal) {
      const {
        cj,
        mexico,
        quarantine,
        globalGap,
        variety,
        tesco,
        grasp,
        fsma,
        type,
      } = dataModal;
      console.log(dataModal, '----dataModal');
      if (cj === 'add' && type === 'add') {
        setValue('cj', true);
        setDisabledCjState(true);
      }
      if (mexico === 'add' && type === 'add') {
        setValue('mexico', true);
        setDisabledMexicoState(true);
      }

      if (quarantine === 'add' && type === 'add') {
        setValue('quarantine', true);
        setDisabledQuarantineState(true);
      }

      if (globalGap === 'add' && type === 'add') {
        setValue('globalGap', true);
        setDisabledGlobalGapState(true);
      }

      if (tesco === 'add' && type === 'add') {
        setValue('tn11_Tesco', true);
        setDisabledTn11TescoState(true);
      }

      if (grasp === 'add' && type === 'add') {
        setValue('grasp', true);
        setDisabledGraspState(true);
      }

      if (fsma === 'add' && type === 'add') {
        setValue('fsma', true);
        setDisabledFsmaState(true);
      }

      if (cj === 'delete' && type === 'delete') {
        setValue('cj', true);
        setDisabledCjState(true);
      }
      if (mexico === 'delete' && type === 'delete') {
        setValue('mexico', true);
        setDisabledMexicoState(true);
      }

      if (quarantine === 'delete' && type === 'delete') {
        setValue('quarantine', true);
        setDisabledQuarantineState(true);
      }

      if (globalGap === 'delete' && type === 'delete') {
        setValue('globalGap', true);
        setDisabledGlobalGapState(true);
      }

      if (tesco === 'delete' && type === 'delete') {
        setValue('tn11_Tesco', true);
        setDisabledTn11TescoState(true);
      }

      if (grasp === 'delete' && type === 'delete') {
        setValue('grasp', true);
        setDisabledGraspState(true);
      }

      if (fsma === 'delete' && type === 'delete') {
        setValue('fsma', true);
        setDisabledFsmaState(true);
      }
    }
  }, [dataModal]);

  console.log(successState, '---successState');

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-center'>
              <Typography variant='h5' gutterBottom>
                <i
                  className={clsx(iconTitle, 'mr-2')}
                  style={{
                    color: dataModal.type === 'add' ? '#43a047' : '#e53935',
                  }}
                ></i>
                {textTitle} Certificación - Huerto: {dataModal.orchard} -
                Variedad: {dataModal.variety}
              </Typography>
              <BsRow class='mt-5'>
                <BsCol class='col-md-12'>
                  {/* {JSON.stringify(dataModal, null, 2)} */}

                  <form
                    autoComplete='false'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* clase bootrap 4 para centrar las columnas */}
                    <BsRow class=' justify-content-md-center'>
                      <BsCol class='col-12'>
                        <table className={clsx('table', classes.tableForm)}>
                          <tbody>
                            <tr>
                              <td className={''}>
                                <img
                                  src={CjIcon}
                                  width='52'
                                  className='img-fluid img-estimated'
                                  alt='Mexico'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  CJ
                                </Typography>
                                <Controller
                                  name={`cj`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledCjState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                              <td className={''}>
                                <img
                                  src={MexicoIcon}
                                  width='25'
                                  className='img-fluid img-estimated'
                                  alt='Mexico'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  México
                                </Typography>
                                <Controller
                                  name={`mexico`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledMexicoState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                              <td className={''}>
                                <img
                                  src={QuarantineIcon}
                                  width='25'
                                  className='img-fluid img-estimated'
                                  alt='Mexico'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  Cuarentena
                                </Typography>
                                <Controller
                                  name={`quarantine`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledQuarantineState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                              <td className={''}>
                                <img
                                  src={GlobalGapIcon}
                                  width='25'
                                  className='img-fluid img-estimated'
                                  alt='Global Gap'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  Global Gap
                                </Typography>
                                <Controller
                                  name={`globalGap`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledGlobalGapState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                              <td className={''}>
                                <img
                                  src={Tn11TescoIcon}
                                  width='25'
                                  className='img-fluid img-estimated'
                                  alt='Tn11 Tesco'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  Tn11 Tesco
                                </Typography>
                                <Controller
                                  name={`tn11_Tesco`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledTn11TescoState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                              <td className={''}>
                                <img
                                  src={GraspIcon}
                                  width='25'
                                  className='img-fluid img-estimated'
                                  alt='Grasp'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  Grasp
                                </Typography>
                                <Controller
                                  name={`grasp`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledGraspState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                              <td className={''}>
                                <img
                                  src={FsmaIcon}
                                  width='25'
                                  className='img-fluid img-estimated'
                                  alt='Fsma'
                                />
                                <Typography
                                  variant='body1'
                                  className={classes.root}
                                >
                                  Fsma
                                </Typography>
                                <Controller
                                  name={`fsma`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Checkbox
                                      disabled={disabledFsmaState}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </BsCol>
                    </BsRow>
                    <BsRow class='justify-content-md-center'>
                      <BsCol class='col-12 col-lg-6 text-center'>
                        <div className='text-center'>
                          <TextFieldWidgetDateController
                            name='date'
                            labeltext='Fecha'
                            // value={sinceSelectedState}
                            // onChange={date => setSinceSelectedState(date)}
                            minDate={minDateValidationState}
                            control={control}
                            errors={errors.since}
                            format='dd-MM-yyyy'
                            defaultValue={new Date()}
                          />
                        </div>
                      </BsCol>
                    </BsRow>
                    <BsRow class='justify-content-center mt-5'>
                      <BsCol class='col-md-3'>
                        <SaveAction
                          title='Guardar'
                          color='secondary'
                          size='large'
                          fullWidth={true}
                          disabled={loadingState}
                          actionClose={closeHandler}
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingState}
                          success={successState}
                        />
                      </BsCol>
                    </BsRow>
                  </form>
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalEdit;
