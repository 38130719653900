import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import InfoIcon from '@mui/icons-material/Info';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Box,
  TextField,
  Typography,
  useTheme,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import { useErrorHandler } from 'react-error-boundary';
import {
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom/cjs/react-router-dom';
import { styled } from '@mui/styles';
import NumberFormat from 'react-number-format';
import {
  ButtonAction,
  CheckboxWidgetUncontrolled,
  // DataTableWidget,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetText2,
  // TextFieldWidgetDateController,
} from '../../../components';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';

import { dashDelete, alterArrayGetFields } from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      color: theme.palette.tertiary.main,
      fontWeight: 500,
    },
    divider: {
      background: theme.palette.grey[400],
      marginTop: 10,
    },
    cardContentRoot: {
      padding: 15,
      [theme.breakpoints.up('lg')]: {
        '& div.col-xl-9': {
          width: '78%',
          maxWidth: '78%',
          flex: '0 0 78%',
        },
        '& div.col-xl-3': {
          width: '22%',
          maxWidth: '22%',
          flex: '0 0 22%',
        },
      },
    },
    tableStyles: {
      marginTop: 5,
      '& td': {
        padding: '0.45rem',
        fontSize: 14,
      },
    },
    infoCol: {
      fontWeight: 500,
    },
    theadLight: {
      '& th': {
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[300],
      },
      '& td': {
        verticalAlign: 'middle !important',
      },
    },
    colorBox: {
      width: '80px',
      height: '35px',
    },
    tbodyStyle: {
      '& td': {
        verticalAlign: 'middle',
      },
      '& tr.selected': {
        backgroundColor: '#f7faff',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrowsContainerStyle: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    subtitleDash: {
      fontSize: 18,
    },
    paperContainerStyle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // backgroundColor: theme.palette.grey[100],
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      '& h6': {
        color: theme.palette.primary.contrastText,
      },
      '& h2': {
        color: theme.palette.primary.contrastText,
      },
    },
    bgCellStyle: {
      backgroundColor: theme.palette.primary.main + '!important',
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      fontWeight: 700,
    },
    fullWidthStyle: {
      width: '100%',
    },
    backbuttonStyle: {
      position: 'absolute',
      top: 20,
      right: 10,
    },
    dialogTitleStyle: {
      fontWeight: '500',
      fontSize: 18,
    },
    dialogTextStyle: {
      fontWeight: '500',
      color: theme.palette.text.primary,
    },
    popover: {
      pointerEvents: 'none',
    },
    popOverContainer: {
      padding: theme.spacing(1),
      '& p': {
        marginBottom: theme.spacing(1),
        color: theme.palette.grey[700],
      },
    },
    rootHelper: {
      fontSize: 13,
      lineHeight: 1.3,
    },
    inputText: {
      fontSize: 26,
      '&::placeholder': {
        fontSize: '17px',
      },
    },
  };
});

const endpoint = 'v1/sampling/history-grid';
const endpointCopy = 'v1/sampling/copy-plant-in-grid';

const gridHistory = [
  {
    id: 1,
    year: 2021,
    plantsCount: 15,
    treeWorkGrid: [
      {
        id: '654d2846217c3d98d9faca41',
        deh: null,
        dsh: null,
        lat: null,
        long: null,
        zone: '1',
        dart: 72,
        twig: 38,
        fruit: 48,
        geom: {
          type: 'Point',
          coordinates: [[-71.8034, -35.2546]],
        },
        angle: null,
        client: null,
        specie: null,
        ccValue: 'Vina',
        rowNumber: '7',
        plantNumber: '9',
        agroBusiness: null,
        orchardValue: 'Botalcura',
        quarterValue: '10-Santina',
        varietyValue: 'Santina',
        statusBuffer: true,
        statusSelect: true,
        rootStockValue: 'Maxma_14',
      },
      {
        id: '654d2846217c3d98d9faca41',
        deh: null,
        dsh: null,
        lat: null,
        long: null,
        zone: '2',
        dart: 2,
        twig: 3,
        fruit: 4,
        geom: {
          type: 'Point',
          coordinates: [[-71.8034, -35.2546]],
        },
        angle: null,
        client: null,
        specie: null,
        ccValue: 'Vina',
        rowNumber: '5',
        plantNumber: '11',
        agroBusiness: null,
        orchardValue: 'Botalcura',
        quarterValue: '10-Santina',
        varietyValue: 'Santina',
        statusBuffer: true,
        statusSelect: true,
        rootStockValue: 'Maxma_14',
      },
    ],
  },
  {
    id: 2,
    year: 2022,
    plantsCount: 20,
    treeWorkGrid: [
      {
        id: '654d2846217c3d98d9faca41',
        deh: null,
        dsh: null,
        lat: null,
        long: null,
        zone: '1',
        dart: 5,
        twig: 5,
        fruit: 4,
        geom: {
          type: 'Point',
          coordinates: [[-71.8034, -35.2546]],
        },
        angle: null,
        client: null,
        specie: null,
        ccValue: 'Vina',
        rowNumber: '7',
        plantNumber: '23',
        agroBusiness: null,
        orchardValue: 'Botalcura',
        quarterValue: '10-Santina',
        varietyValue: 'Santina',
        statusBuffer: true,
        statusSelect: true,
        rootStockValue: 'Maxma_14',
      },
      {
        id: '654d2846217c3d98d9faca41',
        deh: null,
        dsh: null,
        lat: null,
        long: null,
        zone: '2',
        dart: 2,
        twig: 3,
        fruit: 4,
        geom: {
          type: 'Point',
          coordinates: [[-71.8034, -35.2546]],
        },
        angle: null,
        client: null,
        specie: null,
        ccValue: 'Vina',
        rowNumber: '2',
        plantNumber: '8',
        agroBusiness: null,
        orchardValue: 'Botalcura',
        quarterValue: '10-Santina',
        varietyValue: 'Santina',
        statusBuffer: true,
        statusSelect: true,
        rootStockValue: 'Maxma_14',
      },
    ],
  },
];

const HistorySampling = (props) => {
  const { userId, userHuerto, userName } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const [successCopyState, setSuccessCopyState] = useState(false);
  const [loadingCopyState, setLoadingCopyState] = useState(false);

  const errorHandler = useErrorHandler();

  const history = useHistory();

  // console.log(grid, '----grid');

  const handleGoBack = () => {
    history.goBack();
  };

  // pop over info
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopoverState = Boolean(anchorEl);

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    season_filter,
    variety_filter,
    rootstock_filter,
    userOrchard_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);

  const [infoGridsState, setInfoGridsState] = useState([]);

  const [loadingInfoGridsState, setLoadingInfoGridsState] = useState(false);

  const [plantsSelectedGridState, setPlantsSelectedGridState] = useState(null);

  const [openPromptState, setOpenPromptState] = useState(false);

  const theme = useTheme();

  // console.log(theme, '----zoneColors');
  const { classes } = useStyles();

  const sortArrayFunc = (array) => {
    array.sort(function (a, b) {
      if (parseInt(a.rowNumber) === parseInt(b.rowNumber)) {
        return parseInt(a.plantNumber) - parseInt(b.plantNumber);
      }
      return parseInt(a.rowNumber) > parseInt(b.rowNumber) ? 1 : -1;
    });
    return array;
  };

  const handleClosePrompt = () => {
    setOpenPromptState(false);
  };

  const handleCopyGrid = async () => {
    // TODO: ejecutar endpoint de delete
    // console.log(plantToDeleteState, 'se borra');
    setLoadingCopyState(true);

    const objCopy = {
      orchardValue: orchard_filter,
      ccValue: cc_filter,
      quarterValue: quarter_filter,
      seasonValue: season_filter,
      varietyValue: variety_filter,
      rootstockValue: rootstock_filter,
      grid: plantsSelectedGridState,
    };

    try {
      const response = await axiosContext.authAxios.post(endpointCopy, objCopy);

      // console.log(response, '------response');
      const { status, data } = response;
      if (status === 200) {
        console.log(data, '----data');

        setSuccessCopyState(true);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingCopyState(false);
    }
  };

  const onCloseSnack = () => {
    // console.log('------close');
    setSuccessCopyState(false);
    handleClosePrompt();
  };

  const getGridsAsync = async () => {
    setLoadingInfoGridsState(true);

    try {
      const response = await axiosContext.authAxios.get(endpoint, {
        params: {
          orchardValue: orchard_filter,
          ccValue: cc_filter,
          quarterValue: quarter_filter,
          varietyValue: variety_filter,
          rootstockValue: rootstock_filter,
        },
      });

      const { status, data } = response;
      if (status === 200) {
        // console.log(data, '----data');
        setInfoGridsState(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingInfoGridsState(false);
    }
  };

  useEffect(() => {
    //  getGridsAsync();
    setInfoGridsState(gridHistory);
  }, []);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters mb-md-2'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-6'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Histórico de Grilla
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-6 text-right'>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => handleGoBack()}
                    >
                      Volver
                    </Button>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>

        <BsRow class='gutter_10'>
          {/* tabla años Grilla */}
          <BsCol class='col-md-6 col-lg-5'>
            <Card elevation={0}>
              <CardContent className={classes.cardContentRoot}>
                <BsRow class='mt-1'>
                  <BsCol class='col-md-12'>
                    <BsRow class='justify-content-between align-items-center'>
                      <BsCol class='col-md-12 mb-3'>
                        <Typography
                          variant='h5'
                          component='h2'
                          color='secondary'
                        >
                          Grillas de: {dashDelete(orchard_filter)} |{' '}
                          {dashDelete(cc_filter)} | {dashDelete(quarter_filter)}{' '}
                          | {dashDelete(variety_filter)} |{' '}
                          {dashDelete(rootstock_filter)}
                        </Typography>
                      </BsCol>
                    </BsRow>
                  </BsCol>
                  <BsCol class='col-md-12'>
                    <BsRow>
                      <BsCol class='col-md-12'>
                        <Box
                          className='table-responsive'
                          sx={{
                            maxHeight: 650,
                            border: '1px solid',
                            borderRadius: theme.shape.borderRadius + 'px',
                            borderColor: theme.palette.grey[300],
                            padding: theme.spacing(2),
                          }}
                        >
                          <Typography variant='h5'>
                            Listado
                            <span className='mx-3'>
                              <IconButton
                                aria-describedby='dd'
                                variant='text'
                                color='primary'
                                size='small'
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                              >
                                <InfoIcon />
                              </IconButton>
                            </span>
                          </Typography>

                          <Popover
                            id='simple-popover'
                            open={openPopoverState}
                            className={classes.popover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                          >
                            <div className={classes.popOverContainer}>
                              <Typography variant='caption' component={'p'}>
                                Grillas disponibles para el Cuartel
                                Seleccionado.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                Una de estas Grillas podrá copiar para utilizar
                                en la temporada actual.
                              </Typography>
                            </div>
                          </Popover>
                          <table
                            className={clsx('table mt-2', classes.theadLight)}
                          >
                            <thead>
                              <tr>
                                <th scope='col'>Año</th>
                                <th scope='col'>Cantidad de Plantas</th>
                                <th scope='col'>Acción</th>
                              </tr>
                            </thead>
                            <tbody className={classes.tbodyStyle}>
                              {_.sortBy(infoGridsState, ['year']).map(
                                (grid, i) => {
                                  return (
                                    <tr
                                      key={`${grid.id}_${i}`}
                                      style={{
                                        backgroundColor:
                                          plantsSelectedGridState?.id ===
                                          grid.id
                                            ? theme.palette.grey[50]
                                            : '',
                                      }}
                                    >
                                      <td>{grid.year}</td>
                                      <td>{grid.plantsCount}</td>
                                      <td>
                                        <Tooltip
                                          title='Ver Detalle'
                                          placement='right'
                                          arrow
                                        >
                                          <IconButton
                                            aria-label='Detalle'
                                            size='medium'
                                            disabled={loadingCopyState}
                                            onClick={() => {
                                              setPlantsSelectedGridState(grid);
                                            }}
                                          >
                                            <SearchIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </Box>
                      </BsCol>
                    </BsRow>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
          {/* tabla grilla Detalle */}
          <BsCol class='col-md-6 col-lg-7'>
            <Card elevation={0}>
              <CardContent className={classes.cardContentRoot}>
                <BsRow class='mt-1'>
                  {plantsSelectedGridState && (
                    <BsCol class='col-md-12'>
                      <Box
                        className='table-responsive'
                        sx={{
                          maxHeight: 550,
                          border: '1px solid',
                          borderRadius: theme.shape.borderRadius + 'px',
                          borderColor: theme.palette.grey[300],
                          padding: theme.spacing(1),
                        }}
                        id='ifmcontentstoprint'
                      >
                        <table
                          className={clsx('table table-sm', classes.theadLight)}
                        >
                          <thead>
                            <tr>
                              <th colSpan={6}>Detalle Plantas</th>
                            </tr>
                            <tr>
                              <th scope='col'>Zona</th>
                              <th scope='col'>Nº Hilera</th>
                              <th scope='col'>Nº Planta</th>
                              <th scope='col'>Dardos</th>
                              <th scope='col'>Ramillas</th>
                              <th scope='col'>Frutos</th>
                            </tr>
                          </thead>
                          <tbody className={classes.tbodyStyle}>
                            {_.sortBy(
                              sortArrayFunc(
                                plantsSelectedGridState.treeWorkGrid
                              ),
                              ['zone']
                            ).map((plant, i) => {
                              return (
                                <tr key={`${plant.id}_${i}`}>
                                  <td>{plant.zone}</td>
                                  <td>{plant.rowNumber}</td>
                                  <td>{plant.plantNumber}</td>
                                  <td>{plant.dart}</td>
                                  <td>{plant.twig}</td>
                                  <td>{plant.fruit}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <Box
                          sx={{
                            textAlign: 'right',
                          }}
                        >
                          <ButtonAction
                            labelButton='Copiar Grilla'
                            color='primary'
                            actionClose={onCloseSnack}
                            variant='contained'
                            fullWidth={false}
                            actionOnClick={() => setOpenPromptState(true)}
                            disabled={loadingCopyState}
                            messageType='edit'
                            mutationLoading={loadingCopyState}
                            success={successCopyState}
                          />
                        </Box>
                      </Box>
                    </BsCol>
                  )}
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
      {plantsSelectedGridState ? (
        <Dialog
          open={openPromptState}
          onClose={handleClosePrompt}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            className={clsx('delete-prompt', classes.dialogTitleStyle)}
          >
            Copiar Grilla
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              className={classes.dialogTextStyle}
            >
              ¿Seguro desea copiar la Grilla Seleccionada como Grilla Actual?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button onClick={handleClosePrompt} disabled={loadingCopyState}>
              Cancelar
            </Button>
            {/* <Button
              color='error'
              onClick={handleDeletePlant}
              autoFocus
              disabled={loadingDeleteState}
            >
              Eliminar
            </Button> */}
            <ButtonAction
              labelButton='Copiar'
              color='primary'
              // size='large'
              actionClose={onCloseSnack}
              variant='outlined'
              fullWidth={false}
              actionOnClick={handleCopyGrid}
              disabled={loadingCopyState}
              messageType='edit'
              mutationLoading={loadingCopyState}
              success={successCopyState}
            />
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};
export default HistorySampling;
