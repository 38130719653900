import { Paper } from '@mui/material';

const CardPaper = (props) => {
  const {
    elevation,
    topColor,
    children,
    style,
    borderTop = 4,
    variant = 'elevation',
    ...others
  } = props;
  return (
    <Paper
      sx={
        variant === 'elevation'
          ? { borderTop: borderTop, borderTopColor: topColor, ...style }
          : { ...style }
      }
      variant={variant}
      elevation={elevation}
      {...others}
    >
      {children}
    </Paper>
  );
};

export default CardPaper;
