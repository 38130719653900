// import _ from "lodash";
import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react';
import { useTheme } from '@mui/material';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import { DataTableWidget, FilterColumnTableWidget } from 'components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from 'context/AxiosContext';
import { Context } from 'context/Context';
import {
	// alterArrayGetIds,
	IsDesktopHandler,
	// numberFormat,
	// dashDelete,
	numberFormatDecimals,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
}));

const NinthTab = ({ filter: filterProp }) => {
	const theme = useTheme();

	// console.log(theme);

	const classes = useStyles();

	const { userOrchard } = useContext(Context);

	const axiosContext = useContext(AxiosContext);

	const isDesktop = IsDesktopHandler('md');

	// no se está ocupando

	const columns = useMemo(
		() => [
			{
				name: 'Huerto',
				selector: (row) => row.huerto,
				sortField: 'huerto',
				sortable: true,
				id: 1,
			},
			{
				name: 'Centro de Costos',
				selector: (row) => row.centro_costo,
				sortField: 'centro_costo',
				sortable: true,
				id: 2,
			},
			{
				name: 'Cuartel',
				selector: (row) => row.cuartel,
				sortField: 'cuartel',
				// hide: 'md',
				sortable: true,
				id: 3,
			},
			{
				name: 'Variedad',
				selector: (row) => row.variety,
				sortField: 'variety',
				// hide: 'sm',
				sortable: true,
				id: 4,
			},
			{
				name: 'Portainjerto',
				selector: (row) => row.pattern,
				sortField: 'pattern',
				// hide: 'sm',
				sortable: true,
				id: 5,
			},
			{
				name: 'Temporada',
				selector: (row) => row.season_year,
				sortField: 'season_year',
				// hide: 'sm',
				sortable: true,
				id: 6,
			},
			{
				name: 'Frutos blandos (%)',
				selector: (row) => row.f_blandos,
				sortField: 'f_blandos',
				format: (row) => numberFormatDecimals(row.f_blandos, 1),
				sortable: true,
				compact: true,
				id: 7,
			},
			{
				name: 'Daño de pajaro (%)',
				selector: (row) => row.f_d_pajaro,
				sortField: 'f_d_pajaro',
				format: (row) => numberFormatDecimals(row.f_d_pajaro, 1),
				sortable: true,
				compact: true,
				id: 8,
			},
			{
				name: 'Fruto deshidratado (%)',
				selector: (row) => row.f_deshid,
				sortField: 'f_deshid',
				format: (row) => numberFormatDecimals(row.f_deshid, 1),
				// hide: 'md',
				compact: true,
				sortable: true,
				id: 9,
			},
			{
				name: 'Herida abierta (%)',
				selector: (row) => row.f_herida_abierta,
				sortField: 'f_herida_abierta',
				format: (row) => numberFormatDecimals(row.f_herida_abierta, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 10,
			},
			{
				name: 'Inicio de deshidratación (%)',
				selector: (row) => row.f_inicio_deshid,
				sortField: 'f_inicio_deshid',
				format: (row) => numberFormatDecimals(row.f_inicio_deshid, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 11,
			},
			{
				name: 'Machucón (%)',
				selector: (row) => row.f_machucon,
				sortField: 'f_machucon',
				format: (row) => numberFormatDecimals(row.f_machucon, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 12,
			},
			{
				name: 'Mancha de pudrición (%)',
				selector: (row) => row.f_mancha_pud,
				sortField: 'f_mancha_pud',
				format: (row) => numberFormatDecimals(row.f_mancha_pud, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 13,
			},
			{
				name: 'Partidura de agua (%)',
				selector: (row) => row.f_partidura_agua,
				sortField: 'f_partidura_agua',
				format: (row) => numberFormatDecimals(row.f_partidura_agua, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 14,
			},
			{
				name: 'Partidura estilar (%)',
				selector: (row) => row.f_partidura_estilar,
				sortField: 'f_partidura_estilar',
				format: (row) =>
					numberFormatDecimals(row.f_partidura_estilar, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 15,
			},
			{
				name: 'Frutos con partiduras (%)',
				selector: (row) => row.f_partiduras,
				sortField: 'f_partiduras',
				format: (row) => numberFormatDecimals(row.f_partiduras, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 16,
			},
			{
				name: 'Pitting (%)',
				selector: (row) => row.f_pitting,
				sortField: 'f_pitting',
				format: (row) => numberFormatDecimals(row.f_pitting, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 17,
			},
			{
				name: 'Pudrición negra (%)',
				selector: (row) => row.f_pud_negra,
				sortField: 'f_pud_negra',
				format: (row) => numberFormatDecimals(row.f_pud_negra, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 18,
			},
			{
				name: 'Pudrición parda (%)',
				selector: (row) => row.f_pud_parda,
				sortField: 'f_pud_parda',
				format: (row) => numberFormatDecimals(row.f_pud_parda, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 19,
			},
			{
				name: 'Pudrición (%)',
				selector: (row) => row.f_pudricion,
				sortField: 'f_pudricion',
				format: (row) => numberFormatDecimals(row.f_pudricion, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 20,
			},
			{
				name: 'Pedicelo deshidratado (%)',
				selector: (row) => row.ped_deshid,
				sortField: 'ped_deshid',
				format: (row) => numberFormatDecimals(row.ped_deshid, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 21,
			},
			{
				name: 'Inicio deshidratación de pedicelo (%)',
				selector: (row) => row.ped_inicio_deshid,
				sortField: 'ped_inicio_deshid',
				format: (row) => numberFormatDecimals(row.ped_inicio_deshid, 1),
				// hide: 'sm',
				compact: true,
				sortable: true,
				id: 22,
			},
		],
		[]
	);

	// console.log(filterProp, "<---llega el filtro");

	const [tableColumnsState, setTableColumnsState] = useState(columns);

	const toggleColumn = (id) => {
		let columns = [...tableColumnsState];
		columns[id].omit = !columns[id].omit;
		setTableColumnsState(columns);
	};

	const toggleAllColumn = (state) => {
		// console.log(state, 'togle all', tableColumnsState);
		let columnsToogleAll = [...tableColumnsState];
		columnsToogleAll.map((col) => (col.omit = !state));
		setTableColumnsState(columnsToogleAll);
	};

	// table
	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [countState, setCountState] = useState(0);
	const [pageState, setPageState] = useState(1);
	const [sortState, setSortState] = useState({
		key: 'huerto',
		option: 'asc',
	});
	const [limitState, setLimitState] = useState(10);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);
	const [busyState, setBusyState] = useState(false);

	const [anchorElState, setAnchorElState] = useState(null);

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				// '&: nth-last-child(-n+3)': {
				//     backgroundColor: theme.palette.primary.main,
				//     borderBottom: '4px solid',
				//     borderColor: theme.palette.secondary.light
				// },
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	async function queryData(page, sort = sortState) {
		setLoadingDataTableState(true);
		try {
			// console.log("llega la data",limitState, "----",pageState,"---",filterProp);

			const {
				huerto,
				centro_costo,
				cuartel,
				variedad,
				porta_injerto,
				temporada,
			} = filterProp;

			let objQuery = {
				huerto: huerto,
				huerto_user: userOrchard, //orchard del login
				centro_costo: centro_costo,
				cuartel: cuartel,
				variedad: variedad,
				rootStock: porta_injerto,
				season: temporada,
				page: page,
				limit: limitState,
				sort: sort ? sort : sortState,
			};

			console.log(objQuery, '---la query que va');

			const response = await axiosContext.authAxios.post(
				'v1/library/identification',
				objQuery
			);

			const { data, status } = response;

			console.log(response, '<-----response query');

			// setCountState(data.totalPassengers);
			if (data.data) {
				// setTableDataState(data.data);
				console.log('eeeeeeee', data);
				setCountState(data.count);
				setTableDataState(data.data);
				setPageState(page);
				setSortState(sort);
				setBusyState(false);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setBusyState(false);
			setLoadingDataTableState(false);
		}
	}

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		// let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];
		let nameColumn = column.sortField;
		// nameColumn = undefined;
		// console.log(nameColumn, '<---nombre de la col que llega');
		if (nameColumn !== null) {
			console.log({ key: nameColumn, option: sortDirection });
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		// console.log(page, totalRows)
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			// console.log("llega la data",limitState, "----",pageState,"---",filterProp);

			const {
				huerto,
				centro_costo,
				cuartel,
				variedad,
				porta_injerto,
				temporada,
			} = filterProp;

			let objQuery = {
				huerto: huerto,
				huerto_user: userOrchard, //orchard del login
				centro_costo: centro_costo,
				cuartel: cuartel,
				variedad: variedad,
				rootStock: porta_injerto,
				season: temporada,
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/library/identification',
				objQuery
			);

			const { data, status } = response;

			console.log(response, '<-----response change rows');

			// setCountState(data.totalPassengers);
			if (data.data) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setCountState(data.count);
				setTableDataState(data.data);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	const handleFilterClick = useCallback((event) => {
		setAnchorElState(event.currentTarget);
	}, []);

	const handleFilterClose = useCallback(() => {
		setAnchorElState(null);
	}, []);

	useEffect(() => {
		if (filterProp) {
			console.log('cambio filters');
			setResetPaginationToggleState(!resetPaginationToggleState);
			// setPageState(1);
			queryData(1);
			setBusyState(true);
		}
	}, [filterProp]);

	return (
		<BsRow class="justify-content-between" style={{ marginTop: -20 }}>
			<BsCol class="col-12">
				<div className={classes.tableContainerStyle}>
					{tableDataState.length > 0 && (
						<FilterColumnTableWidget
							anchorState={anchorElState}
							handleFilterClick={handleFilterClick}
							handleFilterClose={handleFilterClose}
							filterOptions={isDesktop ? columns : columns}
							toggleColumn={toggleColumn}
							toggleAllColumn={toggleAllColumn}
						/>
					)}
					<DataTableWidget
						totalRows={countState}
						handleOnSort={handleOnSort}
						// conditionalRowStyles={conditionalRowStyles}
						// sortFunction={customSort}
						fixedHeader={true}
						fixedHeaderScrollHeight={'590px'}
						changePagination={handleChangePagination}
						rowsChange={handleChangeRows}
						columns={tableColumnsState}
						sortField="1"
						filteredItems={tableDataState}
						// subHeaderComponentMemo={subHeaderComponentMemo}
						loading={loadingDataTableState}
						loadingCount={loadingDataTableState}
						customStyles={customStyles}
						moduleName="Defectos de Condición"
						resetPaginationToggle={resetPaginationToggleState}
					/>
				</div>
			</BsCol>
		</BsRow>
	);
};

export default NinthTab;
