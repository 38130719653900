// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useTheme } from '@mui/material';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import { DataTableWidget, FilterColumnTableWidget } from 'components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from 'context/AxiosContext';
import { Context } from 'context/Context';
import {
  // alterArrayGetIds,
  IsDesktopHandler,
  // numberFormat,
  // dashDelete,
  numberFormatDecimals,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    //   paddingRight: 34,
    // },
  },
}));

const SeventhTab = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const { userOrchard } = useContext(Context);

  const axiosContext = useContext(AxiosContext);

  const isDesktop = IsDesktopHandler('md');

  const columns = useMemo(
    () => [
      {
        name: 'Temporada',
        selector: (row) => row.season,
        sortField: 'season',
        // hide: 'sm',
        sortable: true,
        id: 1,
      },
      {
        name: 'Cliente',
        selector: (row) => row.client,
        sortField: 'client',
        // hide: 'sm',
        sortable: true,
        id: 2,
      },
      {
        name: 'Agrícola',
        selector: (row) => row.agroBusiness,
        sortField: 'agroBusiness',
        // hide: 'sm',
        sortable: true,
        id: 3,
      },
      {
        name: 'Huerto',
        selector: (row) => row.orchard,
        sortField: 'orchard',
        sortable: true,
        id: 4,
      },
      {
        name: 'Especie',
        selector: (row) => row.specie,
        sortField: 'specie',
        sortable: true,
        id: 5,
      },
      {
        name: 'Centro de Costos',
        selector: (row) => row.cc,
        sortField: 'cc',
        sortable: true,
        id: 6,
      },
      {
        name: 'Cuartel',
        selector: (row) => row.quarter,
        sortField: 'quarter',
        // hide: 'md',
        sortable: true,
        id: 7,
      },
      {
        name: 'Variedad',
        selector: (row) => row.variety,
        sortField: 'variety',
        // hide: 'sm',
        sortable: true,
        id: 8,
      },
      {
        name: 'Portainjerto',
        selector: (row) => row.rootStock,
        sortField: 'rootStock',
        // hide: 'sm',
        sortable: true,
        id: 9,
      },
      {
        name: 'Exportable estimado recepción (%)',
        selector: (row) => row.exportableEstimate,
        sortField: 'exportableEstimate',
        format: (row) => numberFormatDecimals(row.exportableEstimate, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 10,
      },
      {
        name: 'calibre G (%) >32,0 mm',
        selector: (row) => row.caliberG,
        sortField: 'caliberG',
        format: (row) => numberFormatDecimals(row.caliberG, 1),
        sortable: true,
        compact: true,
        id: 11,
      },
      {
        name: 'calibre J (%) 26,0-28,0 mm',
        selector: (row) => row.caliberJ,
        sortField: 'caliberJ',
        format: (row) => numberFormatDecimals(row.caliberJ, 1),
        // hide: 'md',
        compact: true,
        sortable: true,
        id: 12,
      },
      {
        name: 'calibre L (%) 22,0-24,0 mm',
        selector: (row) => row.caliberL,
        sortField: 'caliberL',
        format: (row) => numberFormatDecimals(row.caliberL, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 13,
      },
      {
        name: 'calibre P (%) 30,0-32,0 mm',
        selector: (row) => row.caliberP,
        sortField: 'caliberP',
        format: (row) => numberFormatDecimals(row.caliberP, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 14,
      },
      {
        name: 'Pre calibre (%) <20,0-22,0 mm',
        selector: (row) => row.preCaliber,
        sortField: 'preCaliber',
        format: (row) => numberFormatDecimals(row.preCaliber, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 15,
      },
      {
        name: 'calibre SJ (%) 28,0-30,0 mm',
        selector: (row) => row.caliberSj,
        sortField: 'caliberSj',
        format: (row) => numberFormatDecimals(row.caliberSj, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 16,
      },
      {
        name: 'calibre SJD (%) 28,0-30,0 mm',
        selector: (row) => row.caliberSjd,
        sortField: 'caliberSjd',
        format: (row) => numberFormatDecimals(row.caliberSjd, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 17,
      },
      {
        name: 'calibre XL (%) 24,0-26,0 mm',
        selector: (row) => row.caliberXl,
        sortField: 'caliberXl',
        format: (row) => numberFormatDecimals(row.caliberXl, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 18,
      },
      {
        name: 'Fruta blanda (%)',
        selector: (row) => row.fruitSoft,
        sortField: 'fruitSoft',
        format: (row) => numberFormatDecimals(row.fruitSoft, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 19,
      },
      {
        name: 'Fruta firme (%)',
        selector: (row) => row.fruitStrong,
        sortField: 'fruitStrong',
        format: (row) => numberFormatDecimals(row.fruitStrong, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 20,
      },
      {
        name: 'Fruta muy blanda (%)',
        selector: (row) => row.fruitVerySoft,
        sortField: 'fruitVerySoft',
        format: (row) => numberFormatDecimals(row.fruitVerySoft, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 21,
      },
      {
        name: 'Fruta muy firme (%)',
        selector: (row) => row.fruitVeryStrong,
        sortField: 'fruitVeryStrong',
        format: (row) => numberFormatDecimals(row.fruitVeryStrong, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 22,
      },
      {
        name: '°Brix',
        selector: (row) => row.brix,
        sortField: 'brix',
        format: (row) => numberFormatDecimals(row.brix, 1),
        sortable: true,
        compact: true,
        id: 23,
      },
      {
        name: 'Caoba oscuro (%)',
        selector: (row) => row.mahoganyDark,
        sortField: 'mahoganyDark',
        format: (row) => numberFormatDecimals(row.mahoganyDark, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 24,
      },
      {
        name: 'Rojo caoba (%)',
        selector: (row) => row.redMahogany,
        sortField: 'redMahogany',
        format: (row) => numberFormatDecimals(row.redMahogany, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 25,
      },
      {
        name: 'Falta Color (%)',
        selector: (row) => row.missingColour,
        sortField: 'missingColour',
        format: (row) => numberFormatDecimals(row.missingColour, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 26,
      },
      {
        name: 'Negro (%)',
        selector: (row) => row.black,
        sortField: 'black',
        format: (row) => numberFormatDecimals(row.black, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 27,
      },
      {
        name: 'Rojo (%)',
        selector: (row) => row.red,
        sortField: 'red',
        format: (row) => numberFormatDecimals(row.red, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 28,
      },
    ],
    []
  );

  // console.log(filterProp, "<---llega el filtro");

  const [tableColumnsState, setTableColumnsState] = useState(columns);

  const toggleColumn = (id) => {
    let columns = [...tableColumnsState];
    columns[id].omit = !columns[id].omit;
    setTableColumnsState(columns);
  };

  const toggleAllColumn = (state) => {
    // console.log(state, 'togle all', tableColumnsState);
    let columnsToogleAll = [...tableColumnsState];
    columnsToogleAll.map((col) => (col.omit = !state));
    setTableColumnsState(columnsToogleAll);
  };

  // table
  const [loadingDataTableState, setLoadingDataTableState] = useState(false);
  const [tableDataState, setTableDataState] = useState([]);
  const [countState, setCountState] = useState(0);
  const [pageState, setPageState] = useState(1);
  const [sortState, setSortState] = useState({
    key: 'huerto',
    option: 'asc',
  });
  const [limitState, setLimitState] = useState(10);
  const [resetPaginationToggleState, setResetPaginationToggleState] =
    useState(false);
  const [busyState, setBusyState] = useState(false);

  const [anchorElState, setAnchorElState] = useState(null);

  const customStyles = {
    headCells: {
      style: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        // '&: nth-last-child(-n+3)': {
        //     backgroundColor: theme.palette.primary.main,
        //     borderBottom: '4px solid',
        //     borderColor: theme.palette.secondary.light
        // },
        div: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      },
    },
  };

  async function queryData(page, sort = sortState) {
    setLoadingDataTableState(true);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const {
        huerto,
        centro_costo,
        cuartel,
        variedad,
        porta_injerto,
        temporada,
      } = filterProp;

      let objQuery = {
        huerto: huerto,
        huerto_user: userOrchard, //orchard del login
        centro_costo: centro_costo,
        cuartel: cuartel,
        variedad: variedad,
        rootStock: porta_injerto,
        season: temporada,
        page: page,
        limit: limitState,
        sort: sort ? sort : sortState,
      };

      console.log(objQuery, '---la query que va');

      const response = await axiosContext.authAxios.post(
        'v1/library/categorization',
        objQuery
      );

      const { data, status } = response;

      console.log(response, '<-----response query');

      // setCountState(data.totalPassengers);
      if (data.data) {
        // setTableDataState(data.data);
        console.log('eeeeeeee', data);
        setCountState(data.count);
        setTableDataState(data.data);
        setPageState(page);
        setSortState(sort);
        setBusyState(false);
      }
      setLoadingDataTableState(false);
    } catch (err) {
      setTableDataState([]);
      setBusyState(false);
      setLoadingDataTableState(false);
    }
  }

  // table functions
  const handleOnSort = async (column, sortDirection) => {
    // console.log('handleSort', column, sortDirection);
    // let nameColumn = column.sortField;
    // console.log(column.selector.toString().split(".")[1], "---");
    // let nameColumn = column.selector.toString().split('.')[1];
    let nameColumn = column.sortField;
    // nameColumn = undefined;
    // console.log(nameColumn, '<---nombre de la col que llega');
    if (nameColumn !== null) {
      console.log({ key: nameColumn, option: sortDirection });
      if (!busyState) {
        queryData(pageState, {
          key: nameColumn,
          option: sortDirection,
        });
      }
    }
  };

  const handleChangePagination = (page, totalRows) => {
    console.log(page, totalRows);
    // setPageState(page);
    if (!busyState) {
      queryData(page);
    }
  };

  const handleChangeRows = async (currentRowsPerPage, currentPage) => {
    setLoadingDataTableState(false);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const {
        huerto,
        centro_costo,
        cuartel,
        variedad,
        porta_injerto,
        temporada,
      } = filterProp;

      let objQuery = {
        huerto: huerto,
        huerto_user: userOrchard, //orchard del login
        centro_costo: centro_costo,
        cuartel: cuartel,
        variedad: variedad,
        rootStock: porta_injerto,
        season: temporada,
        page: currentPage,
        limit: currentRowsPerPage,
        sort: sortState,
      };

      console.log(objQuery, '---la query que va en handle');

      const response = await axiosContext.authAxios.post(
        'v1/library/categorization',
        objQuery
      );

      const { data, status } = response;

      console.log(response, '<-----response change rows');

      // setCountState(data.totalPassengers);
      if (data.data) {
        // setTableDataState(data.data);
        console.log('eeeeeeee en handle', data);
        setCountState(data.count);
        setTableDataState(data.data);
        setLimitState(currentRowsPerPage);
      }
      setLoadingDataTableState(false);
    } catch (err) {
      setTableDataState([]);
      setLoadingDataTableState(false);
    }
  };

  const handleFilterClick = useCallback((event) => {
    setAnchorElState(event.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorElState(null);
  }, []);

  useEffect(() => {
    if (filterProp) {
      // console.log("cambio filters")
      setResetPaginationToggleState(!resetPaginationToggleState);
      // setPageState(1);
      queryData(1);
      setBusyState(true);
    }
  }, [filterProp]);

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          {tableDataState.length > 0 && (
            <FilterColumnTableWidget
              anchorState={anchorElState}
              handleFilterClick={handleFilterClick}
              handleFilterClose={handleFilterClose}
              filterOptions={isDesktop ? columns : columns}
              toggleColumn={toggleColumn}
              toggleAllColumn={toggleAllColumn}
            />
          )}
          <DataTableWidget
            totalRows={countState}
            handleOnSort={handleOnSort}
            // conditionalRowStyles={conditionalRowStyles}
            // sortFunction={customSort}
            fixedHeader={true}
            fixedHeaderScrollHeight={'590px'}
            changePagination={handleChangePagination}
            rowsChange={handleChangeRows}
            columns={tableColumnsState}
            sortField='1'
            filteredItems={tableDataState}
            // subHeaderComponentMemo={subHeaderComponentMemo}
            loading={loadingDataTableState}
            loadingCount={loadingDataTableState}
            customStyles={customStyles}
            moduleName='Categorización Viaje'
            resetPaginationToggle={resetPaginationToggleState}
          />
        </div>
      </BsCol>
    </BsRow>
  );
};

export default SeventhTab;
