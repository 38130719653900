import React, { useState, useEffect, useMemo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import SnackBarsComponent from '../../SnackBarsComponent';
import snackBarsMessages from '../../../common/snackBarsMessages.json';
import { CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    justifyContent: 'center',
  },
  wrapper: {
    position: 'relative',
  },
}));

const ButtonAction = (props) => {
  const {
    mutationLoading,
    success,
    messageType,
    variant = 'contained',
    disabled,
    actionOnClick,
    labelButton,
    fullWidth = false,
    color = 'primary',
    size = 'medium',
    actionClose = null,
    ...others
  } = props;

  const classesUI = useStyles();

  const snackBarMessage = useMemo(
    () => snackBarsMessages.message[messageType],
    [messageType]
  );

  const [stateSuccess, setStateSuccess] = useState(false);
  const handleCloseSnackBarsComponent = () => {
    console.log('se ejecuta onClose button action');
    setStateSuccess(false);
    const closeAction = actionClose || (() => {});
    closeAction();
  };

  useEffect(() => {
    if (success) {
      setStateSuccess(true);
    } else {
      setStateSuccess(false);
    }
  }, [success]);

  const buttonContent = useMemo(
    () => (
      <CardActions className={fullWidth ? '' : classesUI.cardActions}>
        <div className={classesUI.wrapper} style={{ width: '100%' }}>
          <div
            style={
              !fullWidth
                ? { position: 'relative', display: 'inline-block' }
                : {}
            }
          >
            <LoadingButton
              color={color}
              variant={variant}
              type='button'
              size={size}
              {...others}
              onClick={actionOnClick}
              fullWidth={fullWidth}
              disabled={disabled || mutationLoading}
              loading={mutationLoading}
              loadingPosition='start'
              startIcon={<div />}
            >
              {labelButton}
            </LoadingButton>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={stateSuccess}
          onClose={handleCloseSnackBarsComponent}
          autoHideDuration={4500}
          key={Math.random()}
        >
          <div>
            <SnackBarsComponent
              variant='success'
              message={snackBarMessage}
              handleClose={handleCloseSnackBarsComponent}
            />
          </div>
        </Snackbar>
      </CardActions>
    ),
    [
      fullWidth,
      classesUI.cardActions,
      classesUI.wrapper,
      color,
      variant,
      size,
      others,
      actionOnClick,
      labelButton,
      disabled,
      mutationLoading,
      stateSuccess,
      snackBarMessage,
      handleCloseSnackBarsComponent,
    ]
  );

  return buttonContent;
};

export default ButtonAction;
