import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classesModule from './PodaDashboard.module.scss';
import { Context } from '../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  TextFieldWidgetSelectController,
} from '../../components';
import { FiltersContext } from '../../context';
import { SimpleTabs, TabPanel } from '../../components/TabPanel';
import { tabItems } from './tabList';
import {
  PostPoda,
  PrePoda,
  PreRaleo,
  PostRaleo,
} from 'containers/YieldDashboard';
// import JSON_DATA from '../../helpers/management/dataDashboard.json';

// import { Context } from '../../context/Context';

// import { ButtonBox } from '../../components/Dashboard';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  subtitleDash: {
    fontSize: 17 + '!important',
    fontWeight: 700 + '!important',
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
  rootDayWeather: {
    flexGrow: 1,
  },
  dayWeatherBox: {
    backgroundColor: 'lightblue',
    padding: 6,
    textAlign: 'center',
    borderRadius: 6,
  },
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
  },
  dividerHeight: {
    minHeight: '70px',
  },
}));

const PodaDashboard = () => {
  // let history = useHistory();

  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const { moduleUser, userOrchard, userClient } = useContext(Context);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const clientValue = userClient?.value || 'Garces_Fruit';

  // const [filterDataResponseState, setFilterDataResponseState] = useState(
  //   userOrchard_filter.filter((orchard) => orchard.clientValue === clientValue)
  // );

  const moduleOrchardFilter = 'statusDashboard';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter(
      (ele) =>
        ele[moduleOrchardFilter] === true && ele.clientValue === clientValue
    )
  );

  // const [filterDataResponseState, setFilterDataResponseState] =
  //   useState(userOrchard_filter);

  const [loadingFiltersState] = useState(false);

  const [valueTabState, setValueTabState] = useState(0);

  const [tabsArrayState, setTabsArrayState] = useState([]);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  // console.log(userOrchard_filter, '-----userOrchard_filter');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);

  const onSubmit = async (values) => {
    setLoadingDashDataState(true);

    const dataQuery = {
      orchard: values.orchard,
    };

    setInfoFilterTab(dataQuery);
  };

  useEffect(() => {
    console.log(moduleUser, '----moduleUser');

    // busca si tengo dashboard, menu
    const moduleDash = moduleUser.filter(
      (ele) => ele.id === '642337ecd39504aaa4d31000'
    );

    // Revisar
    let menuTabs;
    if (moduleDash[0].processBackend) {
      menuTabs = tabItems.map((tab) => {
        const enabledTab =
          _.findIndex(moduleDash[0].processBackend, function (p) {
            return p.idModule === tab.idModule;
          }) >= 0;

        return {
          ...tab,
          disabled: !enabledTab,
        };
      });
    } else {
      menuTabs = tabItems;
    }

    setTabsArrayState(menuTabs);
  }, [moduleUser]);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-md-3'>
            <Card
              elevation={0}
              // classes={{
              // 	root: 'MainCardRoot',
              // }}
            >
              <CardContent>
                <BsRow class='mb-1 mb-md-4'>
                  <BsCol class='col-md-6'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Dashboard - Resumen Labor
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        <TextFieldWidgetSelectController
                          options={filterDataResponseState}
                          control={control}
                          titleOption='label'
                          name='orchard'
                          labeltext='Huerto'
                          errors={errors.orchard}
                          defaultValue=''
                          loading={loadingFiltersState}
                          req={true}
                          withDefault
                          variant='standard'
                        />
                      </div>
                      <SaveAction
                        title='Filtrar'
                        color='secondary'
                        size='large'
                        className='roundedButton ml-md-2'
                        variant='contained'
                        messageType='add'
                        mutationLoading={false}
                        success={false}
                      />
                    </form>
                  </BsCol>
                  {!loadingDashDataState && (
                    <BsCol class='col-md-6 align-self-end text-right'>
                      <Typography variant='h6' gutterBottom>
                        {new Date().toLocaleDateString('es-ES', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Typography>
                      {/* <Typography
												variant="body2"
												gutterBottom
											>
												Última actualización 17:45 hrs.
											</Typography> */}
                    </BsCol>
                  )}
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters mb-3'>
          <BsCol class='col-md-12'>
            <BsRow class='gutter_10'>
              <BsCol class='col-md-12'>
                <Card elevation={0} className={classes.dashCard}>
                  <CardContent>
                    <SimpleTabs
                      tabitems={tabsArrayState}
                      value={valueTabState}
                      toggler={handleChangeTab}
                      tabEnabled={true}
                    >
                      <TabPanel
                        py='py-4'
                        value={valueTabState}
                        index={0}
                        noMinHeight
                      >
                        <PrePoda filter={infoFilterTab} />
                      </TabPanel>
                      <TabPanel
                        py='py-4'
                        value={valueTabState}
                        index={1}
                        noMinHeight
                      >
                        <PostPoda filter={infoFilterTab} />
                      </TabPanel>
                      <TabPanel
                        py='py-4'
                        value={valueTabState}
                        index={2}
                        noMinHeight
                      >
                        <PreRaleo filter={infoFilterTab} />
                      </TabPanel>
                      <TabPanel
                        py='py-4'
                        value={valueTabState}
                        index={3}
                        noMinHeight
                      >
                        <PostRaleo filter={infoFilterTab} />
                      </TabPanel>
                    </SimpleTabs>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default PodaDashboard;
