import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  useTheme,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
// import classesModule from './TextFieldWidgetSelectControllerForm.module.scss';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    rootLabel: {
      fontSize: 20,
      marginLeft: -14,
      fontWeight: 500,
    },
    selectEmpty: {
      marginTop: theme.spacing(6),
    },
    errorText: {
      fontSize: 12,
      marginLeft: 0,
    },
    // selectStyle: {
    //   marginTop: 10,
    //   fontSize: 17,
    //   lineHeight: '2.4em',
    // },
  };
});

const TextFieldWidgetSelectControllerForm = (props) => {
  const {
    errors,
    options,
    req,
    loading,
    name,
    control,
    disabled,
    withDefault,
    defaultValue,
    variant = 'standard',
  } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  const { classes } = useStyles();

  const theme = useTheme();

  // const [selectedValueState, setSelectedValueState] = useState('');

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[name].required
              ? errorMessage.message[name].required
              : errorMessage.message[name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  // useEffect(() => {
  // 	setSelectedValueState(selected);
  // 	// console.log("el selected en el select", selectedValueState,"el selected que llega", selected);
  // }, [selected]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  const renderOptionTitle = (option) => {
    switch (props.titleOption) {
      case 'rut-fullname':
        return `${option.rut} - ${option.fullname}`;
      case 'code-name':
        return `${option.code} - ${option.name}`;
      case 'label':
        return `${option.label}`;
      default:
        return `${option.name}`;
    }
  };

  // console.log(selectedValueState, "ultimo valor selected", options);

  //   validaciones
  return (
    <FormControl className={classes.formControl} error={stateError}>
      {/* <div className={classes.inputContainerStyle}> */}
      {/* <div className={classes.labelDesktopStyle}>{props.labeltext}</div> */}
      {props.labeltext && (
        <InputLabel
          shrink={withDefault}
          id={labelText}
          className={classes.rootLabel}
        >
          {props.labelIcon ? props.labelIcon : ''}
          {props.labeltext} {loading && '...cargando'}
        </InputLabel>
      )}
      {/* <div style={{ width: '100%' }}> */}
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            labelId={labelText}
            id={labelText}
            // label={labelText}
            // value={selectedValueState}
            // native
            {...field}
            fullWidth
            displayEmpty={withDefault}
            className={withDefault ? classes.selectEmpty : ''}
            classes={{ select: classes.selectStyle }}
            disabled={disabled}
            // margin="dense"
            variant={variant}
            sx={{
              lineHeight: '2.4em',
              minHeight: '2.4em',
            }}
            // endAdornment={
            //   <>
            //     <InputAdornment
            //       position='end'
            //       variant='filled'
            //       disableTypography
            //       style={{
            //         color: 'black',
            //         height: 'auto',
            //         maxHeight: '40px',
            //         padding: '14px 16px',
            //         borderRadius: theme.shape.borderRadius,
            //         backgroundColor: theme.palette.background.default,
            //       }}
            //     >
            //       u
            //     </InputAdornment>
            //   </>
            // }

            // onChange={e => onChangeFunc(e.target.value)}>
          >
            {withDefault ? (
              <MenuItem value=''>
                <em>Seleccione</em>
              </MenuItem>
            ) : null}
            {options &&
              options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {renderOptionTitle(option)}
                </MenuItem>
              ))}
          </Select>
        )}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: req,
        }}
      />
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
      {/* </div> */}
      {/* </div> */}
    </FormControl>
  );
};

TextFieldWidgetSelectControllerForm.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  loading: PropTypes.bool,
};

export default TextFieldWidgetSelectControllerForm;
