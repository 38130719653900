// import _ from "lodash";
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Checkbox, Typography, useTheme, Modal } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import { useErrorHandler } from 'react-error-boundary';
import { Controller, useForm } from 'react-hook-form';
import { BsRow, BsCol } from '../../../layouts/components';
import {
  DataTableWidget,
  FilterColumnTableWidget,
  LoadingWidget,
  SimpleIconButton,
} from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  alterArrayForCountData,
  alterArrayGetIds,
  dashDelete,
  dateTimeParse,
  dateTimeToCalendarFormat,
  IsDesktopHandler,
  numberFormat,
  numberFormatDecimals,
  alterArrayGetVariety,
  handleActionEdit,
  handleActionEditNew,
} from '../../../helpers';
import CjIcon from '../../../assets/icons/schedule/cj_icon.png';
import MexicoIcon from '../../../assets/icons/schedule/mexico_icon.png';
import QuarantineIcon from '../../../assets/icons/schedule/quarantine_icon.png';
import GraspIcon from '../../../assets/icons/schedule/grasp_icon.png';
import FsmaIcon from '../../../assets/icons/schedule/fsma_icon.png';
import GlobalGapIcon from '../../../assets/icons/schedule/globalgap_icon.png';
import Tn11TescoIcon from '../../../assets/icons/schedule/tn11_icon.png';

import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import ModalEdit from '../ModalEdit';
import { ModalHistory } from '..';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
    minHeight: '40vh',
  },
  dividerHeight: {
    minHeight: '70px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionColStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

// function that recieves a status [true, false, null] and returns a icon
const statusIcon = (status) => {
  if (status === 'add') {
    return <CheckBoxIcon style={{ color: 'green' }} />;
  } else if (status === 'delete') {
    return <DisabledByDefaultIcon style={{ color: 'red' }} />;
  } else {
    return <IndeterminateCheckBoxIcon style={{ color: 'gray' }} />;
  }
};

const handleEditedRegister = (newData, dataCertificationState) => {
  const newDaysSchedule = dataCertificationState.map((register) => {
    if (
      register.orchardValue === newData.orchardValue &&
      register.varietyValue === newData.varietyValue
    ) {
      return newData;
    } else {
      return register;
    }
  });
  return newDaysSchedule;
};

const TabCertification = ({ filter: filterProp }) => {
  const theme = useTheme();

  const { userOrchard, userClient } = useContext(Context);

  const errorHandler = useErrorHandler();

  const [loadingState, setLoadingState] = useState(false);
  const [dataCertificationState, setDataCertificationState] = useState(null);

  const [openModalEditState, setOpenModalEditState] = useState(false);
  const [dataModalEditState, setDataModalEditState] = useState(null);
  const [isEditedState, setIsEditedState] = useState(false);

  const [openModalHistoryState, setOpenModalHistoryState] = useState(false);
  const [dataModalHistoryState, setDataModalHistoryState] = useState(null);

  // console.log(theme);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const isDesktop = IsDesktopHandler('md');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  async function queryData() {
    setLoadingState(true);
    setDataModalHistoryState(null);
    setDataModalEditState(null);
    try {
      const { orchard } = filterProp;

      console.log(filterProp, 'filterProp');

      const objQuery = {
        orchardValue: orchard.value,
        agroBusinessValue: filterProp?.agroBusiness?.value || '',
        specieValue: filterProp?.specie?.value || '',
        productLineValue: filterProp?.productionLine?.value || '',
      };

      console.log(objQuery, 'objQuery');

      const response = await axiosContext.authAxios.post(
        'v1/planningHarvestCertificate/list',
        objQuery
      );

      // let data = true;

      const { data, status } = response;
      // const data = null;
      // const status = 200;

      console.log(response, 'response');
      // setCountState(data.totalPassengers);
      if (status === 200) {
        console.log(data, 'data------');

        setDataCertificationState(data);
      }
      setLoadingState(false);
    } catch (err) {
      setLoadingState(false);
      errorHandler(err);
    }
  }

  useEffect(() => {
    if (filterProp) {
      console.log(filterProp);
      queryData();
    }
  }, [filterProp]);

  if (loadingState) {
    return <LoadingWidget />;
  }

  const handleOpenModalEdit = (row, type) => {
    console.log(row, type, '----row, type');
    const dataEdit = {
      ...row,
      client: userClient?.label,
      clientValue: userClient?.value,
      type,
    };
    setDataModalEditState(dataEdit);
    setOpenModalEditState(true);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEditState(false);
  };

  const handleOpenModalHistory = (row) => {
    setDataModalHistoryState(row);
    setOpenModalHistoryState(true);
  };

  const handleCloseModalHistory = () => {
    setOpenModalHistoryState(false);
  };

  // const handleIsEdited = useCallback(() => {
  //   setIsEditedState((prev) => !prev);
  // }, []);

  console.log(userClient, 'userClient');

  const onEditHandler = (newData) => {
    console.log(newData, '---------newData---edited');
    const newDaysSchedule = handleEditedRegister(
      newData,
      dataCertificationState
    );

    // console.log('new users to table', newDaysSchedule);
    setDataCertificationState(newDaysSchedule);
  };

  if (dataCertificationState === null) {
    return (
      <BsRow class='justify-content-between'>
        <BsCol class='col-12'>
          <p>Filtre por Huerto.</p>
        </BsCol>
      </BsRow>
    );
  }

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12 table-responsive'>
        {/* <p>Aca va data certificaciones</p> */}

        {dataCertificationState ? (
          <table className='table'>
            <thead>
              <tr>
                <th>Variedad</th>
                <th className='text-center'>
                  <img
                    src={CjIcon}
                    width='52'
                    className='img-fluid img-estimated'
                    alt='Mexico'
                  />
                  <br />
                  Corea/Japón
                </th>
                <th className='text-center'>
                  <img
                    src={MexicoIcon}
                    width='25'
                    className='img-fluid img-estimated'
                    alt='Mexico'
                  />
                  <br />
                  México
                </th>
                <th className='text-center'>
                  <img
                    src={QuarantineIcon}
                    width='25'
                    className='img-fluid img-estimated'
                    alt='Mexico'
                  />
                  <br />
                  Cuarentena
                </th>
                <th className='text-center'>
                  <img
                    src={GlobalGapIcon}
                    width='25'
                    className='img-fluid img-estimated'
                    alt='Global Gap'
                  />
                  <br />
                  Global Gap
                </th>
                <th className='text-center'>
                  <img
                    src={Tn11TescoIcon}
                    width='25'
                    className='img-fluid img-estimated'
                    alt='Tn11 Tesco'
                  />
                  <br />
                  Tn11 Tesco
                </th>
                <th className='text-center'>
                  <img
                    src={GraspIcon}
                    width='25'
                    className='img-fluid img-estimated'
                    alt='Grasp'
                  />
                  <br />
                  Grasp
                </th>
                <th className='text-center'>
                  <img
                    src={FsmaIcon}
                    width='25'
                    className='img-fluid img-estimated'
                    alt='FSMA'
                  />
                  <br />
                  FSMA
                </th>

                <th className='text-center'>Acción</th>
              </tr>
            </thead>
            <tbody>
              {dataCertificationState.map((item, index) => {
                return (
                  <tr key={item.varietyValue}>
                    <td>{item.variety}</td>
                    <td className='text-center'>{statusIcon(item.cj)}</td>
                    <td className='text-center'>{statusIcon(item.mexico)}</td>
                    <td className='text-center'>
                      {statusIcon(item.quarantine)}
                    </td>
                    <td className='text-center'>
                      {statusIcon(item.globalGap)}
                    </td>
                    <td className='text-center'>{statusIcon(item.tesco)}</td>
                    <td className='text-center'>{statusIcon(item.grasp)}</td>
                    <td className='text-center'>{statusIcon(item.fsma)}</td>

                    <td className={classes.actionColStyle}>
                      <SimpleIconButton
                        labelButton={'Agregar'}
                        icon={<i className='fas fa-plus-square'></i>}
                        color={'success'}
                        variant={'contained'}
                        actionOnClick={() => handleOpenModalEdit(item, 'add')}
                      />
                      <SimpleIconButton
                        labelButton={'Quitar'}
                        icon={<i className='fas fa-minus-square'></i>}
                        color={'error'}
                        className='ml-2'
                        actionOnClick={() =>
                          handleOpenModalEdit(item, 'delete')
                        }
                      />
                      <SimpleIconButton
                        labelButton={'Historial'}
                        icon={<i className='fas fa-list'></i>}
                        color={'secondary'}
                        className='ml-2'
                        disabled={item.history.length === 0}
                        actionOnClick={() => handleOpenModalHistory(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        <Modal
          open={openModalEditState}
          onClose={handleCloseModalEdit}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalEdit
              closeAction={handleCloseModalEdit}
              dataModal={dataModalEditState}
              isEditedFunc={onEditHandler}
            />
          </>
        </Modal>
        <Modal
          open={openModalHistoryState}
          onClose={handleCloseModalHistory}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalHistory
              closeAction={handleCloseModalHistory}
              dataModal={dataModalHistoryState}
            />
          </>
        </Modal>
      </BsCol>
    </BsRow>
  );
};

export default TabCertification;
