// import _ from "lodash";
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { BsRow, BsCol } from '../../../layouts/components';
import { CardPaper } from '../../../components';
import { Context } from '../../../context/Context';
import { OptionsContext } from '../../../context';
import ColdHour from '../DetailGraphs/ColdHour';
import ColdPortion from '../DetailGraphs/ColdPortion';
import DegreeDays from '../DetailGraphs/DegreeDays';

const MainGraphsDetail = ({ filter }) => {
  const theme = useTheme();

  const { userOrchard } = useContext(Context);

  const {
    state: { weather_detail },
    addOptions,
  } = useContext(OptionsContext);

  const [activeGraphState, setActiveGraphState] = useState(weather_detail || 1);

  const handleChangeCols = (event) => {
    setActiveGraphState(event.target.value);

    console.log(event.target.value, '------');

    addOptions({
      weather_detail: event.target.value,
    });
  };
  // console.log('filter', filter);

  return (
    <>
      <BsRow class='gutter_10'>
        {filter ? (
          <BsCol class='col-md-12'>
            <Box
              sx={{
                px: 2,
              }}
            >
              <FormControl
                sx={{
                  minWidth: 120,
                  padding: 0,
                  '& .MuiSelect-select': {
                    padding: 0.5,
                    fontSize: 14,
                  },
                }}
                size='small'
              >
                <Select
                  labelId='gráfico'
                  id='graph'
                  disabled={!!!filter}
                  value={activeGraphState}
                  label=''
                  onChange={handleChangeCols}
                >
                  <MenuItem value={1}>Horas Frío</MenuItem>
                  <MenuItem value={2}>Porciones Frío</MenuItem>
                  <MenuItem value={3}>Días Grado</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </BsCol>
        ) : null}
        {filter ? (
          <>
            {activeGraphState === 1 ? (
              <BsCol class='col-md-12 mb-2'>
                <CardPaper
                  variant='outlined'
                  sx={{
                    borderColor: 'transparent',
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <ColdHour orchard={filter} />
                  </Box>
                </CardPaper>
              </BsCol>
            ) : null}
            {activeGraphState === 2 ? (
              <BsCol class='col-md-12 mb-2'>
                <CardPaper
                  variant='outlined'
                  sx={{
                    borderColor: 'transparent',
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <ColdPortion orchard={filter} />
                  </Box>
                </CardPaper>
              </BsCol>
            ) : null}
            {activeGraphState === 3 ? (
              <BsCol class='col-md-12 mb-2'>
                <CardPaper
                  variant='outlined'
                  sx={{
                    borderColor: 'transparent',
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <DegreeDays orchard={filter} />
                  </Box>
                </CardPaper>
              </BsCol>
            ) : null}
          </>
        ) : null}
      </BsRow>
    </>
  );
};

export default MainGraphsDetail;
