// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import {
  Box,
  useTheme,
  CardContent,
  Typography,
  Divider,
  Button,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal modules
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { FiltersContext } from '../../../context';

// Components
import {
  CardPaper,
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from '../../../components';

// Functions, constants, etc.
import {
  alterArrayGetVariety,
  dateFormatIso,
  generalOptionsHighCharts,
  numberFormatDecimals,
} from '../../../helpers';
import { downloadXLSFormat } from '../../../helpers/exportFunctions';

// Styles

const useStyles = makeStyles((theme) => ({
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
    },
  },
}));

const yearsArray = Array.from(
  { length: 4 },
  (_, i) => new Date().getFullYear() - i
)
  .map((year) => year.toString())
  .reverse();

const HEADERS_EXPORT = ['Rendimientos', '2021', '2022', '2023', '2024'];

const YieldOrchard = ({ orchardSelected, quarterSelected }) => {
  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const theme = useTheme();

  const quarterRef = useRef(quarterSelected);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const [tableDataState, setTableDataState] = useState(null);

  const [isBusyState, setIsBusyState] = useState(false);

  console.log(
    orchardSelected,
    'orchardSelected',
    quarterSelected,
    'quarterSelected en YieldOrchard'
  );

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    quarter_filter,
    specie_filter,
    isLoading,
  } = filterState;

  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const [loadingState, setLoadingState] = useState(false);

  async function queryData() {
    setIsBusyState(true);
    setLoadingState(true);

    const resultOrchard = userOrchard_filter.find(
      (ele) => ele.id === orchardSelected
    );

    try {
      const params = {
        orchardValue: resultOrchard.value,
        ccValue: cc_filter,
        quarterValue: quarterSelected?.value,
        specieValue: specie_filter,

      };

      const response = await axiosContext.authAxios.get('v1/map/list-yields', {
        params,
      });

      // // let data = true;

      const { data, status } = response;

      console.log(data, '<----data en orchards parameters');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setTableDataState(data);
      }
    } catch (err) {
      setTableDataState(null);
      errorHandler(err);
    } finally {
      setIsBusyState(false);
      setLoadingState(false);
    }

    // setTableDataState(DATAMOCK);
    // setLoadingState(false);
  }

  useEffect(() => {
    if (quarterSelected && orchardSelected) {
      if (!isBusyState) {
        queryData();
      }
    }
  }, [quarterSelected]);

  return (
    <CardPaper
      elevation={0}
      sx={{
        borderTop: 4,
        borderTopColor: theme.palette.secondary.main,
        borderRadius: 0,
      }}
    >
      <CardContent>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h5' gutterBottom>
              Rendimiento
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              size='small'
              disabled={!tableDataState || loadingState}
              onClick={(e) =>
                downloadXLSFormat(
                  {
                    headers: HEADERS_EXPORT,
                    data: tableDataState,
                  },
                  'Rendimientos'
                )
              }
            >
              EXCEL
            </Button>
          </Grid>
        </Grid>

        <Divider className='mt-3' />
        {quarterSelected ? (
          <div className='table-responsive'>
            {!loadingState ? (
              <table className='table'>
                <thead className={classes.theadLight}>
                  <tr>
                    <th>Rendimientos</th>
                    {yearsArray.map((year) => (
                      <th scope='col' key={year} className='text-center'>
                        {year}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Kilos Totales</td>
                    {yearsArray.map((year) => {
                      return (
                        <td key={year} className='text-center'>
                          {numberFormatDecimals(
                            tableDataState?.kilosTotal.find(
                              (ele) => ele.year === year
                            )?.value,
                            0
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Kilos por hectárea</td>
                    {yearsArray.map((year) => {
                      return (
                        <td key={year} className='text-center'>
                          {numberFormatDecimals(
                            tableDataState?.kilosHa.find(
                              (ele) => ele.year === year
                            )?.value,
                            0
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Calibre Real</td>
                    {yearsArray.map((year) => {
                      return (
                        <td key={year} className='text-center'>
                          {numberFormatDecimals(
                            tableDataState?.caliberReal.find(
                              (ele) => ele.year === year
                            )?.value,
                            2
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            ) : (
              <LoadingWidget />
            )}
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <Typography variant='h6'>Seleccione un Cuartel</Typography>
          </div>
        )}
      </CardContent>
    </CardPaper>
  );
};

export default YieldOrchard;
