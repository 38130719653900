export const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1380,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadSVG',
          'downloadPDF',
          'separator',
          'downloadCSV',
        ],
      },
    },
    menuItemDefinitions: {
      viewFullscreen: {
        text: 'Ver en pantalla completa',
      },
      printChart: {
        text: 'Imprimir',
      },
      downloadPDF: {
        text: 'Descargar PDF',
      },
      downloadCSV: {
        text: 'Descargar CSV',
      },
      downloadJPEG: {
        text: 'Descargar JPEG',
      },
      downloadSVG: {
        text: 'Descargar SVG',
      },
    },
    csv: {
      dateFormat: '%Y-%m-%d',
      itemDelimiter: ';',
      lineDelimiter: '\n',
      decimalPoint: ',',
    },
  },
};
