import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import { Controller } from 'react-hook-form';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select, { components } from 'react-select';
import classes from './SelectMultipleWidget.module.scss';
// import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../common/errorMessages.json';
// import theme from 'theme';

import theme from '../../theme';

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 		margin: theme.spacing(1),
// 		minWidth: 210,
// 		maxWidth: 230,
// 		[theme.breakpoints.down('sm')]: {
// 			minWidth: '95%',
// 		},
// 	},
// 	rootLabel: {
// 		fontSize: 18,
// 	},
// }));

const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 210,
      maxWidth: 230,
      [theme.breakpoints.down('sm')]: {
        minWidth: '95%',
      },
    },
    rootLabel: {
      fontSize: 18,
    },
  };
});

// console.log(theme, '<-----------theme');

const customStyles = {
  container: () => ({
    width: '100%',
  }),
  control: (styles) => ({
    // none of react-select's styles are passed to <Control />
    ...styles,
    paddingTop: 6,
    borderRadius: '0px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    minHeight: 34,
    backgroundColor: 'none',
  }),
  ValueContainer: (styles, { data }) => ({
    ...styles,
    // backgroundColor: 'tomato',
    paddingTop: 10,
  }),
  // menu: () => ({
  // 	width: '100%',
  // 	backgroundColor: '#f8f8f8',
  // 	maxHeight: '300px',
  // 	overflowY: 'scroll',
  // }),
  // menuList: () => ({
  // 	display: 'flex',
  // 	flexWrap: 'wrap',
  // 	width: '100%',
  // 	backgroundColor: 'white',
  // }),
  option: (provided, state) => ({
    ...provided,

    // borderBottom: '.4px solid rgba(223,223,223,.5)',
    // borderRight: '.4px solid rgba(223,223,223,.5)',
    // width: '200px',
    fontSize: theme.typography.fontSize,
    color: state.isSelected ? 'white' : theme.palette.text.primary,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: '#cccccc',
    borderRadius: '4px',
    paddingLeft: '4px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    paddingVertical: '5px',
    paddingRight: '8px',
    fontSize: 13,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    // backgroundColor: 'blue',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    ':hover': {
      backgroundColor: '#cccccc',
      color: 'white',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const SelectMultipleWidget = (props) => {
  const {
    errors,
    req,
    loading,
    control,
    name,
    options,
    defaultValue,
    placeholder,
    isMulti = true,
    style,
    ...others
  } = props;

  // const classesUI = useStyles();

  const { classes: classesUI } = useStyles();

  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  let validate = null;

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[name].required
              ? errorMessage.message[name].required
              : errorMessage.message[name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  let labelText = null;
  const isDesktop = false;
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <FormControl
      className={classesUI.formControl}
      error={stateError}
      style={style || {}}
    >
      {/* <div className={classesUI.formControl}> */}
      {/* <div className={classesUI.rootLabel}>{props.labeltext}</div>
			{props.loading && <p>cargando</p>} */}
      <InputLabel shrink={true} id={labelText} className={classesUI.rootLabel}>
        {props.labeltext} {loading && '...cargando'}
      </InputLabel>
      <div style={{ width: '100%' }}>
        <Controller
          render={({ field }) => (
            <Select
              options={options}
              isMulti={isMulti}
              {...field}
              isLoading={loading}
              menuPortalTarget={document.body}
              {...others}
              classNamePrefix='react-select'
              components={{
                DropdownIndicator,
              }}
              placeholder={placeholder}
              styles={customStyles}
            />
          )}
          name={name}
          rules={{
            required: req,
            validate: validate,
          }}
          control={control}
          defaultValue={defaultValue}
        />

        {stateError && (
          <FormHelperText className={classes.errorText} error>
            {stateMessage}
          </FormHelperText>
        )}
      </div>
      {/* </div> */}
    </FormControl>
  );
};

export default SelectMultipleWidget;
