import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import SnackBarsComponent from '../../SnackBarsComponent';
import snackBarsMessages from '../../../common/snackBarsMessages.json';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

const SaveAction = (props) => {
  const {
    mutationLoading,
    success,
    title,
    size = 'medium',
    messageType,
    fullWidth,
    disabled,
    className,
    color = 'primary',
    variant = 'contained',
    actionClose = null,
    ...others
  } = props;

  // const classesUI = useStyles();

  const snackBarMessage = snackBarsMessages.message[messageType];

  const [stateSuccess, setStateSuccess] = useState(false);
  const handleCloseSnackBarsComponent = () => {
    setStateSuccess(false);
    const closeAction = actionClose || (() => {});
    closeAction();
  };

  useEffect(() => {
    // console.log(success, '---success');
    if (success) {
      setStateSuccess(true);
    } else {
      setStateSuccess(false);
    }
  }, [success]);
  return (
    <>
      <div>
        <div
          style={
            !fullWidth ? { position: 'relative', display: 'inline-block' } : {}
          }
        >
          <LoadingButton
            color={color}
            variant={variant}
            className={className}
            size={size}
            {...others}
            fullWidth={fullWidth}
            type='submit'
            disabled={disabled || mutationLoading}
            loading={mutationLoading}
            loadingPosition='start'
            startIcon={<div />}
          >
            {title}
          </LoadingButton>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={stateSuccess}
        onClose={handleCloseSnackBarsComponent}
        autoHideDuration={4500}
        key={Math.random()}
      >
        <div>
          <SnackBarsComponent
            variant='success'
            message={snackBarMessage}
            handleClose={handleCloseSnackBarsComponent}
          />
        </div>
      </Snackbar>
    </>
  );
};

export default SaveAction;
