import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	paperModal: {
		backgroundColor: theme.palette.background.paper,
		// border: '2px solid #000',
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[3],
		padding: theme.spacing(1, 3, 3),
		width: '85%',
		height: '77vh',
	},
	scrollAreaModalStyle: {
		overflow: 'auto',
		maxHeight: '95%',
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
}));

const ModalWeather = ({ closeAction, dataModal }) => {
	const theme = useTheme();

	const classes = useStyles();

	console.log(dataModal, '<--------------------------------');

	return (
		<div className={classes.paperModal}>
			<div>
				<BsContainer fluid>
					<BsRow>
						<BsCol class="col-md-12 text-right pr-0">
							<Button onClick={closeAction}>
								<CloseIcon />
							</Button>
						</BsCol>
					</BsRow>
				</BsContainer>
			</div>
			<div className={classes.scrollAreaModalStyle}>
				<BsContainer fluid>
					<BsRow>
						<BsCol class="col-md-12">
							<Typography variant="h5" gutterBottom>
								Datos Día
							</Typography>
							<table className="table">
								<thead>
									<tr>
										<th>dato</th>
										<th>ayer</th>
										<th>hoy</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Temperatura</td>
										<td>14,5º</td>
										<td>17,3º</td>
									</tr>
								</tbody>
							</table>
						</BsCol>
					</BsRow>
				</BsContainer>
			</div>
		</div>
	);
};

export default ModalWeather;
