import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { Card, CardContent, Divider, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ButtonAction, SimpleIconButton } from '../../../components';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
	dashDelete,
	numberFormatDecimals,
	dateConvertString,
	dateFormatIso,
} from '../../../helpers';
import { async } from 'validate.js';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	buttonContainer: {
		textAlign: 'right',
		marginBottom: 20,
	},
	printArea: {
		'@media print': {
			padding: 20,
		},
	},
	contractorArea: {
		'& th': {
			fontSize: 16,
		},
	},
}));

const HarvestReport = () => {
	const theme = useTheme();

	const {
		state: { button, workers, orchard },
	} = useLocation();

	const reportRef = useRef();

	const axiosContext = useContext(AxiosContext);

	// console.log(workers, '-------dataState');

	let history = useHistory();

	const { userId, userName } = useContext(Context);

	console.log(userId, userName, '<-------contexgt');

	const [workersDataState, setWorkersDataState] = useState(workers);
	const [orchardDataState, setOrchardDataState] = useState(orchard);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);

	const [totalAmountState, setTotalAmountState] = useState(0);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'huerto',
		option: 'asc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [rowToHandleState, setRowToHandleState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const classes = useStyles();

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const getHarvestByDay = async () => {
		let data = {
			orchard: orchardDataState.orchardValue,
			date: dateFormatIso(dateConvertString(orchardDataState.date)),
		};

		try {
			const response = await axiosContext.authAxios.post(
				'v1/traceability/contractor-list-worker',
				data
			);
			const bodyInfo = response.data;
			// const bodyInfo = INFO[0];

			console.log(response, '<---LLEGA RESPONSE DATA--');

			if (bodyInfo) {
				setWorkersDataState(bodyInfo);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (workers.length > 0) {
			setWorkersDataState(workers);
		} else {
			console.log('--------------entra NO HAY WORKERS-----_>');
			getHarvestByDay();
		}
		setOrchardDataState(orchard);
	}, []);

	const handlePrint = useReactToPrint({
		content: () => reportRef.current,
	});

	const createWorkerDay = async () => {
		console.log('crea');
		setLoadingQueryState(true);

		const query = {
			// profile: +values.profile,
			orchard: dashDelete(orchard),
			orchardValue: orchard,
			totalAmount: totalAmountState,
			user: {
				id: userId,
				name: userName,
			},
		};

		console.log(query, 'envia a create');
		try {
			const response = await axiosContext.authAxios.post(
				'v1/traceability/end-worker-day-create',
				query
			);
			const bodyInfo = response.data;

			// setProfileSelectedState('');
			console.log(response, '<-----response end-worker-day-create');

			if (response.status === 200) {
				// alert('listo');
				setSuccessQueryState(true);
			}

			setLoadingQueryState(false);
		} catch (err) {
			setLoadingQueryState(false);
		}
	};

	const HeaderContractor = ({ dataId, dataName }) => {
		// console.log(dataId, '-----');
		return (
			<table className="table">
				<thead className={classes.contractorArea}>
					<tr>
						<th>{dataName}</th>
						<th>{dataId}</th>
						{/* <th>{data.phone}</th> */}
					</tr>
				</thead>
			</table>
		);
	};

	let totalPrice = 0;

	const Harvesters = ({ data }) => {
		// console.log(data, '-------');
		return (
			<table className="table">
				<thead>
					<tr>
						<th>qr</th>
						<th>nombre</th>
						<th>rut</th>
						<th>Totes</th>
						<th>a Pagar</th>
					</tr>
				</thead>
				<tbody>
					{data.map((harvester) => {
						totalPrice = totalPrice + harvester.price;
						console.log(
							'...---psaa total',
							totalPrice,
							harvester.price
						);

						return (
							<tr key={harvester.idCard}>
								<td>{harvester.idCard}</td>
								<td>{harvester.name}</td>
								<td>{harvester.rut}</td>
								<td>{harvester.totes}</td>
								<td>
									{numberFormatDecimals(harvester.price, 0)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};

	useEffect(() => {
		console.log('------> pasa usf', totalPrice);
		setTotalAmountState(totalPrice);
	}, [totalPrice, workersDataState]);

	// console.log(workersDataState, '--------workersDataState');

	const redirect = (link) => {
		history.push({
			pathname: link,
		});
	};

	const redirectHandler = () => {
		redirect('/harvest-list');
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div style={{ minHeight: '300px' }}>
									<div className={classes.buttonContainer}>
										<SimpleIconButton
											actionOnClick={handlePrint}
											labelButton="Imprimir o PDF"
											icon={<PictureAsPdfIcon />}
										/>
									</div>
									{workersDataState.length > 0 && (
										<div
											className={classes.printArea}
											ref={reportRef}
										>
											<p>
												Detalle del cierre diario del
												Huerto por Contratista.
											</p>
											{workersDataState.map(
												(contractor, i) => {
													return (
														<div
															key={contractor.id}
														>
															<HeaderContractor
																dataId={
																	contractor.id
																}
																dataName={
																	contractor.name
																}
															/>
															<Harvesters
																data={
																	contractor.workers
																}
															/>
														</div>
													);
												}
											)}
											<table className="table">
												<tbody>
													<tr
														style={{
															borderTop:
																'1px solid grey',
														}}
													>
														<td>
															<b>
																TOTAL A PAGAR:
															</b>{' '}
														</td>
														<td
															style={{
																fontWeight: 800,
																fontSize: 18,
																width: '18%',
															}}
														>
															${' '}
															{numberFormatDecimals(
																totalAmountState,
																0
															)}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									)}
									{button && (
										<div>
											<ButtonAction
												labelButton="Registrar Cierre"
												color="secondary"
												size="large"
												actionOnClick={createWorkerDay}
												className="roundedButton"
												variant="contained"
												messageType="add"
												actionClose={redirectHandler}
												mutationLoading={
													loadingQueryState
												}
												success={successQueryState}
											/>
										</div>
									)}
								</div>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default HarvestReport;
