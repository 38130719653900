import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import LogoSign from '../../assets/Logologo.png';
import {
  // Button,
  IconButton,
  InputLabel,
  Link,
  Typography,
  Alert,
} from '@mui/material';
import { SaveAction, TextFieldWidgetTextBig } from '../../components';
import classes from './RecoveryPassword.module.scss';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import viewsTexts from '../../common/viewsTexts.json';
import generalTexts from '../../common/generalTexts.json';
import { useTheme } from '@mui/styles';
import { AxiosContext } from '../../context/AxiosContext';

const RecoveryPassword = (props) => {
  const axiosContext = useContext(AxiosContext);

  const [loadingState, setLoadingState] = useState(false);
  const [successRecoveryState, setSuccessRecoveryState] = useState(false);

  const theme = useTheme();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values) => {
    const { email } = values;
    setLoadingState(true);

    const objQuery = {
      email: email,
    };

    try {
      const response = await axiosContext.publicAxios.post(
        'v1/user/recover-password-user',
        objQuery
      );

      //   console.log(response, '------response');
      const { status, data } = response;
      if (status === 200) {
        setValue('email', '');
        setSuccessRecoveryState(true);
        setLoadingState(false);
      }
    } catch (error) {
      console.log(error, 'error');
      setLoadingState(false);
    }
  };

  return (
    <div className={classes.root}>
      <BsContainer fluid class='h-100 px-0 px-md-3' style={{ height: '100%' }}>
        <BsRow class='h-100 justify-content-center align-items-center no-gutters'>
          <BsCol class={['col-xl-4 col-lg-5'].join(' ')}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <img
                  src={LogoSign}
                  alt='analytics'
                  className={clsx('mb-4 mb-md-2', classes.imgPhone)}
                />
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Typography
                    className={classes.title}
                    variant='h3'
                    gutterBottom
                  >
                    {viewsTexts.titles.recoveryPassword.title}
                  </Typography>
                  <div className='mt-5 mt-md-4'>
                    <InputLabel error={Boolean(errors.email)}>Email</InputLabel>
                    <TextFieldWidgetTextBig
                      name='email'
                      placeholder='email'
                      // label={viewsTexts.forms.email}
                      label=''
                      req
                      margin='dense'
                      defaultValue=''
                      control={control}
                      errors={errors.email}
                      // autoComplete="email"
                    />

                    <div className={classes.wrapper}>
                      <SaveAction
                        title='Recuperar'
                        color='secondary'
                        size='large'
                        className='bigButton'
                        fullWidth={true}
                        variant='contained'
                        messageType='add'
                        mutationLoading={loadingState}
                        success={false}
                      />
                    </div>
                  </div>
                  {successRecoveryState && (
                    <div className='text-center'>
                      <Alert severity='success'>
                        Datos enviados a su email
                      </Alert>
                    </div>
                  )}

                  <div className='text-right mt-5'>
                    <Typography variant='body1'>
                      <Link
                        color='primary'
                        component={RouterLink}
                        to='/sign-in'
                        variant='body1'
                      >
                        {generalTexts.textLinkSignin}
                      </Link>
                    </Typography>
                  </div>
                </form>
              </div>
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default withRouter(RecoveryPassword);
