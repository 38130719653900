import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Footer } from '../components/Shared';
import { IsDesktopHandler } from '../../helpers';
import { Box, Toolbar } from '@mui/material';
import { Topbar, WrapperButtons } from './components';
import WrapperTabs from './components/WrapperTabs';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  // minHeight: '100vh',
}));

const MainAreaContent = styled('div')((prop) => ({
  flexGrow: 1,
  minHeight: 'calc(100% - 70px)',
  display: prop.flex ? 'flex' : 'block',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: theme.palette.background.paper,
    // },
  },
  content: {
    paddingTop: 90,
    minHeight: '100vh',
  },
  contentLessFooter: {
    flexGrow: 1,
    minHeight: 'calc(100% - 70px)',
    display: 'flex',
  },
}));

const MainV3 = (props) => {
  const { children, flex = false, moduleId = '' } = props;

  const classes = useStyles();

  const isDesktop = IsDesktopHandler('lg');

  return (
    <Box>
      <CssBaseline />
      <Topbar moduleId={moduleId} />
      {/* <Toolbar /> */}
      <div className={classes.content}>
        <Main>
          <MainAreaContent flex={flex}>
            {/* segun propiedad módulo y layout agrega tabs sobre el módulo */}
            <WrapperButtons moduleId={moduleId}>{children}</WrapperButtons>
          </MainAreaContent>
        </Main>
      </div>
      <Footer />
    </Box>
  );
};

export default MainV3;
