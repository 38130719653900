// import { createMuiTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const original = createTheme();

const theme = createTheme({
  palette,
  typography: typography,
  // componentes default modificados
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiTableRowHead: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid' + palette.grey[200],
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [...original.shadows, 'inset 0px 5px 9px -10px rgba(0, 0, 0, 0.2)'],
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
