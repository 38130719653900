import React from 'react';
import classes from './WindowOfSuccess.module.scss';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import { Card, CardContent, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	pay: {
		display: 'flex',
		alignItems: 'center',
		width: '22%',
	},
}));

const WindowOfSuccess = (props) => {
	const classesU = useStyles();
	return (
		<Card>
			<Divider />
			<CardContent className={classes.containerWindow}>
				<IconButton key="check" aria-label="check" color="inherit">
					<CheckCircleIcon className={classes.icon} />
				</IconButton>
				<h5>Tu pago se ha realizado con exito!</h5>
				<div className={classes.download}>
					<a
						className={classes.downloadLink}
						href="./"
						target="_blank"
						rel="noopener noreferrer"
						download
					>
						Descargar
					</a>
					<Button
						variant="contained"
						color="primary"
						className={classesU.pay}
						onClick={() => {
							// setActionStageState(2);
							// setStageUpdateState(true);
						}}
					>
						Enviar al correo
					</Button>
				</div>
				<div className={classes.voucherContainer}>
					<div className={classes.headerVoucher}>
						Comprobante de pago
					</div>
					<div className={classes.infoVoucher}>
						<span className={classes.titleDetail}>
							N° TRANSACCIÓN
						</span>
						<p>111111111</p>
						<span className={classes.titleDetail}>
							FECHA DE PAGO
						</span>
						<p>20/10/20 | 15:32:04</p>
						<span className={classes.titleDetail}>
							CUOTAS PAGADAS
						</span>
						<p> 3</p>
						<span className={classes.titleDetail}>MONTO TOTAL</span>
						<p>$50.000</p>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default WindowOfSuccess;
