import {
  Avatar,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import './carouselStyles.css';
import clsx from 'clsx';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import { FiltersContext } from '../../../context';
import {
  ButtonAction,
  MapCarouselButton,
  SaveAction,
  SimpleIconButton,
} from '../../../components';
import { useHistory } from 'react-router-dom';
import { numberFormat } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    backgroundColor: theme.palette.background.default,
    height: 90,
    width: '100%',
    zIndex: 990,
  },
  carouselContainer: {
    height: '100%',
    '&& .react-multi-carousel-track': {
      height: '100%',
    },
  },
  colInfoStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 50,
      borderLeft: '1px solid',
      borderColor: theme.palette.grey[300],
    },
  },
  tableStyles: {
    marginTop: 5,
    '& td': {
      padding: '0.45rem',
      fontSize: 14,
    },
  },
  ccContainerStyle: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ccQuarterStyle: {
    marginTop: 20,
    paddingRight: 38,
  },
  quarterContainerStyle: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  activeButton: {
    backgroundColor: 'rgba(127,146,167, 0.3)',
  },
}));

const moduleOrchardFilter = 'statusOrchard';

const MenuSlider = ({ clickHandler, orchardHandler }) => {
  const classes = useStyles();

  let history = useHistory();

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);
  const [orchardDataMenuState, setOrchardDataMenuState] = useState(null);
  const [ccSelectedState, setCcSelectedState] = useState(null);
  const [quarterSelectedState, setQuarterSelectedState] = useState(null);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  // console.log(
  // 	'--------userOrchard_filter',
  // 	orchard_filter,
  // 	orchard_id_filter,
  // 	userOrchard_filter
  // );

  const handleButtonClick = (idOrchard, value, type) => {
    console.log(idOrchard, '<----------CLICK EN BOTON');
    setCcSelectedState(null);
    setQuarterSelectedState(null);
    if (orchard_id_filter !== idOrchard) {
      addFilter({
        cc_filter: '',
        quarter_filter: '',
      });
    }
    addFilter({
      orchard_filter: value,
      orchard_id_filter: idOrchard,
    });
    if (type === 2) {
      setOrchardSelectedState(idOrchard);
    }

    clickHandler();
  };

  const handleButtonClose = () => {
    console.log('close en el boton');
    setOrchardSelectedState(null);
  };

  useEffect(() => {
    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele._id === orchard_id_filter
    );
    setOrchardDataMenuState(resultOrchard[0]);
  }, [orchard_id_filter]);

  const handleQuarterClick = (cc, quarter) => {
    // console.log(orchardSelectedState, cc, quarter, '<---------------');
    setCcSelectedState(cc);
    setQuarterSelectedState(quarter);
    addFilter({
      cc_filter: cc.value,
      quarter_filter: quarter.value,
    });
    // const resultQuarter = orchardSelectedState.filter(
    // 	(ele) => ele._id === orchard_id_filter
    // );
    // setQuarterSelectedState();
  };

  // console.log(userOrchard_filter, '-----');

  return (
    <>
      <div className={classes.menuWrapper}>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=''
          containerClass={classes.carouselContainer}
          // customRightArrow={<CustomRightArrow />}
          dotListClass=''
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=''
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1190,
              },
              items: 6,
              partialVisibilityGutter: 20,
            },
            mobile: {
              breakpoint: {
                max: 600,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1190,
                min: 600,
              },
              items: 3,
              partialVisibilityGutter: 20,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=''
          slidesToSlide={3}
          swipeable
        >
          {
            // deja solo huertos con statusOrchard true
            userOrchard_filter &&
              userOrchard_filter.filter(
                (ele) => ele[moduleOrchardFilter] === true
              ).length > 0 &&
              userOrchard_filter
                .filter((ele) => ele[moduleOrchardFilter] === true)
                .map((orchard) => {
                  return (
                    <MapCarouselButton
                      key={orchard._id}
                      name={orchard.label}
                      handleClick={(type) => {
                        orchardHandler(orchard._id, orchard.value, type);
                        setOrchardSelectedState(orchard._id);
                      }}
                      handleClose={handleButtonClose}
                      icon={orchard.icon}
                      idOrchard={orchard._id}
                      selectedId={orchardSelectedState}
                    />
                  );
                })
          }
        </Carousel>
      </div>
    </>
  );
};

export default MenuSlider;
