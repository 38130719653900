import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { subDays } from 'date-fns';
import { useErrorHandler } from 'react-error-boundary';
import {
  FILTER,
  INFO,
  INFONULL,
  INFO2022,
  RESPONSEFORM,
} from '../../helpers/Mockups';
import {
  DataTableWidget,
  SaveAction,
  SelectMultipleWidget,
  LoadingWidget,
  TextFieldWidgetSelectController,
  TextFieldWidgetDateController,
} from '../../components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  Hidden,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import { MobileFilters } from 'containers/Library';
// import { async } from 'validate.js';
// import { fetchConToken, fetchSinToken } from 'helpers/fetch';
import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  getOrchardVarieties,
  getOrchardVarietiesDeep,
  alterArrayGetFields,
  dateFormatIso,
} from '../../helpers';
// import theme from 'theme';
import { tabItems } from './tabList';
import { Gantt } from '../../containers/Projection';
import { FiltersContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

// const SEASONS_MOCK = [
// 	{ label: '2015', value: '2015' },
// 	{ label: '2016', value: '2016' },
// 	{ label: '2017', value: '2017' },
// 	{ label: '2018', value: '2018' },
// 	{ label: '2019', value: '2019' },
// 	{ label: '2020', value: '2020' },
// 	{ label: '2021', value: '2021' },
// 	{ label: '2022', value: '2022' },
// ];

const Projection = (props) => {
  const { userId, userHuerto, userName, userOrchard } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    season_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  // console.log('----', filterState);

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const errorHandler = useErrorHandler();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const actualYear = new Date().getFullYear();

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(true);

  const [filterDataResponseState, setFilterDataResponseState] =
    useState(userOrchard_filter);

  const theme = useTheme();

  // console.log(theme)

  const classes = useStyles();

  const [valueTabState, setValueTabState] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const getFilterVarietyAsync = async () => {
    try {
      const params = { 
        specieValue: specie_filter, 
      };
      const response = await axiosContext.authAxios.get('v1/variety', { params, }); 

      if (response.data) {
        // console.log(response.data, "<-------response.data")
        setVarietyFullDataState(response.data);
      }
    } catch (error) {
      errorHandler(error);
      // console.log(error);
      // setLoadingVarietyFilterState(false);
    }
  };

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);

    // console.log(values, "<--------")

    const dateBegin = values.since || '';
    const dateEnd = values.until || '';

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    // if (!values.orchard && !values.season) {
    // 	setError('neitherItemlist', {
    // 		type: 'manual',
    // 		message: 'Debe seleccionar al menos Huerto y/o Temporada',
    // 	});
    // 	return;
    // }
    let data = {
      huerto: values.orchard,
      // centro_costo: values.cc,
      huerto_user: userOrchard, //orchard del login
      // cuartel: values.quarter,
      variedad: values.variety
        ? alterArrayGetFields(values.variety, ['value'])
        : '',
      sinceDate: dateFormatIso(dateBegin),
      untilDate: dateFormatIso(dateEnd),
      // temporada: values.season,
    };

    const idOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard
    );

    const idOrchardFilter = idOrchard[0] ? idOrchard[0]._id : null;

    addFilter({
      orchard_filter: values.orchard,
      orchard_id_filter: idOrchardFilter,
      // cc_filter: values.cc,
      // quarter_filter: values.quarter,
      variety_filter: values.variety,
      // season_filter: values.season,
    });
    setInfoFilterTab(data);

    // setInfoFilterTab(INFO);
  };

  useEffect(() => {
    if (orchard_filter) {
      setValue('orchard', orchard_filter);
    }
  }, []);

  // quita el loading terminado las queries
  useEffect(() => {
    if (varietyFullDataState.length) {
      setLoadingFiltersState(false);
    }
  }, [varietyFullDataState]);

  // endpoints variety - rootstock
  useEffect(() => {
    getFilterVarietyAsync();
  }, []);

  // VARIETY
  useEffect(() => {
    // console.log(useOrchard, 'useOrchard -- para variety');
    // cada vez que hay un orchard
    // carga la data de variety
    if (useOrchard) {
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard
      );

      const orchardsCc = resultOrchard[0].cc;

      console.log(orchardsCc, '<<------------------------------las varieties');

      setVarietyDataState(getOrchardVarietiesDeep(orchardsCc));
    } else {
      // vuelve a dejar variety completo
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [useOrchard]);

  // alimenta las varieties full
  // solo cuando no tiene nada
  useEffect(() => {
    if (varietyFullDataState.length && !varietyDataState.length) {
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [varietyFullDataState]);

  console.log(varietyDataState, '<--------varietyDataState');

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  // filtros
  const renderFilters = () => {
    return (
      <MobileFilters>
        {errors.neitherItemlist && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.neitherItemlist.message}
          </p>
        )}
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group'>
                <TextFieldWidgetSelectController
                  options={filterDataResponseState}
                  control={control}
                  titleOption='label'
                  name='orchard'
                  labeltext='Huerto'
                  errors={errors.orchard}
                  defaultValue=''
                  loading={loadingFiltersState}
                  // req
                  withDefault
                  variant='standard'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext='Variedad'
                  name='variety'
                  isSearchable
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Seleccione Variedad'
                />
              </div>
              <div className='input-group ml-md-2 hide-year'>
                <TextFieldWidgetDateController
                  name='since'
                  labeltext='Desde'
                  // value={sinceSelectedState}
                  // onChange={date => setSinceSelectedState(date)}
                  minDate={minDateValidationState}
                  control={control}
                  errors={errors.since}
                  views={['month', 'day']}
                  defaultValue={subDays(new Date(), 1)}
                />
              </div>
              <div className='input-group ml-md-2'>
                <TextFieldWidgetDateController
                  name='until'
                  labeltext='Hasta'
                  views={['month', 'day']}
                  // value={untilSelectedState}
                  // onChange={date => setUntilSelectedState(date)}
                  maxDate={maxDateValidationState}
                  control={control}
                  minDate={minDateState}
                  errors={errors.until}
                  // defaultValue={null}
                  defaultValue={new Date()}
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <SaveAction
            title='Filtrar'
            color='secondary'
            size='large'
            className='roundedButton ml-md-2'
            variant='contained'
            disabled={loadingFiltersState}
            messageType='add'
            mutationLoading={false}
            success={false}
          />
        </form>
        {errors.incorrectDates && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.incorrectDates.message}
          </p>
        )}
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 col-md-12 mb-md-3'>
            <Card
              elevation={0}
              // classes={{
              // 	root: 'MainCardRoot',
              // }}
            >
              <CardContent>
                <BsRow class='mb-1 mb-md-4'>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Proyección Cosecha
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardContent>
                <SimpleTabs
                  tabitems={tabItems}
                  value={valueTabState}
                  toggler={handleChangeTab}
                >
                  <TabPanel value={valueTabState} index={0}>
                    <Gantt filter={infoFilterTab} />
                  </TabPanel>
                </SimpleTabs>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Projection;
