import React from 'react';
import Fade from '@mui/material/Fade';
import classes from './EditionAreaWidget.module.scss';

const EditionAreaWidget = (props) => {
	return (
		<Fade in={props.visible} timeout={800}>
			<div className={classes.EditionStyle}>{props.component}</div>
		</Fade>
	);
};

export default EditionAreaWidget;
