// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useTheme } from '@mui/material';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import { DataTableWidget, FilterColumnTableWidget } from 'components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from 'context/AxiosContext';
import { Context } from 'context/Context';
import {
  // alterArrayGetIds,
  IsDesktopHandler,
  // numberFormat,
  // dashDelete,
  numberFormatDecimals,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    // 	paddingRight: 34,
    // },
  },
}));

const EighthTab = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const { userOrchard } = useContext(Context);

  const axiosContext = useContext(AxiosContext);

  const isDesktop = IsDesktopHandler('md');

  const columns = useMemo(
    () => [
      {
        name: 'Temporada',
        selector: (row) => row.season,
        sortField: 'season',
        // hide: 'sm',
        sortable: true,
        id: 1,
      },
      {
        name: 'Cliente',
        selector: (row) => row.client,
        sortField: 'client',
        // hide: 'sm',
        sortable: true,
        id: 2,
      },
      {
        name: 'Agrícola',
        selector: (row) => row.agroBusiness,
        sortField: 'agroBusiness',
        // hide: 'sm',
        sortable: true,
        id: 3,
      },
      {
        name: 'Huerto',
        selector: (row) => row.orchard,
        sortField: 'orchard',
        sortable: true,
        id: 4,
      },
      {
        name: 'Especie',
        selector: (row) => row.specie,
        sortField: 'specie',
        sortable: true,
        id: 5,
      },
      {
        name: 'Centro de Costos',
        selector: (row) => row.cc,
        sortField: 'cc',
        sortable: true,
        id: 6,
      },
      {
        name: 'Cuartel',
        selector: (row) => row.quarter,
        sortField: 'quarter',
        // hide: 'md',
        sortable: true,
        id: 7,
      },
      {
        name: 'Variedad',
        selector: (row) => row.variety,
        sortField: 'variety',
        // hide: 'sm',
        sortable: true,
        id: 8,
      },
      {
        name: 'Portainjerto',
        selector: (row) => row.rootStock,
        sortField: 'rootStock',
        // hide: 'sm',
        sortable: true,
        id: 9,
      },
      {
        name: 'Frutos deformes (%)',
        selector: (row) => row.fDeformed,
        sortField: 'fDeformed',
        format: (row) => numberFormatDecimals(row.fDeformed, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 10,
      },
      {
        name: 'Daño por insecto (%)',
        selector: (row) => row.fDInsect,
        sortField: 'fDInsect',
        format: (row) => numberFormatDecimals(row.fDInsect, 1),
        // hide: 'md',
        compact: true,
        sortable: true,
        id: 11,
      },
      {
        name: 'Daño por trips (%)',
        selector: (row) => row.fDTrips,
        sortField: 'fDTrips',
        format: (row) => numberFormatDecimals(row.fDTrips, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 12,
      },
      {
        name: 'Daño por escama (%)',
        selector: (row) => row.fDScale,
        sortField: 'fDScale',
        format: (row) => numberFormatDecimals(row.fDScale, 1),
        sortable: true,
        compact: true,
        id: 13,
      },
      {
        name: 'Guata blanca (%)',
        selector: (row) => row.fSoftSide,
        sortField: 'fSoftSide',
        format: (row) => numberFormatDecimals(row.fSoftSide, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 14,
      },
      {
        name: 'Fruto sin pedicelo (%)',
        selector: (row) => row.fWithoutPedicel,
        sortField: 'fWithoutPedicel',
        format: (row) => numberFormatDecimals(row.fWithoutPedicel, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 15,
      },
      {
        name: 'fruto doble (%)',
        selector: (row) => row.fDoble,
        sortField: 'fDoble',
        format: (row) => numberFormatDecimals(row.fDoble, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 16,
      },
      {
        name: 'Herida cicatrizada (%)',
        selector: (row) => row.fWoundScarred,
        sortField: 'fWoundScarred',
        format: (row) => numberFormatDecimals(row.fWoundScarred, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 17,
      },
      {
        name: 'Frutos con manchas (%)',
        selector: (row) => row.fStains,
        sortField: 'fStains',
        format: (row) => numberFormatDecimals(row.fStains, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 18,
      },
      {
        name: 'Media luna (%)',
        selector: (row) => row.fHalfMoon,
        sortField: 'fHalfMoon',
        format: (row) => numberFormatDecimals(row.fHalfMoon, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 19,
      },
      {
        name: 'Mezcla de variedad (%)',
        selector: (row) => row.mixVar,
        sortField: 'mixVar',
        format: (row) => numberFormatDecimals(row.mixVar, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 20,
      },
      {
        name: 'Russet (%)',
        selector: (row) => row.fRusset,
        sortField: 'fRusset',
        format: (row) => numberFormatDecimals(row.fRusset, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 21,
      },
      {
        name: 'Sutura (%)',
        selector: (row) => row.fWound,
        sortField: 'fWound',
        format: (row) => numberFormatDecimals(row.fWound, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 22,
      },
      {
        name: 'Basura (%)',
        selector: (row) => row.waste,
        sortField: 'waste',
        format: (row) => numberFormatDecimals(row.waste, 1),
        sortable: true,
        compact: true,
        id: 23,
      },
      {
        name: 'Fruto deshidratado (%)',
        selector: (row) => row.fDehyd,
        sortField: 'fDehyd',
        format: (row) => numberFormatDecimals(row.fDehyd, 1),
        // hide: 'md',
        compact: true,
        sortable: true,
        id: 24,
      },
      {
        name: 'Pedicelo deshidratado (%)',
        selector: (row) => row.pedDehyd,
        sortField: 'pedDehyd',
        format: (row) => numberFormatDecimals(row.pedDehyd, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 25,
      },
      {
        name: 'Daño de pajaro (%)',
        selector: (row) => row.fDBird,
        sortField: 'fDBird',
        format: (row) => numberFormatDecimals(row.fDBird, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 26,
      },
      {
        name: 'Frutos blandos (%)',
        selector: (row) => row.fSoft,
        sortField: 'fSoft',
        format: (row) => numberFormatDecimals(row.fSoft, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 27,
      },
      {
        name: 'Herida abierta (%)',
        selector: (row) => row.fWoundOpen,
        sortField: 'fWoundOpen',
        format: (row) => numberFormatDecimals(row.fWoundOpen, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 28,
      },
      {
        name: 'Inicio de deshidratación (%)',
        selector: (row) => row.fStartDehy,
        sortField: 'fStartDehy',
        format: (row) => numberFormatDecimals(row.fStartDehy, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 29,
      },
      {
        name: 'Inicio deshidratación de pedicelo (%)',
        selector: (row) => row.pedStartDehy,
        sortField: 'pedStartDehy',
        format: (row) => numberFormatDecimals(row.pedStartDehy, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 30,
      },
      {
        name: 'Machucón (%)',
        selector: (row) => row.fBruising,
        sortField: 'fBruising',
        format: (row) => numberFormatDecimals(row.fBruising, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 31,
      },
      {
        name: 'Mancha de pudrición (%)',
        selector: (row) => row.fStainPudrition,
        sortField: 'fStainPudrition',
        format: (row) => numberFormatDecimals(row.fStainPudrition, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 32,
      },
      {
        name: 'Partidura estilar (%)',
        selector: (row) => row.fCracksSide,
        sortField: 'fCracksSide',
        format: (row) => numberFormatDecimals(row.fCracksSide, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 33,
      },
      {
        name: 'Partidura de agua (%)',
        selector: (row) => row.fCracksWater,
        sortField: 'fCracksWater',
        format: (row) => numberFormatDecimals(row.fCracksWater, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 34,
      },
      {
        name: 'Frutos con partiduras (%)',
        selector: (row) => row.fCracks,
        sortField: 'fCracks',
        format: (row) => numberFormatDecimals(row.fCracks, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 35,
      },
      {
        name: 'Pitting (%)',
        selector: (row) => row.fPitting,
        sortField: 'fPitting',
        format: (row) => numberFormatDecimals(row.fPitting, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 36,
      },
      {
        name: 'Pudrición negra (%)',
        selector: (row) => row.fPudBlack,
        sortField: 'fPudBlack',
        format: (row) => numberFormatDecimals(row.fPudBlack, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 37,
      },
      {
        name: 'Pudrición parda (%)',
        selector: (row) => row.fPudBrown,
        sortField: 'fPudBrown',
        format: (row) => numberFormatDecimals(row.fPudBrown, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 38,
      },
      {
        name: 'Pudrición (%)',
        selector: (row) => row.fPudrition,
        sortField: 'fPudrition',
        format: (row) => numberFormatDecimals(row.fPudrition, 1),
        // hide: 'sm',
        compact: true,
        sortable: true,
        id: 39,
      },
    ],
    []
  );

  // console.log(filterProp, "<---llega el filtro");

  const [tableColumnsState, setTableColumnsState] = useState(columns);

  const toggleColumn = (id) => {
    let columns = [...tableColumnsState];
    columns[id].omit = !columns[id].omit;
    setTableColumnsState(columns);
  };

  const toggleAllColumn = (state) => {
    // console.log(state, 'togle all', tableColumnsState);
    let columnsToogleAll = [...tableColumnsState];
    columnsToogleAll.map((col) => (col.omit = !state));
    setTableColumnsState(columnsToogleAll);
  };

  // table
  const [loadingDataTableState, setLoadingDataTableState] = useState(false);
  const [tableDataState, setTableDataState] = useState([]);
  const [countState, setCountState] = useState(0);
  const [pageState, setPageState] = useState(1);
  const [sortState, setSortState] = useState({
    key: 'huerto',
    option: 'asc',
  });
  const [limitState, setLimitState] = useState(10);
  const [resetPaginationToggleState, setResetPaginationToggleState] =
    useState(false);
  const [busyState, setBusyState] = useState(false);

  const [anchorElState, setAnchorElState] = useState(null);

  const customStyles = {
    headCells: {
      style: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        // '&: nth-last-child(-n+3)': {
        //     backgroundColor: theme.palette.primary.main,
        //     borderBottom: '4px solid',
        //     borderColor: theme.palette.secondary.light
        // },
        div: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      },
    },
  };

  async function queryData(page, sort = sortState) {
    setLoadingDataTableState(true);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const {
        huerto,
        centro_costo,
        cuartel,
        variedad,
        porta_injerto,
        temporada,
      } = filterProp;

      let objQuery = {
        huerto: huerto,
        huerto_user: userOrchard, //orchard del login
        centro_costo: centro_costo,
        cuartel: cuartel,
        variedad: variedad,
        rootStock: porta_injerto,
        season: temporada,
        page: page,
        limit: limitState,
        sort: sort ? sort : sortState,
      };

      console.log(objQuery, '---la query que va');

      const response = await axiosContext.authAxios.post(
        'v1/library/reception',
        objQuery
      );

      const { data, status } = response;

      console.log(response, '<-----response query');

      // setCountState(data.totalPassengers);
      if (data.data) {
        // setTableDataState(data.data);
        console.log('eeeeeeee', data);
        setCountState(data.count);
        setTableDataState(data.data);
        setPageState(page);
        setSortState(sort);
        setBusyState(false);
      }
      setLoadingDataTableState(false);
    } catch (err) {
      setTableDataState([]);
      setBusyState(false);
      setLoadingDataTableState(false);
    }
  }

  // table functions
  const handleOnSort = async (column, sortDirection) => {
    // console.log('handleSort', column, sortDirection);
    // let nameColumn = column.sortField;
    // console.log(column.selector.toString().split(".")[1], "---");
    // let nameColumn = column.selector.toString().split('.')[1];
    let nameColumn = column.sortField;
    // nameColumn = undefined;
    // console.log(nameColumn, '<---nombre de la col que llega');
    if (nameColumn !== null) {
      // console.log({ key: nameColumn, option: sortDirection})
      if (!busyState) {
        queryData(pageState, {
          key: nameColumn,
          option: sortDirection,
        });
      }
    }
  };

  const handleChangePagination = (page, totalRows) => {
    console.log(page, totalRows);
    // setPageState(page);
    if (!busyState) {
      queryData(page);
    }
  };

  const handleChangeRows = async (currentRowsPerPage, currentPage) => {
    setLoadingDataTableState(false);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const {
        huerto,
        centro_costo,
        cuartel,
        variedad,
        porta_injerto,
        temporada,
      } = filterProp;

      let objQuery = {
        huerto: huerto,
        huerto_user: userOrchard, //orchard del login
        centro_costo: centro_costo,
        cuartel: cuartel,
        variedad: variedad,
        rootStock: porta_injerto,
        season: temporada,
        page: currentPage,
        limit: currentRowsPerPage,
        sort: sortState,
      };

      console.log(objQuery, '---la query que va en handle');

      const response = await axiosContext.authAxios.post(
        'v1/library/reception',
        objQuery
      );

      const { data, status } = response;

      console.log(response, '<-----response change rows');

      // setCountState(data.totalPassengers);
      if (data.data) {
        // setTableDataState(data.data);
        console.log('eeeeeeee en handle', data);
        setCountState(data.count);
        setTableDataState(data.data);
        setLimitState(currentRowsPerPage);
      }
      setLoadingDataTableState(false);
    } catch (err) {
      setTableDataState([]);
      setLoadingDataTableState(false);
    }
  };

  const handleFilterClick = useCallback((event) => {
    setAnchorElState(event.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorElState(null);
  }, []);

  useEffect(() => {
    if (filterProp) {
      console.log('cambio filters');
      setResetPaginationToggleState(!resetPaginationToggleState);
      // setPageState(1);
      queryData(1);
      setBusyState(true);
    }
  }, [filterProp]);

  return (
    <BsRow class='justify-content-between' style={{ marginTop: -20 }}>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          {tableDataState.length > 0 && (
            <FilterColumnTableWidget
              anchorState={anchorElState}
              handleFilterClick={handleFilterClick}
              handleFilterClose={handleFilterClose}
              filterOptions={isDesktop ? columns : columns}
              toggleColumn={toggleColumn}
              toggleAllColumn={toggleAllColumn}
            />
          )}
          <DataTableWidget
            totalRows={countState}
            handleOnSort={handleOnSort}
            // conditionalRowStyles={conditionalRowStyles}
            // sortFunction={customSort}
            fixedHeader={true}
            fixedHeaderScrollHeight={'590px'}
            changePagination={handleChangePagination}
            rowsChange={handleChangeRows}
            columns={tableColumnsState}
            sortField='1'
            filteredItems={tableDataState}
            // subHeaderComponentMemo={subHeaderComponentMemo}
            loading={loadingDataTableState}
            loadingCount={loadingDataTableState}
            customStyles={customStyles}
            moduleName='Defectos Calidad'
            resetPaginationToggle={resetPaginationToggleState}
          />
        </div>
      </BsCol>
    </BsRow>
  );
};

export default EighthTab;
