import {
  Alert,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import _ from 'lodash';
import { subDays, addDays } from 'date-fns';
import { useErrorHandler } from 'react-error-boundary';
import { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  SaveAction,
  SelectMultipleWidget2,
  SimpleIconButton,
  TextFieldWidgetDateController2,
} from '../../../components';
import { FiltersContext } from '../../../context';
import {
  alphabeticalSort,
  alterArrayGetVariety,
  dateFormatIso,
  getUniqueValuesMaster,
} from '../../../helpers';
import { DashboardContent } from 'containers/Scheduling';
import { MobileFilters } from 'containers/Library';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  titleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
  },
  subtitleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
    marginBottom: '15px !important',
    marginTop: '20px !important',
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    maxWidth: '90px !important',
    minWidth: '70px !important',
  },
  dividerRow: {
    marginTop: 30,
  },
  iconText: {
    marginRight: 10,
  },
  OddColStyle: {
    backgroundColor: 'rgba(233, 244, 255,.7)',
  },
  tableStyle: {
    '& tbody tr:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  varietyNameStyle: {
    fontWeight: 600,
    fontSize: 15,
  },
}));

const endPointFilters = 'v1/libraryExternal/specie';

const getOrchardsFromaData = (data) => {
  const orchardValues = data.map((orchard) => {
    return {
      label: orchard.label,
      value: orchard.value,
    };
  });

  return orchardValues;
};

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((item) => {
    item.specie.forEach((specie) => {
      specie.productLine.forEach((productLine) => {
        productLine.variety.forEach((variety) => {
          result.push({
            orchard: {
              label: item.label,
              value: item.value,
            },
            agroBusiness: {
              label: item.agroBusiness,
              value: item.agroBusinessValue,
            },
            group: {
              label: item.group,
              value: item.groupValue,
            },
            technicBoss: {
              label: item.technicBoss,
              value: item.technicBossValue,
            },
            specie: {
              label: specie.label,
              value: specie.value,
            },
            productLine: {
              label: productLine.label,
              value: productLine.value,
            },
            variety: {
              label: variety.label,
              value: variety.value,
            },
          });
        });
      });
    });
  });

  return result;
}

const DashboardSchedule = () => {
  // let history = useHistory();

  const theme = useTheme();

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { moduleUser, userClient, userOrchard } = useContext(Context);

  const errorHandler = useErrorHandler();

  const [garces2State, setGarces2State] = useState(false);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const moduleOrchardFilter = 'statusOrchardPlanning';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [agroBusinessOptionsState, setAgroBusinessOptionsState] = useState([]);

  const [specieOptionsState, setSpecieOptionsState] = useState([]);

  const [varietyOptionsState, setVarietyOptionsState] = useState([]);

  const [filterDataTechnicBossState, setFilterDataTechnicBossState] = useState(
    []
  );

  const [filterDataGroupState, setFilterDataGroupState] = useState([]);

  const [filterDataAgroBusinessState, setFilterDataAgroBusinessState] =
    useState([]);

  const [filterOrchardState, setFilterOrchardState] = useState([]);

  const [fullDataOrchardState, setFullDataOrchardState] = useState([]);

  const [dataFilterState, setDataFilterState] = useState([]);

  const [specieDataState, setSpecieDataState] = useState([]);

  const [productionLineDataState, setProductionLineDataState] = useState([]);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  // dates

  const actualYear = new Date().getFullYear();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear + 1}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const [excelSuccessDownloadState, setExcelSuccessDownloadState] =
    useState(false);

  const [excelLoadingState, setExcelLoadingState] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useAgroBusiness = useWatch({
    control,
    name: 'agroBusiness',
  });

  const useSpecie = useWatch({
    control,
    name: 'specie',
  });

  const useProductionLine = useWatch({
    control,
    name: 'productionLine',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const useTechnicBoss = useWatch({
    control,
    name: 'technicBoss',
  });

  const useGroup = useWatch({
    control,
    name: 'group',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const downloadFile = (url) => {
    const urlDownload = url;
    const fileName = 'excel-externos.xlsx';

    // Create a temporary anchor element
    const link = document.createElement('a');

    // Set the download attribute with the desired file name
    link.download = fileName;

    // Set the href attribute to the file URL
    link.href = urlDownload;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    setExcelSuccessDownloadState(true);
  };

  const downloadHandler = async () => {
    console.log('descarga');
    setExcelSuccessDownloadState(false);
    setExcelLoadingState(true);
    const objQuery = {
      // clientValue: userClient.value,
      dateSince: dateFormatIso(sinceSelectedState),
      dateUntil: dateFormatIso(untilSelectedState),
      technicBossValue: useTechnicBoss?.value || '',
    };

    console.log(objQuery, '<<<<<------------objQuery');

    try {
      const response = await axiosContext.authAxios.post(
        'api-task/download-external-garces',
        objQuery
      );
      // console.log(response.data, '<<<<<------------');
      const { data, status } = response;
      if (status === 200) {
        // console.log(data, '<<<<<------------');
        const urlDownload = data?.menssage?.result?.sucess?.url || null;
        if (urlDownload) {
          // console.log(urlDownload, '<<<<<------------', window);
          downloadFile(urlDownload);
        } else {
          const errorDownload = data?.menssage?.result?.error || null;
          if (errorDownload) {
            alert(errorDownload);
          }
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setExcelLoadingState(false);
    }
  };

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    setValue('until', addDays(new Date(watchSince), 21));
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const objQuery = {
      clientValue: userClient.value,
      userOrchards: userOrchard,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointFilters,
        objQuery
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        data.orchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDataFilterState(data.orchard);

        setFullDataOrchardState(data.orchard);

        const flattenEndpointData = flattenEndpointDataHandler(data.orchard);

        // console.log(
        //   flattenEndpointData,
        //   '<<<<<------flattenEndpointData------',
        //   data.orchard
        // );

        setFiltersFlattenDataState(flattenEndpointData);

        // ---->agroBusiness
        const agroBusinessArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.agroBusiness,
          };
        });

        const uniqueAgroBusiness = getUniqueValuesMaster(
          agroBusinessArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        setAgroBusinessOptionsState(
          alphabeticalSort(uniqueAgroBusiness, 'label')
        );

        // ---->orchard
        const specieArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.specie,
          };
        });

        const uniqueSpecie = getUniqueValuesMaster(
          specieArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueSpecie, '<<<<<------uniqueSpecie------')

        setSpecieOptionsState(alphabeticalSort(uniqueSpecie, 'label'));

        // ---->variety
        const varietyArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.variety,
          };
        });

        const uniqueVariety = getUniqueValuesMaster(
          varietyArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueVariety, '<<<<<------uniqueVariety------')

        setVarietyOptionsState(alphabeticalSort(uniqueVariety, 'label'));

        // ---->group
        const groupArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.group,
          };
        });

        const uniqueGroup = getUniqueValuesMaster(
          groupArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        setFilterDataGroupState(alphabeticalSort(uniqueGroup, 'label'));

        // ---->texhnicBoss
        const technicBossArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.technicBoss,
          };
        });

        const uniqueTechnicBoss = getUniqueValuesMaster(
          technicBossArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        setFilterDataTechnicBossState(
          alphabeticalSort(uniqueTechnicBoss, 'label')
        );

        // ---->productionLine
        const productionLineArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.productLine,
          };
        });

        const uniqueProductionLine = getUniqueValuesMaster(
          productionLineArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        setProductionLineDataState(
          alphabeticalSort(uniqueProductionLine, 'label')
        );
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    getFilterAsync();
  }, []);

  // UseEffect para cambios de filtros
  useEffect(() => {
    console.log(
      filtersFlattenDataState,
      '<<<<<------filtersFlattenDataState------'
    );

    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      const agroBusinessFilter = useAgroBusiness
        ? ele?.agroBusiness.value === useAgroBusiness.value
        : true;
      // const orchardFilter = useOrchard
      //   ? ele?.orchard.value === useOrchard.value
      //   : true;
      const specieFilter = useSpecie
        ? ele?.specie.value === useSpecie.value
        : true;
      const productLineFilter = useProductionLine
        ? ele?.productLine.value === useProductionLine.value
        : true;
      const varietyFilter = useVariety
        ? ele?.variety.value === useVariety.value
        : true;
      const groupFilter = useGroup ? ele?.group.value === useGroup.value : true;
      const technicBossFilter = useTechnicBoss
        ? ele?.technicBoss.value === useTechnicBoss.value
        : true;

      return (
        agroBusinessFilter &&
        // orchardFilter &&
        specieFilter &&
        productLineFilter &&
        varietyFilter &&
        groupFilter &&
        technicBossFilter
      );
    });

    // TODO: de lo filtrado dejar solo los que tengan los hueertos seleccionados
    const filterUpdatedDataOrchard = filterUpdatedData.filter((ele) => {
      // buscarlo con some en el array de useOrchard
      const orchardFilter =
        useOrchard && useOrchard.length
          ? useOrchard.some((orchard) => orchard.value === ele?.orchard.value)
          : true;

      return orchardFilter;
    });

    console.log(
      filterUpdatedData,
      '<<<<<------filterUpdatedData------solo los que tienen los huertos estos',
      useOrchard,
      filterUpdatedDataOrchard
    );

    const agroBusinessArray = filterUpdatedDataOrchard.map((ele) => {
      return {
        ...ele.agroBusiness,
      };
    });

    const uniqueAgroBusiness = getUniqueValuesMaster(
      agroBusinessArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setAgroBusinessOptionsState(alphabeticalSort(uniqueAgroBusiness, 'label'));

    const orchardArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.orchard,
      };
    });

    const uniqueOrchards = getUniqueValuesMaster(
      orchardArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterOrchardState(alphabeticalSort(uniqueOrchards, 'label'));

    const specieArray = filterUpdatedDataOrchard.map((ele) => {
      return {
        ...ele.specie,
      };
    });

    const uniqueSpecie = getUniqueValuesMaster(
      specieArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setSpecieOptionsState(alphabeticalSort(uniqueSpecie, 'label'));

    const productLineArray = filterUpdatedDataOrchard.map((ele) => {
      return {
        ...ele.productLine,
      };
    });

    const uniqueProductLine = getUniqueValuesMaster(
      productLineArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setProductionLineDataState(alphabeticalSort(uniqueProductLine, 'label'));

    const varietyArray = filterUpdatedDataOrchard.map((ele) => {
      return {
        ...ele.variety,
      };
    });

    const uniqueVariety = getUniqueValuesMaster(
      varietyArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setVarietyOptionsState(alphabeticalSort(uniqueVariety, 'label'));

    const groupArray = filterUpdatedDataOrchard.map((ele) => {
      return {
        ...ele.group,
      };
    });

    const uniqueGroup = getUniqueValuesMaster(
      groupArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterDataGroupState(alphabeticalSort(uniqueGroup, 'label'));

    const technicBossArray = filterUpdatedDataOrchard.map((ele) => {
      return {
        ...ele.technicBoss,
      };
    });

    const uniqueTechnicBoss = getUniqueValuesMaster(
      technicBossArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterDataTechnicBossState(alphabeticalSort(uniqueTechnicBoss, 'label'));
  }, [
    useAgroBusiness,
    useOrchard,
    useSpecie,
    useProductionLine,
    useVariety,
    useGroup,
    useTechnicBoss,
  ]);

  const onSubmit = async (values) => {
    // console.log(values, '·-----asi llega');

    const dateBegin = values.since || '';
    const dateEnd = values.until || '';

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const filterOrchard =
      values.orchard && values.orchard.length
        ? alterArrayGetVariety(values.orchard)
        : filterOrchardState.length
        ? alterArrayGetVariety(filterOrchardState)
        : alterArrayGetVariety(getOrchardsFromaData(fullDataOrchardState));

    console.log(
      values.orchard,
      filterOrchardState,
      fullDataOrchardState,
      'data de orchard'
    );

    const data = {
      agroBusiness: values.agroBusiness,
      technicBoss: values.technicBoss,
      group: values.group,
      orchard: filterOrchard,
      variety: values.variety,
      specie: values.specie,
      productionLine: values.productionLine,
      sinceDate: dateFormatIso(dateBegin),
      untilDate: dateFormatIso(dateEnd),
    };

    console.log(data, '<<<<<---DATA PASA A QUERY---------');
    setInfoFilterTab(data);
  };

  useEffect(() => {
    if (userClient && userClient?.value === 'Garces_Fruit_2') {
      // console.log(userClient, '<<<<<------------');
      setGarces2State(true);
    }
  }, [userClient]);

  const downloadText = excelLoadingState ? '...Descargando' : 'Descargar Excel';

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            {excelSuccessDownloadState ? (
              <Alert
                onClose={() => setExcelSuccessDownloadState(false)}
                className='mb-2'
                variant='filled'
              >
                Descargado con éxito.
              </Alert>
            ) : null}
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-6'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Gráficos Planificación Cosecha
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-6 text-right'>
                    <Typography gutterBottom variant='h6' component='h2'>
                      {downloadText}
                      <Tooltip
                        title='Descarga UTILIZANDO LAS FECHAS DESDE Y HASTA DEL FILTRO y, si selecciona Jefe Técnico, descargará solo los de ese J. Técnico.'
                        placement='top-start'
                        arrow
                        followCursor
                      >
                        <i
                          className='fas fa-info-circle ml-3'
                          style={{ fontSize: 18 }}
                        ></i>
                      </Tooltip>
                    </Typography>
                    <SimpleIconButton
                      labelButton='Descargar'
                      color='primary'
                      icon={<CloudDownloadIcon />}
                      disabled={excelLoadingState || !sinceSelectedState}
                      actionOnClick={downloadHandler}
                    />
                  </BsCol>
                  <BsCol class='col-md-12 mt-3'>
                    <MobileFilters>
                      <form
                        className='form-inline'
                        autoComplete='on'
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className='input-group mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.technicBoss}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='technicBoss'
                            isSearchable
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataTechnicBossState}
                            placeholder='Jefe Técnico'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.group}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            req={false}
                            name='group'
                            display='vertical'
                            variant='outlined'
                            isMulti={false}
                            isClearable={true}
                            isSearchable
                            onlyOptionValue={false}
                            defaultValue=''
                            options={filterDataGroupState}
                            placeholder='Grupo'
                          />
                        </div>

                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.agroBusiness}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='agroBusiness'
                            isSearchable
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={agroBusinessOptionsState}
                            placeholder='Razón Social'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.orchard}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='orchard'
                            isClearable={true}
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isMulti={true}
                            req={false}
                            defaultValue=''
                            options={filterOrchardState}
                            placeholder='Huerto/s'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.specie}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='specie'
                            isClearable={true}
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={specieOptionsState}
                            placeholder='Especie'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.productionLine}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='productionLine'
                            isClearable={true}
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={productionLineDataState}
                            placeholder='l. Producción'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.variety}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='variety'
                            isClearable={true}
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={varietyOptionsState}
                            placeholder='Variedad'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <TextFieldWidgetDateController2
                            name='since'
                            labeltext='Desde'
                            placeholder='Desde'
                            display='vertical'
                            variant='outlined'
                            format='dd-MM-yyyy'
                            // value={sinceSelectedState}
                            // onChange={date => setSinceSelectedState(date)}
                            control={control}
                            errors={errors.since}
                            defaultValue={subDays(new Date(), 1)}
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <TextFieldWidgetDateController2
                            name='until'
                            labeltext='Hasta'
                            display='vertical'
                            variant='outlined'
                            format='dd-MM-yyyy'
                            maxDate={maxDateValidationState}
                            control={control}
                            minDate={minDateState}
                            errors={errors.until}
                            // defaultValue={null}
                            defaultValue={new Date()}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            paddingTop: 5,
                          }}
                        >
                          <SaveAction
                            title='Filtrar'
                            color='secondary'
                            size='large'
                            className='roundedButton ml-md-2'
                            variant='contained'
                            messageType='add'
                            mutationLoading={
                              excelLoadingState || loadingFiltersState
                            }
                            success={false}
                          />
                        </div>
                      </form>
                      {errors.incorrectDates && (
                        <p
                          style={{
                            color: theme.palette.error.main,
                          }}
                        >
                          {errors.incorrectDates.message}
                        </p>
                      )}
                    </MobileFilters>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <>
          <>
            <BsRow class='no-gutters'>
              <BsCol class='col-md-12'>
                <BsRow class='gutter_10'>
                  <BsCol class='col-md-12 col-lg-12'>
                    <Card elevation={0} className={classes.dashCard}>
                      <CardContent>
                        <DashboardContent filter={infoFilterTab} />
                      </CardContent>
                    </Card>
                  </BsCol>
                </BsRow>
              </BsCol>
            </BsRow>
          </>
        </>
      </BsContainer>
    </div>
  );
};

export default DashboardSchedule;
