import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useTheme } from '@mui/styles';

const HighchartsWrapper = (props) => {
  const theme = useTheme();

  const { fullScreenHandler, children, title = 'Highcharts Title' } = props;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <Typography gutterBottom variant='h5'>
          {title}
        </Typography>
        <Tooltip title='Pantalla Completa' arrow>
          <IconButton
            aria-label='FullScreen'
            onClick={fullScreenHandler}
            size='small'
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            <FullscreenIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      {children}
    </>
  );
};

export default HighchartsWrapper;
