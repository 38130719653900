import React, { useContext, useEffect, useState } from 'react';
import { Switch, Redirect, useLocation, Route } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { Context } from './context/Context';
import { RouteWithLayout } from './components';
import { FiltersContext } from './context';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  MinimalNoTopbar as MinimalNoTopbarLayout,
  MinimalBackButton as MinimalBackButtonLayout,
  MainNoFooter as MainNoFooterLayout,
  MainV2 as MainV2Layout,
  MainV3 as MainV3Layout,
} from './layouts';
import './utils/firebaseConfig';

import {
  Dashboard as DashboardView,
  // UserList as UserListView,
  // UserAdd as UserAddView,
  // UserPrivilege as UserPrivilegeView,
  // Account as AccountView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  RecoveryPassword as RecoveryPasswordView,
  // ValidateAccount as ValidateAccountView,
  NotFound as NotFoundView,
  Maintenance as MaintenanceView,
  // Parameters as ParametersView,
  Intelligence as IntelligenceView,
  Orchard as OrchardView,
  Library as LibraryView,
  Projection as ProjectionView,
  Counting as CountingView,
  Heatmap as HeatmapView,
  ByPlant as ByPlantView,
  ByQuarter as ByQuarterView,
  CountingDashboard as CountingDashboardView,
  ContractorAdd as ContractorAddView,
  ContractorList as ContractorListView,
  SupervisorAdd as SupervisorAddView,
  SupervisorList as SupervisorListView,
  HarvestAdd as HarvestAddView,
  HarvestList as HarvestListView,
  HarvestReport as HarvestReportView,
  WeatherResume as WeatherResumeView,
  WeatherDetail as WeatherDetailView,
  SamplingEdit as SamplingEditView,
  SamplingHistory as SamplingHistoryView,
  Sampling as SamplingView,
  DataAnalysis as DataAnalysisView,
  ImportAnalysis as ImportAnalysisView,
  DormancyAnalysis as DormancyAnalysisView,
  ManagementDashboard as ManagementDashboardView,
  PodaDashboard as PodaDashboardView,
  MonthlyReport as MonthlyReportView,
  FormExternal as FormExternalView,
  ListSchedule as ListScheduleView,
  FormSchedule as FormScheduleView,
  DashboardSchedule as DashboardScheduleView,
  // IndustryDashboard as IndustryDashboardView,
  DashboardExternal as DashboardExternalView,
  Params as ParamsView,
  DesktopDashboard as DesktopDashboardView,
  HomeMenu as HomeMenuView,
  YieldDashboard as YieldDashboardView,
  ByQuarter,
  Estimates as IndustryEstimatesView,
  CountingIndustry as IndustryCountingView,
  WeatherIndustry as IndustryWeatherView,
  Flowers as IndustryFlowersView,
  SpecieMenu as SpecieMenuView,
  OrchardHeatmap as OrchardHeatmapView,
  FormSchedule2 as FormSchedule2View,
  DashboardSchedule2 as DashboardSchedule2View,
  ListSchedule2 as ListSchedule2View,
  HistoricSchedule as HistoricScheduleView,
  HistoryDaySchedule as HistoryDayScheduleView,
  CheckDay as CheckDayView,
  CheckDayForm as CheckDayFormView,
  CheckBiWeekly as CheckBiWeeklyView,
  CountingTracking as CountingTrackingView,
  PowerBiDash as PowerBiDashView,
  EstimateTable as EstimateTableView,
  Irrigation as IrrigationView,
  /*Prueba Schedule*/
  // FormSchedule3 as FormSchedule3View,
  // DashboardSchedule3 as DashboardSchedule3View,
  // ListSchedule3 as ListSchedule3View,
  // HistoricSchedule3 as HistoricSchedule3View,
  // HistoryDaySchedule3 as HistoryDaySchedule3View,
  // CheckDay3 as CheckDay3View,
} from './views';
import { checkModuleAssigned, getUniqueSpeciesValues } from './helpers';
import { add } from 'lodash';

const Routes = () => {
  const {
    token,
    userId,
    userClient,
    userFullname,
    initialRoute,
    setUserSpeciesHandler,
    moduleUser,
  } = useContext(Context);

  const {
    state: { userOrchard_filter, userSpecies_filter },
    addFilter,
  } = useContext(FiltersContext);

  let siteIdHjStaging = '3215663';
  let siteIdHj = '3215659';
  let hjsv = '6';

  useEffect(() => {
    hotjar.initialize(siteIdHj, hjsv);
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.identify('USER_ID', { userProperty: userId });
    }
  }, [userId]);

  useEffect(() => {
    const userSpecies = getUniqueSpeciesValues(userOrchard_filter);

    console.log(userOrchard_filter, '----userOrchard_filter');

    // si tiene el módulo Plan. Cosecha y no tiene Carozo, lo agrega.
    if (checkModuleAssigned(moduleUser, '651eab668dd2652ad27fc094')) {
      const shouldAddCarozo = userSpecies_filter.findIndex(
        (item) => item.value === 'Carozo'
      );

      if (shouldAddCarozo < 0) {
        addFilter({
          userSpecies_filter: [
            ...userSpecies_filter,
            { label: 'Carozo', value: 'Carozo' },
          ],
        });
      }

      const shouldAddCarozoUser = userSpecies.findIndex(
        (item) => item === 'Carozo'
      );

      if (shouldAddCarozoUser < 0) {
        userSpecies.push('Carozo');
      }
    }

    setUserSpeciesHandler(userSpecies);
  }, [userOrchard_filter, moduleUser]);

  // useEffect(() => {
  // if (userId) {
  // const tagManagerArgs = {

  // const tagManagerArgs = {
  // 	gtmId: 'GTM-M2XNPBM',
  // 	dataLayer: {
  // 		userId: userId,
  // 		clientName: 'Garces',
  // 	},
  // };
  // TagManager.initialize(tagManagerArgs);
  // 	}
  // }, [userId]);

  // console.log(initialRoute, '----initialRoute');

  const initialPage = `/${initialRoute}`;

  // si el userName existe en el array de USERNAMES_ACTUAL, define una variable con el layout MainV3Layout, sino con el layout MainV2Layout

  // console.log(userFullname, '----userName en ROUTES');

  // const layoutActive = USERNAMES_ACTUAL.includes(userFullname)
  //   ? MainV3Layout
  //   : MainV2Layout;

  const layoutActive = MainV3Layout;

  return (
    <Switch>
      {!token && <Redirect from='/' to='/sign-in' exact />}
      <Redirect from='/' to={initialPage} exact />

      {token && <Redirect from='/sign-in-rol' to={initialPage} exact />}
      {token && <Redirect from='/sign-in' to={initialPage} exact />}
      {token && <Redirect from='/recovery-password' to={initialPage} exact />}

      {/* -----DESKTOP DASHBOARD----- */}
      {!token && <Redirect from='/home' to='/sign-in' exact />}

      {/* -----DESKTOP DASHBOARD----- */}
      {!token && <Redirect from='/desktop-dashboard' to='/sign-in' exact />}

      {/* -----DASHBOARD----- */}
      {!token && <Redirect from='/dashboard' to='/sign-in' exact />}
      {/* -----USER----- */}
      {!token && <Redirect from='/user-add' to='/sign-in' exact />}
      {!token && <Redirect from='/user-list' to='/sign-in' exact />}
      {!token && <Redirect from='/user-privilege' to='/sign-in' exact />}

      {/* -----CONTRACTOR----- */}
      {!token && <Redirect from='/contractor-add' to='/sign-in' exact />}
      {!token && <Redirect from='/contractor-list' to='/sign-in' exact />}

      {/* -----SUPERVISOR----- */}
      {!token && <Redirect from='/supervisor-add' to='/sign-in' exact />}
      {!token && <Redirect from='/supervisor-list' to='/sign-in' exact />}

      {/* -----HARVEST----- */}
      {!token && <Redirect from='/harvest-add' to='/sign-in' exact />}
      {!token && <Redirect from='/harvest-list' to='/sign-in' exact />}
      {!token && <Redirect from='/harvest-report' to='/sign-in' exact />}

      {/* -----ACCOUNT----- */}
      {/* {!token && <Redirect from="/account" to="/sign-in" exact />} */}
      {!token && <Redirect from='/settings' to='/sign-in' exact />}

      {/* -----PARAMETERS----- */}
      {!token && <Redirect from='/parameters-edit' to='/sign-in' exact />}

      {/* -----INTELLIGENCE----- */}
      {!token && <Redirect from='/intelligence' to='/sign-in' exact />}

      {/* -----LIBRARY----- */}
      {!token && <Redirect from='/library' to='/sign-in' exact />}

      {/* -----PROJECTION----- */}
      {!token && <Redirect from='/projection' to='/sign-in' exact />}

      {/* -----COUNTING----- */}
      {!token && <Redirect from='/counting' to='/sign-in' exact />}
      {!token && <Redirect from='/counting-heatmap' to='/sign-in' exact />}
      {!token && (
        <Redirect from='/counting-orchard-heatmap' to='/sign-in' exact />
      )}
      {!token && <Redirect from='/counting-plants' to='/sign-in' exact />}
      {!token && <Redirect from='/counting-quarters' to='/sign-in' exact />}
      {!token && <Redirect from='/counting-dashboard' to='/sign-in' exact />}
      {!token && (
        <Redirect from='/counting-orchard-heatmap' to='/sign-in' exact />
      )}

      {/* -----ORCHARD----- */}
      {!token && <Redirect from='/orchard' to='/sign-in' exact />}

      {/* -----WEATHER----- */}
      {!token && <Redirect from='/weather-resume' to='/sign-in' exact />}
      {!token && <Redirect from='/weather-detail' to='/sign-in' exact />}

      {/* -----SAMPLING----- */}
      {!token && <Redirect from='/sampling' to='/sign-in' exact />}
      {!token && <Redirect from='/sampling-edit' to='/sign-in' exact />}
      {!token && <Redirect from='/sampling-history' to='/sign-in' exact />}

      {/* -----BUD ANALYSIS----- */}
      {!token && <Redirect from='/analysis-bud' to='/sign-in' exact />}
      {!token && <Redirect from='/analysis-bud-upload' to='/sign-in' exact />}

      {/* -----DORMANCY ANALYSIS----- */}
      {!token && <Redirect from='/analysis-dormancy' to='/sign-in' exact />}
      {/* -----MANAGEMENT----- */}
      {!token && <Redirect from='/dashboard-management' to='/sign-in' exact />}
      {!token && <Redirect from='/table-management' to='/sign-in' exact />}

      {/* -----YIELD----- */}
      {!token && <Redirect from='/yield-dashboard' to='/sign-in' exact />}

      {/* -----PODA----- */}
      {!token && <Redirect from='/dashboard-yield' to='/sign-in' exact />}

      {/* -----MONTHLY REPORT----- */}
      {!token && <Redirect from='/monthly-report' to='/sign-in' exact />}
      {!token && <Redirect from='/counting-tracking' to='/sign-in' exact />}

      {/* -----SCHEDULING----- */}
      {!token && <Redirect from='/schedule-list' to='/sign-in' exact />}

      {!token && <Redirect from='/schedule-form' to='/sign-in' exact />}

      {!token && <Redirect from='/schedule-dashboard' to='/sign-in' exact />}

      {/* ----SCHEDULING DEMO----- */}
      {!token && <Redirect from='/schedule2-form' to='/sign-in' exact />}
      {!token && <Redirect from='/schedule2-dashboard' to='/sign-in' exact />}
      {!token && <Redirect from='/schedule2-list' to='/sign-in' exact />}
      {!token && <Redirect from='/schedule-history' to='/sign-in' exact />}
      {!token && <Redirect from='/schedule-historyday' to='/sign-in' exact />}
      {!token && <Redirect from='/check-day' to='/sign-in' exact />}
      {!token && <Redirect from='/check-biweekly' to='/sign-in' exact />}
      {!token && <Redirect from='/check-day-form' to='/sign-in' exact />}

      {/* -----INDUSTRY----- */}
      {!token && <Redirect from='/industry-estimates' to='/sign-in' exact />}
      {!token && <Redirect from='/industry-counting' to='/sign-in' exact />}
      {!token && <Redirect from='/industry-weather' to='/sign-in' exact />}
      {!token && <Redirect from='/industry-flowers' to='/sign-in' exact />}

      {!token && <Redirect from='/external-form' to='/sign-in' exact />}
      {!token && <Redirect from='/external-dashboard' to='/sign-in' exact />}

      {/* -----SPECIE MENU----- */}
      {!token && <Redirect from='/specie-menu' to='/sign-in' exact />}

      {/* ----SCHEDULING2----- */}
      {!token && <Redirect from='/schedule-form2' to='/sign-in' exact />}
      {!token && <Redirect from='/schedule-dashboard2' to='/sign-in' exact />}
      {!token && <Redirect from='/schedule-list2' to='/sign-in' exact />}

      {/* -----PARAMS----- */}
      {!token && <Redirect from='/params' to='/sign-in' exact />}

      {/* -----NOT FOUND----- */}
      {!token && <Redirect from='/not-found' to='/sign-in' exact />}

      {!token && <Redirect from='/dashboard-direction' to='/sign-in' exact />}

      {!token && <Redirect from='/irrigation' to='/sign-in' exact />}

      {/* HOME */}
      <RouteWithLayout
        component={HomeMenuView}
        exact
        moduleId='000000000000000000000000'
        layout={MainV3Layout}
        path='/home'
      />

      {/* Desktop */}
      <RouteWithLayout
        component={DesktopDashboardView}
        exact
        layout={MainV2Layout}
        path='/desktop-dashboard'
      />

      <RouteWithLayout
        component={IntelligenceView}
        exact
        moduleId='642333cb241198d7ea424c03'
        layout={layoutActive}
        path='/intelligence'
      />

      {/* ahora si se pasa flex a MainV2Layout usa display flex para el content */}
      <RouteWithLayout
        component={OrchardView}
        exact
        flex={false}
        moduleId='6423302fa603384a5039a60f'
        layout={layoutActive}
        path='/orchard'
      />

      <RouteWithLayout
        component={LibraryView}
        exact
        layout={layoutActive}
        moduleId='6422e4d13f15d6fddadfcb8a'
        path='/library'
      />

      <RouteWithLayout
        component={ProjectionView}
        exact
        layout={MainLayout}
        path='/projection'
      />

      <RouteWithLayout
        component={CountingView}
        exact
        layout={layoutActive}
        moduleId='64234ac2f7b53d43704e067b'
        path='/counting'
      />

      <RouteWithLayout
        component={ByPlantView}
        exact
        layout={layoutActive}
        moduleId='64234ac2f7b53d43704e067b'
        path='/counting-plants'
      />

      <RouteWithLayout
        component={ByQuarterView}
        exact
        layout={layoutActive}
        moduleId='64234ac2f7b53d43704e067b'
        path='/counting-quarters'
      />

      <RouteWithLayout
        component={HeatmapView}
        exact
        layout={layoutActive}
        moduleId='64234ac2f7b53d43704e067b'
        path='/counting-heatmap'
      />

      <RouteWithLayout
        component={OrchardHeatmapView}
        exact
        layout={layoutActive}
        moduleId='64234ac2f7b53d43704e067b'
        path='/counting-orchard-heatmap'
      />

      <RouteWithLayout
        component={CountingDashboardView}
        exact
        layout={layoutActive}
        moduleId='64234ac2f7b53d43704e067b'
        path='/counting-dashboard'
      />

      {/* harvest */}
      {/* <RouteWithLayout
        component={ContractorAddView}
        exact
        layout={MainLayout}
        path='/contractor-add'
      />

      <RouteWithLayout
        component={ContractorListView}
        exact
        layout={MainLayout}
        path='/contractor-list'
      />

      <RouteWithLayout
        component={SupervisorAddView}
        exact
        layout={MainLayout}
        path='/supervisor-add'
      />

      <RouteWithLayout
        component={SupervisorListView}
        exact
        layout={MainLayout}
        path='/supervisor-list'
      />

      <RouteWithLayout
        component={HarvestAddView}
        exact
        layout={MainLayout}
        path='/harvest-add'
      />

      <RouteWithLayout
        component={HarvestListView}
        exact
        layout={MainLayout}
        path='/harvest-list'
      />

      <RouteWithLayout
        component={HarvestReportView}
        layout={MinimalLayout}
        path='/harvest-report'
      /> */}

      {/* -----WEATHER----- */}
      <RouteWithLayout
        component={WeatherResumeView}
        exact
        moduleId='6422f05b89487f570c713901'
        layout={layoutActive}
        path='/weather-resume'
      />
      <RouteWithLayout
        component={WeatherDetailView}
        exact
        moduleId='6422f05b89487f570c713901'
        layout={layoutActive}
        path='/weather-detail'
      />

      {/* Sampling */}
      <RouteWithLayout
        component={SamplingView}
        exact
        moduleId='64234ed70ba2eaeaeafc3496'
        layout={layoutActive}
        path='/sampling'
      />

      <RouteWithLayout
        component={SamplingEditView}
        exact
        layout={layoutActive}
        moduleId='64234ed70ba2eaeaeafc3496'
        path='/sampling-edit'
      />

      <RouteWithLayout
        component={SamplingHistoryView}
        exact
        layout={layoutActive}
        path='/sampling-history'
      />

      {/* -----DATA----- */}
      <RouteWithLayout
        component={DataAnalysisView}
        exact
        moduleId='6478c8a3b722d8c83ac83b65'
        layout={layoutActive}
        path='/analysis-bud'
      />

      <RouteWithLayout
        component={ImportAnalysisView}
        exact
        moduleId='6478c8a3b722d8c83ac83b65'
        layout={layoutActive}
        path='/analysis-bud-upload'
      />

      {/* -----DORMANCY----- */}
      <RouteWithLayout
        component={DormancyAnalysisView}
        exact
        layout={layoutActive}
        moduleId='6478c8a3b722d8c83ac83b65'
        path='/analysis-dormancy'
      />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={layoutActive}
        path='/dashboard'
      />

      {/* -----MANAGEMENT----- */}
      <RouteWithLayout
        component={ManagementDashboardView}
        exact
        moduleId='64e62ced5c228dec08aaf0e9'
        layout={layoutActive}
        path='/dashboard-management'
      />
      <RouteWithLayout
        component={EstimateTableView}
        exact
        moduleId='64e62ced5c228dec08aaf0e9'
        layout={layoutActive}
        path='/table-management'
      />

      {/* -----Rendmiento Dash----- */}
      <RouteWithLayout
        component={YieldDashboardView}
        exact
        moduleId='64ef4f50e252668d3c000048'
        layout={layoutActive}
        path='/yield-dashboard'
      />

      {/* -----PODA DASH----- */}
      {/* <RouteWithLayout
        component={PodaDashboardView}
        exact
        layout={MainLayout}
        path='/dashboard-yield'
      /> */}

      {/* -----MONTHLY REPORT----- */}
      <RouteWithLayout
        component={MonthlyReportView}
        exact
        moduleId='64f87e1ada19593a715a6292'
        layout={layoutActive}
        path='/monthly-report'
      />

      <RouteWithLayout
        component={CountingTrackingView}
        exact
        moduleId='64f87e1ada19593a715a6292'
        layout={layoutActive}
        path='/counting-tracking'
      />

      {/* -----SCHEDULING----- */}
      {/* <RouteWithLayout
        component={ListScheduleView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule-list'
      />

      <RouteWithLayout
        component={FormScheduleView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule-form'
      />

      <RouteWithLayout
        component={DashboardScheduleView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule-dashboard'
      /> */}

      {/* -----SCHEDULING DEMO----- */}
      <RouteWithLayout
        component={FormSchedule2View}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule-form'
      />
      <RouteWithLayout
        component={DashboardSchedule2View}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule2-dashboard'
      />
      <RouteWithLayout
        component={ListSchedule2View}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule2-list'
      />
      <RouteWithLayout
        component={HistoricScheduleView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule-history'
      />
      <RouteWithLayout
        component={HistoryDayScheduleView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/schedule-historyday'
      />
      <RouteWithLayout
        component={CheckDayView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/check-day'
      />
      <RouteWithLayout
        component={CheckBiWeeklyView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/check-biweekly'
      />
      <RouteWithLayout
        component={CheckDayFormView}
        exact
        layout={layoutActive}
        moduleId='651eab668dd2652ad27fc094'
        path='/check-day-form'
      />

      {/* -----SCHEDULING PRUEBA----- */}
      {/* <RouteWithLayout
        component={FormSchedule3View}
        exact
        layout={layoutActive}
        moduleId='6651eab668dd252ad28fc088'
        path='/schedule3-form'
      />
      <RouteWithLayout
        component={DashboardSchedule3View}
        exact
        layout={layoutActive}
        moduleId='6651eab668dd252ad28fc088'
        path='/schedule3-dashboard'
      />
      <RouteWithLayout
        component={ListSchedule3View}
        exact
        layout={layoutActive}
        moduleId='6651eab668dd252ad28fc088'
        path='/schedule3-list'
      />
      <RouteWithLayout
        component={HistoricSchedule3View}
        exact
        layout={layoutActive}
        moduleId='6651eab668dd252ad28fc088'
        path='/schedule3-history'
      />
      <RouteWithLayout
        component={HistoryDaySchedule3View}
        exact
        layout={layoutActive}
        moduleId='6651eab668dd252ad28fc088'
        path='/schedule3-historyday'
      />
      <RouteWithLayout
        component={CheckDay3View}
        exact
        layout={layoutActive}
        moduleId='6651eab668dd252ad28fc088'
        path='/check-day3'
      /> */}

      {/* -----INDUSTRY----- */}
      {/* <RouteWithLayout
        component={IndustryDashboardView}
        exact
        layout={MainLayout}
        path='/industry'
      /> */}
      {/* -----USER----- */}
      {/* <RouteWithLayout
				component={UserAddView}
				exact
				layout={MainLayout}
				path="/user-add"
			/>
			<RouteWithLayout
				component={UserListView}
				exact
				layout={MainLayout}
				path="/user-list"
			/>

			<RouteWithLayout
				component={UserPrivilegeView}
				exact
				layout={MainLayout}
				path="/user-privilege"
			/> */}

      {/* <RouteWithLayout
				component={AccountView}
				exact
				layout={MainLayout}
				path="/account"
			/> */}

      {/* <RouteWithLayout
				component={ParametersView}
				exact
				layout={MainLayout}
				path="/parameters-edit"
			/> */}

      {/* <RouteWithLayout
				component={SignUpView}
				exact
				layout={MinimalLayout}
				path="/sign-up"
			/> */}

      {/* -----EXTERNAL----- */}
      <RouteWithLayout
        component={DashboardExternalView}
        exact
        layout={layoutActive}
        path='/external-dashboard'
      />

      <RouteWithLayout
        component={FormExternalView}
        exact
        layout={layoutActive}
        path='/external-form'
      />

      {/* -----POWER BI------ */}
      <RouteWithLayout
        component={IndustryEstimatesView}
        exact
        layout={layoutActive}
        moduleId='668407849f5946eca6727733'
        path='/industry-estimates'
      />

      <RouteWithLayout
        component={IndustryCountingView}
        exact
        layout={layoutActive}
        moduleId='668407849f5946eca6727733'
        path='/industry-counting'
      />

      <RouteWithLayout
        component={IndustryWeatherView}
        exact
        layout={layoutActive}
        moduleId='668407849f5946eca6727733'
        path='/industry-weather'
      />

      <RouteWithLayout
        component={IndustryFlowersView}
        exact
        layout={layoutActive}
        moduleId='668407849f5946eca6727733'
        path='/industry-flowers'
      />

      <RouteWithLayout
        component={PowerBiDashView}
        exact
        layout={layoutActive}
        moduleId='66d0ca968244d5872f356a03'
        path='/dashboard-direction'
      />

      <RouteWithLayout
        component={IrrigationView}
        exact
        layout={layoutActive}
        moduleId='67475b98ae573778c932b250'
        path='/irrigation'
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalNoTopbarLayout}
        path='/sign-in'
      />

      <RouteWithLayout
        component={RecoveryPasswordView}
        exact
        layout={MinimalNoTopbarLayout}
        path='/recovery-password'
      />

      {/*
			<RouteWithLayout
				component={ValidateAccountView}
				exact
				layout={MinimalLayout}
				path="/validate-account"
			/> */}

      <RouteWithLayout
        component={ParamsView}
        exact
        layout={layoutActive}
        path='/params'
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={layoutActive}
        path='/not-found'
      />

      <RouteWithLayout
        component={MaintenanceView}
        exact
        layout={MinimalNoTopbarLayout}
        path='/maintenance'
      />
      <RouteWithLayout
        component={SpecieMenuView}
        exact
        layout={MinimalBackButtonLayout}
        path='/specie-menu'
      />

      <Redirect to='/not-found' />
    </Switch>
  );
};

export default Routes;
