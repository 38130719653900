export const tabItems = [
  {
    id: '6422e8ed3b513fe22b595a8b',
    name: 'libraryYieldCalculator',
    namePrint: 'Calculadora, Estimaciones de Rendimiento',
    code: 'library-process-001',
    order: 1,
    idModule: '6422e4d13f15d6fddadfcb8a',
  },
  {
    id: '6422eca9377e963bcdd6e912',
    name: 'LibraryBreakBudDormancy',
    namePrint: 'Rompedores de Dormancia',
    code: 'library-process-002',
    order: 2,
    idModule: '6422e4d13f15d6fddadfcb8a',
  },
  {
    id: '6422ed7482849894ed2c8def',
    name: 'LibraryLeafAnalysis',
    namePrint: 'Análisis de Foliar',
    code: 'library-process-003',
    order: 3,
    idModule: '6422e4d13f15d6fddadfcb8a',
  },
  {
    id: '6422eda19ca83f366389ab73',
    name: 'RootReservoir',
    namePrint: 'Análisis de Reservas',
    code: 'library-process-004',
    order: 4,
    idModule: '6422e4d13f15d6fddadfcb8a',
  },
  {
    id: '6422ef7ae3a16c1bd268c803',
    name: 'LibrarySoilAnalysis',
    namePrint: 'Análisis de Suelos',
    code: 'library-process-005',
    order: 5,
    idModule: '6422e4d13f15d6fddadfcb8a',
  },
  {
    id: '6422efebd917b88429c98a2e',
    name: 'LibraryWaterAnalysis',
    namePrint: 'Análisis de Aguas',
    code: 'library-process-006',
    order: 6,
    idModule: '6422e4d13f15d6fddadfcb8a',
  },
];
