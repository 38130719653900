import React, { useState } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar';
import Context from './context/Context';
import { AxiosProvider } from './context/AxiosContext';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import SnackBarsComponent from './components/SnackBarsComponent';
import {
  FiltersProvider,
  IntelligenceProvider,
  OptionsProvider,
} from 'context';
// import ErrorView from 'ErrorView';

const browserHistory = createBrowserHistory();

// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
// 	draw: chartjs.draw,
// });

const App = () => {
  const [errorState, setErrorState] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState('');

  const handleCloseSnackBarsComponent = () => {
    setErrorState(false);
    setErrorMessageState('');
  };

  return (
    <Context.Provider>
      <AxiosProvider>
        <FiltersProvider>
          <OptionsProvider>
            <IntelligenceProvider>
              <BrowserRouter>
                <React.Fragment>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <Router history={browserHistory}>
                        <Routes />

                        {errorState && (
                          <Snackbar
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            open={errorState}
                            onClose={() => setErrorState(false)}
                            autoHideDuration={4500}
                            key={Math.random()}
                          >
                            <SnackBarsComponent
                              variant='error'
                              message={errorMessageState}
                              handleClose={handleCloseSnackBarsComponent}
                            />
                          </Snackbar>
                        )}
                      </Router>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </React.Fragment>
              </BrowserRouter>
            </IntelligenceProvider>
          </OptionsProvider>
        </FiltersProvider>
      </AxiosProvider>
    </Context.Provider>
  );
};
export default App;
