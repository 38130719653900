export const optionsReducer = (state, action) => {
  switch (action.type) {
    case 'set_user_orchard':
      return {
        ...state,
        isLoading: false,
        withDataOrchards: true,
        userOrchard_filter: action.payload,
      };

    case 'add_options':
      let newObj = {};

      Object.entries(action.payload).forEach(([key, value]) => {
        return (newObj = {
          ...newObj,
          [key]: value,
        });
      });

      // console.log('----->', state, '---', newObj);

      return {
        ...state,
        ...newObj,
      };

    case 'clear_options':
      return action.payload;

    default:
      return state;
  }
};
