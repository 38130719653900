import { useContext } from 'react';
import clsx from 'clsx';
import { Avatar, Box, Typography } from '@mui/material';
import { Context } from '../../../../../../context/Context';

const Profile = (props) => {
  const { className, open, ...rest } = props;
  const context = useContext(Context);
  // console.log(context, '<<<<<<');
  const user = {
    name: context.userName,
    bio: context.userProfile,
  };

  return (
    <Box
      sx={{
        display: open ? 'flex' : 'none',
        py: 1,
        mb: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
      className={clsx(className)}
    >
      <Avatar
        alt='Remy Sharp'
        src='/static/images/avatar/1.jpg'
        sx={{ mb: 1 }}
      />
      <Typography color='textPrimary' variant='h5'>
        {user.name}
      </Typography>
      <Typography color='textSecondary' variant='body2'>
        Bienvenido/a
      </Typography>
    </Box>
  );
};

export default Profile;
