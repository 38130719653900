import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
// import classes from './TextFieldWidgetSelectController.module.scss';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 		margin: theme.spacing(1),
// 		minWidth: 210,
// 		[theme.breakpoints.down('sm')]: {
// 			minWidth: '95%',
// 		},
// 	},
// 	rootLabel: {
// 		fontSize: 18,
// 	},
// 	selectEmpty: {
// 		marginTop: theme.spacing(4),
// 	},
// }));

const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 210,
      [theme.breakpoints.down('sm')]: {
        minWidth: '95%',
      },
    },
    rootLabel: {
      fontSize: 18,
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  };
});

const TextFieldWidgetSelectController = (props) => {
  const {
    errors,
    options,
    req,
    loading,
    name,
    control,
    disabled,
    withDefault,
    defaultValue,
    variant = 'standard',
    multiple = false,
  } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  // const classes = useStyles();
  const { classes } = useStyles();

  // console.log(props, '<-----');

  // const [selectedValueState, setSelectedValueState] = useState('');

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[name].required
              ? errorMessage.message[name].required
              : errorMessage.message[name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  // useEffect(() => {
  // 	setSelectedValueState(selected);
  // 	// console.log("el selected en el select", selectedValueState,"el selected que llega", selected);
  // }, [selected]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  const renderOptionTitle = (option) => {
    switch (props.titleOption) {
      case 'rut-fullname':
        return `${option.rut} - ${option.fullname}`;
      case 'code-name':
        return `${option.code} - ${option.name}`;
      case 'label':
        return `${option.label}`;
      default:
        return `${option.name}`;
    }
  };

  // console.log(selectedValueState, "ultimo valor selected", options);

  //   validaciones
  return (
    <FormControl className={classes.formControl} error={stateError}>
      {/* <div className={classes.inputContainerStyle}> */}
      {/* <div className={classes.labelDesktopStyle}>{props.labeltext}</div> */}
      <InputLabel
        shrink={withDefault}
        id={labelText}
        className={classes.rootLabel}
      >
        {props.labeltext} {loading && '...cargando'}
      </InputLabel>
      {/* <div style={{ width: '100%' }}> */}
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            labelId={labelText}
            id={labelText}
            // label={labelText}
            // value={selectedValueState}
            // native
            {...field}
            multiple={multiple}
            fullWidth
            displayEmpty={withDefault}
            className={withDefault ? classes.selectEmpty : ''}
            disabled={disabled}
            // margin="dense"
            variant={variant}

            // onChange={e => onChangeFunc(e.target.value)}>
          >
            {withDefault ? (
              <MenuItem value=''>
                <em>Seleccione {props.labeltext}</em>
              </MenuItem>
            ) : null}
            {options &&
              options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {renderOptionTitle(option)}
                </MenuItem>
              ))}
          </Select>
        )}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: req,
        }}
      />
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
      {/* </div> */}
      {/* </div> */}
    </FormControl>
  );
};

TextFieldWidgetSelectController.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  loading: PropTypes.bool,
};

export default TextFieldWidgetSelectController;
