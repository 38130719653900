import React from 'react';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classes from './NotFound.module.scss';

const NotFound = () => {
	// const preventDefault = (event) => event.preventDefault();
	return (
		<div className={classes.root}>
			<BsContainer fluid>
				<BsRow class="justify-content-md-center">
					<BsCol class="col-lg-6 mt-4">
						<div className={classes.content}>
							<Typography variant="h1" gutterBottom>
								La página no existe.
							</Typography>
							<Typography variant="subtitle1">
								Puedes probar volver hacia atrás o{' '}
								<Link
									color="secondary"
									component={RouterLink}
									to="/"
								>
									click aquí
								</Link>{' '}
								para ir al home.
							</Typography>
							<img
								alt="Under development"
								className={classes.image}
								src="/images/undraw_page_not_found_su7k.svg"
							/>
						</div>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default NotFound;
