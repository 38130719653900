import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Box,
  TextField,
  Typography,
  useTheme,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  AlertTitle,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import { useErrorHandler } from 'react-error-boundary';
import {
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom/cjs/react-router-dom';
import { styled } from '@mui/styles';
import NumberFormat from 'react-number-format';
import {
  ButtonAction,
  CheckboxWidgetUncontrolled,
  // DataTableWidget,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  SimpleIconButton,
  TextFieldWidgetText2,
  TextFieldWidgetTextTypeFile,
  // TextFieldWidgetDateController,
} from '../../../components';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';

import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  getOrchardVarietiesDeep,
  numberFormatDecimals,
  alterArrayGetIds,
  printOrder,
  dashDelete,
  alterArrayGetFields,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      color: theme.palette.tertiary.main,
      fontWeight: 500,
    },
    divider: {
      background: theme.palette.grey[400],
      marginTop: 10,
    },
    tableStyles: {
      marginTop: 5,
      '& td': {
        padding: '0.45rem',
        fontSize: 14,
      },
    },
    infoCol: {
      fontWeight: 500,
    },
    theadLight: {
      '& th': {
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[300],
      },
      '& td': {
        verticalAlign: 'middle !important',
      },
    },
    colorBox: {
      width: '80px',
      height: '35px',
    },
    tbodyStyle: {
      '& td': {
        verticalAlign: 'middle',
      },
      '& tr.selected': {
        backgroundColor: '#f7faff',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrowsContainerStyle: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    subtitleDash: {
      fontSize: 18,
    },
    paperContainerStyle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // backgroundColor: theme.palette.grey[100],
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      '& h6': {
        color: theme.palette.primary.contrastText,
      },
      '& h2': {
        color: theme.palette.primary.contrastText,
      },
    },
    bgCellStyle: {
      backgroundColor: theme.palette.primary.main + '!important',
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      fontWeight: 700,
    },
    fullWidthStyle: {
      width: '100%',
    },
    backbuttonStyle: {
      position: 'absolute',
      top: 20,
      right: 10,
    },
    dialogTitleStyle: {
      fontWeight: '500',
      fontSize: 18,
    },
    dialogTextStyle: {
      fontWeight: '500',
      color: theme.palette.text.primary,
    },
    popover: {
      pointerEvents: 'none',
    },
    popOverContainer: {
      padding: theme.spacing(1),
      '& p': {
        marginBottom: theme.spacing(1),
        color: theme.palette.grey[700],
      },
    },
    rootHelper: {
      fontSize: 13,
      lineHeight: 1.3,
    },
    inputText: {
      fontSize: 26,
      '&::placeholder': {
        fontSize: '17px',
      },
    },
  };
});

const endpointUpload = '....';

const moduleOrchardFilter = 'statusAnalysis';

const ImportAnalysis = (props) => {
  const { userId, userHuerto, userName } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    season_filter,
    orchard_id_filter,
    variety_filter,
    rootstock_filter,
    userOrchard_filter,
  } = filterState;

  const [successUploadState, setSuccessUploadState] = useState(false);
  const [loadingValidateState, setLoadingValidateState] = useState(false);
  const [validationDataState, setValidationDataState] = useState(true);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const { grid, zoneColors } = useLocation();

  const errorHandler = useErrorHandler();

  const history = useHistory();

  // console.log(grid, '----grid');

  const handleGoBack = () => {
    history.goBack();
  };

  // pop over info
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopoverState = Boolean(anchorEl);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmitFilter,
    control: controlFilter,
    setValue: setValueFilter,
    formState: { errors: errorsFilter },
    reset: resetFilter,
  } = useForm();

  // console.log(grid, '----data que llega');

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [excelLoadingState, setExcelLoadingState] = useState(false);
  const [excelSuccessDownloadState, setExcelSuccessDownloadState] =
    useState(false);

  const [orchardFilterState, setOrchardFilterState] = useState(null);
  const [openPromptState, setOpenPromptState] = useState(false);
  const [openPromptCheckState, setOpenPromptCheckState] = useState(false);
  const [plantToDeleteState, setPlantToDeleteState] = useState(null);
  const [loadingDeleteState, setLoadingDeleteState] = useState(false);
  const [deleteSuccessState, setDeleteSuccessState] = useState(false);

  const theme = useTheme();

  // console.log(theme, '----zoneColors');
  const { classes } = useStyles();

  const onSubmit = async (values) => {
    console.log(values, '----datos del form');
    setLoadingValidateState(true);
    // console.log('graba', arrayIdsState, plantsSelectedGridState);

    // verificar que los values no exista en plantsSelectedGridState con findIndex

    const formData = new FormData();
    formData.append('file', values.analysisFile);

    const objQuery = {
      orchardValue: orchard_filter,
      ccValue: cc_filter,
      quarterValue: quarter_filter,
      varietyValue: variety_filter,
      rootStockValue: rootstock_filter,
    };

    // console.log(objQuery);

    try {
      // const response = await axiosContext.authAxios.post(
      //   endpointUpload,
      //   objQuery
      // );
      // // console.log(response, '------response');
      // const { status, data } = response;
      // if (status === 200) {
      //   // console.log(dataEdit, '----dataEdit');
      //   setSuccessCreateState(true);
      //   setValue('rowNumber', '');
      //   setValue('plantNumber', '');
      // }
    } catch (error) {
      // console.log(error, '---error post');
      errorHandler(error);
    } finally {
      setLoadingValidateState(false);
    }
  };

  // carga en selected los de la grilla actual

  const handleClosePrompt = () => {
    setOpenPromptState(false);
  };

  const handleClosePromptCheck = () => {
    setOpenPromptCheckState(false);
  };

  const onCloseSnack = () => {
    console.log('------close');
    setDeleteSuccessState(false);
    handleClosePrompt();
    handleClosePromptCheck();
    setPlantToDeleteState(null);
  };

  const onCloseSnackCreate = () => {
    // console.log('------close');
    setSuccessUploadState(false);
  };

  const downloadFile = (url) => {
    const urlDownload = url;
    const fileName = 'plantilla-analisis.xlsx';

    // Create a temporary anchor element
    const link = document.createElement('a');

    // Set the download attribute with the desired file name
    link.download = fileName;

    // Set the href attribute to the file URL
    link.href = urlDownload;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    setExcelSuccessDownloadState(true);
  };

  const downloadHandler = async () => {
    // console.log('download');
    window.open(
      'https://s3.amazonaws.com/analitica/Plantilla+de+Analitica.xlsx',
      '_blank'
    );

    setExcelSuccessDownloadState(false);
    setExcelLoadingState(true);
    // const objQuery = {
    //   clientValue: userClient.value
    // };

    if (orchardFilterState) {
      try {
        const response = await axiosContext.authAxios.post(
          'api-task/download-external-garces',
          orchardFilterState
        );

        // console.log(response.data, '<<<<<------------');

        const { data, status } = response;

        if (status === 200) {
          // console.log(data, '<<<<<------------');

          const urlDownload = data?.menssage?.result?.sucess?.url || null;

          if (urlDownload) {
            // console.log(urlDownload, '<<<<<------------', window);
            downloadFile(urlDownload);
          } else {
            const errorDownload = data?.menssage?.result?.error || null;
            if (errorDownload) {
              alert(errorDownload);
            }
          }
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setExcelLoadingState(false);
      }
    }
  };

  const resetForm = () => {
    setValue('analysisFile', '');
  };

  const handleChangeFile = (_event) => {
    if (_event.target) {
      setValue(`analysisFile.file`, _event.target.files[0]);
    }
  };

  const onSubmitFilter = async (values) => {
    console.log(values, '<--------', userOrchard_filter);
    // arrayGetStationIds(values.orchard, userOrchard_filter);
    // let data = {
    //   huerto: values.orchard
    //     ? alterArrayGetFields(values.orchard, ['value'])
    //     : '',
    // };

    const data = {
      orchardValue: values.orchard?.value,
    };

    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard?.value
    );

    console.log(resultOrchard, '----');

    if (orchard_id_filter !== resultOrchard[0].id) {
      addFilter({
        cc_filter: '',
        quarter_filter: '',
      });
    }
    addFilter({
      orchard_filter: values.orchard?.value,
      orchard_id_filter: resultOrchard[0].id,
    });

    setOrchardFilterState(data);
  };

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmitFilter(onSubmitFilter)}
        >
          <>
            <div className='input-group mb-2'>
              <SelectMultipleWidget2
                errors={errorsFilter.orchard}
                loading={false}
                control={controlFilter}
                menuPortalTarget={document.body}
                labeltext=''
                req={true}
                name='orchard'
                display='vertical'
                variant='outlined'
                isMulti={false}
                isClearable={true}
                isSearchable
                onlyOptionValue={false}
                defaultValue=''
                options={filterDataResponseState}
                placeholder='Huerto'
              />
            </div>
          </>
          <SaveAction
            title='Filtrar'
            color='secondary'
            size='large'
            className='roundedButton ml-md-2'
            variant='contained'
            disabled={false}
            messageType='add'
            mutationLoading={false}
            success={false}
          />
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters mb-md-2'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-6'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Importar Análisis
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                  <BsCol class='col-md-6 text-right'>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => handleGoBack()}
                    >
                      Volver
                    </Button>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>

        <BsRow class='justify-content-md-center'>
          <BsCol class='col-md-8 mt-3'>
            <Card elevation={0}>
              <CardContent>
                <BsRow class='mt-2'>
                  <BsCol class='col-md-12'>
                    <Typography variant='body1'>
                      Descarga la plantilla de Analytics para llenarla con la
                      información de tus unidades productivas y luego selecciona
                      el archivo para hacer la carga.
                    </Typography>
                    <div className='text-center mt-3'>
                      <SimpleIconButton
                        labelButton='Descargar Plantilla'
                        color='primary'
                        size='large'
                        icon={<DownloadIcon />}
                        disabled={excelLoadingState}
                        actionOnClick={downloadHandler}
                      />
                    </div>
                    {excelSuccessDownloadState ? (
                      <Alert
                        onClose={() => setExcelSuccessDownloadState(false)}
                        className='mb-2'
                        variant='filled'
                      >
                        Descargado con éxito.
                      </Alert>
                    ) : null}
                  </BsCol>
                  <BsCol class='col-md-12 text-center py-4'>
                    <form
                      autoComplete='false'
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <BsRow class=' align-items-center'>
                        <BsCol class='col-md-12 mb-3 text-left'>
                          <Typography variant='h5'>
                            Cargar Excel
                            <span className='mx-3'>
                              <IconButton
                                aria-describedby='dd'
                                variant='text'
                                color='primary'
                                size='small'
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                              >
                                <InfoIcon />
                              </IconButton>
                            </span>
                          </Typography>

                          <Popover
                            id='simple-popover'
                            open={openPopoverState}
                            className={classes.popover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div className={classes.popOverContainer}>
                              <Typography variant='caption' component={'p'}>
                                Debe subir la plantilla descargada con los datos
                                completados. Los datos que vengan en el Excel
                                serán registrados en el sistema.
                              </Typography>
                            </div>
                          </Popover>
                        </BsCol>
                        <BsCol class='col-md-5'>
                          <TextFieldWidgetTextTypeFile
                            control={control}
                            name='analysisFile'
                            multiple={false}
                            req={true}
                            onChangeFile={handleChangeFile}
                            labeltext='Subir Archivo'
                            errors={errors.analysisFile}
                          />
                        </BsCol>
                        <BsCol class='col-md-3 px-md-1'>
                          <Button
                            variant='outlined'
                            color='secondary'
                            sx={{
                              borderColor: theme.palette.grey[300],
                              color: theme.palette.grey[300],
                              p: 1,
                            }}
                            fullWidth={true}
                            onClick={() => resetForm('')}
                          >
                            Cancelar
                          </Button>
                        </BsCol>
                        <BsCol class='col-md-3 pl-md-1'>
                          <SaveAction
                            title='Validar Datos'
                            color='secondary'
                            size='large'
                            fullWidth={true}
                            actionClose={onCloseSnackCreate}
                            disabled={loadingValidateState}
                            // className='btn-block btn-Intelligence-calc'
                            variant='contained'
                            messageType='add'
                            mutationLoading={loadingValidateState}
                            success={false}
                          />
                        </BsCol>
                      </BsRow>
                    </form>
                  </BsCol>
                  <BsCol class='col-md-12 mt-2'>
                    {loadingValidateState ? <LoadingWidget /> : null}
                    {validationDataState ? (
                      <Alert
                        severity='warning'
                        color='warning'
                        variant='outlined'
                        sx={{
                          backgroundColor: theme.palette.warning.light,
                          borderColor: theme.palette.warning.main,
                        }}
                      >
                        <AlertTitle>Resumen de Carga</AlertTitle>
                        Las unidades que está modificando tienen valores fuera
                        de rango y unidades con valores vacíos. Se recomienda
                        verificar la información antes de cargarla.
                      </Alert>
                    ) : null}
                  </BsCol>
                  <BsCol class='col-md-12 mt-5'>
                    <BsRow class='justify-content-md-center align-items-center'>
                      <BsCol class='col-md-4'>
                        <Button
                          variant='outlined'
                          color='secondary'
                          sx={{
                            borderColor: theme.palette.grey[300],
                            color: theme.palette.grey[300],
                            p: 1,
                          }}
                          fullWidth={true}
                          onClick={() => resetForm('')}
                        >
                          Cancelar Carga
                        </Button>
                      </BsCol>
                      <BsCol class='col-md-4'>
                        <ButtonAction
                          labelButton='Confirmar Carga de Datos'
                          color='primary'
                          size='large'
                          fullWidth={true}
                          actionClose={onCloseSnackCreate}
                          actionOnClick={() => {}}
                          disabled={loadingValidateState}
                          messageType='add'
                          mutationLoading={loadingValidateState}
                          success={successUploadState}
                        />
                      </BsCol>
                    </BsRow>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
      {plantToDeleteState ? (
        <Dialog
          open={openPromptState}
          onClose={handleClosePrompt}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            className={clsx('delete-prompt', classes.dialogTitleStyle)}
          >
            Eliminar Planta
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              className={classes.dialogTextStyle}
            >
              Seguro desea eliminar la planta: Hilera:{' '}
              {plantToDeleteState.rowNumber} | Nº Planta:{' '}
              {plantToDeleteState.plantNumber}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleClosePrompt}
              disabled={loadingDeleteState || deleteSuccessState}
            >
              Cancelar
            </Button>
            {/* <Button
              color='error'
              onClick={handleDeletePlant}
              autoFocus
              disabled={loadingDeleteState}
            >
              Eliminar
            </Button> */}
            <ButtonAction
              labelButton='Eliminar'
              color='error'
              // size='large'
              actionClose={onCloseSnack}
              variant='text'
              fullWidth={true}
              actionOnClick={() => {}}
              disabled={loadingDeleteState || deleteSuccessState}
              messageType='edit'
              mutationLoading={loadingDeleteState}
              success={deleteSuccessState}
            />
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};
export default ImportAnalysis;
