import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import classes from './TextFieldWidgetTextBig.module.scss';
import { validateRut } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';

const TextFieldWidgetTextBig = (props) => {
  const {
    errors,
    req,
    control,
    defaultValue,
    name,
    variant = 'standard',
    size = 'medium',
    ...others
  } = props;

  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  // console.log(errors, '<------');

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[name].required
              ? errorMessage.message[name].required
              : errorMessage.message[name]
          );
          break;
        case 'pattern':
          setStateMessage(errorMessage.message[name].pattern);
          break;
        case 'maxLength':
          setStateMessage(errorMessage.message[name].maxLength);
          break;
        case 'minLength':
          setStateMessage(errorMessage.message[name].minLength);
          break;
        case 'menorQue':
          setStateMessage(errorMessage.message[name].menorQue);
          break;
        case 'mayorQue':
          setStateMessage(errorMessage.message[name].mayorQue);
          break;
        case 'rutValido':
          setStateMessage(errorMessage.message[name].rutValido);
          break;
        case 'matchesPreviousPassword':
          setStateMessage(errorMessage.message[name].matchesPreviousPassword);
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  //   validaciones
  let pattern = null;
  let validate = null;
  if (props.name === 'email') {
    pattern =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  }

  if (props.name === 'rut') {
    validate = {
      rutValido: (value) => validateRut(value),
    };
  }

  if (props.name === 'password2') {
    validate = {
      matchesPreviousPassword: (value) => props.equalto === value,
    };
  }

  return (
    <div className={classes.inputContainerStyle}>
      <Controller
        control={control}
        render={({ field }) => (
          <TextField
            variant={variant}
            size={size}
            {...others}
            {...field}
            fullWidth
            classes={{
              root: classes.inputBorderContainer,
            }}
            error={stateError}
            helperText={stateMessage}
          />
        )}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: req,
          maxLength: props.maxLength,
          minLength: props.minLength,
          pattern: pattern,
          validate: validate,
        }}
      />
    </div>
  );
};

TextFieldWidgetTextBig.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  label: PropTypes.string,
};

export default TextFieldWidgetTextBig;
