import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
// import { makeStyles } from 'tss-react/mui';
import { Controller } from 'react-hook-form';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select, { components } from 'react-select';
import classes from './SelectMultipleWidget2.module.scss';
// import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../common/errorMessages.json';
// import theme from 'theme';

import theme from '../../theme';
import { IsDesktopHandler } from '../../helpers';
import clsx from 'clsx';

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 		margin: theme.spacing(1),
// 		minWidth: 210,
// 		maxWidth: 230,
// 		[theme.breakpoints.down('sm')]: {
// 			minWidth: '95%',
// 		},
// 	},
// 	rootLabel: {
// 		fontSize: 18,
// 	},
// }));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 180,
    width: '100%',
    maxWidth: '100%',
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '95%',
    // },
  },
  errorStateStyle: {
    '& .react-select__control': {
      borderColor: theme.palette.error.main,
    },
  },
  rootLabel: {
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: 'white',
  },
  inputContainerStyleHorizontal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputContainerStyleVertical: (props) => ({
    minHeight: props.minheight ? 65 : 'auto',
    ...props.containerStyle,
    '& label': {
      fontSize: '18px',
      paddingLeft: 10,
      paddingRight: 10,
      color: '#4C5662 !important',
    },
    '& fieldset': {
      '& legend': {
        fontSize: '15px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  }),
}));

// console.log(theme, '<-----------theme');

const customVerticalStyles = {
  container: () => ({
    width: '100%',
  }),
  control: (styles) => ({
    // none of react-select's styles are passed to <Control />
    ...styles,
    paddingTop: 6,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.primary.light,
    minHeight: 34,
    backgroundColor: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.react-select__control--is-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    },
  }),
  ValueContainer: (styles, { data }) => ({
    ...styles,
    // backgroundColor: 'tomato',
    paddingTop: 0,
  }),
  // menu: () => ({
  // 	width: '100%',
  // 	backgroundColor: '#f8f8f8',
  // 	maxHeight: '300px',
  // 	overflowY: 'scroll',
  // }),
  // menuList: () => ({
  // 	display: 'flex',
  // 	flexWrap: 'wrap',
  // 	width: '100%',
  // 	backgroundColor: 'white',
  // }),
  placeholder: (styles) => ({
    ...styles,
    ...theme.typography.body2,
    fontWeight: 600,
  }),
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '.4px solid rgba(223,223,223,.5)',
    // borderRight: '.4px solid rgba(223,223,223,.5)',
    // width: '200px',
    backgroundColor: state.isSelected ? theme.palette.primary.main : 'white',
    fontSize: theme.typography.fontSize,
    color: state.isSelected ? theme.palette.white : theme.palette.text.primary,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: 600,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: '#cccccc',
    borderRadius: '4px',
    paddingLeft: '4px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    paddingVertical: '5px',
    paddingRight: '8px',
    fontSize: 13,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    // backgroundColor: 'blue',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    ':hover': {
      backgroundColor: '#cccccc',
      color: 'white',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const customHorizontalStyles = {
  container: () => ({
    width: '100%',
  }),
  control: (styles) => ({
    // none of react-select's styles are passed to <Control />
    ...styles,
    paddingTop: 6,
    borderRadius: '8px',
    // border: 'none',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',

    minHeight: 34,
    backgroundColor: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.react-select__control--is-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    },
  }),
  ValueContainer: (styles, { data }) => ({
    ...styles,
    // backgroundColor: 'tomato',
    paddingTop: 10,
  }),
  // menu: () => ({
  // 	width: '100%',
  // 	backgroundColor: '#f8f8f8',
  // 	maxHeight: '300px',
  // 	overflowY: 'scroll',
  // }),
  // menuList: () => ({
  // 	display: 'flex',
  // 	flexWrap: 'wrap',
  // 	width: '100%',
  // 	backgroundColor: 'white',
  // }),
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '.4px solid rgba(223,223,223,.5)',
    // borderRight: '.4px solid rgba(223,223,223,.5)',
    // width: '200px',
    backgroundColor: state.isSelected ? theme.palette.primary.main : 'white',
    fontSize: theme.typography.fontSize,
    color: state.isSelected ? theme.palette.white : theme.palette.text.primary,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: '#cccccc',
    borderRadius: '4px',
    paddingLeft: '4px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    paddingVertical: '5px',
    paddingRight: '8px',
    fontSize: 13,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    // backgroundColor: 'blue',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    ':hover': {
      backgroundColor: '#cccccc',
      color: 'white',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const SelectMultipleWidget2 = (props) => {
  const {
    errors,
    req,
    loading,
    control,
    name,
    options,
    defaultValue,
    placeholder,
    display = 'horizontal',
    variant = 'standard',
    isMulti = true,
    style,
    onlyOptionValue = false,
    minheight = true,
    containerStyle,
    ...others
  } = props;

  // const classesUI = useStyles();

  // console.log(errors, '<-----------errors');

  const classesUI = useStyles();

  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  let validate = null;

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[name].required
              ? errorMessage.message[name].required
              : errorMessage.message[name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  if (display === 'vertical') {
    labelText = props.labeltext;
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };

  // console.log(isDesktop, '<-----------isDesktop', display);
  // para aplicar o no el minheight al campo
  const CustomInputContainer = (props) => {
    const classesUI = useStyles(props);
    return (
      <div
        className={clsx(
          display === 'horizontal'
            ? classesUI.inputContainerStyleHorizontal
            : classesUI.inputContainerStyleVertical
        )}
      >
        {props.children}
      </div>
    );
  };

  return (
    <CustomInputContainer minheight={minheight} containerStyle={containerStyle}>
      {display === 'horizontal' ? (
        <div className={classes.labelDesktopStyle}>
          {props.labeltext} {props.download}
        </div>
      ) : null}
      {props.loading && <p>cargando</p>}
      <FormControl
        className={classesUI.formControl}
        error={stateError}
        variant={variant}
        style={style || {}}
      >
        {/* <div className={classesUI.formControl}> */}
        {/* <div className={classesUI.rootLabel}>{props.labeltext}</div>
        {props.loading && <p>cargando</p>} */}
        {!isDesktop || display === 'vertical' ? (
          <InputLabel
            shrink={true}
            id={labelText}
            className={classesUI.rootLabel}
          >
            {props.labeltext} {loading && '...cargando'}
          </InputLabel>
        ) : null}
        <Controller
          render={({ field }) => {
            if (onlyOptionValue) {
              return (
                <Select
                  options={options}
                  inputRef={field.ref}
                  isMulti={isMulti}
                  variant={variant}
                  labelId={labelText}
                  // {...field}
                  value={options.find((c) => c.value === field.value)}
                  onChange={(val) => field.onChange(val?.value)}
                  clearValue={() => field.onChange(null)}
                  isLoading={loading}
                  menuPortalTarget={document.body}
                  {...others}
                  classNamePrefix='react-select'
                  components={{
                    DropdownIndicator,
                  }}
                  placeholder={placeholder}
                  styles={
                    display === 'vertical'
                      ? customVerticalStyles
                      : customHorizontalStyles
                  }
                />
              );
            } else {
              return (
                <Select
                  options={options}
                  isMulti={isMulti}
                  variant={variant}
                  labelId={labelText}
                  error={stateError}
                  {...field}
                  isLoading={loading}
                  menuPortalTarget={document.body}
                  {...others}
                  classNamePrefix='react-select'
                  className={stateError ? classesUI.errorStateStyle : ''}
                  components={{
                    DropdownIndicator,
                  }}
                  noOptionsMessage={() => 'No hay resultados'}
                  placeholder={placeholder}
                  styles={
                    display === 'vertical'
                      ? customVerticalStyles
                      : customHorizontalStyles
                  }
                />
              );
            }
          }}
          name={name}
          rules={{
            required: req,
            validate: validate,
          }}
          control={control}
          defaultValue={defaultValue}
        />

        {stateError && (
          <FormHelperText className={classes.errorText} error>
            {stateMessage}
          </FormHelperText>
        )}
      </FormControl>
    </CustomInputContainer>
  );
};

export default SelectMultipleWidget2;
