import React from 'react';
// import Paper from '@material-ui/core/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import classesModule from './Tabs.module.scss';
import { IsDesktopHandler } from 'helpers';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabsStyle: {
    // borderBottomColor: theme.palette.divider,
    // borderBottom: '1px solid',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.secondary,
      height: 4,
    },
    '& .MuiTab-root.Mui-selected': {
      // color: 'red',
      fontWeight: 600,
    },
  },
  tabDisabledStyle: {
    opacity: 0.3,
  },
  TabsSelected: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const SimpleTabs = (props) => {
  const classes = useStyles();

  let tabList;
  if (props.tabEnabled) {
    tabList =
      props.tabitems.length > 0 &&
      props.tabitems.map(({ id, namePrint, disabled }) => {
        return (
          <Tab
            key={id}
            disabled={disabled}
            classes={{
              disabled: classes.tabDisabledStyle,
              // root: classes.rootTabs,
              // selected: classes.TabsSelected,
              // textColorPrimary: classes.TabsTextColor,
            }}
            label={namePrint}
            {...a11yProps({ id })}
          />
        );
      });
  } else {
    tabList =
      props.tabitems.length > 0 &&
      props.tabitems.map((tKey, tid) => {
        return (
          <Tab
            key={tid}
            classes={
              {
                // root: classes.rootTabs,
                // selected: classes.TabsSelected,
                // textColorPrimary: classes.TabsTextColor,
              }
            }
            label={tKey}
            {...a11yProps({ tid })}
          />
        );
      });
  }

  const isDesktop = IsDesktopHandler('md');

  // console.log(isDesktop, '<-----isDesktop');

  return (
    <div className={classes.root}>
      <Box
        elevation={2}
        component='div'
        // style={{ borderBottom: '1px solid' }}
      >
        <Tabs
          value={props.value}
          onChange={props.toggler}
          indicatorColor='secondary'
          className={classes.tabsStyle}
          textColor='secondary'
          aria-label='area de Tabs'
          variant='scrollable'
          scrollButtons={isDesktop ? 'auto' : true}
          allowScrollButtonsMobile
        >
          {}
          {tabList}
        </Tabs>
      </Box>
      {props.children}
    </div>
  );
};

export default SimpleTabs;
