import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Card, CardContent, Divider, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	SearchBasicTable,
	ModalWidget,
	ModalWidgetDetailTable,
	DataTableWidget,
	DialogDelete,
	DialogState,
	IconActionTableDelete,
	IconActionTableState,
	IconActionTableEdit,
	IconActionTableDetail,
	BreadCrumbs,
	CardHeaderWidget,
	EditionAreaWidget,
} from '../../../components';
import {
	// handleActionDetail,
	handleActionState,
	handleActionEdit,
	handleActionDelete,
	concatObjectConfigDatatable,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import ContractorEdit from '../ContractorEdit';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
}));

const ContractorList = () => {
	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'rut',
		option: 'asc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [editionState, setEditionState] = useState(false);
	const [rowToHandleState, setRowToHandleState] = useState('');
	const [rowToEditState, setRowToEditState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const columnsConfig = useMemo(
		() => [
			{
				name: 'Rut',
				selector: (row) => row.rut,
				sortField: 'rut',
				sortable: true,
				id: 1,
			},
			{
				name: 'Nombres',
				selector: (row) => row.names,
				sortField: 'names',
				sortable: false,
				id: 2,
			},
			{
				name: 'Apellidos',
				selector: (row) => row.lastNames,
				sortField: 'lastNames',
				// hide: 'md',
				sortable: true,
				id: 3,
			},
			{
				name: 'E-mail',
				selector: (row) => row.email,
				sortField: 'email',
				// hide: 'sm',
				sortable: false,
				id: 4,
			},
			{
				name: 'Teléfono',
				selector: (row) => row.phoneNumber,
				sortField: 'phoneNumber',
				// hide: 'sm',
				sortable: false,
				id: 5,
			},
		],
		[]
	);

	const actionsConfig = [
		{
			name: 'Acciones',
			width: '190px',
			cell: (row) => (
				<div className="actionColumn">
					<IconActionTableDetail
						handleActionDetail={() => {
							const html = row;
							setModalState({
								...modalState,
								openDetail: true,
								detailModal: html,
							});

							console.info('estado modal: ', modalState);
						}}
					/>
					<IconActionTableEdit
						handleActionEdit={() => {
							setRowToEditState(row);
							setEditionState(true);
						}}
					/>
					<IconActionTableDelete
						handleActionDelete={() => DialogDeleteHandler(row)}
					/>
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);

	const getQueryCount = async () => {
		try {
			const response = await axiosContext.authAxios.post(
				'v1/traceability/contractor-count'
			);

			// console.log(response, '<-----response query count');

			if (response.status === 200) {
				// console.log(response.data, "<-------response.data")
				setCountState(response.data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		}
	};

	async function queryData(page, sort = sortState) {
		setLoadingDataTableState(true);
		try {
			let objQuery = {
				page: page,
				limit: limitState,
				sort: sort ? sort : sortState,
			};

			// console.log(objQuery, '----------------------la query que va');

			const response = await axiosContext.authAxios.post(
				'v1/traceability/contractor-list',
				objQuery
			);

			const { data, status } = response;

			// console.log(response, '<-----response query');

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				// console.log('eeeeeeee', data);
				// setCountState(data.count);
				setTableDataState(data);
				setPageState(page);
				setSortState(sort);
				setBusyState(false);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setBusyState(false);
			setLoadingDataTableState(false);
			console.log('-----------', err);
		}
	}

	const DialogDeleteHandler = (row) => {
		console.log(row, '<-----row');
		setRowToHandleState(row);
		setEditionState(false);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			console.log(objDel, '----------------------la query que va');

			const responseDel = await axiosContext.authAxios.post(
				'v1/traceability/contractor-delete',
				objDel
			);

			console.log(responseDel, '<-----response query');

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
				setLoadingDeleteState(false);
				setSuccessDeleteState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	const closeEditionAreaHandler = () => {
		setEditionState(false);
	};

	const editActionHandler = (newObject) => {
		console.log('asi pasa al editAction', newObject);
		const newUsers = handleActionEdit(newObject, tableDataState);
		console.log(newUsers, '-----mod newUsers');
		setTableDataState(newUsers);
	};

	//const searchTool = false;

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({ key: nameColumn, option: sortDirection });
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/traceability/contractor-list',
				objQuery
			);

			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				// setCountState(data.count);
				setTableDataState(data);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	const handleCloseModal = () => {
		setModalState({
			...modalState,
			openDetail: false,
		});
	};

	useEffect(() => {
		queryData(pageState);
		getQueryCount();
		// setTableDataState(CONTRACTORS);
		// setCountState(CONTRACTORS.length);
	}, []);

	// console.log(usersDataState,"la data");

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						{editionState && (
							<EditionAreaWidget
								component={
									<ContractorEdit
										rowEdit={rowToEditState}
										closeFunc={closeEditionAreaHandler}
										editFunc={editActionHandler}
									/>
								}
								visible={editionState}
							/>
						)}
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.contractor.list.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div style={{ minHeight: '300px' }}>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										sortField="1"
										filteredItems={tableDataState}
										// subHeaderComponentMemo={subHeaderComponentMemo}
										loading={loadingDataTableState}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Contratistas"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								{/* <ModalWidget
									size="modal-50"
									openDetail={modalState.openDetail}
									detailModal={modalState.detailModal}
									moduleName="Usuarios"
								/> */}
								<ModalWidgetDetailTable
									size="modal-50"
									closeFunc={handleCloseModal}
									openDetail={modalState.openDetail}
									detailModal={modalState.detailModal}
									moduleName="Contratistas"
								/>
								{/* <DialogState
									openDialog={openDialogStateState}
									rowState={rowToHandleState}
									actionState={stateActionDialogHandler}
									variant="success"
									loading={loadingChangeStateGraph}
									success={dataChangeStateGraph}
									openState={setOpenDialogStateState}
								/> */}
								<DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default ContractorList;
