import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { findActualYear, numberFormat } from '../../../helpers';
import clsx from 'clsx';
// import theme from 'theme';
import DartIcon from '../../../assets/icons/Vectordardo.png';
import TwigIcon from '../../../assets/icons/Vectorramilla.png';
import FlowerIcon from '../../../assets/icons/Groupflor.png';
import FruitsetIcon from '../../../assets/icons/Capa2cuaja.png';
import PerformanceIcon from '../../../assets/icons/Grouprendimiento.png';
import CaliberIcon from '../../../assets/icons/Vectorcalibre.png';
// import { INFO } from '../../../helpers/Mockups';

const useStyles = makeStyles((theme) => ({
	paperModal: {
		backgroundColor: theme.palette.background.paper,
		// border: '2px solid #000',
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[3],
		padding: theme.spacing(1, 3, 3),
		width: '95%',
		height: '87vh',
	},
	scrollAreaModalStyle: {
		overflow: 'auto',
		maxHeight: '95%',
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
}));

const generalOptionsHighCharts = {
	chart: {
		type: 'area',
		height: 180,
		spacingBottom: 8,
		spacingLeft: 0,
		spacingRight: 5,
		spacingTop: 10,
	},
	title: {
		text: '',
		style: { display: 'none' },
	},
	yAxis: {
		min: 0,
		// max: 140,
		tickInterval: 20,
		title: {
			text: 'Cantidad',
			enabled: false,
		},
	},
	credits: {
		enabled: false,
	},
	plotOptions: {
		area: {
			fillColor: {
				linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
				stops: [
					[0, 'rgba(52, 94, 181,0.5)'],
					[1, 'rgba(52, 94, 181,0.1)'],
				],
			},
			color: '#cccccc',
			// fillOpacity: 0.5,
		},
	},
	tooltip: {
		formatter: function () {
			return (
				'<b>' +
				numberFormat(this.y) +
				'</b><br/>' +
				'en el año: ' +
				this.x
			);
		},
	},
	legend: {
		layout: 'horizontal',
		align: 'right',
		// x: 100,
		enabled: false,
		verticalAlign: 'top',
		// y: -150,
		floating: false,
	},
	responsive: {
		rules: [
			{
				condition: {
					maxWidth: 500,
				},
				chartOptions: {
					legend: {
						layout: 'horizontal',
						align: 'left',
						verticalAlign: 'bottom',
					},
				},
			},
		],
	},
};

const ModalTable = ({ closeAction, dataModal }) => {
	const theme = useTheme();

	const classes = useStyles();

	// ESTADOS GRÁFICOS
	const [yearFlowerGraphState, setYearFlowerGraphState] = useState([]);
	const [dataFlowerGraphState, setDataFlowerGraphState] = useState([]);

	const [yearFruitsetGraphState, setYearFruitsetGraphState] = useState([]);
	const [dataFruitsetGraphState, setDataFruitsetGraphState] = useState([]);

	const [yearWeightGraphState, setYearWeightGraphState] = useState([]);
	const [dataWeightGraphState, setDataWeightGraphState] = useState([]);

	const [yearPerformanceGraphState, setYearPerformanceGraphState] = useState(
		[]
	);
	const [dataPerformanceGraphState, setDataPerformanceGraphState] = useState(
		[]
	);

	console.log(dataModal, '<--------------------------------');

	const [expectedInfoState, setExpectedInfoState] = useState({
		dart: dataModal.dart,
		twig: dataModal.twig,
		flower: dataModal.flores_ha_helada,
		fruitSet: dataModal.fruitsetEstimate,
		weight: dataModal.caliberEstimate,
		performance: dataModal.rend_estimado_prepoda,
	});

	const optionsFlower = {
		...generalOptionsHighCharts,
		xAxis: {
			categories: yearFlowerGraphState,
		},
		tooltip: {
			formatter: function () {
				return (
					'<b>' +
					numberFormat(this.y) +
					'</b><br/>' +
					'en el año: ' +
					this.x
				);
			},
		},
		yAxis: {
			min: 0,
			// max: 140,
			tickInterval: 10000,
			title: {
				text: 'Cantidad',
				enabled: false,
			},
			labels: {
				formatter: function () {
					let theval = ['' + this.value].map((n) => {
						if (n / 1000000 > 1) return n / 1000000 + ' M.';
						else return n / 1000 + 'm.';
					});
					return theval;
				},
				style: { color: '#2B2B2B' },
			},
		},
		series: [
			{
				data: dataFlowerGraphState,
			},
		],
	};

	const optionsFruitset = {
		...generalOptionsHighCharts,
		// tooltip: {
		// 	formatter: function() {
		// 		return (
		// 			'<b>' +
		// 			Highcharts.numberFormat(this.y, 3,",",".") +
		// 			'</b><br/>' +
		// 			'en el año: ' +
		// 			this.x
		// 		);
		// 	},
		// },
		xAxis: {
			categories: yearFruitsetGraphState,
		},
		series: [
			{
				data: dataFruitsetGraphState,
			},
		],
	};

	const optionsWeight = {
		...generalOptionsHighCharts,
		// tooltip: {
		// 	formatter: function() {
		// 		return (
		// 			'<b>' +
		// 			Highcharts.numberFormat(this.y, 1,",",".") +
		// 			'</b><br/>' +
		// 			'en el año: ' +
		// 			this.x
		// 		);
		// 	},
		// },
		xAxis: {
			categories: yearWeightGraphState,
		},
		series: [
			{
				data: dataWeightGraphState,
			},
		],
	};

	const optionsPerformance = {
		...generalOptionsHighCharts,
		xAxis: {
			categories: yearPerformanceGraphState,
		},
		yAxis: {
			min: 0,
			// max: 140,
			tickInterval: 10000,
			title: {
				text: 'Cantidad',
				enabled: false,
			},
			labels: {
				formatter: function () {
					let theval = ['' + this.value].map((n) => {
						if (n / 1000000 > 1) return n / 1000000 + ' M.';
						else return n / 1000 + ' m.';
					});
					return theval;
				},
				style: { color: '#2B2B2B' },
			},
		},
		series: [
			{
				data: dataPerformanceGraphState,
			},
		],
	};

	useEffect(() => {
		// console.log(INFO[0]);

		// FRUITSET
		// revisa si está el 2022
		if (!findActualYear(dataModal.graphicFruitSet.year)) {
			setYearFruitsetGraphState([
				...dataModal.graphicFruitSet.year,
				new Date().getFullYear(),
			]);
		} else {
			setYearFruitsetGraphState([...dataModal.graphicFruitSet.year]);
		}

		setDataFruitsetGraphState([
			...dataModal.graphicFruitSet.data,
			{
				y: parseFloat(expectedInfoState.fruitSet),
				color: 'white',

				marker: {
					radius: 10,
					lineWidth: 5,
					lineColor: theme.palette.tertiary.main,
				},
			},
		]);

		// setDataFruitsetGraphState(dataModal.graphicFruitSet.data);
		// Flores
		if (!findActualYear(dataModal.graphicFlower.year)) {
			setYearFlowerGraphState([
				...dataModal.graphicFlower.year,
				new Date().getFullYear(),
			]);
		} else {
			setYearFlowerGraphState([...dataModal.graphicFlower.year]);
		}

		setDataFlowerGraphState([
			...dataModal.graphicFlower.data,
			{
				y: parseFloat(expectedInfoState.flower),
				color: 'white',

				marker: {
					radius: 10,
					lineWidth: 5,
					lineColor: theme.palette.tertiary.main,
				},
			},
		]);

		// Weight
		if (!findActualYear(dataModal.graphicWeight.year)) {
			setYearWeightGraphState([
				...dataModal.graphicWeight.year,
				new Date().getFullYear(),
			]);
		} else {
			setYearWeightGraphState([...dataModal.graphicWeight.year]);
		}

		setDataWeightGraphState([
			...dataModal.graphicWeight.data,
			{
				y: parseFloat(expectedInfoState.weight),
				color: 'white',

				marker: {
					radius: 10,
					lineWidth: 5,
					lineColor: theme.palette.tertiary.main,
				},
			},
		]);

		// Performance
		if (!findActualYear(dataModal.graphicPerformance.year)) {
			setYearPerformanceGraphState([
				...dataModal.graphicPerformance.year,
				new Date().getFullYear(),
			]);
		} else {
			setYearPerformanceGraphState([
				...dataModal.graphicPerformance.year,
			]);
		}

		setDataPerformanceGraphState([
			...dataModal.graphicPerformance.data,
			{
				y: parseFloat(expectedInfoState.performance),
				color: 'white',

				marker: {
					radius: 10,
					lineWidth: 5,
					lineColor: theme.palette.tertiary.main,
				},
			},
		]);
	}, []);

	console.log(dataModal, '----dataModal');

	return (
		<div className={classes.paperModal}>
			<div>
				<BsContainer fluid>
					<BsRow>
						<BsCol class="col-md-12 text-right pr-0">
							<Button onClick={closeAction}>
								<CloseIcon />
							</Button>
						</BsCol>
					</BsRow>
				</BsContainer>
			</div>
			<div className={classes.scrollAreaModalStyle}>
				<BsContainer fluid>
					<BsRow>
						<BsCol class="col-md-12">
							<Typography variant="h5" gutterBottom>
								Dashboard cuartel
							</Typography>
							<BsRow>
								<BsCol class="col-md-3">
									<table className="table">
										<tbody>
											<tr>
												<th scope="row">
													Centro de Costo
												</th>
												<td>
													{dataModal
														? dataModal?.cc
														: '-'}
												</td>
											</tr>
											<tr>
												<th scope="row">Cuartel</th>
												<td>
													{dataModal
														? dataModal?.quarter
														: '-'}
												</td>
											</tr>
											<tr>
												<th scope="row">Variedad</th>
												<td>
													{dataModal
														? dataModal?.variety
														: '-'}
												</td>
											</tr>
											<tr>
												<th scope="row">Patrón</th>
												<td>
													{dataModal
														? dataModal?.rootStock
														: '-'}
												</td>
											</tr>
											<tr>
												<th scope="row">
													Temporada Año
												</th>
												<td>
													{dataModal
														? dataModal?.season
														: '-'}
												</td>
											</tr>
											<tr>
												<th scope="row">
													Sistema de Conducción
												</th>
												<td>
													{dataModal
														? dataModal?.conduction
														: '-'}
												</td>
											</tr>
											{/* <tr>
												<th scope="row">
													Flores Ha Helada
												</th>
												<td>
													{dataModal
														? numberFormat(
																dataModal?.flores_ha_helada
														  )
														: '-'}
												</td>
											</tr> */}
										</tbody>
									</table>
								</BsCol>
								<BsCol class="col-md-9 pl-md-5">
									<BsRow class="mt-2 mt-md-3 mb-md-5">
										<BsCol class="col-md-6 col-lg-4 mb-4 mb-lg-3">
											<Typography
												variant="h6"
												gutterBottom
											>
												Dardos
											</Typography>
											<Divider
												className={clsx(
													'mb-2',
													classes.divider
												)}
											/>
											<Grid
												direction="row"
												container
												justifyContent="flex-start"
												alignItems="center"
											>
												<Grid item>
													<img
														src={DartIcon}
														className="img-fluid ico-recomendation"
														alt="dart"
													/>
												</Grid>
												<Grid item className="ml-1">
													<Typography
														variant="h3"
														className={classes.root}
													>
														{expectedInfoState
															? numberFormat(
																	expectedInfoState.dart
															  )
															: '---'}
													</Typography>
												</Grid>
											</Grid>
										</BsCol>
										<BsCol class="col-md-6 col-lg-4 mb-4 mb-lg-0">
											<Typography
												variant="h6"
												gutterBottom
											>
												Ramillas
											</Typography>
											<Divider
												className={clsx(
													'mb-2',
													classes.divider
												)}
											/>
											<Grid
												direction="row"
												container
												justifyContent="flex-start"
												alignItems="center"
											>
												<Grid item>
													<img
														src={TwigIcon}
														className="img-fluid ico-recomendation"
														alt="twig"
													/>
												</Grid>
												<Grid item className="ml-1">
													<Typography
														variant="h3"
														className={classes.root}
													>
														{expectedInfoState
															? numberFormat(
																	expectedInfoState.twig
															  )
															: '---'}
													</Typography>
												</Grid>
											</Grid>
										</BsCol>
										<BsCol class="col-md-6 col-lg-4 mb-4 mb-lg-0">
											<Typography
												variant="h6"
												gutterBottom
											>
												Flores
											</Typography>
											<Divider
												className={clsx(
													'mb-2',
													classes.divider
												)}
											/>
											<Grid
												direction="row"
												container
												justifyContent="flex-start"
												alignItems="center"
											>
												<Grid item>
													<img
														src={FlowerIcon}
														className="img-fluid ico-recomendation"
														alt="flower"
													/>
												</Grid>
												<Grid item className="ml-1">
													<Typography
														variant="h3"
														className={classes.root}
													>
														{expectedInfoState
															? numberFormat(
																	expectedInfoState.flower
															  )
															: '---'}
													</Typography>
												</Grid>
											</Grid>
										</BsCol>
									</BsRow>
									<BsRow class="mt-md-5 mb-md-4">
										<BsCol class="col-md-6 col-lg-4 mb-4 mb-lg-0">
											<Typography
												variant="h6"
												gutterBottom
											>
												Cuaja estimada
											</Typography>
											<Divider
												className={clsx(
													'mb-2',
													classes.divider
												)}
											/>
											<Grid
												direction="row"
												container
												justifyContent="flex-start"
												alignItems="center"
											>
												<Grid item>
													<img
														src={FruitsetIcon}
														className="img-fluid ico-recomendation"
														alt="dart"
													/>
												</Grid>
												<Grid item className="ml-1">
													<Typography
														variant="h3"
														className={classes.root}
													>
														{expectedInfoState
															? numberFormat(
																	expectedInfoState.fruitSet
															  )
															: '---'}{' '}
														<Typography
															component="span"
															display="inline"
															variant="h4"
														>
															%
														</Typography>
													</Typography>
												</Grid>
											</Grid>
										</BsCol>
										<BsCol class="col-md-6 col-lg-4 mb-4 mb-lg-0">
											<Typography
												variant="h6"
												gutterBottom
											>
												Calibre estimado
											</Typography>
											<Divider
												className={clsx(
													'mb-2',
													classes.divider
												)}
											/>
											<Grid
												direction="row"
												container
												justifyContent="flex-start"
												alignItems="center"
											>
												<Grid item>
													<img
														src={CaliberIcon}
														className="img-fluid ico-recomendation"
														alt="dart"
													/>
												</Grid>
												<Grid item className="ml-1">
													<Typography
														variant="h3"
														className={classes.root}
													>
														{expectedInfoState
															? numberFormat(
																	expectedInfoState.weight
															  )
															: '---'}{' '}
														<Typography
															display="inline"
															component="span"
															variant="h4"
														>
															gr/fruto
														</Typography>
													</Typography>
												</Grid>
											</Grid>
										</BsCol>
										<BsCol class="col-md-6 col-lg-4 mb-4 mb-lg-0">
											<Typography
												variant="h6"
												gutterBottom
											>
												Rendimiento estimado
											</Typography>
											<Divider
												className={clsx(
													'mb-2',
													classes.divider
												)}
											/>
											<Grid
												direction="row"
												container
												justifyContent="flex-start"
												alignItems="center"
											>
												<Grid item>
													<img
														src={PerformanceIcon}
														className="img-fluid ico-recomendation"
														alt="dart"
													/>
												</Grid>
												<Grid item className="ml-1">
													<Typography
														variant="h3"
														className={classes.root}
													>
														{expectedInfoState
															? numberFormat(
																	expectedInfoState.performance
															  )
															: '---'}{' '}
														<Typography
															display="inline"
															component="span"
															variant="h4"
														>
															kg/ha
														</Typography>
													</Typography>
												</Grid>
											</Grid>
										</BsCol>
									</BsRow>
								</BsCol>
							</BsRow>
							{/* gráficos */}
							<BsRow class="mt-md-5">
								<BsCol class="col-md-6">
									<Typography variant="h5" gutterBottom>
										Flores en M.
									</Typography>
									<HighchartsReact
										highcharts={Highcharts}
										options={optionsFlower}
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<Typography variant="h5" gutterBottom>
										Cuaja
									</Typography>
									<HighchartsReact
										highcharts={Highcharts}
										options={optionsFruitset}
									/>
								</BsCol>
								<BsCol class="col-md-6 mt-md-3">
									<Typography variant="h5" gutterBottom>
										Calibre
									</Typography>
									<HighchartsReact
										highcharts={Highcharts}
										options={optionsWeight}
									/>
								</BsCol>
								<BsCol class="col-md-6 mt-md-3">
									<Typography variant="h5" gutterBottom>
										Rendimiento en m.
									</Typography>
									<HighchartsReact
										highcharts={Highcharts}
										options={optionsPerformance}
									/>
								</BsCol>
							</BsRow>
						</BsCol>
					</BsRow>
				</BsContainer>
			</div>
		</div>
	);
};

export default ModalTable;
