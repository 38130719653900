// import _ from 'lodash';
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Hidden,
  Modal,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classesModule from './DashboardExternal.module.scss';
import { AxiosContext } from '../../context/AxiosContext';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  SelectMultipleWidget2,
  TextFieldWidgetSelectControllerStyled,
} from '../../components';
import { FiltersContext } from '../../context';
import { ModalTable, WeatherBox } from '../../containers/Dashboard';
import clsx from 'clsx';
import {
  dateFrontConvert,
  numberFormat,
  numberFormatDecimals,
} from '../../helpers';
import _ from 'lodash';
import { useErrorHandler } from 'react-error-boundary';
import { Context } from '../../context/Context';

// import { ButtonBox } from '../../components/Dashboard';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
    marginTop: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  subtitleDash: {
    fontSize: '17px !important',
    fontWeight: '600 !important',
    marginBottom: '18px !important',
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
}));

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
  },
});

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 580,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const MOCK_FINAL = {
  pieKilos: [
    {
      name: 'Filtrado',
      y: 24.5,
      sliced: true,
      selected: true,
    },
    {
      name: 'Total',
      y: 75.5,
    },
  ],
  dataVarieties: {
    data: [
      {
        variety: 'Bing',
        kilos: 5000,
      },
      {
        variety: 'Lapins',
        kilos: 7000,
      },
      {
        variety: 'Royal Dawn',
        kilos: 1000,
      },
      {
        variety: 'Santina',
        kilos: 7000,
      },
    ],
    total: 12000,
  },
  dataAgroBusinesss: {
    data: [
      {
        agrobusiness: 'Agro 1',
        kilos: 5000,
        date: '2023-12-15',
      },
      {
        agrobusiness: 'Agro 2',
        kilos: 7000,
        date: '2023-03-21',
      },
    ],
    total: 120000,
  },
};

const materialUIColors = [
  '#ff4081',
  '#9fa8da',
  '#4db6ac',
  '#039be5',
  '#ff9800',
  '#aed581',
  '#ffcc80',
  '#a1887f',
  '#546e7a',
];

// una función que recibe pieData u deja solamente y mas name
const pieDataHandler = (pieData) => {
  const newData = pieData.map((item) => {
    return {
      name: item.name,
      y: parseFloat(item.y),
    };
  });

  console.log(newData, '<<<<<------------');
  return newData;
};

const alphabeticalSort = (data) => {
  data.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  return data;
};

const alphabeticalSortAgro = (data) => {
  data.sort((a, b) => {
    if (a.agrobusiness < b.agrobusiness) {
      return -1;
    }
    if (a.agrobusiness > b.agrobusiness) {
      return 1;
    }
    return 0;
  });
  return data;
};

const DashboardExternal = () => {
  // let history = useHistory();

  const theme = useTheme();

  console.log(theme, '----------------theme');

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const errorHandler = useErrorHandler();

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const { userClient } = useContext(Context);

  const [filterDataResponseState, setFilterDataResponseState] =
    useState(userOrchard_filter);

  const [dataModalState, setDataModalState] = useState(null);

  const [filtersDataState, setFiltersDataState] = useState(null);
  const [loadingFiltersDataState, setLoadingFiltersDataState] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);
  const [dashDataState, setDashDataState] = useState(null);

  // const context = useContext(Context);
  // const { userId, userIdProfile, userFullname } = context;
  const [openModalState, setOpenModalState] = useState(false);

  const optionsHighCharts = {
    ...generalOptionsHighCharts,
    // title: {
    //   verticalAlign: 'middle',
    //   floating: true,
    //   text: `Superficie Total <br> ${
    //     dashDataState && dashDataState.surface
    //       ? numberFormat(dashDataState.surface)
    //       : ''
    //   } há`,
    //   style: {
    //     fontSize: 15,
    //     fontWeight: 600,
    //   },
    // },
    title: {
      text: '',
    },
    chart: {
      type: 'pie',
      height: 320,
      spacingBottom: 10,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0,
    },
    tooltip: {
      // valueSuffix: '%',
      pointFormat: '<b>{point.percentage:.2f}%</b>',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        showInLegend: false,
        colors: ['#75b127', '#4f8257'],
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          formatter: function () {
            return (
              this.point.name +
              '<br/>' +
              numberFormatDecimals(this.y, 1) +
              ' Kg.'
            );
          },
          distance: 18,
          // color: 'white',
          style: {
            fontSize: 12,
          },
        },
      },
    },
    series:
      dashDataState && dashDataState.pieKilos
        ? [
            {
              name: 'Kilos',
              colorByPoint: true,
              data: pieDataHandler(dashDataState.pieKilos),
            },
          ]
        : [],
  };

  const optionsHighchartsVarietiesKilos = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 300,
      zoomType: 'y',
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return '<b>' + numberFormat(this.y) + ' Kg.</b><br/>' + this.x;
      },
    },
    xAxis: {
      categories:
        dashDataState && dashDataState.columnVarieties.varieties
          ? dashDataState.columnVarieties.varieties
          : [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Kilos (kg)',
      },
    },
    // legend: {
    // 	reversed: true, // right here!
    // },
    colors: materialUIColors,
    series: dashDataState ? [dashDataState.columnVarieties.data] : [],
  };

  const onSubmit = async (values) => {
    setLoadingDashDataState(true);

    // console.log(values, '<<<<<-----VALUES-------');

    const data = {
      groupValue: values?.group ? values?.group.value : null,
      technicBossValue: values?.technicBoss || null,
      specieValue: values?.specie || null,
    };

    try {
      const response = await axiosContext.authAxios.post(
        'v1/libraryExternal/dahsboard',
        data
      );
      const bodyInfo = response.data;
      // const bodyInfo = INFO[0];

      console.log(response, '<-------LLEGA RESPONSE DATA--------');

      if (bodyInfo) {
        const dataChartVarieties = bodyInfo.dataVarieties.data;
        const arrayVarieties = dataChartVarieties.map((item) => item.variety);
        const arrayVarietiesKilos = dataChartVarieties.map(
          (item) => item.kilos
        );

        const dashBodyInfo = {
          columnVarieties: {
            varieties: arrayVarieties,
            data: {
              name: 'Kilos',
              data: arrayVarietiesKilos,
              colorByPoint: true, // Esta propiedad permite que se utilicen los col
            },
          },
          ...bodyInfo,
        };

        setDashDataState(dashBodyInfo);
        setLoadingDashDataState(false);
      }
    } catch (error) {
      // console.log(error);
      // función del boundary, pasa el error
      // se aplica en los cach
      // errorHandler(error);
      setLoadingDashDataState(false);
    } finally {
      setLoadingDashDataState(false);
    }
  };

  const getFilterDataAsync = async () => {
    setLoadingFiltersDataState(true);
    try {
      const params = {
        clientValue: userClient?.value,
      };

      const response = await axiosContext.authAxios.get(
        'v1/libraryExternal/filter',
        {
          params: params,
        }
      );

      console.log(response, '<<<<<------RESPONSE------');

      if (response.data) {
        // eliminar de response.data los label ''
        const dataGroup = response.data.groups.filter((item) => item.label);
        const technicBoss = response.data.technicBoss.filter(
          (item) => item.label
        );

        const species = response.data.specie.filter((item) => item.label);

        const dataFilter = {
          groups: alphabeticalSort(dataGroup),
          technicBoss: alphabeticalSort(technicBoss),
          specie: alphabeticalSort(species),
        };

        setFiltersDataState(dataFilter);
      }
      setLoadingFiltersDataState(false);
    } catch (error) {
      setLoadingFiltersDataState(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    getFilterDataAsync();
  }, []);

  // console.log(dashDataState, '<<<<<------------', filtersDataState);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-md-3'>
            <Card
              elevation={0}
              // classes={{
              // 	root: 'MainCardRoot',
              // }}
            >
              <CardContent>
                <BsRow class='mb-1 mb-md-4'>
                  <BsCol class='col-md-9'>
                    <Typography gutterBottom variant='h3' component='h2'>
                      Dashboard Externos
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        {/* <TextFieldWidgetSelectControllerStyled
                          options={filtersDataState?.groups || []}
                          control={control}
                          titleOption='label'
                          name='group'
                          labeltext='Seleccione Grupo'
                          errors={errors.group}
                          defaultValue=''
                          loading={loadingFiltersDataState}
                          req={false}
                          withDefault
                          variant='standard'
                        /> */}
                        <SelectMultipleWidget
                          errors={errors.group}
                          loading={loadingFiltersDataState}
                          control={control}
                          menuPortalTarget={document.body}
                          labeltext='Seleccione Grupo'
                          name='group'
                          isSearchable
                          isClearable={true}
                          isMulti={false}
                          req={false}
                          defaultValue=''
                          options={filtersDataState?.groups || []}
                          placeholder='Seleccione Grupo'
                        />
                        {/* <SelectMultipleWidget2
                          errors={errors.group}
                          loading={loadingFiltersDataState}
                          control={control}
                          menuPortalTarget={document.body}
                          labeltext='Seleccione Grupo'
                          // style={{ width: '100%', maxWidth: '100%' }}
                          name='group'
                          display='vertical'
                          variant='standard'
                          isMulti={false}
                          isSearchable
                          defaultValue=''
                          options={filtersDataState?.groups || []}
                          placeholder='Seleccione Grupo'
                        /> */}
                      </div>
                      <div className='input-group'>
                        <TextFieldWidgetSelectControllerStyled
                          options={filtersDataState?.technicBoss || []}
                          control={control}
                          titleOption='label'
                          name='technicBoss'
                          labeltext='Seleccione Jefe Técnico'
                          errors={errors.technicBoss}
                          defaultValue=''
                          loading={loadingFiltersDataState}
                          req={false}
                          withDefault
                          variant='standard'
                        />
                      </div>
                      <div className='input-group'>
                        <TextFieldWidgetSelectControllerStyled
                          options={filtersDataState?.specie || []}
                          control={control}
                          titleOption='label'
                          name='specie'
                          labeltext='Seleccione Especie'
                          errors={errors.specie}
                          defaultValue=''
                          loading={loadingFiltersDataState}
                          req={false}
                          withDefault
                          variant='standard'
                        />
                      </div>
                      <SaveAction
                        title='Filtrar'
                        color='secondary'
                        size='large'
                        disabled={loadingFiltersDataState}
                        className='roundedButton ml-md-2'
                        variant='contained'
                        messageType='add'
                        mutationLoading={false}
                        success={false}
                      />
                    </form>
                  </BsCol>
                  {!loadingDashDataState && (
                    <BsCol class='col-md-3 align-self-end text-right'>
                      <Typography variant='h6' gutterBottom>
                        {new Date().toLocaleDateString('es-ES', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Typography>
                      {/* <Typography
												variant="body2"
												gutterBottom
											>
												Última actualización 17:45 hrs.
											</Typography> */}
                    </BsCol>
                  )}
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        {!loadingDashDataState ? (
          dashDataState ? (
            <>
              <BsRow class='no-gutters'>
                <BsCol class='col-md-12'>
                  <BsRow class='gutter_10'>
                    <BsCol class='col-md-6 col-lg-5'>
                      <Card elevation={0} className={classes.dashCard}>
                        <CardContent>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Kg. Totales / Filtrado
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighCharts}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>

                    <BsCol class='col-md-6 col-lg-7'>
                      <Card elevation={0} className={classes.dashCard}>
                        <CardContent>
                          <BsRow>
                            <BsCol class='col-md-12'>
                              <Typography
                                variant='subtitle1'
                                className={classes.subtitleDash}
                                gutterBottom
                              >
                                Detalle Agrícola Kg.
                              </Typography>
                              <div className={classes.tableAreaStyle}>
                                <table className='table'>
                                  <thead
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 1,
                                    }}
                                    className={classes.theadLight}
                                  >
                                    <tr>
                                      <th scope='col'>Agrícola</th>
                                      <th scope='col'>Kilos Totales</th>
                                      <th scope='col'>Fecha Actualización</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dashDataState.dataAgroBusinesss &&
                                      alphabeticalSortAgro(
                                        dashDataState.dataAgroBusinesss.data
                                      ).map((agrobusiness, i) => (
                                        <tr
                                          key={`${agrobusiness.agrobusiness}_${i}`}
                                        >
                                          <td>{agrobusiness.agrobusiness}</td>
                                          <td>
                                            {numberFormatDecimals(
                                              agrobusiness.kilos,
                                              1
                                            )}
                                          </td>
                                          <td>
                                            {dateFrontConvert(
                                              agrobusiness.date
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th>
                                        <strong>Total</strong>
                                      </th>
                                      <th>
                                        <strong>
                                          {numberFormatDecimals(
                                            dashDataState.dataAgroBusinesss
                                              .total,
                                            1
                                          )}
                                        </strong>
                                      </th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </BsCol>
                          </BsRow>
                        </CardContent>
                      </Card>
                    </BsCol>
                  </BsRow>
                </BsCol>
              </BsRow>
              {/* 3B row CONTEOS*/}
              <BsRow class='no-gutters mt-3'>
                <BsCol class='col-md-12'>
                  <BsRow class='gutter_10'>
                    <BsCol class='col-md-6 col-lg-5'>
                      <Card elevation={0} className={classes.dashCard}>
                        <CardContent>
                          <BsRow>
                            <BsCol class='col-md-12'>
                              <Typography
                                variant='subtitle1'
                                className={classes.subtitleDash}
                                gutterBottom
                              >
                                Detalle Kg. Variedades
                              </Typography>
                              <div className={classes.tableAreaStyle}>
                                <table className='table'>
                                  <thead
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 1,
                                    }}
                                    className={classes.theadLight}
                                  >
                                    <tr>
                                      <th scope='col'>Especie</th>
                                      <th scope='col'>Variedad</th>
                                      <th scope='col'>Kilos Totales</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dashDataState.dataVarieties &&
                                      dashDataState.dataVarieties.data.map(
                                        (variety, i) => (
                                          <tr key={`${variety.variety}_${i}`}>
                                            <td>{variety?.specie}</td>
                                            <td>{variety.variety}</td>
                                            <td>
                                              {numberFormatDecimals(
                                                variety.kilos,
                                                1
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th colSpan={2}>
                                        <strong>Total</strong>
                                      </th>
                                      <th>
                                        <strong>
                                          {numberFormatDecimals(
                                            dashDataState.dataVarieties.total,
                                            1
                                          )}
                                        </strong>
                                      </th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </BsCol>
                          </BsRow>
                        </CardContent>
                      </Card>
                    </BsCol>
                    <BsCol class='col-md-6 col-lg-7'>
                      <Card elevation={0} className={classes.dashCard}>
                        <CardContent>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Kg. por Variedad
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighchartsVarietiesKilos}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>
                  </BsRow>
                </BsCol>
              </BsRow>
            </>
          ) : (
            <></>
          )
        ) : (
          <LoadingWidget />
        )}
      </BsContainer>
    </div>
  );
};

export default DashboardExternal;
