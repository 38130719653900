// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { BsRow, BsCol } from '../../../layouts/components';
import { LoadingWidget } from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  alterArrayForCountData,
  dashDelete,
  IsDesktopHandler,
} from '../../../helpers';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { useErrorHandler } from 'react-error-boundary';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
    minHeight: '40vh',
  },
  dividerHeight: {
    minHeight: '70px',
  },
}));

const DailyFruit = ({ filter: filterProp }) => {
  const errorHandler = useErrorHandler();

  const { userOrchard } = useContext(Context);

  const [loadingGraphState, setLoadingGraphState] = useState(true);
  const [graphDataState, setGraphDataState] = useState(null);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [showLoadingState, setShowLoadingState] = useState(false);

  Highcharts.setOptions({
    lang: {
      loading: 'Cargando...',
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      weekdays: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      shortMonths: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      exportButtonTitle: 'Exportar',
      printButtonTitle: 'Importar',
      rangeSelectorFrom: 'Desde',
      rangeSelectorTo: 'Hasta',
      rangeSelectorZoom: 'Período',
      downloadPNG: 'Descargar imagen PNG',
      downloadJPEG: 'Descargar imagen JPEG',
      downloadPDF: 'Descargar imagen PDF',
      downloadSVG: 'Descargar imagen SVG',
      printChart: 'Imprimir',
      resetZoom: 'Reiniciar zoom',
      resetZoomTitle: 'Reiniciar zoom',
      thousandsSep: ',',
      decimalPoint: '.',
    },
  });

  // console.log(theme);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { state: filterState } = useContext(FiltersContext);

  async function queryData() {
    setShowLoadingState(true);
    setLoadingGraphState(true);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const {
        huerto,
        centro_costo,
        cuartel,
        variety,
        // temporada,
      } = filterProp;

      // console.log(filterProp, '-----filterProp');

      let objQuery = {
        huerto: huerto,
        huerto_user: userOrchard, //orchard del login
        centro_costo: centro_costo,
        cuartel: cuartel,
        variedad: variety,
        // rootStock: porta_injerto,
        // dateBegin: dateTimeToCalendarFormat(dateBegin),
        // dateEnd: dateTimeToCalendarFormat(dateEnd),
        // season: temporada,
      };

      // console.log(objQuery, '---la query que va');

      const response = await axiosContext.authAxios.post(
        'v1/counter/plant-days',
        objQuery
      );

      const { data } = response;

      // console.log(response, '<-----response query------days');

      // setCountState(data.totalPassengers);
      if (data) {
        // setTableDataState(data.data);
        console.log('eeeeeeee', data);

        setGraphDataState({
          counterPreRaleo: alterArrayForCountData(data.plantCountPreFruit),
          counterPostRaleo: alterArrayForCountData(data.plantCountPostFruit),
        });
        setLoadingGraphState(false);
        // setGraphDataState(data.data);
      }
    } catch (err) {
      setGraphDataState([]);
      setLoadingGraphState(false);
      errorHandler(err);
    }
  }

  useEffect(() => {
    if (filterProp) {
      // console.log(filterProp);
      queryData();
    }
  }, [filterProp]);

  useEffect(() => {
    if (graphDataState) {
      console.log(graphDataState, 'empieza a crear gráfico');
      setGraphOptionsState({
        chart: {
          alignTicks: false,
          height: 450,
          spacingBottom: 48,
          spacingLeft: 30,
          spacingRight: 30,
          spacingTop: 10,
        },

        time: {
          timezone: 'America/Santiago',
        },

        rangeSelector: {
          selected: 1,
          enabled: false,
        },

        title: {
          text: 'Conteos Frutos',
        },
        credits: {
          enabled: false,
        },
        // xAxis: {
        // 	categories: ['Pre Poda', 'Post Poda'],
        // 	crosshair: false,
        // },
        series: [
          {
            type: 'column',
            name: 'Pre Raleo',
            showInLegend: true,
            data: graphDataState.counterPreRaleo,
            dataGrouping: {
              units: [['week', [1]]],
              // forced: true,
              // enabled: true,
              // groupAll: true,
            },
          },
          {
            type: 'column',
            name: 'Post Raleo',
            showInLegend: true,
            data: graphDataState.counterPostRaleo,
            dataGrouping: {
              units: [['week', [1]]],
              // forced: true,
              // enabled: true,
              // groupAll: true,
            },
          },
        ],
        tooltip: {
          shared: true,
        },
        legend: {
          align: 'center',
          className: 'legend_class',
          useHTML: true,
          enabled: true,
          verticalAlign: 'top',
          // backgroundColor: 'tomato',
        },
        // responsive: {
        // 	rules: [
        // 		{
        // 			condition: {
        // 				maxWidth: 500,
        // 			},
        // 			chartOptions: {
        // 				legend: {
        // 					layout: 'horizontal',
        // 					align: 'left',
        // 					verticalAlign: 'bottom',
        // 				},
        // 			},
        // 		},
        // 	],
        // },
      });
      setShowLoadingState(false);
    }
  }, [graphDataState]);

  // const generalOptionsHighCharts = {
  // 	chart: {
  // 		alignTicks: false,
  // 	},

  // 	time: {
  // 		timezone: 'America/Santiago',
  // 	},

  // 	rangeSelector: {
  // 		selected: 1,
  // 	},

  // 	title: {
  // 		text: 'Conteos',
  // 	},
  // 	credits: {
  // 		enabled: false,
  // 	},
  // 	xAxis: {
  // 		categories: ['Pre Poda', 'Post Poda'],
  // 		crosshair: false,
  // 	},
  // 	series: [
  // 		{
  // 			type: 'column',
  // 			name: 'Pre Poda',
  // 			data: graphDataState.prePoda,
  // 			dataGrouping: {
  // 				units: [['week', [1]]],
  // 				// forced: true,
  // 				// enabled: true,
  // 				// groupAll: true,
  // 			},
  // 		},
  // 		{
  // 			type: 'column',
  // 			name: 'Post Poda',
  // 			data: graphDataState.postPoda,
  // 			dataGrouping: {
  // 				units: [['week', [1]]],
  // 				// forced: true,
  // 				// enabled: true,
  // 				// groupAll: true,
  // 			},
  // 		},
  // 	],
  // 	responsive: {
  // 		rules: [
  // 			{
  // 				condition: {
  // 					maxWidth: 500,
  // 				},
  // 				chartOptions: {
  // 					legend: {
  // 						layout: 'horizontal',
  // 						align: 'left',
  // 						verticalAlign: 'bottom',
  // 					},
  // 				},
  // 			},
  // 		],
  // 	},
  // };

  // useEffect(() => {
  // 	setTimeout(() => {
  // 	setGraphDataState({
  // 		prePoda: exampleData,
  // 		postPoda: exampleData2
  // 	});
  // }, 1000);
  // }, []);

  return (
    <BsRow class='justify-content-between' style={{ marginTop: -20 }}>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          <Typography variant='h5' gutterBottom>
            {filterProp && dashDelete(filterProp.huerto)}
          </Typography>
          {showLoadingState && <LoadingWidget />}
          {!loadingGraphState && graphOptionsState && (
            <HighchartsReact
              highcharts={Highcharts}
              options={graphOptionsState}
              constructorType={'stockChart'}
            />
          )}
        </div>
      </BsCol>
    </BsRow>
  );
};

export default DailyFruit;
