import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import DartIcon from '../../../assets/icons/Vectordardo.png';
import TwigIcon from '../../../assets/icons/Vectorramilla.png';
import FlowerIcon from '../../../assets/icons/Groupflor.png';
import FruitsetIcon from '../../../assets/icons/Capa2cuaja.png';
import PerformanceIcon from '../../../assets/icons/Grouprendimiento.png';
import CaliberIcon from '../../../assets/icons/Vectorcalibre.png';
import Header from '../../../assets/cabecera_print.jpg';
import PrintIcon from '@mui/icons-material/Print';
import { numberFormatDecimals, printOrder } from 'helpers';
import clsx from 'clsx';
import { async } from 'validate.js';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 3),
    width: '70%',
    height: '65vh',
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '95%',
    // backgroundColor: 'yellow',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: 'black',
    fontWeight: 500,
  },
  ulListStyle: {
    '& li': {
      listStyle: 'inside',
      marginBottom: 8,
      fontSize: 15,
    },
  },
  trTableStyle: {
    '& td': {
      verticalAlign: 'middle',
      paddingLeft: '0.20rem',
      paddingRight: '0.20rem',
      textAlign: 'center',
    },
  },
  theadTableStyle: {
    '& th': {
      paddingLeft: '0.20rem',
      paddingRight: '0.20rem',
    },
  },
  bgCellStyle: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontWeight: 700,
  },
}));

const ModalDetailPrint = ({ closeAction, dataModal }) => {
  const classes = useStyles();

  const contentRef = useRef(null);

  const [scenaryDataState, setScenaryDataState] = useState([]);

  console.log('------------', dataModal);

  useEffect(() => {
    if (dataModal) {
      setScenaryDataState(dataModal);
    }
  }, [dataModal]);

  const handlePrint = async () => {
    if (contentRef.current) {
      const content = contentRef.current;

      // const printWindow = window.open('', '_blank');
      // printWindow.document.write(
      //   '<html><title>Print</title><style>body{ background-color: #FFFFFF !important; padding: 10px 20px; }</style>'
      // );
      // printWindow.document.write(
      //   '<link rel="stylesheet"	href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"	integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"/>'
      // );
      // printWindow.document.write('</head><body>');
      // printWindow.document.write(content);
      // printWindow.document.write('</body></html>');
      // printWindow.document.close(); // Cierra el documento para finalizar la carga

      // // Espera a que la ventana cargue completamente antes de activar el evento
      // printWindow.onload = function () {
      //   printWindow.print();
      // };
      html2canvas(content).then((canvas) => {
        const imageURL = canvas.toDataURL('image/png');

        const printWindow = window.open();
        printWindow.document.write(
          '<html><head><title>Print</title></head><body>'
        );
        printWindow.document.write(
          `<img src="${imageURL}" style="width: 100%;" />`
        );
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        printWindow.onload = function () {
          printWindow.print();
        };
      });
    }
  };

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className='mb-2'>
        {/* <Button variant='contained' onClick={handlePrint}>
          Imprimir
        </Button> */}
        <Button
          size='small'
          color='primary'
          startIcon={<PrintIcon />}
          onClick={handlePrint}
        >
          Imprimir
        </Button>
      </div>
      <div ref={contentRef} id='ifmcontentstoprint'>
        <div className={classes.scrollAreaModalStyle}>
          <BsContainer fluid>
            <>
              <BsRow class='mt-2 mb-md-2'>
                <BsCol class='col-md-12 mb-3'>
                  <img src={Header} className='img-fluid w-100' alt='flower' />
                </BsCol>
                <BsCol class='col-md-12 mb-3'>
                  <h5 className='text-center'>
                    CC: {scenaryDataState.cc} | Cuartel:{' '}
                    {scenaryDataState.quarter} | Variedad:{' '}
                    {scenaryDataState.variety} | Portainjerto:{' '}
                    {scenaryDataState.rootStock}
                  </h5>
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Dardos
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ minHeight: '39px' }}
                  >
                    <Grid item>
                      <img
                        src={DartIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.dartObjective,
                              2
                            )
                          : '---'}
                        {' / '}
                        <span style={{ fontSize: 20 }}>
                          {scenaryDataState
                            ? numberFormatDecimals(
                                scenaryDataState.dartPrePoda,
                                2
                              )
                            : '---'}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={clsx('mt-3 mb-2', classes.divider)} />
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Ramillas
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ minHeight: '39px' }}
                  >
                    <Grid item>
                      <img
                        src={TwigIcon}
                        className='img-fluid ico-recomendation'
                        alt='twig'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.twigObjective,
                              2
                            )
                          : '---'}
                        {' / '}
                        <span style={{ fontSize: 20 }}>
                          {scenaryDataState
                            ? numberFormatDecimals(
                                scenaryDataState.twigPrePoda,
                                2
                              )
                            : '---'}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={clsx('mt-3 mb-2', classes.divider)} />
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Yemas/Dardos
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ minHeight: '39px' }}
                  >
                    <Grid item>
                      <img
                        src={FlowerIcon}
                        className='img-fluid ico-recomendation'
                        alt='flower'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState &&
                        scenaryDataState.budDartFinalObjective
                          ? numberFormatDecimals(
                              scenaryDataState.budDartFinalObjective,
                              2
                            )
                          : '---'}
                        {scenaryDataState.budDartFinalObjective !==
                        scenaryDataState.budDart ? (
                          <>
                            {' / '}
                            <span style={{ fontSize: 20 }}>
                              {scenaryDataState
                                ? numberFormatDecimals(
                                    scenaryDataState.budDart,
                                    2
                                  )
                                : '---'}
                            </span>
                          </>
                        ) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={clsx('mt-3 mb-2', classes.divider)} />
                </BsCol>
              </BsRow>
              <BsRow class='mt-md-2 mb-md-2'>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Cuaja
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ minHeight: '39px' }}
                  >
                    <Grid item>
                      <img
                        src={FruitsetIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.fruitSetObjective,
                              2
                            )
                          : '---'}{' '}
                        <Typography
                          component='span'
                          display='inline'
                          variant='h4'
                        >
                          %
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={clsx('mt-3 mb-2', classes.divider)} />
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Calibre
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ minHeight: '39px' }}
                  >
                    <Grid item>
                      <img
                        src={CaliberIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.caliberObjective,
                              2
                            )
                          : '---'}{' '}
                        <Typography
                          display='inline'
                          component='span'
                          variant='h4'
                        >
                          gr/fruto
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={clsx('mt-3 mb-2', classes.divider)} />
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Rendimiento
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ minHeight: '39px' }}
                  >
                    <Grid item>
                      <img
                        src={PerformanceIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.yieldObjective,
                              0
                            )
                          : '---'}{' '}
                        <Typography
                          display='inline'
                          component='span'
                          variant='h4'
                        >
                          kg/ha
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={clsx('mt-3 mb-2', classes.divider)} />
                </BsCol>
              </BsRow>
              <BsRow class='mt-md-2 mb-md-4'>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Frutos/dardo
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item>
                      <img
                        src={DartIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.fruitsDartEstimateObjective,
                              1
                            )
                          : '---'}{' '}
                        {/* <Typography component='span' display='inline' variant='h4'>
                      %
                    </Typography> */}
                      </Typography>
                    </Grid>
                  </Grid>
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Flores/dardo
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item>
                      <img
                        src={DartIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.flowersDartFinalObjective,
                              1
                            )
                          : '---'}{' '}
                        {/* <Typography display='inline' component='span' variant='h4'>
                      gr/fruto
                    </Typography> */}
                      </Typography>
                    </Grid>
                  </Grid>
                </BsCol>
                <BsCol class='col-md-4'>
                  <Typography variant='h5' gutterBottom>
                    Flores
                  </Typography>
                  <Grid
                    direction='row'
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item>
                      <img
                        src={FlowerIcon}
                        className='img-fluid ico-recomendation'
                        alt='dart'
                      />
                    </Grid>
                    <Grid item className='ml-1'>
                      <Typography variant='h3' className={classes.root}>
                        {scenaryDataState
                          ? numberFormatDecimals(
                              scenaryDataState.flowersHaObjective,
                              0
                            )
                          : '---'}{' '}
                        {/* <Typography display='inline' component='span' variant='h4'>
                      
                    </Typography> */}
                      </Typography>
                    </Grid>
                  </Grid>
                </BsCol>
              </BsRow>
            </>
          </BsContainer>
        </div>
      </div>
    </div>
  );
};

export default ModalDetailPrint;
