import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
  Circle,
} from 'google-maps-react';
import _ from 'lodash';
import ReactDOM, { createPortal } from 'react-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  useTheme,
  Tooltip,
  IconButton,
  SvgIcon,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  FormControlLabel,
  Popover,
} from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles, withStyles } from '@mui/styles';
import { CallReceived } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { FiltersContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  dashAdd,
  getCoords,
  getCoordsByGeom,
  getCoordsMulti,
} from '../../../helpers/functionHelpers';
import iconJson from '../../../common/iconSVG.json';
import treeMarker from '../../../assets/planta1.png';
// import JSON_ZONE from './11309_C14_zones.json';

const useStyles = makeStyles((theme) => ({
  infoOrchardStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    padding: '8px 20px',
  },
  infoCuarterStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '& table td': {
      fontSize: 14,
    },
  },
  irButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  overlayButtonNoDrawer: {
    position: 'absolute',
    top: '100px',
    right: '0',
    zIndex: 979,
  },
  overlayButtonLayers: {
    position: 'absolute',
    top: '100px',
    right: '10px',
    zIndex: 979,
  },
  ndviLegendStyle: {
    position: 'absolute',
    top: '60px',
    left: '10px',
    zIndex: 980,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,.9)',
    padding: '5px 20px',
    borderRadius: '5px',
  },
  menuItemStyle: {
    paddingBottom: 0,
    paddingTop: 0,
    width: '100%',
    '& .MuiTypography-body2': {
      color: theme.palette.primary.main,
    },
  },
  layersButtonStyle: {
    // textAlign: 'center',
    // display: 'inline',
    // borderRadius: 25,
    // backgroundColor: theme.palette.secondary.main,
  },
  layersButtonInactiveStyle: {
    // textAlign: 'center',
    // display: 'inline',
    // paddingTop: 10,
    // paddingBottom: 10,
    // paddingLeft: 10,
    // paddingRight: 10,
    // borderRadius: 25,
    // backgroundColor: 'rgba(197,197,196,.9)',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey[500],
  },
}))((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

// const ZONE_COLORS = [
//   '#6bb035',
//   '#f3b10a',
//   '#4a98ee',
//   '#4888a6',
//   '#1e6f1b',
//   '#bbcd2d',
//   '#e423e4',
//   '#2640bd',
//   '#1eed27',
//   '#ed211e',
// ];

const MapComponent = (props) => {
  const MapRef = useRef();

  const theme = useTheme();

  const classes = useStyles();

  let history = useHistory();

  let PolygonRef = [];
  let PolygonZoneRef = [];
  let CircleRef = [];

  const errorHandler = useErrorHandler();

  const IconTree = {
    url: treeMarker, // URL del ícono personalizado
    scaledSize: new window.google.maps.Size(30, 30), // Tamaño del ícono
    origin: new window.google.maps.Point(0, 0), // Origen del ícono
    anchor: new window.google.maps.Point(15, 30), // Punto de anclaje del ícono
  };

  const {
    zonesHandler,
    plantsHandler,
    selectedGrid,
    colorsArray,
    loadingHandler,
    histogramHandler,
    loadingState,
    validData,
    clearValidData,
    clearMapData,
  } = props;

  const axiosContext = useContext(AxiosContext);

  // console.log(theme);
  const [mapState, setMapState] = useState(/** google.maps.Map */ null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [imageBoundsState, setImageBoundsState] = useState(null);
  const [imageOverlayState, setImageOverlayState] = useState(null);

  const [showOverlayState, setShowOverlayState] = useState(false);
  const [overlayState, setOverlayState] = useState(null);

  const [layer1PolysState, setLayer1PolysState] = useState(false);
  const [layer2CirclesState, setLayer2CirclesState] = useState(false);
  const [layer3NdviState, setLayer3NdviState] = useState(false);

  const {
    state: {
      orchard_filter,
      orchard_id_filter,
      userOrchard_filter,
      cc_filter,
      quarter_filter,
      variety_filter,
      rootstock_filter,
      season_filter,
      specie_filter,
      idUnitProductive,
    },
    addFilter,
  } = useContext(FiltersContext);

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showInfoWindowPolygon, setShowInfoWindowPolygon] = useState(false);
  const [showInfoWindowPolygonTooltip, setShowInfoWindowPolygonTooltip] =
    useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [activePolygonState, setActivePolygonState] = useState({});
  const [activePolygonTooltipState, setActivePolygonTooltipState] = useState(
    {}
  );
  const [activeQuarterDataState, setActiveQuarterDataState] = useState(null);
  const [activeQuarterTooltipState, setActiveQuarterTooltipState] =
    useState(null);
  const [activeCircleTooltipState, setActiveCircleTooltipState] = useState({});
  const [activeCircleTooltipDataState, setActiveCircleTooltipDataState] =
    useState(null);
  const [showInfoWindowCircle, setShowInfoWindowCircle] = useState(false);

  const [toggleColorState, setToggleColorState] = useState(false);

  const [polygonFillColorState, setPolygonFillColorState] = useState([
    'rgba(255,255,255,0.1)',
    'rgba(255,255,255,0.9)',
  ]);

  const [quartersState, setQuartersState] = useState([]);

  const [mapInitialLocation, setMapInitialLocation] = useState({
    lat: -34.4142,
    lng: -70.7931,
  });

  const [initialZoom, setInitialZoom] = useState(9);

  // plant1
  const [plant1State, setPlant1State] = useState(null);

  // capas
  const [data_layer1State, setData_layer1State] = useState(null);
  const [data_layer2State, setData_layer2State] = useState(null);
  const [data_layer3State, setData_layer3State] = useState(null);

  const style = {
    width: '100%',
    height: '100%',
  };

  // const setPolygonOptions = (options) => {
  // 	PolygonRef.current.polygon.setOptions(options);
  // };

  // timer query
  const [actualQuarter, setActualQuarter] = useState(null);
  const [lastQuarter, setLastQuarter] = useState(null);
  const [waitingTime, setWaitingTime] = useState(500);

  const [noPolygonUniteState, setNoPolygonUniteState] = useState(false);

  const onPolygonClick = (props, marker, e, quarter, allPolygons) => {
    if (!checked.length) {
      console.log(checked, '-----checked');

      if (PolygonRef[0]) {
        for (let i = 0; i < quartersState.length; i++) {
          PolygonRef[i].polygon.setOptions({
            fillColor: polygonFillColorState[0],
          });
        }
      }

      // console.log(quarter, "<-------------------llega el quarter")

      marker.setOptions({ fillColor: polygonFillColorState[1] });

      setToggleColorState(!toggleColorState);

      setActivePolygonState(e.latLng);
      setActiveQuarterDataState(quarter);
      setShowInfoWindowPolygon(true);
      setShowInfoWindowPolygonTooltip(false);
      setActiveMarker({});
    }
  };

  const onMapClicked = (props) => {
    if (showInfoWindowCircle) {
      setShowInfoWindowCircle(false);
      setActiveCircleTooltipDataState(null);
    }

    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
    if (showInfoWindowPolygon) {
      for (let i = 0; i < quartersState.length; i++) {
        PolygonRef[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
      }
      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }
  };

  const onInfoWindowClose = (props) => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }

    if (showInfoWindowPolygon) {
      if (PolygonRef[0]) {
        for (let i = 0; i < quartersState.length; i++) {
          PolygonRef[i].polygon.setOptions({
            fillColor: polygonFillColorState[0],
          });
        }
      }
      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }

    if (showInfoWindowPolygonTooltip) {
      setShowInfoWindowPolygonTooltip(false);
      setActiveMarker({});
    }
  };

  const _onZoomChanged = (e) => {
    // console.log(e, MapRef.current.map.zoom);
    setInitialZoom(MapRef.current.map.zoom);
  };

  const _onCenterChanged = (mapProps, map) => {
    setMapInitialLocation({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  // 1- CUANDO RECIBE NUEVO ORCHARD / CC / QUARTER / VARIETY / ROOTSTOCK
  // falta agregar el idUnitProductive
  useEffect(() => {
    if (
      orchard_filter &&
      quarter_filter &&
      variety_filter &&
      idUnitProductive
    ) {
      closeAllWindows();

      setNoPolygonUniteState(false);

      console.log(
        '--los contextos en el mapa',
        cc_filter,
        quarter_filter,
        variety_filter,
        rootstock_filter,
        idUnitProductive
      );

      // 2- FILTRA EL ORCHARD SELECCIONADO
      const result = userOrchard_filter.find(
        (ele) => ele.value === orchard_filter
      );
      // console.log(result, '---------result orchard');
      // obtiene plant1 del huerto
      const plant1Orchard = result.plant1 || [];

      console.log(plant1Orchard, '---------plant1Orchard', idUnitProductive);

      // find de plant1Orchard con idUnitProductive
      const plant1Quarter = plant1Orchard.find(
        (ele) => ele.idUnitProductive === idUnitProductive
      );

      console.log(plant1Quarter, '---------plant1Quarter');

      if (plant1Quarter?.geom) {
        setPlant1State(plant1Quarter);
      } else {
        setPlant1State(null);
      }

      // 3- OBTIENE LOS POLIGONOS DEL ORCHARD SELECCIONADO
      const areaOrchard = result.polygon ? result.polygon : null;

      // aca debe quedarse con un quarter, el selected

      // 4- FILTRA EL POLIGONO DEL CUARTEL SELECCIONADO, SE QUEDA CON 1
      // falta el idUnitProductive, podría usar solo eso
      // 4.5 - FILTRA EL POLIGONO DEL IDUNITE DEL CONTEXT, SE QUEDA CON 1
      const quarterPolygonSelected = areaOrchard
        .filter((ele) => ele.idUnitProductive === idUnitProductive)
        .slice(0, 1);

      // console.log(
      //   '---------ESTE ES EL POLIGONO SELECCIONADO POR CONTEXTO',
      //   quarterPolygonSelected
      // );

      if (quarterPolygonSelected.length === 0) {
        setNoPolygonUniteState(true);
      }

      // 5- SETEA El CUARTEL SELECCIONADO

      setQuartersState(quarterPolygonSelected || []);

      handleChangeQuarter(quarterPolygonSelected || '');

      // 6- LIMPIA LOS CHECKBOX
      setChecked([]);

      const bounds = new props.google.maps.LatLngBounds();

      // console.log(quarterPolygonSelected, '--------quarterPolygonSelected');

      // 7- CENTRA EL MAPA EN EL POLIGONO SELECCIONADO

      const arrayCoords =
        quarterPolygonSelected && quarterPolygonSelected.length
          ? getCoordsByGeom(quarterPolygonSelected[0]?.geom)
          : [];

      // console.log(_.flatten(arrayCoords), '-----arrayCoords');

      // TODO: no mapa, sin poligono, no hay poligono con esos datos unidad

      const flattenArray = _.flatten(arrayCoords);

      for (let i = 0; i < flattenArray.length; i++) {
        // console.log(flattenArray[i], '-----bounds----');
        bounds.extend(flattenArray[i]);
      }

      setMapInitialLocation(bounds.getCenter());
      if (mapState) mapState.fitBounds(bounds);
    } else {
      setQuartersState([]);
    }
  }, [
    orchard_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    idUnitProductive,
    mapState,
  ]);

  const _mapLoaded = (mapProps, map) => {
    const { google } = mapProps;
    setMapState(map);
    map.setOptions({
      mapTypeId: 'hybrid',
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      fullscreenControl: true,
      disableDefaultUI: false,
    });

    google.maps.event.addListener(map, 'bounds_changed', onBoundsChanged);

    function onBoundsChanged() {
      if (
        map.getDiv().firstChild.clientHeight === window.innerHeight &&
        map.getDiv().firstChild.clientWidth === window.innerWidth
      ) {
        // console.log('FULL SCREEN');
        setIsFullScreen(true);
      } else {
        // console.log('NOT FULL SCREEN');
        setIsFullScreen(false);
      }
    }
  };

  const closeToolTip = () => {
    setShowInfoWindowPolygonTooltip(false);
  };

  // overlay

  useEffect(() => {
    let historicalOverlay = props.google.maps.GroundOverlay;

    if (showOverlayState) {
      // console.log('cuando es true', mapState);

      const overlayOpts = {
        opacity: 0.7,
      };

      if (imageOverlayState) {
        historicalOverlay = new props.google.maps.GroundOverlay(
          imageOverlayState,
          imageBoundsState,
          overlayOpts
        );
        setOverlayState(historicalOverlay);
        historicalOverlay.setMap(mapState);
      }
      // mapState.fitBounds(imageBoundsState);
      // centrar los límites del mapa a la imagen
    } else {
      if (mapState) {
        // console.log('cuando es false');
        overlayState.setMap(null);
      }
    }
  }, [showOverlayState]);

  // cuando está la data NDVI configura
  useEffect(() => {
    if (data_layer3State) {
      setImageOverlayState(data_layer3State?.image || null);
      // debe fijar los límites igual setImageBoundsState();

      // console.log(data_layer3State, '-----data_layer3State');

      const imageBounds =
        data_layer3State?.bounds && data_layer3State?.bounds.length
          ? {
              north: data_layer3State?.bounds[3] || '',
              south: data_layer3State?.bounds[1] || '',
              east: data_layer3State?.bounds[2] || '',
              west: data_layer3State?.bounds[0] || '',
            }
          : null;

      setImageBoundsState(imageBounds);
    }
  }, [data_layer3State]);

  // tooltip Circle
  const onCircleClick = (props, marker, e, circle) => {
    // console.log(props, marker, e, circle, '----circle click');
    setActiveCircleTooltipState(e.latLng);
    setActiveCircleTooltipDataState(circle);
    setShowInfoWindowCircle(true);
  };

  const onPlantInfoWindowClose = (props) => {
    // console.log('sale del circle');
    setActiveCircleTooltipDataState(null);
    setShowInfoWindowCircle(false);
  };

  // polígonos del cuartel
  const polys =
    quartersState &&
    quartersState.map((quarter, index) => {
      // TODO: revisar casos de Multi
      // console.log(
      //   '-----------------------------polys',
      //   getCoordsByGeom(quarter.geom)
      // );
      // console.log(
      //   '-----------------------------polys2',
      //   getCoordsByGeom(quarter.geom)
      // );
      return (
        <Polygon
          key={`${quarter.centro_costo}_${index}`}
          // ref={setRef(`cuarter_${index}`)}
          ref={(ref) => (PolygonRef[index] = ref)}
          paths={getCoordsByGeom(quarter.geom)}
          // onClick={(props, marker, e) =>
          //   !checked.length ? onPolygonClick(props, marker, e, quarter) : {}
          // }
          id={`${quarter.orchard}_${quarter.cc}_${quarter.quarter}`}
          onMouseout={closeToolTip}
          strokeColor='#FFFFFF'
          strokeOpacity={1}
          strokeWeight={2}
          // fillColor={quarter.color}
          fillOpacity={1}
          options={{
            fillColor: polygonFillColorState[0],
          }}
        />
      );
    });

  // función que recorre las zonas como llegan,y las convierte en un array de ZONAS
  const renderZones = () => {
    const allZonePolygon = [];
    let zonePolygon;
    if (layer1PolysState) {
      for (let index = 0; index < layer1PolysState.length; index++) {
        const zone = layer1PolysState[index];

        // console.log(zone, '--------zona');

        // TODO: revisar si es multiPolygon, si es hace lo actual
        // si es polygon, debería devolver directo el geom.coordinates

        if (zone.geom.type === 'MultiPolygon') {
          // console.log(zone.geom.type, 'zona con multipolygon', zone);
          zonePolygon = zone.geom.coordinates.map((subZone, i) => {
            // console.log(subZone, '<------sub zone');
            return {
              zone: zone.zone,
              subZone: subZone,
              color: zone.zoneColor,
              areaHa: zone.areaHa,
              percentArea: zone.percentArea,
            };
          });

          allZonePolygon.push(...zonePolygon);
        } else {
          // console.log('zona con polygon simple', zone);
          zonePolygon = [
            {
              zone: zone.zone,
              subZone: zone.geom.coordinates,
              color: zone.zoneColor,
              areaHa: zone.areaHa,
              percentArea: zone.percentArea,
            },
          ];

          allZonePolygon.push(...zonePolygon);
        }
      }
    }
    return allZonePolygon;
  };

  // variable que se renderiza en el mapa de Zonas,
  // recorre el array de zonas entregado por renderZones
  const layer1Polys =
    layer1PolysState &&
    renderZones(layer1PolysState).map((area, index) => {
      // console.log(
      //   area,
      //   '-----------------------------cada area de la zona',
      //   '---',
      //   area.subZone.length
      // );
      return (
        <Polygon
          key={`${area.zone}_${index}`}
          // ref={setRef(`cuarter_${index}`)}
          ref={(ref) => (PolygonZoneRef[index] = ref)}
          paths={getCoordsMulti(area.subZone)}
          strokeColor='#FFFFFF'
          title={area.zone}
          strokeOpacity={0.8}
          strokeWeight={1}
          // fillColor={quarter.color}
          fillOpacity={1}
          options={{
            fillColor: area.color,
          }}
        />
      );
    });

  // console.log(layer1PolysState, '--------');

  // console.log(renderZones(), '-------zones');

  // variable que renderia los círculos del mapa, plantas
  const layer2Circles =
    layer2CirclesState &&
    layer2CirclesState.map((circle, index) => {
      // console.log(
      //   circle.geom.coordinates[0] ? circle.geom.coordinates[0] : 'NO',
      //   '-----'
      // );

      // solo imprime plantas con coordenadas
      if (circle.geom.coordinates.length) {
        if (
          !circle.geom.coordinates[0].includes(null) &&
          circle.geom.coordinates[0]
        ) {
          // console.log('circle zona', circle, colorsArray);
          return (
            <Circle
              key={`circ_${index}`}
              radius={2}
              title={circle.plantNumber}
              // onClick={(props, marker, e) =>
              //   onCircleClick(props, marker, e, circle)
              // }
              onMouseover={(props, marker, e) =>
                onCircleClick(props, marker, e, circle)
              }
              onMouseout={() => onPlantInfoWindowClose()}
              // ref={setRef(`cuarter_${index}`)}
              ref={(ref) => (CircleRef[index] = ref)}
              center={getCoords(circle.geom.coordinates)[0]}
              // center={circle}
              strokeColor='#FFFFFF'
              strokeOpacity={1}
              strokeWeight={1}
              // fillColor={quarter.color}
              fillOpacity={1}
              fillColor={colorsArray[circle.zone - 1]}
              zIndex={100}
            />
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    });

  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (checked.includes(0)) {
      setLayer1PolysState(data_layer1State);
    } else {
      setLayer1PolysState(null);
    }
    if (checked.includes(1)) {
      setLayer2CirclesState(data_layer2State);
    } else {
      setLayer2CirclesState(null);
    }
    if (checked.includes(2)) {
      // console.log(data_layer3State, '---data_layer3State');
      if (data_layer3State.image) {
        setShowOverlayState(true);
        setLayer3NdviState(data_layer3State);
      }
    } else {
      setLayer3NdviState(null);
      setShowOverlayState(false);
    }
  }, [checked]);

  // console.log(checked, '<------------------');

  // useEffect(() => {
  // 	if(quartersState){
  // 		console.log("se ejecuta el endpoint");
  // 		searchDataHandler(quartersState)

  // 	}
  // }, [quartersState]);

  useEffect(() => {
    if (clearMapData) {
      setData_layer1State(null);
      setData_layer2State(null);
      setData_layer3State(null);
      setLayer3NdviState(null);
      setShowInfoWindowCircle(false);
    }
  }, [clearMapData]);

  const searchDataHandler = async (quarter) => {
    // 1- LIMPIA LOS DATOS DE SAMPLING - PLANTS - ZONES

    setData_layer1State(null);
    setData_layer2State(null);
    loadingHandler(1);
    // llama función para ejecutar la consulta
    // y establecer los resultados en el estado
    // console.log(quarter, '-----que va a la query---');

    // no está usando el idUnitProductive
    try {
      const params = {
        orchardValue: orchard_filter,
        ccValue: cc_filter,
        quarterValue: quarter_filter,
        varietyValue: variety_filter,
        rootStockValue: rootstock_filter,
        specieValue: specie_filter,
      };

      // console.log(params, '--------params a sampling');

      const response = await axiosContext.authAxios.get('v1/sampling', {
        params: params,
      });

      // console.log(response, '------response', params);

      const { data, status } = response;

      if (status === 200) {
        // console.log(
        //   data.tableSamplingRecomendation,
        //   '--------------------------------zones'
        // );
        // 2- RECIBE LAS ZONAS Y LAS ESTABLECE EN EL ESTADO
        const zones = data.tableSamplingRecomendation;
        // const zone_pruebas = JSON_ZONE;
        // console.log([zone_pruebas[0]], '-------zone_pruebas');

        // console.log(data.treeWorkGrid, '---------treework');

        setData_layer1State(zones);

        // 3- PASA LAS ZONAS A LA FUNCION DE ZONAS

        console.log('data de zonas y color', data.tableSamplingRecomendation);

        // crear una constante con un sort de las zonas por zone

        const zonesOrdered =
          data?.tableSamplingRecomendation &&
          data?.tableSamplingRecomendation.length
            ? data?.tableSamplingRecomendation.sort((a, b) => a.zone - b.zone)
            : [];

        zonesHandler(zonesOrdered);

        // 4- RECIBE LA GRILLA DE PLANTAS Y LA ESTABLECE EN EL ESTADO
        const resultGrid = data?.treeWorkGrid;

        // console.log(resultGrid, '-------resultGrid', data);

        setData_layer2State(resultGrid);

        // 5- PASA LA GRILLA DE PLANTAS A LA FUNCION DE PLANTAS, Y DATOS PARA VALIDACIONES
        plantsHandler(
          resultGrid,
          data?.statusLock,
          data?.plantsHa,
          data?.totalPlantPorcentage,
          data?.hectare,
          data?.totalPlants,
          data?.tableSamplingRecomendation
        );

        // 6- PASA EL HISTOGRAMA A LA FUNCION DE HISTOGRAMA
        histogramHandler(data?.histogram);
        // const asiLlegan = [
        //   -71.0040078666763321, -34.2502465006818184, -71.0012230892955643,
        //   -34.2480905439999361,
        // ];

        // 7- PASA EL NDVI AL ESTADO DE NDVI
        setData_layer3State({
          image: data?.ndvi,
          bounds: data?.ndviBounds,
        });
      } else if (status === 204) {
        plantsHandler([], false, 0, 0, 0, 0);
        zonesHandler([]);
      }
    } catch (error) {
      plantsHandler([], false, 0, 0, 0, 0);
      zonesHandler([]);
      // errorHandler(error);
    } finally {
      loadingHandler(0);
    }
  };

  useEffect(() => {
    if (selectedGrid) {
      if (checked.includes(1)) {
        // si está ON, actualiza mapa directo
        setLayer2CirclesState(selectedGrid);
      }
      setData_layer2State(selectedGrid);
    }
  }, [selectedGrid]);

  // EL MAPA EJECUTA ESTO AL DEFINIR EL CUARTEL ACTUAL
  const handleChangeQuarter = (quarter) => {
    // podría llevar el idUnitProductive
    const quarterIdentifier = {
      cc: quarter[0]?.cc,
      quarter: quarter[0]?.quarter,
      variety: quarter[0]?.variety,
      rootStock: quarter[0]?.rootStock,
    };

    // 1- SETEA EL CUARTEL ACTUAL
    setActualQuarter(quarterIdentifier);
    setWaitingTime(500);

    // 2- CANCELA EL TIEMPO DE ESPERA ANTERIOR
    if (waitingTime !== 500) {
      clearTimeout(waitingTime);
    }
  };

  // UFE QUE CONTROLA LA LLAMADA A LA QUERY CON UN DEBOUNCE
  // EVITA LLAMADAS REPETIDAS
  // last quarter podría ser un REF para evitar que renderice
  useEffect(() => {
    const timer = setTimeout(() => {
      // 1- SI HAY UN CUARTEL ACTUAL
      if (actualQuarter) {
        // 2- SI EL CUARTEL ACTUAL ES DIFERENTE AL ANTERIOR
        if (
          actualQuarter.cc !== lastQuarter?.cc ||
          actualQuarter.quarter !== lastQuarter?.quarter ||
          actualQuarter.variety !== lastQuarter?.variety
        ) {
          // 3- LIMPIA LOS DATOS DE SAMPLING

          clearValidData();

          // 4- EJECUTA LA CONSULTA CON EL CUARTEL ACTUAL
          searchDataHandler(actualQuarter);

          // 5- SETEA EL CUARTEL ACTUAL COMO EL ÚLTIMO CUARTEL

          const quarterIdentifier = {
            cc: actualQuarter?.cc,
            quarter: actualQuarter?.quarter,
            variety: actualQuarter?.variety,
            rootStock: actualQuarter?.rootStock,
          };

          // console.log(quarterIdentifier, '---fija el last');

          setLastQuarter(quarterIdentifier);
        }
      }
      setWaitingTime(300);
    }, waitingTime);

    // Limpiar el temporizador anterior si hay uno
    return () => clearTimeout(timer);
  }, [actualQuarter, lastQuarter, waitingTime]);

  // console.log(!!data_layer1State, 'estado para el disabled', data_layer1State);

  // const activeLayers = !!data_layer1State?.length;
  let activeLayers = validData;

  const closeAllWindows = () => {
    // console.log('cierra las ventanas');
    setShowInfoWindowCircle(false);
  };

  // CREAR EL CONTROLADOR DE CAPAS
  const CustomMapControl = ({
    position = window.google.maps.ControlPosition.TOP_LEFT,
    children,
  }) => {
    const controlDiv2 = document.createElement('div');

    useEffect(() => {
      let controls;
      let index;
      if (MapRef.current) {
        controls = MapRef.current.map.controls[position];
        index = controls.length;
        controls.push(controlDiv2);
      }

      return () => {
        controls.removeAt(index);
      };
    }, []);

    return createPortal(
      <div
        style={{
          zIndex: 1200,
          marginTop: 20,
          width: '120px',
          backgroundColor: 'rgba(255,255,255,.8)',
          padding: '2px 10px',
          borderRadius: '4px',
        }}
      >
        {children}
      </div>,
      controlDiv2
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {/* boton de capas */}
      <div className={classes.overlayButtonLayers}>
        <LightTooltip title='Capas' aria-label='layers' placement='left' arrow>
          <span>
            <IconButton
              aria-label='layers'
              onClick={handleClick}
              disabled={!activeLayers || loadingState}
              // TODO: PROBAR
              sx={{
                backgroundColor: activeLayers
                  ? theme.palette.secondary.main
                  : theme.palette.grey[500],
                '&:hover': {
                  backgroundColor: activeLayers
                    ? theme.palette.secondary.dark
                    : theme.palette.grey[500],
                },
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SvgIcon
                  viewBox='0 -1 18 22'
                  style={{
                    color: 'white',
                  }}
                >
                  <path
                    // fillRule="evenodd"
                    clipRule='evenodd'
                    d={iconJson.icon.layer}
                  />
                </SvgIcon>
              </div>
            </IconButton>
          </span>
        </LightTooltip>
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            key={2}
            role={undefined}
            dense
            selected={checked.indexOf(2) !== -1}
            component={Button}
            className={classes.menuItemStyle}
            onClick={handleToggle(2)}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={checked.indexOf(2) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': 'checkbox-list-label-1',
                }}
              />
            </ListItemIcon>
            <ListItemText primary='NDVI' />
          </MenuItem>
          <MenuItem
            key={0}
            role={undefined}
            dense={true}
            selected={checked.indexOf(0) !== -1}
            component={Button}
            className={classes.menuItemStyle}
            onClick={handleToggle(0)}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={checked.indexOf(0) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': 'checkbox-list-label-0',
                }}
              />
            </ListItemIcon>
            <ListItemText primary='Zonas' />
          </MenuItem>
          <MenuItem
            key={1}
            role={undefined}
            dense
            selected={checked.indexOf(1) !== -1}
            component={Button}
            className={classes.menuItemStyle}
            onClick={handleToggle(1)}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={checked.indexOf(1) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': 'checkbox-list-label-1',
                }}
              />
            </ListItemIcon>
            <ListItemText primary='Plantas' />
          </MenuItem>
        </StyledMenu>
      </div>

      {/* {showOverlayState ? (
        <div className={classes.ndviLegendStyle}>
          <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
            100
          </Typography>
          <img
            src='http://downloads2.esri.com/MappingCenter2007/blog/Apr12Images/IBP_TheHorizontalLegend.png'
            alt='legend'
            width='100'
          />
          <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
            100
          </Typography>
        </div>
      ) : null} */}

      <Map
        style={style}
        google={props.google}
        onClick={onMapClicked}
        onZoomChanged={_onZoomChanged}
        onDragend={_onCenterChanged}
        // onCenterChanged={_onCenterChanged}
        initialCenter={mapInitialLocation}
        center={mapInitialLocation}
        zoom={initialZoom}
        ref={MapRef}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      >
        {isFullScreen && validData && (
          <CustomMapControl
            position={props.google.maps.ControlPosition.RIGHT_CENTER}
          >
            <FormControlLabel
              sx={{
                color: 'white',
              }}
              control={
                <Checkbox
                  checked={checked.indexOf(2) !== -1}
                  onChange={handleToggle(2)}
                />
              }
              label='NDVI'
            />
            <FormControlLabel
              sx={{
                color: 'white',
              }}
              control={
                <Checkbox
                  checked={checked.indexOf(0) !== -1}
                  onChange={handleToggle(0)}
                />
              }
              label='Zonas'
            />
            <FormControlLabel
              sx={{
                color: 'white',
              }}
              control={
                <Checkbox
                  checked={checked.indexOf(1) !== -1}
                  onChange={handleToggle(1)}
                />
              }
              label='Plantas'
            />
          </CustomMapControl>
        )}
        {/* TOOLTIP */}
        {activeCircleTooltipDataState && (
          <InfoWindow
            position={activeCircleTooltipState}
            visible={showInfoWindowCircle}
            onClose={onPlantInfoWindowClose}
          >
            <div className={classes.infoCuarterStyle}>
              {/* <p>
                <b>Nº Fila:</b> {activeCircleTooltipDataState.rowNumber}
              </p>
              <p>
                <b>Nº Planta:</b> {activeCircleTooltipDataState.plantNumber}
              </p> */}
              <table className='table table-sm table-borderless'>
                <tr>
                  <td>Nº Hilera:</td>
                  <td style={{ fontWeight: 700 }}>
                    {activeCircleTooltipDataState?.rowNumber || '-'}
                  </td>
                </tr>
                <tr>
                  <td>Nº Planta:</td>
                  <td style={{ fontWeight: 700 }}>
                    {activeCircleTooltipDataState?.plantNumber || '-'}
                  </td>
                </tr>
              </table>
            </div>
          </InfoWindow>
        )}

        {layer2Circles}
        {layer1Polys}
        {polys}
        {/* {renderZones()} */}
        {plant1State &&
          plant1State.geom &&
          plant1State.geom.coordinates &&
          plant1State.geom.coordinates.length && (
            <Marker
              key={plant1State.idUnitProductive}
              name={plant1State.idUnitProductive}
              position={getCoords([plant1State.geom.coordinates])[0]}
              icon={IconTree}
            />
          )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  language: 'es',
  // apiKey: 'AIzaSyCgDjp2D44L-NQ5okVOSlCXmBwtkScGmLM',
  apiKey: 'AIzaSyBnXQDrdvbXPg0GjUdQzC_-v0ZMa3q5U9k',
})(MapComponent);
