import React, { useEffect, useReducer } from 'react';
import { IntelligenceContext } from './IntelligenceContext';
import { intelligenceReducer } from './intelligenceReducer';

const INITIAL_STATE = {
	isSet: false,
	model_value: null,
	fruitSet_value: null,
	performance_value: null,
	caliber_value: null,
	dart_value: null,
	twig_value: null,
	flower_value: null,
	fruitsDart_value: null,
	fruitSet_estimated: null,
	performance_estimated: null,
	caliber_estimated: null,
	dart_estimated: null,
	twig_estimated: null,
	flower_estimated: null,
};

export const IntelligenceProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		intelligenceReducer,
		INITIAL_STATE,
		() => {
			// const localState = localStorage.getItem('@session'); //se mantiene aunque cierre el navegador
			const localState = window.sessionStorage.getItem('@intelligence'); // se limpia cuando cierra nevagador
			return localState ? JSON.parse(localState) : INITIAL_STATE;
		}
	);

	const addValues = (values) => {
		dispatch({ type: 'add_values', payload: values });
	};

	const resetValues = () => {
		dispatch({ type: 'reset_values', payload: INITIAL_STATE });
	};

	const notSetValues = () => {
		dispatch({ type: 'not_set_values' });
	};

	useEffect(() => {
		// localStorage.setItem('@session', JSON.stringify(state));
		window.sessionStorage.setItem('@intelligence', JSON.stringify(state));
	}, [state]);

	return (
		<IntelligenceContext.Provider
			value={{
				state,
				addValues,
				resetValues,
				notSetValues,
			}}
		>
			{children}
		</IntelligenceContext.Provider>
	);
};
