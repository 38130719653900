import _ from 'lodash';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Divider, Typography, useTheme } from '@mui/material';
// import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import InfoIcon from '@mui/icons-material/Info';
import { useErrorHandler } from 'react-error-boundary';
import HeatmapLegend from '../../../assets/leyenda_heatmap2.png';
import { BsRow, BsCol } from '../../../layouts/components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from '../../../context/AxiosContext';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import {
  dashAdd,
  dashDelete,
  findCcByIdUnitProductive,
  findQuarterByIdUnitProductive,
  getOnlyValidCcs,
  getOnlyValidOrchardCcQuarters,
  getSeasonActiveQuarters,
  numberFormat,
  numberFormatDecimals,
  // alterArrayGetIds,
  // numberFormat,
  // dashDelete,
  // numberFormatDecimals,
  numberFormatDecimalsCounting,
  setOrderForApi,
} from '../../../helpers';
import { SimpleIconButton } from '../../../components';
import clsx from 'clsx';
import { Map } from '../../../containers/Counting';

const getMinMax = (array) => {
  // Obtener los valores de dart y twig
  const darts = array.map((item) => item.dart);
  const twigs = array.map((item) => item.twig);
  const fruits = array.map((item) => item.totalCounts);

  // Calcular el valor máximo y mínimo de dart
  const maxDart = Math.max(...darts);
  const minDart = Math.min(...darts);

  // Calcular el valor máximo y mínimo de twig
  const maxTwig = Math.max(...twigs);
  const minTwig = Math.min(...twigs);

  // Calcular el valor máximo y mínimo de fruit
  const maxFruit = Math.max(...fruits);
  const minFruit = Math.min(...fruits);

  return {
    maxDart,
    minDart,
    maxTwig,
    minTwig,
    maxFruit,
    minFruit,
  };
};

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      color: theme.palette.tertiary.main,
      fontWeight: 500,
    },
    divider: {
      background: theme.palette.grey[400],
      marginTop: 10,
    },
    rootHelper: {
      fontSize: 17,
    },
    inputText: {
      fontSize: 26,
    },
    cardContentRoot: {
      padding: 15,
      [theme.breakpoints.up('lg')]: {
        '& div.col-xl-9': {
          width: '78%',
          maxWidth: '78%',
          flex: '0 0 78%',
        },
        '& div.col-xl-3': {
          width: '22%',
          maxWidth: '22%',
          flex: '0 0 22%',
        },
      },
    },
    mapContainer: {
      minHeight: '78vh',
      width: '100%',
      position: 'relative',
    },
    tableStyles: {
      marginTop: 5,
      '& td': {
        padding: '0.45rem',
        fontSize: 14,
      },
    },
    infoCol: {
      fontWeight: 500,
    },
    theadLight: {
      '& th': {
        // color: '#495057',
        backgroundColor: theme.palette.grey[200],
        borderColor: theme.palette.grey[300],
      },
    },
    colorBox: {
      width: '80px',
      height: '35px',
    },
    tbodyStyle: {
      '& td': {
        verticalAlign: 'middle',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrowsContainerStyle: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    subtitleDash: {
      fontSize: 18,
    },
    paperContainerStyle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // backgroundColor: theme.palette.grey[100],
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      '& h6': {
        color: theme.palette.primary.contrastText,
      },
      '& h2': {
        color: theme.palette.primary.contrastText,
      },
    },
    bgCellStyle: {
      backgroundColor: theme.palette.primary.main + '!important',
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      fontWeight: 700,
    },
    fullWidthStyle: {
      width: '100%',
    },
    tableMaxMinStyle: {
      marginBottom: 0 + '!important',
      '& td': {
        fontSize: 14,
      },
    },
  };
});

const endPointName = 'v1/counter/plant';

const PlantHeatMap = ({ filter: filterProp }) => {
  const theme = useTheme();

  const { userOrchard } = useContext(Context);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const axiosContext = useContext(AxiosContext);

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    season_filter,
    variety_filter,
    rootstock_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  // console.log(filterState, '-------');

  // console.log(theme);
  const [infoHeatmapState, setInfoHeatmapState] = useState(null);
  // const [plantsSelectedGridState, setPlantsSelectedGridState] = useState(null);
  const [colorsArrayState, setColorsArrayState] = useState([]);
  const [loadingSamplingState, setLoadingSamplingState] = useState(false);
  const [polygonActualIndexState, setPolygonActualIndexState] = useState(null);
  const [availablePolygonsState, setAvailablePolygonsState] = useState([]);
  const [orchardMaxIndexState, setOrchardMaxIndexState] = useState(null);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);
  const [ccSelectedState, setCcSelectedState] = useState(null);
  const [quarterSelectedState, setQuarterSelectedState] = useState(null);
  const [varietySelectedState, setVarietySelectedState] = useState(null);
  const [idUnitProductiveState, setIdUnitProductiveState] = useState(null);

  const [minMaxState, setMinMaxState] = useState(null);

  const [orchardWithNoValidPolygons, setOrchardWithNoValidPolygons] =
    useState(false);

  const [counterTypeState, setCounterTypeState] = useState(null);

  const [filterQuarterToMapState, setFilterQuarterToMapState] = useState(null);

  const { classes } = useStyles();

  const errorHandler = useErrorHandler();

  const getMinMaxValues = (data) => {
    const mixMaxData = data ? getMinMax(data) : null;

    setMinMaxState(mixMaxData);
  };

  const plantsHandler = (plants) => {
    setInfoHeatmapState(plants);

    console.log(plants, '---------plants');
    if (plants) {
      getMinMaxValues(plants.plants);
    }
  };

  const onServerLoading = (type) => {
    // console.log(type, '---', !!type);
    setLoadingSamplingState(!!type);
  };

  const handleNextQuarter = () => {
    setMinMaxState(null);
    let isQuarterSelected;

    isQuarterSelected = (element) =>
      element.idUnitProductive === idUnitProductiveState;

    const actualIndex = availablePolygonsState.findIndex(isQuarterSelected);

    // const isQuarterSelected = (element) =>
    //   element.cc === ccSelectedState.value &&
    //   element.quarter === quarterSelectedState.value &&
    //   element.variety === varietySelectedState.value;

    // const actualIndex = availablePolygonsState.findIndex(isQuarterSelected);
    console.log(actualIndex, '---actualIndex', orchardMaxIndexState);

    if (actualIndex < orchardMaxIndexState) {
      setPolygonActualIndexState(actualIndex + 1);

      const nextQuarter = availablePolygonsState[actualIndex + 1];

      // cambiar el contexto y cambiar lo seleccionado
      addFilter({
        cc_filter: nextQuarter.cc,
        quarter_filter: nextQuarter.quarter,
        variety_filter: dashAdd(nextQuarter.variety),
        rootstock_filter: dashAdd(nextQuarter.rootStock),
        idUnitProductive: nextQuarter.idUnitProductive,
      });

      const selectedCcById = findCcByIdUnitProductive(
        orchardSelectedState.cc,
        nextQuarter.idUnitProductive
      );

      setCcSelectedState(selectedCcById);

      const selectedQuarterById = findQuarterByIdUnitProductive(
        selectedCcById.quarter,
        nextQuarter.idUnitProductive
      );

      setQuarterSelectedState(selectedQuarterById);

      const varietySelectedArray = selectedQuarterById.variety.filter(
        (ele) => ele.idUnitProductive === nextQuarter.idUnitProductive
      );

      setVarietySelectedState(varietySelectedArray[0]);

      setIdUnitProductiveState(nextQuarter.idUnitProductive);

      const objToFilter = {
        orchardMapFilter: orchardSelectedState.value,
        ccMapFilter: selectedCcById.value,
        quarterMapFilter: selectedQuarterById.value,
        varietyMapFilter: varietySelectedArray[0].value,
        rootStockMapFilter: varietySelectedArray[0].rootStock,
        idUnitProductiveFilter: nextQuarter?.idUnitProductive,
        counterTypeMapFilter: counterTypeState,
      };

      // console.log(objToFilter, '----asi va al mapa');

      setFilterQuarterToMapState(objToFilter);
    }
  };

  const handleBackQuarter = () => {
    setMinMaxState(null);
    let isQuarterSelected;

    isQuarterSelected = (element) =>
      element.idUnitProductive === idUnitProductiveState;

    const actualIndex = availablePolygonsState.findIndex(isQuarterSelected);

    if (actualIndex >= 0) {
      setPolygonActualIndexState(actualIndex - 1);

      const previusQuarter = availablePolygonsState[actualIndex - 1];

      addFilter({
        cc_filter: previusQuarter.cc,
        quarter_filter: previusQuarter.quarter,
        variety_filter: dashAdd(previusQuarter.variety),
        rootstock_filter: dashAdd(previusQuarter.rootStock),
        idUnitProductive: previusQuarter.idUnitProductive,
      });

      const selectedCcById = findCcByIdUnitProductive(
        orchardSelectedState.cc,
        previusQuarter.idUnitProductive
      );

      setCcSelectedState(selectedCcById);

      const selectedQuarterById = findQuarterByIdUnitProductive(
        selectedCcById.quarter,
        previusQuarter.idUnitProductive
      );

      setQuarterSelectedState(selectedQuarterById);

      const varietySelectedArray = selectedQuarterById.variety.filter(
        (ele) => ele.idUnitProductive === previusQuarter.idUnitProductive
      );

      setVarietySelectedState(varietySelectedArray[0]);

      setIdUnitProductiveState(previusQuarter?.idUnitProductive);

      const objToFilter = {
        orchardMapFilter: orchardSelectedState.value,
        ccMapFilter: selectedCcById.value,
        quarterMapFilter: selectedQuarterById.value,
        varietyMapFilter: varietySelectedArray[0].value,
        rootStockMapFilter: varietySelectedArray[0].rootStock,
        idUnitProductiveFilter: previusQuarter?.idUnitProductive,
        counterTypeMapFilter: counterTypeState,
      };

      // console.log(objToFilter, '----asi va al mapa');

      setFilterQuarterToMapState(objToFilter);
    }
  };

  useEffect(() => {
    if (filterProp) {
      // console.log(filterProp, '---filterProp');

      setOrchardWithNoValidPolygons(false);
      setMinMaxState(null);

      const {
        orchard,
        cc,
        quarter,
        variety,
        counterType,
        // porta_injerto,
        // temporada,
      } = filterProp;

      console.log(filterProp, '--------------------filterProp');

      let selectedOrchard = userOrchard_filter.filter(
        (ele) => ele.value === orchard?.value
      );

      // DEJA EL HUERTO SELECCIONADO, PERO SOLO CON CC QUARTERS Y POLIGONOS VÁLIDOS
      selectedOrchard = getOnlyValidOrchardCcQuarters(
        selectedOrchard[0],
        specie_filter
      );

      // TODO: dejar solo los polygons de la especie seleccionada
      let abailableSpeciePolygons = selectedOrchard.polygon.filter(
        (obj) => obj.specie === specie_filter
      );

      const abailablePolygons = abailableSpeciePolygons.map((poly) => {
        const enabledCc =
          _.findIndex(selectedOrchard.cc, function (p) {
            return p.value === poly.cc && p.quarter.length > 0;
          }) >= 0;

        return enabledCc ? poly : null;
      });

      const clearPolygons = abailablePolygons.filter((item) => item);

      // 3.5 - AHORA SOLO DEJARÁ LIS IDUNITEPRODUCTIVE DE LAS VARIETY ACTIVAS
      const uniqueArrayIdUniteProductive = selectedOrchard.cc.reduce(
        (acc, item) => {
          item.quarter.forEach((quarter) => {
            quarter.variety.forEach((variety) => {
              if (variety.seasonActive) {
                const uniqueString = `${variety.idUnitProductive}`;
                if (!acc.includes(uniqueString)) {
                  acc.push(uniqueString);
                }
              }
            });
          });
          return acc;
        },
        []
      );

      let resultsPolygonsAvailablesId = clearPolygons.filter((poly) =>
        uniqueArrayIdUniteProductive.includes(poly.idUnitProductive)
      );

      let polygonSet = resultsPolygonsAvailablesId;

      if (cc) {
        const polygonCcFiltered = resultsPolygonsAvailablesId.filter(
          (ele) => ele.cc.toLowerCase() === cc?.value.toLowerCase()
        );

        polygonSet = polygonCcFiltered;
      }

      if (quarter) {
        const polygonQuarterFiltered = polygonSet.filter(
          (ele) => ele.quarter.toLowerCase() === quarter?.value.toLowerCase()
        );

        polygonSet = polygonQuarterFiltered;
      }

      resultsPolygonsAvailablesId = polygonSet;

      const uniqueCombinationsId = new Set();

      // Filtrar el array para mantener solo los objetos con combinaciones únicas
      const uniquePolygonsId = resultsPolygonsAvailablesId.filter((obj) => {
        const combination = `${obj.idUnitProductive}`;
        if (uniqueCombinationsId.has(combination)) {
          return false;
        }
        uniqueCombinationsId.add(combination);
        return true;
      });

      if (uniquePolygonsId.length) {
        uniquePolygonsId.sort((a, b) => {
          const ccComparison = a.cc.localeCompare(b.cc);
          if (ccComparison !== 0) {
            return ccComparison;
          }
          const quarterComparison = a.quarter.localeCompare(b.quarter);
          if (quarterComparison !== 0) {
            return quarterComparison;
          }
          return a.idUnitProductive.localeCompare(b.idUnitProductive);
        });

        setAvailablePolygonsState(uniquePolygonsId);

        const firstQuarter = uniquePolygonsId[0];

        const ccFirst = firstQuarter?.cc;
        const quarterFirst = firstQuarter?.quarter;
        const varietyFirst = dashAdd(firstQuarter?.variety);
        const rootStockFirst = dashAdd(firstQuarter?.rootStock);
        const idUnitProductiveFirst = firstQuarter?.idUnitProductive;

        const selectedCcFilter = cc ? cc?.value : ccFirst;
        const selectedQuarterFilter = quarter ? quarter?.value : quarterFirst;
        const selectedVarietyFilter = variety ? variety?.value : varietyFirst;

        let quarterFromCc = null;
        let varietyFromCc = null;
        let rootStockFromCc = null;

        if (filterProp.cc && !filterProp.quarter) {
          const ccSelectValid = selectedOrchard.cc
            ? getOnlyValidCcs(selectedOrchard.cc, specie_filter)
            : [];

          const resultQuarterFromCc = ccSelectValid.filter(
            (ele) => ele.value.toLowerCase() === selectedCcFilter.toLowerCase()
          );

          let quarterArray = [];
          if (resultQuarterFromCc[0]?.quarter) {
            quarterArray = getSeasonActiveQuarters(
              resultQuarterFromCc[0]?.quarter
            );
          }

          quarterFromCc = quarterArray[0]?.value;
          varietyFromCc = quarterArray[0]?.variety[0]?.value;
          rootStockFromCc = quarterArray[0]?.variety[0]?.rootStockValue;
        }

        const maxIndex = uniquePolygonsId.length - 1;

        setOrchardMaxIndexState(maxIndex);

        const idOrchardFilter = selectedOrchard ? selectedOrchard.id : null;

        const selectedCc = getOnlyValidCcs(
          selectedOrchard.cc,
          specie_filter
        ).filter(
          (ele) => ele.value.toLowerCase() === selectedCcFilter.toLowerCase()
        );

        let selectedQuarter;
        if (!quarterFromCc) {
          // console.log('entra con null', selectedQuarterFilter);
          selectedQuarter = selectedCc[0]?.quarter
            ? selectedCc[0]?.quarter.filter(
                (ele) =>
                  ele.value.toLowerCase() ===
                  selectedQuarterFilter.toLowerCase()
              )
            : [];
        } else {
          selectedQuarter = selectedCc[0]?.quarter
            ? selectedCc[0]?.quarter.filter(
                (ele) => ele.value.toLowerCase() === quarterFromCc.toLowerCase()
              )
            : [];
        }

        const idUnitProductive = selectedQuarter[0].variety.find(
          (ele) => ele.value === varietyFromCc || selectedVarietyFilter
        );

        addFilter({
          orchard_filter: filterProp.orchard?.value,
          orchard_id_filter: idOrchardFilter,
          cc_filter: selectedCcFilter,
          quarter_filter: quarterFromCc || selectedQuarterFilter,
          variety_filter: varietyFromCc || selectedVarietyFilter,
          rootstock_filter: selectedQuarter[0]?.variety[0].rootStockValue,
          idUnitProductive: idUnitProductive?.idUnitProductive || '',
        });

        const varietyToFilter = varietyFromCc || selectedVarietyFilter;

        const isQuarterSelectedId = (element) =>
          element.idUnitProductive === idUnitProductive?.idUnitProductive;

        const actualIndex = uniquePolygonsId.findIndex(isQuarterSelectedId);

        setPolygonActualIndexState(actualIndex);

        setOrchardSelectedState(selectedOrchard);
        setCcSelectedState(selectedCc[0]);
        setQuarterSelectedState(selectedQuarter[0]);

        const varietySelectedArray = selectedQuarter[0].variety.filter(
          (ele) => ele.value.toLowerCase() === varietyToFilter.toLowerCase()
        );

        setVarietySelectedState(varietySelectedArray[0]);

        setIdUnitProductiveState(idUnitProductive?.idUnitProductive);

        const objToFilter = {
          orchardMapFilter: selectedOrchard.value,
          ccMapFilter: selectedCc[0].value,
          quarterMapFilter: selectedQuarter[0].value,
          varietyMapFilter: varietySelectedArray[0]?.value,
          rootStockMapFilter: varietySelectedArray[0]?.rootStock,
          idUnitProductiveFilter: idUnitProductive?.idUnitProductive,
          counterTypeMapFilter: counterType.value,
        };

        console.log(
          objToFilter,
          '-------------------------------asi va al mapa'
        );

        setFilterQuarterToMapState(objToFilter);

        setCounterTypeState(counterType.value);
      } else {
        setAvailablePolygonsState([]);
        setQuarterSelectedState(null);
        setIdUnitProductiveState(null);
        setOrchardMaxIndexState(null);
        setPolygonActualIndexState(null);
        setOrchardWithNoValidPolygons(true);

        addFilter({
          orchard_filter: null,
          orchard_id_filter: null,
          cc_filter: null,
          quarter_filter: null,
          variety_filter: null,
          rootstock_filter: null,
          idUnitProductive: null,
        });
      }
    }
  }, [filterProp]);

  console.log(minMaxState, '---minMaxState');

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        <BsRow>
          <BsCol class='col-md-6 col-lg-8 col-xl-9'>
            <BsRow>
              <BsCol class='col-12 col-lg-5 text-center'>
                <Typography variant='h5' className='mb-2'>
                  Leyenda
                </Typography>
                <p>
                  Los niveles de concentración muestran la cantidad de
                  dardos/ramillas contados o la cantidad de árboles próximos
                  contados. Mientras más cálido el tono, existen más conteos.
                </p>
              </BsCol>
              <BsCol class='col-12 col-lg-7'>
                <img src={HeatmapLegend} className='img-fluid' alt='legend' />
                {/* <table
                  className={clsx('table table-sm', classes.tableMaxMinStyle)}
                >
                  <tbody>
                    <tr>
                      <td>
                        Min. Dardos: <b>{minMaxState?.minDart}</b>
                      </td>
                      <td>
                        Máx. Dardos: <b>{minMaxState?.maxDart}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Min. Ramillas: <b>{minMaxState?.minTwig}</b>
                      </td>
                      <td>
                        Máx. Ramillas: <b>{minMaxState?.maxTwig}</b>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </BsCol>
              <BsCol class='col-md-12 pl-0 mt-2'>
                <div className={classes.mapContainer}>
                  <Map
                    plantsHandler={plantsHandler}
                    // selectedGrid={plantsSelectedGridState}
                    counterTypeProp={counterTypeState}
                    quarterFiltered={filterQuarterToMapState}
                    colorsArray={colorsArrayState}
                    loadingHandler={onServerLoading}
                    loadingState={loadingSamplingState}
                  />
                </div>
              </BsCol>
            </BsRow>
          </BsCol>
          <BsCol class='col-md-6 col-lg-4 col-xl-3 pl-xl-1'>
            <div className={classes.arrowsContainerStyle}>
              <SimpleIconButton
                actionOnClick={handleBackQuarter}
                disabled={
                  polygonActualIndexState === 0 || !quarterSelectedState
                }
                labelButton='Cuartel Anterior'
                icon={<KeyboardArrowLeftIcon />}
              />
              <SimpleIconButton
                actionOnClick={handleNextQuarter}
                disabled={
                  polygonActualIndexState === orchardMaxIndexState ||
                  !quarterSelectedState
                }
                labelButton='Próximo Cuartel'
                endIcon={<KeyboardArrowRightIcon />}
              />
            </div>
            <div>
              <Typography variant='h5' component='h2' color='secondary'>
                Información Cuartel
              </Typography>
              <Divider className={classes.divider} />
              {quarterSelectedState && (
                <table
                  className={clsx(
                    'table table-borderless table-sm',
                    classes.tableStyles
                  )}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: '35%',
                        }}
                      >
                        Huerto
                      </td>
                      <td className={classes.infoCol}>
                        {orchardSelectedState && orchardSelectedState.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Centro de costo</td>
                      <td className={classes.infoCol}>
                        {ccSelectedState && ccSelectedState.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Cuartel</td>
                      <td className={classes.infoCol}>
                        {quarterSelectedState && quarterSelectedState.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Variedad</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState && varietySelectedState?.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Portainjerto</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState &&
                          varietySelectedState?.rootStock}
                      </td>
                    </tr>
                    <tr>
                      <td>Hectárea</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState && varietySelectedState?.hectare}
                      </td>
                    </tr>
                    <tr>
                      <td>Plantas/há</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState &&
                          numberFormat(varietySelectedState?.plantsHa)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Plantas Cuartel</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState &&
                          numberFormat(
                            varietySelectedState?.hectare *
                              varietySelectedState?.plantsHa
                          )}
                      </td>
                    </tr>
                    {/* data del endpoint */}
                    <tr>
                      <td>Tipo Conteo</td>
                      <td className={classes.infoCol}>
                        {infoHeatmapState &&
                          dashDelete(infoHeatmapState.counterType)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Plantas Contadas</td>
                      <td className={classes.infoCol}>
                        {infoHeatmapState &&
                          numberFormatDecimals(infoHeatmapState.plantCounts, 0)}
                      </td>
                    </tr>
                    {infoHeatmapState &&
                    infoHeatmapState.plants.length === 0 ? (
                      <tr className='table-warning'>
                        <td>AVISO</td>
                        <td className={classes.infoCol}>
                          {infoHeatmapState && infoHeatmapState.message}
                        </td>
                      </tr>
                    ) : null}
                    {infoHeatmapState &&
                      (infoHeatmapState.counterType === 'Pre_Poda' ||
                        infoHeatmapState.counterType === 'Post_Poda') && (
                        <>
                          <tr>
                            <td>Promedio de Dardos Cuartel</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapState &&
                                numberFormatDecimals(
                                  infoHeatmapState.promedioDardosCuartel,
                                  0
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Promedio de Ramillas Cuartel</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapState &&
                                numberFormatDecimals(
                                  infoHeatmapState.promedioRamillasCuartel,
                                  0
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                    {infoHeatmapState &&
                      (infoHeatmapState.counterType === 'Pre_Raleo' ||
                        infoHeatmapState.counterType === 'Post_Raleo') && (
                        <>
                          <tr>
                            <td>Total Frutos</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapState &&
                                numberFormatDecimals(
                                  infoHeatmapState.fruits,
                                  1
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                    {infoHeatmapState &&
                      infoHeatmapState.counterType === 'Pre_Raleo' && (
                        <>
                          <tr>
                            <td>Promedio de Frutos Cuartel Pre Raleo</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapState &&
                                numberFormatDecimals(
                                  infoHeatmapState.promedioFrutosCuartelPreRaleo,
                                  1
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                    {infoHeatmapState &&
                      infoHeatmapState.counterType === 'Post_Raleo' && (
                        <>
                          <tr>
                            <td>Promedio de Frutos Cuartel Post Raleo</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapState &&
                                numberFormatDecimals(
                                  infoHeatmapState.promedioFrutosCuartelPostRaleo,
                                  1
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                    {infoHeatmapState &&
                    infoHeatmapState.plants.length !== 0 &&
                    (infoHeatmapState.counterType === 'Pre_Poda' ||
                      infoHeatmapState.counterType === 'Post_Poda') ? (
                      <>
                        <tr>
                          <td>Mínimo/Máximo de Dardos</td>
                          <td className={classes.infoCol}>
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.minDart, 0)}{' '}
                            -{' '}
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.maxDart, 0)}
                          </td>
                        </tr>
                        <tr>
                          <td>Mínimo/Máximo de Ramillas</td>
                          <td className={classes.infoCol}>
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.minTwig, 0)}{' '}
                            -{' '}
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.maxTwig, 0)}
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {infoHeatmapState &&
                    infoHeatmapState.plants.length !== 0 &&
                    (infoHeatmapState.counterType === 'Pre_Raleo' ||
                      infoHeatmapState.counterType === 'Post_Raleo') ? (
                      <>
                        <tr>
                          <td>Mínimo/Máximo de Frutos</td>
                          <td className={classes.infoCol}>
                            {minMaxState &&
                              numberFormatDecimals(
                                minMaxState.minFruit,
                                0
                              )}{' '}
                            -{' '}
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.maxFruit, 0)}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </table>
              )}
            </div>
          </BsCol>
        </BsRow>
      </BsCol>
    </BsRow>
  );
};

export default PlantHeatMap;
