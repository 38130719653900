import {
  Button,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Divider,
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Drawer,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  List,
  useTheme,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Radio,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import _, { filter, head, max, pad } from 'lodash';
import { useHistory } from 'react-router-dom';
import { subDays, addDays } from 'date-fns';
import { useErrorHandler } from 'react-error-boundary';
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useForm, useWatch, Controller, Form } from 'react-hook-form';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { CardPaper } from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SimpleIconButton,
  SelectMultipleWidget2,
  TextFieldWidgetDateController2,
  ButtonAction,
} from '../../../components';
import { FiltersContext } from '../../../context';
import {
  alphabeticalSort,
  birthConvert,
  dashAdd,
  dashDelete,
  dateConvert,
  dateConvertInverse,
  dateFormatIso,
  dateFrontConvert,
  dateFrontConvertWithFormat,
  getOrchardVarietiesDeep,
  getUniqueValuesMaster,
  numberFormatDecimals,
} from '../../../helpers';
import { FormPrompt } from '../../../helpers/FormPrompt';
import { MobileFilters } from 'containers/Library';
// import customPanel from './CustomPanel';
import './styles.css';
import MOCKDATA from './data_weeks.json';
import UserProfile from '../../../common/profiles.json';

const drawerWidth = '40vw';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  titleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
  },
  subtitleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
    marginBottom: '15px !important',
    marginTop: '20px !important',
  },
  weekTitleStyle: {
    fontSize: '18px !important',
    fontWeight: 600 + ' !important',
    // marginTop: '20px !important',
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    maxWidth: '90px !important',
    minWidth: '70px !important',
  },
  dividerRow: {
    marginTop: 30,
  },
  iconText: {
    marginRight: 10,
  },
  OddColStyle: {
    backgroundColor: 'rgba(227,235,237,.3)',
  },
  tableStyle: {
    '& tbody tr:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& tbody tr td': {
      borderTop: 'none !important',
    },
  },
  varietyNameStyle: {
    fontWeight: 600,
    fontSize: 15,
    paddingTop: 10 + 'px !important',
    width: '120px',
  },
  legendStyle: {
    position: 'absolute',
    right: 0,
    top: 10,
    // media query para pantallas menores a 768px
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0,
      marginBottom: 10,
    },
  },
  daysTableStyle: {
    // borderRight: '1px solid #e3e3e3',
    // borderBottom: '1px solid #e3e3e3',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: '8px',
      padding: '0px 10px',
      '& i': {
        fontSize: '17px',
        marginRight: '9px',
      },

      '& i.fa-check-square': {
        color: theme.palette.success.main,
      },
    },
  },
  rowVarietiesStyle: {
    marginBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& .varietyBox': {
      paddingLeft: '5px',
      paddingRight: '5px',
      width: '408px',
      backgroundColor: 'rgba(245,245,245,.4)',
      marginRight: '8px',
      marginBottom: '5px',
      borderTop: '3px solid',
      borderTopColor: theme.palette.primary.main,
    },
    '& .varietyBox table': {
      marginBottom: '0px',
    },
    // '& .varietyBox table td:not(:first-child)': {
    // '& .varietyBox table td': {
    //   textAlign: 'center',
    // },
    '& .varietyBox table td': {
      width: '25%',
      overflow: 'hidden',
      wordBreak: 'break-all',
      whiteSpace: 'normal',
      textAlign: 'center',
    },
    // '& .varietyBox table td:nth-child(1)': {
    //   width: '110px',
    //   overflow: 'hidden',
    //   wordBreak: 'break-all',
    //   whiteSpace: 'normal',
    // },
    '& .varietyBox table td.varietyTitleStyle': {
      textAlign: 'center',
      padding: '5px 0px',
    },
    '& .varietyBox table td:nth-child(1)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td:nth-child(2)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td p': {
      fontSize: '12px',
    },
  },
  weekContainerTitleStyle: {
    backgroundColor: '#d9eef4',
    padding: '5px 8px',
    '& h6': {
      // color: 'white',
      padding: '5px 0px',
      marginBottom: '0px',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
  },
}));

/**
 * Inverts a number.
 *
 * @param {number} number - The number to be inverted.
 * @returns {number} The inverted number.
 */
const invertNumber = (number = 0) => {
  return number === 0 ? number : number * -1;
};

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((item) => {
    item.specie.forEach((specie) => {
      specie.productLine.forEach((productLine) => {
        productLine.variety.forEach((variety) => {
          result.push({
            orchard: {
              label: item.label,
              value: item.value,
            },
            agroBusiness: {
              label: item.agroBusiness,
              value: item.agroBusinessValue,
            },
            group: {
              label: item.group,
              value: item.groupValue,
            },
            technicBoss: {
              label: item.technicBoss,
              value: item.technicBossValue,
            },
            specie: {
              label: specie.label,
              value: specie.value,
            },
            productLine: {
              label: productLine.label,
              value: productLine.value,
            },
            variety: {
              label: variety.label,
              value: variety.value,
            },
          });
        });
      });
    });
  });

  return result;
}

const endPointName = 'v1/planningHarvest/daily';
const endPointNamePost = 'v1/planningHarvest';
const endPointFilters = 'v1/libraryExternal/specie';
const endPointExport = '/api-task/report/download-variety-report';

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  ClientSideRowModelModule,
  RangeSelectionModule,
  MenuModule,
  MultiFilterModule,
  MasterDetailModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  ClipboardModule,
]);

const arrayColsResume = ['deliveredYield', 'availableYield', 'balance'];

function sortArrayByPlanning(array, flag) {
  // Determina la propiedad con base en el flag
  const property = flag === 0 ? 'confirmedPlanning' : 'preConfirmedPlanning';

  // Ordena el array según el valor de la propiedad
  return array.sort((a, b) => {
    if (a[property] === b[property]) return 0;
    return a[property] ? 1 : -1; // false primero, true después
  });
}

const CheckDays = (props) => {
  const theme = useTheme();

  const classes = useStyles();

  const history = useHistory();

  // const { window } = props;

  const axiosContext = useContext(AxiosContext);

  const {
    moduleUser,
    userClient,
    userOrchard,
    userId,
    userFullname,
    userIdProfile,
  } = useContext(Context);

  const [garces2State, setGarces2State] = useState(false);

  const errorHandler = useErrorHandler();

  // crear un ref de alert
  const alertRef = useRef(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const {
    state: {
      userOrchard_filter,
      orchard_filter,
      orchard_id_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const moduleOrchardFilter = 'statusOrchardPlanning';

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [agroBusinessOptionsState, setAgroBusinessOptionsState] = useState([]);

  const [specieOptionsState, setSpecieOptionsState] = useState([]);

  const [varietyOptionsState, setVarietyOptionsState] = useState([]);

  const [filterDataTechnicBossState, setFilterDataTechnicBossState] = useState(
    []
  );

  const [filterDataGroupState, setFilterDataGroupState] = useState([]);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // const [filterOrchardState, setFilterOrchardState] = useState(
  //   userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  // );

  const [filterOrchardState, setFilterOrchardState] = useState([]);

  const [fullDataOrchardState, setFullDataOrchardState] = useState([]);

  const [dataFilterState, setDataFilterState] = useState([]);

  const [filterDataAgroBusinessState, setFilterDataAgroBusinessState] =
    useState([]);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [specieDataState, setSpecieDataState] = useState([]);

  const [productionLineDataState, setProductionLineDataState] = useState([]);

  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);

  const [loadingVarietiesState, setLoadingVarietiesState] = useState(false);

  const [openDialogState, setOpenDialogState] = useState(false);

  // dates

  const actualYear = new Date().getFullYear();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const [loadingSubmitState, setLoadingSubmitState] = useState(false);

  const [noMessageKilosState, setNoMessageKilosState] = useState(false);

  const [successSubmitState, setSuccessSubmitState] = useState(false);

  const [totalKilosPerVarietyState, setTotalKilosPerVarietyState] = useState(
    {}
  );

  const [copyButtonState, setCopyButtonState] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);

  const [excelLoadingState, setExcelLoadingState] = useState(false);

  const [excelSuccessDownloadState, setExcelSuccessDownloadState] =
    useState(false);

  const [styleState, setStyleState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
  });

  const [fullScreenState, setFullScreenState] = useState(false);

  const [totalDataKilosState, setTotalDataKilosState] = useState(0);

  const [visibleColumnsState, setVisibleColumnsState] = useState([]);
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [totalRow, setTotalRow] = useState({});
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  const [quickFilterText, setQuickFilterText] = useState(''); // estado para el filtro de busquedageneral
  const [anchorEl, setAnchorEl] = useState(null);

  const [customGroupColsState, setCustomGroupColsState] = useState('0');
  const [detailColumnsState, setDetailColumnsState] = useState([]);
  const [dashDataState, setDashDataState] = useState(null);

  const [titleCheckState, setTitleCheckState] = useState(
    'Estimación Diaria Confirmada'
  );

  const [dateCheckState, setDateCheckState] = useState(0);

  const [loadingExportState, setLoadingExportState] = useState(false);

  // crear un ref para mantener el custom actual
  const customGroupColsRef = useRef('0');

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const localeText = AG_GRID_LOCALE_ES;

  //-------------- FUNCIONES PARA LA TABLA

  // funcion para formatear los números
  const numberFormatter = (value) => {
    if (value === undefined || value === null) {
      return '';
    }
    return `${value.toLocaleString().replace(/,/g, '.')}`;
  };

  // suma de totales
  const sumTotalWeeks = (rowData) => {
    return rowData.reduce((total, row) => {
      return total + (row.totalWeeks || 0);
    }, 0);
  };

  // funcion para limpiar la selección de celdas
  const clearFocusSelection = () => {
    if (gridApiRef.current && gridApiRef.current) {
      gridApiRef.current.clearFocusedCell();
    }
  };

  // procesa las filas con su detalle histórico
  const processDataRowDetailFull = (data) => {
    const { table } = data;
    const rows = [];

    table.forEach((item) => {
      const row = {
        id: `${item.productorID}_${item.clientValue}_${item.specieValue}_${item.groupValue}_${item.agroBusinessValue}_${item.orchardValue}_${item.csg}_${item.varietyValue}_${item.productLineValue}_${item.technicBossValue}_${item.date}`,
        client: item.client,
        clientValue: item.clientValue,
        specie: item.specie,
        specieValue: item.specieValue,
        group: item.group,
        groupValue: item.groupValue,
        agroBusiness: item.agroBusiness,
        agroBusinessValue: item.agroBusinessValue,
        orchard: item.orchard,
        orchardValue: item.orchardValue,
        csg: item.csg,
        variety: item.variety,
        varietyValue: item.varietyValue,
        productLine: item.productLine,
        productLineValue: item.productLineValue,
        technicBoss: item.technicBoss,
        technicBossValue: item.technicBossValue,
        productorID: item.productorID,
        confirmedPlanning: item.confirmedPlanning,
        preConfirmedPlanning: item.preConfirmedPlanning,
        dateConfirm: item.dateConfirm,
        datePreConfirm: item.datePreConfirm,
        dateNoConfirm: item.dateNoConfirm,
        hourConfirm: item.hourConfirm,
        hourPreConfirm: item.hourPreConfirm,
        hourNoConfirm: item.hourNoConfirm,
        kilos: item.kilos,
        fruitRating: item.fruitRating,
      };

      rows.push(row);
    });
    console.log(rows, '<<<<<--------------rowsTable');

    return rows;
  };

  const getDayAbbreviation = (dayName) => {
    return dayName.slice(0, 3).toLowerCase();
  };

  const dateConvertTable = (date) => {
    let newFormat = '';
    if (date) {
      const converted = date.split('-');
      newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
    }
    return newFormat;
  };

  const dateShortConvertTable = (date) => {
    let newFormat = '';
    if (date) {
      const converted = date.split('-');
      newFormat = converted[2] + '-' + converted[1];
    }
    return newFormat;
  };

  const finalColumns = [
    {
      colId: 'caracter',
      // headerGroup: 'caracter',
      headerName: 'Caracterización',
      children: [
        {
          headerName: 'Huerto',
          field: 'orchard',
          editable: false,
          suppressFillHandle: true,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          hide: false,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: 'Especie',
          field: 'specie',
          editable: false,
          suppressFillHandle: true,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          hide: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: 'Cartera',
          field: 'clientValue',
          editable: false,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
          valueGetter: (params) => {
            // Si es la fila de totales, no mostrar ningún valor
            if (params.node?.level) return '';
            return params.data?.clientValue === 'Garces_Fruit_2'
              ? 'EXTERNOS'
              : 'PROPIOS';
          },
        },
        {
          headerName: 'Jefe tecnico',
          field: 'technicBoss',
          // colId: 'technicBoss',
          editable: false,
          // suppressFillHandle: true,
          // rowGroup: false,
          suppressMovable: true,
          filter: 'agMultiColumnFilter',
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: 'CSG',
          field: 'csg',
          colId: 'csg',
          editable: false,
          suppressFillHandle: true,
          suppressMovable: true,
          aggFunc: 'sum',
          filter: 'agMultiColumnFilter',
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: 'Rut',
          field: 'productorID',
          editable: false,
          suppressFillHandle: true,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          // hide: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: 'Agricola',
          field: 'agroBusiness',
          editable: false,
          suppressFillHandle: true,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },

        {
          headerName: 'Grupo',
          field: 'group',
          editable: false,
          suppressFillHandle: true,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
        },
        {
          headerName: 'Línea de Producción',
          field: 'productLine',
          editable: false,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          hide: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
          suppressFillHandle: true,
        },
        {
          headerName: 'Variedad',
          field: 'variety',
          editable: false,
          filter: 'agMultiColumnFilter',
          suppressMovable: true,
          // rowGroup: false,
          enableRowGroup: true,
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'contains',
                  filterOptions: ['contains'],
                },
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressMiniFilter: true,
                },
              },
            ],
          },
          suppressFillHandle: true,
        },
        {
          headerName: dateCheckState === 0 ? 'Confirmado' : 'Estimado',
          field:
            dateCheckState === 0 ? 'confirmedPlanning' : 'preConfirmedPlanning',
          editable: false,
          // filter: 'agMultiColumnFilter',
          suppressMovable: true,
          rowGroup: false,
          cellDataType: 'boolean',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: [true, false], // Only display true and false in the filter
            suppressMiniFilter: true, // Hides the search box in the filter popup
          },
          cellRenderer: (params) => {
            if (params.node.level === -1) {
              // Si es una fila de totales
              return ''; // No muestra nada
            }
            if (params.node.group) {
              return ''; // No muestra nada en la fila de agrupación
            }
            return params.value ? 'SI' : 'NO'; // Si no, muestra el valor normal
          },
        },
      ],
    },
    {
      headerName: 'Kilos',
      field: 'kilos',
      editable: false,
      suppressFillHandle: true,
      editable: false,
      filter: false,
      cellDataType: 'number',
      aggFunc: 'sum',
      valueFormatter: (params) => numberFormatDecimals(params.value, 2),
      cellStyle: (params) => {
        if (params.node?.level) {
          return '';
        }
        if (!params.data) {
          return '';
        }
        if (dateCheckState === 0) {
          return params.data && params.data.confirmedPlanning === true
            ? { backgroundColor: '#CDFEE4' }
            : { backgroundColor: '#A6AEB4' };
        } else {
          return params.data && params.data.preConfirmedPlanning === true
            ? { backgroundColor: '#CDFEE4' }
            : { backgroundColor: '#A6AEB4' };
        }
      },
    },
    {
      headerName: 'Fecha',
      field: 'dateNoConfirm',
      editable: false,
      suppressFillHandle: true,
      filter: false,
      valueFormatter: (params) => dateConvertTable(params.value),
      cellStyle: (params) => {
        if (params.node?.level) {
          return '';
        }
        if (!params.data) {
          return '';
        }
        if (dateCheckState === 0) {
          return params.data && params.data.confirmedPlanning === true
            ? { backgroundColor: '#CDFEE4' }
            : { backgroundColor: '#A6AEB4' };
        } else {
          return params.data && params.data.preConfirmedPlanning === true
            ? { backgroundColor: '#CDFEE4' }
            : { backgroundColor: '#A6AEB4' };
        }
      },
    },
    {
      colId: 'confirmedData',
      // headerGroup: 'caracter',
      headerName:
        dateCheckState === 0
          ? 'Fecha Hora Confirmación'
          : 'Fecha Hora Estimación',
      children: [
        {
          headerName: 'Fecha',
          // field: dateCheckState === 0 ? 'dateConfirm' : 'datePreConfirm',
          field: 'dateConfirm',
          editable: false,
          suppressFillHandle: true,
          filter: false,
          valueFormatter: (params) => {
            // console.log(params.data, '<<<<<----params.data');
            if (dateCheckState === 0) {
              if (params?.data?.confirmedPlanning) {
                return `${dateShortConvertTable(params.value)}`;
              } else {
                return '-';
              }
            } else {
              if (params?.data?.preConfirmedPlanning) {
                return `${dateShortConvertTable(params.value)}`;
              } else {
                return '-';
              }
            }
          },
          cellStyle: (params) => {
            if (params.node?.level) {
              return '';
            }
            if (!params.data) {
              return '';
            }
            if (dateCheckState === 0) {
              return params.data && params.data.confirmedPlanning === true
                ? { backgroundColor: '#CDFEE4' }
                : { backgroundColor: '#A6AEB4' };
            } else {
              return params.data && params.data.preConfirmedPlanning === true
                ? { backgroundColor: '#CDFEE4' }
                : { backgroundColor: '#A6AEB4' };
            }
          },
        },
        {
          headerName: 'Hora',
          // field: dateCheckState === 0 ? 'hourConfirm' : 'hourPreConfirm',
          field: 'hourConfirm',
          editable: false,
          suppressFillHandle: true,
          filter: false,
          valueFormatter: (params) => {
            // console.log(params.data, '<<<<<----params.data');
            if (dateCheckState === 0) {
              if (params?.data?.confirmedPlanning) {
                return `${params.value}`;
              } else {
                return '-';
              }
            } else {
              if (params?.data?.preConfirmedPlanning) {
                return `${params.value}`;
              } else {
                return '-';
              }
            }
          },
          cellStyle: (params) => {
            if (params.node?.level) {
              return '';
            }
            if (!params.data) {
              return '';
            }
            if (dateCheckState === 0) {
              return params.data && params.data.confirmedPlanning === true
                ? { backgroundColor: '#CDFEE4' }
                : { backgroundColor: '#A6AEB4' };
            } else {
              return params.data && params.data.preConfirmedPlanning === true
                ? { backgroundColor: '#CDFEE4' }
                : { backgroundColor: '#A6AEB4' };
            }
          },
        },
      ],
    },
    {
      headerName: 'Nota Fruta',
      field: 'fruitRating',
      editable: false,
      aggFunc: 'avg',
      suppressFillHandle: true,
      filter: false,
      cellDataType: 'number',
      valueFormatter: (params) => numberFormatDecimals(params.value, 2),
    },
  ];

  // funcion para limpiar las celdas editadas
  const clearEditedCells = () => {
    // const updatedRowData = rowData.map((row) => {
    //   const updatedRow = { ...row };
    //   Object.keys(updatedRow).forEach((key) => {
    //     if (key.startsWith('edited_')) {
    //       delete updatedRow[key];
    //     }
    //   });
    //   return updatedRow;
    // });

    console.log(rowData, '<<<<<-------------------rowData');

    const transaction = {
      update: rowData.map((row) => {
        const updatedRow = { ...row };
        Object.keys(updatedRow).forEach((key) => {
          if (key.startsWith('edited_')) {
            delete updatedRow[key];
          }
        });
        return updatedRow;
      }),
    };

    console.log(transaction, '<<<<<-------------------transaction');

    gridApiRef.current.applyTransaction(transaction);

    // setRowData(updatedRowData);
    // gridApiRef.current.setRowData(updatedRowData);
  };

  // función para setear el fullScreen
  const setFullScreen = (fullScreenState) => {
    if (fullScreenState) {
      document.body.style.overflow = 'hidden';
      setStyleState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenState((prevState) => !prevState);
  };

  // confirma si debe tener master row, si tiene datos internos
  const isRowMaster = useCallback((dataItem) => {
    return dataItem ? dataItem.variantDetails.length > 0 : false;
  }, []);

  useEffect(() => {
    if (gridApiRef.current && customGroupColsState) {
      gridApiRef.current.openToolPanel('custom');
    }
  }, [customGroupColsState]);

  const customPanel = () => {
    return (
      <div className='p-2'>
        <FormControl
          sx={{
            '& .MuiFormControlLabel-label.MuiTypography-root': {
              fontSize: 13,
            },
          }}
        >
          <RadioGroup value={customGroupColsState} onChange={handleChangeCols}>
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='0'
              label='Sin Agrupación'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='1'
              label='Confirmados, por Huerto'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='2'
              label='Confirmados, por Agrícola'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='3'
              label='Confirmados, por J. Técnico'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='4'
              label='Confirmados, por Variedad'
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  // genera el sidebar Custom
  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: 'custom',
        labelDefault: 'Personalizadas',
        labelKey: 'customStats',
        iconKey: 'columns',
        toolPanel: customPanel,
        toolPanelParams: {},
      },

      // {
      //     id: 'filters',
      //     labelDefault: 'Filters',
      //     labelKey: 'filters',
      //     iconKey: 'filter',
      //     toolPanel: 'agFiltersToolPanel',
      //     minWidth: 180,
      //     maxWidth: 400,
      //     width: 250
      // }
    ],
    position: 'right',
    //   defaultToolPanel: 'customPanel',
  };

  // componente de panel detalle para las filas
  // const detailCellRendererParams = useMemo(
  //   () => ({
  //     detailGridOptions: {
  //       columnDefs: detailColumnsState,
  //       rowClass: 'inner-row-class',
  //       // turns OFF row hover, it's on by default
  //       suppressRowHoverHighlight: true,
  //       // turns ON column hover, it's off by default
  //       columnHoverHighlight: false,
  //       suppressHeaderHoverHighlight: true,
  //       defaultColDef: {
  //         flex: 1,
  //         rowClass: 'inner-row-class',
  //         suppressHeaderMenuButton: true,
  //         suppressHeaderFocus: true,
  //         suppressHeaderHoverHighlight: true,
  //         columnHoverHighlight: false,
  //         headerClass: 'custom-header',
  //         sortable: false,
  //       },
  //       // headerHeight: 38,
  //     },
  //     getDetailRowData: ({ successCallback, data: { variantDetails } }) => {
  //       return successCallback(variantDetails);
  //     },
  //   }),
  //   [detailColumnsState]
  // );

  //

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  const [dataTableState, setDataTableState] = useState([]);

  // const useOrchard = useWatch({
  //   control,
  //   name: 'orchard',
  // });

  const useAgroBusiness = useWatch({
    control,
    name: 'agroBusiness',
  });

  const useSpecie = useWatch({
    control,
    name: 'specie',
  });

  const useProductionLine = useWatch({
    control,
    name: 'productionLine',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const useTechnicBoss = useWatch({
    control,
    name: 'technicBoss',
  });

  const useGroup = useWatch({
    control,
    name: 'group',
  });

  const watchSince = watch('since', '');
  // const watchUntil = watch('until', '');

  const minCheck = new Date();
  const maxCheck = addDays(new Date(), 1);

  useEffect(() => {
    // setminDateState(watchSince);
    // setSinceSelectedState(watchSince);
    // setValue('until', addDays(new Date(watchSince), 0));
    // setMaxDateValidationState(addDays(new Date(watchSince), 30));
    // clearErrors('incorrectDates');

    if (watchSince) {
      if (birthConvert(watchSince) === birthConvert(minCheck)) {
        setTitleCheckState('Estimación diaria Confirmada');
        setDateCheckState(0);
      } else {
        setTitleCheckState('Estimación diaria');
        setDateCheckState(1);
      }
    }
  }, [watchSince]);

  // useEffect(() => {
  //   setUntilSelectedState(watchUntil);
  //   clearErrors('incorrectDates');
  // }, [watchUntil]);

  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const objQuery = {
      clientValue: userClient.value,
      userOrchards: userOrchard,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointFilters,
        objQuery
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        data.orchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDataFilterState(data.orchard);

        const flattenEndpointData = flattenEndpointDataHandler(data.orchard);

        setFiltersFlattenDataState(flattenEndpointData);

        // cargar con valores únicos
        const agroBusinessArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.agroBusiness,
          };
        });

        const uniqueAgroBusiness = getUniqueValuesMaster(
          agroBusinessArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueAgroBusiness.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setAgroBusinessOptionsState(uniqueAgroBusiness);

        const specieArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.specie,
          };
        });

        const uniqueSpecie = getUniqueValuesMaster(
          specieArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueSpecie, '<<<<<------uniqueSpecie------')

        setSpecieOptionsState(uniqueSpecie);

        const varietyArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.variety,
          };
        });

        const uniqueVariety = getUniqueValuesMaster(
          varietyArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueVariety, '<<<<<------uniqueVariety------')

        setVarietyOptionsState(uniqueVariety);

        const groupArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.group,
          };
        });

        const uniqueGroup = getUniqueValuesMaster(
          groupArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueGroup.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataGroupState(uniqueGroup);

        const technicBossArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.technicBoss,
          };
        });

        const uniqueTechnicBoss = getUniqueValuesMaster(
          technicBossArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueTechnicBoss.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataTechnicBossState(uniqueTechnicBoss);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    // getFilterVarietyAsync();

    getFilterAsync();
  }, []);

  // UseEffect para cambios de filtros
  useEffect(() => {
    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      const agroBusinessFilter = useAgroBusiness
        ? ele?.agroBusiness.value === useAgroBusiness.value
        : true;
      // const orchardFilter = useOrchard
      //   ? ele?.orchard.value === useOrchard.value
      //   : true;
      const specieFilter = useSpecie
        ? ele?.specie.value === useSpecie.value
        : true;
      const productLineFilter = useProductionLine
        ? ele?.productLine.value === useProductionLine.value
        : true;
      // const varietyFilter = useVariety
      //   ? ele?.variety.value === useVariety.value
      //   : true;
      const groupFilter = useGroup ? ele?.group.value === useGroup.value : true;
      const technicBossFilter = useTechnicBoss
        ? ele?.technicBoss.value === useTechnicBoss.value
        : true;

      return (
        agroBusinessFilter &&
        // orchardFilter &&
        specieFilter &&
        productLineFilter &&
        // varietyFilter &&
        groupFilter &&
        technicBossFilter
      );
    });

    // console.log(filterUpdatedData, '<<<<<------filterData------');
    const filterUpdatedDataVariety = filterUpdatedData.filter((ele) => {
      // buscarlo con some en el array de useOrchard
      const varietyFilter =
        useVariety && useVariety.length
          ? useVariety.some((variety) => variety.value === ele?.variety.value)
          : true;

      return varietyFilter;
    });

    // console.log(filterUpdatedDataVariety, '<<<<<------filterDataVariety------');

    const agroBusinessArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.agroBusiness,
      };
    });

    const uniqueAgroBusiness = getUniqueValuesMaster(
      agroBusinessArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setAgroBusinessOptionsState(uniqueAgroBusiness);

    if (!useAgroBusiness && uniqueAgroBusiness.length === 1) {
      setValue('agroBusiness', uniqueAgroBusiness[0]);
    }

    const orchardArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.orchard,
      };
    });

    const uniqueOrchards = getUniqueValuesMaster(
      orchardArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterOrchardState(uniqueOrchards);

    // if (!useOrchard && uniqueOrchards.length === 1) {
    //   setValue('orchard', uniqueOrchards[0]);
    // }

    const specieArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.specie,
      };
    });

    const uniqueSpecie = getUniqueValuesMaster(
      specieArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setSpecieOptionsState(uniqueSpecie);

    if (!useSpecie && uniqueSpecie.length === 1) {
      // console.log(uniqueSpecie, '<<<<<------Entra para seterlo otra vez------');

      setValue('specie', uniqueSpecie[0]);
    }

    const productLineArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.productLine,
      };
    });

    const uniqueProductLine = getUniqueValuesMaster(
      productLineArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setProductionLineDataState(uniqueProductLine);

    // if (!useProductionLine && uniqueProductLine.length === 1) {
    //   setValue('productionLine', uniqueProductLine[0]);
    // }

    const varietyArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.variety,
      };
    });

    const uniqueVariety = getUniqueValuesMaster(
      varietyArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setVarietyOptionsState(uniqueVariety);

    const groupArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.group,
      };
    });

    const uniqueGroup = getUniqueValuesMaster(
      groupArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueGroup.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataGroupState(uniqueGroup);

    if (!useGroup && uniqueGroup.length === 1) {
      setValue('group', uniqueGroup[0]);
    }

    const technicBossArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.technicBoss,
      };
    });

    const uniqueTechnicBoss = getUniqueValuesMaster(
      technicBossArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueTechnicBoss.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataTechnicBossState(uniqueTechnicBoss);
  }, [
    useAgroBusiness,
    // useOrchard,
    useSpecie,
    useProductionLine,
    useVariety,
    useGroup,
    useTechnicBoss,
  ]);

  const onSubmit = async (values) => {
    if (alertRef.current) {
      handleClickOpenDialog();
      return;
    }

    setNoMessageKilosState(false);

    const dateBegin = values.since || '';
    // const dateEnd = values.until || '';

    const dateBeginFormat = dateFormatIso(dateBegin);
    // const dateEndFormat = dateFormatIso(dateEnd);
    const dateEndFormat = dateFormatIso(dateBegin);

    if (dateBeginFormat > dateEndFormat) {
      // console.log(dateBeginFormat, dateEndFormat, '<<<<<------------');
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    setDataTableState([]);
    setLoadingSubmitFilterState(true);

    let technicBossValueFilter = '';

    // Si es ID jefe técnico filtra con su username
    if (
      userIdProfile === '66b35738fce7da894e2b2572' ||
      userIdProfile === '6422e08d26bae345b6309abb'
    ) {
      technicBossValueFilter = userFullname;
    } else {
      technicBossValueFilter = values.technicBoss?.value;
    }

    // AGREGADO de specie y productionLine
    const dataQuery = {
      clientValue: userClient.value,
      // orchardValue: values.orchard.value,
      // agroBusiness: values?.agroBusiness?.label,
      technicBossValue: technicBossValueFilter,
      agroBusinessValue: '',
      varieties: [],
      // variety: values.variety?.label || '',
      specieValue: specie_filter,
      productLineValue: '',
      dateSince: dateBeginFormat,
      dateUntil: dateEndFormat,
      day: dateCheckState,
    };

    // console.log(values.orchard, orchard_filter, '---------evalua huertos');
    setInfoFilterTab({ orchard: values.orchard, ...values });

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        dataQuery
      );

      const { data, status } = response;

      // console.log(data, '<<<<<-------------------', paramsQuery);

      if (status === 200) {
        // setDataTableState(MOCKDATA);
        // setDashDataState(MOCKDATA);

        // setVisibleColumnsState(processDataColumns(MOCKDATA.table));

        // // setDetailColumnsState(processDataDetailColumn(MOCKDATA.days));

        // const rowDataFullTransform = processDataRowDetailFull(MOCKDATA);

        // REAL

        setDataTableState(data);
        setDashDataState(data);

        setVisibleColumnsState(finalColumns);

        // setDetailColumnsState(processDataDetailColumn(data.getPlanningHarvestDaily.table));

        const rowDataFullTransform = processDataRowDetailFull(data);

        setRowData(sortArrayByPlanning(rowDataFullTransform, dateCheckState));
      }
    } catch (error) {
      errorHandler(error);
      setDashDataState([]);
    } finally {
      setLoadingSubmitFilterState(false);
    }
  };

  /*Funcionamiento de el boton de busqueda */
  const handleQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const closeHandler = () => {
    setSuccessSubmitState(false);
  };

  const onResetSearch = () => {
    setQuickFilterText('');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /*Funcionamiento del boton de exportar Csv*/
  // const onBtnExportCsv = () => {
  //   if (gridApiRef.current) {
  //     gridApiRef.current.exportDataAsCsv();
  //   }
  //   handleMenuClose();
  // };

  // const onBtnExportExcel = () => {
  //   if (gridApiRef.current) {
  //     gridApiRef.current.exportDataAsExcel();
  //   }
  //   handleMenuClose();
  // };

  const onBtnExportCsv = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        '0'
      )}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS

      const docName = dashAdd(titleCheckState);

      const fileName = `${docName}_${formattedDate}_${formattedTime}.csv`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === 'string' ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  const onBtnExportExcel = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        '0'
      )}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS

      const docName = dashAdd(titleCheckState);

      const fileName = `${docName}_${formattedDate}_${formattedTime}.xlsx`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === 'string' ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  // const resetFilters = () => {
  //   // console.log('reset filters');
  //   reset();
  //   setCopyButtonState(false);
  //   setSuccessSubmitState(false);
  //   setDataTableState([]);
  // };

  useEffect(() => {
    if (userClient && userClient?.value === 'Garces_Fruit_2') {
      // console.log(userClient, '<<<<<------------');
      setGarces2State(true);
    }
  }, [userClient]);

  const handleClickOpenDialog = () => {
    setOpenDialogState(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogState(false);
  };

  const handleCloseAndConfirmDialog = () => {
    setCopyButtonState(false);
    alertRef.current = false;
    handleSubmit(onSubmit)();

    setOpenDialogState(false);
  };

  // const selectNothing = useCallback(() => {
  //   gridApiRef.current
  //     .setColumnFilterModel('confirmedPlanning', { values: ['Verdadero'] })
  //     .then(() => {
  //       gridApiRef.current.onFilterChanged();
  //     });
  // }, []);

  // const getValuesFilter = useCallback(
  //   (type) => {
  //     gridApiRef.current
  //       .getColumnFilterInstance('confirmedPlanning')
  //       .then((instance) => {
  //         alert(JSON.stringify(instance.getFilterValues(), null, 2));
  //       });
  //   },
  //   [alert]
  // );

  // const setModel = useCallback((type) => {
  //   gridApiRef.current
  //     .setColumnFilterModel('confirmedPlanning', { values: [true] })
  //     .then(() => {
  //       gridApiRef.current.onFilterChanged();
  //     });
  // }, []);

  // const filterByTrue = () => {
  //   if (gridApiRef && gridApiRef.current) {
  //     gridApiRef.current.getFilterInstance('confirmedPlanning', (filter) => {
  //       if (filter) {
  //         console.log(filter, '<<<<<------filter------');

  //         filter.setModel({ values: [true] }); // Filtramos solo los valores `true`
  //         filter.applyModel(); // Aseguramos aplicar el modelo al filtro

  //         // Ejecutar el cambio de filtro en la grilla
  //         gridApiRef.current.onFilterChanged();
  //       }
  //     });
  //   }
  // };

  const setConfirmedFilter = useCallback(async () => {
    const hardcodedFilter = { values: ['true'], filterType: 'set' };

    if (dateCheckState === 0) {
      gridApiRef.current
        .setColumnFilterModel('confirmedPlanning', hardcodedFilter)
        .then(() => {
          gridApiRef.current.onFilterChanged();
        });
    } else {
      gridApiRef.current
        .setColumnFilterModel('preConfirmedPlanning', hardcodedFilter)
        .then(() => {
          gridApiRef.current.onFilterChanged();
        });
    }
  }, [dateCheckState]);

  const resetFilters = () => {
    if (gridApiRef && gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      gridApiRef.current.resetColumnState();
    }
  };

  const resetGrouping = () => {
    // Obtener el estado actual de las columnas
    // const columnState = gridApiRef.current.getColumnState();

    // // Modificar el estado para que ninguna columna esté agrupada
    // const resetState = columnState.map((col) => ({
    //   ...col,
    //   rowGroup: false, // Esto desactiva la agrupación para todas las columnas
    // }));

    // console.log(resetState, '<<<<<------resetState------');

    // Aplicar el nuevo estado de las columnas
    gridApiRef.current.resetColumnGroupState();
  };

  const setGroupingHandler = (columnsConfig, colsGroup) => {
    if (gridApiRef.current) {
      // Limpiar la agrupación y el estado previo
      gridApiRef.current.resetColumnState();

      // Establecer las nuevas columnas
      gridApiRef.current.setColumnDefs(columnsConfig);

      // Si tienes una nueva agrupación, configúrala aquí
      const groupConfig = colsGroup; // Ejemplo
      gridApiRef.current.applyColumnState({
        state: groupConfig.map((colId) => ({
          colId,
          rowGroup: true,
        })),
        applyOrder: true,
      });
    }
  };

  const handleChangeCols = async (event) => {
    if (gridApiRef.current) {
      // let colsToHide = [];

      const allColumns = gridApiRef.current.getColumns();
      allColumns.forEach((column) => {
        column.setVisible(true);
      });

      if (event.target.value === '0') {
        resetFilters();
        setVisibleColumnsState(finalColumns);
      }

      if (event.target.value === '1') {
        // getValuesFilter();
        setConfirmedFilter();
        setGroupingHandler(finalColumns, ['orchard']);
        // setVisibleColumnsState(finalColumns);
        // setVisibleColumnsState(finalColumnsOrchard);
      }

      if (event.target.value === '2') {
        setConfirmedFilter();
        setGroupingHandler(finalColumns, ['agroBusiness']);
        // setVisibleColumnsState(finalColumns);
        // setVisibleColumnsState(finalColumnsAgroBusiness);
      }
      if (event.target.value === '3') {
        setConfirmedFilter();
        setGroupingHandler(finalColumns, ['technicBoss']);
      }
      if (event.target.value === '4') {
        setConfirmedFilter();
        setGroupingHandler(finalColumns, ['variety']);
      }

      setCustomGroupColsState(event.target.value);

      customGroupColsRef.current = event.target.value;
    }
  };

  const handleFormatExport = async () => {
    const minCheck = new Date();
    const maxCheck = addDays(new Date(), 1);

    const dataQuery = {
      clientValue: userClient.value,
      specieValue: specie_filter,
      date:
        dateCheckState === 0
          ? dateFormatIso(minCheck)
          : dateFormatIso(maxCheck),
    };
    setLoadingExportState(true);

    try {
      const response = await axiosContext.authAxios.post(
        endPointExport,
        dataQuery
      );

      const { data } = response;

      // console.log(data, '<<<<<-------------------', dataQuery);

      if (data.status === 200) {
        console.log(
          data.message,
          '<<<<<-------------------',
          data.result.sucess.url
        );

        if (data?.result?.sucess?.url) {
          // const blob = new Blob([data.result.sucess.url], {
          //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          // });
          // const link = document.createElement('a');
          // link.href = window.URL.createObjectURL(blob);
          // link.download = 'export.xlsx';
          // link.click();

          const link = document.createElement('a');
          link.href = data.result.sucess.url; // La URL que recibes del endpoint
          link.download = `${new Date()}_reporte_diario.xlsx`; // Nombre del archivo que se descargará
          link.target = '_blank'; // Asegúrate de abrir en una nueva pestaña si es necesario
          document.body.appendChild(link); // Necesario para Firefox
          link.click(); // Disparar el evento de clic para descargar
          document.body.removeChild(link); // Limpiar el DOM

          // window.open(data.result.sucess.url, '_blank');
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingExportState(false);
    }
  };

  // const technicBossList = MOCKDATA.counterBoxes[0].technicBoss;
  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <>
          <>
            <BsRow class='no-gutters'>
              <BsCol class='col-md-12'>
                <Card elevation={0}>
                  <CardContent sx={{ p: 0 }}>
                    <>
                      {/* PARA AVISAR DATOS NO GUARDADOS */}
                      {/* <FormPrompt hasUnsavedChanges={copyButtonState} />
                      <Dialog
                        open={openDialogState}
                        keepMounted
                        onClose={handleCloseDialog}
                        aria-describedby='alert-dialog-slide-description'
                      >
                        <DialogContent>
                          <DialogContentText id='alert-dialog-slide-description'>
                            Tiene datos editados sin guardar.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog}>Cancelar</Button>
                          <Button onClick={handleCloseAndConfirmDialog}>
                            Aceptar y Filtrar
                          </Button>
                        </DialogActions>
                      </Dialog> */}
                      <BsRow class='no-gutters'>
                        <BsCol class='col-md-12 pb-5'>
                          {/* Cajas  */}
                          <Typography variant='h4'>
                            {titleCheckState}
                          </Typography>
                          <div className='pb-4 pt-4'>
                            <Box>
                              <div style={{ display: 'flex' }}>
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1.4 }}
                                  sx={{
                                    minHeight: {
                                      xs: 200,
                                      lg: 102,
                                    },
                                  }}
                                  columns={{ xs: 8, lg: 6 }}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    lg={1}
                                    sx={{
                                      mb: {
                                        xs: 2,
                                        lg: 0,
                                      },
                                    }}
                                  >
                                    <CardPaper
                                      elevation={0}
                                      topColor=''
                                      borderTop={0}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        backgroundColor: '#FFF9EB',
                                        padding: 2,
                                      }}
                                    >
                                      <Typography
                                        variant='h3'
                                        sx={{
                                          fontWeight: 700,
                                          color: '#203543',
                                        }}
                                      >
                                        {dashDataState?.planningBoxes
                                          ?.kilosConfirm
                                          ? numberFormatDecimals(
                                              dashDataState.planningBoxes
                                                ?.kilosConfirm,
                                              1
                                            )
                                          : '-'}
                                      </Typography>
                                      {dateCheckState === 0 ? (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#2A4C60',
                                          }}
                                        >
                                          Kilos Confirmados
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#2A4C60',
                                          }}
                                        >
                                          Kilos Estimados
                                        </Typography>
                                      )}
                                    </CardPaper>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    lg={1}
                                    sx={{
                                      mb: {
                                        xs: 2,
                                        lg: 0,
                                      },
                                    }}
                                  >
                                    <CardPaper
                                      elevation={0}
                                      topColor=''
                                      borderTop={0}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        backgroundColor: '#F1FCFA',
                                        padding: 2,
                                      }}
                                    >
                                      <Typography
                                        variant='h3'
                                        sx={{
                                          fontWeight: 700,
                                          color: '#2A4C60',
                                        }}
                                      >
                                        {dashDataState?.planningBoxes
                                          ?.kilosNoConfirm
                                          ? numberFormatDecimals(
                                              dashDataState.planningBoxes
                                                ?.kilosNoConfirm,
                                              1
                                            )
                                          : '-'}
                                      </Typography>
                                      {dateCheckState === 0 ? (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#203543',
                                          }}
                                        >
                                          Kilos Sin Confirmar
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#203543',
                                          }}
                                        >
                                          Kilos Sin Estimar
                                        </Typography>
                                      )}
                                    </CardPaper>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    lg={1}
                                    sx={{
                                      mb: {
                                        xs: 2,
                                        lg: 0,
                                      },
                                    }}
                                  >
                                    <CardPaper
                                      elevation={0}
                                      topColor=''
                                      borderTop={0}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        backgroundColor: '#F1F9FE',
                                        padding: 2,
                                      }}
                                    >
                                      <Typography
                                        variant='h3'
                                        sx={{
                                          fontWeight: 700,
                                          color: '#2A4C60',
                                        }}
                                      >
                                        {dashDataState?.planningBoxes
                                          ?.agroBusinessNoConfirm
                                          ? numberFormatDecimals(
                                              dashDataState.planningBoxes
                                                ?.agroBusinessNoConfirm,
                                              1
                                            )
                                          : '-'}
                                      </Typography>
                                      {dateCheckState === 0 ? (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#203543',
                                          }}
                                        >
                                          Agricolas No Confirmadas
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#203543',
                                          }}
                                        >
                                          Agricolas Sin Estimar
                                        </Typography>
                                      )}
                                    </CardPaper>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    lg={1}
                                    sx={{
                                      mb: {
                                        xs: 2,
                                        lg: 0,
                                      },
                                    }}
                                  >
                                    <CardPaper
                                      elevation={0}
                                      topColor=''
                                      borderTop={0}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        backgroundColor: '#FBF4F7',
                                        padding: 2,
                                      }}
                                    >
                                      <Typography
                                        variant='h3'
                                        sx={{
                                          fontWeight: 700,
                                          color: '#2A4C60',
                                        }}
                                      >
                                        {dashDataState?.planningBoxes
                                          ?.specieNoConfirm
                                          ? numberFormatDecimals(
                                              dashDataState.planningBoxes
                                                ?.specieNoConfirm,
                                              1
                                            )
                                          : '-'}
                                      </Typography>
                                      {dateCheckState === 0 ? (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#203543',
                                          }}
                                        >
                                          Especies No Confirmadas
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 400,
                                            color: '#203543',
                                          }}
                                        >
                                          Especies Sin Estimar
                                        </Typography>
                                      )}
                                    </CardPaper>
                                  </Grid>
                                  <Grid item xs={6} lg={2}>
                                    <CardPaper
                                      elevation={0}
                                      topColor=''
                                      borderTop={0}
                                      sx={{
                                        display: 'flex',
                                        // alignItems: 'center',
                                        // justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        backgroundColor: '#F8FCFB',
                                        padding: 3,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        {dateCheckState === 0 ? (
                                          <Typography
                                            variant='body2'
                                            gutterBottom
                                            sx={{
                                              fontWeight: 700,
                                              color: '#2A4C60',
                                              fontSize: '1rem',
                                            }}
                                          >
                                            Jefes Técnicos Sin Confirmar
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant='body2'
                                            gutterBottom
                                            sx={{
                                              fontWeight: 700,
                                              color: '#2A4C60',
                                              fontSize: '1rem',
                                            }}
                                          >
                                            Jefes Técnicos Sin Estimar
                                          </Typography>
                                        )}
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                          sx={{
                                            fontWeight: 700,
                                            color: '#2A4C60',
                                            fontSize: '1.5rem',
                                          }}
                                        >
                                          {
                                            dashDataState?.planningBoxes?.technicBoss.filter(
                                              (item) => !item.confirm
                                            ).length
                                          }
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                          maxHeight: '100px', // Limitamos la altura del contenedor
                                          overflowY: 'auto', // Habilitamos el scroll vertical si la lista excede esta altura
                                          padding: 2,
                                          borderRadius: 2,
                                        }}
                                      >
                                        {dashDataState?.planningBoxes?.technicBoss
                                          .sort((a, b) => a.confirm - b.confirm)
                                          .map((item) => (
                                            <List
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                              }}
                                              key={item.label}
                                            >
                                              <Typography
                                                variant='body2'
                                                sx={{ color: '#2A4C60' }}
                                              >
                                                {item.label}
                                              </Typography>
                                              {item.confirm ? (
                                                <CheckBoxIcon
                                                  sx={{ color: '#00C982' }}
                                                />
                                              ) : (
                                                <IndeterminateCheckBoxIcon
                                                  sx={{ color: '#B52F2F' }}
                                                />
                                              )}
                                            </List>
                                          ))}
                                      </Box>
                                    </CardPaper>
                                  </Grid>
                                </Grid>
                              </div>
                            </Box>
                          </div>
                        </BsCol>
                      </BsRow>

                      <>
                        <BsRow class='no-gutters'>
                          <BsCol class='col-md-12 pb-5'>
                            <div className='ag-theme-quartz' style={styleState}>
                              <div
                                className='d-flex justify-content-between pb-3 pt-2'
                                style={{ backgroundColor: 'white' }}
                              >
                                <Box
                                  sx={{
                                    justifyContent: 'flex-start',
                                    display: 'flex',
                                  }}
                                >
                                  <form
                                    className='form-inline'
                                    autoComplete='on'
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div className='input-group ml-md-2'>
                                      <TextFieldWidgetDateController2
                                        name='since'
                                        labeltext='Desde'
                                        placeholder='Desde'
                                        display='vertical'
                                        variant='outlined'
                                        defaultValue={new Date()}
                                        format='dd-MM-yyyy'
                                        style={{}}
                                        minDate={minCheck}
                                        maxDate={maxCheck}
                                        minheight={false}
                                        slotProps={{
                                          textField: {
                                            size: 'small',
                                          },
                                        }}
                                        sx={{
                                          width: '150px',
                                        }}
                                        // value={sinceSelectedState}
                                        // onChange={date => setSinceSelectedState(date)}
                                        control={control}
                                        errors={errors.since}
                                        // defaultValue={startColdDate}
                                      />
                                    </div>
                                    <div className='input-group ml-md-2'>
                                      {/* <TextFieldWidgetDateController2
                                        name='until'
                                        labeltext='Hasta'
                                        placeholder='Hasta'
                                        display='vertical'
                                        variant='outlined'
                                        format='dd-MM-yyyy'
                                        style={{}}
                                        minheight={false}
                                        slotProps={{
                                          textField: {
                                            size: 'small',
                                          },
                                        }}
                                        sx={{
                                          width: '150px',
                                        }}
                                        // value={sinceSelectedState}
                                        // onChange={date => setSinceSelectedState(date)}
                                        minDate={minDateState}
                                        control={control}
                                        errors={errors.until}
                                        defaultValue={new Date()}
                                        // defaultValue={endColdDate}
                                      /> */}
                                    </div>
                                    <SaveAction
                                      title='Filtrar'
                                      color='secondary'
                                      size='medium'
                                      className='roundedButton ml-md-2'
                                      variant='contained'
                                      disabled={loadingSubmitFilterState}
                                      messageType='add'
                                      mutationLoading={loadingSubmitFilterState}
                                      success={false}
                                    />
                                  </form>
                                </Box>
                                <Box
                                  sx={{
                                    // marginRight: 2,
                                    marginBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: 2,
                                  }}
                                >
                                  <TextField
                                    variant='outlined'
                                    placeholder='Buscar...'
                                    label='Buscar'
                                    size='small'
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position='start'>
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                      endAdornment: quickFilterText ? (
                                        <IconButton
                                          color='primary'
                                          aria-label='clear'
                                          component='span'
                                          onClick={() => onResetSearch()}
                                        >
                                          <ClearIcon
                                            fontSize='small'
                                            style={{
                                              color: theme.palette.grey[400],
                                            }}
                                          />
                                        </IconButton>
                                      ) : null,
                                    }}
                                    value={quickFilterText}
                                    onChange={handleQuickFilterChange}
                                    sx={{
                                      width: 200,
                                    }}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 2,
                                  }}
                                >
                                  {userIdProfile !==
                                    UserProfile.UserProfile[2].id &&
                                    userIdProfile !==
                                      UserProfile.UserProfile[5].id && (
                                      <LoadingButton
                                        color='primary'
                                        variant='contained'
                                        onClick={handleFormatExport}
                                        loading={loadingExportState}
                                        disabled={
                                          loadingSubmitFilterState ||
                                          rowData.length === 0 ||
                                          loadingExportState
                                        }
                                      >
                                        Descargar Gerencia
                                      </LoadingButton>
                                    )}
                                  <IconButton
                                    color='primary'
                                    aria-label='export'
                                    onClick={handleMenuOpen}
                                    disabled={
                                      loadingSubmitFilterState ||
                                      rowData.length === 0
                                    }
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem onClick={onBtnExportExcel}>
                                      Exportar a Excel
                                    </MenuItem>
                                    <MenuItem onClick={onBtnExportCsv}>
                                      Exportar a CSV
                                    </MenuItem>
                                  </Menu>
                                  <IconButton
                                    color='primary'
                                    aria-label='fullscreen-toggle'
                                    onClick={() =>
                                      setFullScreen(!fullScreenState)
                                    }
                                    disabled={loadingSubmitFilterState}
                                  >
                                    {fullScreenState ? (
                                      <FullscreenExitIcon />
                                    ) : (
                                      <FullscreenIcon />
                                    )}
                                  </IconButton>
                                </Box>
                              </div>
                              {loadingSubmitFilterState && <LoadingWidget />}

                              {!loadingSubmitFilterState &&
                                (dataTableState &&
                                dataTableState?.table &&
                                dataTableState?.table.length ? (
                                  <AgGridReact
                                    rowData={rowData}
                                    columnDefs={visibleColumnsState}
                                    // onCellValueChanged={onCellValueChanged}
                                    pagination={true}
                                    paginationPageSize={50}
                                    suppressMovableColumns={true}
                                    suppressDragLeaveHidesColumns={true}
                                    paginationPageSizeSelector={
                                      paginationPageSizeSelector
                                    }
                                    localeText={localeText}
                                    defaultColDef={{
                                      flex: 1,
                                      minWidth: 150,
                                      filter: false,
                                      floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                                    }}
                                    //   masterDetail={true}
                                    //   detailCellRendererParams={
                                    //     detailCellRendererParams
                                    //   }
                                    groupDisplayType={'multipleColumns'}
                                    rowGroupPanelShow={'always'}
                                    enableRangeSelection={true}
                                    enableFillHandle={true}
                                    quickFilterText={quickFilterText}
                                    onGridReady={onGridReady}
                                    sideBar={sideBar}
                                    embedFullWidthRows={true}
                                    isRowMaster={isRowMaster}
                                    detailRowAutoHeight={true}
                                    getRowId={(params) => params.data.id}
                                    grandTotalRow={'bottom'}
                                    // getRowStyle={(params) => {
                                    //   if (params.node.footer) {
                                    //     return {
                                    //       'font-weight': 'bold',
                                    //       'background-color': '#f0f0f0',
                                    //     };
                                    //   }
                                    //   return null;
                                    // }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '50%',
                                      width: '100%',
                                    }}
                                  >
                                    <Typography
                                      variant='h5'
                                      color='textSecondary'
                                    >
                                      No hay datos
                                    </Typography>
                                  </div>
                                ))}
                            </div>
                          </BsCol>
                        </BsRow>
                      </>
                    </>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
          </>
        </>
      </BsContainer>
    </div>
  );
};

export default CheckDays;
