// import _ from "lodash";
import { useState, useEffect, useContext, useMemo } from 'react';
import { Button, useTheme } from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';
// import clsx from 'clsx';
// import Editable from 'react-bootstrap-editable';
import { makeStyles } from '@mui/styles';
import { ReactDataTable } from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import { OptionsContext } from '../../../context';
import { Context } from '../../../context/Context';
import { setOrderForApi, setOrderForNewApi } from '../../../helpers';
import { BsRow, BsCol } from '../../../layouts/components';
import {
  colsNoVisBbDormancy,
  colsNoVisBbDormancy2,
  colsBbDormancy,
} from '../../../helpers/tableDefinitions';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    //   paddingRight: 27,
    // },
  },
}));

const endPointName = 'v1/library/break-bud-dormancy';

const SecondTab = ({ filter: filterProp }) => {
  const theme = useTheme();

  const errorHandler = useErrorHandler();

  // console.log(theme);
  const { userOrchard } = useContext(Context);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const {
    state: { library_table_dormancy },
  } = useContext(OptionsContext);

  const customHeadStyles = {};

  const columns = useMemo(() => colsBbDormancy, []);

  const [visibleColumnsState, setVisibleColumnsState] =
    useState(colsNoVisBbDormancy);

  // table
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsError(null);

      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const {
        huerto,
        centro_costo,
        cuartel,
        variedad,
        porta_injerto,
        temporada,
        specieValue,
      } = filterProp;

      let objQuery = {
        // orchardValue: huerto,
        // huerto_user: userOrchard, //orchard del login
        orchardValue: huerto,
        huerto_user: huerto ? null : userOrchard, //orchard del login
        ccValue: centro_costo,
        quarterValue: cuartel,
        varietyValue: variedad,
        rootStockValue: porta_injerto,
        season: temporada,
        specieValue: specieValue,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
      };

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointName,
          objQuery
        );

        const { data, status } = response;

        console.log(data, '----asi llega el first tab');

        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    if (filterProp) {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filterProp]);

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        {/* <Button onClick={() => setVisibleColumnsState(colsNoVisBbDormancy2)}>
          Sin Agrícola y cc
        </Button>
        <Button onClick={() => setVisibleColumnsState(colsNoVisBbDormancy)}>
          Con todo
        </Button> */}
        <div className={classes.tableContainerStyle}>
          <ReactDataTable
            columns={columns}
            data={data}
            hideColumns={visibleColumnsState}
            isError={isError}
            isLoading={isLoading}
            isRefetching={isRefetching}
            module='Rompedores_Dormancia'
            onSortingChange={setSorting}
            sorting={sorting}
            onPaginationChange={setPagination}
            pagination={pagination}
            rowCount={rowCount}
            onColumnFiltersChange={setColumnFilters}
            columnFilters={columnFilters}
            onGlobalFilterChange={setGlobalFilter}
            globalFilter={globalFilter}
            enableRowActions={false}
            // renderRowActions={actionsColumn}
            headStyles={customHeadStyles}
            enableStickyHeader={true}
            positionPagination='top'
            memoryColumns={library_table_dormancy}
            tableid='library_table_dormancy'
          />
        </div>
      </BsCol>
    </BsRow>
  );
};

export default SecondTab;
