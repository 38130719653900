import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Box,
  TextField,
  Typography,
  useTheme,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Checkbox,
  Alert,
  AlertTitle,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import { useErrorHandler } from 'react-error-boundary';
import {
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom/cjs/react-router-dom';
import { styled } from '@mui/styles';
import NumberFormat from 'react-number-format';
import {
  ButtonAction,
  CheckboxWidgetUncontrolled,
  // DataTableWidget,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetText2,
  // TextFieldWidgetDateController,
} from '../../../components';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';

import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  getOrchardVarietiesDeep,
  numberFormatDecimals,
  alterArrayGetIds,
  printOrder,
  dashDelete,
  alterArrayGetFields,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      color: theme.palette.tertiary.main,
      fontWeight: 500,
    },
    divider: {
      background: theme.palette.grey[400],
      marginTop: 10,
    },
    cardContentRoot: {
      padding: 15,
      [theme.breakpoints.up('lg')]: {
        '& div.col-xl-9': {
          width: '78%',
          maxWidth: '78%',
          flex: '0 0 78%',
        },
        '& div.col-xl-3': {
          width: '22%',
          maxWidth: '22%',
          flex: '0 0 22%',
        },
      },
    },
    tableStyles: {
      marginTop: 5,
      '& td': {
        padding: '0.45rem',
        fontSize: 14,
      },
    },
    infoCol: {
      fontWeight: 500,
    },
    theadLight: {
      '& th': {
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[300],
      },
      '& td': {
        verticalAlign: 'middle !important',
      },
    },
    colorBox: {
      width: '80px',
      height: '35px',
    },
    tbodyStyle: {
      '& td': {
        verticalAlign: 'middle',
      },
      '& tr.selected': {
        backgroundColor: '#f7faff',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrowsContainerStyle: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    subtitleDash: {
      fontSize: 18,
    },
    paperContainerStyle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // backgroundColor: theme.palette.grey[100],
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      '& h6': {
        color: theme.palette.primary.contrastText,
      },
      '& h2': {
        color: theme.palette.primary.contrastText,
      },
    },
    bgCellStyle: {
      backgroundColor: theme.palette.primary.main + '!important',
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      fontWeight: 700,
    },
    fullWidthStyle: {
      width: '100%',
    },
    backbuttonStyle: {
      position: 'absolute',
      top: 20,
      right: 10,
    },
    dialogTitleStyle: {
      fontWeight: '500',
      fontSize: 18,
    },
    dialogTextStyle: {
      fontWeight: '500',
      color: theme.palette.text.primary,
    },
    popover: {
      pointerEvents: 'none',
    },
    popOverContainer: {
      padding: theme.spacing(1),
      '& p': {
        marginBottom: theme.spacing(1),
        color: theme.palette.grey[700],
      },
    },
    rootHelper: {
      fontSize: 13,
      lineHeight: 1.3,
    },
    inputText: {
      fontSize: 26,
      '&::placeholder': {
        fontSize: '17px',
      },
    },
  };
});

const endpointDelete = 'v1/sampling/delete-plant-in-grid';
const endpointMutation = 'v1/sampling/create-plant-in-grid';

const EditSampling = (props) => {
  const { userId, userHuerto, userName } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const [successCreateState, setSuccessCreateState] = useState(false);
  const [loadingCreateState, setLoadingCreateState] = useState(false);
  const [disabledCreateState, setDisabledCreateState] = useState(false);
  const [newPlantCreatedState, setNewPlantCreatedState] = useState(null);

  const [checkAllState, setCheckAllState] = useState(false);

  const { grid, zoneColors } = useLocation();

  const errorHandler = useErrorHandler();

  const history = useHistory();

  // console.log(grid, '----grid');

  const handleGoBack = () => {
    history.goBack();
  };

  // pop over info
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopoverState = Boolean(anchorEl);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmitDelete,
    control: controlDelete,
    watch: watchDelete,
    setValue: setValueDelete,
    formState: { errors: errorsDelete },
    getValues: getValuesDelete,
    setError: setErrorDelete,
    clearErrors: clearErrorsDelete,
  } = useForm();

  const resetForm = () => {
    setValue('rowNumber', '');
    setValue('plantNumber', '');
  };

  const onResetForm = (type) => {
    setValue(type, '');
    // setExpectedInfoState([]);
  };

  // console.log(grid, '----data que llega');

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    season_filter,
    variety_filter,
    rootstock_filter,
    specie_filter,
    userOrchard_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const watchPlantNumber = watch('plantNumber');
  const watchRowNumber = watch('rowNumber');

  const watchDeleteAll = watchDelete('selectAll');

  // data generada en mapa
  const [infoPlantsState, setInfoPlantsState] = useState(null);
  const [plantsSelectedGridState, setPlantsSelectedGridState] = useState(null);
  const [colorsArrayState, setColorsArrayState] = useState(zoneColors);

  const [arrayIdsState, setArrayIdsState] = useState([]);

  const [openPromptState, setOpenPromptState] = useState(false);
  const [openPromptCheckState, setOpenPromptCheckState] = useState(false);
  const [plantToDeleteState, setPlantToDeleteState] = useState(null);
  const [loadingDeleteState, setLoadingDeleteState] = useState(false);
  const [deleteSuccessState, setDeleteSuccessState] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(null);

  const [alreadyExistState, setAlreadyExistState] = useState(false);

  const [arrayIdsToDeleteState, setArrayIdsToDeleteState] = useState([]);

  const theme = useTheme();

  // console.log(theme, '----zoneColors');
  const { classes } = useStyles();

  if (!grid) {
    // Redireccionar si el array está vacío
    return <Redirect to='/sampling' />;
  }

  const onSubmit = async (values) => {
    // console.log(values);
    setLoadingCreateState(true);
    // console.log('graba', arrayIdsState, plantsSelectedGridState);

    // console.log(plantsSelectedGridState, 'las actuales', values);

    // verificar que los values no exista en plantsSelectedGridState con findIndex
    let existPlant = -1;
    if (plantsSelectedGridState) {
      existPlant = plantsSelectedGridState.findIndex(
        (plant) =>
          plant.rowNumber.toString() === values.rowNumber &&
          plant.plantNumber.toString() === values.plantNumber
      );
    }

    // console.log(existPlant, '----existPlant');

    if (existPlant < 0) {
      const objQuery = {
        orchardValue: orchard_filter,
        ccValue: cc_filter,
        quarterValue: quarter_filter,
        varietyValue: variety_filter,
        rootStockValue: rootstock_filter,
        specieValue: specie_filter,
        rowNumber: values.rowNumber,
        plantNumber: values.plantNumber,
      };

      // console.log(objQuery);

      try {
        const response = await axiosContext.authAxios.post(
          endpointMutation,
          objQuery
        );

        // console.log(response, '------response');
        const { status, data } = response;
        if (status === 200) {
          // console.log(dataEdit, '----dataEdit');
          setSuccessCreateState(true);
          setNewPlantCreatedState(data);
          setValue('rowNumber', '');
          setValue('plantNumber', '');
        }
      } catch (error) {
        // console.log(error, '---error post');
        errorHandler(error);
      } finally {
        setLoadingCreateState(false);
      }
    } else {
      setLoadingCreateState(false);
      setAlreadyExistState(true);
    }
  };

  const onSubmitDelete = async (values) => {
    // console.log(values);

    const idsConTrue = [];

    // obtiene los ids con true
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] === true) {
        const id = key.replace('plant_', '');
        idsConTrue.push(id);
      }
    }

    // console.log(idsConTrue, '----idsConTrue');

    if (idsConTrue.length === 0) {
      setErrorDelete('oneAtLeast', {
        type: 'manual',
        message: 'Debe seleccionar al menos una Planta',
      });
      return;
    } else {
      setArrayIdsToDeleteState(idsConTrue);
      setDeleteSuccessState(false);
      setOpenPromptCheckState(true);
    }
  };

  // carga en selected los de la grilla actual
  useEffect(() => {
    if (grid && grid.length) {
      setArrayIdsState(grid.map((ele) => ele.id));
      setPlantsSelectedGridState(grid);
    }
  }, [grid]);

  const sortArrayFunc = (array) => {
    array.sort(function (a, b) {
      if (parseInt(a.rowNumber) === parseInt(b.rowNumber)) {
        return parseInt(a.plantNumber) - parseInt(b.plantNumber);
      }
      return parseInt(a.rowNumber) > parseInt(b.rowNumber) ? 1 : -1;
    });
    return array;
  };

  const onDeletePlant = async (plant) => {
    setDeleteSuccessState(false);
    setOpenPromptState(true);
    setPlantToDeleteState(plant);
    // console.log(plant);
  };

  const handleClosePrompt = () => {
    setOpenPromptState(false);
  };

  const handleClosePromptCheck = () => {
    setOpenPromptCheckState(false);
    setDeleteSuccessState(false);
  };

  const handleDeletePlant = async () => {
    // TODO: ejecutar endpoint de delete
    // console.log(plantToDeleteState, 'se borra');
    setLoadingDeleteState(true);

    let objDelete = {};

    if (plantToDeleteState) {
      objDelete = {
        id: [plantToDeleteState.id],
      };
    } else {
      objDelete = {
        id: arrayIdsToDeleteState,
      };
    }

    objDelete = {
      ...objDelete,
      orchardValue: orchard_filter,
      ccValue: cc_filter,
      quarterValue: quarter_filter,
      varietyValue: variety_filter,
      rootStockValue: rootstock_filter,
      specieValue: specie_filter, 
    };

    try {
      const response = await axiosContext.authAxios.post(
        endpointDelete,
        objDelete
      );

      // console.log(response, '------response');
      const { status, data } = response;
      if (status === 200) {
        // console.log(data, '----data');

        const { message, noDeleted } = data;

        setErrorMessageState(message);

        // deseleccionar todo
        setArrayIdsToDeleteState([]);
        arrayIdsState.forEach((id) => {
          setValueDelete(`plant_${id}`, false);
        });

        // const notDeletedIds = data.notDeletedIds;
        const notDeletedIds = noDeleted;

        const newChecked = [...arrayIdsState];

        // quitar de arrayIdsState todos los ids que están en en el Array objDelete.id

        // console.log(newChecked, '-----eliminar-----', objDelete.id);

        objDelete.id = objDelete.id.filter((id) => !notDeletedIds.includes(id));

        const newPlantsIds = newChecked.filter(
          (id) => !objDelete.id.includes(id)
        );

        // console.log(newPlantsIds, '----newPlantsIds');

        // dejar en plantsSelectedGridState solo las que el id está en newPlantsIds
        const newFilteredPlants = plantsSelectedGridState.filter((plant) =>
          newPlantsIds.includes(plant.id)
        );

        setPlantsSelectedGridState(newFilteredPlants);
        // const currentIndex = arrayIdsState.indexOf(plantToDeleteState.id);

        // if (currentIndex !== -1) {
        //   newChecked.splice(currentIndex, 1);
        //   // TODO: sacar planta que ya no va
        //   const filteredPlants = plantsSelectedGridState.filter(
        //     (plant) => plant.id !== plantToDeleteState.id
        //   );
        //   setPlantsSelectedGridState(filteredPlants);
        // }
        setArrayIdsState(newPlantsIds);
        // console.log('-----------------delete');
        setDeleteSuccessState(true);
      }
    } catch (error) {
      // console.log(error, '---error post');
      errorHandler(error);
    } finally {
      setLoadingDeleteState(false);
      setPlantToDeleteState(null);
    }
  };

  const onCloseSnack = () => {
    // console.log('------close');
    setDeleteSuccessState(false);
    handleClosePrompt();
    handleClosePromptCheck();
    setPlantToDeleteState(null);
  };

  useEffect(() => {
    if (newPlantCreatedState) {
      // console.log(newPlantCreatedState, '-----newPlantCreatedState');
      // setSuccessCreateState(false);
      // TODO: agregar la nueva planta en el grid

      // console.log(plantsSelectedGridState, '----grid');

      const newChecked = [...arrayIdsState];

      const currentIndex = arrayIdsState.indexOf(newPlantCreatedState.id);

      if (currentIndex === -1) {
        newChecked.push(newPlantCreatedState.id);
        if (plantsSelectedGridState) {
          setPlantsSelectedGridState((prevState) => [
            ...prevState,
            newPlantCreatedState,
          ]);
        } else {
          setPlantsSelectedGridState([newPlantCreatedState]);
        }
      }

      setArrayIdsState(newChecked);
    }
  }, [newPlantCreatedState]);

  const onCloseSnackCreate = () => {
    // console.log('------close');
    setSuccessCreateState(false);
  };

  useEffect(() => {
    if (watchPlantNumber || watchRowNumber) {
      setAlreadyExistState(false);
    }
  }, [watchPlantNumber, watchRowNumber]);

  console.log(plantsSelectedGridState, '--------------plantsSelectedGridState');

  // useEffect(() => {
  //   if (watchDeleteAll) {
  //     setArrayIdsToDeleteState(arrayIdsState);

  //     arrayIdsState.forEach((id) => {
  //       setValueDelete(`plant_${id}`, true);
  //     });
  //   } else {
  //     setArrayIdsToDeleteState([]);

  //     console.log('entra aca al hacer click');

  //     arrayIdsState.forEach((id) => {
  //       setValueDelete(`plant_${id}`, false);
  //     });
  //   }
  // }, [watchDeleteAll]);

  const handleChangeCheckAll = (event) => {
    setCheckAllState(event.target.checked);

    if (event.target.checked) {
      setArrayIdsToDeleteState(arrayIdsState);

      arrayIdsState.forEach((id) => {
        setValueDelete(`plant_${id}`, true);
      });
    } else {
      setArrayIdsToDeleteState([]);

      arrayIdsState.forEach((id) => {
        setValueDelete(`plant_${id}`, false);
      });
    }
  };

  const watchAllFields = watchDelete();

  useEffect(() => {
    const atLeastOneTrue = Object.values(watchAllFields).some(
      (value) => value === true
    );

    if (errorsDelete?.oneAtLeast && atLeastOneTrue) {
      clearErrorsDelete('oneAtLeast');
    }
  }, [watchAllFields]);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters mb-md-2'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-6'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Editar Grilla
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-6 text-right'>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => handleGoBack()}
                    >
                      Volver
                    </Button>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>

        <BsRow class='gutter_10'>
          {/* tabla grilla actual */}
          <BsCol class='col-md-6 col-lg-9'>
            <Card elevation={0}>
              <CardContent className={classes.cardContentRoot}>
                <BsRow class='mt-1'>
                  <BsCol class='col-md-12'>
                    <BsRow class='justify-content-between align-items-center'>
                      <BsCol class='col-md-12 mb-3'>
                        {errorMessageState ? (
                          <Alert
                            variant='filled'
                            severity='success'
                            sx={{
                              fontSize: 16,
                              mb: 2,
                            }}
                            onClose={() => {
                              setErrorMessageState(null);
                              setDeleteSuccessState(false);
                            }}
                          >
                            <AlertTitle
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                            >
                              Aviso
                            </AlertTitle>
                            {errorMessageState}
                          </Alert>
                        ) : null}
                        <Typography
                          variant='h4'
                          component='h2'
                          // color='secondary'
                          className='mb-3'
                        >
                          Grilla Actual: {arrayIdsState.length} plantas
                        </Typography>
                        <Typography
                          variant='h5'
                          component='h2'
                          color='secondary'
                        >
                          Grilla de: {dashDelete(orchard_filter)} |{' '}
                          {dashDelete(cc_filter)} | {dashDelete(quarter_filter)}{' '}
                          | {dashDelete(variety_filter)} |{' '}
                          {dashDelete(rootstock_filter)}
                        </Typography>
                      </BsCol>
                    </BsRow>
                  </BsCol>
                  {plantsSelectedGridState && (
                    <>
                      <BsCol class='col-md-12'>
                        <form
                          autoComplete='false'
                          noValidate
                          onSubmit={handleSubmitDelete(onSubmitDelete)}
                        >
                          <Box
                            className='table-responsive'
                            sx={{
                              maxHeight: 550,
                              border: '1px solid',
                              borderRadius: theme.shape.borderRadius + 'px',
                              borderColor: theme.palette.grey[300],
                            }}
                            id='ifmcontentstoprint'
                          >
                            <table
                              className={clsx(
                                'table table-sm',
                                classes.theadLight
                              )}
                            >
                              <thead>
                                <tr>
                                  <th colSpan={5}>Detalle Plantas</th>
                                </tr>
                                {errorsDelete.oneAtLeast && (
                                  <tr>
                                    <th colSpan={5}>
                                      <Typography
                                        variant='body2'
                                        color='error'
                                        component='p'
                                      >
                                        <i className='fas fa-exclamation-triangle'></i>{' '}
                                        {errorsDelete.oneAtLeast.message}
                                      </Typography>
                                    </th>
                                  </tr>
                                )}
                                <tr>
                                  <th scope='col' style={{ width: '15%' }}>
                                    <Checkbox
                                      checked={checkAllState}
                                      onChange={handleChangeCheckAll}
                                      inputProps={{
                                        'aria-label': 'controlled',
                                      }}
                                    />

                                    <IconButton
                                      aria-label='delete'
                                      color='error'
                                      type='submit'
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </th>
                                  <th scope='col'>Zona</th>
                                  <th scope='col'>Nº Hilera</th>
                                  <th scope='col'>Nº Planta</th>
                                  <th scope='col'>Acción</th>
                                </tr>
                              </thead>
                              <tbody className={classes.tbodyStyle}>
                                {_.sortBy(
                                  sortArrayFunc(plantsSelectedGridState),
                                  ['zone']
                                ).map((plant, i) => {
                                  return (
                                    <tr key={`${plant.id}_${i}`}>
                                      <td>
                                        <CheckboxWidgetUncontrolled
                                          name={`plant_${plant.id}`}
                                          control={controlDelete}
                                          req={false}
                                        />
                                      </td>
                                      <td>
                                        <div
                                          style={{
                                            width: '20px',
                                            display: 'inline-block',
                                            height: '20px',
                                            marginRight: '15px',
                                            marginBottom: '-4px',
                                            backgroundColor:
                                              colorsArrayState[plant.zone - 1],
                                          }}
                                        ></div>
                                        {plant.zone}
                                      </td>
                                      <td>{plant.rowNumber || '-'}</td>
                                      <td>{plant.plantNumber || '-'}</td>
                                      <td>
                                        <Tooltip
                                          title='Eliminar Planta'
                                          placement='right'
                                          arrow
                                        >
                                          <IconButton
                                            aria-label='delete'
                                            size='medium'
                                            color='error'
                                            disabled={loadingCreateState}
                                            onClick={() => onDeletePlant(plant)}
                                          >
                                            <ClearIcon fontSize='inherit' />
                                          </IconButton>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Box>
                        </form>
                      </BsCol>
                    </>
                  )}
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
          {/* tabla edición */}
          <BsCol class='col-md-6 col-lg-3'>
            <Card elevation={0}>
              <CardContent className={classes.cardContentRoot}>
                <BsRow class='mt-2'>
                  <BsCol class='col-md-12'>
                    <BsRow>
                      <BsCol class='col-md-12 text-center py-4'>
                        {alreadyExistState && (
                          <Typography
                            variant='h6'
                            component='h2'
                            color='error'
                            className='mb-3'
                          >
                            La planta ya existe en la grilla
                          </Typography>
                        )}
                        <form
                          autoComplete='false'
                          noValidate
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <BsRow>
                            <BsCol class='col-md-12 mb-3 text-left'>
                              <Typography variant='h5'>
                                Registrar de Planta
                                <span className='mx-3'>
                                  <IconButton
                                    aria-describedby='dd'
                                    variant='text'
                                    color='primary'
                                    size='small'
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </span>
                              </Typography>

                              <Popover
                                id='simple-popover'
                                open={openPopoverState}
                                className={classes.popover}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <div className={classes.popOverContainer}>
                                  <Typography variant='caption' component={'p'}>
                                    El sistema le asignará la zona
                                    correspondiente a la planta generada.
                                  </Typography>
                                </div>
                              </Popover>
                            </BsCol>
                            <BsCol class='col-md-12'>
                              <TextFieldWidgetText2
                                name='rowNumber'
                                display='vertical'
                                labeltext=''
                                placeholder='Nº de Hilera'
                                req
                                defaultValue=''
                                type='number'
                                className='mb-2'
                                disabled={false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  sx: {
                                    // borderRadius: 40,
                                  },
                                }}
                                control={control}
                                errors={errors.rowNumber}
                              />
                            </BsCol>
                            <BsCol class='col-md-12 mb-2'>
                              <TextFieldWidgetText2
                                name='plantNumber'
                                display='vertical'
                                labeltext=''
                                placeholder='Nº de Planta'
                                req
                                defaultValue=''
                                type='number'
                                className='mb-2'
                                disabled={false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  sx: {
                                    // borderRadius: 40,
                                  },
                                }}
                                control={control}
                                errors={errors.plantNumber}
                              />
                            </BsCol>
                            <BsCol class='col-md-6 pr-md-1'>
                              <Button
                                variant='outlined'
                                color='secondary'
                                sx={{
                                  borderColor: theme.palette.grey[300],
                                  color: theme.palette.grey[300],
                                  p: 1,
                                }}
                                fullWidth={true}
                                onClick={() => resetForm('')}
                              >
                                Limpiar
                              </Button>
                            </BsCol>
                            <BsCol class='col-md-6 pl-md-1'>
                              <SaveAction
                                title='Agregar'
                                color='secondary'
                                size='large'
                                fullWidth={true}
                                actionClose={onCloseSnackCreate}
                                disabled={disabledCreateState}
                                // className='btn-block btn-Intelligence-calc'
                                variant='contained'
                                messageType='add'
                                mutationLoading={loadingCreateState}
                                success={successCreateState}
                              />
                            </BsCol>
                          </BsRow>
                        </form>
                      </BsCol>
                    </BsRow>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
      {plantToDeleteState ? (
        <Dialog
          open={openPromptState}
          onClose={handleClosePrompt}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            className={clsx('delete-prompt', classes.dialogTitleStyle)}
          >
            Eliminar Planta
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              className={classes.dialogTextStyle}
            >
              Seguro desea eliminar la planta: Hilera:{' '}
              {plantToDeleteState.rowNumber} | Nº Planta:{' '}
              {plantToDeleteState.plantNumber}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleClosePrompt}
              disabled={loadingDeleteState || deleteSuccessState}
            >
              Cancelar
            </Button>
            {/* <Button
              color='error'
              onClick={handleDeletePlant}
              autoFocus
              disabled={loadingDeleteState}
            >
              Eliminar
            </Button> */}
            <ButtonAction
              labelButton='Eliminar'
              color='error'
              // size='large'
              actionClose={onCloseSnack}
              variant='text'
              fullWidth={true}
              actionOnClick={handleDeletePlant}
              disabled={loadingDeleteState || deleteSuccessState}
              messageType='edit'
              mutationLoading={loadingDeleteState}
              success={deleteSuccessState}
            />
          </DialogActions>
        </Dialog>
      ) : null}
      {arrayIdsToDeleteState ? (
        <Dialog
          open={openPromptCheckState}
          onClose={handleClosePromptCheck}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            className={clsx('delete-prompt', classes.dialogTitleStyle)}
          >
            Eliminar Plantas
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              className={classes.dialogTextStyle}
            >
              Seguro desea eliminar las Plantas Seleccionadas
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleClosePromptCheck}
              disabled={loadingDeleteState || deleteSuccessState}
            >
              Cancelar
            </Button>
            {/* <Button
              color='error'
              onClick={handleDeletePlant}
              autoFocus
              disabled={loadingDeleteState}
            >
              Eliminar
            </Button> */}
            <ButtonAction
              labelButton='Eliminar'
              color='error'
              // size='large'
              actionClose={onCloseSnack}
              variant='text'
              fullWidth={true}
              actionOnClick={handleDeletePlant}
              disabled={loadingDeleteState || deleteSuccessState}
              messageType='edit'
              mutationLoading={loadingDeleteState}
              success={deleteSuccessState}
            />
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};
export default EditSampling;
