// import _ from "lodash";
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Modal,
  Tooltip,
  useTheme,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import {
  IconActionTableDetail,
  IconActionTableDelete,
  ReactDataTable,
  EditionAreaWidget,
  ButtonAction,
  IconActionTableEdit,
} from '../../../components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  handleActionEdit,
  handleActionDelete,
  dateFrontConvert,
  setOrderForNewApi,
} from '../../../helpers';
import ModalDetail from '../ModalDetail';
import { useErrorHandler } from 'react-error-boundary';
import {
  colsNoVisScheduleTable,
  colsScheduleTable,
} from '../../../helpers/tableDefinitions';
import EditDay from '../EditDay';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 27,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCol: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  backButton: {
    position: 'absolute',
    top: 10,
    zIndex: 100,
  },
}));

// TODO: agrega agroBusinessValue al endPoint
const endPointName = 'v1/planningHarvest/list';
const endPointDeleteName = 'v1/planningHarvest';

// función que recibe orchards ['Almahue', 'Bodega'] y que lo convierte en formato objeto con el key orchard
const orchardsToObj = (orchards) => {
  const orchardsObj = orchards.map((orchard) => {
    return { orchard: orchard };
  });
  return orchardsObj;
};

const ListTable = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const history = useHistory();

  const { userOrchard, userClient } = useContext(Context);

  // console.log(scenarySaved, '<---scenarySaved');

  const axiosContext = useContext(AxiosContext);

  // modal
  const [openModalState, setOpenModalState] = useState(false);
  const [dataModalState, setDataModalState] = useState(null);

  // dialog delete and edit
  const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
  const [rowToDeleteState, setRowToDeleteState] = useState('');
  const [loadingDeleteState, setLoadingDeleteState] = useState(false);
  const [successDeleteState, setSuccessDeleteState] = useState(false);

  const [editionState, setEditionState] = useState(false);
  const [rowToEditState, setRowToEditState] = useState('');

  const customHeadStyles = {
    '&:nth-last-of-type(-n+1)': {
      backgroundColor: theme.palette.primary.main,
      borderBottom: '4px solid',
      borderColor: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
    },
  };

  const columns = useMemo(() => colsScheduleTable, []);

  // table
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsError(null);

      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      // console.log(filterProp, '<----filterProp en el listado');

      const { orchard, variety, sinceDate, untilDate } = filterProp;

      // TODO: ahora con agrobusinessValue

      const objQuery = {
        orchardValue: orchard,
        // userOrchards: orchardsToObj(userOrchard), // orchard del login
        userOrchards: userOrchard, // orchard del login
        agroBusinessValue: filterProp?.agroBusiness?.value || '', // cliente del login
        varietyValue: variety?.value || '',
        specieValue: filterProp?.specie?.value || '',
        productLineValue: filterProp?.productionLine?.value || '',
        dateSince: sinceDate,
        dateUntil: untilDate,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
        // sort: setOrderForApi(sorting),
      };

      // const paramsQuery = {
      //   params: objQuery,
      // };

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointName,
          objQuery
        );

        const { data, status } = response;

        // console.log(data, '----asi llega el first tab');

        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    if (filterProp) {
      fetchData();
      setEditionState(false);
      setRowToEditState('');
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filterProp]);

  // modal
  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleOpenModal = (row) => {
    setDataModalState(row);
    setOpenModalState(true);
  };

  // console.log(new Date().getFullYear().toString(), '------actual year');
  const actualYear = new Date().getFullYear().toString();

  // delete
  const DialogDeleteHandler = (row) => {
    // console.log(row, '------row to delete');
    setRowToDeleteState(row);
    setEditionState(false);
    setOpenDialogDeleteState(true);
  };

  const onCloseSnack = () => {
    // console.log('-----------------------close');
    setSuccessDeleteState(false);
    handleClosePrompt();
  };

  const handleClosePrompt = () => {
    // console.log('-----------------------close prompt');
    setSuccessDeleteState(false);
    setOpenDialogDeleteState(false);
  };

  const deleteActionDialogHandler = async () => {
    setLoadingDeleteState(true);
    try {
      const objDel = rowToDeleteState.id;

      // console.log(objDel, '----------------------la query que va');

      const responseDel = await axiosContext.authAxios.delete(
        `${endPointDeleteName}/${objDel}`
      );

      if (responseDel.status === 200) {
        setSuccessDeleteState(true);
        setRowCount((prevState) => prevState - 1);
        const newDaysSchedule = handleActionDelete(rowToDeleteState, data);

        setData(newDaysSchedule);
      }
    } catch (err) {
      // console.log('-----------', err);
      setLoadingDeleteState(false);
    } finally {
      setLoadingDeleteState(false);
    }
  };

  // edit
  const closeEditionAreaHandler = () => {
    setEditionState(false);
  };

  const editActionHandler = (newObject) => {
    // console.log('asi pasa al editAction', newObject);
    const newDaysSchedule = handleActionEdit(newObject, data);

    // console.log('new users to table', newDaysSchedule);
    setData(newDaysSchedule);
  };

  const actionsColumn = (row) => {
    return (
      <div className={clsx('actionColumn', classes.actionCol)}>
        {/* <IconActionTableDetail
          handleActionDetail={() => {
            const html = row.original;
            handleOpenModal(html);
            console.info('estado modal: ', row);
          }}
        /> */}
        <IconActionTableEdit
          handleActionEdit={() => {
            setRowToEditState(row.original);
            setEditionState(true);
          }}
        />
        <IconActionTableDelete
          handleActionDelete={() => DialogDeleteHandler(row.original)}
        />
      </div>
    );
  };

  return (
    <>
      {editionState && (
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <EditionAreaWidget
              component={
                <EditDay
                  rowEdit={rowToEditState}
                  closeFunc={closeEditionAreaHandler}
                  editFunc={editActionHandler}
                />
              }
              visible={editionState}
            />
          </BsCol>
        </BsRow>
      )}
      <BsRow class=''>
        <BsCol class='col-md-12'>
          <Card elevation={0}>
            <CardContent>
              {/* <Typography gutterBottom variant='h4' component='h2'>
                Listado Calendarización
              </Typography> */}
              <BsRow class='justify-content-between'>
                <BsCol class='col-12'>
                  <div className={classes.tableContainerStyle}>
                    <ReactDataTable
                      columns={columns}
                      data={data}
                      hideColumns={colsNoVisScheduleTable}
                      isError={isError}
                      isLoading={isLoading}
                      isRefetching={isRefetching}
                      module='Planificacion'
                      onSortingChange={setSorting}
                      sorting={sorting}
                      onPaginationChange={setPagination}
                      pagination={pagination}
                      rowCount={rowCount}
                      onColumnFiltersChange={setColumnFilters}
                      columnFilters={columnFilters}
                      onGlobalFilterChange={setGlobalFilter}
                      globalFilter={globalFilter}
                      enableRowActions={true}
                      renderRowActions={actionsColumn}
                      headStyles={customHeadStyles}
                      enableStickyHeader={true}
                      positionPagination='bottom'
                    />
                  </div>
                  <Modal
                    open={openModalState}
                    onClose={handleCloseModal}
                    className={classes.modal}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                  >
                    <>
                      <ModalDetail
                        closeAction={handleCloseModal}
                        dataModal={dataModalState}
                        dataColumn={columns}
                      />
                    </>
                  </Modal>
                  {rowToDeleteState ? (
                    <Dialog
                      open={openDialogDeleteState}
                      onClose={handleClosePrompt}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                    >
                      <DialogTitle
                        id='alert-dialog-title'
                        className={clsx(
                          'delete-prompt',
                          classes.dialogTitleStyle
                        )}
                      >
                        Eliminar Registro
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id='alert-dialog-description'
                          className={classes.dialogTextStyle}
                        >
                          Seguro desea eliminar el registro: <br />
                          {rowToDeleteState.orchard} /{' '}
                          {rowToDeleteState.variety} /{' '}
                          {dateFrontConvert(rowToDeleteState.date)} / v:{' '}
                          {rowToDeleteState.version}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions sx={{ justifyContent: 'space-between' }}>
                        <Button
                          onClick={handleClosePrompt}
                          disabled={loadingDeleteState || successDeleteState}
                        >
                          Cancelar
                        </Button>
                        <ButtonAction
                          labelButton='Eliminar'
                          color='error'
                          actionClose={onCloseSnack}
                          variant='text'
                          fullWidth={true}
                          actionOnClick={deleteActionDialogHandler}
                          disabled={loadingDeleteState || successDeleteState}
                          messageType='edit'
                          mutationLoading={loadingDeleteState}
                          success={successDeleteState}
                        />
                      </DialogActions>
                    </Dialog>
                  ) : null}
                </BsCol>
              </BsRow>
            </CardContent>
          </Card>
        </BsCol>
      </BsRow>
    </>
  );
};

export default ListTable;
