import { useContext, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { AxiosContext } from '../../../context/AxiosContext';
import { FiltersContext } from '../../../context';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../../components';
import { MobileFilters } from '../../Library';
import { alphabeticalSort } from 'helpers';

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((orchard) => {
    orchard.cc.forEach((cc) => {
      cc.quarter.forEach((quarter) => {
        // si el cuartel tiene alguna variedad activa
        if (quarter.variety.some((varietyItem) => varietyItem.seasonActive)) {
          quarter.variety.forEach((variety) => {
            if (variety.seasonActive) {
              // si la variedad está activa
              result.push({
                agroBusiness: {
                  label: orchard.agroBusiness,
                  value: orchard.agroBusinessValue,
                },
                orchard: {
                  label: orchard.label,
                  value: orchard.value,
                },
                cc: {
                  label: cc.label,
                  value: cc.value,
                },
                specie: {
                  label: cc.specie,
                  value: cc.specieValue,
                },
                quarter: {
                  label: quarter.label,
                  value: quarter.value,
                },
                variety: {
                  label: variety.label,
                  value: variety.value,
                },
                rootStock: {
                  label: variety.rootStock,
                  value: variety.rootStockValue,
                },
              });
            }
          });
        }
      });
    });
  });

  return result;
}

/**
 * Returns an array of unique values from the given array of objects based on the specified unique property.
 *
 * @param {Array} arrayData - The array of objects to extract unique values from.
 * @param {string} uniqueProperty - The property to determine uniqueness.
 * @param {Array} returnProperties - The properties to include in the returned objects.
 * @param {Array} returnLabels - The labels for the returned properties (optional).
 * @returns {Array} - An array of objects with unique values based on the specified unique property.
 */
function getUniqueValuesMaster(
  arrayData,
  uniqueProperty,
  returnProperties,
  returnLabels
) {
  const uniqueValues = arrayData.reduce((result, current) => {
    const uniqueValue = current[uniqueProperty];

    if (!result.some((item) => item[uniqueProperty] === uniqueValue)) {
      const newObject = {};
      returnProperties.forEach((property, index) => {
        const label = returnLabels[index] || property;
        newObject[label] = current[property];
      });
      result.push(newObject);
    }

    return result;
  }, []);

  return uniqueValues;
}

// por contexto en el submit, pasará los filtros al componente

const UnitFilter = ({
  submitFunc,
  autoSubmit = false,
  module,
  requiredGroup,
}) => {
  const axiosContext = useContext(AxiosContext);
  const errorHandler = useErrorHandler();

  // console.log(requiredGroup, '----requireGroup');

  const {
    state: {
      userOrchard_filter,
      cc_filter,
      quarter_filter,
      orchard_filter,
      variety_filter,
      rootstock_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // ---------estados
  const [orchardFullDataState, setOrchardFullDataState] = useState(
    module === 'prunning'
      ? userOrchard_filter.filter((ele) => ele.statusPruningRecommendation)
      : userOrchard_filter
  );
  const [orchardDataState, setOrchardDataState] = useState();
  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [rootStockDataState, setRootStockDataState] = useState([]);

  const [flattenDataState, setFlattenDataState] = useState([]);

  //   watchs
  const watchOrchard = useWatch({ control, name: 'orchard' });
  const watchCc = useWatch({ control, name: 'cc' });
  const watchQuarter = useWatch({ control, name: 'quarter' });
  const watchVariety = useWatch({ control, name: 'variety' });
  const watchRootstock = useWatch({ control, name: 'rootStock' });

  const onSubmit = async (values) => {
    console.log(values, '-----submit');

    const idOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard?.value
    );

    const idOrchardFilter = idOrchard[0]?.id || null;

    addFilter({
      orchard_filter: values.orchard.value,
      orchard_id_filter: idOrchardFilter,
      cc_filter: values.cc?.value,
      quarter_filter: values.quarter?.value,
      variety_filter: values.variety?.value,
      rootstock_filter: values.rootStock?.value,
    });

    submitFunc(values);
  };

  useEffect(() => {
    if (orchardFullDataState) {
      console.log(orchardFullDataState, '----orchardFullDataState');
      let flattenData = flattenEndpointDataHandler(orchardFullDataState);

      flattenData = flattenData.filter(
        (ele) => ele.specie?.value === specie_filter
      );

      console.log(
        flattenData,
        '----------------------------------------flattenData'
      );

      setFlattenDataState(flattenData);

      console.log(flattenData, '----flattenData');

      const orchardArray = flattenData.map((ele) => {
        return {
          ...ele.orchard,
        };
      });

      const uniqueOrchards = getUniqueValuesMaster(
        orchardArray,
        'value',
        ['label', 'value'],
        ['label', 'value']
      );

      const orchardOrdered = alphabeticalSort(uniqueOrchards, 'label');

      setOrchardDataState(orchardOrdered);

      const varietyArray = flattenData.map((ele) => {
        return {
          ...ele.variety,
        };
      });

      const uniqueVarieties = getUniqueValuesMaster(
        varietyArray,
        'value',
        ['label', 'value'],
        ['label', 'value']
      );

      const varietyOrdered = alphabeticalSort(uniqueVarieties, 'label');

      setVarietyDataState(varietyOrdered);
    }
  }, [orchardFullDataState]);

  // console.log(watchOrchard, '----watchOrchard');

  // UseEffect para cambios de filtros
  useEffect(() => {
    const filterUpdatedData = flattenDataState.filter((ele) => {
      const orchardFilter = watchOrchard
        ? ele?.orchard.value === watchOrchard.value
        : true;

      const ccFilter = watchCc ? ele?.cc.value === watchCc.value : true;

      const quarterFilter = watchQuarter
        ? ele?.quarter.value === watchQuarter.value
        : true;

      const varietyFilter = watchVariety
        ? ele?.variety.value === watchVariety.value
        : true;

      const rootstockFilter = watchRootstock
        ? ele?.rootStock.value === watchRootstock.value
        : true;

      return (
        orchardFilter &&
        ccFilter &&
        quarterFilter &&
        varietyFilter &&
        rootstockFilter
      );
    });

    console.log(filterUpdatedData, '----filterUpdatedData');

    const orchardArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.orchard,
      };
    });

    const uniqueOrchards = getUniqueValuesMaster(
      orchardArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setOrchardDataState(alphabeticalSort(uniqueOrchards, 'label'));

    const varietyArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.variety,
      };
    });

    const uniqueVariety = getUniqueValuesMaster(
      varietyArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setVarietyDataState(alphabeticalSort(uniqueVariety, 'label'));

    const ccArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.cc,
      };
    });

    const uniqueCc = getUniqueValuesMaster(
      ccArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setCcDataState(alphabeticalSort(uniqueCc, 'label'));

    const quarterArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.quarter,
      };
    });

    console.log(quarterArray, '----quarterArray filtered');

    const uniqueQuarter = getUniqueValuesMaster(
      quarterArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setQuarterDataState(alphabeticalSort(uniqueQuarter, 'label'));

    const rootStockArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.rootStock,
      };
    });

    const uniqueRootStock = getUniqueValuesMaster(
      rootStockArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setRootStockDataState(alphabeticalSort(uniqueRootStock, 'label'));
  }, [
    watchOrchard,
    watchCc,
    watchQuarter,
    watchVariety,
    watchRootstock,
    flattenDataState,
  ]);

  useEffect(() => {
    if (!watchOrchard) {
      setValue('cc', '');
    }
  }, [watchOrchard]);

  useEffect(() => {
    if (!watchCc) {
      setValue('quarter', '');
    }
  }, [watchCc]);

  useEffect(() => {
    if (!watchVariety) {
      setValue('rootStock', '');
    }
  }, [watchVariety]);

  return (
    <MobileFilters>
      <form
        className='form-inline mt-3'
        autoComplete='on'
        onSubmit={handleSubmit(onSubmit)}
      >
        <>
          <div className='input-group'>
            {/* <TextFieldWidgetSelectController
              options={orchardDataState}
              control={control}
              titleOption='label'
              name='orchard'
              labeltext='Huerto'
              errors={errors.orchard}
              defaultValue=''
              loading={false}
              req={requiredGroup ? requiredGroup.orchard : true}
              withDefault
              variant='standard'
            /> */}
            <SelectMultipleWidget2
              errors={errors.orchard}
              loading={false}
              control={control}
              menuPortalTarget={document.body}
              labeltext=''
              req={requiredGroup ? requiredGroup.orchard : true}
              name='orchard'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={orchardDataState}
              placeholder='Huerto'
            />
          </div>
          <div className='input-group ml-md-2'>
            {/* <TextFieldWidgetSelectController
              options={ccDataState}
              control={control}
              name='cc'
              titleOption='label'
              labeltext='Centro de Costo'
              errors={errors.cc}
              defaultValue=''
              loading={false}
              disabled={!watchOrchard}
              req={requiredGroup ? requiredGroup.cc : true}
              withDefault
              variant='standard'
            /> */}
            <SelectMultipleWidget2
              errors={errors.cc}
              loading={false}
              control={control}
              menuPortalTarget={document.body}
              labeltext=''
              req={requiredGroup ? requiredGroup.cc : true}
              name='cc'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isDisabled={!watchOrchard && !watchVariety}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={ccDataState}
              placeholder='Centro de Costo'
            />
          </div>
          <div className='input-group ml-md-2'>
            {/* <TextFieldWidgetSelectController
              options={quarterDataState}
              titleOption='label'
              control={control}
              name='quarter'
              labeltext='Cuarteles'
              errors={errors.quarter}
              defaultValue=''
              loading={false}
              disabled={!watchOrchard}
              req={requiredGroup ? requiredGroup.quarter : true}
              withDefault
              variant='standard'
            /> */}
            <SelectMultipleWidget2
              errors={errors.quarter}
              loading={false}
              control={control}
              menuPortalTarget={document.body}
              labeltext=''
              req={requiredGroup ? requiredGroup.quarter : true}
              name='quarter'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isDisabled={!watchOrchard}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={quarterDataState}
              placeholder='Cuarteles'
            />
          </div>
          <div className='input-group ml-md-2'>
            {/* <TextFieldWidgetSelectController
              options={varietyDataState}
              titleOption='label'
              control={control}
              name='variety'
              labeltext='Variedad'
              errors={errors.variety}
              defaultValue=''
              loading={false}
              disabled={!watchOrchard}
              req={requiredGroup ? requiredGroup.variety : true}
              withDefault
              variant='standard'
            /> */}
            <SelectMultipleWidget2
              errors={errors.variety}
              loading={false}
              control={control}
              menuPortalTarget={document.body}
              labeltext=''
              req={requiredGroup ? requiredGroup.variety : true}
              name='variety'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isDisabled={false}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={varietyDataState}
              placeholder='Variedad'
            />
          </div>
          <div className='input-group ml-md-2'>
            {/* <TextFieldWidgetSelectController
              options={rootStockDataState}
              titleOption='label'
              control={control}
              name='rootStock'
              labeltext='Portainjerto'
              errors={errors.rootStock}
              defaultValue=''
              loading={false}
              disabled={!watchOrchard}
              req={requiredGroup ? requiredGroup.rootstock : true}
              withDefault
              variant='standard'
            /> */}
            <SelectMultipleWidget2
              errors={errors.rootStock}
              loading={false}
              control={control}
              menuPortalTarget={document.body}
              labeltext=''
              req={requiredGroup ? requiredGroup.rootstock : true}
              name='rootStock'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              isDisabled={!watchOrchard}
              defaultValue=''
              options={rootStockDataState}
              placeholder='Portainjerto'
            />
          </div>
        </>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'flex-start',
            paddingTop: 5,
          }}
        >
          <SaveAction
            title='Filtrar'
            color='secondary'
            size='large'
            className='roundedButton ml-md-2'
            variant='contained'
            disabled={false}
            messageType='add'
            mutationLoading={false}
            success={false}
          />
        </div>
      </form>
    </MobileFilters>
  );
};

export default UnitFilter;
