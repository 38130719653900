// import _ from 'lodash';
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Hidden,
  Modal,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useForm } from 'react-hook-form';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { AxiosContext } from '../../context/AxiosContext';
import {
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../components';
import { FiltersContext } from '../../context';
import { ModalTable, WeatherBox } from '../../containers/Dashboard';
import clsx from 'clsx';
import {
  numberFormat,
  numberFormatDecimals,
  generalOptionsHighCharts,
} from '../../helpers';
import _ from 'lodash';
import { useErrorHandler } from 'react-error-boundary';
import { countStageColors, varietyGraphColors } from '../../theme/palette';

// import { ButtonBox } from '../../components/Dashboard';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
    marginTop: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
}));

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
  },
});

const getVarietySurface = (array) => {
  const newArray = array.map((item) => {
    return {
      name: item.variety,
      y: item.value,
    };
  });
  return newArray;
};

const getPropertyNames = (array, property) => {
  const newArray = array.map((item) => {
    return item[property];
  });
  return newArray;
};

const Dashboard = () => {
  // let history = useHistory();

  const theme = useTheme();

  console.log(theme, '----------------theme');

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const errorHandler = useErrorHandler();

  const chartRef = useRef();

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const moduleOrchardFilter = 'statusDashboard';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [dataModalState, setDataModalState] = useState(null);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);
  const [dashDataState, setDashDataState] = useState(null);
  const [performanceReverseDataState, setPerformanceReverseDataState] =
    useState(null);

  // const context = useContext(Context);
  // const { userId, userIdProfile, userFullname } = context;
  const [openModalState, setOpenModalState] = useState(false);

  const optionsHighCharts = {
    ...generalOptionsHighCharts,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: `Superficie Total <br> ${
        dashDataState && dashDataState.surface
          ? numberFormat(dashDataState.surface)
          : ''
      } há`,
      style: {
        fontSize: 15,
        fontWeight: 600,
      },
    },
    chart: {
      type: 'pie',
      height: 320,
      spacingBottom: 10,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0,
      backgroundColor: 'transparent',
    },
    tooltip: {
      // valueSuffix: '%',
      pointFormat: '<b>{point.percentage:.2f}%</b><br />{point.y:.1f} Há',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        showInLegend: false,
        colors: varietyGraphColors,
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          formatter: function () {
            return (
              this.point.name +
              '<br/>' +
              numberFormatDecimals(this.percentage, 2) +
              ' % <br />' +
              numberFormat(this.point.y) +
              ' Há'
            );
          },
          distance: 18,
          // color: 'white',
          style: {
            fontSize: 12,
          },
        },
      },
    },
    series: [
      {
        innerSize: '65%',
        data: dashDataState
          ? getVarietySurface(dashDataState.surfaceVariety)
          : [],
      },
    ],
  };

  const optionsHighChartsBarPerformance = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 310,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return '<b>' + numberFormat(this.y) + 't.</b><br/>' + this.x;
      },
    },
    xAxis: {
      categories:
        dashDataState && dashDataState.variety ? dashDataState.variety : [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Rendimiento (t.)',
      },
    },
    colors: varietyGraphColors,
    series: performanceReverseDataState || [],
  };

  // grafico STACKED
  const optionsHighChartsBarStacked = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 320,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f} t.</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    xAxis: {
      categories:
        dashDataState && dashDataState.variety ? dashDataState.variety : [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
          textOutline: 'none',
        },
      },
      title: {
        text: '%',
      },
    },
    plotOptions: {
      column: {
        stacking: 'percent',
        dataLabels: {
          // para mostrar % en cada stack
          formatter: function () {
            return Math.round((100 * this.y) / this.total) + '%';
          },
          enabled: true,
        },
      },
    },
    colors: countStageColors,
    series: dashDataState?.yeliabilityVarietyObjectPorcentage || [],
  };

  const optionsHighChartsAreaStacked = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'area',
      height: 320,
      spacingBottom: 10,
      spacingLeft: 10,
      spacingRight: 10,
      spacingTop: 20,
    },
    title: {},
    yAxis: {
      labels: {
        format: '{value}%',
      },
      title: {
        enabled: false,
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.1f} ton.)<br/>',
      split: true,
    },
    plotOptions: {
      series: {
        pointStart: 2021,
      },
      area: {
        stacking: 'percent',
        marker: {
          enabled: false,
        },
      },
    },
    colors: varietyGraphColors,
    series: [
      {
        name: 'Santina',
        data: [40.6, 40, 42, 43.6],
      },
      {
        name: 'Bing',
        data: [55.1, 55.1, 55.2, 53.3],
      },
      {
        name: 'Lapins',
        data: [3.9, 3.8, 3.7, 3.6],
      },
      {
        name: 'Ranier',
        data: [80.5, 88, 88, 90],
      },
    ],
  };

  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleOpenModal = (row) => {
    setDataModalState(row);
    setOpenModalState(true);
  };

  useEffect(() => {
    if (orchard_filter) {
      setValue('orchard', orchard_filter);
    }
  }, []);

  const onSubmit = async (values) => {
    setLoadingDashDataState(true);

    console.log(values, '<--------', userOrchard_filter);

    let data = {
      params: { huerto: values.orchard.value },
    };

    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard.value
    );

    // console.log(values.orchard, userOrchard_filter, '<-----');

    if (orchard_id_filter !== resultOrchard[0].id) {
      addFilter({
        cc_filter: '',
        quarter_filter: '',
      });
    }
    addFilter({
      orchard_filter: values.orchard.value,
      orchard_id_filter: resultOrchard[0].id,
    });

    // console.log(data, '<<<<<------------');

    try {
      const response = await axiosContext.authAxios.get('v1/dashboard', data);
      const bodyInfo = response.data;
      // const bodyInfo = INFO[0];

      console.log(response, '<-------LLEGA RESPONSE DATA--------');

      if (bodyInfo) {
        setDashDataState(bodyInfo);
        setLoadingDashDataState(false);
      }
    } catch (error) {
      // console.log(error);
      // función del boundary, pasa el error
      // se aplica en los cach
      errorHandler(error);
      setLoadingDashDataState(false);
    }
  };

  useEffect(() => {
    if (dashDataState) {
      let reversed = [...dashDataState.performanceVariety].reverse();
      // console.log(dashDataState.performanceVariety, '--------', reversed);
      setPerformanceReverseDataState(reversed);
    }
  }, [dashDataState]);

  const openFullscreen = () => {
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-8'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Resumen de Huerto
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        <SelectMultipleWidget2
                          errors={errors.orchard}
                          loading={false}
                          control={control}
                          menuPortalTarget={document.body}
                          labeltext=''
                          // req
                          name='orchard'
                          display='vertical'
                          variant='outlined'
                          isMulti={false}
                          isClearable={true}
                          isSearchable
                          onlyOptionValue={false}
                          defaultValue=''
                          options={filterDataResponseState}
                          placeholder='Huerto'
                        />
                      </div>
                      <SaveAction
                        title='Filtrar'
                        color='secondary'
                        size='large'
                        className='roundedButton ml-md-2'
                        variant='contained'
                        messageType='add'
                        mutationLoading={false}
                        success={false}
                      />
                    </form>
                  </BsCol>
                  {!loadingDashDataState && (
                    <BsCol class='col-md-4 align-self-end text-right'>
                      <Typography variant='h6' gutterBottom>
                        {new Date().toLocaleDateString('es-ES', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Typography>
                    </BsCol>
                  )}
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        {!loadingDashDataState ? (
          dashDataState ? (
            <>
              <BsRow class='no-gutters'>
                <BsCol class='col-md-12'>
                  <BsRow class='gutter_10'>
                    <BsCol class='col-md-6 col-lg-4'>
                      <Card
                        elevation={0}
                        variant='contained'
                        className={classes.dashCard}
                        sx={{
                          backgroundColor: theme.palette.grey[50],
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Superficie por Variedad
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighCharts}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>
                    <BsCol class='col-md-6 col-lg-8'>
                      <Card elevation={0} className={classes.dashCard}>
                        <CardContent>
                          <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <Grid item>
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Rendimiento{' '}
                                {dashDataState.performanceVariety[1].name}
                              </Typography>
                              <Typography variant='h2' gutterBottom>
                                {numberFormat(
                                  dashDataState.performanceLastYear
                                )}{' '}
                                toneladas
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Rendimiento estimado{' '}
                                {dashDataState.performanceVariety[0].name}
                              </Typography>
                              <Typography variant='h2' gutterBottom>
                                {numberFormat(
                                  dashDataState.performanceCurrentYear
                                )}{' '}
                                toneladas
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography variant='body2'>
                            Resumen de la producción en los años{' '}
                            {`${new Date().getFullYear() - 2} - ${
                              new Date().getFullYear() - 1
                            }`}{' '}
                            y la proyección que nuestros datos estiman.
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarPerformance}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>
                  </BsRow>
                </BsCol>
              </BsRow>

              <BsRow class='no-gutters mt-4'>
                <BsCol class='col-md-12'>
                  <HighchartsWrapper
                    fullScreenHandler={openFullscreen}
                    title='Rendimiento por Temporadas'
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsHighChartsAreaStacked}
                      ref={chartRef}
                    />
                  </HighchartsWrapper>
                </BsCol>
              </BsRow>

              <BsRow class='no-gutters mt-4'>
                <BsCol class='col-md-12'>
                  <BsRow class='gutter_10'>
                    <BsCol class='col-md-6'>
                      <Card
                        variant='outlined'
                        className={classes.dashCard}
                        sx={{ borderColor: theme.palette.grey[200] }}
                      >
                        <CardContent>
                          <Typography variant='h5' gutterBottom>
                            Confiabilidad Porcentajes
                          </Typography>
                          <Typography variant='body1' gutterBottom paragraph>
                            Distribución de rendimiento según última labor
                            agrícola
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarStacked}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>
                    <BsCol class='col-md-6'>
                      <Card
                        variant='outlined'
                        className={classes.dashCard}
                        sx={{ borderColor: theme.palette.grey[200] }}
                      >
                        <CardContent>
                          <Typography variant='h5' gutterBottom>
                            Confiablidad Cantidades
                          </Typography>
                          <div className={classes.tableAreaStyle}>
                            <table className='table'>
                              <thead
                                style={{
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 1,
                                }}
                                className={classes.theadLight}
                              >
                                <tr>
                                  <th scope='col'>Variedad</th>
                                  <th scope='col'>Pre Poda</th>
                                  <th scope='col'>Post Poda</th>
                                  <th scope='col'>Pre Raleo</th>
                                  <th scope='col'>Post Raleo</th>
                                </tr>
                              </thead>
                              {/* TODO: actualmente tiene 14 como cantidad para los colores */}
                              <tbody>
                                {dashDataState.variety &&
                                  dashDataState.variety.map((variety, i) => (
                                    <tr key={`${variety}_${i}`}>
                                      <td>{variety}</td>
                                      <td
                                        className={
                                          dashDataState.yeliabilityVariety[0]
                                            .data[i]
                                            ? parseInt(
                                                dashDataState
                                                  .yeliabilityVariety[0].data[i]
                                              ) < 14
                                              ? 'table-danger'
                                              : 'table-success'
                                            : ''
                                        }
                                      >
                                        {numberFormatDecimals(
                                          dashDataState.yeliabilityVariety[0]
                                            .data[i],
                                          1
                                        )}
                                      </td>
                                      <td
                                        className={
                                          dashDataState.yeliabilityVariety[1]
                                            .data[i]
                                            ? parseInt(
                                                dashDataState
                                                  .yeliabilityVariety[1].data[i]
                                              ) < 14
                                              ? 'table-danger'
                                              : 'table-success'
                                            : ''
                                        }
                                      >
                                        {numberFormatDecimals(
                                          dashDataState.yeliabilityVariety[1]
                                            .data[i],
                                          1
                                        )}
                                      </td>
                                      <td
                                        className={
                                          dashDataState.yeliabilityVariety[2]
                                            .data[i]
                                            ? parseInt(
                                                dashDataState
                                                  .yeliabilityVariety[2].data[i]
                                              ) < 14
                                              ? 'table-danger'
                                              : 'table-success'
                                            : ''
                                        }
                                      >
                                        {numberFormatDecimals(
                                          dashDataState.yeliabilityVariety[2]
                                            .data[i],
                                          1
                                        )}
                                      </td>
                                      <td
                                        className={
                                          dashDataState.yeliabilityVariety[3]
                                            .data[i]
                                            ? parseInt(
                                                dashDataState
                                                  .yeliabilityVariety[3].data[i]
                                              ) < 14
                                              ? 'table-danger'
                                              : 'table-success'
                                            : ''
                                        }
                                      >
                                        {numberFormatDecimals(
                                          dashDataState.yeliabilityVariety[3]
                                            .data[i],
                                          1
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </CardContent>
                      </Card>
                    </BsCol>
                  </BsRow>
                </BsCol>
              </BsRow>

              <Modal
                open={openModalState}
                onClose={handleCloseModal}
                className={classes.modal}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
              >
                <>
                  <ModalTable
                    closeAction={handleCloseModal}
                    dataModal={dataModalState}
                  />
                </>
              </Modal>
            </>
          ) : (
            <></>
          )
        ) : (
          <LoadingWidget />
        )}
      </BsContainer>
    </div>
  );
};

export default Dashboard;
