import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import { Sidebar, Topbar, Footer } from './components';
import { IsDesktopHandler } from '../../helpers';

//import './MainNoFooter.scss';
import { makeStyles } from '@mui/styles';
import { Backdrop } from '@mui/material';
import { FiltersContext } from '../../context/filters/FiltersContext';
import { LoadingWidget } from '../../components';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: '100%',
    paddingRight: theme.spacing(0),
    // paddingLeft: theme.spacing(9),
    // [theme.breakpoints.up('xs')]: {
    // 	paddingLeft: theme.spacing(9),
    // },
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(8),
    },
    paddingBottom: 0,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}));

const MainNoFooter = (props) => {
  const { children } = props;

  const classes = useStyles();

  const isDesktop = IsDesktopHandler('lg');

  const [openSidebar, setOpenSidebar] = useState(false);

  const { state: filterState } = useContext(FiltersContext);

  const { isLoading } = filterState;

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;
  // console.log(isLoading, '---------------------------isLoading filters');

  return (
    <div className={classes.root}>
      <>
        <CssBaseline />
        <Topbar
          onSidebarOpen={handleSidebarOpen}
          openState={openSidebar}
          drawerWidth={drawerWidth}
        />
        <Sidebar
          onClose={handleSidebarClose}
          onSidebarOpen={handleSidebarOpen}
          open={openSidebar}
          variant={'permanent'}
        />
        <Backdrop
          className={classes.backdrop}
          open={openSidebar}
          onClick={handleSidebarClose}
          style={{ zIndex: 1000 }}
        ></Backdrop>
        <div className={classes.content}>
          <main className={clsx('contentStyle')}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
      </>
    </div>
  );
};

MainNoFooter.propTypes = {
  children: PropTypes.node,
};

export default MainNoFooter;
