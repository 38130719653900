// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import { Box, useTheme } from '@mui/material';

// Internal modules
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { yearGraphColors } from 'theme/palette';

// Components
import {
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from '../../../components';

// Functions, constants, etc.
import {
  alterArrayDashDelete,
  alterArrayGetVariety,
  dateFormatIso,
  dateFormatIsoLocale,
  generalOptionsHighCharts,
  numberFormatDecimals,
} from '../../../helpers';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);

const GraphPrecipitation = ({ orchards: orchardsProp }) => {
  const chartRef = useRef(null);

  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const theme = useTheme();

  const errorHandler = useErrorHandler();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const [loadingState, setLoadingState] = useState(false);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [minDateState, setminDateState] = useState(null);
  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const actualYear = new Date().getFullYear();

  const highchartsOptions = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 300,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          numberFormatDecimals(this.y, 2) + ' mm<br/><b>' + this.x + '</b>'
        );
      },
    },
    xAxis: {
      categories: alterArrayDashDelete(graphOptionsState?.orchard),
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Precipitaciones (mm)',
      },
    },
    // legend: {
    // 	reversed: true, // right here!
    // },
    colors: yearGraphColors,
    series: graphOptionsState?.precipitation,
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Precipitaciones Acumuladas',
    },
  };

  console.log(graphOptionsState, '-------graphOptionsState');

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      //   TODO: ver cuando quiero ver mis huertos
      const { orchards, sinceDate, untilDate } = dataQuery;

      const objQuery = {
        orchard: orchards,
        sinceDate,
        untilDate,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        'v1/weather/precipitation',
        objQuery
      );

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        // setTableDataState(data.data);
        // console.log('eeeeeeee', data);
        const sortedYearsData = data.precipitation
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));

        setGraphOptionsState({
          orchard: data.orchard,
          precipitation: sortedYearsData,
        });
      }
      setLoadingState(false);
    } catch (err) {
      setGraphOptionsState([]);
      setLoadingState(false);
      errorHandler(err);
    }
  }

  const onSubmit = async (values) => {
    const dateBegin = values.since;
    const dateEnd = values.until;

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const data = {
      orchards:
        orchardsProp?.orchards && orchardsProp?.orchards.length
          ? orchardsProp?.orchards
          : userOrchard,
      sinceDate: dateFormatIsoLocale(dateBegin),
      untilDate: dateFormatIsoLocale(dateEnd),
    };

    console.log(data, '<--------data');

    queryData(data);
  };

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    handleSubmit(onSubmit)();
  }, [orchardsProp]);

  return (
    <HighchartsWrapper
      fullScreenHandler={openFullscreen}
      title='Precipitaciones Acumuladas'
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            width: {
              sm: '100%',
              md: '70%',
            },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <form
            className='form-inline'
            autoComplete='on'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='input-group ml-md-2'>
              <TextFieldWidgetDateController2
                name='since'
                labeltext='Desde'
                placeholder='Desde'
                display='vertical'
                variant='outlined'
                format='dd-MM-yyyy'
                minheight={false}
                style={{}}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{
                  width: '150px',
                }}
                // value={sinceSelectedState}
                // onChange={date => setSinceSelectedState(date)}
                control={control}
                errors={errors.since}
                defaultValue={subDays(new Date(), 14)}
              />
            </div>
            <div className='input-group ml-md-2'>
              <TextFieldWidgetDateController2
                name='until'
                labeltext='Hasta'
                placeholder='Hasta'
                display='vertical'
                variant='outlined'
                format='dd-MM-yyyy'
                style={{}}
                minheight={false}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{
                  width: '150px',
                }}
                // value={sinceSelectedState}
                // onChange={date => setSinceSelectedState(date)}
                minDate={minDateState}
                control={control}
                errors={errors.until}
                defaultValue={subDays(new Date(), 1)}
              />
            </div>
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='medium'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={false}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </form>
          {errors.incorrectDates && (
            <p
              style={{
                color: theme.palette.error.main,
              }}
            >
              {errors.incorrectDates.message}
            </p>
          )}
        </Box>
      </div>
      {loadingState && <LoadingWidget />}
      {!loadingState && graphOptionsState && (
        <HighchartsReact
          highcharts={Highcharts}
          options={highchartsOptions}
          ref={chartRef}
        />
      )}
    </HighchartsWrapper>
  );
};

export default GraphPrecipitation;
