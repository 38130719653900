import React, {
  useState,
  useEffect,
  useContext,
  useDebugValue,
  useRef,
} from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useErrorHandler } from 'react-error-boundary';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import InfoIcon from '@mui/icons-material/Info';
import NumberFormat from 'react-number-format';
import {
  Card,
  CardContent,
  Divider,
  // FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Button,
  Paper,
  Popover,
  Switch,
  TextField,
  Typography,
  useTheme,
  Tooltip,
  Tab,
  Tabs,
  Box,
  SvgIcon,
  Modal,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import iconJson from '../../common/iconSVG.json';
import DartIcon from '../../assets/icons/Vectordardo.png';
import TwigIcon from '../../assets/icons/Vectorramilla.png';
import FlowerIcon from '../../assets/icons/Groupflor.png';
import FruitsetIcon from '../../assets/icons/Capa2cuaja.png';
import PerformanceIcon from '../../assets/icons/Grouprendimiento.png';

import CaliberIcon from '../../assets/icons/Vectorcalibre.png';
import { CALIBER_OPTIONS } from '../../helpers/Mockups';
// import { INTELLIGENCE_DATA } from '../../helpers/INTELLIGENCE_DATA';
import {
  ButtonAction,
  CardPaper,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  SimpleIconButton,
  TextFieldWidgetSelectController,
  TextFieldWidgetSelectControllerForm,
} from '../../components';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
// import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from '../../components/TabPanel';

// import { async } from 'validate.js';
// import { fetchConToken, fetchSinToken } from 'helpers/fetch';
import {
  numberFormat,
  isNumber,
  numberFormatDecimals,
  flowersHaForm,
  yieldForm,
  intelligenceDataConverter,
  findAYear,
  flowersDartForm,
  removeDashes,
  IsDesktopHandler,
} from '../../helpers';
import theme from '../../theme';
import { FiltersContext, IntelligenceContext } from '../../context';
import {
  InfoBox,
  InfoRowBoxes,
  ModalGraph,
  RecommendationBox,
} from '../../containers/Intelligence';
import { UnitFilter } from 'containers/General';

const tabInternalItems = ['Por Rendimiento'];
// const tabInternalItems = ['Por Rendimiento', 'Por datos'];

const useStyles = makeStyles()((theme) => {
  return {
    fontSavedStyle: {
      color: theme.palette.tertiary.main,
      fontWeight: 500,
    },
    noPruneData: {
      fontWeight: 400,
    },
    divider: {
      background: theme.palette.grey[400],
      marginTop: 10,
    },
    dividerWithoutPrune: {
      borderColor: theme.palette.secondary.main,
      borderBottomWidth: 3,
      marginBottom: 5,
    },
    rootHelper: {
      fontSize: 13,
      lineHeight: 1.3,
    },
    inputText: {
      fontSize: 26,
      '&::placeholder': {
        fontSize: '17px',
      },
    },
    baselineContainerStyle: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: 'rgba(187, 192, 144, 0.12)',
    },
    lineDivider: {
      borderLeft: '1px dotted',
      borderLeftColor: theme.palette.grey[400],
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        marginTop: 12,
      },
    },
    popover: {
      pointerEvents: 'none',
    },
    popOverContainer: {
      padding: theme.spacing(1),
      '& p': {
        marginBottom: theme.spacing(1),
        color: theme.palette.grey[700],
      },
    },
    recommendationContainerStyle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: 'rgba(187, 192, 144, 0.12)',
    },
    textButtonStyle: {
      color: theme.palette.grey[600],
    },
    arrowsContainerStyle: {
      // marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    withoutPruneTable: {
      marginBottom: 10,
      '& thead': {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      },
      '& thead th': {
        padding: 3,
      },
      '& td': {
        borderTopStyle: 'dashed',
        borderTopColor: theme.palette.secondary.main,
        padding: '7px 7px',
      },
      '& tfoot tr th': {
        paddingTop: 8,
        fontSize: 16,
        borderTop: '1px solid' + theme.palette.secondary.main + '!important',
      },
    },
    graphTabPanel: {
      height: 'auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});

const generalOptionsHighCharts = {
  chart: {
    type: 'area',
    height: 160,
    spacingBottom: 8,
    spacingLeft: 0,
    spacingRight: 5,
    spacingTop: 10,
  },
  title: {
    text: '',
    style: { display: 'none' },
  },
  yAxis: {
    min: 0,
    // max: 140,
    tickInterval: 20,
    title: {
      text: 'Cantidad',
      enabled: false,
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    area: {
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, 'rgba(52, 94, 181,0.5)'],
          [1, 'rgba(52, 94, 181,0.1)'],
        ],
      },
      color: theme.palette.secondary.main,
      // fillOpacity: 0.5,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        '<b>' + numberFormat(this.y) + '</b><br/>' + 'en el año: ' + this.x
      );
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'right',
    // x: 100,
    enabled: false,
    verticalAlign: 'top',
    // y: -150,
    floating: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const requiredGroup = {
  orchard: true,
  cc: false,
  quarter: false,
  variety: false,
  rootstock: false,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[500],

  '& .MuiToggleButtonGroup-grouped': {
    color: theme.palette.grey[700],
    '&:not(:first-of-type)': {
      borderLeftColor: theme.palette.secondary.main,
      borderLeftWidth: 2,
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#ECF2FD',
    borderWidth: 2,
    borderStyle: 'solid',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
}));

const Intelligence = (props) => {
  // const { userId } = useContext(Context);

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const tableRef = useRef(null);

  const errorHandler = useErrorHandler();

  const history = useHistory();

  const isDesktop = IsDesktopHandler('xl');

  const {
    state: {
      userOrchard_filter,
      cc_filter,
      quarter_filter,
      orchard_filter,
      variety_filter,
      rootstock_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  // console.log(userOrchard_filter, '---userOrchard_filter');

  const {
    state: {
      fruitSet_value,
      performance_value,
      caliber_value,
      dart_value,
      twig_value,
      flower_value,
      model_value,
      stage_value,
      isSet,
      fruitSet_estimated,
      performance_estimated,
      caliber_estimated,
      dart_estimated,
      twig_estimated,
      flower_estimated,
    },
    addValues,
    resetValues,
    notSetValues,
  } = useContext(IntelligenceContext);

  // const [groupButtonState, setGroupButtonState] = useState('left');

  const [loadingInfoState, setLoadingInfoState] = useState(false);
  const [loadingExpectedState, setLoadingExpectedState] = useState(false);
  const [activeCalButtonState, setActiveCalButtonState] = useState(false);
  const [activeCalAritmeticButtonState, setActiveCalAritmeticButtonState] =
    useState(false);

  const [noDataFilterState, setNoDataFilterState] = useState(false);
  const [noDataCalcState, setNoDataCalcState] = useState(false);

  const [infoIntelligence, setInfoIntelligence] = useState(null);
  const [expectedInfoState, setExpectedInfoState] = useState(null);
  // nuevo dato fruitsDartEstimateState, para la info dinámico
  const [fruitsDartEstimateState, setFruitsDartEstimateState] = useState(null);

  const [flowersHaState, setFlowersHaState] = useState(null);

  const [flowersDartState, setFlowersDartState] = useState(null);

  const [scenariesDataState, setScenariesDataState] = useState([]);

  const [selectedScenaryState, setSelectedScenaryState] = useState(null);

  const [savingScenaryState, setSavingScenaryState] = useState(false);

  const [saveSuccessState, setSaveSuccessState] = useState(false);

  const [noModelMessageState, setNoModelMessageState] = useState('');

  // ESTADO FORM CON MODELO
  const [withModelFormPerformanceState, setWithModelFormPerformanceState] =
    useState(false);
  const [withModelFormDartState, setWithModelFormDartState] = useState(false);

  const [alertMessageState, setAlertMessageState] = useState(null);

  const [withFruitsDartState, setWithFruitsDartState] = useState(false);

  // ESTADOS GRÁFICOS
  const [yearFlowerGraphState, setYearFlowerGraphState] = useState([]);
  const [dataFlowerGraphState, setDataFlowerGraphState] = useState([]);

  const [yearFruitsetGraphState, setYearFruitsetGraphState] = useState([]);
  const [dataFruitsetGraphState, setDataFruitsetGraphState] = useState([]);

  const [yearWeightGraphState, setYearWeightGraphState] = useState([]);
  const [dataWeightGraphState, setDataWeightGraphState] = useState([]);

  const [yearPerformanceGraphState, setYearPerformanceGraphState] = useState(
    []
  );
  const [dataPerformanceGraphState, setDataPerformanceGraphState] = useState(
    []
  );

  const [yearPortionsGraphState, setYearPortionsGraphState] = useState([]);
  const [dataPortionsGraphState, setDataPortionsGraphState] = useState([]);

  const [dataFruitSetFlowersGraphState, setDataFruitSetFlowersGraphState] =
    useState([]);

  // modal
  const [openModalState, setOpenModalState] = useState(false);

  const [quartersDataArrayState, setQuartersDataArrayState] = useState([]);

  // Gestión de recorrido indexes
  // estados de index
  const [actualQuarterIndexState, setactualQuarterIndexState] = useState(null);
  const [quarterMaxIndexState, setQuarterMaxIndexState] = useState(null);

  const [maxFruitSetState, setMaxFruitSetState] = useState(null);
  const [minFruitSetState, setMinFruitSetState] = useState(null);

  // ESTADO PARA LLEVAR VALORES A LA TABLA HISTORICA
  const [dataScenaryHistoryTableState, setDataScenaryHistoryTableState] =
    useState(null);

  // const handleGroupButton = (event, newAlignment) => {
  // 	setGroupButtonState(newAlignment);
  // };

  // pop over info
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopoverState = Boolean(anchorEl);

  const theme = useTheme();

  // console.log(theme, '-------');

  const { classes } = useStyles();

  const [valueTabState, setValueTabState] = useState(0);
  const [valueInternalTabState, setValueInternalTabState] = useState(0);

  const [valueTabGraphState, setValueTabGraphState] = useState(0);

  const handleChangeTabGraph = (event, newValue) => {
    setValueTabGraphState(newValue);
  };

  const actualYear = new Date().getFullYear();

  const getTooltipData = (years, data, year, type, decimals) => {
    const indexYear = years.findIndex((ye) => ye === year);
    const showData = data[indexYear];

    // console.log(showData, '----showData');

    const dataToShow = typeof showData === 'object' ? showData?.y : showData;

    // función en javascript que recibe una vari

    return `${type}: <b>${numberFormatDecimals(dataToShow, decimals)}</b>`;
  };

  const getDataGraphFromState = (value) => {
    // console.log(value, '---asi llega al convert');
    const dataToShow = typeof value === 'object' ? value?.y : value;
    return dataToShow;
  };

  const optionsFlower = {
    ...generalOptionsHighCharts,
    xAxis: {
      categories: yearFlowerGraphState,
    },
    tooltip: {
      formatter: function () {
        // return (
        //   '<b>' +
        //   numberFormat(this.y) +
        //   '</b><br/>' +
        //   'en el año: ' +
        //   this.x +
        //   '</br>' +
        //   JSON.stringify(dataFruitsetGraphState)
        // );
        return (
          'Año: <b>' +
          this.x +
          '</b><br/>' +
          'Flores: <b>' +
          numberFormatDecimals(this.y, 0) +
          '</b></br>' +
          getTooltipData(
            yearFruitsetGraphState,
            dataFruitsetGraphState,
            this.x,
            'Cuaja',
            1
          ) +
          '<br/>' +
          getTooltipData(
            yearWeightGraphState,
            dataWeightGraphState,
            this.x,
            'Calibre',
            2
          ) +
          '<br/>' +
          getTooltipData(
            yearPerformanceGraphState,
            dataPerformanceGraphState,
            this.x,
            'Rendimiento',
            0
          )
        );
      },
    },
    yAxis: {
      min: 0,
      // max: 140,
      tickInterval: 10000,
      title: {
        text: 'Cantidad',
        enabled: false,
      },
      labels: {
        formatter: function () {
          let theval = ['' + this.value].map((n) => {
            if (n / 1000000 > 1)
              return numberFormatDecimals(n / 1000000, 0) + '';
            else return n / 1000 + '';
          });
          return theval;
        },
        style: { color: '#2B2B2B' },
      },
    },
    series: [
      {
        data: dataFlowerGraphState,
        zoneAxis: 'x',
        zones: [
          {
            value: findAYear(yearFlowerGraphState, actualYear)
              ? dataFlowerGraphState.length - 2
              : null,
          },
          {
            dashStyle: 'dot',
          },
        ],
      },
    ],
  };

  const optionsFruitset = {
    ...generalOptionsHighCharts,
    // tooltip: {
    // 	formatter: function() {
    // 		return (
    // 			'<b>' +
    // 			Highcharts.numberFormat(this.y, 3,",",".") +
    // 			'</b><br/>' +
    // 			'en el año: ' +
    // 			this.x
    // 		);
    // 	},
    // },
    yAxis: {
      tickInterval: 1,
      title: {
        enabled: false,
      },
    },
    tooltip: {
      formatter: function () {
        // return (
        //   '<b>' +
        //   numberFormat(this.y) +
        //   '</b><br/>' +
        //   'en el año: ' +
        //   this.x +
        //   '</br>' +
        //   JSON.stringify(dataFruitsetGraphState)
        // );
        return (
          'Año: <b>' +
          this.x +
          '</b><br/>' +
          'Cuaja: <b>' +
          numberFormatDecimals(this.y, 1) +
          '</b></br>' +
          getTooltipData(
            yearFlowerGraphState,
            dataFlowerGraphState,
            this.x,
            'Flores',
            0
          ) +
          '<br/>' +
          getTooltipData(
            yearWeightGraphState,
            dataWeightGraphState,
            this.x,
            'Calibre',
            2
          ) +
          '<br/>' +
          getTooltipData(
            yearPerformanceGraphState,
            dataPerformanceGraphState,
            this.x,
            'Rendimiento',
            0
          )
        );
      },
    },
    xAxis: {
      categories: yearFruitsetGraphState,
    },
    series: [
      {
        data: dataFruitsetGraphState,
        zoneAxis: 'x',
        zones: [
          {
            value:
              yearFruitsetGraphState.length === dataFruitsetGraphState.length
                ? dataFruitsetGraphState.length - 2
                : null,
          },
          {
            dashStyle: 'dot',
          },
        ],
      },
    ],
  };

  const optionsWeight = {
    ...generalOptionsHighCharts,
    tooltip: {
      formatter: function () {
        // return (
        //   '<b>' +
        //   numberFormat(this.y) +
        //   '</b><br/>' +
        //   'en el año: ' +
        //   this.x +
        //   '</br>' +
        //   JSON.stringify(dataFruitsetGraphState)
        // );
        return (
          'Año: <b>' +
          this.x +
          '</b><br/>' +
          'Calibre: <b>' +
          numberFormatDecimals(this.y, 2) +
          '</b></br>' +
          getTooltipData(
            yearFlowerGraphState,
            dataFlowerGraphState,
            this.x,
            'Flores',
            0
          ) +
          '<br/>' +
          getTooltipData(
            yearFruitsetGraphState,
            dataFruitsetGraphState,
            this.x,
            'Cuaja',
            1
          ) +
          '<br/>' +
          getTooltipData(
            yearPerformanceGraphState,
            dataPerformanceGraphState,
            this.x,
            'Rendimiento',
            0
          )
        );
      },
    },
    yAxis: {
      min: 6,
      // max: 14,
      tickInterval: 2,
      title: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          let theval = this.value + '';
          return theval;
        },
        style: { color: '#2B2B2B' },
      },
    },
    xAxis: {
      categories: yearWeightGraphState,
    },
    series: [
      {
        data: dataWeightGraphState,
        zoneAxis: 'x',
        zones: [
          {
            value:
              yearWeightGraphState.length === dataWeightGraphState.length
                ? dataWeightGraphState.length - 2
                : null,
          },
          {
            dashStyle: 'dot',
          },
        ],
      },
    ],
  };

  const optionsPerformance = {
    ...generalOptionsHighCharts,
    tooltip: {
      formatter: function () {
        // return (
        //   '<b>' +
        //   numberFormat(this.y) +
        //   '</b><br/>' +
        //   'en el año: ' +
        //   this.x +
        //   '</br>' +
        //   JSON.stringify(dataFruitsetGraphState)
        // );
        return (
          'Año: <b>' +
          this.x +
          '</b><br/>' +
          'Rendimiento: <b>' +
          numberFormatDecimals(this.y, 0) +
          '</b></br>' +
          getTooltipData(
            yearFlowerGraphState,
            dataFlowerGraphState,
            this.x,
            'Flores',
            0
          ) +
          '<br/>' +
          getTooltipData(
            yearFruitsetGraphState,
            dataFruitsetGraphState,
            this.x,
            'Cuaja',
            1
          ) +
          '<br/>' +
          getTooltipData(
            yearWeightGraphState,
            dataWeightGraphState,
            this.x,
            'Calibre',
            2
          )
        );
      },
    },
    xAxis: {
      categories: yearPerformanceGraphState,
    },
    yAxis: {
      min: 0,
      // max: 140,
      tickInterval: 5000,
      title: {
        text: 'Cantidad',
        enabled: false,
      },
      labels: {
        formatter: function () {
          let theval = ['' + this.value].map((n) => {
            if (n / 1000000 > 1) return n / 1000000 + '';
            else return n / 1000 + '';
          });
          return theval;
        },
        style: { color: '#2B2B2B' },
      },
    },
    series: [
      {
        data: dataPerformanceGraphState,
        zoneAxis: 'x',
        zones: [
          {
            value:
              yearPerformanceGraphState.length ===
              dataPerformanceGraphState.length
                ? dataPerformanceGraphState.length - 2
                : null,
          },
          {
            dashStyle: 'dot',
          },
        ],
      },
    ],
  };

  const optionsColdPortions = {
    ...generalOptionsHighCharts,
    tooltip: {
      formatter: function () {
        // return (
        //   '<b>' +
        //   numberFormat(this.y) +
        //   '</b><br/>' +
        //   'en el año: ' +
        //   this.x +
        //   '</br>' +
        //   JSON.stringify(dataFruitsetGraphState)
        // );
        return (
          'Año: <b>' +
          this.x +
          '</b><br/>' +
          'Porciones Frío: <b>' +
          numberFormatDecimals(this.y, 2) +
          '</b></br>' +
          getTooltipData(
            yearFlowerGraphState,
            dataFlowerGraphState,
            this.x,
            'Flores',
            0
          ) +
          '<br/>' +
          getTooltipData(
            yearFruitsetGraphState,
            dataFruitsetGraphState,
            this.x,
            'Cuaja',
            1
          ) +
          '<br/>' +
          getTooltipData(
            yearPerformanceGraphState,
            dataPerformanceGraphState,
            this.x,
            'Rendimiento',
            0
          ) +
          '<br/>' +
          getTooltipData(
            yearWeightGraphState,
            dataWeightGraphState,
            this.x,
            'Calibre',
            2
          )
        );
      },
    },
    yAxis: {
      min: 6,
      // max: 14,
      tickInterval: 2,
      title: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          let theval = this.value + '';
          return theval;
        },
        style: { color: '#2B2B2B' },
      },
    },
    xAxis: {
      categories: yearPortionsGraphState,
    },
    series: [
      {
        data: dataPortionsGraphState,
        // zoneAxis: 'x',
        // zones: [
        //   {
        //     value: findAYear(yearPortionsGraphState, actualYear)
        //       ? dataPortionsGraphState.length - 2
        //       : null,
        //   },
        //   {
        //     dashStyle: 'dot',
        //   },
        // ],
      },
    ],
  };

  const optionsFruitSetFlowers = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      height: 180,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 5,
      spacingTop: 10,
    },
    tooltip: {
      formatter: function () {
        // return (
        //   '<b>' +
        //   numberFormat(this.y) +
        //   '</b><br/>' +
        //   'en el año: ' +
        //   this.x +
        //   '</br>' +
        //   JSON.stringify(dataFruitsetGraphState)
        // );
        return (
          'Año: <b>' +
          this.point.year +
          '</b><br/>Flores: <b>' +
          numberFormatDecimals(this.x, 0) +
          '</b><br/>Cuaja: <b>' +
          numberFormatDecimals(this.y, 1) +
          '</b>'
        );
      },
    },
    yAxis: {
      // min: 6,
      // max: 14,
      tickInterval: 2,
      title: {
        text: 'Cuaja',
      },
      labels: {
        formatter: function () {
          let theval = this.value + '';
          return theval;
        },
        style: { color: '#2B2B2B' },
      },
    },
    xAxis: {
      title: {
        enabled: true,
        text: 'Flores',
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    series: [
      {
        // data: [
        //   { x: 497000, y: 39, year: 2019 },
        //   { x: 4600000, y: 14.5, year: 2020 },
        //   { x: 1500000, y: 8, year: 2021 },
        //   { x: 6000000, y: 10.4, year: 2022 },
        // ],
        data: dataFruitSetFlowersGraphState,
        // zoneAxis: 'x',
        // zones: [
        //   {
        //     value: findAYear(yearPortionsGraphState, actualYear)
        //       ? dataPortionsGraphState.length - 2
        //       : null,
        //   },
        //   {
        //     dashStyle: 'dot',
        //   },
        // ],
      },
    ],
  };

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  // internal
  const handleChangeInternalTab = (event, newValue) => {
    setValueInternalTabState(newValue);
    // with model
    setValueCalc('performanceModel', '');
    setValueCalc('caliberModel', '');
    setValueCalc('fruitsDartModel', '');
    setValueCalc('dartModel', '');
    setValueCalc('twigModel', '');
    setValueCalc('flowerModel', '');
  };

  const {
    handleSubmit: handleSubmitCalc,
    control: controlCalc,
    setValue: setValueCalc,
    setError: setErrorCalc,
    clearErrors: clearErrorsCalc,
    watch: watchCalc,
    formState: { errors: errorsCalc },
    reset: resetCalc,
  } = useForm();

  const watchDartNoModel = watchCalc('dartNoModel');
  const watchTwigNoModel = watchCalc('twigNoModel');
  const watchFlowerNoModel = watchCalc('flowerNoModel');
  const watchCaliberNoModel = watchCalc('caliberNoModel');

  // Model
  const watchPerformanceModel = watchCalc('performanceModel');
  const watchCaliberModel = watchCalc('caliberModel');
  const watchFruitsDartModel = watchCalc('fruitsDartModel');
  const watchDartModel = watchCalc('dartModel');
  const watchTwigModel = watchCalc('twigModel');
  const watchFlowerModel = watchCalc('flowerModel');

  const useModel = useWatch({
    control: controlCalc,
    name: 'switchModel',
  });

  // -----------------------FILTER SUBMIT
  const onSubmitFilter = async (values) => {
    setLoadingInfoState(true);
    // console.log(values, '--------------función de submit');

    // LIMPIEZA CALCULADOS E INPUT
    // setExpectedInfoState(null);
    // setValueCalc('expected', '');
    setInfoIntelligence(null);
    setActiveCalButtonState(false);
    setActiveCalAritmeticButtonState(false);
    setNoDataFilterState(false);
    setNoDataCalcState(false);
    resetFormHandler();

    const params = {
      orchardValue: values.orchard.value,
      ccValue: values.cc?.value,
      quarterValue: values.quarter?.value,
      varietyValue: values.variety?.value,
      rootStockValue: values.rootStock?.value,
      specieValue: specie_filter,
    };

    try {
      const response = await axiosContext.authAxios.get(
        'v1/pruningrecommendation/scenary-basal',
        {
          params: params,
        }
      );

      // console.log(response, '------response');

      const { data, status } = response;

      console.log(response, '<-------------LLEGA RESPONSE DATA--');

      if (status === 200) {
        // TODO: setear array de escenarios
        // lo demás solo cuando hay un array cargado
        setQuartersDataArrayState(data);
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
      setNoDataFilterState(true);
      setInfoIntelligence(null);
      setDataFruitsetGraphState([]);
      setDataFlowerGraphState([]);
      setDataWeightGraphState([]);
      setDataPerformanceGraphState([]);
      setDataPortionsGraphState([]);
    }
  };

  // console.log(dataWeightGraphState, "<--------------, data weight graph");
  // console.log(useModel, '<----cambia el switch');

  // Función para ejecutar lo basal
  const getDataBasalHandler = (dataBasal) => {
    const bodyInfo = dataBasal ? intelligenceDataConverter(dataBasal) : null;

    console.log(bodyInfo, '-----------------bodyInfo', dataBasal);

    if (bodyInfo) {
      setInfoIntelligence(bodyInfo);

      // definie los máxmimos y mínimos de cuaja
      setMaxFruitSetState(bodyInfo.dataTableGraphs.maxFruitSetValue);
      setMinFruitSetState(bodyInfo.dataTableGraphs.minFruitSetValue);

      const fruitsDartEstimateState = bodyInfo?.predict?.fruitsDart;
      // ahora carga flowersHa,
      // Busca el pre poda
      setFlowersHaState(bodyInfo.information.flowersHaPrePoda);
      setFruitsDartEstimateState(fruitsDartEstimateState);
      setFlowersDartState(bodyInfo.information.flowersDart);
      // año para agregar a la data
      const actualYear = new Date().getFullYear();
      // verificar si tiene año actual (2022)
      // FLOWER
      // agrege el año actual para que esté disponible
      if (!findAYear(bodyInfo.graphicFlower.year, actualYear)) {
        setYearFlowerGraphState([...bodyInfo.graphicFlower.year, actualYear]);
      } else {
        setYearFlowerGraphState(bodyInfo.graphicFlower.year);
      }

      setDataFlowerGraphState([...bodyInfo.graphicFlower.data]);

      // WEIGHT
      if (!findAYear(bodyInfo.graphicWeight.year, actualYear)) {
        setYearWeightGraphState([...bodyInfo.graphicWeight.year, actualYear]);
      } else {
        setYearWeightGraphState(bodyInfo.graphicWeight.year);
      }

      setDataWeightGraphState([...bodyInfo.graphicWeight.data]);

      // FRUITSET
      if (!findAYear(bodyInfo.graphicFruitSet.year, actualYear)) {
        setYearFruitsetGraphState([
          ...bodyInfo.graphicFruitSet.year,
          actualYear,
        ]);
      } else {
        setYearFruitsetGraphState(bodyInfo.graphicFruitSet.year);
      }

      setDataFruitsetGraphState([...bodyInfo.graphicFruitSet.data]);

      // PERFORMANCE

      if (!findAYear(bodyInfo.graphicPerformance.year, actualYear)) {
        setYearPerformanceGraphState([
          ...bodyInfo.graphicPerformance.year,
          actualYear,
        ]);
      } else {
        setYearPerformanceGraphState(bodyInfo.graphicPerformance.year);
      }

      setDataPerformanceGraphState([...bodyInfo.graphicPerformance.data]);

      // PORTIONS

      // console.log(bodyInfo.graphicPF, '---bodyInfo.graphicPF');

      if (!findAYear(bodyInfo.graphicPF.year, actualYear)) {
        setYearPortionsGraphState([...bodyInfo.graphicPF.year, actualYear]);
      } else {
        setYearPortionsGraphState(bodyInfo.graphicPF.year);
      }

      setDataPortionsGraphState([...bodyInfo.graphicPF.data]);

      setLoadingInfoState(false);
      // setActiveCalButtonState(true);
      // siempre activa aritmético
      setActiveCalAritmeticButtonState(true);

      // solo activa Model si los estimated están
      if (
        isNumber(bodyInfo.predict.predictFruitSet) &&
        isNumber(bodyInfo.predict.predictCaliber) &&
        isNumber(bodyInfo.predict.predictYield)
      ) {
        setActiveCalButtonState(true);

        // FLOWER
        // agregar al gráfico, cuando son correctos en el año actual
        // preguntar si está año acutal, reemplazar o agregar

        const predictFlowersHa =
          bodyInfo.predict.predictFlowersHaPrePoda ||
          bodyInfo.predict.flowersHa;

        if (!findAYear(bodyInfo.graphicFlower.year, actualYear)) {
          setDataFlowerGraphState([
            ...bodyInfo.graphicFlower.data,
            predictFlowersHa,
          ]);
        } else {
          setDataFlowerGraphState((prevState) => [
            ...prevState.slice(0, -1),
            // el pre poda
            predictFlowersHa,
          ]);
        }

        // FRUITSET
        // agregar al gráfico, cuando son correctos en el año actual
        // preguntar si está año acutal, reemplazar o agregar
        // No agrega más el dato predict
        // if (!findAYear(bodyInfo.graphicFruitSet.year, actualYear)) {
        //   setDataFruitsetGraphState([
        //     ...bodyInfo.graphicFruitSet.data,
        //     bodyInfo.predict.predictFruitSet,
        //   ]);
        // } else {
        //   setDataFruitsetGraphState((prevState) => [
        //     ...prevState.slice(0, -1),
        //     bodyInfo.predict.predictFruitSet,
        //   ]);
        // }

        // WEIGHT
        // agregar al gráfico, cuando son correctos en el año actual
        // preguntar si está año acutal, reemplazar o agregar
        // No agrega más el dato predict
        // if (!findAYear(bodyInfo.graphicWeight.year, actualYear)) {
        //   setDataWeightGraphState([
        //     ...bodyInfo.graphicWeight.data,
        //     bodyInfo.predict.predictCaliber,
        //   ]);
        // } else {
        //   setDataWeightGraphState((prevState) => [
        //     ...prevState.slice(0, -1),
        //     bodyInfo.predict.predictCaliber,
        //   ]);
        // }

        // PERFORMANCE
        // agregar al gráfico, cuando son correctos en el año actual
        // preguntar si está año acutal, reemplazar o agregar
        // No agrega más el dato predict
        // if (!findAYear(bodyInfo.graphicPerformance.year, actualYear)) {
        //   setDataPerformanceGraphState([
        //     ...bodyInfo.graphicPerformance.data,
        //     bodyInfo.predict.predictYield,
        //   ]);
        // } else {
        //   setDataPerformanceGraphState((prevState) => [
        //     ...prevState.slice(0, -1),
        //     bodyInfo.predict.predictYield,
        //   ]);
        // }
      } else {
        setNoDataFilterState(true);
        // si no tiene predcitos, no activa el botón, solo se podrá aritmético
        setActiveCalButtonState(false);
        setValueCalc('switchModel', false);
      }
    } else {
      // limpia si al filtrar no existe resultad
      setLoadingInfoState(false);
      console.log('no llega nnguna data');
      setNoDataFilterState(true);
      setInfoIntelligence(null);
      setDataFruitsetGraphState([]);
      setDataFlowerGraphState([]);
      setDataWeightGraphState([]);
      setDataPerformanceGraphState([]);
    }
  };

  // ufe cada vez que cambie ArrayData
  useEffect(() => {
    if (quartersDataArrayState.length) {
      console.log('entra para ejecutar basal', quartersDataArrayState);
      setactualQuarterIndexState(0);

      const maxIndex = quartersDataArrayState.length - 1;

      setQuarterMaxIndexState(maxIndex);
      getDataBasalHandler(quartersDataArrayState[0]);
    } else {
      setLoadingInfoState(false);
      setDataFruitsetGraphState([]);
      setDataFlowerGraphState([]);
      setDataWeightGraphState([]);
      setDataPerformanceGraphState([]);
      setDataPortionsGraphState([]);
    }
  }, [quartersDataArrayState]);

  // -----------------------FORM SUBMIT
  const onSubmitCalc = async (values) => {
    console.log(values, 'submit calc');
    setLoadingExpectedState(true);
    setNoDataCalcState(false);
    setNoModelMessageState(null);
    setAlertMessageState(null);
    // setea no fruitsDart
    setWithFruitsDartState(false);

    // CON MODELO VALIDA SI VIENE ALGUNO DE LOS 3 DE TAB 1
    if (values.switchModel && valueInternalTabState === 1) {
      if (!values.dartModel && !values.twigModel && !values.flowerModel) {
        setErrorCalc('neitherItemlist', {
          type: 'manual',
          message: ' Debe ingresar al menos Dardos o Ramillas o Yemas/dardos',
        });
        setExpectedInfoState(null);
        setLoadingExpectedState(false);
        return;
      }
    }

    addValues({
      fruitSet_value: values.switchModel
        ? values.fruitSetModel
        : values.fruitSetNoModel,
      caliber_value: values.switchModel
        ? values.caliberModel
        : values.caliberNoModel,
      dart_value: values.switchModel ? values.dartModel : values.dartNoModel,
      twig_value: values.switchModel ? values.twigModel : values.twigNoModel,
      flower_value: values.switchModel
        ? values.flowerModel
        : values.flowerNoModel,
      performance_value: values.switchModel ? values.performanceModel : null,
      fruitsDart_value: values.switchModel ? values.fruitsDartModel : null,
      model_value: values.switchModel,
    });

    let bodyExpected = null;
    let resultReduce = null;

    // ------------NO MODEL
    if (!values.switchModel) {
      // aquí guarda los valores del form

      // sin modelo
      let fruitSetToNumber = null;
      if (values.fruitSetNoModel) {
        fruitSetToNumber = values.fruitSetNoModel.replace(/\./g, '');
        fruitSetToNumber = fruitSetToNumber.replace(',', '.');
      }

      let caliberToNumber = null;
      if (values.caliberNoModel) {
        caliberToNumber = values.caliberNoModel.replace(/\./g, '');
        caliberToNumber = caliberToNumber.replace(',', '.');
      }

      let dartToNumber = null;
      if (values.dartNoModel) {
        dartToNumber = values.dartNoModel.replace(/\./g, '');
        dartToNumber = dartToNumber.replace(',', '.');
      }

      let twigToNumber = null;
      if (values.twigNoModel) {
        twigToNumber = values.twigNoModel.replace(/\./g, '');
        twigToNumber = twigToNumber.replace(',', '.');
      }

      let flowerToNumber = null;
      if (values.flowerNoModel) {
        flowerToNumber = values.flowerNoModel.replace(/\./g, '');
        flowerToNumber = flowerToNumber.replace(',', '.');
      }

      // pre poda mayor a 0 y mayor a post, queda pre, sino queda post
      const dartBasal =
        infoIntelligence.information?.dartPrePoda > 0
          ? infoIntelligence.information?.dartPrePoda <
            infoIntelligence.information?.dartPostPoda
            ? infoIntelligence.information?.dartPostPoda
            : infoIntelligence.information?.dartPrePoda
          : infoIntelligence.information?.dartPostPoda;

      const twigBasal =
        infoIntelligence.information?.twigPrePoda > 0
          ? infoIntelligence.information?.twigPrePoda <
            infoIntelligence.information?.twigPostPoda
            ? infoIntelligence.information?.twigPostPoda
            : infoIntelligence.information?.twigPrePoda
          : infoIntelligence.information?.twigPostPoda;

      // valores actuales:
      const dartCalc = dartToNumber || dartBasal;

      const twigCalc = twigToNumber || twigBasal;

      const flowerCalc = flowerToNumber || infoIntelligence.information.budDart;

      const flowersTwigCalc = infoIntelligence.information.flowersTwig
        ? infoIntelligence.information.flowersTwig
        : 0;

      const fruitSetCalc =
        fruitSetToNumber || infoIntelligence.information.fruitsSetReal;

      const caliberCalc =
        caliberToNumber || infoIntelligence.information.caliberReal;

      const flowersHa = flowersHaForm(
        parseFloat(dartCalc),
        parseFloat(flowerCalc),
        parseFloat(infoIntelligence.information.flowersBudDart),
        parseFloat(twigCalc),
        parseFloat(flowersTwigCalc),
        parseFloat(infoIntelligence.information.plantsHa),
        // no mas pollinator
        // parseFloat(
        //   infoIntelligence.information.pollinator
        //     ? infoIntelligence.information.pollinator
        //     : 0
        // )
        0
      );

      console.log(flowersHa, '------A');

      const newYield = yieldForm(
        flowersHa,
        parseFloat(fruitSetCalc),
        parseFloat(caliberCalc)
      );

      console.log(newYield, '------B');

      // calculo del flowersDart
      const flowersDartFinal = flowerToNumber
        ? flowersDartForm(
            flowerToNumber,
            parseFloat(infoIntelligence.information.flowersBudDart)
          )
        : infoIntelligence.information.flowersDart;

      console.log(flowersDartFinal, '------C');

      bodyExpected = {
        dart: dartCalc,
        twig: twigCalc,
        flower: flowerCalc,
        fruitSet: fruitSetCalc,
        caliber: caliberCalc,
        yield: newYield,
        flowersHa: flowersHa,
        flowersDart: flowersDartFinal,
      };

      // nueva fórmula - 5-4
      const fruitsDartEst = (flowersDartFinal * fruitSetCalc) / 100;

      console.log(fruitsDartEst, '------D');

      setFruitsDartEstimateState(fruitsDartEst);

      // ahora carga flowersHa
      setFlowersHaState(flowersHa);

      // -----------------------HASTA ACA SIN MODELO
    } else {
      let performanceToNumber = null;
      if (values.performanceModel) {
        performanceToNumber = values.performanceModel.replace(/\./g, '');
        performanceToNumber = performanceToNumber.replace(',', '.');
      }
      // console.log('--------------------> CON modelo', values.performance);

      let caliberToNumber = null;
      if (values.caliberModel) {
        // console.log(values.caliberModel, '----asi llega');
        // caliberToNumber = values.caliberModel.replace(/\./g, '');
        // caliberToNumber = caliberToNumber.replace(',', '.');
        // desde el select va directo
        caliberToNumber = values.caliberModel;
      }

      let fruitsDartToNumber = null;
      if (values.fruitsDartModel) {
        fruitsDartToNumber = values.fruitsDartModel.replace(/\./g, '');
        fruitsDartToNumber = fruitsDartToNumber.replace(',', '.');

        setWithFruitsDartState(true);
      }

      //

      // otros
      let dartToNumber = null;
      if (values.dartModel) {
        dartToNumber = values.dartModel.replace(/\./g, '');
        dartToNumber = dartToNumber.replace(',', '.');
      }

      let twigToNumber = null;
      if (values.twigModel) {
        twigToNumber = values.twigModel.replace(/\./g, '');
        twigToNumber = twigToNumber.replace(',', '.');
      }

      let flowerToNumber = null;
      if (values.flowerModel) {
        flowerToNumber = values.flowerModel.replace(/\./g, '');
        flowerToNumber = flowerToNumber.replace(',', '.');
      }

      // const flowerCalc = flowerToNumber || infoIntelligence.information.budDart;

      // console.log(infoIntelligence, '----aca debo sacar la data');

      const params = {
        orchardValue: orchard_filter,
        ccValue: infoIntelligence.ccValue,
        quarterValue: infoIntelligence.quarterValue,
        varietyValue: infoIntelligence.varietyValue,
        rootStockValue: infoIntelligence.rootStockValue,
        specieValue: specie_filter,
      };

      let bodyQuery = {
        yieldFront: parseFloat(performanceToNumber),
        caliber: parseFloat(caliberToNumber),
      };

      if (fruitsDartToNumber) {
        bodyQuery = {
          ...bodyQuery,
          fruitsDartFront: parseFloat(fruitsDartToNumber),
        };
      }
      // va a ejecutar la query
      try {
        const response = await axiosContext.authAxios.post(
          'v1/pruningrecommendation/get-predictive-model',
          bodyQuery,
          {
            params: params,
          }
        );
        const { data, status } = response;

        // console.log(data, status, '-<<-------response', params);

        if (status === 200) {
          // console.log(response, '----response');
          //
          if (data.escenaries.length) {
            // aca debe guardar, todos los escenarios resultados

            // TODO: revisa escenarios cuaja
            console.log(
              maxFruitSetState,
              minFruitSetState,
              '-----',
              data.escenaries
            );

            // const correctedFruitSetScenaries = alterArrayScenariesFruitSet(
            //   data.escenaries,
            //   maxFruitSetState,
            //   minFruitSetState
            // );

            const correctedFruitSetScenaries = data.escenaries;

            setScenariesDataState(correctedFruitSetScenaries);

            resultReduce = correctedFruitSetScenaries[0];

            // si el resultado es escenarios secundarios
            if (data.typeEscenary === 'secondary') {
              const desviationYield = numberFormatDecimals(
                correctedFruitSetScenaries[0].yieldDifference,
                1
              );
              let textDiference = '';

              if (fruitsDartToNumber) {
                let desviationFruitsDart =
                  fruitsDartToNumber -
                  correctedFruitSetScenaries[0].fruitsDartEstimate;
                desviationFruitsDart = removeDashes(
                  numberFormatDecimals(desviationFruitsDart, 1)
                );

                textDiference = `Los frutos/dardos se han desviado ${desviationFruitsDart} u. del objetivo.`;
              } else {
                textDiference = `El rendimiento se ha desviado ${desviationYield} kg/ha del objetivo.`;
              }

              setAlertMessageState(textDiference);
            }
            // si no hay escenarios
          } else {
            setScenariesDataState(null);
            resultReduce = null;
          }

          bodyExpected = resultReduce
            ? {
                ...resultReduce,
                flower: resultReduce.budDart
                  ? resultReduce.budDart
                  : infoIntelligence.information.budDart,
              }
            : null;

          // selecciono el primero de la tabla
          setSelectedScenaryState(resultReduce);

          if (bodyExpected) {
            console.log(bodyExpected, '----------bodyExpected');

            const flowersHa = bodyExpected.flowersHa;

            const fruitsDartEstimateState = bodyExpected.fruitsDartEstimate;

            setFruitsDartEstimateState(fruitsDartEstimateState);

            setFlowersHaState(flowersHa);

            const newFlowersDart = bodyExpected.flowersDartFinal || '';
            setFlowersDartState(newFlowersDart);
          }
        }
      } catch (error) {
        errorHandler(error);
      }
    }

    // tanto con o sin modelo
    if (bodyExpected) {
      let trueResult = true;

      if (
        bodyExpected.dart === null &&
        bodyExpected.twig === null &&
        bodyExpected.flower === null &&
        bodyExpected.fruitSet === null &&
        bodyExpected.caliber === null &&
        bodyExpected.yield === null
      ) {
        trueResult = false;
      }

      console.log(bodyExpected, '----bodyExpected');

      if (trueResult) {
        onCalcResponse(bodyExpected);

        // aquí guarda los valores recibidos en context
        addValues({
          stage_value: null,
          fruitSet_estimated: bodyExpected.fruitSet,
          performance_estimated: bodyExpected.yield,
          caliber_estimated: bodyExpected.caliber,
          dart_estimated: bodyExpected.dart,
          twig_estimated: bodyExpected.twig,
          flower_estimated: bodyExpected.flower,
        });
      } else {
        setNoDataCalcState(true);
        setExpectedInfoState(null);
        // vuelve graficos originales
        // console.log(infoIntelligence, "<<<--asi esta cuando limpia")
        // aquí usa la copia deep de lo filtrado
        setDataFlowerGraphState(infoIntelligence.graphicFlower.data);
        // setYearFlowerGraphState(infoIntelligence.graphicFlower.year);

        // SI ESTIMADO TIENE VALOR, VUELVE CON EL ESTIMADO, SINO SOLO GRAPH
        if (
          isNumber(infoIntelligence.predict.predictFruitSetPrePoda) &&
          isNumber(infoIntelligence.predict.predictCaliber) &&
          isNumber(infoIntelligence.predict.predictYieldPrePoda)
        ) {
          const fruitSetBasal =
            infoIntelligence.predict.predictFruitSetPrePoda ||
            infoIntelligence.predict.predictFruitSet;

          setDataFruitsetGraphState([
            ...infoIntelligence.graphicFruitSet.data,
            fruitSetBasal,
          ]);
          setDataWeightGraphState([
            ...infoIntelligence.graphicWeight.data,
            infoIntelligence.predict.predictCaliber,
          ]);

          const yieldBasal =
            infoIntelligence.predict.predictYieldPrePoda ||
            infoIntelligence.predict.predictYield;

          setDataPerformanceGraphState([
            ...infoIntelligence.graphicPerformance.data,
            yieldBasal,
          ]);
        } else {
          setDataFruitsetGraphState(infoIntelligence.graphicFruitSet.data);
          setDataPerformanceGraphState(
            infoIntelligence.graphicPerformance.data
          );
          setDataWeightGraphState(infoIntelligence.graphicWeight.data);
        }
        // debe volver al origen, pero más lo estimado
        // setYearFruitsetGraphState(infoIntelligence.graphicFruitSet.year);
        // setYearPerformanceGraphState(infoIntelligence.graphicPerformance.year);
        // setYearWeightGraphState(infoIntelligence.graphicWeight.year);
      }

      setLoadingExpectedState(false);
    } else {
      // console.log('entra aca cuando no hay respform');
      setExpectedInfoState(null);
      setLoadingExpectedState(false);
      // no hay datos del modelo
      setNoModelMessageState('No hay datos...');
    }
  };

  const onResetCalc = (type) => {
    setValueCalc(type, '');
    // setExpectedInfoState([]);
  };

  useEffect(() => {
    if (isSet) {
      // handleSubmit(onSubmit)();
      // console.log("------ENTRA------");
      // setValueCalc('fruitSet', fruitSet_value);
      // setValueCalc('caliber', caliber_value);
      // setValueCalc('performance', performance_value);
      // setValueCalc('flower', flower_value);
      // setValueCalc('dart', dart_value);
      // setValueCalc('twig', twig_value);
      // setValueCalc('switchModel', model_value);
      // setExpectedInfoState({
      // 	dart: dart_estimated,
      // 	twig: twig_estimated,
      // 	flower: flower_estimated,
      // 	performance: performance_estimated,
      // 	fruitSet: fruitSet_estimated,
      // 	caliber: caliber_estimated,
      // });
      // limpia el seteo
      // solo debería de limpiar si viene de biblioteca
      // notSetValues();
    }
  }, [isSet]);

  // función que actualiza los gráficos, al calcular
  const onCalcResponse = (bodyExpected) => {
    console.log(bodyExpected, '----bodyExpected', infoIntelligence);

    setExpectedInfoState({
      ...bodyExpected,
      flowersTwig: infoIntelligence.information.flowersTwig,
    });
    setNoDataCalcState(false);

    // console.log(bodyExpected, '------bodyexpected');
    // año para agregar a la data
    const actualYear = new Date().getFullYear();

    // FLOWER
    if (!findAYear(infoIntelligence.graphicFlower.year, actualYear)) {
      // console.log("NO ESTA EL AÑO",infoIntelligence)
      setDataFlowerGraphState([
        ...infoIntelligence.graphicFlower.data,
        {
          y: parseFloat(bodyExpected.flowersHa),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    } else {
      // console.log("ESTA EL AÑO",infoIntelligence)
      setDataFlowerGraphState((prevState) => [
        ...prevState.slice(0, -1),
        {
          y: parseFloat(bodyExpected.flowersHa),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    }

    // FRUITSET
    if (!findAYear(infoIntelligence.graphicFruitSet.year, actualYear)) {
      setDataFruitsetGraphState([
        ...infoIntelligence.graphicFruitSet.data,
        {
          y: parseFloat(bodyExpected.fruitSet),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    } else {
      setDataFruitsetGraphState((prevState) => [
        ...prevState.slice(0, -1),
        {
          y: parseFloat(bodyExpected.fruitSet),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    }

    // WEIGHT
    if (!findAYear(infoIntelligence.graphicWeight.year, actualYear)) {
      setDataWeightGraphState([
        ...infoIntelligence.graphicWeight.data,
        {
          y: parseFloat(bodyExpected.caliber),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    } else {
      setDataWeightGraphState((prevState) => [
        ...prevState.slice(0, -1),
        {
          y: parseFloat(bodyExpected.caliber),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    }

    // PERFORMANCE
    if (!findAYear(infoIntelligence.graphicPerformance.year, actualYear)) {
      setDataPerformanceGraphState([
        ...infoIntelligence.graphicPerformance.data,
        {
          y: parseFloat(bodyExpected.yield),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    } else {
      setDataPerformanceGraphState((prevState) => [
        ...prevState.slice(0, -1),
        {
          y: parseFloat(bodyExpected.yield),
          color: theme.palette.tertiary.main,
          marker: {
            radius: 5,
          },
        },
      ]);
    }

    // TODO: actualizar tabla caliber, fruitSet, yield
    console.log(infoIntelligence.dataTableGraphs, '-------');

    setDataScenaryHistoryTableState({
      fruitSet: bodyExpected.fruitSet,
      weight: bodyExpected.caliber,
      performance: bodyExpected.yield,
    });
  };

  useEffect(() => {
    if (!expectedInfoState) {
      setDataScenaryHistoryTableState(null);
    }
  }, [expectedInfoState]);

  // estados form Modelo
  useEffect(() => {
    // console.log(watchDart, 'watch dart');
    if (watchDartModel || watchTwigModel || watchFlowerModel) {
      setWithModelFormPerformanceState(true);
      clearErrorsCalc('neitherItemlist');
    } else {
      setWithModelFormPerformanceState(false);
    }
  }, [watchDartModel, watchTwigModel, watchFlowerModel]);

  // estados form Modelo
  useEffect(() => {
    // console.log(watchPerformance, 'watch watchPerformance');
    if (watchPerformanceModel || watchCaliberModel || watchFruitsDartModel) {
      setWithModelFormDartState(true);
      clearErrorsCalc('neitherItemlist');
    } else {
      setWithModelFormDartState(false);
    }
  }, [watchPerformanceModel, watchCaliberModel, watchFruitsDartModel]);

  // limpia form a cambios de switch
  useEffect(() => {
    // console.log(useModel, '----------');
    setExpectedInfoState(null);
    if (!useModel) {
      // with model
      setValueCalc('performanceModel', '');
      setValueCalc('caliberModel', '');
      setValueCalc('fruitsDartModel', '');
      setValueCalc('dartModel', '');
      setValueCalc('twigModel', '');
      setValueCalc('flowerModel', '');
    }
  }, [useModel]);

  // limpia el form y borra lo esperado
  const resetFormHandler = () => {
    setValueCalc('fruitSetNoModel', '');
    setValueCalc('caliberNoModel', '');
    setValueCalc('dartNoModel', '');
    setValueCalc('twigNoModel', '');
    setValueCalc('flowerNoModel', '');
    // with model
    setValueCalc('performanceModel', '');
    setValueCalc('caliberModel', '');
    setValueCalc('fruitsDartModel', '');
    setValueCalc('dartModel', '');
    setValueCalc('twigModel', '');
    setValueCalc('flowerModel', '');
    clearErrorsCalc('neitherItemlist');

    setExpectedInfoState(null);
    // handleSubmit(onSubmit)();
  };

  // limpia el form vuelve a basal
  const clearAndBasalFormHandler = () => {
    resetFormHandler();

    // TODO: debe tener el index actual del array quarter
    // console.log(infoIntelligence, '----<-----infoIntelligence');
    // console.log(quartersDataArrayState, '-----data de los quarters');
    // console.log(actualQuarterIndexState, 'index actual');
    getDataBasalHandler(quartersDataArrayState[actualQuarterIndexState]);
  };

  const validateDart = {
    topValue: (value) => {
      if (value) {
        let formatedValue = value.replace(/\./g, '');
        formatedValue = formatedValue.replace(',', '.');

        const dartBasal =
          infoIntelligence.information?.dartPrePoda > 0
            ? infoIntelligence.information?.dartPrePoda <
              infoIntelligence.information?.dartPostPoda
              ? infoIntelligence.information?.dartPostPoda
              : infoIntelligence.information?.dartPrePoda
            : infoIntelligence.information?.dartPostPoda;

        return formatedValue <= dartBasal;
      } else {
        return true;
      }
    },
  };

  const validateTwig = {
    topValue: (value) => {
      if (value) {
        let formatedValue = value.replace(/\./g, '');
        formatedValue = formatedValue.replace(',', '.');

        const twigBasal =
          infoIntelligence.information?.twigPrePoda > 0
            ? infoIntelligence.information?.twigPrePoda <
              infoIntelligence.information?.twigPostPoda
              ? infoIntelligence.information?.twigPostPoda
              : infoIntelligence.information?.twigPrePoda
            : infoIntelligence.information?.twigPostPoda;

        return formatedValue <= twigBasal;
      } else {
        return true;
      }
    },
  };

  // console.log(infoIntelligence, '-------------infoIntelligence');

  // carga datos basales al form
  const getValuesForm = () => {
    if (!useModel) {
      const fruitSetBasal =
        infoIntelligence.predict.predictFruitSetPrePoda ||
        infoIntelligence.predict.predictFruitSet;

      let fruitSetToString = fruitSetBasal.toString();
      fruitSetToString = fruitSetToString.replace('.', ',');

      setValueCalc('fruitSetNoModel', fruitSetToString);

      let caliberToString = infoIntelligence.predict.predictCaliber.toString();
      caliberToString = caliberToString.replace('.', ',');

      setValueCalc('caliberNoModel', caliberToString);

      const dartBasal =
        infoIntelligence.information?.dartPrePoda > 0
          ? infoIntelligence.information?.dartPrePoda <
            infoIntelligence.information?.dartPostPoda
            ? infoIntelligence.information?.dartPostPoda
            : infoIntelligence.information?.dartPrePoda
          : infoIntelligence.information?.dartPostPoda;

      let dartToString = dartBasal.toString();
      dartToString = dartToString.replace('.', ',');
      setValueCalc('dartNoModel', dartToString);

      const twigBasal =
        infoIntelligence.information?.twigPrePoda > 0
          ? infoIntelligence.information?.twigPrePoda <
            infoIntelligence.information?.twigPostPoda
            ? infoIntelligence.information?.twigPostPoda
            : infoIntelligence.information?.twigPrePoda
          : infoIntelligence.information?.twigPostPoda;

      let twigToString = twigBasal.toString();
      twigToString = twigToString.replace('.', ',');
      setValueCalc('twigNoModel', twigToString);

      let budDartToString = infoIntelligence.information.budDart.toString();
      budDartToString = budDartToString.replace('.', ',');
      setValueCalc('flowerNoModel', budDartToString);
    } else {
      // tab 0
      if (valueInternalTabState === 0) {
        const yieldBasal =
          infoIntelligence.predict.predictYieldPrePoda ||
          infoIntelligence.predict.predictYield;

        let yieldToString = yieldBasal.toString();
        yieldToString = yieldToString.replace('.', ',');

        setValueCalc('performanceModel', yieldToString);

        let caliberToString =
          infoIntelligence.predict.predictCaliber.toString();
        caliberToString = caliberToString.replace('.', ',');
        // console.log(caliberToString.split(',')[0], '-----');

        setValueCalc('caliberModel', caliberToString.split(',')[0]);

        let fruitsDartToString = infoIntelligence.predict.fruitsDart.toString();
        fruitsDartToString = fruitsDartToString.replace('.', ',');

        setValueCalc('fruitsDartModel', fruitsDartToString);
        // tab 1
      } else {
        const dartBasal =
          infoIntelligence.information?.dartPrePoda > 0
            ? infoIntelligence.information?.dartPrePoda <
              infoIntelligence.information?.dartPostPoda
              ? infoIntelligence.information?.dartPostPoda
              : infoIntelligence.information?.dartPrePoda
            : infoIntelligence.information?.dartPostPoda;

        let dartToString = dartBasal.toString();
        dartToString = dartToString.replace('.', ',');
        setValueCalc('dartModel', dartToString);

        const twigBasal =
          infoIntelligence.information?.twigPrePoda > 0
            ? infoIntelligence.information?.twigPrePoda <
              infoIntelligence.information?.twigPostPoda
              ? infoIntelligence.information?.twigPostPoda
              : infoIntelligence.information?.twigPrePoda
            : infoIntelligence.information?.twigPostPoda;

        let twigToString = twigBasal.toString();
        twigToString = twigToString.replace('.', ',');
        setValueCalc('twigModel', twigToString);

        let budDartToString = infoIntelligence.information.budDart.toString();
        budDartToString = budDartToString.replace('.', ',');
        setValueCalc('flowerModel', budDartToString);
      }
    }
  };

  // para botón que toma valores del escenario
  const getValuesModelForm = () => {
    console.log(expectedInfoState, '----expectedInfoState');

    setValueCalc('switchModel', false);
    // pasa la recomendación a sin modelo
    let fruitSetToString = expectedInfoState.fruitSet.toString();
    fruitSetToString = fruitSetToString.replace('.', ',');

    setValueCalc('fruitSetNoModel', fruitSetToString);

    let caliberToString = expectedInfoState.caliber.toString();
    caliberToString = caliberToString.replace('.', ',');

    setValueCalc('caliberNoModel', caliberToString);

    let dartToString = expectedInfoState.dart.toString();
    dartToString = dartToString.replace('.', ',');
    setValueCalc('dartNoModel', dartToString);

    let twigToString = expectedInfoState.twig.toString();
    twigToString = twigToString.replace('.', ',');
    setValueCalc('twigNoModel', twigToString);

    let budDartToString = expectedInfoState.budDartFinal.toString();
    budDartToString = budDartToString.replace('.', ',');
    setValueCalc('flowerNoModel', budDartToString);

    setExpectedInfoState(null);
  };

  // función para cerrar caja recomendación
  const closeRecommendationHandler = () => {
    setExpectedInfoState(null);
    getDataBasalHandler(quartersDataArrayState[actualQuarterIndexState]);
  };

  const radioChangeHandler = (scenary) => {
    // console.log('---->', JSON.parse(scenary));
    setSelectedScenaryState(JSON.parse(scenary));
  };

  useEffect(() => {
    // setExpectedInfoState(null);
    // cada vez que cambia
    // deba cambiar
    // flowersHaState
    // fruitsDartEstimateState
    // flowersDartState
    // graficos
    if (selectedScenaryState) {
      // console.log('pasa ufe de cambios', selectedScenaryState);

      const flowersHa = selectedScenaryState.flowersHa;

      const fruitsDartEstimateState = selectedScenaryState.fruitsDartEstimate;

      setFruitsDartEstimateState(fruitsDartEstimateState);

      // ahora carga flowersHa
      setFlowersHaState(flowersHa);

      // aca carga nuevo flowersDartFinal
      const newFlowersDart = selectedScenaryState.flowersDartFinal || '';
      setFlowersDartState(newFlowersDart);

      onCalcResponse(selectedScenaryState);
    }
  }, [selectedScenaryState]);

  const handleBackQuarter = () => {
    // console.log(quartersDataArrayState, '---el array');
    // console.log(quarterMaxIndexState, '----max');
    // console.log(actualQuarterIndexState, '----index actual');

    resetFormHandler();

    if (actualQuarterIndexState >= 0) {
      setactualQuarterIndexState(actualQuarterIndexState - 1);

      const previusQuarter =
        quartersDataArrayState[actualQuarterIndexState - 1];

      getDataBasalHandler(previusQuarter);
    }
  };

  const handleNextQuarter = () => {
    // console.log(quartersDataArrayState, '---el array');
    // console.log(quarterMaxIndexState, '----max');
    // console.log(actualQuarterIndexState, '----index actual');

    // TODO: limpiar toda la data
    // basal y calculos
    resetFormHandler();

    // TODO: aumentar en uno el index
    if (actualQuarterIndexState < quarterMaxIndexState) {
      setactualQuarterIndexState(actualQuarterIndexState + 1);

      const newQuarter = quartersDataArrayState[actualQuarterIndexState + 1];

      getDataBasalHandler(newQuarter);
    }
  };

  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleOpenModal = () => {
    setOpenModalState(true);
  };

  const onSaveScenaryHandler = async () => {
    setSavingScenaryState(true);
    // console.log('escenario a guardar', selectedScenaryState);

    const actualYear = new Date().getFullYear();

    let objQuery = {
      season: actualYear.toString(),
      // TODO: dejar año actual
      // season: '2022',
    };

    if (!useModel) {
      // console.log(expectedInfoState, '-----datos sin modelo', infoIntelligence);

      // TODO: budDart basal o del formulario

      objQuery = {
        ...objQuery,
        // budDart debe ser el basal
        // si no tiene basal, está en 0 y envia el del form en budDartScenary
        budDart: infoIntelligence.budDart || 0,
        predictFruitSet: parseFloat(expectedInfoState.fruitSet),
        dart: parseFloat(expectedInfoState.dart),
        twig: parseFloat(expectedInfoState.twig),
        budDartScenary: parseFloat(expectedInfoState.flower),
        budDartFinal: parseFloat(expectedInfoState.flower),
        flowersDart: expectedInfoState.flowersDart,
        flowersDartFinal: expectedInfoState.flowersDart,
        flowersTwig: infoIntelligence.flowersTwig,
        flowersHa: expectedInfoState.flowersHa,
        fruitsDartEstimate: fruitsDartEstimateState,
        fruitSet: parseFloat(expectedInfoState.fruitSet),
        caliber: parseFloat(expectedInfoState.caliber),
        yield: expectedInfoState.yield,
        pruneLevel: null,
        yieldDifference: null,
        ccValue: infoIntelligence.ccValue,
        orchardValue: infoIntelligence.orchardValue,
        quarterValue: infoIntelligence.quarterValue,
        rootStockValue: infoIntelligence.rootStockValue,
        varietyValue: infoIntelligence.varietyValue,
        specieValue: infoIntelligence.specieValue,
        // specieValue: specie_filter,
        predictiveModel: false,
      };

      addFilter({
        orchard_filter: infoIntelligence?.orchardValue,
        cc_filter: infoIntelligence?.ccValue,
        quarter_filter: infoIntelligence?.quarterValue,
        variety_filter: infoIntelligence?.varietyValue,
        rootstock_filter: infoIntelligence?.rootStockValue,
      });
    } else {
      addFilter({
        orchard_filter: selectedScenaryState?.orchardValue,
        cc_filter: selectedScenaryState?.ccValue,
        quarter_filter: selectedScenaryState?.quarterValue,
        variety_filter: selectedScenaryState?.varietyValue,
        rootstock_filter: selectedScenaryState?.rootStockValue,
      });

      const {
        cc,
        quarter,
        variety,
        orchard,
        rootStock,
        specie,
        _id,
        ...propertiesToSend
      } = selectedScenaryState;

      objQuery = {
        ...objQuery,
        ...propertiesToSend,
        predictiveModel: true,
      };
    }

    const response = await axiosContext.authAxios.post(
      'v1/pruningrecommendation/save-scenary',
      objQuery
    );

    const { status, data } = response;

    if (status === 200) {
      if (data === 1) {
        setSaveSuccessState(true);
      }

      setSavingScenaryState(false);
    }

    setSavingScenaryState(false);
  };

  // console.log(
  //   infoIntelligence,
  //   '----------------------------infoIntelligence',
  //   selectedScenaryState
  // );

  const redirectHandler = () => {
    setSaveSuccessState(false);
    // history.push({
    //   pathname: '/library',
    // });
    history.go(0);
  };

  const redirect = (link) => {
    // console.log(infoIntelligence, '----infoIntelligence');
    // TODO: tomar de infointelligence, cc, quarter para setear filters antes de ir

    addFilter({
      cc_filter: infoIntelligence?.ccValue,
      quarter_filter: infoIntelligence?.quarterValue,
      variety_filter: infoIntelligence?.varietyValue,
      rootstock_filter: infoIntelligence?.rootStockValue,
    });

    // a biblioteca
    history.push({
      pathname: link,
      state: {
        fromPrune: true,
        IntelligenceState: infoIntelligence?.savedScenario,
      },
    });
  };

  useEffect(() => {
    // console.log(dataFlowerGraphState, '-----optionsFlower');

    // TODO: debe tomar años de uno, recorrer e ir armando la data del nuevo
    // yearFlowerGraphState;
    // yearFruitsetGraphState;
    const dataFruitSetFlowers = [];
    for (let index = 0; index < yearFlowerGraphState.length; index++) {
      const dataFlower = getDataGraphFromState(dataFlowerGraphState[index]);
      const dataFruitSet = getDataGraphFromState(dataFruitsetGraphState[index]);
      const yearDot = yearFlowerGraphState[index];

      // console.log({ dataFlower, dataFruitSet, yearDot });

      dataFruitSetFlowers.push({
        x: dataFlower,
        y: dataFruitSet,
        year: yearDot,
      });
    }

    setDataFruitSetFlowersGraphState(dataFruitSetFlowers);
  }, [dataFlowerGraphState, dataFruitsetGraphState]);

  // console.log(infoIntelligence, '-----la data procesada');

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-5'>
          <Modal
            open={openModalState}
            onClose={handleCloseModal}
            className={classes.modal}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
          >
            <>
              <ModalGraph
                closeAction={handleCloseModal}
                optionsFlower={optionsFlower}
                optionsFruitset={optionsFruitset}
                optionsPerformance={optionsPerformance}
                optionsWeight={optionsWeight}
                optionsColdPortions={optionsColdPortions}
                optionsFruitSetFlowers={optionsFruitSetFlowers}
              />
            </>
          </Modal>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Recomendación de Poda
                    </Typography>
                    <UnitFilter
                      submitFunc={onSubmitFilter}
                      module='prunning'
                      requiredGroup={requiredGroup}
                    />
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <BsRow class=' justify-content-end'>
              <BsCol class='col-md-12'>
                <Typography variant='h5'>Información Basal</Typography>
              </BsCol>
            </BsRow>

            <BsRow>
              <BsCol class='col-md-6 col-lg-9'>
                <InfoRowBoxes
                  infoIntelligence={infoIntelligence}
                  addFilter={addFilter}
                  scenarySaved={infoIntelligence?.savedScenario}
                  loadingData={loadingInfoState}
                />
              </BsCol>
              <BsCol class='col-md-6 col-lg-3 pl-lg-1 d-flex flex-column'>
                <div>
                  <Typography variant='h5' gutterBottom className='text-center'>
                    Cambiar de Cuartel
                  </Typography>
                  <div className={classes.arrowsContainerStyle}>
                    <SimpleIconButton
                      style={{ width: '50%' }}
                      variant='contained'
                      actionOnClick={handleBackQuarter}
                      disabled={
                        actualQuarterIndexState === 0 ||
                        !quartersDataArrayState.length ||
                        loadingInfoState
                      }
                      labelButton='Anterior'
                      icon={<KeyboardArrowLeftIcon />}
                    />
                    <SimpleIconButton
                      style={{ width: '50%' }}
                      variant='contained'
                      actionOnClick={handleNextQuarter}
                      disabled={
                        actualQuarterIndexState === quarterMaxIndexState ||
                        !quartersDataArrayState.length ||
                        loadingInfoState
                      }
                      sx={{ ml: 1 }}
                      labelButton='Siguiente'
                      endIcon={<KeyboardArrowRightIcon />}
                    />
                  </div>
                </div>
                <div className='mt-2'>
                  <SimpleIconButton
                    labelButton='Ir a la Biblioteca'
                    actionOnClick={() => redirect('/library')}
                    // variant='contained'
                    color='secondary'
                    size='large'
                    fullWidth
                    disabled={!infoIntelligence}
                    icon={
                      <SvgIcon viewBox='0 -1 18 22'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d={iconJson.icon.library}
                        />
                      </SvgIcon>
                    }
                  />
                </div>
                <CardPaper
                  elevation={0}
                  variant='outlined'
                  sx={{
                    padding: 1,
                    mt: 1,
                    borderWidth: 2,
                    flexGrow: 1,
                    borderColor: infoIntelligence?.savedScenario
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  }}
                >
                  {infoIntelligence?.savedScenario !== undefined && (
                    <>
                      <Typography
                        variant='h5'
                        gutterBottom
                        className='my-3'
                        sx={{ textAlign: 'center' }}
                      >
                        Estado del Escenario
                      </Typography>

                      <Typography
                        variant='h4'
                        gutterBottom
                        className='mb-2'
                        sx={{
                          textAlign: 'center',
                          alignItems: 'center',
                          color: infoIntelligence?.savedScenario
                            ? '#00B374'
                            : '#CC3333',
                        }}
                      >
                        {infoIntelligence?.savedScenario ? (
                          <>
                            <LockIcon sx={{ mr: 1 }} /> Guardado
                          </>
                        ) : (
                          <>
                            <LockOpenIcon sx={{ mr: 1 }} /> No Guardado
                          </>
                        )}
                      </Typography>
                    </>
                  )}
                </CardPaper>
              </BsCol>
            </BsRow>

            {/* BOX ESCENARIO GUARDADO */}
            {infoIntelligence?.savedScenario ? (
              <BsRow class='mt-3'>
                <BsCol class='col-md-12'>
                  <CardPaper
                    elevation={0}
                    variant='outlined'
                    sx={{
                      padding: 1.5,
                      borderWidth: 2,
                      borderColor: theme.palette.success.main,
                    }}
                  >
                    <BsRow>
                      <BsCol class='col-md-12'>
                        <Typography
                          variant='h5'
                          gutterBottom
                          className='mb-3'
                          sx={{ textAlign: 'left' }}
                        >
                          <i
                            className='fas fa-lock mr-2'
                            style={{ color: theme.palette.success.main }}
                          ></i>{' '}
                          Escenario Guardado
                        </Typography>
                      </BsCol>
                    </BsRow>
                    <BsRow>
                      <BsCol class='col-md-12'>
                        <Grid
                          container
                          columnSpacing={{ xs: 1.4 }}
                          columns={{ xs: 4, lg: 5 }}
                        >
                          <Grid item xs={2} lg={1}>
                            <Box
                              sx={{
                                // backgroundColor: 'tomato',
                                textAlign: 'center',
                                p: 1,
                              }}
                            >
                              <Typography variant='body1' gutterBottom>
                                <img
                                  src={FruitsetIcon}
                                  className='img-fluid mr-2'
                                  width={16}
                                  alt='dart'
                                />{' '}
                                Cuaja
                              </Typography>
                              <Typography
                                variant='h3'
                                className={classes.fontSavedStyle}
                              >
                                {numberFormatDecimals(
                                  infoIntelligence?.fruitSetObjective,
                                  2
                                )}{' '}
                                <Typography
                                  component='span'
                                  display='inline'
                                  variant='h4'
                                >
                                  %
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} lg={1}>
                            <Box
                              sx={{
                                // backgroundColor: 'tomato',
                                textAlign: 'center',
                                p: 1,
                              }}
                            >
                              <Typography variant='body1' gutterBottom>
                                <img
                                  src={CaliberIcon}
                                  className='img-fluid mr-2'
                                  width={16}
                                  alt='dart'
                                />{' '}
                                Calibre
                              </Typography>
                              <Typography
                                variant='h3'
                                className={classes.fontSavedStyle}
                              >
                                {numberFormatDecimals(
                                  infoIntelligence?.caliberObjective,
                                  2
                                )}{' '}
                                <Typography
                                  component='span'
                                  display='inline'
                                  variant='h4'
                                >
                                  gr/fruto
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} lg={1}>
                            <Box
                              sx={{
                                // backgroundColor: 'tomato',
                                textAlign: 'center',
                                p: 1,
                              }}
                            >
                              <Typography variant='body1' gutterBottom>
                                <img
                                  src={FlowerIcon}
                                  className='img-fluid mr-2'
                                  width={16}
                                  alt='dart'
                                />{' '}
                                Flores
                              </Typography>
                              <Typography
                                variant='h3'
                                className={classes.fontSavedStyle}
                              >
                                {numberFormatDecimals(
                                  infoIntelligence?.flowersHaObjective,
                                  0
                                )}{' '}
                                {/* <Typography
                                  component='span'
                                  display='inline'
                                  variant='h4'
                                >
                                  gr/fruto
                                </Typography> */}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} lg={1}>
                            <Box
                              sx={{
                                // backgroundColor: 'tomato',
                                textAlign: 'center',
                                p: 1,
                              }}
                            >
                              <Typography variant='body1' gutterBottom>
                                <img
                                  src={DartIcon}
                                  className='img-fluid mr-2'
                                  width={16}
                                  alt='dart'
                                />{' '}
                                Frutos/dardos
                              </Typography>
                              <Typography
                                variant='h3'
                                className={classes.fontSavedStyle}
                              >
                                {numberFormatDecimals(
                                  infoIntelligence?.fruitsDartEstimateObjective,
                                  1
                                )}{' '}
                                {/* <Typography
                                  component='span'
                                  display='inline'
                                  variant='h4'
                                >
                                  gr/fruto
                                </Typography> */}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} lg={1}>
                            <Box
                              sx={{
                                // backgroundColor: 'tomato',
                                textAlign: 'center',
                                p: 1,
                              }}
                            >
                              <Typography variant='body1' gutterBottom>
                                <img
                                  src={PerformanceIcon}
                                  className='img-fluid mr-2'
                                  width={16}
                                  alt='dart'
                                />{' '}
                                Rendimiento
                              </Typography>
                              <Typography
                                variant='h3'
                                className={classes.fontSavedStyle}
                              >
                                {numberFormatDecimals(
                                  infoIntelligence?.yieldObjective,
                                  0
                                )}{' '}
                                {/* <Typography
                                  component='span'
                                  display='inline'
                                  variant='h4'
                                >
                                  gr/fruto
                                </Typography> */}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </BsCol>
                    </BsRow>
                  </CardPaper>
                </BsCol>
              </BsRow>
            ) : null}

            {/* row FORM & BASAL */}

            <BsRow class='mt-3 gutter_10'>
              <BsCol class='col-md-4 col-lg-4'>
                <CardPaper
                  elevation={0}
                  variant='outlined'
                  sx={{
                    padding: 1,
                    height: '100%',
                    borderColor: theme.palette.grey[300],
                  }}
                >
                  <Typography variant='h5' gutterBottom className='mb-3'>
                    Formulario Predictivo
                  </Typography>
                  <form
                    autoComplete='false'
                    noValidate
                    onSubmit={handleSubmitCalc(onSubmitCalc)}
                  >
                    {/* selector modelo */}
                    <BsRow>
                      <BsCol class='col-md-12 mb-3'>
                        <Controller
                          name='switchModel'
                          control={controlCalc}
                          defaultValue={false}
                          render={({ field }) => {
                            return (
                              <StyledToggleButtonGroup
                                {...field}
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    setValueCalc(field.name, value);
                                  }
                                }}
                                exclusive
                                fullWidth
                                disabled={!activeCalAritmeticButtonState}
                                color='secondary'
                                aria-label='model'
                              >
                                <ToggleButton value={false} aria-label='sin'>
                                  Sin modelo predictivo
                                </ToggleButton>
                                {activeCalButtonState && (
                                  <ToggleButton value={true} aria-label='con'>
                                    Con modelo predictivo
                                  </ToggleButton>
                                )}
                              </StyledToggleButtonGroup>
                            );
                          }}
                        />
                      </BsCol>
                    </BsRow>
                    {useModel ? (
                      // ------> CON MODELO
                      <BsRow>
                        <BsCol class='col-md-12'>
                          <Typography variant='body1'>
                            <b>Con Modelo Analytics:</b> Estimación predictiva
                            en base a modelamiento, desde un Rendimiento
                            deseado.
                            <span className='ml-2'>
                              <IconButton
                                aria-describedby='dd'
                                variant='text'
                                color='primary'
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                              >
                                <InfoIcon />
                              </IconButton>
                            </span>
                          </Typography>
                          <Popover
                            id='simple-popover'
                            open={openPopoverState}
                            className={classes.popover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div className={classes.popOverContainer}>
                              <Typography variant='caption' component={'p'}>
                                <b>Rendimiento Objetivo:</b> Busca según la
                                historia del cuartel
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Calibre Objetivo:</b> Según la historia y la
                                Variedad.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Frutos/Dardos Objetivo:</b> Al reducir Al
                                reducir la relación tendrás un aumento en el
                                calibre.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Dardos / Ramillas:</b> Objetivo de Poda.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Yemas/Dardo:</b> Cantidad de yemas por dardo.
                              </Typography>
                            </div>
                          </Popover>
                          {errorsCalc.neitherItemlist && (
                            <p
                              style={{
                                color: theme.palette.error.main,
                              }}
                            >
                              <i className='fas fa-exclamation'></i>
                              {errorsCalc.neitherItemlist.message}
                            </p>
                          )}
                          <SimpleTabs
                            tabitems={tabInternalItems}
                            value={valueInternalTabState}
                            toggler={handleChangeInternalTab}
                          >
                            {/* panel de rendimiento */}
                            <TabPanel
                              value={valueInternalTabState}
                              index={0}
                              py='py-4'
                              px='px-1'
                              noMinHeight={true}
                            >
                              <BsRow>
                                <BsCol class='col-md-12 mb-2'>
                                  <div className=''>
                                    <Grid
                                      container
                                      direction='row'
                                      justifyContent='flex-start'
                                      alignItems='center'
                                    >
                                      <Grid item>
                                        <img
                                          src={PerformanceIcon}
                                          width='16'
                                          className='img-fluid img-estimated'
                                          alt='dart'
                                        />
                                      </Grid>
                                      <Grid item className='ml-0 ml-lg-1'>
                                        <Typography
                                          variant='h6'
                                          // gutterBottom
                                        >
                                          Rendimiento Objetivo
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <div className='d-flex'>
                                      <Controller
                                        control={controlCalc}
                                        rules={{
                                          required: true,
                                        }}
                                        name='performanceModel'
                                        render={({
                                          field: { onChange, name, value },
                                        }) => (
                                          <NumberFormat
                                            thousandsGroupStyle='thousand'
                                            value={value}
                                            onChange={onChange}
                                            disabled={
                                              withModelFormPerformanceState
                                            }
                                            error={Boolean(
                                              errorsCalc.performanceModel
                                            )}
                                            helperText={
                                              errorsCalc.performanceModel
                                                ? 'Complete cantidad'
                                                : ''
                                            }
                                            variant='standard'
                                            FormHelperTextProps={{
                                              classes: {
                                                root: classes.rootHelper,
                                              },
                                            }}
                                            InputProps={{
                                              classes: {
                                                input: classes.inputText,
                                              },
                                              endAdornment: (
                                                <>
                                                  {value && (
                                                    <IconButton
                                                      color='primary'
                                                      aria-label='upload picture'
                                                      component='span'
                                                      onClick={() =>
                                                        onResetCalc(
                                                          'performanceModel'
                                                        )
                                                      }
                                                    >
                                                      <ClearIcon
                                                        fontSize='small'
                                                        style={{
                                                          color:
                                                            theme.palette
                                                              .grey[400],
                                                        }}
                                                      />
                                                    </IconButton>
                                                  )}
                                                  <InputAdornment
                                                    position='end'
                                                    variant='filled'
                                                    disableTypography
                                                    style={{
                                                      color: 'black',
                                                      height: 'auto',
                                                      maxHeight: '40px',
                                                      padding: '14px 16px',
                                                      borderRadius:
                                                        theme.shape
                                                          .borderRadius,
                                                      backgroundColor:
                                                        theme.palette.background
                                                          .default,
                                                    }}
                                                  >
                                                    kg/ha
                                                  </InputAdornment>
                                                </>
                                              ),
                                            }}
                                            customInput={TextField}
                                            className='flex-grow-1'
                                            // prefix="$"
                                            decimalSeparator=','
                                            displayType='input'
                                            type='text'
                                            thousandSeparator='.'
                                            allowNegative={true}
                                            decimalScale={1}
                                            placeholder='en miles, 5.000-25.000'
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </BsCol>
                                <BsCol class='col-md-12 mb-2'>
                                  <>
                                    {/* <Grid
                                            container
                                            direction='row'
                                            justifyContent='flex-start'
                                            alignItems='center'
                                          >
                                            <Grid item>
                                              <img
                                                src={CaliberIcon}
                                                width='16'
                                                className='img-fluid img-estimated'
                                                alt='dart'
                                              />
                                            </Grid>
                                            <Grid item className='ml-0 ml-lg-1'>
                                              <Typography
                                                variant='h6'
                                                // gutterBottom
                                              >
                                                Calibre Objetivo
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <div className='d-flex'>
                                            <Controller
                                              control={controlCalc}
                                              // rules={{
                                              // 	required: true,
                                              // }}
                                              name='caliberModel'
                                              render={({
                                                field: {
                                                  onChange,
                                                  name,
                                                  value,
                                                },
                                              }) => (
                                                <NumberFormat
                                                  thousandsGroupStyle='thousand'
                                                  value={value}
                                                  onChange={onChange}
                                                  disabled={
                                                    withModelFormPerformanceState
                                                  }
                                                  error={Boolean(
                                                    errorsCalc.caliberModel
                                                  )}
                                                  helperText={
                                                    errorsCalc.caliberModel
                                                      ? 'Complete cantidad'
                                                      : ''
                                                  }
                                                  variant='standard'
                                                  FormHelperTextProps={{
                                                    classes: {
                                                      root: classes.rootHelper,
                                                    },
                                                  }}
                                                  InputProps={{
                                                    classes: {
                                                      input: classes.inputText,
                                                    },
                                                    endAdornment: (
                                                      <>
                                                        {value && (
                                                          <IconButton
                                                            color='primary'
                                                            aria-label='upload picture'
                                                            component='span'
                                                            onClick={() =>
                                                              onResetCalc(
                                                                'caliberModel'
                                                              )
                                                            }
                                                          >
                                                            <ClearIcon
                                                              fontSize='small'
                                                              style={{
                                                                color:
                                                                  theme.palette
                                                                    .grey[400],
                                                              }}
                                                            />
                                                          </IconButton>
                                                        )}
                                                        <InputAdornment
                                                          position='end'
                                                          variant='filled'
                                                          disableTypography
                                                          style={{
                                                            color: 'black',
                                                            height: 'auto',
                                                            maxHeight: '40px',
                                                            padding:
                                                              '14px 16px',
                                                            borderRadius:
                                                              theme.shape
                                                                .borderRadius,
                                                            backgroundColor:
                                                              theme.palette
                                                                .background
                                                                .default,
                                                          }}
                                                        >
                                                          g
                                                        </InputAdornment>
                                                      </>
                                                    ),
                                                  }}
                                                  customInput={TextField}
                                                  className='flex-grow-1'
                                                  // prefix="$"
                                                  decimalSeparator=','
                                                  displayType='input'
                                                  type='text'
                                                  thousandSeparator='.'
                                                  allowNegative={true}
                                                  decimalScale={1}
                                                  placeholder='en gramos'
                                                />
                                              )}
                                            />
                                          </div> */}
                                    <TextFieldWidgetSelectControllerForm
                                      options={CALIBER_OPTIONS}
                                      control={controlCalc}
                                      titleOption='label'
                                      name='caliberModel'
                                      labelIcon={
                                        <img
                                          src={CaliberIcon}
                                          width='16'
                                          className='img-fluid img-estimated'
                                          alt='dart'
                                        />
                                      }
                                      labeltext='Seleccione Calibre'
                                      errors={errorsCalc.caliberModel}
                                      defaultValue=''
                                      loading={false}
                                      req={true}
                                      withDefault
                                      variant='standard'
                                    />
                                  </>
                                </BsCol>
                                <BsCol class='col-md-12 mb-2'>
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      justifyContent='flex-start'
                                      alignItems='center'
                                    >
                                      <Grid item>
                                        <img
                                          src={FlowerIcon}
                                          width='16'
                                          className='img-fluid img-estimated'
                                          alt='fruitsDart'
                                        />
                                      </Grid>
                                      <Grid item className='ml-0 ml-lg-1'>
                                        <Typography
                                          variant='h6'
                                          // gutterBottom
                                        >
                                          Frutos/dardos Objetivo
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <div className='d-flex'>
                                      <Controller
                                        control={controlCalc}
                                        // rules={{
                                        // 	required: true,
                                        // }}
                                        name='fruitsDartModel'
                                        render={({
                                          field: { onChange, name, value },
                                        }) => (
                                          <NumberFormat
                                            thousandsGroupStyle='thousand'
                                            value={value}
                                            onChange={onChange}
                                            disabled={
                                              withModelFormPerformanceState
                                            }
                                            error={Boolean(
                                              errorsCalc.fruitsDartModel
                                            )}
                                            helperText={
                                              errorsCalc.fruitsDartModel
                                                ? 'Complete cantidad'
                                                : ''
                                            }
                                            variant='standard'
                                            FormHelperTextProps={{
                                              classes: {
                                                root: classes.rootHelper,
                                              },
                                            }}
                                            InputProps={{
                                              classes: {
                                                input: classes.inputText,
                                              },
                                              endAdornment: (
                                                <>
                                                  {value && (
                                                    <IconButton
                                                      color='primary'
                                                      aria-label='upload picture'
                                                      component='span'
                                                      onClick={() =>
                                                        onResetCalc(
                                                          'fruitsDartModel'
                                                        )
                                                      }
                                                    >
                                                      <ClearIcon
                                                        fontSize='small'
                                                        style={{
                                                          color:
                                                            theme.palette
                                                              .grey[400],
                                                        }}
                                                      />
                                                    </IconButton>
                                                  )}
                                                  <InputAdornment
                                                    position='end'
                                                    variant='filled'
                                                    disableTypography
                                                    style={{
                                                      color: 'black',
                                                      height: 'auto',
                                                      maxHeight: '40px',
                                                      padding: '14px 16px',
                                                      borderRadius:
                                                        theme.shape
                                                          .borderRadius,
                                                      backgroundColor:
                                                        theme.palette.background
                                                          .default,
                                                    }}
                                                  >
                                                    u
                                                  </InputAdornment>
                                                </>
                                              ),
                                            }}
                                            customInput={TextField}
                                            className='flex-grow-1'
                                            // prefix="$"
                                            decimalSeparator=','
                                            displayType='input'
                                            type='text'
                                            thousandSeparator='.'
                                            allowNegative={true}
                                            decimalScale={1}
                                            placeholder='unidades'
                                          />
                                        )}
                                      />
                                    </div>
                                  </>
                                </BsCol>
                              </BsRow>
                            </TabPanel>
                            {/* panel por datos */}
                            <TabPanel
                              value={valueInternalTabState}
                              index={1}
                              py='py-4'
                              noMinHeight={true}
                            >
                              <BsRow>
                                <BsCol class='col-md-12 mb-2'>
                                  <Grid
                                    container
                                    direction='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                  >
                                    <Grid item>
                                      <img
                                        src={DartIcon}
                                        width='16'
                                        className='img-fluid img-estimated'
                                        alt='dart'
                                      />
                                    </Grid>
                                    <Grid item className='ml-0 ml-lg-1'>
                                      <Typography
                                        variant='h6'
                                        // gutterBottom
                                      >
                                        Dardos
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <div className='d-flex'>
                                    <Controller
                                      control={controlCalc}
                                      rules={{
                                        // required: true,
                                        validate: validateDart,
                                        // max:
                                        // 	infoIntelligence
                                        // 		?.information
                                        // 		?.dart,
                                      }}
                                      name='dartModel'
                                      render={({
                                        field: { onChange, name, value },
                                      }) => (
                                        <NumberFormat
                                          thousandsGroupStyle='thousand'
                                          value={value}
                                          onChange={onChange}
                                          error={Boolean(errorsCalc.dartModel)}
                                          disabled={withModelFormDartState}
                                          helperText={
                                            errorsCalc.dartModel &&
                                            errorsCalc.dartModel.type ===
                                              'topValue'
                                              ? 'Supera máximo'
                                              : ''
                                          }
                                          variant='standard'
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.rootHelper,
                                            },
                                          }}
                                          InputProps={{
                                            classes: {
                                              input: classes.inputText,
                                            },
                                            endAdornment: (
                                              <>
                                                {value && (
                                                  <IconButton
                                                    color='primary'
                                                    aria-label='upload picture'
                                                    component='span'
                                                    onClick={() =>
                                                      onResetCalc('dartModel')
                                                    }
                                                  >
                                                    <ClearIcon
                                                      fontSize='small'
                                                      style={{
                                                        color:
                                                          theme.palette
                                                            .grey[400],
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                                <InputAdornment
                                                  position='end'
                                                  variant='filled'
                                                  disableTypography
                                                  style={{
                                                    color: 'black',
                                                    height: 'auto',
                                                    maxHeight: '40px',
                                                    padding: '14px 16px',
                                                    borderRadius:
                                                      theme.shape.borderRadius,
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .default,
                                                  }}
                                                >
                                                  u
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          customInput={TextField}
                                          className='flex-grow-1'
                                          // prefix="$"
                                          decimalSeparator=','
                                          displayType='input'
                                          type='text'
                                          thousandSeparator='.'
                                          allowNegative={true}
                                          decimalScale={1}
                                          placeholder='en unidades 100-1200'
                                        />
                                      )}
                                    />
                                  </div>
                                </BsCol>
                                <BsCol class='col-md-12 mb-2'>
                                  <Grid
                                    container
                                    direction='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                  >
                                    <Grid item>
                                      <img
                                        src={TwigIcon}
                                        width='16'
                                        className='img-fluid img-estimated'
                                        alt='dart'
                                      />
                                    </Grid>
                                    <Grid item className='ml-0 ml-lg-1'>
                                      <Typography
                                        variant='h6'
                                        // gutterBottom
                                      >
                                        Ramillas
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <div className='d-flex'>
                                    <Controller
                                      control={controlCalc}
                                      rules={{
                                        // required: true,
                                        validate: validateTwig,
                                        // max:
                                        // 	infoIntelligence
                                        // 		?.information
                                        // 		?.twig,
                                      }}
                                      name='twigModel'
                                      render={({
                                        field: { onChange, name, value },
                                      }) => (
                                        <NumberFormat
                                          thousandsGroupStyle='thousand'
                                          value={value}
                                          onChange={onChange}
                                          error={Boolean(errorsCalc.twigModel)}
                                          disabled={withModelFormDartState}
                                          variant='standard'
                                          helperText={
                                            errorsCalc.twigModel &&
                                            errorsCalc.twigModel.type ===
                                              'topValue'
                                              ? 'Supera máximo'
                                              : ''
                                          }
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.rootHelper,
                                            },
                                          }}
                                          InputProps={{
                                            classes: {
                                              input: classes.inputText,
                                            },
                                            endAdornment: (
                                              <>
                                                {value && (
                                                  <IconButton
                                                    color='primary'
                                                    aria-label='upload picture'
                                                    component='span'
                                                    onClick={() =>
                                                      onResetCalc('twigModel')
                                                    }
                                                  >
                                                    <ClearIcon
                                                      fontSize='small'
                                                      style={{
                                                        color:
                                                          theme.palette
                                                            .grey[400],
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                                <InputAdornment
                                                  position='end'
                                                  variant='filled'
                                                  disableTypography
                                                  style={{
                                                    color: 'black',
                                                    height: 'auto',
                                                    maxHeight: '40px',
                                                    padding: '14px 16px',
                                                    borderRadius:
                                                      theme.shape.borderRadius,
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .default,
                                                  }}
                                                >
                                                  u
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          customInput={TextField}
                                          className='flex-grow-1'
                                          // prefix="$"
                                          decimalSeparator=','
                                          displayType='input'
                                          type='text'
                                          thousandSeparator='.'
                                          allowNegative={true}
                                          decimalScale={1}
                                          placeholder='unidades 10-80'
                                        />
                                      )}
                                    />
                                  </div>
                                </BsCol>
                                <BsCol class='col-md-12 mb-2'>
                                  <Grid
                                    container
                                    direction='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                  >
                                    <Grid item>
                                      <img
                                        src={FlowerIcon}
                                        width='16'
                                        className='img-fluid img-estimated'
                                        alt='dart'
                                      />
                                    </Grid>
                                    <Grid item className='ml-0 ml-lg-1'>
                                      <Typography
                                        variant='h6'
                                        // gutterBottom
                                      >
                                        Yemas/Dardo
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <div className='d-flex'>
                                    <Controller
                                      control={controlCalc}
                                      rules={{
                                        // required: true,
                                        max: infoIntelligence?.information
                                          ?.budDart,
                                      }}
                                      name='flowerModel'
                                      render={({
                                        field: { onChange, name, value },
                                      }) => (
                                        <NumberFormat
                                          thousandsGroupStyle='thousand'
                                          value={value}
                                          onChange={onChange}
                                          error={Boolean(
                                            errorsCalc.flowerModel
                                          )}
                                          disabled={withModelFormDartState}
                                          helperText={
                                            errorsCalc.flowerModel &&
                                            errorsCalc.flowerModel.type ===
                                              'max'
                                              ? 'Supera máximo'
                                              : ''
                                          }
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.rootHelper,
                                            },
                                          }}
                                          InputProps={{
                                            classes: {
                                              input: classes.inputText,
                                            },
                                            endAdornment: (
                                              <>
                                                {value && (
                                                  <IconButton
                                                    color='primary'
                                                    aria-label='upload picture'
                                                    component='span'
                                                    onClick={() =>
                                                      onResetCalc('flowerModel')
                                                    }
                                                  >
                                                    <ClearIcon
                                                      fontSize='small'
                                                      style={{
                                                        color:
                                                          theme.palette
                                                            .grey[400],
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                                <InputAdornment
                                                  position='end'
                                                  variant='filled'
                                                  disableTypography
                                                  style={{
                                                    color: 'black',
                                                    height: 'auto',
                                                    maxHeight: '40px',
                                                    padding: '14px 16px',
                                                    borderRadius:
                                                      theme.shape.borderRadius,
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .default,
                                                  }}
                                                >
                                                  u
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          variant='standard'
                                          customInput={TextField}
                                          className='flex-grow-1'
                                          // prefix="$"
                                          decimalSeparator=','
                                          displayType='input'
                                          type='text'
                                          thousandSeparator='.'
                                          allowNegative={true}
                                          decimalScale={1}
                                          placeholder='unidades 2-10'
                                        />
                                      )}
                                    />
                                  </div>
                                </BsCol>
                              </BsRow>
                            </TabPanel>
                          </SimpleTabs>
                        </BsCol>
                      </BsRow>
                    ) : (
                      // ------> SIN MODELO
                      <BsRow>
                        <BsCol class='col-md-12 mb-3'>
                          <Typography variant='body1'>
                            <b>Sin Modelo Predictivo:</b> Calculo aritmético,
                            con información del Productor.
                            <span className='mx-3'>
                              <IconButton
                                aria-describedby='dd'
                                variant='text'
                                color='primary'
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                              >
                                <InfoIcon />
                              </IconButton>
                            </span>
                          </Typography>

                          <Popover
                            id='simple-popover'
                            open={openPopoverState}
                            className={classes.popover}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div className={classes.popOverContainer}>
                              <Typography variant='caption' component={'p'}>
                                <b>Cuaja:</b> Porcentaje estimado por el
                                productor.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Calibre:</b> Peso estimado por el productor.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Dardos / Ramillas:</b> Objetivo de Poda.
                              </Typography>
                              <Typography variant='caption' component={'p'}>
                                <b>Yemas/Dardo:</b> Cantidad de yemas por dardo.
                              </Typography>
                            </div>
                          </Popover>
                        </BsCol>
                        <BsCol class='col-md-12 mb-2'>
                          <>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                            >
                              <Grid item>
                                <img
                                  src={FruitsetIcon}
                                  width='16'
                                  className='img-fluid img-estimated'
                                  alt='dart'
                                />
                              </Grid>
                              <Grid item className='ml-0 ml-lg-1'>
                                <Typography
                                  variant='h6'
                                  // gutterBottom
                                >
                                  Cuaja *
                                </Typography>
                              </Grid>
                            </Grid>
                            <div className='d-flex'>
                              <Controller
                                control={controlCalc}
                                rules={{
                                  required: true,
                                }}
                                name='fruitSetNoModel'
                                render={({
                                  field: { onChange, name, value },
                                }) => (
                                  <NumberFormat
                                    thousandsGroupStyle='thousand'
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                    error={Boolean(errorsCalc.fruitSetNoModel)}
                                    helperText={
                                      errorsCalc.fruitSetNoModel
                                        ? 'Complete cantidad'
                                        : ''
                                    }
                                    variant='standard'
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.rootHelper,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        input: classes.inputText,
                                      },
                                      endAdornment: (
                                        <>
                                          {value && (
                                            <IconButton
                                              color='primary'
                                              aria-label='upload picture'
                                              component='span'
                                              onClick={() =>
                                                onResetCalc('fruitSetNoModel')
                                              }
                                            >
                                              <ClearIcon
                                                fontSize='small'
                                                style={{
                                                  color:
                                                    theme.palette.grey[400],
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                          <InputAdornment
                                            position='end'
                                            variant='filled'
                                            disableTypography
                                            style={{
                                              color: 'black',
                                              height: 'auto',
                                              maxHeight: '40px',
                                              padding: '14px 16px',
                                              borderRadius:
                                                theme.shape.borderRadius,
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                            }}
                                          >
                                            %
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    customInput={TextField}
                                    className='flex-grow-1'
                                    // prefix="$"
                                    decimalSeparator=','
                                    displayType='input'
                                    type='text'
                                    // ejemplo para pasar máximo
                                    // isAllowed={(
                                    // 	val
                                    // ) =>
                                    // 	withValueLimit(
                                    // 		val,
                                    // 		1000.5
                                    // 	)
                                    // }
                                    thousandSeparator='.'
                                    allowNegative={true}
                                    decimalScale={1}
                                    placeholder='números enteros 10-40'
                                  />
                                )}
                              />
                            </div>
                          </>
                        </BsCol>
                        <BsCol class='col-md-12 mb-2'>
                          <>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                            >
                              <Grid item>
                                <img
                                  src={CaliberIcon}
                                  width='16'
                                  className='img-fluid img-estimated'
                                  alt='dart'
                                />
                              </Grid>
                              <Grid item className='ml-0 ml-lg-1'>
                                <Typography
                                  variant='h6'
                                  // gutterBottom
                                >
                                  Calibre *
                                </Typography>
                              </Grid>
                            </Grid>
                            <div className='d-flex'>
                              <Controller
                                control={controlCalc}
                                rules={{
                                  required: true,
                                }}
                                name='caliberNoModel'
                                render={({
                                  field: { onChange, name, value },
                                }) => (
                                  <NumberFormat
                                    thousandsGroupStyle='thousand'
                                    value={value}
                                    onChange={onChange}
                                    error={Boolean(errorsCalc.caliberNoModel)}
                                    helperText={
                                      errorsCalc.caliberNoModel
                                        ? 'Complete cantidad'
                                        : ''
                                    }
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.rootHelper,
                                      },
                                    }}
                                    variant='standard'
                                    InputProps={{
                                      classes: {
                                        input: classes.inputText,
                                      },
                                      endAdornment: (
                                        <>
                                          {value && (
                                            <IconButton
                                              color='primary'
                                              aria-label='upload picture'
                                              component='span'
                                              onClick={() =>
                                                onResetCalc('caliberNoModel')
                                              }
                                            >
                                              <ClearIcon
                                                fontSize='small'
                                                style={{
                                                  color:
                                                    theme.palette.grey[400],
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                          <InputAdornment
                                            position='end'
                                            variant='filled'
                                            disableTypography
                                            style={{
                                              color: 'black',
                                              height: 'auto',
                                              maxHeight: '40px',
                                              padding: '14px 16px',
                                              borderRadius:
                                                theme.shape.borderRadius,
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                            }}
                                          >
                                            g
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    customInput={TextField}
                                    className='flex-grow-1'
                                    // prefix="$"
                                    decimalSeparator=','
                                    displayType='input'
                                    type='text'
                                    thousandSeparator='.'
                                    allowNegative={true}
                                    decimalScale={1}
                                    placeholder='entre 9-13 grs'
                                  />
                                )}
                              />
                            </div>
                          </>
                        </BsCol>
                        <BsCol class='col-md-12 my-2'>
                          <>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                            >
                              <Grid item>
                                <img
                                  src={DartIcon}
                                  width='16'
                                  className='img-fluid img-estimated'
                                  alt='dart'
                                />
                              </Grid>
                              <Grid item className='ml-0 ml-lg-1'>
                                <Typography
                                  variant='h6'
                                  // gutterBottom
                                >
                                  Dardos
                                </Typography>
                              </Grid>
                            </Grid>
                            <div className='d-flex'>
                              <Controller
                                control={controlCalc}
                                rules={{
                                  // required: true,
                                  validate: validateDart,
                                }}
                                name='dartNoModel'
                                render={({
                                  field: { onChange, name, value },
                                }) => (
                                  <NumberFormat
                                    thousandsGroupStyle='thousand'
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                    error={Boolean(errorsCalc.dartNoModel)}
                                    variant='standard'
                                    helperText={
                                      errorsCalc.dartNoModel &&
                                      errorsCalc.dartNoModel.type === 'topValue'
                                        ? 'Supera máximo'
                                        : ''
                                    }
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.rootHelper,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        input: classes.inputText,
                                      },
                                      endAdornment: (
                                        <>
                                          {value && (
                                            <IconButton
                                              color='primary'
                                              aria-label='upload picture'
                                              component='span'
                                              onClick={() =>
                                                onResetCalc('dartNoModel')
                                              }
                                            >
                                              <ClearIcon
                                                fontSize='small'
                                                style={{
                                                  color:
                                                    theme.palette.grey[400],
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                          <InputAdornment
                                            position='end'
                                            variant='filled'
                                            disableTypography
                                            style={{
                                              color: 'black',
                                              height: 'auto',
                                              maxHeight: '40px',
                                              padding: '14px 16px',
                                              borderRadius:
                                                theme.shape.borderRadius,
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                            }}
                                          >
                                            u
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    customInput={TextField}
                                    // ejemplo para pasar máximo
                                    // isAllowed={(
                                    // 	val
                                    // ) =>
                                    // 	withValueLimit(
                                    // 		val,
                                    // 		infoIntelligence
                                    // 			?.information
                                    // 			?.dart
                                    // 	)
                                    // }
                                    className='flex-grow-1'
                                    // prefix="$"
                                    decimalSeparator=','
                                    displayType='input'
                                    type='text'
                                    thousandSeparator='.'
                                    allowNegative={true}
                                    decimalScale={1}
                                    placeholder='unidades 100-1200'
                                  />
                                )}
                              />
                            </div>
                          </>
                        </BsCol>
                        <BsCol class='col-md-12 my-2'>
                          <>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                            >
                              <Grid item>
                                <img
                                  src={TwigIcon}
                                  width='16'
                                  className='img-fluid img-estimated'
                                  alt='dart'
                                />
                              </Grid>
                              <Grid item className='ml-0 ml-lg-1'>
                                <Typography
                                  variant='h6'
                                  // gutterBottom
                                >
                                  Ramillas
                                </Typography>
                              </Grid>
                            </Grid>
                            <div className='d-flex'>
                              <Controller
                                control={controlCalc}
                                rules={{
                                  // required: true,
                                  validate: validateTwig,
                                }}
                                name='twigNoModel'
                                render={({
                                  field: { onChange, name, value },
                                }) => (
                                  <NumberFormat
                                    thousandsGroupStyle='thousand'
                                    value={value}
                                    onChange={onChange}
                                    error={Boolean(errorsCalc.twigNoModel)}
                                    helperText={
                                      errorsCalc.twigNoModel &&
                                      errorsCalc.twigNoModel.type === 'topValue'
                                        ? 'Supera máximo'
                                        : ''
                                    }
                                    variant='standard'
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.rootHelper,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        input: classes.inputText,
                                      },
                                      endAdornment: (
                                        <>
                                          {value && (
                                            <IconButton
                                              color='primary'
                                              aria-label='upload picture'
                                              component='span'
                                              onClick={() =>
                                                onResetCalc('twigNoModel')
                                              }
                                            >
                                              <ClearIcon
                                                fontSize='small'
                                                style={{
                                                  color:
                                                    theme.palette.grey[400],
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                          <InputAdornment
                                            position='end'
                                            variant='filled'
                                            disableTypography
                                            style={{
                                              color: 'black',
                                              height: 'auto',
                                              maxHeight: '40px',
                                              padding: '14px 16px',
                                              borderRadius:
                                                theme.shape.borderRadius,
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                            }}
                                          >
                                            u
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    customInput={TextField}
                                    className='flex-grow-1'
                                    // prefix="$"
                                    decimalSeparator=','
                                    displayType='input'
                                    type='text'
                                    thousandSeparator='.'
                                    allowNegative={true}
                                    decimalScale={1}
                                    placeholder='unidades 10-80'
                                  />
                                )}
                              />
                            </div>
                          </>
                        </BsCol>
                        <BsCol class='col-md-12 my-2'>
                          <>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                            >
                              <Grid item>
                                <img
                                  src={FlowerIcon}
                                  width='16'
                                  className='img-fluid img-estimated'
                                  alt='dart'
                                />
                              </Grid>
                              <Grid item className='ml-0 ml-lg-1'>
                                <Typography
                                  variant='h6'
                                  // gutterBottom
                                >
                                  Yemas/Dardo
                                </Typography>
                              </Grid>
                            </Grid>
                            <div className='d-flex'>
                              <Controller
                                control={controlCalc}
                                rules={{
                                  // required: true,
                                  max: infoIntelligence?.information?.budDart,
                                }}
                                name='flowerNoModel'
                                render={({
                                  field: { onChange, name, value },
                                }) => (
                                  <NumberFormat
                                    thousandsGroupStyle='thousand'
                                    value={value}
                                    onChange={onChange}
                                    error={Boolean(errorsCalc.flowerNoModel)}
                                    variant='standard'
                                    helperText={
                                      errorsCalc.flowerNoModel &&
                                      errorsCalc.flowerNoModel.type === 'max'
                                        ? 'Supera máximo'
                                        : ''
                                    }
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.rootHelper,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        input: classes.inputText,
                                      },
                                      endAdornment: (
                                        <>
                                          {value && (
                                            <IconButton
                                              color='secondary'
                                              aria-label='upload picture'
                                              component='span'
                                              onClick={() =>
                                                onResetCalc('flowerNoModel')
                                              }
                                            >
                                              <ClearIcon
                                                fontSize='small'
                                                style={{
                                                  color:
                                                    theme.palette.grey[400],
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                          <InputAdornment
                                            position='end'
                                            variant='filled'
                                            disableTypography
                                            style={{
                                              color: 'black',
                                              height: 'auto',
                                              maxHeight: '40px',
                                              padding: '14px 16px',
                                              borderRadius:
                                                theme.shape.borderRadius,
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                            }}
                                          >
                                            u
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    customInput={TextField}
                                    className='flex-grow-1'
                                    // prefix="$"
                                    decimalSeparator=','
                                    displayType='input'
                                    type='text'
                                    thousandSeparator='.'
                                    allowNegative={true}
                                    decimalScale={1}
                                    placeholder='unidades 2-10'
                                  />
                                )}
                              />
                            </div>
                          </>
                        </BsCol>
                      </BsRow>
                    )}
                    <BsRow>
                      <BsCol class='col-md-12'>
                        <div className='mb-3'>
                          <Typography variant='caption'>
                            * Campos obligatorios.
                          </Typography>
                        </div>
                      </BsCol>
                      <BsCol class='col-md-12 text-center pt-2 mb-3'>
                        <div className='d-flex justify-content-between mb-3'>
                          {/* botones limpiar y traer datos */}
                          <Button
                            color='secondary'
                            // size='small'
                            variant='outlined'
                            fullWidth
                            disabled={!activeCalButtonState}
                            onClick={getValuesForm}
                          >
                            Traer Datos
                          </Button>
                          {useModel && (
                            <Button
                              color='secondary'
                              // size='small'
                              fullWidth
                              disabled={
                                !activeCalButtonState || !expectedInfoState
                              }
                              onClick={getValuesModelForm}
                              className='ml-md-3'
                            >
                              Copiar Recomendación
                            </Button>
                          )}
                        </div>
                        <SaveAction
                          title='Calcular'
                          color='secondary'
                          size='large'
                          fullWidth={true}
                          disabled={!activeCalAritmeticButtonState}
                          // className='btn-block btn-Intelligence-calc'
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingExpectedState}
                          success={false}
                        />
                        <Button
                          color='error'
                          variant='outlined'
                          fullWidth
                          sx={{ mt: 2 }}
                          onClick={clearAndBasalFormHandler}
                          disabled={!activeCalAritmeticButtonState}
                          // className={classes.textButtonStyle}
                        >
                          Limpiar Formulario
                        </Button>
                      </BsCol>
                    </BsRow>
                  </form>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-8 col-lg-8'>
                <BsRow>
                  <BsCol class='col-md-12'>
                    <CardPaper
                      elevation={0}
                      variant='outlined'
                      sx={{
                        padding: 1,
                        height: '100%',
                        borderColor: theme.palette.grey[300],
                      }}
                    >
                      <Typography variant='h5' gutterBottom className='mb-2'>
                        Tabla Data Histórica{' '}
                        {/* <Tooltip
                                  title='Escenario con información basal sin realizar manejo. No se recomienda dejar un cuartel sin podar, ya que afectará directamente su vida útil productiva.'
                                  placement='right'
                                >
                                  <i className='fas fa-info-circle'></i>
                                </Tooltip> */}
                      </Typography>
                      <div
                        style={{
                          // width: '100%',
                          maxHeight: '270px',
                          position: 'relative',
                          // overflowY: 'auto',
                        }}
                        className='table-responsive'
                      >
                        <table
                          className={clsx('table', classes.withoutPruneTable)}
                          style={{ marginBottom: 0 }}
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th>Año</th>
                              <th>Flores</th>
                              <th>Frutos/Dardo Real</th>
                              <th>Cuaja</th>
                              <th>Calibre</th>
                              <th>Rendim.</th>
                              <th>P. Frío</th>
                            </tr>
                          </thead>
                          <tbody>
                            {infoIntelligence ? (
                              infoIntelligence.dataTableGraphs.tableData.map(
                                (yearData, i) => {
                                  return (
                                    <tr key={`${i}_row`}>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData && yearData.year
                                            ? yearData.year
                                            : '-'}{' '}
                                        </Typography>
                                      </td>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData && yearData.flower
                                            ? numberFormatDecimals(
                                                yearData.flower,
                                                0
                                              )
                                            : '-'}{' '}
                                        </Typography>
                                      </td>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData && yearData.fruitsDartReal
                                            ? numberFormatDecimals(
                                                yearData.fruitsDartReal,
                                                1
                                              )
                                            : '-'}{' '}
                                        </Typography>
                                      </td>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData.year === actualYear ? (
                                            <span className='text-primary font-weight-bold'>
                                              {yearData && yearData.fruitSet
                                                ? numberFormatDecimals(
                                                    yearData.fruitSet,
                                                    1
                                                  )
                                                : dataScenaryHistoryTableState &&
                                                  dataScenaryHistoryTableState.fruitSet
                                                ? numberFormatDecimals(
                                                    dataScenaryHistoryTableState.fruitSet,
                                                    1
                                                  )
                                                : '-'}
                                            </span>
                                          ) : yearData && yearData.fruitSet ? (
                                            numberFormatDecimals(
                                              yearData.fruitSet,
                                              1
                                            )
                                          ) : (
                                            '-'
                                          )}{' '}
                                        </Typography>
                                      </td>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData.year === actualYear ? (
                                            <span className='text-primary font-weight-bold'>
                                              {yearData && yearData.weight
                                                ? numberFormatDecimals(
                                                    yearData.weight,
                                                    1
                                                  )
                                                : dataScenaryHistoryTableState &&
                                                  dataScenaryHistoryTableState.weight
                                                ? numberFormatDecimals(
                                                    dataScenaryHistoryTableState.weight,
                                                    1
                                                  )
                                                : '-'}
                                            </span>
                                          ) : yearData && yearData.weight ? (
                                            numberFormatDecimals(
                                              yearData.weight,
                                              1
                                            )
                                          ) : (
                                            '-'
                                          )}{' '}
                                        </Typography>
                                      </td>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData.year === actualYear ? (
                                            <span className='text-primary font-weight-bold'>
                                              {yearData && yearData.performance
                                                ? numberFormatDecimals(
                                                    yearData.performance,
                                                    1
                                                  )
                                                : dataScenaryHistoryTableState &&
                                                  dataScenaryHistoryTableState.performance
                                                ? numberFormatDecimals(
                                                    dataScenaryHistoryTableState.performance,
                                                    1
                                                  )
                                                : '-'}
                                            </span>
                                          ) : yearData &&
                                            yearData.performance ? (
                                            numberFormatDecimals(
                                              yearData.performance,
                                              1
                                            )
                                          ) : (
                                            '-'
                                          )}{' '}
                                        </Typography>
                                      </td>
                                      <td>
                                        <Typography
                                          variant='h5'
                                          className={classes.noPruneData}
                                        >
                                          {yearData && yearData.portion
                                            ? numberFormatDecimals(
                                                yearData.portion,
                                                2
                                              )
                                            : '-'}{' '}
                                        </Typography>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}></td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th></th>
                              <th>
                                {infoIntelligence &&
                                infoIntelligence.dataTableGraphs.promFlower
                                  ? numberFormatDecimals(
                                      infoIntelligence.dataTableGraphs
                                        .promFlower,
                                      0
                                    )
                                  : '-'}{' '}
                              </th>
                              <th>
                                {infoIntelligence &&
                                infoIntelligence.dataTableGraphs
                                  .promFruitDartReal
                                  ? numberFormatDecimals(
                                      infoIntelligence.dataTableGraphs
                                        .promFruitDartReal,
                                      0
                                    )
                                  : '-'}{' '}
                              </th>
                              <th>
                                {infoIntelligence &&
                                infoIntelligence.dataTableGraphs.promFruitSet
                                  ? numberFormatDecimals(
                                      infoIntelligence.dataTableGraphs
                                        .promFruitSet,
                                      1
                                    )
                                  : '-'}{' '}
                              </th>
                              <th>
                                {infoIntelligence &&
                                infoIntelligence.dataTableGraphs.promWeight
                                  ? numberFormatDecimals(
                                      infoIntelligence.dataTableGraphs
                                        .promWeight,
                                      1
                                    )
                                  : '-'}{' '}
                              </th>
                              <th>
                                {infoIntelligence &&
                                infoIntelligence.dataTableGraphs.promPerformance
                                  ? numberFormatDecimals(
                                      infoIntelligence.dataTableGraphs
                                        .promPerformance,
                                      0
                                    )
                                  : '-'}{' '}
                              </th>
                              <th>
                                {infoIntelligence &&
                                infoIntelligence.dataTableGraphs.promPortion
                                  ? numberFormatDecimals(
                                      infoIntelligence.dataTableGraphs
                                        .promPortion,
                                      2
                                    )
                                  : '-'}{' '}
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <Box
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        <Button
                          variant='outlined'
                          color='secondary'
                          onClick={handleOpenModal}
                          disabled={!activeCalButtonState}
                        >
                          Gráficas
                        </Button>
                      </Box>
                    </CardPaper>
                  </BsCol>
                </BsRow>
                {/* recommendation */}
                <BsRow>
                  <BsCol class='col-md-12 mt-3'>
                    {/* RECOMENDACIÓN */}
                    {loadingExpectedState && <LoadingWidget />}

                    {expectedInfoState && !loadingExpectedState && (
                      <CardPaper
                        elevation={3}
                        topColor={theme.palette.secondary.main}
                        style={{ padding: 1 }}
                      >
                        <RecommendationBox
                          closeFunc={closeRecommendationHandler}
                          saveFunc={onSaveScenaryHandler}
                          saveLoading={savingScenaryState}
                          saveSuccess={saveSuccessState}
                          closeSuccessFunc={redirectHandler}
                          noData={noDataCalcState}
                          expectedInfo={expectedInfoState}
                          withModel={useModel}
                          scenaries={scenariesDataState}
                          selectedScenary={selectedScenaryState}
                          changeHandler={radioChangeHandler}
                          fruitsDartEstimateState={fruitsDartEstimateState}
                          alert={alertMessageState}
                          withFruitsDart={withFruitsDartState}
                          budDartBasal={infoIntelligence?.information?.budDart}
                        />
                      </CardPaper>
                    )}
                    {noModelMessageState && (
                      <Typography variant='h4' color='secondary'>
                        <i className='far fa-bell mr-3'></i>
                        {noModelMessageState}
                      </Typography>
                    )}
                    {!expectedInfoState && !loadingExpectedState && (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          minHeight: '300px',
                          // backgroundColor:
                          // 	'yellow',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{}}>
                          <Typography
                            variant='body1'
                            style={{
                              fontStyle: 'italic',
                            }}
                          >
                            <i className='far fa-bell'></i> Utilice el
                            formulario para recibir recomendaciones.
                          </Typography>
                        </div>
                      </div>
                    )}
                  </BsCol>
                </BsRow>
              </BsCol>
            </BsRow>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Intelligence;
