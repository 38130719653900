import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, Typography, useTheme } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import { LoadingWidget } from '../../../components';
import { DATATABLE, GANTT } from '../../../helpers/Mockups';
import { AxiosContext } from '../../../context/AxiosContext';
import { dashDelete, dateFormatIso, IsDesktopHandler } from '../../../helpers';
import { useRef } from 'react';
import { GanttComponent } from '../GanttComponent';
import { Context } from '../../../context/Context';
import { useErrorHandler } from 'react-error-boundary';
import ModalEdit from '../ModalEdit';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
  },
  dividerHeight: {
    minHeight: '70px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const getFirstLastDate = (dataChart) => {
  // console.log(dataChart, '---dataChart em get first');
  // TODO: si es null debe buscar la próxima
  // let firstDate = dataChart[0].data[0]?.beginDate;

  const validBeginDates = dataChart[0].data.filter(
    (item) => item.beginDate !== null
  );

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  let firstDate = `${currentYear}-01-02`;

  // Encontrar la fecha más temprana
  if (validBeginDates.length > 0) {
    const earliestDate = validBeginDates.reduce((minDate, item) => {
      const currentDate = new Date(item.beginDate);
      return currentDate < minDate ? currentDate : minDate;
    }, new Date(validBeginDates[0].beginDate));

    // console.log('Fecha más temprana:', earliestDate);

    firstDate = earliestDate;
  }
  // console.log(subDays(new Date(firstDate), 1), "----------firstDate", firstDate)

  const validEndDates = dataChart[0].data.filter(
    (item) => item.endDate !== null
  );

  let lastDate = `${currentYear}-12-29`;

  if (validEndDates.length > 0) {
    const latestDate = validEndDates.reduce((maxDate, item) => {
      const currentDate = new Date(item.endDate);
      return currentDate > maxDate ? currentDate : maxDate;
    }, new Date(validEndDates[0].endDate));

    // console.log('Fecha más temprana:', latestDate);

    lastDate = latestDate;
  }

  // console.log(lastDate, '---del array');

  return [subDays(new Date(firstDate), 1), addDays(new Date(lastDate), 2)];
};

const Gantt = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const isDesktop = IsDesktopHandler('md');

  const almahueRef = useRef();

  const [loadingGraphState, setLoadingGraphState] = useState(false);

  const [dataMapGanttsState, setDataMapGanttsState] = useState(null);

  const [openModalState, setOpenModalState] = useState(false);

  const [orchardToEditState, setOrchardToEditState] = useState(null);

  const [firstDateState, setFirstDateState] = useState(null);
  const [lastDateState, setLastDateState] = useState(null);

  const customStyles = {
    headCells: {
      style: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        '&: nth-last-child(-n+3)': {
          backgroundColor: theme.palette.primary.main,
          borderBottom: '4px solid',
          borderColor: theme.palette.secondary.light,
        },
        div: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      },
    },
  };

  async function queryData() {
    setLoadingGraphState(true);
    try {
      // console.log(filterProp, '-------filterProp');
      let objQuery = {
        huerto_user: userOrchard, //orchard del login
      };
      if (filterProp) {
        const { huerto, variedad, sinceDate, untilDate } = filterProp;

        console.log(huerto, variedad, sinceDate, untilDate, '----filtros');

        objQuery = {
          ...objQuery,
          huerto: huerto,
          variedad: variedad,
        };
      }

      // console.log(objQuery, '---la query que va');

      const response = await axiosContext.authAxios.post(
        'v1/harvest-projection',
        objQuery
      );

      const { data, status } = response;

      // console.log(response, '<-----response query');

      // setCountState(data.totalPassengers);
      if (data) {
        console.log(data, '----data');

        setLoadingGraphState(false);
        setDataMapGanttsState(data);

        if (data.length > 0 && data[0].data && data[0].data.length) {
          // sacar la primer y última fecha
          const [firstDate, lastDate] = getFirstLastDate(data);

          console.log(firstDate, lastDate, '------first y last');

          // console.log(dateFormatIso(firstDate), dateFormatIso(lastDate),"------------------", lastDate, firstDate);
          setFirstDateState(dateFormatIso(firstDate));
          setLastDateState(dateFormatIso(lastDate));
        }
      }
    } catch (err) {
      setLoadingGraphState(false);
      errorHandler(err);
    }
  }

  useEffect(() => {
    // console.log('filtros');
    if (filterProp) {
      // console.log(filterProp, '-------------filtro');
      queryData();
      // setDataMapGanttsState(GANTT);
    }
  }, [filterProp]);

  useEffect(() => {
    // console.log('-----se ejecuta 1ra vez');
    queryData();
  }, []);

  // modal para editar fechas
  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleOpenModal = () => {
    setOpenModalState(true);
  };

  const handleEdit = useCallback((orchard) => {
    console.log(orchard, '----orchard');
    setOrchardToEditState(orchard);
    handleOpenModal();
  }, []);

  // función que recibe la nueva data y la reemplaza en el dataMapGanttsState
  const handleUpdateDataOrchard = (newData) => {
    console.log(newData, '----newData para editar en gantt');

    const newMapData = dataMapGanttsState.map((orchard) => {
      if (orchard.id === newData.id) {
        return newData;
      } else {
        return orchard;
      }
    });

    setDataMapGanttsState(newMapData);
  };

  return (
    <BsRow class='justify-content-between' style={{ marginTop: -20 }}>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          {!loadingGraphState ? (
            <>
              {dataMapGanttsState &&
                dataMapGanttsState.length > 0 &&
                dataMapGanttsState.map((orchard) => (
                  <React.Fragment key={orchard.id}>
                    <GanttComponent
                      title={dashDelete(orchard.orchard)}
                      dataChart={orchard}
                      firstDate={firstDateState}
                      lastDate={lastDateState}
                      editFunction={() => handleEdit(orchard)}
                    />
                    <div className={classes.dividerHeight}></div>
                  </React.Fragment>
                ))}
              {orchardToEditState ? (
                <Modal
                  open={openModalState}
                  onClose={handleCloseModal}
                  className={classes.modal}
                  aria-labelledby='simple-modal-title'
                  aria-describedby='simple-modal-description'
                >
                  <>
                    <ModalEdit
                      closeAction={handleCloseModal}
                      dataModal={orchardToEditState}
                      updateDataOrchard={handleUpdateDataOrchard}
                    />
                  </>
                </Modal>
              ) : null}
            </>
          ) : (
            <>
              <LoadingWidget />
            </>
          )}
        </div>
      </BsCol>
    </BsRow>
  );
};

export default Gantt;
