import {
  Card,
  CardContent,
  Divider,
  Hidden,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import clsx from 'clsx';
import { addDays, subDays } from 'date-fns';
import _ from 'lodash';
import { useErrorHandler } from 'react-error-boundary';
import { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useForm } from 'react-hook-form';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { AxiosContext } from '../../context/AxiosContext';
import { Context } from '../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../components';
import { FiltersContext } from '../../context';
import {
  dateFormatIso,
  numberFormatDecimals,
  generalOptionsHighCharts,
  getOrchardsBySpecie,
} from '../../helpers';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  subtitleDash: {
    fontSize: 17 + '!important',
    fontWeight: 700 + '!important',
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
  rootDayWeather: {
    flexGrow: 1,
  },
  dayWeatherBox: {
    backgroundColor: 'lightblue',
    padding: 6,
    textAlign: 'center',
    borderRadius: 6,
  },
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
  },
  dividerHeight: {
    minHeight: '70px',
  },
  progressBoxStyle: {
    '& h6': {
      textAlign: 'center',
      lineHeight: '20px',
    },
    '& h6.percentage': {
      fontSize: 30,
    },
  },
}));

Highcharts.setOptions({
  lang: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    decimalPoint: ',',
  },
});

const COLORS = ['#2A4C60', '#345EB5', '#00ADB5', '#9BB9F7'];
const COLORS_PARTICIPATION = ['#2A4C60', '#345EB5', '#00ADB5', '#1295D4'];
const COLORS_SEASON = ['#447920', '#c6c6c6', '#1295D4', '#c44545'];

const stackedColumns = (array) => {
  const newArray = array.map((item) => {
    if (item.name === 'Rend Conteo' || item.name === 'Rend Modelo') {
      return {
        ...item,
        stack: 'Analytics',
      };
    } else {
      return {
        ...item,
        stack: item.name,
      };
    }
  });
  return newArray;
};

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;
const beforeLastYear = currentYear - 2;
const threeYearsAgo = currentYear - 3;
const fourYearsAgo = currentYear - 4;

const YieldDashboard = () => {
  // let history = useHistory();

  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { moduleUser, userOrchard, userClient } = useContext(Context);

  const errorHandler = useErrorHandler();

  const chartTotal1Ref = useRef(null);
  const chartTotal2Ref = useRef(null);

  const {
    state: { userOrchard_filter, specie_filter },
    addFilter,
  } = useContext(FiltersContext);

  // console.log(userOrchard, userClient, userOrchard_filter, '------');

  const clientValue = userClient?.value || 'Garces_Fruit';

  const moduleOrchardFilter = 'statusDashboard';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [loadingFiltersState] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);
  const [dashDataState, setDashDataState] = useState(null);
  const [jsonDataState, setJsonDataState] = useState(null);
  const [noDataState, setNoDataState] = useState(false);

  // Producción por huerto
  const optionsStackProductionByOrchard = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      zoomType: 'x',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: dashDataState?.orchards,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Producción (Kg.)',
      },
    },

    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b><br/><b>' +
          this?.x +
          '</b> '
        );
      },
    },
    colors: COLORS,
    series: dashDataState?.productionByOrchard.data || [],
    plotOptions: {
      series: {
        pointPadding: 0.06,
      },
      column: {
        stacking: 'normal',
      },
    },
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Kg Totales',
    },
  };

  // stacked Producción Total
  const chartOptionsProdTotal = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      height: 300,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    title: {
      text: '',
    },
    xAxis: {
      // ['2021', '2022', 'Rend Conteo / Rend Modelo'],
      // need a function that takes the categories from the data and returns the array like above
      labels: {
        enabled: false, // Esto oculta los nombres en el eje x
      },
      categories: [],
      crosshair: true,
      legend: {
        enabled: false,
      },
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b>'
        );
      },
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    colors: COLORS,
    series: dashDataState?.productionTotal?.data,
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción Total (Kg)',
    },
  };

  // Producción por Variedad
  const optionsHighChartsBarProductionByVarieties = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b><br/><b>' +
          this?.x +
          '</b> '
        );
      },
    },
    xAxis: {
      categories: dashDataState?.productionByVarieties?.varieties || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    colors: COLORS,
    series: dashDataState?.productionByVarieties?.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Kg Totales por Variedad',
    },
  };

  // Rendimiento por Variedad
  const optionsHighChartsBarYieldByVarieties = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg/ha</b><br/><b>' +
          this?.x +
          '</b> '
        );
      },
    },
    xAxis: {
      categories: dashDataState?.yieldByVarieties?.varieties || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Rendimiento (Kg/ha)',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    colors: COLORS,
    series: dashDataState?.yieldByVarieties?.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Kg por Hectárea por Variedad',
    },
  };

  // Participation
  const optionsHighChartsBarParticipation = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 20,
    },
    tooltip: {
      formatter: function () {
        return '<b>' + numberFormatDecimals(this.y, 1) + '%</b><br/>' + this?.x;
      },
    },
    plotOptions: {
      column: {
        // dataLabels: {
        //   enabled: true,
        //   formatter: function () {
        //     return this.y.toFixed(1) + ' %'; // Mostrar dos decimales en los dataLabels
        //   },
        // },
        stacking: 'normal', // Apila las columnas
        pointPadding: 0.02, // Espacio entre columnas dentro del grupo
        groupPadding: 0.06, // Espacio entre grupos de columnas
      },
    },
    xAxis: {
      categories: dashDataState?.orchards || [],
      // crosshair: true,
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      // max: 100,
      labels: {
        overflow: 'justify',
        formatter: function () {
          return numberFormatDecimals(this.value, 1) + '%';
        },
      },
      gridLineWidth: 0,
      title: {
        text: '',
      },
    },
    legend: {
      align: 'center',
    },
    colors: COLORS_PARTICIPATION,
    series: dashDataState?.participation?.data
      ? stackedColumns(dashDataState?.participation?.data)
      : [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Participación',
    },
  };

  // Rendimiento por huerto
  const optionsHighChartsBarYield = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 320,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          numberFormatDecimals(this.y, 1) +
          ' kg/ha</b><br/><b>' +
          this.series.name +
          ' - ' +
          this?.x +
          '</b>'
        );
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0.06,
      },
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    xAxis: {
      categories: dashDataState?.orchards,
    },
    yAxis: {
      min: 0,
      allowDecimals: false,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Rendimiento (kg/ha)',
      },
    },
    // legend: {
    // 	reversed: true, // right here!
    // },
    colors: COLORS,
    series: dashDataState?.yieldByOrchard.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Kg por Hectárea',
    },
  };

  // stacked Rendimiento Total
  const chartOptionsYieldTotal = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      height: 250,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    title: {
      text: '',
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg/ha</b>'
        );
      },
    },
    xAxis: {
      // ['2021', '2022', 'Rend Conteo / Rend Modelo'],
      // need a function that takes the categories from the data and returns the array like above
      labels: {
        enabled: false, // Esto oculta los nombres en el eje x
      },
      categories: [],
      crosshair: true,
      legend: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Rendimiento (Kg/ha)',
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    colors: COLORS,
    series: dashDataState?.yieldTotal?.data,
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Rendimiento Total (Kg/ha)',
    },
  };

  // Calibres
  const optionsHighChartsBarCalibers = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 20,
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' + numberFormatDecimals(this.y, 1) + 'grs.</b><br/>' + this?.x
        );
      },
    },
    plotOptions: {
      column: {
        // dataLabels: {
        //   enabled: true,
        //   formatter: function () {
        //     return this.y.toFixed(1) + ' %'; // Mostrar dos decimales en los dataLabels
        //   },
        // },
        stacking: 'normal', // Apila las columnas
        pointPadding: 0.02, // Espacio entre columnas dentro del grupo
        groupPadding: 0.06, // Espacio entre grupos de columnas
      },
    },
    xAxis: {
      categories: dashDataState?.orchards || [],
      // crosshair: true,
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      // max: 100,
      labels: {
        overflow: 'justify',
        formatter: function () {
          return numberFormatDecimals(this.value, 1) + 'grs.';
        },
      },
      gridLineWidth: 0,
      title: {
        text: '',
      },
    },
    legend: {
      align: 'center',
    },
    colors: COLORS_PARTICIPATION,
    series: dashDataState?.caliber?.data
      ? stackedColumns(dashDataState?.caliber?.data)
      : [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Promedios de Calibre',
    },
  };

  const onSubmit = async (values) => {
    setLoadingDashDataState(true);

    // console.log(values, '·-----asi llega');
    setJsonDataState(null);
    setDashDataState(null);
    setNoDataState(false);

    let orchardFilter = userOrchard;

    if (values.orchard) {
      orchardFilter = [values?.orchard?.value];
    }

    console.log(values, '--------------------------values');

    const dataQuery = {
      orchards: orchardFilter,
      clientValue: clientValue,
      spevieValue: specie_filter,
      // orchard: '',
      // orchards: ['Almahue', 'Bodega', 'San_Hernan'],
      // clientValue: 'Garces_Fruit',
    };

    try {
      const response = await axiosContext.authAxios.post(
        'v1/dashboard/get-dashboard-rend',
        dataQuery
      );
      const { data, status } = response;

      console.log(data, '------------------------------------------endpoint');

      if (status === 200) {
        if (data.orchards?.length) {
          const dataPlusMock = {
            ...data,
          };

          setDashDataState(dataPlusMock);
        } else {
          setJsonDataState([]);
          setNoDataState(true);
        }
      }
      setLoadingDashDataState(false);
    } catch (error) {
      // console.log(error);
      // función del boundary, pasa el error
      // se aplica en los cach
      errorHandler(error);
      setLoadingDashDataState(false);
    }
  };

  let productionCountModel = 0;
  let productionCountModelSeason = 0;
  let yieldTotal = 0;
  // useEffect(() => {
  //   if (dashDataState) {
  //     productionCountModel =
  //       dashDataState?.productionTotal?.data[2].data[0] +
  //       dashDataState?.productionTotal?.data[3].data[0] +
  //       dashDataState?.productionTotal?.data[4].data[0];

  //     setTotalProductionState(productionCountModel);

  //     yieldTotal =
  //       dashDataState?.yieldTotal?.data[2].data[0] +
  //       dashDataState?.yieldTotal?.data[3].data[0] +
  //       dashDataState?.yieldTotal?.data[4].data[0];
  //     setTotalYieldState(yieldTotal);

  //     productionCountModelSeason =
  //       dashDataState?.productionSeasonTotal?.data[1].data[0] +
  //       dashDataState?.productionSeasonTotal?.data[2].data[0] +
  //       dashDataState?.productionSeasonTotal?.data[3].data[0];

  //     setTotalProductionSeasonState(productionCountModelSeason);
  //   }
  // }, [dashDataState]);

  console.log(dashDataState, '--------------------------dashDataState');

  const getYearTotal = (data, year) => {
    const yearData = data
      ? data.find((item) => item.name === year.toString())
      : null;
    return yearData?.data[0] || null;
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-8'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Rendimiento
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        {/* <TextFieldWidgetSelectController
                          options={filterDataResponseState}
                          control={control}
                          titleOption='label'
                          name='orchard'
                          labeltext='Huerto'
                          errors={errors.orchard}
                          defaultValue=''
                          loading={loadingFiltersState}
                          req={false}
                          withDefault
                          variant='standard'
                        /> */}
                        <SelectMultipleWidget2
                          errors={errors.orchard}
                          loading={loadingFiltersState}
                          control={control}
                          menuPortalTarget={document.body}
                          labeltext=''
                          // req
                          name='orchard'
                          display='vertical'
                          variant='outlined'
                          isMulti={false}
                          isClearable={true}
                          isSearchable
                          onlyOptionValue={false}
                          defaultValue=''
                          options={getOrchardsBySpecie(
                          filterDataResponseState,
                          specie_filter)}
                          placeholder='Huerto'
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'flex-start',
                          paddingTop: 5,
                        }}
                      >
                        <SaveAction
                          title=' Filtrar'
                          color='secondary'
                          size='large'
                          className='roundedButton ml-md-2'
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingDashDataState}
                          success={false}
                        />
                      </div>
                    </form>
                  </BsCol>
                  <BsCol class='col-md-4 align-self-end text-right'>
                    <Typography variant='h6' gutterBottom>
                      {new Date().toLocaleDateString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        {!loadingDashDataState ? (
          dashDataState ? (
            <>
              <BsRow class=''>
                <BsCol class='col-md-12'>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{ borderColor: theme.palette.grey[200] }}
                  >
                    <CardContent>
                      <BsRow class=''>
                        {/* General */}
                        <BsCol class='col-md-10 col-lg-9 mb-3 mb-md-0 pr-md-4'>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={9}>
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Kg Totales
                              </Typography>
                              <p>
                                Resumen de la producción en los años{' '}
                                {fourYearsAgo.toString() +
                                  ' - ' +
                                  threeYearsAgo.toString() +
                                  ' - ' +
                                  beforeLastYear.toString() +
                                  ' - ' +
                                  lastYear.toString()}
                              </p>
                            </Grid>
                          </Grid>

                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsStackProductionByOrchard}
                          />
                        </BsCol>
                        <BsCol class='col-md-2 col-lg-3 pl-md-4'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Producción Total (Kg)
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            justifyContent='space-between'
                          >
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.productionTotal?.data,
                                    fourYearsAgo
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[0] }}
                                ></i>{' '}
                                {fourYearsAgo.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.productionTotal?.data,
                                    threeYearsAgo
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[1] }}
                                ></i>{' '}
                                {threeYearsAgo.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.productionTotal?.data,
                                    beforeLastYear
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[2] }}
                                ></i>{' '}
                                {beforeLastYear.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.productionTotal?.data,
                                    lastYear
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[3] }}
                                ></i>{' '}
                                {lastYear.toString()}
                              </p>
                            </Grid>
                          </Grid>
                          <HighchartsReact
                            highcharts={Highcharts}
                            ref={chartTotal1Ref}
                            options={chartOptionsProdTotal}
                          />
                        </BsCol>
                      </BsRow>
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{ borderColor: theme.palette.grey[200] }}
                  >
                    <CardContent>
                      <BsRow class=''>
                        <BsCol class='col-md-10 col-lg-9 pr-md-4'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Kg por Hectárea
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={12}>
                              <p>
                                Resumen del rendimiento en los años{' '}
                                {fourYearsAgo.toString() +
                                  ' - ' +
                                  threeYearsAgo.toString() +
                                  ' - ' +
                                  beforeLastYear.toString() +
                                  ' - ' +
                                  lastYear.toString()}
                              </p>
                            </Grid>
                          </Grid>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarYield}
                          />
                        </BsCol>
                        <BsCol class='col-md-2 col-lg-3 pl-md-4'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Rendimiento Total (Kg/ha)
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            justifyContent='space-between'
                          >
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.yieldTotal?.data,
                                    fourYearsAgo
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[0] }}
                                ></i>{' '}
                                {fourYearsAgo.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.yieldTotal?.data,
                                    threeYearsAgo
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[1] }}
                                ></i>{' '}
                                {threeYearsAgo.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.yieldTotal?.data,
                                    beforeLastYear
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[2] }}
                                ></i>{' '}
                                {beforeLastYear.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={3}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  getYearTotal(
                                    dashDataState?.yieldTotal?.data,
                                    lastYear
                                  ),
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[3] }}
                                ></i>{' '}
                                {lastYear.toString()}
                              </p>
                            </Grid>
                            {/* <Grid xs={6} lg={4}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(totalYieldState, 1) ||
                                  '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[2] }}
                                ></i>{' '}
                                {currentYear.toString()}
                              </p>
                            </Grid> */}
                          </Grid>
                          <HighchartsReact
                            highcharts={Highcharts}
                            ref={chartTotal2Ref}
                            options={chartOptionsYieldTotal}
                          />
                        </BsCol>
                      </BsRow>
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{ borderColor: theme.palette.grey[200] }}
                  >
                    <CardContent>
                      <Typography
                        variant='subtitle1'
                        gutterBottom
                        className={classes.subtitleDash}
                      >
                        Kg totales por Variedad
                      </Typography>
                      {/* <p>Resumen.</p> */}
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsHighChartsBarProductionByVarieties}
                      />
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{ borderColor: theme.palette.grey[200] }}
                  >
                    <CardContent>
                      <Typography
                        variant='subtitle1'
                        gutterBottom
                        className={classes.subtitleDash}
                      >
                        Kg por Hectárea por Variedad
                      </Typography>
                      {/* <p>Resumen.</p> */}
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsHighChartsBarYieldByVarieties}
                      />
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <BsRow class=''>
                    <BsCol class='col-md-6 col-lg-7 pr-md-3'>
                      <Card
                        elevation={0}
                        variant='outlined'
                        sx={{ borderColor: theme.palette.grey[200] }}
                      >
                        <CardContent>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={clsx(classes.subtitleDash, 'mb-4')}
                          >
                            Participación
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarParticipation}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>
                    <BsCol class='col-md-6 col-lg-5 pl-md-3'>
                      <Card
                        elevation={0}
                        variant='outlined'
                        sx={{ borderColor: theme.palette.grey[200] }}
                      >
                        <CardContent>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={clsx(classes.subtitleDash, 'mb-4')}
                          >
                            Promedios de Calibre
                          </Typography>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarCalibers}
                          />
                        </CardContent>
                      </Card>
                    </BsCol>
                  </BsRow>
                </BsCol>
              </BsRow>
            </>
          ) : (
            <>
              <BsRow class='no-gutters mb-3'>
                {noDataState ? (
                  <BsCol class='col-md-12'>
                    <BsRow class='gutter_10'>
                      <BsCol class='col-md-12'>
                        <Card elevation={0} className={classes.dashCard}>
                          <CardContent>
                            <Typography
                              variant='subtitle1'
                              gutterBottom
                              // className={classes.subtitleDash}
                            >
                              Huerto sin datos.
                            </Typography>
                          </CardContent>
                        </Card>
                      </BsCol>
                    </BsRow>
                  </BsCol>
                ) : null}
              </BsRow>
            </>
          )
        ) : (
          <LoadingWidget />
        )}
      </BsContainer>
    </div>
  );
};
export default YieldDashboard;
