// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import {
  Box,
  useTheme,
  CardContent,
  Typography,
  Divider,
  SvgIcon,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

// Internal modules
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { FiltersContext } from '../../../context';

// Components
import {
  CardPaper,
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from '../../../components';

// Functions, constants, etc.
import {
  alterArrayGetVariety,
  dateFormatIso,
  generalOptionsHighCharts,
  numberFormatDecimals,
} from '../../../helpers';
import { BsCol, BsRow } from '../../../layouts/components';
import iconJson from '../../../common/iconSVG.json';

// Styles

const useStyles = makeStyles((theme) => ({
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
    },
  },
  tableDataStyle: {
    marginBottom: 0,
    '& td': {
      color: 'white',
      borderTop: 'none',
      '&:nth-child(1)': {
        textAlign: 'center',
      },
    },
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
    width: '90%',
    borderTop: 2,
  },
}));

const yearsArray = Array.from(
  { length: 4 },
  (_, i) => new Date().getFullYear() - i
)
  .map((year) => year.toString())
  .reverse();

const WeatherOrchard = ({ orchardSelected, quarterSelected }) => {
  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const theme = useTheme();

  const quarterRef = useRef(quarterSelected);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const [tableDataState, setTableDataState] = useState(null);

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    quarter_filter,
    specie_filter,
    isLoading,
  } = filterState;

  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const [loadingState, setLoadingState] = useState(false);

  const [isBusyState, setIsBusyState] = useState(false);

  async function queryData() {
    setIsBusyState(true);
    setLoadingState(true);

    const resultOrchard = userOrchard_filter.find(
      (ele) => ele.id === orchardSelected
    );

    try {
      const objQuery = {
        orchard: resultOrchard.value,
        specieValue: specie_filter,
      };

      const response = await axiosContext.authAxios.post(
        'v1/weather/weather-agroclimatic-dashboard-by-orchard',
        objQuery
      );

      // // let data = true;

      const { data, status } = response;

      console.log(data, '<----data en orchards parameters');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setTableDataState(data.weatherBoxes);
      }
    } catch (err) {
      setTableDataState(null);
      errorHandler(err);
    } finally {
      setIsBusyState(false);
      setLoadingState(false);
    }

    // setTableDataState(DATAMOCK);
    // setLoadingState(false);
  }

  useEffect(() => {
    // if (quarterSelected && orchardSelected) {
    //   if (quarterSelected !== quarterRef.current) {
    //     quarterRef.current = quarterSelected;
    //     console.log('ejecuta query');
    //     queryData();
    //   }
    // }
    if (orchardSelected) {
      if (!isBusyState) {
        queryData();
      }
    }
  }, [quarterSelected]);

  return (
    <CardPaper
      elevation={0}
      sx={{
        borderTop: 4,
        borderTopColor: theme.palette.secondary.main,
        borderRadius: 0,
      }}
    >
      <CardContent>
        <Typography variant='h5' gutterBottom>
          Clima
        </Typography>
        <Divider className='mt-3' />
        {quarterSelected ? (
          <>
            {loadingState ? (
              <BsRow>
                <BsCol class='col-md-6'>
                  <Skeleton variant='rectangular' height={140} />
                </BsCol>
                <BsCol class='col-md-6 mt-3 mt-md-0'>
                  <Skeleton variant='rectangular' height={140} />
                </BsCol>
                <BsCol class='col-md-6 mt-3'>
                  <Skeleton variant='rectangular' height={140} />
                </BsCol>
                <BsCol class='col-md-6 mt-3'>
                  <Skeleton variant='rectangular' height={140} />
                </BsCol>
              </BsRow>
            ) : (
              <BsRow>
                <BsCol class='col-md-6'>
                  <CardPaper
                    elevation={0}
                    topColor=''
                    borderTop={0}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%',
                      backgroundColor: theme.palette.boxes.dark,
                      padding: theme.spacing(2),
                    }}
                  >
                    <SvgIcon
                      sx={{
                        width: 24,
                        height: 24,
                        mb: 1,
                      }}
                      viewBox='0 0 24 24'
                      color='#FFFFFF'
                      htmlColor='#FFFFFF'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d={iconJson.icon.coldHour}
                      />
                    </SvgIcon>
                    <Typography
                      variant='h5'
                      sx={{ fontWeight: 600, color: 'white' }}
                    >
                      Horas Frío
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{ fontSize: 12, color: 'white' }}
                    >
                      Comparativo de fecha actual
                    </Typography>
                    <Typography
                      variant='h4'
                      sx={{ fontWeight: 700, color: 'white' }}
                    >
                      {tableDataState &&
                      tableDataState.coldHour.find(
                        (item) =>
                          item.year === new Date().getFullYear().toString()
                      )?.value
                        ? numberFormatDecimals(
                            tableDataState.coldHour.find(
                              (item) =>
                                item.year ===
                                new Date().getFullYear().toString()
                            )?.value,
                            2
                          )
                        : '-'}
                    </Typography>
                    <Divider className={classes.divider} />
                    <table
                      className={clsx(
                        'table table-sm mt-2',
                        classes.tableDataStyle
                      )}
                    >
                      <tbody>
                        <tr>
                          {tableDataState &&
                            tableDataState.coldHour
                              .filter(
                                (item) =>
                                  item.year !==
                                  new Date().getFullYear().toString()
                              )
                              .map((item) => (
                                <>
                                  <td className='text-center' key={item.year}>
                                    <div>{item.year}</div>

                                    <div className='font-weight-bold'>
                                      {numberFormatDecimals(item.value, 2)}
                                    </div>
                                  </td>
                                </>
                              ))}
                        </tr>
                      </tbody>
                    </table>
                  </CardPaper>
                </BsCol>
                <BsCol class='col-md-6 mt-3 mt-md-0'>
                  <CardPaper
                    elevation={0}
                    topColor=''
                    borderTop={0}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%',
                      backgroundColor: theme.palette.boxes.light,
                      padding: theme.spacing(2),
                    }}
                  >
                    <SvgIcon
                      sx={{
                        width: 24,
                        height: 24,
                        mb: 1,
                      }}
                      viewBox='0 0 24 24'
                      color='#FFFFFF'
                      htmlColor='#FFFFFF'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d={iconJson.icon.coldPortion}
                      />
                    </SvgIcon>
                    <Typography
                      variant='h5'
                      sx={{ fontWeight: 600, color: 'white' }}
                    >
                      Porciones Frío
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{ fontSize: 12, color: 'white' }}
                    >
                      Comparativo de fecha actual
                    </Typography>
                    <Typography
                      variant='h4'
                      sx={{ fontWeight: 700, color: 'white' }}
                    >
                      {tableDataState &&
                      tableDataState.coldPortion.find(
                        (item) =>
                          item.year === new Date().getFullYear().toString()
                      )?.value
                        ? numberFormatDecimals(
                            tableDataState.coldPortion.find(
                              (item) =>
                                item.year ===
                                new Date().getFullYear().toString()
                            )?.value,
                            2
                          )
                        : '-'}
                    </Typography>
                    <Divider className={classes.divider} />
                    <table
                      className={clsx(
                        'table table-sm mt-2',
                        classes.tableDataStyle
                      )}
                    >
                      <tbody>
                        <tr>
                          {tableDataState &&
                            tableDataState.coldPortion
                              .filter(
                                (item) =>
                                  item.year !==
                                  new Date().getFullYear().toString()
                              )
                              .map((item) => (
                                <>
                                  <td className='text-center' key={item.year}>
                                    <div>{item.year}</div>

                                    <div className='font-weight-bold'>
                                      {numberFormatDecimals(item.value, 2)}
                                    </div>
                                  </td>
                                </>
                              ))}
                        </tr>
                      </tbody>
                    </table>
                  </CardPaper>
                </BsCol>
                <BsCol class='col-md-6 mt-3'>
                  <CardPaper
                    elevation={0}
                    topColor=''
                    borderTop={0}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%',
                      backgroundColor: theme.palette.boxes.main,
                      padding: theme.spacing(2),
                    }}
                  >
                    <SvgIcon
                      sx={{
                        width: 24,
                        height: 24,
                        mb: 1,
                      }}
                      viewBox='0 0 24 24'
                      color='#FFFFFF'
                      htmlColor='#FFFFFF'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d={iconJson.icon.degreeDay}
                      />
                    </SvgIcon>
                    <Typography
                      variant='h5'
                      sx={{ fontWeight: 600, color: 'white' }}
                    >
                      Grados Día
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{ fontSize: 12, color: 'white' }}
                    >
                      Comparativo de fecha actual
                    </Typography>
                    <Typography
                      variant='h4'
                      sx={{ fontWeight: 700, color: 'white' }}
                    >
                      {tableDataState &&
                      tableDataState.degreeDays.find(
                        (item) =>
                          item.year === new Date().getFullYear().toString()
                      )?.value
                        ? numberFormatDecimals(
                            tableDataState.degreeDays.find(
                              (item) =>
                                item.year ===
                                new Date().getFullYear().toString()
                            )?.value,
                            2
                          )
                        : '-'}
                    </Typography>
                    <Divider className={classes.divider} />
                    <table
                      className={clsx(
                        'table table-sm mt-2',
                        classes.tableDataStyle
                      )}
                    >
                      <tbody>
                        <tr>
                          {tableDataState &&
                            tableDataState.degreeDays
                              .filter(
                                (item) =>
                                  item.year !==
                                  new Date().getFullYear().toString()
                              )
                              .map((item) => (
                                <>
                                  <td className='text-center' key={item.year}>
                                    <div>{item.year}</div>

                                    <div className='font-weight-bold'>
                                      {numberFormatDecimals(item.value, 2)}
                                    </div>
                                  </td>
                                </>
                              ))}
                        </tr>
                      </tbody>
                    </table>
                  </CardPaper>
                </BsCol>
                <BsCol class='col-md-6 mt-3'>
                  <CardPaper
                    elevation={0}
                    topColor=''
                    borderTop={0}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%',
                      backgroundColor: theme.palette.boxes.lighter,
                      padding: theme.spacing(2),
                    }}
                  >
                    <SvgIcon
                      sx={{
                        width: 24,
                        height: 24,
                        mb: 1,
                      }}
                      viewBox='0 0 30 30'
                      color='#FFFFFF'
                      htmlColor='#FFFFFF'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d={iconJson.icon.precipitation}
                      />
                    </SvgIcon>
                    <Typography
                      variant='h5'
                      sx={{ fontWeight: 600, color: 'white' }}
                    >
                      Precipitación
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{ fontSize: 12, color: 'white' }}
                    >
                      Comparativo de fecha actual
                    </Typography>
                    <Typography
                      variant='h4'
                      sx={{ fontWeight: 700, color: 'white' }}
                    >
                      {tableDataState &&
                      tableDataState.precipitation.find(
                        (item) =>
                          item.year === new Date().getFullYear().toString()
                      )?.value
                        ? numberFormatDecimals(
                            tableDataState.precipitation.find(
                              (item) =>
                                item.year ===
                                new Date().getFullYear().toString()
                            )?.value,
                            2
                          )
                        : '-'}
                    </Typography>
                    <Divider className={classes.divider} />
                    <table
                      className={clsx(
                        'table table-sm mt-2',
                        classes.tableDataStyle
                      )}
                    >
                      <tbody>
                        <tr>
                          {tableDataState &&
                            tableDataState.precipitation
                              .filter(
                                (item) =>
                                  item.year !==
                                  new Date().getFullYear().toString()
                              )
                              .map((item) => (
                                <>
                                  <td className='text-center' key={item.year}>
                                    <div>{item.year}</div>

                                    <div className='font-weight-bold'>
                                      {numberFormatDecimals(item.value, 2)}
                                    </div>
                                  </td>
                                </>
                              ))}
                        </tr>
                      </tbody>
                    </table>
                  </CardPaper>
                </BsCol>
              </BsRow>
            )}
          </>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <Typography variant='h6'>Seleccione un Cuartel</Typography>
          </div>
        )}
      </CardContent>
    </CardPaper>
  );
};

export default WeatherOrchard;
