// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import {
  Box,
  useTheme,
  CardContent,
  Typography,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  Grid,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal modules
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { FiltersContext } from '../../../context';

// Components
import {
  CardPaper,
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from '../../../components';

// Functions, constants, etc.
import {
  alterArrayGetVariety,
  dateFormatIso,
  generalOptionsHighCharts,
  numberFormatDecimals,
} from '../../../helpers';
import { downloadXLSFormat } from 'helpers/exportFunctions';

// Styles

const useStyles = makeStyles((theme) => ({
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
    },
  },
}));

const yearsArray = Array.from(
  { length: 4 },
  (_, i) => new Date().getFullYear() - i
)
  .map((year) => year.toString())
  .reverse();

const HEADERS_EXPORT = ['Conteos', '2021', '2022', '2023', '2024'];

const CountOrchard = ({ orchardSelected, quarterSelected }) => {
  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  // crear un ref que mantenga el valor del cuarter
  const quarterRef = useRef(quarterSelected);

  const theme = useTheme();

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const [tableSelectedTypeState, setTableSelectedTypeState] =
    useState('prePoda');

  console.log(
    orchardSelected,
    'orchardSelected',
    quarterSelected,
    'quarterSelected en CountOrchard'
  );

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    specie_filter,
    quarter_filter,
    isLoading,
  } = filterState;

  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const [loadingState, setLoadingState] = useState(false);

  const [isBusyState, setIsBusyState] = useState(false);

  const [tableDataState, setTableDataState] = useState(null);

  async function queryData() {
    setIsBusyState(true);
    setLoadingState(true);

    const resultOrchard = userOrchard_filter.find(
      (ele) => ele.id === orchardSelected
    );

    console.log(
      resultOrchard,
      '<----resultOrchard en CountOrchard',
      quarterSelected,
      cc_filter
    );

    try {
      const params = {
        orchardValue: resultOrchard.value,
        ccValue: cc_filter,
        quarterValue: quarterSelected?.value,
        specieValue: specie_filter,
      };
      console.log(params, '<----params en orchards parameters');

      const response = await axiosContext.authAxios.get('v1/map/list-counts', {
        params,
      });

      // // let data = true;

      const { data, status } = response;

      console.log(data, '<----data en orchards parameters');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setTableDataState(data);
      }
    } catch (err) {
      setTableDataState(null);
      errorHandler(err);
    } finally {
      setIsBusyState(false);
      setLoadingState(false);
    }

    // setTableDataState(DATAMOCK);
    // setLoadingState(false);
  }

  useEffect(() => {
    if (quarterSelected && orchardSelected) {
      // if (quarterSelected !== quarterRef.current) {
      //   quarterRef.current = quarterSelected;
      //   console.log('ejecuta query');
      //   queryData();
      // }
      if (!isBusyState) {
        queryData();
      }
    }
  }, [quarterSelected]);

  const handleChangeType = (event) => {
    setTableSelectedTypeState(event.target.value);
  };

  return (
    <CardPaper
      elevation={0}
      sx={{
        borderTop: 4,
        borderTopColor: theme.palette.secondary.main,
        borderRadius: 0,
      }}
    >
      <CardContent>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h5' gutterBottom>
              Conteo
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              size='small'
              disabled={!tableDataState || loadingState}
              onClick={(e) =>
                downloadXLSFormat(
                  {
                    headers: HEADERS_EXPORT,
                    data: tableDataState,
                  },
                  'Conteo'
                )
              }
            >
              EXCEL
            </Button>
          </Grid>
        </Grid>
        <Divider className='mt-3' />
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={<Radio />}
              value='prePoda'
              checked={tableSelectedTypeState === 'prePoda'}
              onChange={handleChangeType}
              label='Pre Poda'
              componentsProps={{
                typography: { variant: 'body2' },
              }}
              sx={{
                p: 0.5,
                height: 35,
              }}
            />
            <FormControlLabel
              control={<Radio />}
              value='postPoda'
              checked={tableSelectedTypeState === 'postPoda'}
              onChange={handleChangeType}
              componentsProps={{
                typography: { variant: 'body2' },
              }}
              label='Post Poda'
              sx={{
                p: 0.5,
                height: 35,
              }}
            />
            <FormControlLabel
              control={<Radio />}
              value='preRaleo'
              checked={tableSelectedTypeState === 'preRaleo'}
              onChange={handleChangeType}
              label='Pre Raleo'
              componentsProps={{
                typography: { variant: 'body2' },
              }}
              sx={{
                p: 0.5,
                height: 35,
              }}
            />
            <FormControlLabel
              control={<Radio />}
              value='postRaleo'
              checked={tableSelectedTypeState === 'postRaleo'}
              onChange={handleChangeType}
              label='Post Raleo'
              componentsProps={{
                typography: { variant: 'body2' },
              }}
              sx={{
                p: 0.5,
                height: 35,
              }}
            />
          </FormGroup>
        </Box>
        {quarterSelected ? (
          <div className='table-responsive mt-4'>
            {!loadingState ? (
              <table className='table'>
                <thead className={classes.theadLight}>
                  <tr>
                    <th></th>
                    {yearsArray.map((year) => (
                      <th scope='col' key={year} className='text-center'>
                        {year}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableSelectedTypeState === 'prePoda' ? (
                    <>
                      <tr>
                        <td>Dardos Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {numberFormatDecimals(
                                tableDataState?.dartPrePoda.find(
                                  (ele) => ele.year === year
                                )?.value,
                                0
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>Ramillas Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {numberFormatDecimals(
                                tableDataState?.twigPrePoda.find(
                                  (ele) => ele.year === year
                                )?.value,
                                0
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  ) : null}
                  {tableSelectedTypeState === 'postPoda' ? (
                    <>
                      <tr>
                        <td>Dardos Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {numberFormatDecimals(
                                tableDataState?.dartPostPoda.find(
                                  (ele) => ele.year === year
                                )?.value,
                                0
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>Ramillas Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {numberFormatDecimals(
                                tableDataState?.twigPostPoda.find(
                                  (ele) => ele.year === year
                                )?.value,
                                0
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  ) : null}
                  {tableSelectedTypeState === 'preRaleo' ? (
                    <>
                      <tr>
                        <td>Frutos por Planta Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {numberFormatDecimals(
                                tableDataState?.fruitPreRaleo.find(
                                  (ele) => ele.year === year
                                )?.value,
                                0
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>Frutos/Dardo Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {tableDataState?.fruitsDartPreRaleo
                                ? numberFormatDecimals(
                                    tableDataState.fruitsDartPreRaleo.find(
                                      (ele) => ele.year === year
                                    )?.value,
                                    1
                                  )
                                : '-'}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  ) : null}
                  {tableSelectedTypeState === 'postRaleo' ? (
                    <>
                      <tr>
                        <td>Frutos por Planta Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {numberFormatDecimals(
                                tableDataState?.fruitPostRaleo.find(
                                  (ele) => ele.year === year
                                )?.value,
                                0
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>Frutos/Dardo Promedio</td>
                        {yearsArray.map((year) => {
                          return (
                            <td key={year} className='text-center'>
                              {tableDataState?.fruitsDartPostRaleo
                                ? numberFormatDecimals(
                                    tableDataState.fruitsDartPostRaleo.find(
                                      (ele) => ele.year === year
                                    )?.value,
                                    1
                                  )
                                : '-'}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
            ) : (
              <LoadingWidget />
            )}
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <Typography variant='h6'>Seleccione un Cuartel</Typography>
          </div>
        )}
      </CardContent>
    </CardPaper>
  );
};

export default CountOrchard;
