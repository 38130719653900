import { Skeleton, SvgIcon, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import {
  dashDelete,
  numberFormat,
  numberFormatDecimals,
} from '../../../helpers';
import { BsCol, BsRow } from '../../../layouts/components';
import { CardPaper, SimpleIconButton } from '../../../components';
import iconJson from '../../../common/iconSVG.json';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  infoTableStyle: {
    '& td': {
      fontSize: 14,
    },
    '& .bold': {
      fontWeight: '600',
    },
  },
}));

const InfoRowBoxes = ({
  infoIntelligence,
  addFilter,
  scenarySaved,
  loadingData,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const [accumulatedPortionsState, setAccumulatedPortionsState] = useState({});

  const dartBasal =
    infoIntelligence?.information?.dartPrePoda > 0
      ? infoIntelligence?.information?.dartPrePoda <
        infoIntelligence?.information?.dartPostPoda
        ? infoIntelligence?.information?.dartPostPoda
        : infoIntelligence?.information?.dartPrePoda
      : infoIntelligence?.information?.dartPostPoda;

  const twigBasal =
    infoIntelligence?.information?.twigPrePoda > 0
      ? infoIntelligence?.information?.twigPrePoda <
        infoIntelligence?.information?.twigPostPoda
        ? infoIntelligence?.information?.twigPostPoda
        : infoIntelligence?.information?.twigPrePoda
      : infoIntelligence?.information?.twigPostPoda;

  useEffect(() => {
    if (infoIntelligence) {
      // console.log(infoIntelligence, '------adentro---infoIntelligence');
      // TODO: adaptar para más años

      // const mayPrev =
      //   infoIntelligence.pfValueAprilPreviousSeason +
      //   infoIntelligence.pfValueMayPreviousSeason;

      const mayPrev = infoIntelligence.pfValueMayPreviousSeason;

      // const mayActual =
      //   infoIntelligence.pfValueApril + infoIntelligence.pfValueMay;

      const mayActual = infoIntelligence.pfValueMay;

      // const junePrev = mayPrev + infoIntelligence.pfValueJunePreviousSeason;
      const junePrev = infoIntelligence.pfValueJunePreviousSeason;

      // const juneActual = mayActual + infoIntelligence.pfValueJune;
      const juneActual = infoIntelligence.pfValueJune;

      // const julyPrev = junePrev + infoIntelligence.pfValueJulyPreviousSeason;
      const julyPrev = infoIntelligence.pfValueJulyPreviousSeason;

      // const julyActual = juneActual + infoIntelligence.pfValueJuly;
      const julyActual = infoIntelligence.pfValueJuly;

      setAccumulatedPortionsState({
        mayActual,
        mayPrev,
        junePrev,
        juneActual,
        julyPrev,
        julyActual,
      });
    }
  }, [infoIntelligence]);

  if (loadingData) {
    return (
      <BsRow class='mt-2'>
        <BsCol class='col-md-6 col-lg-3 pr-lg-1'>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton variant='rounded' width={'100%'} height={120} />
        </BsCol>
        <BsCol class='col-md-6 col-lg-3 pr-lg-1'>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton variant='rounded' width={'100%'} height={120} />
        </BsCol>
        <BsCol class='col-md-6 col-lg-3 pr-lg-1'>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton variant='rounded' width={'100%'} height={120} />
        </BsCol>
        <BsCol class='col-md-6 col-lg-3 pr-lg-1'>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton variant='rounded' width={'100%'} height={120} />
        </BsCol>
      </BsRow>
    );
  }

  return (
    <BsRow class='mt-2'>
      <BsCol class='col-md-6 col-lg-4 pr-lg-1'>
        <CardPaper
          elevation={0}
          variant='outlined'
          sx={{
            padding: 1,
            height: '100%',
            borderColor: theme.palette.grey[300],
          }}
        >
          <table
            className={clsx(
              'table table-borderless table-sm mb-0',
              classes.infoTableStyle
            )}
          >
            <tbody>
              <tr>
                <td style={{ width: '60%' }} className='bold'>
                  Huerto
                </td>
                <td>
                  {infoIntelligence
                    ? dashDelete(infoIntelligence.information?.orchard)
                    : '-'}
                </td>
              </tr>
              <tr>
                <td style={{ width: '60%' }} className='bold'>
                  CC
                </td>
                <td>
                  {infoIntelligence ? infoIntelligence.information?.cc : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Cuartel</td>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.quarter
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Variedad</td>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.variety
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Portainjerto</td>
                <td>
                  {infoIntelligence
                    ? dashDelete(infoIntelligence.information?.rootStock)
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Superficie</td>
                <td>
                  {infoIntelligence
                    ? infoIntelligence.information?.surface
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Plantas/Ha</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.plantsHa,
                        0
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Edad</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(infoIntelligence.information?.age, 2)
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </CardPaper>
      </BsCol>
      <BsCol class='col-md-6 col-lg-4 px-lg-1 mt-2 mt-md-0'>
        <CardPaper
          elevation={0}
          variant='outlined'
          sx={{
            padding: 1,
            height: '100%',
            borderColor: theme.palette.grey[300],
          }}
        >
          <table
            className={clsx(
              'table table-borderless table-sm mb-0',
              classes.infoTableStyle
            )}
          >
            <tbody>
              <tr>
                <td style={{ width: '60%' }} className='bold'>
                  Dardos
                </td>
                <td>
                  {infoIntelligence ? numberFormatDecimals(dartBasal, 2) : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Ramillas</td>
                <td>
                  {infoIntelligence ? numberFormatDecimals(twigBasal, 2) : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Yemas/Dardo</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.budDart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Flores/Yemas/Dardo</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersBudDart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Flores/Dardo</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersDart,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Flores/Ramilla</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersTwig,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Flores/Ha Pre Poda</td>
                <td>
                  {infoIntelligence && infoIntelligence.information
                    ? numberFormatDecimals(
                        infoIntelligence.information?.flowersHaPrePoda,
                        0
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td style={{ width: '60%' }} className='bold'>
                  Arginina
                </td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.arginine,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Almidón</td>
                <td>
                  {' '}
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.starch,
                        2
                      )
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </CardPaper>
      </BsCol>
      <BsCol class='col-md-6 col-lg-4 px-lg-1 mt-2 mt-lg-0'>
        <CardPaper
          elevation={0}
          variant='outlined'
          sx={{
            padding: 1,
            height: '100%',
            borderColor: theme.palette.grey[300],
          }}
        >
          <table
            className={clsx(
              'table table-borderless table-sm mb-0',
              classes.infoTableStyle
            )}
          >
            <thead>
              <tr>
                <th>Porciones Frío</th>
                {/* <th>{new Date().getFullYear() - 2}</th> */}
                <th>{new Date().getFullYear() - 1}</th>
                <th>{new Date().getFullYear()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='bold'>Abril</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information
                          ?.pfValueAprilPreviousSeason,
                        2
                      )
                    : '-'}
                </td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfValueApril,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Mayo</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(accumulatedPortionsState?.mayPrev, 2)
                    : '-'}
                </td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        accumulatedPortionsState?.mayActual,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Junio</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        accumulatedPortionsState?.junePrev,
                        2
                      )
                    : '-'}
                </td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        accumulatedPortionsState?.juneActual,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>Julio</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        accumulatedPortionsState?.julyPrev,
                        2
                      )
                    : '-'}
                </td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        accumulatedPortionsState?.julyActual,
                        2
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='bold'>A la fecha</td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfToDatePreviousSeason,
                        2
                      )
                    : '-'}
                </td>
                <td>
                  {infoIntelligence
                    ? numberFormatDecimals(
                        infoIntelligence.information?.pfToDate,
                        2
                      )
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </CardPaper>
      </BsCol>
    </BsRow>
  );
};

export default InfoRowBoxes;
