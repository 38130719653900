import * as XLSX from 'xlsx';

export function transformYearsData(headers, data) {
  const years = headers.slice(1); // Obtenemos los años de los headers
  const transformedData = [];

  for (const key in data) {
    const row = [key];
    years.forEach((year) => {
      const found = data[key].find((item) => item.year === year);
      row.push(found ? found.value : 0);
    });
    transformedData.push(row);
  }

  return transformedData;
}

export function downloadXLSFormat({ headers, data }, moduleName) {
  // Transformar los datos para que coincidan con las columnas
  const formattedData = transformYearsData(headers, data);

  // Crear una hoja de cálculo con los datos formateados
  const ws = XLSX.utils.aoa_to_sheet([headers, ...formattedData]);
  const wb = XLSX.utils.book_new();

  // Añadir la hoja al libro y escribir el archivo
  XLSX.utils.book_append_sheet(wb, ws, moduleName);
  XLSX.writeFile(wb, `${moduleName}.xlsx`);
}

// CLIMA DETALLE
export const monthNames = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];

export function transformDataWeather(inputData) {
  const transformedData = inputData.flatMap((orchard) =>
    orchard.data.flatMap((yearData) =>
      yearData.months
        .sort((a, b) => a.month - b.month)
        .map((monthData) => ({
          orchardValue: orchard.orchardValue,
          year: yearData.year,
          month: monthNames[monthData.month - 1],
          value: monthData.value,
        }))
    )
  );

  return transformedData;
}

// Función para descargar XLS
export const exportToExcelWeatherMulti = (
  data1,
  data2,
  data3,
  headers,
  moduleName = 'reporte'
) => {
  // Crea una nueva hoja de trabajo
  const worksheet1 = XLSX.utils.json_to_sheet(data1, {
    header: Object.keys(headers),
  });

  // Renombra los headers
  const headerRow1 = Object.keys(headers).map((key) => headers[key]);
  XLSX.utils.sheet_add_aoa(worksheet1, [headerRow1], { origin: 'A1' });

  const worksheet2 = XLSX.utils.json_to_sheet(data2, {
    header: Object.keys(headers),
  });

  // Renombra los headers
  const headerRow2 = Object.keys(headers).map((key) => headers[key]);
  XLSX.utils.sheet_add_aoa(worksheet2, [headerRow2], { origin: 'A1' });

  const worksheet3 = XLSX.utils.json_to_sheet(data3, {
    header: Object.keys(headers),
  });

  // Renombra los headers
  const headerRow3 = Object.keys(headers).map((key) => headers[key]);
  XLSX.utils.sheet_add_aoa(worksheet3, [headerRow3], { origin: 'A1' });

  // Crea un nuevo libro de trabajo
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet1, 'HorasFrio');
  XLSX.utils.book_append_sheet(workbook, worksheet2, 'PorcionesFrio');
  XLSX.utils.book_append_sheet(workbook, worksheet3, 'GradoDia');

  // Genera el archivo Excel
  XLSX.writeFile(workbook, `${moduleName}.xlsx`);
};

// CLIMA RESUMEN
// Función para descargar XLS
export const exportToExcelWeatherResume = (
  data,
  headers,
  moduleName = 'reporte'
) => {
  // Crea una nueva hoja de trabajo
  const worksheet = XLSX.utils.json_to_sheet(data, {
    header: Object.keys(headers),
  });

  // Renombra los headers
  const headerRow = Object.keys(headers).map((key) => headers[key]);
  XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: 'A1' });

  // Crea un nuevo libro de trabajo
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Genera el archivo Excel
  XLSX.writeFile(workbook, `${moduleName}.xlsx`);
};
