import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Typography, useTheme } from '@mui/material';
import {
  alterArrayDashDelete,
  alterArrayForGanttData,
  numberFormat,
} from '../../../helpers';
import { BsCol, BsRow } from 'layouts/components';

const GanttComponent = ({
  title,
  dataChart,
  firstDate,
  lastDate,
  editFunction,
}) => {
  const theme = useTheme();
  // const [categoriesState, setCategoriesState] = useState(dataChart.variety);
  // const [dataState, setDataState] = useState(
  // 	alterArrayForGanttData(dataChart.data)
  // );
  const [optionChartState, setOptionChartState] = useState(null);

  // console.log(dataChart, 'en gantt component');

  useEffect(() => {
    const optionChart = {
      title: {
        text: '',
        style: { display: 'none' },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      // colors: ['red', 'black', 'tomato'],
      tooltip: {
        outside: true,
        xDateFormat: '%Y-%m-%d',
        formatter: function () {
          if (this.point.beginDate) {
            return (
              '<b>' +
              this.yCategory +
              '</b><br/>' +
              'Predicción: ' +
              numberFormat(this.point.t) +
              ' t. <br/>' +
              'desde: ' +
              Highcharts.dateFormat('%d/%m', this.x) +
              '<br/>hasta: ' +
              Highcharts.dateFormat('%d/%m', this.x2)
            );
          }
          // console.log(this.point, '----tooltip');
          return (
            '<b>' +
            this.yCategory +
            '</b><br/>' +
            'Predicción: ' +
            numberFormat(this.point.t) +
            ' t. <br/>'
          );
        },
      },
      chart: {
        type: 'xrange',
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 5,
        height: dataChart.variety.length * 60,
      },
      accessibility: {
        point: {
          descriptionFormatter: function (point) {
            var ix = point.index + 1,
              category = point.yCategory,
              from = new Date(point.x),
              to = new Date(point.x2);
            return (
              ix +
              '. ' +
              category +
              ', ' +
              from.toDateString() +
              ' to ' +
              to.toDateString() +
              '.'
            );
          },
        },
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 24 * 3600 * 1000 * 5,
        min: Date.parse(firstDate),
        max: Date.parse(lastDate),
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%d/%m', this.value);
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'left',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
      yAxis: {
        title: {
          text: '',
        },
        categories: alterArrayDashDelete(dataChart.variety),
        labels: {
          useHTML: true,
          style: {
            textAlign: 'left',
          },
        },
        reversed: true,
        alternateGridColor: theme.palette.grey[50],
      },

      series: [
        {
          name: title,
          borderColor: 'gray',
          pointWidth: 25,
          data: alterArrayForGanttData(dataChart.data, firstDate, lastDate),
          dataLabels: {
            enabled: true,
          },
        },
      ],
    };

    // console.log(optionChart, "---------------");

    setOptionChartState(optionChart);
  }, [dataChart, firstDate, lastDate]);

  return (
    <>
      <BsRow>
        <BsCol class='col-md-6'>
          <Typography variant='h5' gutterBottom>
            {title}
          </Typography>
        </BsCol>
        <BsCol class='col-md-6 text-right'>
          <Button
            variant='outlined'
            startIcon={<EditIcon />}
            onClick={editFunction}
          >
            Editar
          </Button>
        </BsCol>
      </BsRow>
      {optionChartState && (
        <HighchartsReact
          highcharts={Highcharts}
          options={optionChartState}
          constructorType={'chart'}
        />
      )}
    </>
  );
};

export default GanttComponent;
