import { useContext, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { makeStyles, styled } from '@mui/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Card,
  CardContent,
  Typography,
  useTheme,
  Grid,
  Chip,
  IconButton,
  Divider,
  Paper,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import {
  alterArrayForSelectVariety,
  arrayGetStationIds,
  dashDelete,
  dateFormatIso,
  numberFormat,
  numberFormatDecimals,
} from '../../../helpers';
import CalIcon from '../../../assets/icons/dashboards/cal_icon.png';
import CountersIcon from '../../../assets/icons/dashboards/conters_icon.png';
import PlantsIcon from '../../../assets/icons/dashboards/plants_icon.png';
import CountedPlantsIcon from '../../../assets/icons/dashboards/counted_plants_icon.png';
import PlantsGreenIcon from '../../../assets/icons/dashboards/plants_green_icon.png';
import ProgressCountIcon from '../../../assets/icons/dashboards/progress_count_icon.png';
import YieldAdvisorIcon from '../../../assets/icons/dashboards/yield_advisor_icon.png';
import YieldObjectiveIcon from '../../../assets/icons/dashboards/yield_objective_icon.png';
import DifferenceIcon from '../../../assets/icons/dashboards/difference_icon.png';
import { AxiosContext } from '../../../context/AxiosContext';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { FiltersContext } from '../../../context';
import { CardPaper, DeviationChip, LoadingWidget } from '../../../components';

const COLORS = [
  '#A80943',
  '#4EAA00',
  '#1A2831',
  '#1295D4',
  '#B86D16',
  '#FF698C',
];

const KPI_SIMBOLS = ['', '%', '%', '', '', '%'];

const ICONS_COUNTING_WORKDAY = [CalIcon, PlantsIcon, CountersIcon];
const ICONS_COUNTING_DAILY = [CalIcon, CountersIcon, PlantsIcon];
const ICONS_PERFORMANCE_INDICATORS = [
  PlantsGreenIcon,
  CountedPlantsIcon,
  ProgressCountIcon,
  YieldAdvisorIcon,
  YieldObjectiveIcon,
  DifferenceIcon,
];

const COLORS_KPI_TOPS = [
  '#4EAA00',
  '#4EAA00',
  '#034EBE',
  '#1A2831',
  '#1A2831',
  '#1A2831',
];

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  subtitleDash: {
    fontSize: 17 + '!important',
    fontWeight: 700 + '!important',
  },
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
  },
  dividerHeight: {
    minHeight: '70px',
  },
  kpiBoxStyle: {
    minHeight: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  kpiInnerBoxStyle: {
    minHeight: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      textAlign: 'center',
    },
  },
  resumeTableStyle: {
    '& thead': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
    },
    '& th': {
      textAlign: 'center',
    },
    '& td': {
      textAlign: 'center',
    },
  },
  totalTableStyle: {
    '& thead': {},
    '& th': {
      textAlign: 'center',
      borderBottom: 0 + '!important',
      borderTop: '2px solid #dee2e6 !important',
    },
    '& td': {
      textAlign: 'center',
    },
  },
  tableBottomStyle: {
    '& th:not(:first-child)': {
      textAlign: 'center',
    },
  },
  dividerBoxStyle: {
    width: '100%',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#034EBE !important',
    borderBottomWidth: 'thin !important',
  },
  positiveStyle: {
    color: '#70ce43 !important',
  },
  negativeStyle: {
    color: '#cd562d !important',
  },
  midStyle: {
    color: '#e6a832 !important',
  },
}));

Highcharts.setOptions({
  lang: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    decimalPoint: ',',
  },
});

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1380,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const PostPoda = ({ filter: filterProp }) => {
  const errorHandler = useErrorHandler();

  const axiosContext = useContext(AxiosContext);

  const { addFilter } = useContext(FiltersContext);

  const classes = useStyles();

  const history = useHistory();

  const { userOrchard } = useContext(Context);

  const tableRef = useRef(null);

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);
  const [dashDataState, setDashDataState] = useState(null);
  const [orchardSelectedState, setOrchardSelectedState] = useState(null);

  const getVarietySurface = (array) => {
    const newArray = array.map((item) => {
      return {
        name: item.variety,
        y: item.value,
      };
    });
    return newArray;
  };

  async function queryData() {
    setLoadingDashDataState(true);
    try {
      const { orchard } = filterProp;

      // const myOrchards = userOrchard.length
      //   ? userOrchard.join(',')
      //   : 'Almahue, Bodega';

      setOrchardSelectedState(orchard);

      // const dataParams = {
      //   params: { orchardValue: orchard, orchardValues: myOrchards },
      // };

      const objQuery = {
        orchardValue: orchard,
        orchardValues: userOrchard,
        // orchardValues: ['Almahue', 'Bodega'],
      };

      // console.log(objQuery, '-----orchard');

      const response = await axiosContext.authAxios.post(
        'v1/dashboard/get-dashboard_work_summary_post_poda',
        objQuery
      );
      const { data, status } = response;

      if (data) {
        // setTableDataState(data.data);
        console.log('eeeeeeee', data);

        setDashDataState(data);
        setLoadingDashDataState(false);
        // setGraphDataState(data.data);
      }
    } catch (err) {
      setDashDataState([]);
      setLoadingDashDataState(false);
      errorHandler(err);
    }
  }

  // Variedades Pie
  const optionsHighChartsVarieties = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'pie',
      height: 370,
      spacingBottom: 10,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0,
    },
    tooltip: {
      // valueSuffix: '%',
      // pointFormat: '<b>Superficie:' + numberFormatDecimals(this.y) + '</b>',
      formatter: function () {
        return '<b>Superficie:' + numberFormatDecimals(this.y, 0) + ' ha</b>';
      },
      valueDecimals: 1,
      valueThousandsSep: '.',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        showInLegend: true,
        colors: [
          '#A80943',
          '#4EAA00',
          '#1A2831',
          '#1295D4',
          '#B86D16',
          '#FF698C',
        ],
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          formatter: function () {
            // return (
            //   this.point.name +
            //   '<br/>' +
            //   numberFormatDecimals(this.percentage, 2) +
            //   ' % <br />' +
            //   numberFormat(this.point.y) +
            //   ' Há'
            // );
            return (
              this.point.name +
              '<br/>' +
              numberFormatDecimals(this.percentage, 2) +
              ' %'
            );
          },
          distance: 14,
          // color: 'white',
          style: {
            fontSize: 12,
          },
        },
      },
    },
    series: [
      {
        innerSize: '55%',
        data: dashDataState?.surfaceVarietiesChart
          ? getVarietySurface(dashDataState?.surfaceVarietiesChart)
          : [],
      },
    ],
  };

  // Rendimiento por Variedad
  const optionsHighChartsBarVarieties = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 380,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 1) +
          ' %</b><br/>' +
          this?.x
        );
      },
    },
    colors: ['#1295D4', '#034EBE', '#1A2831'],
    plotOptions: {
      column: {
        colorByPoint: false,
        // dataLabels: {
        //   enabled: true,
        //   inside: true,
        //   color: 'white',
        //   rotation: -90,
        //   align: 'right',
        //   verticalAlign: 'top',
        //   y: 5, // Adjust vertical position
        //   style: {
        //     paddingTop: '10px', // Add padding to the top
        //     textShadow: '0 0 3px black', // Add a text shadow for better visibility
        //   },
        //   formatter: function () {
        //     return this.y + '%';
        //   },
        // },
      },
      series: {
        pointPadding: 0.06,
      },
    },
    xAxis: {
      categories: dashDataState?.yieldVarieties?.categories || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: '',
      },
    },
    legend: {
      enabled: true,
    },
    series: dashDataState?.yieldVarieties?.data || [],
  };

  // avance conteo variedad
  const optionsHighChartsBarCountingVariety = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 380,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' + numberFormatDecimals(this.y, 1) + ' %</b><br/>' + this?.x
        );
      },
    },
    plotOptions: {
      column: {
        colorByPoint: true,
        colors: COLORS,
      },
      series: {
        pointPadding: 0.06,
      },
    },
    xAxis: {
      categories: dashDataState?.countingVarietiesChart?.categories || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Avance de Conteos',
      },
    },
    // Opción para ocultar las leyendas
    legend: {
      enabled: false,
    },
    // colors: ['#1A2831', '#034EBE', '#1295D4'],
    series: [
      {
        name: 'progress',
        data: dashDataState?.countingVarietiesChart?.data,
        showInLegend: false,
      },
    ],
  };

  useEffect(() => {
    if (filterProp) {
      // console.log(filterProp);
      queryData();
      // setDashDataState(JSON_DATA);
    }
  }, [filterProp]);

  useEffect(() => {
    if (dashDataState) {
      console.log(dashDataState, '---dashDataState');
    }
  }, [dashDataState]);

  if (loadingDashDataState) {
    return (
      <div>
        <LoadingWidget />
      </div>
    );
  }

  let totalKilosAdvisor = 0;
  let totalKilosObjective = 0;

  const redirect = (path, quarter) => {
    // console.log(infoIntelligence, '----infoIntelligence');
    // TODO: tomar de infointelligence, cc, quarter para setear filters antes de ir
    const link = path;

    console.log(quarter, '----para redirect');

    addFilter({
      orchard_filter: orchardSelectedState,
      cc_filter: quarter?.ccValue,
      quarter_filter: quarter?.quarterValue,
      variety_filter: quarter?.varietyValue,
      rootstock_filter: quarter?.rootStockValue,
    });

    // a biblioteca
    history.push({
      pathname: link,
      // state: {
      //   fromPrune: true,
      //   IntelligenceState: infoIntelligence?.savedScenario,
      // },
    });
  };

  return (
    <div>
      {dashDataState ? (
        <>
          <BsRow class='mb-5'>
            <BsCol class='col-md-6 col-lg-5'>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.subtitleDash}
              >
                Indicadores de {dashDelete(orchardSelectedState)}
              </Typography>
              <Grid container spacing={2}>
                {dashDataState.performanceIndicators.map((indicator, i) => {
                  if (indicator.id === 4) {
                    return (
                      <Grid item xs={6} md={4} key={`kpi_${i}`}>
                        <CardPaper elevation={1} topColor={COLORS_KPI_TOPS[i]}>
                          <div className={clsx(classes.kpiBoxStyle, 'px-2')}>
                            <img
                              src={ICONS_PERFORMANCE_INDICATORS[i]}
                              width='18'
                              className='img-fluid'
                              alt='dart'
                            />
                            <Typography>{indicator.name}</Typography>
                            <Typography variant='h3'>
                              {numberFormatDecimals(indicator.value, 1)}{' '}
                              {KPI_SIMBOLS[i]}
                            </Typography>
                            <Divider
                              className={clsx('my-2', classes.dividerBoxStyle)}
                            />
                            <img
                              src={ICONS_PERFORMANCE_INDICATORS[i]}
                              width='18'
                              className='img-fluid'
                              alt='dart'
                            />
                            <Typography>{indicator.name2}</Typography>
                            <Typography variant='h3'>
                              {numberFormatDecimals(indicator.value2, 1)}{' '}
                              {KPI_SIMBOLS[i]}
                            </Typography>
                          </div>
                        </CardPaper>
                      </Grid>
                    );
                  } else if (indicator.id === 7) {
                    const absoluteDeviation = Math.abs(indicator.value);
                    let classColor;
                    if (absoluteDeviation < 15) {
                      classColor = 'positiveStyle';
                    } else if (absoluteDeviation < 25) {
                      classColor = 'midStyle';
                    } else {
                      classColor = 'negativeStyle';
                    }
                    return (
                      <Grid item xs={6} md={4} key={`kpi_${i}`}>
                        <CardPaper elevation={1} topColor={COLORS_KPI_TOPS[i]}>
                          <div className={clsx(classes.kpiBoxStyle, 'px-2')}>
                            <img
                              src={ICONS_PERFORMANCE_INDICATORS[i]}
                              width='25'
                              className='img-fluid mb-2'
                              alt='dart'
                            />
                            <Typography>{indicator.name}</Typography>
                            <Typography
                              variant='h3'
                              className={classes[classColor]}
                            >
                              {numberFormatDecimals(indicator.value, 1)}{' '}
                              {KPI_SIMBOLS[i]}
                            </Typography>
                          </div>
                        </CardPaper>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={6} md={4} key={`kpi_${i}`}>
                        <CardPaper elevation={1} topColor={COLORS_KPI_TOPS[i]}>
                          <div className={clsx(classes.kpiBoxStyle, 'px-2')}>
                            <img
                              src={ICONS_PERFORMANCE_INDICATORS[i]}
                              width='25'
                              className='img-fluid mb-2'
                              alt='dart'
                            />
                            <Typography>{indicator.name}</Typography>
                            <Typography variant='h3'>
                              {numberFormatDecimals(indicator.value, 1)}{' '}
                              {KPI_SIMBOLS[i]}
                            </Typography>
                          </div>
                        </CardPaper>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </BsCol>
            <BsCol class='col-md-6 col-lg-7 mt-3 mt-md-0'>
              <CardPaper
                elevation={1}
                topColor='#E6A832'
                style={{ backgroundColor: '#F8FDFF' }}
              >
                <div className='py-3 px-3'>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    className={classes.subtitleDash}
                  >
                    Avances y Desviación de Rdto. por Huerto
                  </Typography>
                  {/* <p className='mb-1'>
                dsfd sfds fdsfs dfsdf dsfdf sdfsdfsdf sdfs fds.
              </p> */}
                  <div
                    style={{
                      // width: '100%',
                      maxHeight: '280px',
                      position: 'relative',
                      // overflowY: 'auto',
                    }}
                    className='table-responsive'
                  >
                    <table
                      className={clsx(
                        'table table-sm',
                        classes.resumeTableStyle
                      )}
                      style={{ marginBottom: 0 }}
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th className='text-left'>Huerto</th>
                          <th>Hectárea</th>
                          <th>Yema %</th>
                          <th>Conteo %</th>
                          <th>Rendimiento</th>
                          <th>Rend. Objetivo</th>
                          <th>Diferencia</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashDataState.orchardProgress.map((orchard) => {
                          totalKilosAdvisor += orchard?.kilos_adviser;
                          totalKilosObjective += orchard?.kilos_objective;
                          return (
                            <tr key={orchard.orchard}>
                              <th scope='row' className='text-left'>
                                {orchard.orchard}
                              </th>
                              <td>{numberFormatDecimals(orchard.ha, 0)}</td>
                              <td>{numberFormatDecimals(orchard.bud, 1)}</td>
                              <td>{numberFormatDecimals(orchard.count, 1)}</td>
                              <td>
                                {numberFormatDecimals(
                                  orchard?.kilos_adviser,
                                  1
                                )}
                              </td>
                              <td>
                                {numberFormatDecimals(
                                  orchard?.kilos_objective,
                                  1
                                )}
                              </td>
                              <td>
                                <DeviationChip
                                  simbol='%'
                                  value={orchard?.difference}
                                  level={orchard.level}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <table
                    className={clsx('table table-sm', classes.totalTableStyle)}
                    style={{ marginBottom: 0 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '25%' }}>Total</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ width: '18%' }}>
                          {numberFormatDecimals(totalKilosAdvisor)} kg
                        </th>
                        <th style={{ width: '18%' }}>
                          {numberFormatDecimals(totalKilosObjective)} kg
                        </th>
                        <th style={{ width: '15%' }}></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </CardPaper>
            </BsCol>
          </BsRow>
          <BsRow class='mb-5'>
            <BsCol class='col-md-6'>
              <BsRow>
                <BsCol class='col-md-12'>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    className={classes.subtitleDash}
                  >
                    Promedios por día
                  </Typography>
                </BsCol>
                {dashDataState.countingGeneralIndicators.map((indicator, i) => {
                  return (
                    <BsCol class='col-md-4' key={indicator.id.toString()}>
                      <CardPaper elevation={3} topColor='white'>
                        <div className={classes.kpiInnerBoxStyle}>
                          <img
                            src={ICONS_COUNTING_DAILY[i]}
                            width='25'
                            className='img-fluid mb-2'
                            alt='dart'
                          />
                          <Typography>{indicator.name}</Typography>
                          <Typography variant='h3'>
                            {i === 0
                              ? indicator.value
                              : numberFormatDecimals(indicator.value, 1)}
                          </Typography>
                        </div>
                      </CardPaper>
                    </BsCol>
                  );
                })}
              </BsRow>
            </BsCol>
            <BsCol class='col-md-6 mt-3 mt-md-0'>
              <BsRow>
                <BsCol class='col-md-12'>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    className={classes.subtitleDash}
                  >
                    Avances por Jornada
                  </Typography>
                </BsCol>
                {dashDataState.countingDayIndicators.map((indicator, i) => {
                  return (
                    <BsCol class='col-md-4' key={indicator.id.toString()}>
                      <CardPaper elevation={3} topColor='white'>
                        <div className={classes.kpiInnerBoxStyle}>
                          <img
                            src={ICONS_COUNTING_WORKDAY[i]}
                            width='25'
                            className='img-fluid mb-2'
                            alt='dart'
                          />
                          <Typography>{indicator.name}</Typography>
                          <Typography variant='h3'>
                            {indicator.value}
                          </Typography>
                        </div>
                      </CardPaper>
                    </BsCol>
                  );
                })}
              </BsRow>
            </BsCol>
          </BsRow>
          <BsRow class='mb-5'>
            <BsCol class='col-md-5'>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.subtitleDash}
              >
                Superficie por Variedad
              </Typography>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsHighChartsVarieties}
              />
            </BsCol>
            {/* <BsCol class='col-md-4'>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.subtitleDash}
              >
                Avance de Conteo por Variedad
              </Typography>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsHighChartsBarCountingVariety}
              />
            </BsCol> */}
            <BsCol class='col-md-7'>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.subtitleDash}
              >
                Rendimiento por Variedad
              </Typography>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsHighChartsBarVarieties}
              />
            </BsCol>
          </BsRow>
          <BsRow>
            <BsCol class='col-md-12'>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.subtitleDash}
              >
                Detalle de Cuarteles
              </Typography>
              {/* <p className='mb-1'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Corporis, nulla delectus volup
              </p> */}
              <div className='table-responsive'>
                <table className={clsx('table', classes.tableBottomStyle)}>
                  <thead>
                    <tr>
                      <th>Unidad Prod.</th>
                      <th>Total Plantas</th>
                      <th>Plantas Contadas</th>
                      <th>Rdto. Pre Poda</th>
                      <th>Rdto. Objetivo</th>
                      <th>Rdto. Post Poda</th>
                      <th>Desviación</th>
                      <th>Biblioteca</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashDataState.quarterDetail.map((quarter, i) => {
                      const absoluteDeviation = Math.abs(quarter.deviation);
                      let colorDeviation = 'success';
                      if (absoluteDeviation > 15) {
                        colorDeviation = 'warning';
                      }
                      if (absoluteDeviation > 25) {
                        colorDeviation = 'error';
                      }

                      return (
                        <tr key={`${quarter.ccValue}_${quarter.quarterValue}`}>
                          <td>
                            <Typography variant='h6'>{quarter.cc}</Typography>
                            <Typography variant='h6'>
                              {quarter.quarter}
                            </Typography>
                            <Typography>
                              {quarter.variety} / {quarter.rootStock}
                            </Typography>
                          </td>
                          <td className='text-center'>
                            {numberFormatDecimals(quarter.totalPlants, 0)}
                          </td>
                          <td className='text-center'>
                            {numberFormatDecimals(quarter.countedPlants, 1)} %
                          </td>
                          <td className='text-center'>
                            {/* <DeviationChip
                            simbol='kg/ha'
                            value={quarter.yieldPrePoda}
                            level={quarter.levelYieldPrePoda}
                          /> */}
                            {numberFormatDecimals(
                              quarter.yieldEstimatePostPoda
                            )}{' '}
                            kg/ha
                          </td>
                          <td className='text-center'>
                            {/* <DeviationChip
                            simbol='kg/ha'
                            value={quarter.yieldObjective}
                            level={quarter.levelYieldObjective}
                          /> */}
                            {numberFormatDecimals(quarter.yieldObjective)} kg/ha
                          </td>
                          <td className='text-center'>
                            {/* <DeviationChip
                            simbol='kg/ha'
                            value={quarter.yieldObjective}
                            level={quarter.levelYieldObjective}
                          /> */}
                            {numberFormatDecimals(
                              quarter.yieldEstimatePostPoda
                            )}{' '}
                            kg/ha
                          </td>
                          <td className='text-center'>
                            {numberFormatDecimals(quarter.deviation, 1)} %
                            <LinearProgress
                              variant='determinate'
                              value={quarter.deviation}
                              color={colorDeviation}
                            />
                          </td>
                          <td className='text-center'>
                            <IconButton
                              aria-label='biblioteca'
                              color='primary'
                              size='small'
                              onClick={() => redirect('/library', quarter)}
                            >
                              <ArrowForwardIcon size='small' />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </BsCol>
          </BsRow>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PostPoda;
