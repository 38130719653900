import { useState } from 'react';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Topbar, Sidebar, Footer } from '../components/Shared';
import { IsDesktopHandler } from '../../helpers';
import { Box } from '@mui/material';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    // padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: `calc(${theme.spacing(8)} + 1px)`,
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  })
);

const MainAreaContent = styled('div')((prop) => ({
  flexGrow: 1,
  minHeight: 'calc(100% - 70px)',
  display: prop.flex ? 'flex' : 'block',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: theme.palette.background.paper,
    // },
  },
  content: {
    minHeight: '100vh',
  },
  contentLessFooter: {
    flexGrow: 1,
    minHeight: 'calc(100% - 70px)',
    display: 'flex',
  },
}));

const MainV2 = (props) => {
  const { children, flex = false } = props;

  const classes = useStyles();

  const isDesktop = IsDesktopHandler('lg');

  // console.log('LAYOUT: --MainV2');

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <Box>
      <CssBaseline />
      <Topbar handleSidebarOpen={handleSidebarOpen} openState={openSidebar} />
      <Sidebar
        handleOnClose={handleSidebarClose}
        HandleOnOpen={handleSidebarOpen}
        openState={openSidebar}
        variant={'permanent'}
      />
      <div className={classes.content}>
        <Main open={openSidebar}>
          <DrawerHeader />
          <MainAreaContent flex={flex}>{children}</MainAreaContent>
          <Footer />
        </Main>
      </div>
    </Box>
  );
};

export default MainV2;
