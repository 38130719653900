import { useContext, useEffect, useState } from 'react';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import parseISO from 'date-fns/parseISO';
import { Controller, useForm } from 'react-hook-form';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  birthConvert,
  dashDelete,
  dateFormatDatesZ,
  dateFormatIso,
  findActualYear,
  numberFormat,
} from '../../../helpers';
import { SaveAction, TextFieldWidgetDateController } from 'components';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 7),
    width: '80%',
    height: '65vh',
    margin: 'auto',
    // marginTop: '5vh',
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
}));

const ModalEdit = ({ closeAction, dataModal, updateDataOrchard }) => {
  const theme = useTheme();

  const classes = useStyles();

  const errorHandler = useErrorHandler();

  const [loadingUpdateState, setLoadingUpdateState] = useState(false);

  const [successState, setSuccessState] = useState(false);

  const axiosContext = useContext(AxiosContext);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  console.log(dataModal, '----dataModal');

  const onSubmit = async (values) => {
    console.log(values, '----data');

    setLoadingUpdateState(true);

    const varietiesData = values[dataModal.orchard];

    const newArray = [];

    // Recorre el objeto original
    for (const varietyValue in varietiesData) {
      if (Object.prototype.hasOwnProperty.call(varietiesData, varietyValue)) {
        const { beginDate, endDate } = varietiesData[varietyValue];
        const formattedBeginDate = dateFormatIso(beginDate); // assuming formatDate is defined elsewhere
        const formattedEndDate = dateFormatIso(endDate); // assuming formatDate is defined elsewhere

        // Crea un nuevo objeto en el formato deseado
        const newObject = {
          varietyValue,
          beginDate: formattedBeginDate,
          endDate: formattedEndDate,
        };

        // Agrega el nuevo objeto al array
        newArray.push(newObject);
      }
    }

    console.log(newArray, '----newArray');

    const bodyQuery = {
      orchardValue: dataModal.orchard,
      varieties: newArray,
    };

    console.log(bodyQuery, '----bodyQuery');

    try {
      const response = await axiosContext.authAxios.post(
        'v1/harvest-projection/update',
        bodyQuery
      );
      const { data, status } = response;

      if (status === 200) {
        console.log(data, '----data');
        updateDataOrchard(data);
        setSuccessState(true);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingUpdateState(false);
    }
  };

  const closeHandler = () => {
    setSuccessState(false);
    closeAction();
  };

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12'>
              <Typography variant='h5' gutterBottom>
                Editar Huerto {dashDelete(dataModal.orchard)}
              </Typography>
              <BsRow>
                <BsCol class='col-md-12'>
                  <form
                    autoComplete='false'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {dataModal.variety.map((variety, i) => {
                      const sinceDateVariety = dataModal.data[i].beginDate
                        ? new Date(
                            dateFormatDatesZ(dataModal.data[i].beginDate)
                          )
                        : new Date();

                      const untilDateVariety = dataModal.data[i].endDate
                        ? new Date(dateFormatDatesZ(dataModal.data[i].endDate))
                        : new Date();

                      return (
                        <BsRow key={variety} class='align-items-center'>
                          <BsCol class='col-md-4'>
                            <Typography variant='h5' gutterBottom>
                              {dashDelete(variety)}
                            </Typography>
                          </BsCol>
                          <BsCol class='col-md-4'>
                            <TextFieldWidgetDateController
                              name={`${dataModal.orchard}.${variety}.beginDate`}
                              labeltext='Desde'
                              // value={sinceSelectedState}
                              // onChange={date => setSinceSelectedState(date)}
                              // minDate={minDateValidationState}
                              control={control}
                              errors={errors.since}
                              views={['month', 'day']}
                              defaultValue={sinceDateVariety}
                            />
                          </BsCol>
                          <BsCol class='col-md-4'>
                            <TextFieldWidgetDateController
                              name={`${dataModal.orchard}.${variety}.endDate`}
                              labeltext='Desde'
                              // value={sinceSelectedState}
                              // onChange={date => setSinceSelectedState(date)}
                              // minDate={minDateValidationState}
                              control={control}
                              errors={errors.until}
                              views={['month', 'day']}
                              defaultValue={untilDateVariety}
                            />
                          </BsCol>
                        </BsRow>
                      );
                    })}
                    <BsRow class='justify-content-center mt-3'>
                      <BsCol class='col-md-3'>
                        <SaveAction
                          title='Actualizar'
                          color='secondary'
                          size='large'
                          fullWidth={true}
                          actionClose={closeHandler}
                          disabled={loadingUpdateState}
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingUpdateState}
                          success={successState}
                        />
                      </BsCol>
                    </BsRow>
                  </form>
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalEdit;
