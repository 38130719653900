// import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useForm, useWatch } from 'react-hook-form';
import { useField } from '@mui/x-date-pickers/internals';
import {
  Card,
  CardContent,
  useTheme,
  FormHelperText,
  InputAdornment,
  Alert,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import {
  SaveAction,
  TextFieldWidgetText,
  TextFieldWidgetSelectController,
  TextFieldWidgetText2,
  SelectMultipleWidget,
  SelectMultipleWidget2,
} from '../../components';
import { VARIETY_ARRAY } from '../../helpers/Mockups';
import { AxiosContext } from '../../context/AxiosContext';
import { Context } from '../../context/Context';
import { FiltersContext } from '../../context';
import { useErrorHandler } from 'react-error-boundary';
import { alphabeticalSort } from 'helpers';

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    fontFamily: theme.typography.fontFamily,
  },
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  blueLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: 20,
  },
  tableHead: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    '& thead tr th': {
      verticalAlign: 'middle',
      borderBottom: 'none',
      borderTop: 'none',
      textAlign: 'center',
      padding: '0.5rem',
    },
  },
  cellFruit: {
    backgroundColor: theme.palette.grey[50],
  },
  evenItem: {
    backgroundColor: theme.palette.grey[50],
  },
  oddItem: {},
}));

const ARRAY_VARIETY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ARRAY_COLORS = [
  {
    label: 'Roja',
    value: 'Roja',
  },
  {
    label: 'Amarilla',
    value: 'Amarilla',
  },
];
// const ARRAY_FILTERS = {
//   group: [
//     {
//       label: 'Grupo 1',
//       value: 'Grupo_1',
//       agroBusiness: [
//         {
//           label: 'Agro 1',
//           value: 'Agro_1',
//           orchard: [
//             {
//               label: 'La Laja',
//               value: 'La_Laja',
//             },
//             {
//               label: 'La Perla',
//               value: 'La_Perla',
//             },
//           ],
//         },
//         {
//           label: 'Agro 2',
//           value: 'Agro_2',
//           orchard: [
//             {
//               label: 'La Sapa',
//               value: 'La_Sapa',
//             },
//           ],
//         },
//       ],
//     },
//     {
//       label: 'Grupo 2',
//       value: 'Grupo_2',
//       agroBusiness: [
//         {
//           label: 'Agro 3',
//           value: 'Agro_3',
//           orchard: [
//             {
//               label: 'La Tresa',
//               value: 'La_Tresa',
//             },
//             {
//               label: 'La Cuarta',
//               value: 'La_Cuarta',
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };

const FormExternal = () => {
  // const sortedVariety = VARIETY_ARRAY.sort((a, b) =>
  //   a.label > b.label ? 1 : -1
  // );

  const [loadingState, setLoadingState] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(null);
  const [submittedState, setsubmittedState] = useState(null);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [loadingFiltersState, setLoadingFiltersState] = useState(false);
  const [groupDataState, setGroupDataState] = useState([]);
  const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
  const [orchardDataState, setOrchardDataState] = useState([]);
  const [specieDataState, setSpecieDataState] = useState([]);
  const [productionLineDataState, setProductionLineDataState] = useState([]);

  const actualYear = new Date().getFullYear();

  const errorHandler = useErrorHandler();

  const theme = useTheme();

  const classesUI = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { userClient, userOrchard } = useContext(Context);

  const {
    state: filterState,
    addFilter,
    getMyOrchardsInfo,
  } = useContext(FiltersContext);

  const { isLoading } = filterState;

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const useVarieties = useWatch({
    control,
    name: [
      'variety1',
      'variety2',
      'variety3',
      'variety4',
      'variety5',
      'variety6',
      'variety7',
      'variety8',
      'variety9',
      'variety10',
    ],
  });

  const useGroup = useWatch({ control, name: 'group' });
  const useAgroBusiness = useWatch({ control, name: 'agroBusiness' });
  const useOrchard = useWatch({ control, name: 'orchard' });
  const useSpecie = useWatch({ control, name: 'specie' });
  const useProductionLine = useWatch({ control, name: 'productionLine' });

  const getMyNewOrchardsAsync = async (userOrch) => {
    try {
      const response = await axiosContext.authAxios.get('v1/orchard', {
        params: {
          orchard: JSON.stringify(userOrch),
        },
      });

      const { data, status } = response;

      if (status === 200) {
        const array_id = (array) => {
          const newArray = array.map((item) => {
            return {
              ...item,
              _id: item.id,
            };
          });
          return newArray;
        };

        addFilter({
          userOrchard_filter: array_id(data),
        });

        setsubmittedState(true);

        setLoadingState(false);
        reset();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingState(false);
    }
  };

  const onSubmit = async (values) => {
    // console.log(values, 'values', useVarieties);
    clearErrors('duplicateValues');

    // quita los '', luego revisa que no haya repetidos
    const hasDuplicates = (array) => {
      const filteredData = array.filter((item) => item !== '');
      return new Set(filteredData).size !== filteredData.length;
    };

    // si hay repetidos en el array, muestra error
    if (hasDuplicates(useVarieties)) {
      console.log('hay duplicados');
      setError('duplicateValues', {
        type: 'manual',
        message: 'Hay variedades duplicadas.',
      });
      return;
    }

    setLoadingState(true);

    const bodyQuery = {
      client: userClient?.label,
      clientValue: userClient?.value,
      group: values.group.label,
      groupValue: values.group.value,
      orchard: values.orchard.label,
      orchardValue: values.orchard.value,
      agroBusiness: values.agroBusiness.label,
      agroBusinessValue: values.agroBusiness.value,
      specie: values.specie.label,
      specieValue: values.specie.value,
      productLine: values.productionLine.label,
      productLineValue: values.productionLine.value,
      varieties: [],
    };

    useVarieties.forEach((item, index) => {
      if (item) {
        const varietyValue = item.value;
        const kilos = parseFloat(values[`variety_${index + 1}`].kilos);
        // const surface = values[`variety_${index + 1}`].surface
        //   ? parseFloat(values[`variety_${index + 1}`].surface)
        //   : null;
        // const deliveryPercent = parseFloat(
        //   values[`variety_${index + 1}`].deliveryPercent
        // );
        const variety = varietyDataState.find(
          (item) => item.value === varietyValue
        );
        // const pulpColor = values[`variety_${index + 1}`].pulpColor;
        const objVariety = {
          variety: variety.label,
          varietyValue,
          // pulpColor,
          kilos,
          // surface,
          // deliveryPercent,
        };
        bodyQuery.varieties.push(objVariety);
      }
    });

    console.log(bodyQuery, 'bodyQuery');
    try {
      const response = await axiosContext.authAxios.post(
        'v1/libraryExternal',
        bodyQuery
      );
      // console.log(response, '-------response library external');

      const { status } = response;

      if (status === 200) {
        getMyNewOrchardsAsync(userOrchard);
      }
    } catch (error) {
      console.log(error, 'error');
      setLoadingState(false);
      setErrorMessageState('Error al guardar los datos.');
      errorHandler(error);
    }
  };

  const closeHandler = () => {
    // ejecutar el get orchards
    // getMyOrchardsInfo(userOrchard);
    setsubmittedState(false);
  };

  // si modifica el valor de la variedad, limpia el error de duplicados
  useEffect(() => {
    if (useVarieties.some((item) => item !== '')) {
      clearErrors('duplicateValues');
    }
  }, [useVarieties]);

  console.log(useVarieties, 'useVarieties');

  const getFilterDataAsync = async () => {
    setLoadingFiltersState(true);
    try {
      const params = {
        clientValue: userClient?.value,
      };

      const response = await axiosContext.authAxios.get('v1/libraryExternal', {
        params: params,
      });

      console.log(response, 'response');

      if (response.data) {
        // ordena los grupos por label
        const sortedGroup = response.data.group.sort((a, b) =>
          a.label > b.label ? 1 : -1
        );

        setGroupDataState(sortedGroup);
      }
      setLoadingFiltersState(false);
    } catch (error) {
      setLoadingFiltersState(false);
      setErrorMessageState('Error al obtener los datos de los filtros.');
      console.log(error, 'error');
    } finally {
      setLoadingFiltersState(false);
    }
  };

  useEffect(() => {
    getFilterDataAsync();
  }, []);

  useEffect(() => {
    // console.log(useGroup, 'useGroup');
    if (useGroup) {
      setValue('agroBusiness', '');
      setValue('orchard', '');
      const group = groupDataState.find(
        (item) => item.value === useGroup.value
      );
      console.log(group, 'group');
      if (group) {
        setAgroBusinessDataState(group.agroBusiness);
      }
    }
  }, [useGroup]);

  useEffect(() => {
    // console.log(useAgroBusiness, 'useAgroBusiness');
    if (useAgroBusiness) {
      setValue('orchard', '');
      const agroBusiness = groupDataState
        .find((item) => item.value === useGroup.value)
        .agroBusiness.find((item) => item.value === useAgroBusiness.value);
      console.log(agroBusiness, 'agroBusiness');
      if (agroBusiness) {
        setOrchardDataState(agroBusiness.orchard);
      }
    }
  }, [useAgroBusiness]);

  useEffect(() => {
    // console.log(useAgroBusiness, 'useAgroBusiness');
    if (useOrchard) {
      setValue('specie', '');
      const orchard = groupDataState
        .find((item) => item.value === useGroup.value)
        .agroBusiness.find((item) => item.value === useAgroBusiness.value)
        .orchard.find((item) => item.value === useOrchard.value);
      console.log(orchard, 'huerto con especies');
      if (orchard) {
        setSpecieDataState(orchard.specie);
      }
    }
  }, [useOrchard]);

  useEffect(() => {
    // console.log(useAgroBusiness, 'useAgroBusiness');
    if (useSpecie) {
      setValue('productionLine', '');
      const orchard = groupDataState
        .find((item) => item.value === useGroup.value)
        .agroBusiness.find((item) => item.value === useAgroBusiness.value)
        .orchard.find((item) => item.value === useOrchard.value)
        .specie.find((item) => item.value === useSpecie.value);
      // console.log(orchard, 'especie con lineProduction');
      if (orchard) {
        setProductionLineDataState(orchard.productLine);
      }
    }
  }, [useSpecie]);

  useEffect(() => {
    if (useProductionLine) {
      const orchard = groupDataState
        .find((item) => item.value === useGroup.value)
        .agroBusiness.find((item) => item.value === useAgroBusiness.value)
        .orchard.find((item) => item.value === useOrchard.value)
        .specie.find((item) => item.value === useSpecie.value)
        .productLine.find((item) => item.value === useProductionLine.value);
      console.log(orchard, 'orchard en product line');
      if (orchard) {
        setVarietyDataState(alphabeticalSort(orchard.variety, 'label'));
      }
    }
  }, [useProductionLine]);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-md-3'>
            <Card
              elevation={0}
              // classes={{
              // 	root: 'MainCardRoot',
              // }}
            >
              <CardContent className='px-4 py-4'>
                <Typography gutterBottom variant='h4' component='h2'>
                  Formulario Externos
                </Typography>
                <p>Registre aquí los kilogramos por variedad de su huerto.</p>
                {errors.duplicateValues && (
                  <Alert severity='warning' className='mb-3'>
                    {errors.duplicateValues.message}
                  </Alert>
                )}
                {errorMessageState && (
                  <Alert severity='error' className='mb-3'>
                    {errorMessageState}
                  </Alert>
                )}
                {submittedState && (
                  <Alert severity='success' className='mb-3'>
                    Datos guardados correctamente.
                  </Alert>
                )}
                <form autoComplete='on' onSubmit={handleSubmit(onSubmit)}>
                  <BsRow class='mb-5'>
                    <BsCol class='col-md-12 mb-2'>
                      <SelectMultipleWidget2
                        errors={errors.group}
                        loading={loadingFiltersState}
                        control={control}
                        menuPortalTarget={document.body}
                        labeltext='Grupo'
                        req
                        // style={{ width: '100%', maxWidth: '100%' }}
                        name='group'
                        display='horizontal'
                        variant='outlined'
                        isMulti={false}
                        isClearable={true}
                        isSearchable
                        defaultValue=''
                        options={groupDataState}
                        placeholder='Seleccione Grupo'
                      />
                    </BsCol>
                    <BsCol class='col-md-12 mb-2'>
                      <SelectMultipleWidget2
                        errors={errors.agroBusiness}
                        loading={loadingFiltersState}
                        control={control}
                        menuPortalTarget={document.body}
                        labeltext='Razón Social'
                        req
                        style={{ width: '100%', maxWidth: '100%' }}
                        name='agroBusiness'
                        isMulti={false}
                        isClearable={true}
                        isSearchable
                        defaultValue=''
                        options={agroBusinessDataState}
                        placeholder='Seleccione Razón Social'
                      />
                    </BsCol>
                    <BsCol class='col-md-12 mb-2'>
                      <SelectMultipleWidget2
                        errors={errors.orchard}
                        loading={loadingFiltersState}
                        control={control}
                        menuPortalTarget={document.body}
                        labeltext='Huerto'
                        req
                        style={{ width: '100%', maxWidth: '100%' }}
                        name='orchard'
                        isMulti={false}
                        isClearable={true}
                        isSearchable
                        defaultValue=''
                        options={orchardDataState}
                        placeholder='Seleccione Huerto'
                      />
                    </BsCol>
                    <BsCol class='col-md-12 mb-2'>
                      <SelectMultipleWidget2
                        errors={errors.specie}
                        loading={loadingFiltersState}
                        control={control}
                        menuPortalTarget={document.body}
                        labeltext='Especie'
                        req
                        style={{ width: '100%', maxWidth: '100%' }}
                        name='specie'
                        isMulti={false}
                        isClearable={true}
                        isSearchable
                        defaultValue=''
                        options={specieDataState}
                        placeholder='Seleccione Especie'
                      />
                    </BsCol>
                    <BsCol class='col-md-12'>
                      <SelectMultipleWidget2
                        errors={errors.productionLine}
                        loading={loadingFiltersState}
                        control={control}
                        menuPortalTarget={document.body}
                        labeltext='Línea de Producción'
                        req
                        style={{ width: '100%', maxWidth: '100%' }}
                        name='productionLine'
                        isMulti={false}
                        isClearable={true}
                        isSearchable
                        defaultValue=''
                        options={productionLineDataState}
                        placeholder='S. L. de Producción'
                      />
                    </BsCol>
                  </BsRow>
                  {ARRAY_VARIETY.map((item, index) => {
                    const className = useVarieties[index]
                      ? 'oddItem'
                      : 'evenItem';
                    return (
                      <BsRow
                        key={`fila_${index}`}
                        class={
                          'align-items-center mb-4 py-3 gutter_10 ' +
                          classesUI[className]
                        }
                      >
                        <BsCol class='col-md-6 col-lg-6'>
                          {/* <TextFieldWidgetSelectController
                            options={varietyDataState}
                            titleOption='label'
                            control={control}
                            name={`variety${item}`}
                            labeltext='Variedad'
                            errors={errors.variety}
                            defaultValue=''
                            loading={false}
                            // req
                            withDefault
                            variant='standard'
                          /> */}
                          <SelectMultipleWidget2
                            errors={errors.variety}
                            loading={false}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext='Variedad'
                            style={{ width: '100%', maxWidth: '100%' }}
                            name={`variety${item}`}
                            isMulti={false}
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            defaultValue=''
                            options={varietyDataState}
                            placeholder='Seleccione Variedad'
                          />
                        </BsCol>
                        {/* <BsCol class='col-md-6 col-lg-2'>
                          <TextFieldWidgetSelectController
                            options={ARRAY_COLORS}
                            titleOption='label'
                            control={control}
                            name={`variety_${item}.pulpColor`}
                            labeltext='Color Pulpa'
                            errors={errors[`variety_${item}`]}
                            defaultValue=''
                            loading={false}
                            // req
                            withDefault
                            variant='standard'
                          />
                        </BsCol> */}
                        <BsCol class='col-md-6 col-lg-6'>
                          <TextFieldWidgetText2
                            name={`variety_${item}.kilos`}
                            type='number'
                            display='vertical'
                            labeltext={`Kilos Variedad`}
                            req={!!useVarieties[index]}
                            defaultValue=''
                            disabled={!useVarieties[index]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                              endAdornment: (
                                <InputAdornment position='end'>
                                  Kg
                                </InputAdornment>
                              ),
                            }}
                            control={control}
                            errors={errors[`variety_${item}`]}
                          />
                          {errors[`variety_${item}`]?.kilos && (
                            <FormHelperText error className='text-left'>
                              Ingrese Kilos.
                            </FormHelperText>
                          )}
                        </BsCol>
                        {/* <BsCol class='col-md-6 col-lg-2'>
                          <TextFieldWidgetText2
                            name={`variety_${item}.deliveryPercent`}
                            type='number'
                            display='vertical'
                            labeltext={`Entrega Variedad`}
                            req={!!useVarieties[index]}
                            defaultValue={100}
                            disabled={!useVarieties[index]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: 0.1,
                              },
                              endAdornment: (
                                <InputAdornment position='end'>
                                  %
                                </InputAdornment>
                              ),
                            }}
                            control={control}
                            errors={errors[`variety_${item}`]}
                          />
                          {errors[`variety_${item}`]?.deliveryPercent && (
                            <FormHelperText error className='text-left'>
                              Ingrese % Entrega.
                            </FormHelperText>
                          )}
                        </BsCol> */}
                        {/* <BsCol class='col-md-6 col-lg-2'>
                          <TextFieldWidgetText2
                            name={`variety_${item}.surface`}
                            type='number'
                            labeltext={`Superficie Variedad`}
                            req={false}
                            display='vertical'
                            defaultValue=''
                            disabled={!useVarieties[index]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: 0.1,
                              },
                              endAdornment: (
                                <InputAdornment position='end'>
                                  Ha
                                </InputAdornment>
                              ),
                            }}
                            control={control}
                            errors={errors.surface}
                          />
                        </BsCol> */}
                      </BsRow>
                    );
                  })}

                  <BsRow class='mt-5'>
                    <BsCol class='col-md-12 text-center'>
                      <SaveAction
                        title='Guardar'
                        color='secondary'
                        size='large'
                        className='roundedButton ml-md-2'
                        variant='contained'
                        disabled={loadingState}
                        messageType='add'
                        actionClose={closeHandler}
                        mutationLoading={loadingState}
                        success={submittedState}
                      />
                    </BsCol>
                  </BsRow>
                </form>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default FormExternal;
