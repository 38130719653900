import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import {
  // Avatar,
  Card,
  CardContent,
  Typography,
  useTheme,
  Tooltip,
  Grid,
  Button,
} from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useHistory } from 'react-router-dom';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { AxiosContext } from '../../context/AxiosContext';
import { Context } from '../../context/Context';
import { useForm, useWatch } from 'react-hook-form';
import { SimpleTabs, TabPanel } from '../../components/TabPanel';
import theme from '../../theme';
import { FiltersContext } from '../../context';
import {
  ButtonAction,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  TextFieldWidgetSelectController,
  TextFieldWidgetTextTypeFile,
} from '../../components';
import {
  alterArrayForSelectVariety,
  alterArrayGetFields,
  arrayGetStationIds,
  setOrderForApi,
} from '../../helpers';
import { MobileFilters } from '../../containers/Library';
import { EditTable } from 'containers/DataAnalysis';

const MonthlyReport = (props) => {
  const { userClient } = useContext(Context);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const [loadingInfoState, setLoadingInfoState] = useState(false);

  const [loadingFiltersState] = useState(false);

  const [infoFilterState, setInfoFilterState] = useState(null);

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  // const [filterDataResponseState] = useState(
  //   alterArrayForSelectVariety(userOrchard_filter)
  // );
  const [filterDataResponseState] = useState(userOrchard_filter);

  const [ccDataState, setCcDataState] = useState([]);
  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // const params = {
  //   clientName: 'Rucaray',
  // };
  // const paramsAsString = JSON.stringify(params);
  // const encodedParams = encodeURIComponent(paramsAsString);

  console.log('client------', userClient);

  const clientName = userClient?.label;

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardContent>
                {/* <iframe
                  src='https://lookerstudio.google.com/embed/reporting/b375e342-770d-4d0b-bf88-949c13ac27ef'
                  frameBorder='0'
                  style={{ border: 0, width: '100%', height: '800px' }}
                  allowfullscreen
                ></iframe> */}
                {/* PRUEBAS <iframe
                  style={{ border: 0, width: '100%', height: '2000px' }}
                  src={`https://lookerstudio.google.com/embed/reporting/318bfdfd-8a7c-461b-82a7-6d6740a137e8/page/y4QUD?params={"df4":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${clientName}"}`}
                  frameborder='0'
                  allowfullscreen
                  sandbox='allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
                ></iframe> */}
                {/* <iframe
                  style={{ border: 0, width: '100%', height: '2000px' }}
                  src={`https://lookerstudio.google.com/embed/reporting/05984ae2-f83c-40a5-b527-7feaee46fde8/page/y4QUD?params={"df4":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${clientName}"}`}
                  frameborder='0'
                  allowfullscreen
                  sandbox='allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
                ></iframe> */}
                <iframe
                  style={{ border: 0, width: '100%', height: '2000px' }}
                  src={`https://lookerstudio.google.com/embed/reporting/c2a8205b-77cf-4117-9445-36e987bf8f6a/page/y4QUD?params={"df20":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${clientName}","df4":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${clientName}"}`}
                  frameborder='0'
                  allowfullscreen
                  sandbox='allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
                ></iframe>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default MonthlyReport;
