// import _ from 'lodash';
import {
	Accordion,
	AccordionSummary,
	Avatar,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	Hidden,
	Modal,
	Paper,
	Typography,
	useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import {
	ButtonAction,
	LoadingWidget,
	SaveAction,
	TextFieldWidgetSelectControllerStyled,
} from '../../../components';
import { FiltersContext } from '../../../context';
import { ModalTable } from 'containers/Dashboard';
import clsx from 'clsx';
import {
	dateTimeParse,
	numberFormat,
	numberFormatDecimals,
} from '../../../helpers';
import _ from 'lodash';
import { HarvestBox } from 'containers/Harvest';
import { useHistory } from 'react-router-dom';
// import { Context } from '../../context/Context';

// import { ButtonBox } from '../../components/Dashboard';

const SupervisorData = [
	{
		_id: '123',
		name: 'Supervisor 1',
		rut: '21.958.024-K',
		phone: '435435345',
		appClose: true,
		syncronized: [
			{
				dateTime: '10-11-2022 16:30',
				count: 10,
			},
			{
				dateTime: '10-11-2022 10:30',
				count: 1,
			},
		],
	},
	{
		_id: '1234',
		name: 'Supervisor 2',
		rut: '',
		appClose: false,
		phone: '345435444',
		syncronized: [],
	},
	{
		_id: '12345',
		name: 'Supervisor 3',
		rut: '22.958.024-K',
		phone: '435425345',
		appClose: true,
		syncronized: [
			{
				dateTime: '10-11-2022 14:30',
				count: 10,
			},
			{
				dateTime: '10-11-2022 12:30',
				count: 1,
			},
			{
				dateTime: '10-11-2022 10:30',
				count: 1,
			},
		],
	},
];

const CONTRACTOR = [
	{
		id: 1,
		name: 'Contractor 1',
		rut: '11.111.111-1',
		phone: '43543543',
		amountTotal: 1000000,
		amountTote: 6000,
		harvester: [
			{
				name: 'Marco',
				rut: '22.222.222-2',
				idQr: '645654618464565',
				countTote: 10,
				amount: 10000,
			},
			{
				name: '',
				rut: '',
				idQr: '324457842323544',
				countTote: 10,
				amount: 10000,
			},
		],
	},
	{
		id: 2,
		name: 'Contractor 2',
		rut: '11.222.111-1',
		phone: '43593543',
		amountTotal: 60000,
		amountTote: 6000,
		harvester: [
			{
				name: 'Marco',
				rut: '22.222.222-2',
				idQr: '645654665554565',
				countTote: 10,
				amount: 10000,
			},
		],
	},
	{
		id: 3,
		name: 'No Contractor',
		rut: '',
		phone: '',
		amountTotal: 60000,
		amountTote: 6000,
		harvester: [
			{
				name: 'Marco',
				rut: '22.222.222-2',
				idQr: '645654665464565',
				countTote: 10,
				amount: 10000,
			},
			{
				name: 'Marco 2',
				rut: '22.111.222-2',
				idQr: '645654665411111',
				countTote: 10,
				amount: 80000,
			},
		],
	},
];

const useStyles = makeStyles((theme) => ({
	tableAreaStyle: {
		maxHeight: '370px',
		overflow: 'auto',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paperContainerStyle: {
		padding: 20,
		marginBottom: 15,
	},
	dashCard: {
		minHeight: '100%',
	},
	dividerRow: {
		marginTop: 30,
	},
	kpiTextStyle: {
		color: theme.palette.primary.main,
		fontWeight: 600,
		fontSize: 42,
		textAlign: 'center',
	},
	prePosContent: {
		justifyContent: 'space-between',
		alignItems: 'center',
		minHeight: '290px',
		display: 'flex',
		flexDirection: 'column',
		'& div': {
			width: '100%',
		},
	},
	subtitleDash: {
		fontSize: 18,
	},
	successText: {
		textAlign: 'right',
		color: theme.palette.success.main,
		fontSize: 15,
		'& span': {
			fontSize: 22,
			fontWeight: 700,
		},
	},
	theadLight: {
		'& th': {
			// color: '#495057',
			backgroundColor: theme.palette.grey[200],
			borderColor: theme.palette.grey[300],
		},
	},
}));

const HarvestAdd = () => {
	// let history = useHistory();

	const theme = useTheme();

	// console.log(theme);

	const classes = useStyles();

	const axiosContext = useContext(AxiosContext);

	const {
		state: { userOrchard_filter, orchard_filter, orchard_id_filter },
		addFilter,
	} = useContext(FiltersContext);

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [dataModalState, setDataModalState] = useState(null);

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();

	const [loadingDashDataState, setLoadingDashDataState] = useState(false);
	const [dashDataState, setDashDataState] = useState(null);

	const [kpiHarvestDataState, setKpiHarvestDataState] = useState(null);

	const [workersByContractorState, setWorkersByContractorState] = useState(
		null
	);

	// const context = useContext(Context);
	// const { userId, userIdProfile, userFullname } = context;
	const [openModalState, setOpenModalState] = useState(false);

	const handleCloseModal = () => {
		setOpenModalState(false);
	};

	const handleOpenModal = (row) => {
		setDataModalState(row);
		setOpenModalState(true);
	};

	const onSubmit = async (values) => {
		setLoadingDashDataState(true);

		// para get
		// let data = {
		// 	params: { huerto: values.orchard },
		// };

		let data = {
			orchardValue: values.orchard,
		};

		const resultOrchard = userOrchard_filter.filter(
			(ele) => ele.value === values.orchard
		);

		// console.log(values.orchard, userOrchard_filter, '<-----');

		if (orchard_id_filter !== resultOrchard[0]._id) {
			addFilter({
				cc_filter: '',
				quarter_filter: '',
			});
		}
		addFilter({
			orchard_filter: values.orchard,
			orchard_id_filter: resultOrchard[0]._id,
		});

		// console.log(data, '<<<<<');

		try {
			const response = await axiosContext.authAxios.post(
				'v1/traceability/summary-harvest-sync',
				data
			);
			const bodyInfo = response.data;
			// const bodyInfo = INFO[0];

			console.log(response, '<---LLEGA RESPONSE DATA--');

			if (bodyInfo) {
				setDashDataState(bodyInfo.supervisors);
				setWorkersByContractorState(bodyInfo.workersBycontractor);
				setLoadingDashDataState(false);
				// kpi
				setKpiHarvestDataState([
					{
						title: 'Cant. Trabajadores',
						value: bodyInfo.workersCount,
						id: '1',
					},
					{ title: 'Bins', value: bodyInfo.totalBins, id: '2' },
					{ title: 'Kilos', value: bodyInfo.totalKilos, id: '3' },
				]);
			}
		} catch (error) {
			// console.log(error);
			setLoadingDashDataState(false);
		}
	};

	useEffect(() => {
		let timer;
		if (orchard_filter) {
			setValue('orchard', orchard_filter);

			timer = setTimeout(() => {
				handleSubmit(onSubmit)();
			}, 200);
		}

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// console.log(userOrchard_filter);

	let history = useHistory();

	const redirect = (link, type) => {
		history.push({
			pathname: link,
			state: {
				workers: workersByContractorState,
				orchard: orchard_filter,
				button: type,
			},
		});
	};

	const showReport = (type) => {
		redirect('/harvest-report', type);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-12 mb-md-3">
						<Card
							elevation={0}
							// classes={{
							// 	root: 'MainCardRoot',
							// }}
						>
							<CardContent>
								<BsRow class="mb-1 mb-md-4">
									<BsCol class="col-md-6">
										<Typography
											gutterBottom
											variant="h4"
											component="h2"
										>
											Cierre diario por Huerto
										</Typography>
										<form
											className="form-inline"
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<div className="input-group">
												<TextFieldWidgetSelectControllerStyled
													options={
														filterDataResponseState
													}
													control={control}
													titleOption="label"
													name="orchard"
													labeltext="Seleccione Huerto"
													errors={errors.orchard}
													defaultValue=""
													loading={false}
													req
													withDefault
													variant="standard"
												/>
											</div>
											<SaveAction
												title="Filtrar"
												color="secondary"
												size="large"
												className="roundedButton ml-md-2"
												variant="contained"
												messageType="add"
												mutationLoading={false}
												success={false}
											/>
										</form>
									</BsCol>
									{!loadingDashDataState && (
										<BsCol class="col-md-6 align-self-end text-right">
											<Typography
												variant="h6"
												gutterBottom
											>
												{new Date().toLocaleDateString(
													'es-ES',
													{
														weekday: 'long',
														year: 'numeric',
														month: 'short',
														day: 'numeric',
													}
												)}
											</Typography>
											{/* <Typography
												variant="body2"
												gutterBottom
											>
												Última actualización 17:45 hrs.
											</Typography> */}
										</BsCol>
									)}
								</BsRow>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
				{!loadingDashDataState ? (
					dashDataState ? (
						<>
							{/* row 1 kpis */}
							<BsRow class="no-gutters">
								<BsCol class="col-md-12">
									<BsRow class="gutter_10">
										{kpiHarvestDataState &&
											kpiHarvestDataState.map((box) => {
												return (
													<BsCol
														key={box.id}
														class="col-md-4"
													>
														<Card
															elevation={0}
															className={
																classes.dashCard
															}
														>
															<CardContent>
																<HarvestBox
																	title={
																		box.title
																	}
																	value={
																		box.value
																	}
																/>
															</CardContent>
														</Card>
													</BsCol>
												);
											})}
									</BsRow>
								</BsCol>
							</BsRow>
							{/* row 2 supervisores */}
							<BsRow class="no-gutters mt-3">
								<BsCol class="col-md-12">
									<BsRow class="gutter_10">
										<BsCol class="col-md-12">
											<Card
												elevation={0}
												className={classes.dashCard}
											>
												<CardContent>
													<Typography
														variant="subtitle1"
														className={
															classes.subtitleDash
														}
														gutterBottom
													>
														Supervisores
													</Typography>
													<div
														className={
															classes.tableAreaStyle
														}
													>
														<table className="table">
															<thead
																style={{
																	position:
																		'sticky',
																	top: 0,
																	zIndex: 1,
																}}
																className={
																	classes.theadLight
																}
															>
																<tr>
																	<th scope="col">
																		Nombre
																		completo
																	</th>
																	<th scope="col">
																		Rut
																	</th>
																	<th scope="col">
																		teléfono
																	</th>
																	<th scope="col">
																		Últ.
																		Actualización
																	</th>
																	<th scope="col">
																		Cierre
																		Aplicación
																	</th>
																</tr>
															</thead>
															<tbody>
																{dashDataState &&
																	dashDataState.length >
																		0 &&
																	dashDataState.map(
																		(
																			supervisor,
																			i
																		) => (
																			<tr
																				key={`${supervisor.rut}_${i}`}
																			>
																				<td>
																					{
																						supervisor.fullname
																					}
																				</td>
																				<td>
																					{
																						supervisor.rut
																					}
																				</td>
																				<td>
																					{
																						supervisor.phoneNumber
																					}
																				</td>
																				<td>
																					{supervisor.harvestSync
																						? dateTimeParse(
																								supervisor
																									.harvestSync
																									.dateTime
																						  )
																						: '-'}
																				</td>
																				<td>
																					{supervisor.harvestSyncState
																						? 'SI'
																						: 'NO'}
																				</td>
																				{/* <td>
																					<Button
																						variant="contained"
																						color="secondary"
																						size="small"
																						disableElevation
																						onClick={() =>
																							handleOpenModal(
																								quarter
																							)
																						}
																					>
																						Detalles
																					</Button>
																				</td> */}
																			</tr>
																		)
																	)}
															</tbody>
														</table>
													</div>
												</CardContent>
											</Card>
										</BsCol>
									</BsRow>
								</BsCol>
							</BsRow>
							{/* row 3 Cierre */}
							<BsRow class="no-gutters mt-3">
								<BsCol class="col-md-12">
									<BsRow class="gutter_10">
										<BsCol class="col-md-12">
											<Card
												elevation={0}
												className={classes.dashCard}
											>
												<CardContent>
													<BsRow>
														<BsCol class="col-md-6">
															<ButtonAction
																labelButton="Previsualizar Pagos por Contratista"
																color="secondary"
																size="large"
																actionOnClick={() =>
																	showReport(
																		false
																	)
																}
																className="roundedButton"
																variant="contained"
																messageType="add"
																mutationLoading={
																	false
																}
																success={false}
															/>
														</BsCol>
														<BsCol class="col-md-6">
															<ButtonAction
																labelButton="Generar Reporte de Pagos"
																color="secondary"
																size="large"
																actionOnClick={() =>
																	showReport(
																		true
																	)
																}
																className="roundedButton"
																variant="contained"
																messageType="add"
																mutationLoading={
																	false
																}
																success={false}
															/>
														</BsCol>
													</BsRow>
												</CardContent>
											</Card>
										</BsCol>
									</BsRow>
								</BsCol>
							</BsRow>
							<Modal
								open={openModalState}
								onClose={handleCloseModal}
								className={classes.modal}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
							>
								<>
									<ModalTable
										closeAction={handleCloseModal}
										dataModal={dataModalState}
									/>
								</>
							</Modal>
						</>
					) : (
						<></>
					)
				) : (
					<LoadingWidget />
				)}
			</BsContainer>
		</div>
	);
};

export default HarvestAdd;
