import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { subDays } from 'date-fns';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  TextFieldWidgetDateController2,
  // TextFieldWidgetDateController,
} from '../../../components';
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  // Hidden,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useErrorHandler } from 'react-error-boundary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { makeStyles } from '@mui/styles';
import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  alterArrayGetFields,
  getOrchardVarietiesDeep,
  getOrchardRootStockFromCcDeep,
  getSeasonActiveQuarters,
  getOnlyValidCcs,
  alphabeticalSort,
  getOrchardsBySpecie,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';
import { Quarter } from '../../../containers/Counting';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

// const COUNTER_TYPE_MOCK = [
//   { value: 'Pre_Poda', label: 'Pre Poda' },
//   { value: 'Post_Poda', label: 'Post Poda' },
//   { value: 'Pre_Raleo', label: 'Pre Raleo' },
//   { value: 'Post_Raleo', label: 'Post Raleo' },
// ];

const ByQuarter = (props) => {
  // const { userId, userHuerto, userName, moduleUser } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const errorHandler = useErrorHandler();

  const {
    orchard_filter,
    orchard_id_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    rootstock_filter,
    season_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [loadingInfoState, setLoadingInfoState] = useState(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [tabsArrayState, setTabsArrayState] = useState([]);

  // const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);
  const [minDateState, setminDateState] = useState(null);

  // const [sinceSelectedFilterState, setSinceSelectedFilterState] =
  //   useState(null);
  const [untilSelectedFilterState, setUntilSelectedFilterState] =
    useState(null);

  const theme = useTheme();

  // console.log(theme)

  const classes = useStyles();

  const [valueTabState, setValueTabState] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchVariety = watch('variety');
  // const watchRootStock = watch('rootStock');
  // const watchSeason = watch('season');

  // const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  useEffect(() => {
    setUntilSelectedState(watchUntil);
  }, [watchUntil]);

  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);
  // const [rootStockDataState, setRootStockDataState] = useState([]);
  // const [rootStockFullDataState, setRootStockFullDataState] = useState([]);

  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // loading quarter data
  const [loadingFilterQuarterState, setLoadingFilterQuarterState] =
    useState(false);

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(true);

  const moduleOrchardFilter = 'statusCounter';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // seasons
  // const [counterTypeDataState, setCounterTypeDataState] =
  //   useState(COUNTER_TYPE_MOCK);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useCc = useWatch({
    control,
    name: 'cc',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);

    console.log(values);

    if (
      !values.orchard &&
      !values.variety.length
      // !values.rootStock.length
    ) {
      setError('neitherItemlist', {
        type: 'manual',
        message: 'Debe seleccionar al menos Huerto y/o Variedad ',
      });
      return;
    }

    let data = {
      orchard: values.orchard,
      cc: values.cc,
      quarter: values.quarter,
      // variedad: values.variety ? alterArrayGetValue(values.variety) : '', // debe ir solo array nombres
      variety: values.variety
        ? alterArrayGetFields(values.variety, ['value'])
        : '',
      dateEnd: values.until ? values.until : '',
      specieValue: specie_filter,
    };

    let idOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard?.value
    );

    let idOrchardFilter = idOrchard[0] ? idOrchard[0].id : null;

    addFilter({
      orchard_filter: values.orchard?.value,
      orchard_id_filter: idOrchardFilter,
      cc_filter: values.cc?.value,
      quarter_filter: values.quarter?.value,
      variety_filter: values.variety?.value,
      // rootstock_filter: values.rootStock,
      // season_filter: values.season,
    });
    setInfoFilterTab(data);
  };

  // --------NUEVO

  const getFilterVarietyAsync = async () => {
    try {
      const params = {
        specieValue: specie_filter,
      };
      const response = await axiosContext.authAxios.get('v1/variety', {
        params,
      });

      if (response.data) {
        setVarietyFullDataState(response.data);
      }
    } catch (error) {
      errorHandler(error);
      // console.log(error);
      // setLoadingVarietyFilterState(false);
    }
  };

  useEffect(() => {
    if (orchard_filter) {
      const orchardFilterFullData = filterDataResponseState.find(
        (ele) => ele.value === orchard_filter
      );

      setValue('orchard', orchardFilterFullData);
    }
  }, []);

  useEffect(() => {
    if (orchard_filter && !loadingFilterQuarterState) {
      setTimeout(() => {
        handleSubmit(onSubmit)();
      }, 800);
    }
  }, []);

  useEffect(() => {
    if (varietyFullDataState.length) {
      setLoadingFiltersState(false);
    }
  }, [varietyFullDataState]);

  // endpoints variety - rootstock
  useEffect(() => {
    getFilterVarietyAsync();
    // getFilterRootStockAsync();
  }, []);

  useEffect(() => {
    console.log(useOrchard, 'useOrchard');
    // cada vez que hay un orchard
    // limpia cc
    // y carga la nueva data de cc
    if (useOrchard) {
      clearErrors('neitherItemlist');
      setValue('cc', '');
      // console.log(
      // 	filterDataResponseState,
      // 	'-----------filterDataResponseState'
      // );
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      if (resultOrchard.length) {
        let ccSelect = resultOrchard[0].cc
          ? getOnlyValidCcs(resultOrchard[0].cc, specie_filter)
          : [];

        setCcDataState(alphabeticalSort(ccSelect, 'label'));
      }
    } else {
      setValue('cc', '');
      setCcDataState([]);
      setQuarterDataState([]);
    }
  }, [useOrchard]);

  useEffect(() => {
    // console.log('entra en ccData', ccDataState);
    if (ccDataState.length) {
      // si hay data de cc
      // reivsa si puede seleccionar el del context
      setLoadingFilterCcState(true);
      const found = ccDataState.find((element) => element.value === cc_filter);
      // console.log(found, '---found');
      if (found) {
        setValue('cc', found);
      } else {
        setValue('cc', '');
        setValue('quarter', '');
      }
      setLoadingFilterCcState(false);
    }
  }, [ccDataState]);

  useEffect(() => {
    // console.log(useCc, 'useCc');
    // cada vez que hay un cc
    // limpia quarter
    // y carga la nueva data de quarter
    if (useCc) {
      setValue('quarter', '');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);

      console.log(resultCc[0]?.quarter);

      let quarterArray = [];
      if (resultCc[0]?.quarter) {
        quarterArray = getSeasonActiveQuarters(resultCc[0]?.quarter);
      }

      console.log(quarterArray, 'active quarters');

      setQuarterDataState(quarterArray);
    } else {
      setValue('quarter', '');
      setQuarterDataState([]);
    }
  }, [useCc]);

  useEffect(() => {
    // console.log('entra en quarterData', quarterDataState);
    if (quarterDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      setLoadingFilterQuarterState(true);
      const found = quarterDataState.find(
        (element) => element.value === quarter_filter
      );
      // console.log(found, '---found');
      if (found) {
        setValue('quarter', found);
      } else {
        setValue('quarter', '');
      }
      setLoadingFilterQuarterState(false);
    } else {
      setValue('quarter', '');
    }
  }, [quarterDataState]);

  // VARIETY
  useEffect(() => {
    // console.log(useOrchard, 'useOrchard -- para variety');
    // cada vez que hay un orchard
    // carga la data de variety
    if (useOrchard) {
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      const orchardsCc = resultOrchard[0].cc;

      // console.log(getOrchardVarieties(orchardsCc), '<<---las varieties');

      setVarietyDataState(getOrchardVarietiesDeep(orchardsCc));
    } else {
      // vuelve a dejar variety completo
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [useOrchard]);

  // alimenta las varieties full
  // solo cuando no tiene nada
  useEffect(() => {
    if (varietyFullDataState.length && !varietyDataState.length) {
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [varietyFullDataState]);

  // cambios de cc
  // para ajustar variety
  useEffect(() => {
    // console.log(useCc, 'useCc - para variety');
    // cada vez que hay un cc
    // y carga la nueva data de quarter
    if (useCc) {
      // console.log(useCc, 'useCc - para variety - CON CC');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);
      setVarietyDataState(getOrchardVarietiesDeep(resultCc));
    } else {
      if (useOrchard && ccDataState.length) {
        // console.log(
        // 	useOrchard,
        // 	'------useCc hay orchard',
        // 	getOrchardVarieties(ccDataState)
        // );
        setVarietyDataState(getOrchardVarietiesDeep(ccDataState));
      }
    }
  }, [useCc]);

  // cambios en quarter
  useEffect(() => {
    if (useQuarter) {
      console.log(useQuarter, 'useQuarter - para variety');

      setVarietyDataState(alterArrayForSelectVariety(useQuarter.variety));

      setValue('variety', '');
    } else {
      if (useCc) {
        // console.log(useCc, 'useCc - para variety - CON CC');
        const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);
        setVarietyDataState(getOrchardVarietiesDeep(resultCc));
      }

      setValue('variety', '');
    }
  }, [useQuarter, setValue]);

  useEffect(() => {
    if (watchVariety) {
      console.log(watchVariety);
      clearErrors('neitherItemlist');
    }
  }, [watchVariety]);

  const renderFilters = () => {
    return (
      <MobileFilters>
        {errors.neitherItemlist && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.neitherItemlist.message}
          </p>
        )}
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group'>
                {/* <TextFieldWidgetSelectController
                  options={filterDataResponseState}
                  control={control}
                  titleOption='label'
                  name='orchard'
                  labeltext='Huerto'
                  errors={errors.orchard}
                  defaultValue=''
                  loading={loadingFiltersState}
                  // req
                  withDefault
                  variant='standard'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={loadingFilterCcState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='cc'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={ccDataState}
                  placeholder='Centro de Costo'
                />
              </div>
              <div className='input-group ml-md-2'>
                {/* <TextFieldWidgetSelectController
                  options={quarterDataState}
                  titleOption='label'
                  control={control}
                  name='quarter'
                  labeltext='Cuarteles'
                  errors={errors.quarter}
                  defaultValue=''
                  loading={loadingFilterQuarterState}
                  // req
                  withDefault
                  variant='standard'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.quarter}
                  loading={loadingFilterQuarterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='quarter'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='Cuarteles'
                />
              </div>
              <div className='input-group ml-md-2'>
                {/* <SelectMultipleWidget
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext='Variedad'
                  name='variety'
                  isSearchable
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Seleccione Variedad'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='variety'
                  display='vertical'
                  variant='outlined'
                  isMulti={true}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Variedad'
                />
              </div>
              {/* <div className='input-group ml-md-2'>
                <TextFieldWidgetDateController2
                  name='until'
                  labeltext='Hasta'
                  placeholder='Hasta'
                  display='vertical'
                  variant='outlined'
                  format='dd-MM-yyyy'
                  // value={sinceSelectedState}
                  // onChange={date => setSinceSelectedState(date)}
                  control={control}
                  errors={errors.until}
                  defaultValue={subDays(new Date(), 1)}
                />
              </div> */}
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Conteos Promedios Cuartel
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardContent
                sx={{
                  px: '15px',
                }}
              >
                <Quarter filter={infoFilterTab} />
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default ByQuarter;
