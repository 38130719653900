import React, { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import clsx from 'clsx';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';
import {
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import classes from './TextFieldWidgetDateController2.module.scss';
import { makeStyles } from '@mui/styles';
// import { makeStyles } from 'tss-react/mui';

// const useStyles = makeStyles()((theme) => {
//   return {
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 210,
//       maxWidth: 230,
//       [theme.breakpoints.down('sm')]: {
//         minWidth: '95%',
//       },
//     },
//     rootLabel: {
//       fontSize: 18,
//     },
//     textFieldStyle: {
//       marginTop: 22,
//     },
//   };
// });

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    // minWidth: 180,
    width: '100%',
    maxWidth: '100%',
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '95%',
    // },
  },
  rootLabel: {
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: 'white',
  },
  inputContainerStyleHorizontal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputContainerStyleVertical: (props) => ({
    minHeight: props.minheight ? 65 : 'auto',
    '& label': {
      fontSize: '18px',
      paddingLeft: 10,
      paddingRight: 10,
      color: '#4C5662 !important',
    },
    '& .MuiInputBase-root': {
      ...theme.typography.body2,
      fontWeight: 600,
      '& input': {
        height: 'auto',
      },
    },
    '& fieldset': {
      borderColor: theme.palette.primary.light,
      '& legend': {
        fontSize: '15px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  }),
}));

const TextFieldWidgetDateController2 = (props) => {
  const {
    errors,
    success,
    labeltext,
    name,
    loading,
    disabled,
    control,
    defaultValue,
    format = 'dd-MM',
    req,
    views,
    display = 'horizontal',
    variant = 'standard',
    style,
    minheight = true,
    ...others
  } = props;

  const classesUI = useStyles();
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  const esLocaleDate = esLocale;
  // const [selectedDate, setSelectedDate] = useState('');
  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };
  useEffect(() => {
    if (errors && errors.message) {
      setStateMessage(errors.message);
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  // useEffect(() => {
  //   if (success) {
  //     setSelectedDate('');
  //   }
  // }, [success]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = labeltext;
  } else {
    labelText = null;
  }

  if (display === 'vertical') {
    labelText = labeltext;
  }

  // para aplicar o no el minheight al campo
  const CustomInputContainer = (props) => {
    const classesUI = useStyles(props);
    return (
      <div
        className={clsx(
          display === 'horizontal'
            ? classesUI.inputContainerStyleHorizontal
            : classesUI.inputContainerStyleVertical
        )}
      >
        {props.children}
      </div>
    );
  };

  return (
    <CustomInputContainer minheight={minheight}>
      {display === 'horizontal' ? (
        <div className={classes.labelDesktopStyle}>
          {props.labeltext} {props.download}
        </div>
      ) : null}
      {props.loading && <p>cargando</p>}
      <FormControl
        className={classesUI.formControl}
        error={stateError}
        variant={variant}
        style={style || {}}
      >
        {/* <div className={classesUI.formControl}> */}
        {/* <div className={classesUI.rootLabel}>{props.labeltext}</div>
        {props.loading && <p>cargando</p>} */}
        {!isDesktop || display === 'vertical' ? (
          <InputLabel
            shrink={true}
            id={labelText}
            className={classesUI.rootLabel}
          >
            {props.labeltext} {loading && '...cargando'}
          </InputLabel>
        ) : null}
        <Controller
          control={control}
          defaultValue={defaultValue}
          name={name}
          rules={{
            required: errorMessage.message[name],
          }}
          render={({ field }) => {
            // console.log(field, '-----');
            const { ref, ...otherfields } = field;
            return (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocaleDate}
              >
                <DatePicker
                  clearable
                  name={name}
                  placeholder='25-10-2021'
                  // format='dd-MM-yyyy'
                  format={format}
                  label={labelText}
                  views={views || ['year', 'month', 'day']}
                  // variant='standard'
                  inputVariant={variant}
                  className={classes.textFieldStyle}
                  slotProps={{ textField: { variant: variant } }}
                  disabled={disabled}
                  loading={loading}
                  // selectedSections={'all'}
                  {...otherfields}
                  // onChange={onChange}
                  // value={value}
                  {...others}
                  fullWidth
                  TextFieldComponent={(params) => (
                    <TextField inputRef={ref} {...params} />
                  )}
                  InputLabelProps={{ shrink: true }}
                  invalidDateMessage='Fecha no válida'
                  // margin='dense'
                  // onChange={handleDateChange}
                  // inputRef={register({
                  //   required: errorMessage.message[name]
                  // })}
                  error={stateError}
                  helperText={stateMessage}
                />
              </LocalizationProvider>
            );
          }}
        />
        {stateError && (
          <FormHelperText className={classes.errorText} error>
            {stateMessage}
          </FormHelperText>
        )}
      </FormControl>
    </CustomInputContainer>
  );
};
export default TextFieldWidgetDateController2;
