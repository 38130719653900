import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Polygon,
  Circle,
  HeatMap,
  Polyline,
} from 'google-maps-react';
import CircularProgress from '@mui/material/CircularProgress';
import React, {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Backdrop, Button, useTheme, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { FiltersContext } from '../../../context';
import {
  dashDelete,
  getCoords,
  getCoordsByGeom,
  getValidPlant1ById,
  getValidPolygons,
  getValidPolygonsById,
  numberFormatDecimals,
} from '../../../helpers/functionHelpers';
import treeMarker from '../../../assets/planta1.png';

const useStyles = makeStyles((theme) => ({
  infoOrchardStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    padding: '8px 20px',
  },
  infoCuarterStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '& table td': {
      fontSize: 14,
    },
  },
  irButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  overlayButton: {
    position: 'fixed',
    top: '240px',
    right: '16px',
    [theme.breakpoints.up('lg')]: {
      right: 'calc(16px + 380px)',
    },
    zIndex: 979,
  },
  overlayButtonNoDrawer: {
    position: 'fixed',
    top: '240px',
    right: '16px',
    zIndex: 979,
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const moduleOrchardFilter = 'statusCounter';

const MapComponent = (props) => {
  const MapRef = useRef();
  // const PolygonRef = useRef();

  // const [getRef, setRef] = useDynamicRefs();
  const polygonRefs = useRef([]);

  let CircleRef = [];

  const IconTree = {
    url: treeMarker, // URL del ícono personalizado
    scaledSize: new window.google.maps.Size(30, 30), // Tamaño del ícono
    origin: new window.google.maps.Point(0, 0), // Origen del ícono
    anchor: new window.google.maps.Point(15, 30), // Punto de anclaje del ícono
  };

  const {
    buttonClick,
    quarterSelected,
    orchardHandler,
    isLgWindow,
    loadingState,
    plantsData,
    positionsHeat,
    idsWithData,
  } = props;

  const theme = useTheme();

  const classes = useStyles();

  let history = useHistory();

  let PolygonRef = [];

  // console.log(theme);
  const [mapState, setMapState] = useState(/** google.maps.Map */ null);

  const [imageBoundsState, setImageBoundsState] = useState(null);
  const [imageOverlayState, setImageOverlayState] = useState(null);

  const [showOverlayState, setShowOverlayState] = useState(false);
  const [overlayState, setOverlayState] = useState(null);

  const {
    state: {
      orchard_filter,
      orchard_id_filter,
      userOrchard_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showInfoWindowPolygon, setShowInfoWindowPolygon] = useState(false);

  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [activePolygonState, setActivePolygonState] = useState({});

  const [activeQuarterDataState, setActiveQuarterDataState] = useState(null);
  const [activeQuarterTooltipState, setActiveQuarterTooltipState] =
    useState(null);

  const [polygonFillColorState, setPolygonFillColorState] = useState([
    'rgba(255,255,255,0.1)',
    'rgba(255,255,255,0.4)',
    'rgba(253, 188, 33,1)',
    'rgba(253, 188, 33,0.8)',
    // rgb(253, 188, 33),
  ]);

  const [plant1State, setPlant1State] = useState(null);
  const [plant1QuarterState, setPlant1QuarterState] = useState(null);

  // estados Plantas
  const [layer2CirclesState, setLayer2CirclesState] = useState(false);
  const [activeCircleTooltipDataState, setActiveCircleTooltipDataState] =
    useState(null);
  const [showInfoWindowCircle, setShowInfoWindowCircle] = useState(false);
  const [activeCircleTooltipState, setActiveCircleTooltipState] = useState({});

  // HEAT
  const [positionsHeatState, setPositionsHeatState] = useState(null);

  const [activateHeatMapState, setActivateHeatMapState] = useState(false);

  const [quartersState, setQuartersState] = useState([]);

  const [mapInitialLocation, setMapInitialLocation] = useState({
    lat: -34.4142,
    lng: -70.7931,
  });

  const [initialZoom, setInitialZoom] = useState(15);

  const style = {
    width: '100%',
    height: '100%',
  };

  const onMarkerOver = (props, marker, e) => {
    // console.log('over marker', props, marker);
    // console.log(props.map.zoom, "<------el zoom")
    if (props.map.zoom < 14) {
      setSelectedPlace(props);
      setActiveMarker(marker);
      setShowInfoWindow(true);
    }
    // setSelectedPlace(props);
    // 	setActiveMarker(marker);
    // 	setShowInfoWindow(true);
  };

  const onMarkerClickZoom = (props, marker, e) => {
    // console.log('over marker', props, marker);
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  // console.log(isLgWindow, '<-----------------isLgWindow');

  const onMarkerClick = (marker) => {
    // console.log('clcik marker-----------', marker);
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
    // orchardHandler();
    // desde aquí va con tipo 1
    orchardHandler(marker._id, marker.value, 1);
  };

  // const setPolygonOptions = (options) => {
  // 	PolygonRef.current.polygon.setOptions(options);
  // };
  let cuarterRef;

  const onPolygonClick = (props, marker, e, quarter) => {
    setShowInfoWindow(false);

    // console.log(quarter, marker, '-----------------------click EN MAPA');
    setShowInfoWindowCircle(false);
    onPlantInfoWindowClose();

    // if (PolygonRef[0]) {
    //   for (let i = 0; i < quartersState.length; i++) {
    //     PolygonRef[i].polygon.setOptions({
    //       fillColor: polygonFillColorState[0],
    //     });
    //   }
    // }

    // console.log(quarter, '<-------------------llega el quarter');

    // marker.setOptions({ fillColor: polygonFillColorState[1] });

    let orchardMap = quarter.orchard ? quarter.orchard : null;

    let ccMap = quarter.cc ? quarter.cc : null;
    let cuarterMap = quarter.quarter ? quarter.quarter : null;

    let idUnitProductiveMap = quarter.idUnitProductive
      ? quarter.idUnitProductive
      : null;

    // ver si viene variety
    let varietyMap = quarter.variety ? quarter.variety : null;

    if (orchardMap) {
      const resultOrchard = userOrchard_filter.filter(
        (ele) => ele.value === orchardMap
      );

      // console.log(resultOrchard, '-----resultOrchard');

      addFilter({
        orchard_filter: orchardMap,
        orchard_id_filter: resultOrchard[0]?.id ? resultOrchard[0]?.id : '',
      });
    }

    if (ccMap) {
      addFilter({
        cc_filter: ccMap,
      });
    }

    if (cuarterMap) {
      addFilter({
        quarter_filter: cuarterMap,
      });
    } else {
      addFilter({
        quarter_filter: '',
      });
    }

    if (idUnitProductiveMap) {
      addFilter({
        idUnitProductive: idUnitProductiveMap,
      });
    } else {
      addFilter({
        idUnitProductive: '',
      });
    }

    if (varietyMap) {
      addFilter({
        variety_filter: varietyMap,
      });
    } else {
      addFilter({
        variety_filter: '',
      });
    }

    // console.log(e.latLng, "-------------latlng")
    // console.log('debe definir planta1 de este click', quarter);

    const plant1Quarter =
      plant1State && plant1State.length
        ? plant1State.filter(
            (item) => item.idUnitProductive === quarter.idUnitProductive
          )
        : [];

    // console.log(plant1Quarter, '----------------firstPlant1', plant1State);

    setPlant1QuarterState(plant1Quarter);

    setActivePolygonState(e.latLng);
    setActiveQuarterDataState(quarter);
    setShowInfoWindowPolygon(true);
    setActiveMarker({});
  };

  // const onPolygonOver = (props, marker, e, quarter) => {
  //   // console.log('-------------onOVer-------', quarter, props);

  //   let orchardMap = quarter.orchard ? quarter.orchard : null;

  //   let ccMap = quarter.cc ? quarter.cc : null;
  //   let cuarterMap = quarter.quarter ? quarter.quarter : null;

  //   // console.log(quarter, '----------quarter');

  //   if (!showInfoWindowPolygon) {
  //     setActiveQuarterTooltipState(quarter);
  //   }
  // };

  const onMapClicked = (props) => {
    if (showInfoWindowCircle) {
      setShowInfoWindowCircle(false);
      setActiveCircleTooltipDataState(null);
    }
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
    if (showInfoWindowPolygon) {
      for (let i = 0; i < polygonRefs.current.length; i++) {
        polygonRefs.current[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
      }
      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }
  };

  const onInfoWindowClose = (props) => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }

    if (showInfoWindowPolygon) {
      for (let i = 0; i < polygonRefs.current.length; i++) {
        polygonRefs.current[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
      }

      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }

    // console.log('se cerró');
  };

  const _onZoomChanged = (e) => {
    // console.log(e, MapRef.current.map.zoom);
    setInitialZoom(MapRef.current.map.zoom);
  };

  const _onCenterChanged = (mapProps, map) => {
    // console.log(mapProps, '...', map);
    // console.log(map.center.lat());
    // console.log(map.center.lng());
    // setInitialZoom(MapRef.current.map.zoom);
    // console.log("-------setMapInitialLocation1")
    setMapInitialLocation({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  useEffect(() => {
    if (orchard_id_filter) {
      const result = userOrchard_filter.filter(
        (ele) => ele.id === orchard_id_filter
      );
      // console.log(
      //   result,
      //   '----------------------------result orchard para actualizar mapa'
      // );
      const validPolygonOrchard = result[0].polygon
        ? getValidPolygonsById(result[0], specie_filter)
        : null;

      const validPlant1 = result[0].plant1
        ? getValidPlant1ById(result[0])
        : null;

      // console.log(
      //   validPolygonOrchard,
      //   '-----------------------------------validPoygons',
      //   validPlant1
      // );

      setQuartersState(validPolygonOrchard);

      setPlant1State(validPlant1);

      setInitialZoom(15);
      // console.log("setMapInitialLocation2")
      // setMapInitialLocation(centerOrchard);
    }
  }, [orchard_id_filter]);

  const closeAllWindows = () => {
    // console.log('cierra las ventanas');
    setShowInfoWindowCircle(false);
  };

  // HEATMAP
  const gradient = [
    'rgba(0, 0, 255, 0)', // Azul transparente
    'rgba(0, 0, 255, 0.5)', // Azul transparente
    'rgba(0, 0, 255, 1)', // Azul sólido
    'rgba(153, 204, 255,1)',
    'rgba(255, 255, 0, 1)', // Amarillo
    'rgba(255, 165, 0, 1)', // Naranja
    'rgba(255, 0, 0, 1)', // Rojo intenso
    'rgba(143, 0, 62,1)',
  ];

  useEffect(() => {
    // Verificar si la biblioteca de visualización ya está cargada
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.visualization
    ) {
      // La biblioteca de visualización está cargada
      // console.log('La biblioteca de visualización ya está cargada.');
      setActivateHeatMapState(true);
    } else {
      // La biblioteca de visualización no está cargada
      // console.log('La biblioteca de visualización no está cargada.');
    }
  }, [mapState, props, window]);

  useEffect(() => {
    setPositionsHeatState(null);
    if (positionsHeat) {
      setPositionsHeatState(positionsHeat);
    }
  }, [positionsHeat]);

  const layerHeatmap =
    positionsHeatState && positionsHeatState.length && activateHeatMapState
      ? positionsHeatState.map((heat, index) => {
          const intensity = 10;

          return (
            <HeatMap
              key={`heat_${index}`}
              positions={heat}
              dissipating={true} // Si deseas que el color se disipe gradualmente
              gradient={gradient} // Define el gradiente de colores
              // maxIntensity={intensity}
              opacity={1} // Define la opacidad del mapa de calor
              radius={60} // Define el tamaño del radio del punto de calor
              // Aquí puedes agregar más propiedades según tus necesidades
            />
          );
        })
      : null;

  // PLANTAS

  // console.log(plantsData, '-----------------plantsData en mapa');

  useEffect(() => {
    // console.log(plantsData, '-----------------plantsData en mapa useEffect');

    if (plantsData) {
      // console.log(plantsData, '-----------------plantsData');

      // hacer un flatten
      // todas las plantas al mismo nivel
      const flattenPlantsData = plantsData.reduce(
        (acc, val) => acc.concat(val),
        []
      );

      setLayer2CirclesState(flattenPlantsData);
    } else {
      setLayer2CirclesState(null);
    }
  }, [plantsData]);

  const onPlantInfoWindowClose = (props) => {
    // console.log('sale del circle');
    setActiveCircleTooltipDataState(null);
    setShowInfoWindowCircle(false);
  };

  const onCircleClick = (props, marker, e, circle) => {
    // console.log(props, marker, e, circle, '----circle click');
    setActiveCircleTooltipState(e.latLng);
    setActiveCircleTooltipDataState(circle);
    setShowInfoWindowCircle(true);
  };

  const layer2Circles =
    layer2CirclesState &&
    layer2CirclesState.length > 0 &&
    layer2CirclesState.map((circle, index) => {
      // console.log(circle, '--------------------------------circle');
      if (circle?.geom?.coordinates.length) {
        if (
          !circle.geom.coordinates[0].includes(null) &&
          circle.geom.coordinates[0]
        ) {
          return (
            <Circle
              key={`circ_${index}`}
              radius={1.5}
              title={circle.plantNumber}
              // onClick={(props, marker, e) =>
              //   onCircleClick(props, marker, e, circle)
              // }
              onMouseover={(props, marker, e) =>
                onCircleClick(props, marker, e, circle)
              }
              onMouseout={() => onPlantInfoWindowClose()}
              // ref={setRef(`cuarter_${index}`)}
              ref={(ref) => (CircleRef[index] = ref)}
              center={getCoords(circle.geom.coordinates)[0]}
              // center={circle}
              strokeColor='transparent'
              strokeOpacity={0}
              strokeWeight={0}
              // fillColor={quarter.color}
              fillOpacity={1}
              fillColor={'rgba(255,255,255,0.7)'}
              zIndex={100 + index}
            />
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    });

  const polys =
    quartersState &&
    quartersState.map((quarter, index) => {
      // console.log(quartersState, '----------------quartersState');
      // console.log('dibuja los polys');
      if (quarter.geom.coordinates.length) {
        // uniqRender.current = true;

        return (
          <Polygon
            key={`${quarter.centro_costo}_${index}`}
            ref={(ref) => {
              if (ref && polygonRefs.current.length < quartersState.length) {
                polygonRefs.current[index] = ref;
              }
            }}
            paths={getCoordsByGeom(quarter.geom)}
            onClick={(props, marker, e) =>
              onPolygonClick(props, marker, e, quarter)
            }
            // onMouseover={(props, marker, e) =>
            //   !activeQuarterDataState && quarter.geom.type !== 'MultiPolygon'
            //     ? onPolygonOver(props, marker, e, quarter)
            //     : {}
            // }
            // id={`${quarter.orchard}_${quarter.cc}_${quarter.quarter}`}
            id={`${quarter.idUnitProductive}`}
            // onMouseout={closeToolTip}
            strokeColor='#FFFFFF'
            strokeOpacity={1}
            strokeWeight={2}
            // fillColor={quarter.color}
            fillOpacity={1}
            options={{
              fillColor: polygonFillColorState[0],
            }}
          />
        );
      } else {
        return null;
      }
    });

  const _mapLoaded = (mapProps, map) => {
    const { google } = mapProps;
    setMapState(map);
    map.setOptions({
      mapTypeId: 'hybrid',
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      fullscreenControl: true,
      disableDefaultUI: false,
    });
  };

  useEffect(() => {
    // console.log(buttonClick, '<----------buttonClick--------');

    const result = userOrchard_filter.filter(
      (ele) => ele.value === orchard_filter
    );

    const centerOrchardMark = result[0]?.location;

    if (result && result.length) {
      const bounds = new props.google.maps.LatLngBounds();

      const centerOrchardByPolygon = result[0].polygon
        ? getValidPolygonsById(result[0], specie_filter)
        : null;

      // const validPolygonOrchard = result[0].polygon
      //   ? getValidPolygonsById(result[0], specie_filter)
      //   : null;

      if (centerOrchardByPolygon && centerOrchardByPolygon.length) {
        let arrayCoords = [];

        centerOrchardByPolygon.forEach((polygon) => {
          arrayCoords.push(getCoordsByGeom(polygon.geom));
        });

        const flattenArray = _.flatten(arrayCoords);

        const flattenedArray = flattenArray.reduce(
          (acc, val) => acc.concat(val),
          []
        );

        flattenedArray.forEach((point) => {
          bounds.extend(new props.google.maps.LatLng(point.lat, point.lng));
        });

        if (mapState && bounds) {
          const padding = 0; // ajusta sin padding el mapa

          mapState.fitBounds(bounds, padding);
          console.log(
            'dentro de map-------CENTRA POR BOUND',
            bounds.getCenter()
          );
        }
      } else if (
        centerOrchardMark &&
        centerOrchardMark.lat &&
        centerOrchardMark.lng
      ) {
        // console.log(centerOrchardMark, '--------centerOrchardMark');

        setMapInitialLocation(centerOrchardMark);
        setInitialZoom(15);
      } else {
        console.log('no tiene nada');
      }
    }
  }, [buttonClick, mapState]);

  // console.log(orchard_filter, orchard_id_filter, '<<<<<<<<<');

  const onInfoWindowOpen = (props, e) => {};

  useEffect(() => {
    // como hay un cuarter seleccionado desde el panel, lo pinta en el mapa
    // Ahora lo hace por el idUnitProductive
    if (quarterSelected) {
      if (showInfoWindow) {
        setShowInfoWindow(false);
      }
      if (showInfoWindowPolygon) {
        setShowInfoWindowPolygon(false);
      }

      // console.log(
      //   quarterSelected,
      //   '<----------------------------quarterSelected',
      //   quartersState
      // );

      const getIdUnitProductives = (obj) => {
        let ids = [];
        if (obj?.variety) {
          obj.variety.forEach((item) => {
            if (item.idUnitProductive) {
              ids.push(item.idUnitProductive);
            }
          });
        }
        return ids;
      };

      // console.log(
      //   getIdUnitProductives(quarterSelected.quarter),
      //   '----ids del Cuartel'
      // );

      // console.log(quarterSelected, '----quarterSelected');

      const idUnitArrayFromQuarter = getIdUnitProductives(
        quarterSelected.quarter
      );

      // console.log(
      //   quartersState,
      //   '----------------------------quartersState en polygon color',
      //   idUnitArrayFromQuarter,
      //   quarterSelected
      // );

      const plant1Quarter =
        plant1State && plant1State.length
          ? plant1State.filter((item) =>
              idUnitArrayFromQuarter.includes(item.idUnitProductive)
            )
          : [];

      setPlant1QuarterState(plant1Quarter);

      // find in quartersState the idUnitProductive
      const findFirstIdUnitProductive = (data, target) => {
        const targetId = target.variety[0].idUnitProductive;
        return data.find((item) => item.idUnitProductive === targetId) || null;
      };

      // TODO: falta seleccionar el cuartel en el mapa
      setActiveQuarterDataState(
        findFirstIdUnitProductive(quartersState, quarterSelected.quarter)
      );
    }
  }, [quarterSelected]);

  useEffect(() => {
    if (activeQuarterDataState) {
      // console.log(
      //   activeQuarterDataState,
      //   '----------------activeQuarterDataState',
      //   activeQuarterDataState.idUnitProductive
      // );

      for (let i = 0; i < polygonRefs.current.length; i++) {
        polygonRefs.current[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
        if (
          polygonRefs.current[i].props.id ===
          activeQuarterDataState.idUnitProductive
        ) {
          // cambiar el fillColor
          polygonRefs.current[i].polygon.setOptions({
            fillColor: polygonFillColorState[1],
          });
        }
      }
    }
  }, [activeQuarterDataState, polys]);

  useEffect(() => {
    if (idsWithData) {
      for (let i = 0; i < polygonRefs.current.length; i++) {
        if (!idsWithData.includes(polygonRefs.current[i].props.id)) {
          // cambiar el fillColor
          polygonRefs.current[i].polygon.setOptions({
            strokeColor: polygonFillColorState[2],
            strokeWeight: 6,
            // fillColor: polygonFillColorState[3],
          });
        } else {
          polygonRefs.current[i].polygon.setOptions({
            strokeColor: '#FFFFFF',
            strokeWeight: 2,
            // fillColor: polygonFillColorState[0],
          });
        }
      }
    }
  }, [idsWithData, polys]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {loadingState ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 979,
            backgroundColor: 'rgba(255,255,255,0)',
          }}
        >
          <CircularProgress style={{ color: 'white' }} />
        </div>
      ) : null}
      <Map
        style={style}
        google={props.google}
        onClick={onMapClicked}
        onZoomChanged={_onZoomChanged}
        onDragend={_onCenterChanged}
        // onCenterChanged={_onCenterChanged}
        initialCenter={mapInitialLocation}
        center={mapInitialLocation}
        zoom={initialZoom}
        ref={MapRef}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      >
        {
          // debe marcar mis orchards
          // marca solo los orchardStatus = true
          userOrchard_filter
            .filter((ele) => ele[moduleOrchardFilter] === true)
            .map((marker) => {
              if (marker.location) {
                return (
                  <Marker
                    key={marker._id}
                    name={marker.label}
                    onClick={
                      mapState && mapState.zoom < 14
                        ? () => onMarkerClick(marker)
                        : (props, marker) => onMarkerClickZoom(props, marker)
                    }
                    onMouseover={
                      mapState && mapState.zoom < 14 ? onMarkerOver : null
                    }
                    position={marker.location}
                  />
                );
              } else {
                return null;
              }
            })
        }
        {activeCircleTooltipDataState && (
          <InfoWindow
            position={activeCircleTooltipState}
            visible={showInfoWindowCircle}
            onClose={onPlantInfoWindowClose}
          >
            <div className={classes.infoCuarterStyle}>
              <table className='table table-sm table-borderless'>
                <tr>
                  <td>Hilera / Nº Planta:</td>
                  <td style={{ fontWeight: 700 }}>
                    {activeCircleTooltipDataState?.rowNumber || '-'} |{' '}
                    {activeCircleTooltipDataState?.plantNumber || '-'}
                  </td>
                </tr>
                <tr>
                  <td>Tipo Conteo:</td>
                  <td style={{ fontWeight: 700 }}>
                    {dashDelete(activeCircleTooltipDataState?.counterType) ||
                      '-'}
                  </td>
                </tr>
                {activeCircleTooltipDataState?.counter ===
                'Conteo_Centro_Frutal' ? (
                  <>
                    <tr>
                      <td>Dardos:</td>
                      <td style={{ fontWeight: 700 }}>
                        {activeCircleTooltipDataState?.dart || '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Ramillas:</td>
                      <td style={{ fontWeight: 700 }}>
                        {activeCircleTooltipDataState?.twig || '-'}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>Frutos:</td>
                    <td style={{ fontWeight: 700 }}>
                      {activeCircleTooltipDataState?.totalCounts
                        ? numberFormatDecimals(
                            activeCircleTooltipDataState?.totalCounts,
                            1
                          )
                        : '-'}
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </InfoWindow>
        )}
        {plant1QuarterState &&
          plant1QuarterState.length &&
          plant1QuarterState.map((marker) => {
            if (marker.geom) {
              return (
                <Marker
                  key={marker.idUnitProductive}
                  name={marker.idUnitProductive}
                  position={getCoords([marker.geom.coordinates])[0]}
                  icon={IconTree}
                />
              );
            } else {
              return null;
            }
          })}
        <InfoWindow
          marker={activeMarker}
          visible={showInfoWindow}
          onClose={onInfoWindowClose}
          // style={styles.infoWindow}
        >
          <div className={classes.infoOrchardStyle}>
            <h5>{selectedPlace.name}</h5>
          </div>
        </InfoWindow>
        {polys}
        {layerHeatmap}
        {layer2Circles}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  language: 'es',
  // apiKey: 'AIzaSyCgDjp2D44L-NQ5okVOSlCXmBwtkScGmLM',
  apiKey: 'AIzaSyBnXQDrdvbXPg0GjUdQzC_-v0ZMa3q5U9k',
  libraries: ['visualization'],
})(MapComponent);
