import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { numberFormat } from '../../../helpers';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	kpiTextStyle: {
		color: theme.palette.primary.main,
		fontWeight: 600,
		fontSize: 42,
		textAlign: 'center',
	},
	subtitleDash: {
		fontSize: 18,
	},
	successText: {
		textAlign: 'right',
		color: theme.palette.success.main,
		fontSize: 15,
		'& span': {
			fontSize: 22,
			fontWeight: 700,
		},
	},
}));

const HarvestReport = ({ title = '', value = 0 }) => {
	const classes = useStyles();
	return (
		<>
			<Typography
				variant="subtitle1"
				className={classes.subtitleDash}
				// gutterBottom
			>
				{title}
			</Typography>
			{/* <Typography variant="body2" gutterBottom>
				05/08/2022
			</Typography> */}
			<Typography
				variant="h1"
				className={classes.kpiTextStyle}
				gutterBottom
			>
				{numberFormat(value)}
			</Typography>
		</>
	);
};

export default HarvestReport;
