// import _ from "lodash";
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { BsRow, BsCol } from '../../../layouts/components';
import { CardPaper } from '../../../components';
import { Context } from '../../../context/Context';
import { OptionsContext } from '../../../context';
import GraphPrecipitation from '../GraphPrecipitation';
import GraphColdHours from '../GraphColdHours';
import GraphColdPortions from '../GraphColdPortions';
import GraphDegreeDays from '../GraphDegreeDays';
import WeatherParameters from '../WeatherParameters';

const ResumeOrchard = ({ filter }) => {
  const theme = useTheme();

  const { userOrchard } = useContext(Context);

  const {
    state: { weather_resume },
    addOptions,
  } = useContext(OptionsContext);

  const [groupGraphsState, setGroupGraphsState] = useState(
    weather_resume || {
      precipitation: true,
      chillHour: true,
      coldPortion: true,
      degreeDays: true,
    }
  );

  const handleChangeCols = (event) => {
    setGroupGraphsState({
      ...groupGraphsState,
      [event.target.name]: event.target.checked,
    });

    addOptions({
      weather_resume: {
        ...groupGraphsState,
        [event.target.name]: event.target.checked,
      },
    });
  };

  return (
    <>
      <BsRow class='gutter_10'>
        <BsCol class='col-md-12'>
          <Box
            sx={{
              px: 2,
            }}
          >
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox name='precipitation' size='small' />}
                checked={groupGraphsState.precipitation}
                onChange={handleChangeCols}
                label='Precipitación'
                componentsProps={{
                  typography: { variant: 'body2' },
                }}
                sx={{
                  borderColor: theme.palette.primary.main,
                  border: 1,
                  p: 0.5,
                  height: 35,
                  borderRadius: theme.shape.borderRadius + 'px',
                  '& .MuiCheckbox-root': {
                    p: 0,
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox name='chillHour' size='small' />}
                checked={groupGraphsState.chillHour}
                onChange={handleChangeCols}
                componentsProps={{
                  typography: { variant: 'body2' },
                }}
                label='Hora Frío'
                sx={{
                  borderColor: theme.palette.primary.main,
                  border: 1,
                  p: 0.5,
                  height: 35,
                  borderRadius: theme.shape.borderRadius + 'px',
                  '& .MuiCheckbox-root': {
                    p: 0,
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox name='coldPortion' size='small' />}
                checked={groupGraphsState.coldPortion}
                onChange={handleChangeCols}
                label='Porción Frío'
                componentsProps={{
                  typography: { variant: 'body2' },
                }}
                sx={{
                  borderColor: theme.palette.primary.main,
                  border: 1,
                  p: 0.5,
                  height: 35,
                  borderRadius: theme.shape.borderRadius + 'px',
                  '& .MuiCheckbox-root': {
                    p: 0,
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox name='degreeDays' size='small' />}
                checked={groupGraphsState.degreeDays}
                onChange={handleChangeCols}
                label='Grados Día'
                componentsProps={{
                  typography: { variant: 'body2' },
                }}
                sx={{
                  borderColor: theme.palette.primary.main,
                  border: 1,
                  p: 0.5,
                  height: 35,
                  borderRadius: theme.shape.borderRadius + 'px',
                  '& .MuiCheckbox-root': {
                    p: 0,
                  },
                }}
              />
            </FormGroup>
          </Box>
        </BsCol>
        {groupGraphsState.precipitation ? (
          <BsCol class='col-md-6 mb-2'>
            <CardPaper
              variant='outlined'
              sx={{
                borderColor: theme.palette.grey[500],
              }}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                <GraphPrecipitation orchards={filter} />
              </Box>
            </CardPaper>
          </BsCol>
        ) : null}
        {groupGraphsState.chillHour ? (
          <BsCol class='col-md-6 mb-2'>
            <CardPaper
              variant='outlined'
              sx={{
                borderColor: theme.palette.grey[500],
              }}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                <GraphColdHours orchards={filter} />
              </Box>
            </CardPaper>
          </BsCol>
        ) : null}
        {groupGraphsState.coldPortion ? (
          <BsCol class='col-md-6 mb-2'>
            <CardPaper
              variant='outlined'
              sx={{
                borderColor: theme.palette.grey[500],
              }}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                <GraphColdPortions orchards={filter} />
              </Box>
            </CardPaper>
          </BsCol>
        ) : null}
        {groupGraphsState.degreeDays ? (
          <BsCol class='col-md-6 mb-2'>
            <CardPaper
              variant='outlined'
              sx={{
                borderColor: theme.palette.grey[500],
              }}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                <GraphDegreeDays orchards={filter} />
              </Box>
            </CardPaper>
          </BsCol>
        ) : null}
      </BsRow>
      <BsRow class=''>
        <BsCol class='col-md-12 mt-3'>
          <WeatherParameters orchards={filter} />
        </BsCol>
      </BsRow>
    </>
  );
};

export default ResumeOrchard;
