import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  IconButton,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import { AxiosContext } from '../../../context/AxiosContext';
import { SaveAction, TextFieldWidgetDateController } from '../../../components';
import CjIcon from '../../../assets/icons/schedule/cj_icon.png';
import MexicoIcon from '../../../assets/icons/schedule/mexico_icon.png';
import QuarantineIcon from '../../../assets/icons/schedule/quarantine_icon.png';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import clsx from 'clsx';
import { dateFormatIso, dateFrontConvert } from '../../../helpers';
import { register } from 'serviceWorker';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 7),
    width: '50%',
    height: '45vh',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  tableForm: {
    '& td': {
      width: '33%',
    },
  },
}));

const ModalHistory = ({ closeAction, dataModal }) => {
  const theme = useTheme();

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const [loadingState, setLoadingState] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const closeHandler = () => {
    setSuccessState(false);
    closeAction();
  };

  console.log(dataModal, 'dataModal');

  // ordenar dataModal.history por fecha
  dataModal.history.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-center'>
              <Typography variant='h5' gutterBottom>
                Historial Certificación - Huerto: {dataModal.orchard} -
                Variedad: {dataModal.variety}
              </Typography>
              <BsRow class='mt-2'>
                <BsCol class='col-md-12'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        {/* <th>Variedad</th> */}
                        <th>Certificación</th>
                        <th>Acción</th>
                        <th>Fecha</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataModal.history.map((register) => {
                        return (
                          <tr key={register.id}>
                            {/* <td>{dataModal.variety}</td> */}
                            <td>{register.certificate}</td>
                            <td>
                              <>
                                {register.type === 'add' ? (
                                  <CheckBoxIcon style={{ color: 'green' }} />
                                ) : (
                                  <DisabledByDefaultIcon
                                    style={{ color: 'red' }}
                                  />
                                )}
                              </>
                              {register.type === 'add'
                                ? ' Agregada'
                                : ' Eliminada'}
                            </td>
                            <td>{dateFrontConvert(register.date)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalHistory;
