// Importaciones de librerías
import _ from 'lodash';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';

import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Importaciones absolutas
// (No hay ninguna en tu ejemplo, pero las colocarías aquí)

// Importaciones relativas
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { Context } from '../../context/Context';
import { FiltersContext } from '../../context';
import { ButtonAction, CardPaper, HighchartsWrapper } from '../../components';
import iconJson from '../../common/iconSVG.json';
import { numberFormatDecimals } from 'helpers';
import clsx from 'clsx';

require('highcharts/modules/exporting')(Highcharts);

Highcharts.setOptions({
  global: {
    useUTC: false,
  },
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
  },
});

const useStyles = makeStyles((theme) => ({
  theadLightSmall: {
    '& thead tr:nth-child(2) th': {
      backgroundColor: 'white',
    },
    '& th': {
      // color: '#495057',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
}));

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'downloadPNG',
          'downloadSVG',
          'downloadPDF',
          'downloadXLS',
        ],
      },
    },
    enabled: true,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 580,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const HomeMenu = () => {
  const theme = useTheme();

  const classes = useStyles();

  const { moduleUser, userOrchard, userClient } = useContext(Context);

  const chartRef = useRef(null);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);

  const hectareChart = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'bar',
      height: 350,
      spacingBottom: 10,
      spacingLeft: 10,
      spacingRight: 10,
      spacingTop: 10,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ['Huerto A', 'Huerto B', 'Huerto C', 'Huerto D'],
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      // valueSuffix: ' Ha',
      pointFormat: '{series.name}: <b>{point.y:,.1f} Ha.</b><br/>',
      // pointFormat: '<b>Superficie:' + numberFormatDecimals(point.x) + '</b>',
      // formatter: function () {
      //   return '<b>Superficie:' + numberFormatDecimals(this.y, 0) + ' ha</b>';
      // },
      // valueDecimals: 1,
      // valueThousandsSep: '.',
    },
    series: [
      {
        name: 'Hectáreas',
        data: [500, 1234, 700, 100], // Datos de cantidad de hectáreas por huerto
      },
    ],
  };

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  return (
    <div style={{ marginTop: theme.spacing(2) }}>
      <BsContainer fluid class=''>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <BsRow class='gutter_10'>
              <BsCol class='col-md-12'>
                <div>
                  <CardPaper
                    elevation={0}
                    topColor=''
                    borderTop={0}
                    sx={{
                      backgroundColor: theme.palette.grey[50],
                      py: 2,
                    }}
                  >
                    <Grid
                      container
                      columnSpacing={{ xs: 1.4 }}
                      sx={{ mx: 0 + ' !important' }}
                      columns={{ xs: 4, lg: 6 }}
                    >
                      <Grid item xs={2} lg={1}>
                        <CardPaper
                          elevation={1}
                          topColor=''
                          borderTop={0}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                          onClick={() => console.log('click')}
                        >
                          <SvgIcon
                            sx={{
                              width: 38,
                              height: 38,
                              mb: 0,
                            }}
                            viewBox='0 0 18 18'
                            color='#A84448'
                            htmlColor='#A84448'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d={iconJson.icon.orchard}
                            />
                          </SvgIcon>
                          <Typography variant='body2' sx={{ fontWeight: 700 }}>
                            Huerto
                          </Typography>
                        </CardPaper>
                      </Grid>
                      <Grid item xs={2} lg={1}>
                        <CardPaper
                          elevation={1}
                          topColor=''
                          borderTop={0}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                          onClick={() => console.log('click')}
                        >
                          <SvgIcon
                            sx={{
                              width: 38,
                              height: 38,
                              mb: 0,
                            }}
                            viewBox='0 0 18 18'
                            color='#81B214'
                            htmlColor='#81B214'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d={iconJson.icon.intelligence}
                            />
                          </SvgIcon>
                          <Typography variant='body2' sx={{ fontWeight: 700 }}>
                            Poda
                          </Typography>
                        </CardPaper>
                      </Grid>
                      <Grid item xs={2} lg={1}>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi voluptas est
                        </p>
                      </Grid>
                      <Grid item xs={2} lg={1}>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi voluptas est
                        </p>
                      </Grid>
                      <Grid item xs={2} lg={1}>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi voluptas est
                        </p>
                      </Grid>
                      <Grid item xs={2} lg={1}>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi voluptas est
                        </p>
                      </Grid>
                    </Grid>
                  </CardPaper>
                </div>
              </BsCol>
              <BsCol class='col-md-12 mt-3'>
                <Typography gutterBottom variant='h4' component='h2'>
                  Unidades Productivas
                </Typography>
              </BsCol>
              <BsCol class='col-md-3 col-6'>
                <CardPaper
                  elevation={0}
                  topColor=''
                  borderTop={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: theme.palette.boxes.main,
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: 38,
                      height: 38,
                      mb: 0,
                    }}
                    viewBox='0 0 18 18'
                    color='#FFFFFF'
                    htmlColor='#FFFFFF'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d={iconJson.icon.orchard}
                    />
                  </SvgIcon>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    Huertos
                  </Typography>
                  <Typography
                    variant='h4'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    200
                  </Typography>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-3 col-6'>
                <CardPaper
                  elevation={0}
                  topColor=''
                  borderTop={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: theme.palette.boxes.light,
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: 40,
                      height: 35,
                      mb: 0,
                    }}
                    viewBox='0 0 40 43'
                    color='#FFFFFF'
                    htmlColor='#FFFFFF'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d={iconJson.icon.hectare}
                    />
                  </SvgIcon>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    Hectáreas
                  </Typography>
                  <Typography
                    variant='h4'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    200
                  </Typography>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-3 col-6'>
                <CardPaper
                  elevation={0}
                  topColor=''
                  borderTop={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: theme.palette.boxes.lighter,
                    py: 2,
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: 40,
                      height: 35,
                      mb: 0,
                    }}
                    viewBox='0 0 40 43'
                    color='#FFFFFF'
                    htmlColor='#FFFFFF'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d={iconJson.icon.tons}
                    />
                  </SvgIcon>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    Toneladas Totales
                  </Typography>
                  <div className='mt-2' style={{ width: '90%' }}>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Grid item xs={6}>
                        <Typography
                          variant='h5'
                          sx={{ color: 'white' }}
                          className='text-center'
                        >
                          {new Date().getFullYear() - 1}
                        </Typography>
                        <Typography
                          variant='h4'
                          className='text-center'
                          sx={{ fontWeight: 700, color: 'white' }}
                        >
                          200
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='h5'
                          className='text-center'
                          sx={{ color: 'white' }}
                        >
                          A la fecha
                        </Typography>
                        <Typography
                          variant='h4'
                          className='text-center'
                          sx={{ fontWeight: 700, color: 'white' }}
                        >
                          200
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-3 col-6'>
                <CardPaper
                  elevation={0}
                  topColor=''
                  borderTop={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: theme.palette.boxes.dark,
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: 40,
                      height: 35,
                      mb: 0,
                    }}
                    viewBox='0 0 40 43'
                    color='#FFFFFF'
                    htmlColor='#FFFFFF'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d={iconJson.icon.users}
                    />
                  </SvgIcon>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    Cantidad de Usuarios
                  </Typography>
                  <Typography
                    variant='h4'
                    sx={{ fontWeight: 700, color: 'white' }}
                  >
                    200
                  </Typography>
                </CardPaper>
              </BsCol>
            </BsRow>
            <BsRow class='gutter_10 mt-3'>
              <BsCol class='col-md-8'>
                <CardPaper
                  variant='outlined'
                  sx={{
                    borderColor: theme.palette.grey[500],
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      // backgroundColor: theme.palette.grey[50],
                    }}
                  >
                    <HighchartsWrapper
                      fullScreenHandler={openFullscreen}
                      title='Hectáreas por Huerto'
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={hectareChart}
                        ref={chartRef}
                      />
                    </HighchartsWrapper>
                  </Box>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-4'>
                <CardPaper
                  elevation={0}
                  topColor=''
                  borderTop={0}
                  sx={{
                    height: '100%',
                    backgroundColor: theme.palette.grey[50],
                    py: 3,
                    px: 2,
                  }}
                >
                  <Typography gutterBottom variant='h5'>
                    Usabilidad
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CardPaper variant='outlined'>
                        <Typography gutterBottom variant='h5'>
                          Conexiones al año
                        </Typography>
                      </CardPaper>
                    </Grid>
                    <Grid item xs={6}>
                      <CardPaper variant='outlined'>
                        <Typography gutterBottom variant='h5'>
                          Cantidad Usuarios
                        </Typography>
                      </CardPaper>
                    </Grid>
                    <Grid item xs={12}>
                      <CardPaper variant='outlined'>
                        <Typography gutterBottom variant='h5'>
                          Módulos más usados
                        </Typography>
                      </CardPaper>
                    </Grid>
                  </Grid>
                </CardPaper>
              </BsCol>
            </BsRow>
            <BsRow class='gutter_10 mt-3'>
              <BsCol class='col-md-12'>
                <div
                  className='table-responsive px-3 pt-4'
                  style={{
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant='h5'
                        gutterBottom
                        component='h2'
                        sx={{ fontWeight: 700 }}
                      >
                        Productividad por Huerto (KG Totales)
                      </Typography>
                      <ButtonAction
                        size='small'
                        labelButton='Ir al Gráfico'
                        actionOnClick={() => {}}
                      />
                    </div>
                    <table
                      className={clsx(
                        'table table-sm',
                        classes.theadLightSmall
                      )}
                    >
                      <thead>
                        <tr>
                          <th scope='col' className='text-center'>
                            Huertos
                          </th>
                          <th scope='col' className='text-center'>
                            Kilos 2021
                          </th>
                          <th scope='col' className='text-center'>
                            Kilos 2022
                          </th>
                          <th scope='col' className='text-center'>
                            Kilos 2023
                          </th>
                          <th scope='col' className='text-center'>
                            Kilos 2024
                          </th>
                        </tr>
                      </thead>
                      <tbody className={classes.tbodyStyle}>
                        <tr>
                          <td className='text-center'>Huerto A</td>
                          <td className='text-center'>500</td>
                          <td className='text-center'>500</td>
                          <td className='text-center'>500</td>
                          <td className='text-center'>500</td>
                        </tr>
                        <tr>
                          <td className='text-center'>Huerto A</td>
                          <td className='text-center'>500</td>
                          <td className='text-center'>500</td>
                          <td className='text-center'>500</td>
                          <td className='text-center'>500</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                </div>
              </BsCol>
            </BsRow>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default HomeMenu;
