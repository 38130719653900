import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IsDesktopHandler } from 'helpers';

const MobileFilters = ({ children }) => {
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Filtros</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  } else {
    return children;
  }
};

export default MobileFilters;
