import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import DialpadIcon from '@mui/icons-material/Dialpad';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactsIcon from '@mui/icons-material/Contacts';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NatureIcon from '@mui/icons-material/Nature';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import GrainIcon from '@mui/icons-material/Grain';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import CompareIcon from '@mui/icons-material/Compare';
import GridViewIcon from '@mui/icons-material/GridView';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import PageviewIcon from '@mui/icons-material/Pageview';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PaymentIcon from '@mui/icons-material/Payment';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined';

export { default as Main } from './Main';
export { default as MainV2 } from './MainV2';
export { default as MainV3 } from './MainV3';
export { default as MainNoFooter } from './MainNoFooter';
export { default as Minimal } from './Minimal';
export { default as MinimalNoTopbar } from './MinimalNoTopbar';
export { default as MinimalBackButton } from './MinimalBackButton';

export const renderMaterialIcon = (icon) => {
  switch (icon) {
    case 'SettingsIcon':
      return <SettingsIcon />;

    case 'DashboardIcon':
      return <DashboardIcon />;

    case 'PersonIcon':
      return <PersonIcon />;

    case 'AssignmentIndIcon':
      return <AssignmentIndIcon />;

    case 'EventIcon':
      return <EventIcon />;

    case 'DialpadIcon':
      return <DialpadIcon />;

    case 'ContactsIcon':
      return <ContactsIcon />;

    case 'NatureIcon':
      return <NatureIcon />;

    case 'HowToRegIcon':
      return <HowToRegIcon />;

    case 'AddIcon':
      return <AddIcon />;

    case 'ListIcon':
      return <ListIcon />;

    case 'WbSunnyIcon':
      return <WbSunnyIcon />;

    case 'InsertChartIcon':
      return <InsertChartIcon />;

    case 'CenterFocusWeakIcon':
      return <CenterFocusWeakIcon />;

    case 'GrainIcon':
      return <GrainIcon />;

    case 'TroubleshootIcon':
      return <TroubleshootIcon />;

    case 'ContentPasteSearchIcon':
      return <ContentPasteSearchIcon />;

    case 'FactCheckIcon':
      return <FactCheckIcon />;

    case 'CompareIcon':
      return <CompareIcon />;

    case 'GridViewIcon':
      return <GridViewIcon />;

    case 'LeaderboardIcon':
      return <LeaderboardIcon />;

    case 'AcUnitIcon':
      return <AcUnitIcon />;

    case 'PageviewIcon':
      return <PageviewIcon />;

    case 'ShoppingBasketIcon':
      return <ShoppingBasketIcon />;

    case 'ManageAccountsIcon':
      return <ManageAccountsIcon />;

    case 'SummarizeIcon':
      return <SummarizeIcon />;

    case 'PaymentIcon':
      return <PaymentIcon />;

    case 'QrCodeScannerIcon':
      return <QrCodeScannerIcon />;

    case 'CalendarMonthIcon':
      return <CalendarMonthIcon />;

    case 'ChecklistRtlIcon':
      return <ChecklistRtlIcon />;

    case 'AssessmentIcon':
      return <AssessmentIcon />;

    case 'GroupWorkIcon':
      return <GroupWorkIcon />;

    case 'RecentActorsIcon':
      return <RecentActorsIcon />;

    case 'HomeOutlinedIcon':
      return <HomeOutlinedIcon />;

    case 'SettingsOutlinedIcon':
      return <SettingsOutlinedIcon />;

    case 'FactoryOutlinedIcon':
      return <FactoryOutlinedIcon />;

    case 'ForestOutlinedIcon':
      return <ForestOutlinedIcon />;

    default:
      break;
  }
};
