import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useErrorHandler } from 'react-error-boundary';
import { subDays, addDays } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import { FiltersContext } from '../../../context';
import { ListTable } from '../../../containers/Scheduling';
import {
  SaveAction,
  SelectMultipleWidget,
  SelectMultipleWidget2,
  TextFieldWidgetDateController,
  TextFieldWidgetDateController2,
  TextFieldWidgetSelectController,
} from '../../../components';
import {
  alphabeticalSort,
  alterArrayForSelectVariety,
  alterVarietiesForSelect,
  dateFormatIso,
  getOrchardVarietiesDeep,
  getUniqueValuesMaster,
} from '../../../helpers';
import { MobileFilters } from 'containers/Library';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const endPointFilters = 'v1/libraryExternal/specie';

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((item) => {
    item.specie.forEach((specie) => {
      specie.productLine.forEach((productLine) => {
        productLine.variety.forEach((variety) => {
          result.push({
            orchard: {
              label: item.label,
              value: item.value,
            },
            agroBusiness: {
              label: item.agroBusiness,
              value: item.agroBusinessValue,
            },
            group: {
              label: item.group,
              value: item.groupValue,
            },
            technicBoss: {
              label: item.technicBoss,
              value: item.technicBossValue,
            },
            specie: {
              label: specie.label,
              value: specie.value,
            },
            productLine: {
              label: productLine.label,
              value: productLine.value,
            },
            variety: {
              label: variety.label,
              value: variety.value,
            },
          });
        });
      });
    });
  });

  return result;
}

const ListSchedule = (props) => {
  const { userId, userOrchard, userName, moduleUser, userClient } =
    useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  // console.log(moduleUser, '------moduleUser');

  const errorHandler = useErrorHandler();

  const {
    orchard_filter,
    orchard_id_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    rootstock_filter,
    season_filter,
    userOrchard_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const moduleOrchardFilter = 'statusOrchardPlanning';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // const [filterOrchardState, setFilterOrchardState] = useState(
  //   userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  // );

  const [filterOrchardState, setFilterOrchardState] = useState([]);

  const [fullDataOrchardState, setFullDataOrchardState] = useState([]);

  const [dataFilterState, setDataFilterState] = useState([]);

  const [specieDataState, setSpecieDataState] = useState([]);

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [agroBusinessOptionsState, setAgroBusinessOptionsState] = useState([]);

  const [specieOptionsState, setSpecieOptionsState] = useState([]);

  const [varietyOptionsState, setVarietyOptionsState] = useState([]);

  const [filterDataTechnicBossState, setFilterDataTechnicBossState] = useState(
    []
  );

  const [filterDataGroupState, setFilterDataGroupState] = useState([]);

  const [productionLineDataState, setProductionLineDataState] = useState([]);

  const [filterDataAgroBusinessState, setFilterDataAgroBusinessState] =
    useState([]);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [garces2State, setGarces2State] = useState(false);

  const actualYear = new Date().getFullYear();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const theme = useTheme();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);

  const [loadingVarietiesState, setLoadingVarietiesState] = useState(false);

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useAgroBusiness = useWatch({
    control,
    name: 'agroBusiness',
  });

  const useSpecie = useWatch({
    control,
    name: 'specie',
  });

  const useProductionLine = useWatch({
    control,
    name: 'productionLine',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const useTechnicBoss = useWatch({
    control,
    name: 'technicBoss',
  });

  const useGroup = useWatch({
    control,
    name: 'group',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    setValue('until', addDays(new Date(watchSince), 14));
    setMaxDateValidationState(addDays(new Date(watchSince), 30));
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const objQuery = {
      clientValue: userClient.value,
      userOrchards: userOrchard,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointFilters,
        objQuery
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        data.orchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDataFilterState(data.orchard);

        const flattenEndpointData = flattenEndpointDataHandler(data.orchard);

        setFiltersFlattenDataState(flattenEndpointData);

        // cargar con valores únicos
        const agroBusinessArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.agroBusiness,
          };
        });

        const uniqueAgroBusiness = getUniqueValuesMaster(
          agroBusinessArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueAgroBusiness.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setAgroBusinessOptionsState(uniqueAgroBusiness);

        const specieArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.specie,
          };
        });

        const uniqueSpecie = getUniqueValuesMaster(
          specieArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueSpecie, '<<<<<------uniqueSpecie------')

        setSpecieOptionsState(uniqueSpecie);

        const varietyArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.variety,
          };
        });

        const uniqueVariety = getUniqueValuesMaster(
          varietyArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueVariety, '<<<<<------uniqueVariety------')

        setVarietyOptionsState(uniqueVariety);

        const groupArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.group,
          };
        });

        const uniqueGroup = getUniqueValuesMaster(
          groupArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueGroup.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataGroupState(uniqueGroup);

        const technicBossArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.technicBoss,
          };
        });

        const uniqueTechnicBoss = getUniqueValuesMaster(
          technicBossArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueTechnicBoss.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataTechnicBossState(uniqueTechnicBoss);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    // getFilterVarietyAsync();

    getFilterAsync();
  }, []);

  // useEffect(() => {
  //   if (garces2State) {
  //     getFilterAgroBusinessAsync();
  //   }
  // }, [garces2State]);

  // UseEffect para cambios de filtros
  useEffect(() => {
    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      const agroBusinessFilter = useAgroBusiness
        ? ele?.agroBusiness.value === useAgroBusiness.value
        : true;
      const orchardFilter = useOrchard
        ? ele?.orchard.value === useOrchard.value
        : true;
      const specieFilter = useSpecie
        ? ele?.specie.value === useSpecie.value
        : true;
      const productLineFilter = useProductionLine
        ? ele?.productLine.value === useProductionLine.value
        : true;
      // const varietyFilter = useVariety
      //   ? ele?.variety.value === useVariety.value
      //   : true;
      const groupFilter = useGroup ? ele?.group.value === useGroup.value : true;
      const technicBossFilter = useTechnicBoss
        ? ele?.technicBoss.value === useTechnicBoss.value
        : true;

      return (
        agroBusinessFilter &&
        orchardFilter &&
        specieFilter &&
        productLineFilter &&
        // varietyFilter &&
        groupFilter &&
        technicBossFilter
      );
    });

    // console.log(filterUpdatedData, '<<<<<------filterData------');
    const filterUpdatedDataVariety = filterUpdatedData.filter((ele) => {
      // buscarlo con some en el array de useOrchard
      const varietyFilter =
        useVariety && useVariety.length
          ? useVariety.some((variety) => variety.value === ele?.variety.value)
          : true;

      return varietyFilter;
    });

    // console.log(filterUpdatedDataVariety, '<<<<<------filterDataVariety------');

    const agroBusinessArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.agroBusiness,
      };
    });

    const uniqueAgroBusiness = getUniqueValuesMaster(
      agroBusinessArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setAgroBusinessOptionsState(uniqueAgroBusiness);

    if (!useAgroBusiness && uniqueAgroBusiness.length === 1) {
      setValue('agroBusiness', uniqueAgroBusiness[0]);
    }

    const orchardArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.orchard,
      };
    });

    const uniqueOrchards = getUniqueValuesMaster(
      orchardArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterOrchardState(uniqueOrchards);

    if (!useOrchard && uniqueOrchards.length === 1) {
      setValue('orchard', uniqueOrchards[0]);
    }

    const specieArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.specie,
      };
    });

    const uniqueSpecie = getUniqueValuesMaster(
      specieArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setSpecieOptionsState(uniqueSpecie);

    if (!useSpecie && uniqueSpecie.length === 1) {
      console.log(uniqueSpecie, '<<<<<------Entra para seterlo otra vez------');

      setValue('specie', uniqueSpecie[0]);
    }

    const productLineArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.productLine,
      };
    });

    const uniqueProductLine = getUniqueValuesMaster(
      productLineArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setProductionLineDataState(uniqueProductLine);

    // if (!useProductionLine && uniqueProductLine.length === 1) {
    //   setValue('productionLine', uniqueProductLine[0]);
    // }

    const varietyArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.variety,
      };
    });

    const uniqueVariety = getUniqueValuesMaster(
      varietyArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setVarietyOptionsState(uniqueVariety);

    const groupArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.group,
      };
    });

    const uniqueGroup = getUniqueValuesMaster(
      groupArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueGroup.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataGroupState(uniqueGroup);

    if (!useGroup && uniqueGroup.length === 1) {
      setValue('group', uniqueGroup[0]);
    }

    const technicBossArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.technicBoss,
      };
    });

    const uniqueTechnicBoss = getUniqueValuesMaster(
      technicBossArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueTechnicBoss.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataTechnicBossState(uniqueTechnicBoss);
  }, [
    useAgroBusiness,
    useOrchard,
    useSpecie,
    useProductionLine,
    useVariety,
    useGroup,
    useTechnicBoss,
  ]);

  const onSubmit = async (values) => {
    // console.log(values, '·-----asi llega');

    const dateBegin = values.since || '';
    const dateEnd = values.until || '';

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    console.log(values, '<<<<<------------values');

    const data = {
      orchard: values.orchard.value,
      agroBusiness: values.agroBusiness,
      variety: values.variety,
      productionLine: values.productionLine,
      specie: values.specie,
      sinceDate: dateFormatIso(dateBegin),
      untilDate: dateFormatIso(dateEnd),
    };

    if (values.orchard.value) {
      const resultOrchard = userOrchard_filter.filter(
        (ele) => ele.value === values.orchard.value
      );

      // console.log(values.orchard, orchard_filter, '---------evalua huertos');

      if (orchard_filter !== values.orchard.value) {
        addFilter({
          cc_filter: '',
          quarter_filter: '',
        });
      }
      // addFilter({
      //   orchard_filter: values.orchard.value,
      //   orchard_id_filter: resultOrchard[0]._id,
      // });
    }

    // console.log(data, '<<<<<------------');
    setInfoFilterTab(data);
  };

  const handleClearSpecieValue = (value) => {
    console.log('clear value', value);
    if (!value) {
      setValue('specie', '');
      setValue('productionLine', '');
      setValue('variety', []);
    } else {
      setValue('specie', value);
    }
  };

  const handleClearProductLineValue = (value) => {
    console.log('clear value', value);
    if (!value) {
      setValue('specie', '');
      setValue('productionLine', '');
      setValue('variety', []);
    } else {
      setValue('productionLine', value);
    }
  };

  const handleClearGroupValue = (value) => {
    console.log('clear value', value);
    if (!value) {
      setValue('group', '');
      setValue('agroBusiness', '');
      setValue('orchard', '');
    } else {
      setValue('group', value);
    }
  };

  const resetFilters = () => {
    // console.log('reset filters');
    reset();
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Bi Semanal - Listado Registros
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-12'>
                    <MobileFilters>
                      <form
                        className='form-inline'
                        autoComplete='on'
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className='input-group mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.technicBoss}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='technicBoss'
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isClearable={true}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataTechnicBossState}
                            placeholder='Jefe Técnico'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.group}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='group'
                            isSearchable
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            onChange={handleClearGroupValue}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataGroupState}
                            placeholder='Grupo'
                          />
                        </div>

                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.agroBusiness}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='agroBusiness'
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isClearable={true}
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={agroBusinessOptionsState}
                            placeholder='Razón Social'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.orchard}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='orchard'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={filterOrchardState}
                            placeholder='Huerto'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.specie}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='specie'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            onChange={handleClearSpecieValue}
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={specieOptionsState}
                            placeholder='Especie'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.productionLine}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='productionLine'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            onChange={handleClearProductLineValue}
                            isSearchable
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={productionLineDataState}
                            placeholder='l. Producción'
                          />
                        </div>
                        <>
                          <div className='input-group ml-md-2 mb-3'>
                            <SelectMultipleWidget2
                              errors={errors.variety}
                              loading={
                                loadingFiltersState || loadingVarietiesState
                              }
                              control={control}
                              menuPortalTarget={document.body}
                              labeltext=''
                              name='variety'
                              display='vertical'
                              variant='outlined'
                              isClearable={true}
                              isSearchable
                              isMulti={false}
                              req={false}
                              defaultValue=''
                              options={varietyOptionsState}
                              placeholder='Variedad'
                            />
                          </div>
                          <div className='input-group ml-md-2 mb-3'>
                            <TextFieldWidgetDateController2
                              name='since'
                              labeltext='Desde'
                              display='vertical'
                              variant='outlined'
                              control={control}
                              errors={errors.since}
                              format='dd-MM-yyyy'
                              defaultValue={subDays(new Date(), 1)}
                            />
                          </div>
                          <div className='input-group ml-md-2 mb-3'>
                            <TextFieldWidgetDateController2
                              name='until'
                              labeltext='Hasta'
                              format='dd-MM-yyyy'
                              display='vertical'
                              variant='outlined'
                              maxDate={maxDateValidationState}
                              control={control}
                              minDate={minDateState}
                              errors={errors.until}
                              // defaultValue={null}
                              defaultValue={new Date()}
                            />
                          </div>
                        </>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            paddingTop: 5,
                          }}
                        >
                          <SaveAction
                            title='Filtrar'
                            color='secondary'
                            size='large'
                            className='roundedButton mx-md-2'
                            variant='contained'
                            messageType='add'
                            mutationLoading={loadingSubmitFilterState}
                            success={false}
                          />
                          <Button
                            variant='outlined'
                            size='large'
                            onClick={resetFilters}
                          >
                            Limpiar
                          </Button>
                        </div>
                      </form>
                      {errors.incorrectDates && (
                        <p
                          style={{
                            color: theme.palette.error.main,
                          }}
                        >
                          {errors.incorrectDates.message}
                        </p>
                      )}
                    </MobileFilters>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <ListTable filter={infoFilterTab} />
      </BsContainer>
    </div>
  );
};
export default ListSchedule;
