import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HttpsIcon from '@mui/icons-material/Https';
import HistoryIcon from '@mui/icons-material/History';
import clsx from 'clsx';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  // Hidden,
  TextField,
  Typography,
  useTheme,
  Paper,
  Skeleton,
  Button,
  Box,
  Alert,
  Divider,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import { useErrorHandler } from 'react-error-boundary';
import PollIcon from '@mui/icons-material/Poll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  ButtonAction,
  CardPaper,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  SimpleIconButton,
} from '../../components';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
import { SimpleTabs, TabPanel } from '../../components/TabPanel';
import { Map, ModalGraph } from '../../containers/Sampling';

import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  getOrchardVarietiesDeep,
  numberFormatDecimals,
  alterArrayGetIds,
  printOrder,
  generalOptionsHighCharts,
  dashAdd,
  alphabeticalSort,
  getSeasonActiveQuarters,
  getOnlyValidCcs,
  getOnlyValidOrchardCcQuarters,
  getOnlyValidCcQuartersWithPolygon,
  getOrchardsBySpecie,
} from '../../helpers';
import { FiltersContext } from '../../context';
import { MobileFilters } from '../../containers/Library';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { unique } from 'underscore';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  rootContent: {},
  rightMarginWide: {},
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 14,
  },
  inputText: {
    fontSize: 26,
  },
  cardContentRoot: {
    padding: 15,
    [theme.breakpoints.up('lg')]: {
      '& div.col-xl-9': {
        width: '78%',
        maxWidth: '78%',
        flex: '0 0 78%',
      },
      '& div.col-xl-3': {
        width: '22%',
        maxWidth: '22%',
        flex: '0 0 22%',
      },
    },
  },
  mapContainer: {
    minHeight: '70vh',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    overflow: 'hidden',
  },
  animatedCardStyle: {
    position: 'absolute',
    bottom: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: 100,
    transform: 'translateY(90px)',
    transition: 'transform 0.3s ease-in-out',
    '&.active': {
      transform: 'translateY(8px)',
    },
  },
  tableStyles: {
    marginTop: 5,
    '& td': {
      padding: '0.45rem',
      fontSize: 14,
    },
  },
  infoCol: {
    fontWeight: 500,
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
    },
  },
  theadLightSmall: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
  colorBox: {
    width: '25px',
    height: '25px',
  },
  tbodyStyle: {
    '& td': {
      verticalAlign: 'middle',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowsContainerStyle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  subtitleDash: {
    fontSize: 18,
  },
  paperContainerStyle: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%',
    '& h6': {
      color: theme.palette.primary.contrastText,
    },
    '& h2': {
      color: theme.palette.primary.contrastText,
    },
  },
  bgCellStyle: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontWeight: 700,
  },
  fullWidthStyle: {
    width: '100%',
  },
}));

const Sampling = (props) => {
  const { userId, userHuerto, userName } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const errorHandler = useErrorHandler();

  // crear un ref de typoOfError
  const typeOfError = useRef(null);

  // modal
  const [openModalState, setOpenModalState] = useState(false);

  // highchart options
  const [loadingGraphState, setLoadingGraphState] = useState(true);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [graphDataState, setGraphDataState] = useState(null);

  // estados form
  const [activePercButtonState, setActivePercButtonState] = useState(true);
  const [loadingSamplingState, setLoadingSamplingState] = useState(false);

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    season_filter,
    variety_filter,
    rootstock_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [loadingInfoState, setLoadingInfoState] = useState(false);
  const [savingDataState, setSavingDataState] = useState(false);

  // data generada en mapa
  const [infoDataZonesState, setInfoDataZonesState] = useState(null);
  const [infoPlantsState, setInfoPlantsState] = useState(null);
  const [plantsSelectedGridState, setPlantsSelectedGridState] = useState(null);
  const [colorsArrayState, setColorsArrayState] = useState([]);
  const [dataPlantsCalcState, setDataPlantsCalcState] = useState(null);

  const [activeCardInfoState, setActiveCardInfoState] = useState(false);

  const theme = useTheme();

  const classes = useStyles();

  const history = useHistory();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // form zonas
  const {
    handleSubmit: handleSubmitPerc,
    control: controlPerc,
    watch: watchPerc,
    setValue: setValuePerc,
    formState: { errors: errorsPerc },
  } = useForm();

  // const watchVariety = watch('variety');

  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);
  const [ccSelectedState, setCcSelectedState] = useState(null);
  const [quarterSelectedState, setQuarterSelectedState] = useState(null);
  const [varietySelectedState, setVarietySelectedState] = useState(null);
  const [idUnitProductiveState, setIdUnitProductiveState] = useState(null);

  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);

  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // loading quarter data
  const [loadingFilterQuarterState, setLoadingFilterQuarterState] =
    useState(false);

  const [blockedFormState, setBlockedFormState] = useState(false);
  const [isInitializatedState, setIsInitializatedState] = useState(false);

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele.statusSampleRecommendation)
  );

  // estados de index

  const [availablePolygonsState, setAvailablePolygonsState] = useState([]);

  const [polygonActualIndexState, setPolygonActualIndexState] = useState(null);
  const [orchardMaxIndexState, setOrchardMaxIndexState] = useState(null);

  const [successSaveState, setSuccessSaveState] = useState(false);

  // NUEVOS ESTADOS CONTROL DATOS Y CARGA GENERAL
  const [dataValidationState, setDataValidationState] = useState(null);

  // ESTADO DE HUERTO SIN POLIGONOS
  const [orchardWithNoValidPolygons, setOrchardWithNoValidPolygons] =
    useState(false);

  // console.log(filterDataResponseState, '--------filterDataResponseState');

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useCc = useWatch({
    control,
    name: 'cc',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const watchPlantsForm = watchPerc('plantsForm');

  const findCcByIdUnitProductive = (data, id) => {
    for (let item of data) {
      for (let quarter of item.quarter) {
        const variety = quarter.variety.find((v) => v.idUnitProductive === id);
        if (variety) {
          return item; // Devuelve el objeto que contiene el quarter donde se encontró el idUnitProductive
        }
      }
    }
    return null; // Si no se encuentra, devuelve null
  };

  const findQuarterByIdUnitProductive = (data, id) => {
    for (let item of data) {
      const variety = item.variety.find((v) => v.idUnitProductive === id);
      if (variety) {
        return item; // Devuelve el objeto que contiene el array variety donde se encontró el idUnitProductive
      }
    }
    return null; // Si no se encuentra, devuelve null
  };

  const clearValidData = () => {
    setDataValidationState(null);
  };

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);
    // setInfoDataZonesState(null);
    // setInfoPlantsState(null);
    setOrchardWithNoValidPolygons(false);
    // setDataValidationState(null);

    // console.log(values, '---------------los valores en submit');

    if (values.quarter && !values.variety) {
      setError('quarterWithVariety', {
        type: 'manual',
        message: 'Si Selecciona Cuartel debe seleccionar Variedad',
      });
      return;
    }

    if (!values.quarter && values.variety) {
      setError('quarterWithVariety', {
        type: 'manual',
        message: 'Si Selecciona Variedad debe seleccionar Cuartel',
      });
      return;
    }

    // setValuePerc('percentage', '50');

    let selectedOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard.value
    );

    // DEJA EL HUERTO SELECCIONADO, PERO SOLO CON CC QUARTERS Y POLIGONOS VÁLIDOS
    selectedOrchard = getOnlyValidOrchardCcQuarters(
      selectedOrchard[0],
      specie_filter
    );

    console.log(
      selectedOrchard,
      '---------------------------------selectedOrchard'
    );

    // 1- CON EL HUERTO SELECCIONADO FILTRAR LOS POLIGONOS DISPONIBLES PARA LOS CC VALUE DE ESE HUERTO

    // TODO: dejar solo los polygons de la especie seleccionada
    let abailableSpeciePolygons = selectedOrchard.polygon.filter(
      (obj) => obj.specie === specie_filter
    );

    const abailablePolygons = abailableSpeciePolygons.map((poly) => {
      const enabledCc =
        _.findIndex(selectedOrchard.cc, function (p) {
          return p.value === poly.cc && p.quarter.length > 0;
        }) >= 0;

      return enabledCc ? poly : null;
    });

    // 2- LIMPIA VACIOS

    const clearPolygons = abailablePolygons.filter((item) => item);

    console.log(clearPolygons, '------------------------clearPolygons');

    console.log(selectedOrchard.cc, '----selectedOrchard.cc');

    // 3.5 - AHORA SOLO DEJARÁ LIS IDUNITEPRODUCTIVE DE LAS VARIETY ACTIVAS
    const uniqueArrayIdUniteProductive = selectedOrchard.cc.reduce(
      (acc, item) => {
        item.quarter.forEach((quarter) => {
          quarter.variety.forEach((variety) => {
            if (variety.seasonActive) {
              const uniqueString = `${variety.idUnitProductive}`;
              if (!acc.includes(uniqueString)) {
                acc.push(uniqueString);
              }
            }
          });
        });
        return acc;
      },
      []
    );

    console.log(
      '-----------uniqueArrayCcQuarterVariety y unique Ids',
      uniqueArrayIdUniteProductive
    );

    // 4.5 - FILTRA LOS POLIGONOS DISPONIBLES POR LOS VALORES UNICOS DE IDUNITEPRODUCTIVE
    let resultsPolygonsAvailablesId = clearPolygons.filter((poly) =>
      uniqueArrayIdUniteProductive.includes(poly.idUnitProductive)
    );

    console.log(
      '------------resultsPolygons availablesId',
      resultsPolygonsAvailablesId
    );

    let polygonSet = resultsPolygonsAvailablesId;

    // 5- ARMA EL SET FINAL DE POLIGONOS DISPONIBLES POR HUERTO, POR CC Y POR CC/QUARTER

    typeOfError.current =
      'El módulo no está disponible para el huerto seleccionado';

    if (values.cc) {
      // const polygonCcFiltered = resultsPolygonsAvailables.filter(
      //   (ele) => ele.cc === values.cc?.value
      // );

      const polygonCcFiltered = resultsPolygonsAvailablesId.filter(
        (ele) => ele.cc.toLowerCase() === values.cc?.value.toLowerCase()
      );

      polygonSet = polygonCcFiltered;
      typeOfError.current =
        'El módulo no está disponible para el Centro de Costo seleccionado';
    }

    if (values.quarter) {
      const polygonQuarterFiltered = polygonSet.filter(
        (ele) =>
          ele.quarter.toLowerCase() === values.quarter?.value.toLowerCase()
      );

      console.log(
        values.quarter,
        values.quarter?.value.toLowerCase(),
        polygonSet,
        '---------------------------------'
      );

      polygonSet = polygonQuarterFiltered;
      typeOfError.current =
        'El módulo no está disponible para el Cuartel seleccionado';
    }

    resultsPolygonsAvailablesId = polygonSet;

    console.log(
      resultsPolygonsAvailablesId,
      '----resultsPolygonsAvailablesId FILTRADO POR CC'
    );

    // 6.5 LIMPIA EL SET DE POLIGONOS DISPONIBLES DE REPETIDOS POR IDUNITEPRODUCTIVE

    const uniqueCombinationsId = new Set();

    // Filtrar el array para mantener solo los objetos con combinaciones únicas
    const uniquePolygonsId = resultsPolygonsAvailablesId.filter((obj) => {
      const combination = `${obj.idUnitProductive}`;
      if (uniqueCombinationsId.has(combination)) {
        return false;
      }
      uniqueCombinationsId.add(combination);
      return true;
    });

    // 7- SI HAY POLIGONOS DISPONIBLES

    if (uniquePolygonsId.length) {
      // console.log('----------------uniquePolygonsId', uniquePolygonsId);

      // 7.5 - ORDENA LOS POLIGONOS DISPONIBLES POR CC, Quarter y por idUnite
      uniquePolygonsId.sort((a, b) => {
        const ccComparison = a.cc.localeCompare(b.cc);
        if (ccComparison !== 0) {
          return ccComparison;
        }
        const quarterComparison = a.quarter.localeCompare(b.quarter);
        if (quarterComparison !== 0) {
          return quarterComparison;
        }
        return a.idUnitProductive.localeCompare(b.idUnitProductive);
      });

      console.log('----------------ORDENADOS', uniquePolygonsId);

      // 8.5 - SETEA LOS POLIGONOS DISPONIBLES POR IDUNITEPRODUCTIVE

      setAvailablePolygonsState(uniquePolygonsId);

      // 9.5 - SETEA EL PRIMER POLIGONO / QUARTER DISPONIBLE POR IDUNITEPRODUCTIVE
      // TODO: ordenar por idUnitProductive

      const firstQuarter = uniquePolygonsId[0];

      console.log(firstQuarter, '----firstQuarter de POLYGON AVAILABLES');

      const ccFirst = firstQuarter?.cc;
      const quarterFirst = firstQuarter?.quarter;
      const varietyFirst = dashAdd(firstQuarter?.variety);
      const rootStockFirst = dashAdd(firstQuarter?.rootStock);
      const idUnitProductiveFirst = firstQuarter?.idUnitProductive;

      const selectedCcFilter = values.cc ? values.cc?.value : ccFirst;
      const selectedQuarterFilter = values.quarter
        ? values.quarter?.value
        : quarterFirst;
      const selectedVarietyFilter = values.variety
        ? values.variety?.value
        : varietyFirst;

      console.log(
        '---------selected or first',
        selectedCcFilter,
        selectedQuarterFilter,
        selectedVarietyFilter
      );

      // si viene cc, busca el primero del cc
      let quarterFromCc = null;
      let varietyFromCc = null;
      let rootStockFromCc = null;
      if (values.cc && !values.quarter) {
        const ccSelectValid = selectedOrchard.cc
          ? getOnlyValidCcs(selectedOrchard.cc, specie_filter)
          : [];

        // FILTRA EL CC SELECCIONADO DE LOS CC VÁIDOS

        console.log(ccSelectValid);

        const resultQuarterFromCc = ccSelectValid.filter(
          (ele) => ele.value.toLowerCase() === selectedCcFilter.toLowerCase()
        );

        // TOMA SOLO CUARTELES ACTIVOS DEL CC

        let quarterArray = [];
        if (resultQuarterFromCc[0]?.quarter) {
          quarterArray = getSeasonActiveQuarters(
            resultQuarterFromCc[0]?.quarter
          );
        }

        quarterFromCc = quarterArray[0]?.value;
        varietyFromCc = quarterArray[0]?.variety[0]?.value;
        rootStockFromCc = quarterArray[0]?.variety[0]?.rootStockValue;
      }

      // const maxIndex = uniquePolygons.length - 1;

      const maxIndex = uniquePolygonsId.length - 1;

      // 10- SETEA EL INDEX DEL PRIMER POLIGONO DISPONIBLE Y EL MAXIMO

      console.log(maxIndex, '----maxIndex', uniquePolygonsId.length);

      setOrchardMaxIndexState(maxIndex);

      const idOrchardFilter = selectedOrchard ? selectedOrchard.id : null;

      const selectedCc = getOnlyValidCcs(selectedOrchard.cc, specie_filter).filter(
        (ele) => ele.value.toLowerCase() === selectedCcFilter.toLowerCase()
      );

      console.log(selectedCc, '-----cc');

      let selectedQuarter;
      if (!quarterFromCc) {
        // console.log('entra con null', selectedQuarterFilter);
        selectedQuarter = selectedCc[0]?.quarter
          ? selectedCc[0]?.quarter.filter(
              (ele) =>
                ele.value.toLowerCase() === selectedQuarterFilter.toLowerCase()
            )
          : [];
      } else {
        selectedQuarter = selectedCc[0]?.quarter
          ? selectedCc[0]?.quarter.filter(
              (ele) => ele.value.toLowerCase() === quarterFromCc.toLowerCase()
            )
          : [];
      }

      const idUnitProductive = selectedQuarter[0].variety.find(
        (ele) => ele.value === varietyFromCc || selectedVarietyFilter
      );

      // 11- SETEA LOS CONTEXT FILTROS, LOS RECIBE EL MAPA

      console.log(idUnitProductive, '----idUnitProductive');

      // EL SUBMIT YA GUARDA IDUNITPRODUCTIVE

      addFilter({
        orchard_filter: values.orchard?.value,
        orchard_id_filter: idOrchardFilter,
        cc_filter: selectedCcFilter,
        quarter_filter: quarterFromCc || selectedQuarterFilter,
        variety_filter: varietyFromCc || selectedVarietyFilter,
        rootstock_filter: selectedQuarter[0]?.variety[0].rootStockValue,
        idUnitProductive: idUnitProductive?.idUnitProductive || '',
      });

      // guardar el index del cuarter selected

      const varietyToFilter = varietyFromCc || selectedVarietyFilter;

      // const isQuarterSelected = (element) =>
      //   element.cc === selectedCc[0].value &&
      //   element.quarter === selectedQuarter[0].value &&
      //   element.variety === varietyToFilter;

      const isQuarterSelectedId = (element) =>
        element.idUnitProductive === idUnitProductive?.idUnitProductive;

      // 12- SETEA EL INDEX DEL POLIGONO / QUARTER SELECCIONADO

      const actualIndex = uniquePolygonsId.findIndex(isQuarterSelectedId);

      console.log(
        actualIndex,
        '----actualIndex',
        selectedCc[0].value,
        selectedQuarter[0].value,
        varietyToFilter,
        idUnitProductive?.idUnitProductive
      );

      setPolygonActualIndexState(actualIndex);

      setOrchardSelectedState(selectedOrchard);
      setCcSelectedState(selectedCc[0]);
      setQuarterSelectedState(selectedQuarter[0]);

      // console.log(selectedQuarter[0], '-----selectedQuarter[0]', varietyToFilter);

      const varietySelectedArray = selectedQuarter[0].variety.filter(
        (ele) => ele.value.toLowerCase() === varietyToFilter.toLowerCase()
      );

      // console.log(selectedQuarter[0], varietyToFilter, '---varietyToFilter');

      setVarietySelectedState(varietySelectedArray[0]);

      setIdUnitProductiveState(idUnitProductive?.idUnitProductive);
    } else {
      setDataPlantsCalcState(null);
      setQuarterSelectedState(null);
      setIdUnitProductiveState(null);
      setInfoPlantsState(null);
      setInfoDataZonesState(null);
      setIsInitializatedState(false);
      setDataValidationState(false);
      setOrchardWithNoValidPolygons(true);

      addFilter({
        orchard_filter: null,
        orchard_id_filter: null,
        cc_filter: null,
        quarter_filter: null,
        variety_filter: null,
        rootstock_filter: null,
        idUnitProductive: null,
      });
    }
  };

  const getFilterVarietyAsync = async () => {
    try {
      const params = { 
        specieValue: specie_filter, 
      };
      const response = await axiosContext.authAxios.get('v1/variety', { params, }); 

      if (response.data) {
        setVarietyFullDataState(response.data);
      }
    } catch (error) {
      errorHandler(error);
      // console.log(error);
      // setLoadingVarietyFilterState(false);
    }
  };

  useEffect(() => {
    if (orchard_filter) {
      const foundOrchard = filterDataResponseState.find(
        (element) => element.value === orchard_filter
      );

      if (foundOrchard) {
        setValue('orchard', foundOrchard);
      }
    }
  }, []);

  useEffect(() => {
    if (varietyFullDataState.length) {
      setLoadingFiltersState(false);
    }
  }, [varietyFullDataState]);

  // endpoints variety - rootstock
  useEffect(() => {
    // getFilterVarietyAsync();
    // getFilterRootStockAsync();
  }, []);

  useEffect(() => {
    // console.log(useOrchard, 'useOrchard');
    // cada vez que hay un orchard
    // limpia cc
    // y carga la nueva data de cc
    if (useOrchard) {
      setValue('cc', '');
      // console.log(
      // 	filterDataResponseState,
      // 	'-----------filterDataResponseState'
      // );
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      if (resultOrchard.length) {
        // const ccSelect = resultOrchard[0].cc
        //   ? getOnlyValidCcs(resultOrchard[0].cc)
        //   : [];

        const ccSelect = getOnlyValidCcQuartersWithPolygon(
          resultOrchard[0],
          specie_filter
        ).cc;

        // console.log(ccSelect, '----ccSelect');

        // console.log(
        //   getOnlyValidCcQuartersWithPolygon(resultOrchard[0]),
        //   '-----------------------VALIDOS-------'
        // );

        // TODO: aplicar getOnlyValidCcQuartersWithPolygon para dejar solo los cc con cuarteles / polígonos activos

        setCcDataState(alphabeticalSort(ccSelect, 'label'));
      }
    } else {
      setValue('cc', '');
    }
  }, [useOrchard]);

  useEffect(() => {
    // console.log('entra en ccData', ccDataState);
    if (ccDataState.length) {
      // si hay data de cc
      // reivsa si puede seleccionar el del context
      setLoadingFilterCcState(true);
      const found = ccDataState.find((element) => element.value === cc_filter);
      // console.log(found, '---found CC');
      // SI LO ENCUENTRA PERO NO ES MISMO HUERTO NO SELECCIONA

      if (found && useOrchard?.value === orchard_filter) {
        setValue('cc', found);
      } else {
        setValue('cc', '');
        setValue('quarter', '');
      }
      setLoadingFilterCcState(false);
    }
  }, [ccDataState]);

  useEffect(() => {
    // console.log(useCc, 'useCc');
    // cada vez que hay un cc
    // limpia quarter
    // y carga la nueva data de quarter
    if (useCc) {
      setValue('quarter', '');
      const resultCc = ccDataState.filter(
        (ele) => ele.value.toLowerCase() === useCc?.value.toLowerCase()
      );

      // console.log(resultCc[0]?.quarter, '-------los cc');

      let quarterArray = [];
      if (resultCc[0]?.quarter) {
        quarterArray = getSeasonActiveQuarters(resultCc[0]?.quarter);
      }

      console.log(quarterArray, '----quarterArray validated');

      setQuarterDataState(quarterArray);
    } else {
      setValue('quarter', '');
      setQuarterDataState([]);
    }
  }, [useCc]);

  useEffect(() => {
    // console.log('entra en quarterData', quarterDataState);
    if (quarterDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      setLoadingFilterQuarterState(true);
      const found = quarterDataState.find(
        (element) => element.value === quarter_filter
      );
      // console.log(found, '---found');
      if (found && useCc?.value === cc_filter) {
        setValue('quarter', found);
      } else {
        setValue('quarter', '');
      }
      setLoadingFilterQuarterState(false);
    } else {
      setValue('quarter', '');
    }
  }, [quarterDataState]);

  // VARIETY
  useEffect(() => {
    // console.log(useOrchard, 'useOrchard -- para variety');
    // cada vez que hay un orchard
    // carga la data de variety
    if (useOrchard) {
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      const orchardsCc = resultOrchard[0]?.cc;

      // console.log(getOrchardVarieties(orchardsCc), '<<---las varieties');

      setVarietyDataState(getOrchardVarietiesDeep(orchardsCc));
    } else {
      // vuelve a dejar variety completo
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
      // vacía los cc y los quarters
      setQuarterDataState([]);
      setCcDataState([]);
    }
  }, [useOrchard]);

  // alimenta las varieties full
  // solo cuando no tiene nada
  useEffect(() => {
    if (varietyFullDataState.length && !varietyDataState.length) {
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [varietyFullDataState]);

  // cambios de cc
  // para ajustar variety
  useEffect(() => {
    // console.log(useCc, 'useCc - para variety');
    // cada vez que hay un cc
    // y carga la nueva data de quarter
    if (useCc) {
      // console.log(useCc, 'useCc - para variety - CON CC');
      const resultCc = ccDataState.filter(
        (ele) => ele.value.toLowerCase() === useCc?.value.toLowerCase()
      );
      setVarietyDataState(getOrchardVarietiesDeep(resultCc));
      setValue('variety', '');
    } else {
      if (useOrchard && ccDataState.length) {
        // console.log(
        // 	useOrchard,
        // 	'------useCc hay orchard',
        // 	getOrchardVarieties(ccDataState)
        // );
        setVarietyDataState(getOrchardVarietiesDeep(ccDataState));
      }
    }
  }, [useCc]);

  // cambios en quarter
  useEffect(() => {
    if (useQuarter) {
      // getImport(useOrchard);
      // console.log(useQuarter);
      const resultQuarter = quarterDataState.filter(
        (ele) => ele.value.toLowerCase() === useQuarter?.value.toLowerCase()
      );
      // console.log(resultQuarter[0].variety, '<---resultado variety');
      setVarietyDataState(
        resultQuarter[0]?.variety ? resultQuarter[0]?.variety : []
      );
      const found = resultQuarter[0]?.variety
        ? resultQuarter[0]?.variety.find(
            (element) => element.value === variety_filter
          )
        : false;

      if (found && useQuarter?.value === quarter_filter) {
        setValue('variety', found);
      } else {
        setValue('variety', '');
      }
    } else {
      setValue('variety', '');
    }
  }, [useQuarter, setValue, quarterDataState]);

  useEffect(() => {
    // console.log('entra en varietyDataState', varietyDataState);
    if (varietyDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      const found = varietyDataState.find(
        (element) => element.value === variety_filter
      );
      // console.log(found, '---found');
      if (found) {
        // setValue('variety', variety_filter);
      } else {
        setValue('variety', '');
      }
    } else {
      setValue('variety', '');
    }
  }, [varietyDataState]);

  useEffect(() => {
    // console.log(useVariety, "----useVariety")
    if (useVariety) {
      clearErrors('quarterWithVariety');
    }

    if (!useVariety && !useQuarter) {
      clearErrors('quarterWithVariety');
    }
  }, [useVariety]);

  useEffect(() => {
    // console.log(useVariety, "----useVariety")
    if (!useQuarter && !useVariety) {
      clearErrors('quarterWithVariety');
    }
  }, [useQuarter]);

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group mb-2'>
                {/* <TextFieldWidgetSelectController
                  options={filterDataResponseState}
                  control={control}
                  titleOption='label'
                  name='orchard'
                  labeltext='Huerto'
                  errors={errors.orchard}
                  defaultValue=''
                  loading={loadingFiltersState}
                  req
                  withDefault
                  variant='standard'
                /> */}
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={loadingFilterCcState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='cc'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={ccDataState}
                  placeholder='Centro de Costo'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.quarter}
                  loading={loadingFilterQuarterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='quarter'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='Cuarteles'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='variety'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Variedad'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
        {errors.quarterWithVariety && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.quarterWithVariety.message}
          </p>
        )}
      </MobileFilters>
    );
  };

  useEffect(() => {
    if (graphDataState) {
      // console.log(graphDataState, 'empieza a crear gráfico');
      setGraphOptionsState({
        ...generalOptionsHighCharts,
        title: {
          text: '',
        },
        chart: {
          type: 'histogram',
          height: 480,
          spacingBottom: 8,
          spacingLeft: 0,
          spacingRight: 0,
          spacingTop: 10,
          zoomType: 'x',
        },
        plotOptions: {
          series: {
            enableMouseTracking: false,
          },
          histogram: {
            accessibility: {
              point: {
                valueDescriptionFormat:
                  '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.',
              },
            },
          },
        },
        xAxis: [
          {
            title: {
              text: 'Data',
            },
            visible: false,
          },
          {
            title: {
              text: '',
            },
            type: 'linear',
            tickPosition: 'outside',
          },
        ],
        yAxis: [
          {
            title: 'none',
          },
          {
            title: { text: 'Conteos' },
            type: 'linear',
            opposite: true,
          },
        ],
        // legend: {
        // 	reversed: true, // right here!
        // },
        tooltip: {
          enabled: false,
        },
        colors: [
          'rgb(61,72,77)',
          'rgba(150,150,161,0.7)',
          'rgba(208,194,161,0.7)',
          'rgba(207, 172, 160,0.7)',
          'rgba(244,213,151,0.7)',
          'rgba(222,170,126,0.7)',
        ],
        series: graphDataState,
      });
      setLoadingGraphState(false);
    }
  }, [graphDataState]);

  // cambios porcentajes
  const onSubmitPerc = async (values) => {
    setLoadingSamplingState(true);
    // console.log(values, '---asi llegan', quarter_filter);

    const idUnitProductive = quarterSelectedState.variety.find(
      (ele) => ele.value === variety_filter
    );

    // console.log(idUnitProductive, '----idUnitProductive');

    const objQuery = {
      ccValue: cc_filter,
      orchardValue: orchard_filter,
      quarterValue: quarter_filter,
      varietyValue: variety_filter,
      rootStockValue: rootstock_filter,
      specieValue: specie_filter,
      idUnitProductive: idUnitProductive?.idUnitProductive || '',
      Plants: parseInt(values.plantsForm),
      calculatedGrid: [],
      save: false,
    };

    // console.log(objQuery, '---la query que va');

    const response = await axiosContext.authAxios.post('v1/sampling', objQuery);

    // console.log(response, '------response de plantas calculadas');
    const { status, data } = response;
    if (status === 200) {
      const resultGrid = data.treeWorkGrid;
      // const resultGrid = data.treeWorkGrid;

      setInfoPlantsState(resultGrid);

      setPlantsSelectedGridState(resultGrid);

      // TODO: actualizar dataPlants
      setDataPlantsCalcState({
        plantsHa: data.plantsHa,
        totalPlantPorcentage: data.totalPlantPorcentage,
        hectare: data.hectare,
        totalPlants: data.totalPlants,
      });

      setLoadingSamplingState(false);
    }
    setValuePerc('plantsForm', '');
  };

  const onResetPerc = (field) => {
    setValuePerc(field, '');
    // setExpectedInfoState([]);
  };

  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleOpenModal = () => {
    setOpenModalState(true);
  };

  // console.log(ccSelectedState, '---ccSelectedState');

  // AVANZAR CUARTELES
  const handleNextQuarter = () => {
    setValuePerc('plantsForm', '');

    let isQuarterSelected;

    // if (varietySelectedState?.value) {
    //   isQuarterSelected = (element) =>
    //     element.cc === ccSelectedState.value &&
    //     element.quarter === quarterSelectedState.value &&
    //     dashAdd(element.variety) === varietySelectedState.value &&
    //     dashAdd(element.rootStock) === varietySelectedState.rootStockValue;
    // } else {
    //   isQuarterSelected = (element) =>
    //     element.cc === ccSelectedState.value &&
    //     element.quarter === quarterSelectedState.value;
    // }

    isQuarterSelected = (element) =>
      element.idUnitProductive === idUnitProductiveState;

    // console.log(
    //   idUnitProductiveState,
    //   varietySelectedState,
    //   '-----------',
    //   availablePolygonsState
    // );
    // varietySelectedState tiene el IdUnitProductive actual

    // 1- OBTENER EL INDEX DE ESTE ID UNIT EN POLYGON

    const actualIndex = availablePolygonsState.findIndex(isQuarterSelected);

    // console.log(
    //   '----------actualIndex----------',
    //   actualIndex,
    //   orchardMaxIndexState
    // );

    // 2- SI EL INDICE ES MENOR AL MAX, SELECCIONA EL PRÓXIMO
    if (actualIndex < orchardMaxIndexState) {
      // console.log(availablePolygonsState[actualIndex + 1], 'próximo cuartel');

      // 3- SETEA EL INDEX DEL PRÓXIMO POLIGONO / QUARTER DISPONIBLE

      setPolygonActualIndexState(actualIndex + 1);

      const nextQuarter = availablePolygonsState[actualIndex + 1];

      console.log(
        nextQuarter,
        '------------el próximo, index ',
        actualIndex + 1
      );

      // deberia actualizar el idUnitProductive
      // 4- CAMBIA EL CONTEXTO AL NUEVO POLIGONO / QUARTER DISPONIBLE
      addFilter({
        cc_filter: nextQuarter.cc,
        quarter_filter: nextQuarter.quarter,
        variety_filter: dashAdd(nextQuarter.variety),
        rootstock_filter: dashAdd(nextQuarter.rootStock),
        idUnitProductive: nextQuarter.idUnitProductive,
      });

      // console.log(
      //   nextQuarter,
      //   '----------------------------',
      //   orchardSelectedState.cc
      // );

      const selectedCcById = findCcByIdUnitProductive(
        orchardSelectedState.cc,
        nextQuarter.idUnitProductive
      );

      console.log(selectedCcById, '----selectedCcById');

      // const selectedCc = orchardSelectedState.cc.filter(
      //   (ele) => ele.value === nextQuarter.cc
      // );

      // 5- SETEA LOS ESTADOS SELECTED

      setCcSelectedState(selectedCcById);

      // console.log(
      //   selectedCcById,
      //   '----------------selectedCcById',
      //   nextQuarter
      // );

      const selectedQuarterById = findQuarterByIdUnitProductive(
        selectedCcById.quarter,
        nextQuarter.idUnitProductive
      );

      // console.log(selectedQuarterById, '---------------selectedQuarter');

      // const selectedQuarter = selectedCcById.quarter.filter(
      //   (ele) => ele.value === nextQuarter.quarter
      // );

      // console.log(
      //   selectedCc,
      //   'cc selected y quarter selected -->',
      //   selectedQuarter[0]
      // );

      // console.log(selectedQuarter[0], '----OLD---------selectedQuarter');

      setQuarterSelectedState(selectedQuarterById);

      // const varietySelectedArray = selectedQuarterById.variety.filter(
      //   (ele) => ele.value === dashAdd(nextQuarter.variety)
      // );

      const varietySelectedArray = selectedQuarterById.variety.filter(
        (ele) => ele.idUnitProductive === nextQuarter.idUnitProductive
      );

      console.log(
        varietySelectedArray,
        'variedad del cc y id unite de polygon',
        nextQuarter.idUnitProductive
      );

      setVarietySelectedState(varietySelectedArray[0]);

      setIdUnitProductiveState(nextQuarter.idUnitProductive);
    }
  };

  // console.log(availablePolygonsState, '------los disponibles');
  // RETROCEDER CUARTELES
  const handleBackQuarter = () => {
    setValuePerc('plantsForm', '');

    // const quarterSelectedVariety = quarterSelectedState?.variety[0]?.value;
    let isQuarterSelected;

    isQuarterSelected = (element) =>
      element.idUnitProductive === idUnitProductiveState;

    // if (varietySelectedState?.value) {
    //   isQuarterSelected = (element) =>
    //     element.cc === ccSelectedState.value &&
    //     element.quarter === quarterSelectedState.value &&
    //     dashAdd(element.variety) === varietySelectedState.value &&
    //     dashAdd(element.rootStock) === varietySelectedState.rootStockValue;
    // } else {
    //   isQuarterSelected = (element) =>
    //     element.cc === ccSelectedState.value &&
    //     element.quarter === quarterSelectedState.value;
    // }

    // varietySelectedState tiene el IdUnitProductive actual

    // 1- OBTENER EL INDEX DE ESTE ID UNIT EN POLYGON
    const actualIndex = availablePolygonsState.findIndex(isQuarterSelected);

    // 2- SI EL INDICE ES MAYOR A 0, SELECCIONA EL ANTERIOR
    if (actualIndex >= 0) {
      // console.log(availablePolygonsState[actualIndex - 1], 'anterior cuartel');

      // 3- SETEA EL INDEX DEL ANTERIOR POLIGONO / QUARTER DISPONIBLE
      setPolygonActualIndexState(actualIndex - 1);

      const previusQuarter = availablePolygonsState[actualIndex - 1];

      // deberia actualizar el idUnitProductive
      // 4- CAMBIA EL CONTEXTO AL NUEVO POLIGONO / QUARTER DISPONIBLE

      addFilter({
        cc_filter: previusQuarter.cc,
        quarter_filter: previusQuarter.quarter,
        variety_filter: dashAdd(previusQuarter.variety),
        rootstock_filter: dashAdd(previusQuarter.rootStock),
        idUnitProductive: previusQuarter.idUnitProductive,
      });

      const selectedCcById = findCcByIdUnitProductive(
        orchardSelectedState.cc,
        previusQuarter.idUnitProductive
      );

      // const selectedCc = orchardSelectedState.cc.filter(
      //   (ele) => ele.value === previusQuarter.cc
      // );

      // 5- SETEA LOS ESTADOS SELECTED

      setCcSelectedState(selectedCcById);

      const selectedQuarterById = findQuarterByIdUnitProductive(
        selectedCcById.quarter,
        previusQuarter.idUnitProductive
      );

      // const selectedQuarter = selectedCc[0].quarter.filter(
      //   (ele) => ele.value === previusQuarter.quarter
      // );

      // console.log(selectedQuarterById, '----selectedQuarter');

      setQuarterSelectedState(selectedQuarterById);

      // const varietySelectedArray = selectedQuarterById.variety.filter(
      //   (ele) => ele.value === dashAdd(previusQuarter.variety)
      // );

      const varietySelectedArray = selectedQuarterById.variety.filter(
        (ele) => ele.idUnitProductive === previusQuarter.idUnitProductive
      );

      setVarietySelectedState(varietySelectedArray[0]);

      setIdUnitProductiveState(previusQuarter.idUnitProductive);
    }
  };

  // console.log(polygonActualIndexState, '--------actual index');

  const getColorsFromZones = (array) => {
    const newArray = array.map((item) => {
      return item.zoneColor;
    });
    return newArray;
  };

  // ZONES HANDLER Y PLANTS HANDLER SON FUNCIONES QUE EJECUTARÁ EL MAPA AL LLAMA A LA API
  const zonesHandler = (zones) => {
    const uniqueZones = zones.filter((item, index) => {
      return index === zones.findIndex((t) => t.zone === item.zone);
    });

    // console.log(zones, '-----asi llegan las zones', uniqueZones);

    const colorZones = getColorsFromZones(uniqueZones);
    // console.log(colorZones, '---colores');
    setColorsArrayState(colorZones);
    setInfoDataZonesState(uniqueZones);
    setLoadingSamplingState(false);
  };

  const plantsHandler = (
    plants,
    statusLock,
    plantsHa,
    totalPlantPorcentage,
    hectare,
    totalPlants,
    zones
  ) => {
    // console.log(plants, '----------------------plants handler----en la tabla');
    setBlockedFormState(statusLock);
    setInfoPlantsState(plants);

    // si hay plants y tienen length
    // si hay plantsHa
    // si hay totalPlantPorcentage
    // si hay zones y tienen length
    let statusValid = true;

    if (!plants) {
      statusValid = false;
    }

    if (plants?.length === 0) {
      statusValid = false;
    }

    if (plantsHa === null || plantsHa === 0) {
      statusValid = false;
    }

    if (totalPlantPorcentage === null || totalPlantPorcentage === 0) {
      statusValid = false;
    }

    if (zones === null || zones?.length === 0) {
      statusValid = false;
    }

    setDataValidationState(statusValid);

    setDataPlantsCalcState({
      plantsHa,
      totalPlantPorcentage,
      hectare,
      totalPlants,
    });

    if (statusLock) {
      setIsInitializatedState(true);
    } else {
      setIsInitializatedState(false);
    }
  };

  const histogramHandler = (histogramData) => {
    // console.log(histogramData, '---------en la tabla');

    const dataGraph = [
      {
        type: 'histogram',
        name: histogramData[0].name,
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's1',
        zIndex: -1,
        // color: 'yellow',
      },
      {
        type: 'scatter',
        data: histogramData[0].data,
        id: 's1',
        showInLegend: false,
        visible: false,
      },
      {
        type: 'histogram',
        name: histogramData[1].name,
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's2',
        zIndex: -1,
        // color: 'rgba(0, 0, 0, 0.50)',
      },
      {
        type: 'scatter',
        data: histogramData[1].data,
        id: 's2',
        showInLegend: false,
        visible: false,
      },
      {
        type: 'histogram',
        name: histogramData[2].name,
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's3',
        zIndex: -1,
        // color: 'rgba(243,177,10, 0.50)',
      },
      {
        type: 'scatter',
        data: histogramData[2].data,
        id: 's3',
        showInLegend: false,
        visible: false,
      },
      {
        type: 'histogram',
        name: histogramData[3].name,
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's4',
        zIndex: -1,
        // color: 'rgba(176,47,175, 0.50)',
      },
      {
        type: 'scatter',
        data: histogramData[3].data,
        id: 's4',
        showInLegend: false,
        visible: false,
      },
      {
        type: 'histogram',
        name: histogramData[4].name,
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's5',
        zIndex: -1,
        // color: 'rgba(176,47,175, 0.50)',
      },
      {
        type: 'scatter',
        data: histogramData[4].data,
        id: 's5',
        showInLegend: false,
        visible: false,
      },
      {
        type: 'histogram',
        name: histogramData[5].name,
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's6',
        zIndex: -1,
        // color: 'rgba(176,57,175, 0.50)',
      },
      {
        type: 'scatter',
        data: histogramData[4].data,
        id: 's6',
        showInLegend: false,
        visible: false,
      },
    ];

    // console.log(dataGraph, '---dataGraph');

    setGraphDataState(dataGraph);
  };

  // console.log(infoPlantsState, '------infoPlantsState', infoDataZonesState);

  const withInfoPlants = !!infoPlantsState;

  // console.log(withInfoPlants, '-----withInfoPlants');

  const onServerLoading = (type) => {
    // console.log(type, '---', !!type);
    if (type === 1) {
      setDataValidationState(true);
      setInfoPlantsState(null);
      setInfoDataZonesState(null);
      setDataPlantsCalcState(null);
    }
    setLoadingSamplingState(!!type);
  };

  useEffect(() => {
    if (
      orchard_filter &&
      cc_filter &&
      quarter_filter &&
      variety_filter &&
      !loadingFilterQuarterState
    ) {
      // console.log(cc_filter, quarter_filter, variety_filter, '-<<<<------');

      // handleSubmit(onSubmit)();
      setTimeout(() => {
        handleSubmit(onSubmit)();
      }, 400);
    }
  }, [loadingFilterQuarterState]);

  // console.log(watchPlantsForm, '------watchPlantsForm');

  // guarda el la grill o la acepta
  const onSaveHandler = async () => {
    // console.log(infoPlantsState, '------------save');
    setSavingDataState(true);

    // console.log(
    //   quarterSelectedState,
    //   '-----al guardar-quarterSelectedState',
    //   quarter_filter,
    //   rootstock_filter
    // );

    const idUnitProductive = quarterSelectedState.variety.find(
      (ele) => ele.value === variety_filter
    );

    console.log(
      idUnitProductive,
      '----idUnitProductive al guardar',
      infoPlantsState
    );

    const objQuery = {
      ccValue: cc_filter,
      orchardValue: orchard_filter,
      quarterValue: quarter_filter,
      varietyValue: variety_filter,
      rootStockValue: rootstock_filter,
      specieValue: specie_filter,
      idUnitProductive: idUnitProductive?.idUnitProductive || '',
      Plants: parseInt(infoPlantsState.length),
      save: true,
      calculatedGrid: infoPlantsState.length
        ? alterArrayGetIds(infoPlantsState)
        : [],
    };

    // console.log(objQuery, '------------save');

    const response = await axiosContext.authAxios.post('v1/sampling', objQuery);

    // console.log(response, '------response del SAVE');
    const { status, data } = response;
    if (status === 200) {
      // aca actualizará con la respuesta la grilla
      // console.log(data, '-------');

      const resultGrid = data.treeWorkGrid;
      // const resultGrid = data.treeWorkGrid;

      setInfoPlantsState(resultGrid);

      setPlantsSelectedGridState(resultGrid);

      setBlockedFormState(true);

      setSuccessSaveState(true);
    }
    setSavingDataState(false);
  };

  const onCloseSnack = () => {
    setSuccessSaveState(false);
  };

  // console.log(polygonActualIndexState, '-----polygonActualIndexState');
  // console.log(infoDataZonesState, '----infoDataZonesState');

  const sortArrayFunc = (array) => {
    array.sort(function (a, b) {
      if (parseInt(a.rowNumber) === parseInt(b.rowNumber)) {
        return parseInt(a.plantNumber) - parseInt(b.plantNumber);
      }
      return parseInt(a.rowNumber) > parseInt(b.rowNumber) ? 1 : -1;
    });
    return array;
  };

  const redirectFunction = (pathName) => {
    const path = pathName;

    history.push({
      pathname: path,
      grid: infoPlantsState,
      zoneColors: colorsArrayState,
    });
  };

  const onDeclineHandler = () => {
    // console.log('no acepta');
    setIsInitializatedState(true);
    setBlockedFormState(false);
  };

  const onAcceptHandler = () => {
    // console.log('acepta', infoPlantsState);
    // TODO: ejecuta el guardado
    onSaveHandler();
    setIsInitializatedState(true);
    setBlockedFormState(true);
  };

  return (
    <div className={classes.rootContent}>
      <div className={clsx('form-style', classes.rightMarginWide)}>
        <BsContainer fluid class='px-3 px-md-3'>
          <BsRow class='no-gutters mb-2 mb-md-2'>
            <BsCol class='col-12 col-md-12'>
              <Modal
                open={openModalState}
                onClose={handleCloseModal}
                className={classes.modal}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
              >
                <>
                  {loadingGraphState ? (
                    <LoadingWidget />
                  ) : (
                    <ModalGraph
                      closeAction={handleCloseModal}
                      dataModal={graphOptionsState}
                    />
                  )}
                </>
              </Modal>
              <Card elevation={0} variant='outlined'>
                <CardContent>
                  <BsRow class=''>
                    <BsCol class='col-md-12'>
                      <Typography gutterBottom variant='h4' component='h2'>
                        Recomendación Muestral
                      </Typography>
                      {renderFilters()}
                    </BsCol>
                  </BsRow>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
          <BsRow class='no-gutters'>
            <BsCol class='col-md-12 mt-3'>
              <Card elevation={0}>
                <CardContent
                  sx={{
                    p: 0,
                  }}
                >
                  <BsRow class='gutter_10'>
                    {orchardWithNoValidPolygons ? (
                      <BsCol class='col-md-12'>
                        <Alert
                          severity='info'
                          variant='filled'
                          sx={{
                            fontWeight: 700,
                            my: 2,
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}
                        >
                          {typeOfError.current}
                        </Alert>
                      </BsCol>
                    ) : null}
                    <BsCol class='col-md-6 col-lg-6 mb-3'>
                      <BsRow class=''>
                        <BsCol class='col-md-6'>
                          {quarterSelectedState ? (
                            <>
                              <Typography
                                variant='body1'
                                gutterBottom
                                sx={{ fontWeight: 700 }}
                              >
                                {orchardSelectedState &&
                                  orchardSelectedState.label}{' '}
                                -{' '}
                                {quarterSelectedState &&
                                  quarterSelectedState.label}
                              </Typography>
                              <Typography variant='body2' gutterBottom>
                                {ccSelectedState && ccSelectedState.label} -{' '}
                                {varietySelectedState &&
                                  varietySelectedState?.label}{' '}
                                -{' '}
                                {varietySelectedState &&
                                  varietySelectedState?.rootStock}
                              </Typography>
                            </>
                          ) : null}
                        </BsCol>
                        <BsCol class='col-md-6 d-flex justify-content-md-end'>
                          <div>
                            <Typography
                              variant='h5'
                              sx={{
                                mb: 1,
                                textAlign: 'center',
                              }}
                            >
                              Cambiar de cuartel
                            </Typography>
                            <div className={classes.arrowsContainerStyle}>
                              <SimpleIconButton
                                actionOnClick={handleBackQuarter}
                                variant='contained'
                                disabled={
                                  polygonActualIndexState === 0 ||
                                  !quarterSelectedState
                                }
                                labelButton='Cuartel Anterior'
                                icon={<KeyboardArrowLeftIcon />}
                                sx={{ mr: 1 }}
                              />
                              <SimpleIconButton
                                actionOnClick={handleNextQuarter}
                                variant='contained'
                                disabled={
                                  polygonActualIndexState ===
                                    orchardMaxIndexState ||
                                  !quarterSelectedState
                                }
                                labelButton='Próximo Cuartel'
                                endIcon={<KeyboardArrowRightIcon />}
                              />
                            </div>
                          </div>
                        </BsCol>
                      </BsRow>
                      <div className={classes.mapContainer}>
                        <Map
                          zonesHandler={zonesHandler}
                          plantsHandler={plantsHandler}
                          selectedGrid={plantsSelectedGridState}
                          colorsArray={colorsArrayState}
                          loadingHandler={onServerLoading}
                          histogramHandler={histogramHandler}
                          loadingState={loadingSamplingState}
                          validData={dataValidationState}
                          clearValidData={clearValidData}
                          clearMapData={orchardWithNoValidPolygons}
                        />
                        {quarterSelectedState ? (
                          <CardPaper
                            className={clsx(
                              classes.animatedCardStyle,
                              activeCardInfoState ? 'active' : ''
                            )}
                            variant='outlined'
                            sx={{
                              ml: 2,
                              mr: 6,
                              width: '-webkit-fill-available',
                            }}
                          >
                            <div>
                              <Grid
                                container
                                columnSpacing={2}
                                sx={{ width: '100%' }}
                              >
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                  {activeCardInfoState ? (
                                    <IconButton
                                      aria-label='Abrir'
                                      size='small'
                                      onClick={() =>
                                        setActiveCardInfoState(false)
                                      }
                                    >
                                      <KeyboardArrowDownIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      aria-label='Cerrar'
                                      size='small'
                                      onClick={() =>
                                        setActiveCardInfoState(true)
                                      }
                                    >
                                      <KeyboardArrowUpIcon />
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  {quarterSelectedState && (
                                    <>
                                      <Typography
                                        variant='body1'
                                        gutterBottom
                                        sx={{ fontWeight: 700 }}
                                      >
                                        {orchardSelectedState &&
                                          orchardSelectedState.label}
                                      </Typography>
                                      <Typography variant='body2' gutterBottom>
                                        {ccSelectedState &&
                                          ccSelectedState.label}
                                      </Typography>
                                      <Typography variant='body2' gutterBottom>
                                        {quarterSelectedState &&
                                          quarterSelectedState.label}{' '}
                                        ||{' '}
                                        {varietySelectedState &&
                                          varietySelectedState?.label}{' '}
                                        ||{' '}
                                        {varietySelectedState &&
                                          varietySelectedState?.rootStock}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                  {dataValidationState === true &&
                                  !loadingSamplingState ? (
                                    <Button
                                      variant='text'
                                      disabled={!graphDataState}
                                      onClick={handleOpenModal}
                                    >
                                      Histograma
                                    </Button>
                                  ) : null}
                                </Grid>
                              </Grid>
                              {dataValidationState === true ? (
                                <Grid
                                  container
                                  columnSpacing={1}
                                  sx={{ mt: 2 }}
                                >
                                  <Grid item xs={4}>
                                    <CardPaper
                                      variant='outlined'
                                      sx={{
                                        borderColor: theme.palette.boxes.light,
                                        height: '100%',
                                      }}
                                    >
                                      <Box sx={{ p: 1, textAlign: 'center' }}>
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                        >
                                          Plantas/há
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                          {dataPlantsCalcState &&
                                            numberFormatDecimals(
                                              dataPlantsCalcState?.plantsHa,
                                              1
                                            )}
                                        </Typography>
                                      </Box>
                                    </CardPaper>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <CardPaper
                                      variant='outlined'
                                      sx={{
                                        borderColor: theme.palette.grey[600],
                                      }}
                                    >
                                      <Box sx={{ p: 1, textAlign: 'center' }}>
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                        >
                                          Total Plantas Cuartel
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                          {dataPlantsCalcState &&
                                          dataPlantsCalcState?.totalPlants !== 0
                                            ? numberFormatDecimals(
                                                dataPlantsCalcState?.totalPlants,
                                                0
                                              )
                                            : '-'}
                                        </Typography>
                                      </Box>
                                    </CardPaper>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <CardPaper
                                      variant='outlined'
                                      sx={{
                                        borderColor: theme.palette.boxes.dark,
                                        height: '100%',
                                      }}
                                    >
                                      <Box sx={{ p: 1, textAlign: 'center' }}>
                                        <Typography
                                          variant='body2'
                                          gutterBottom
                                        >
                                          Hectárea
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                          {dataPlantsCalcState &&
                                            dataPlantsCalcState?.hectare}
                                        </Typography>
                                      </Box>
                                    </CardPaper>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </div>
                          </CardPaper>
                        ) : null}
                      </div>
                    </BsCol>
                    <BsCol class='col-md-6'>
                      {dataValidationState === false &&
                      !loadingSamplingState &&
                      quarterSelectedState ? (
                        <Alert
                          severity='info'
                          variant='filled'
                          sx={{
                            fontWeight: 700,
                            my: 4,
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}
                        >
                          No hay Recomendación Muestral para este Cuartel.
                        </Alert>
                      ) : null}
                      {dataValidationState ? (
                        <>
                          <div>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='stretch'
                              spacing={2}
                              style={{ marginBottom: 20 }}
                            >
                              {!loadingSamplingState ? (
                                <>
                                  <Grid item xs={12} md={4}>
                                    <Paper
                                      elevation={0}
                                      className={classes.paperContainerStyle}
                                      sx={{
                                        backgroundColor:
                                          theme.palette.boxes.dark,
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        gutterBottom
                                        className={classes.subtitleDash}
                                      >
                                        Total Plantas
                                      </Typography>
                                      <Typography variant='h2' gutterBottom>
                                        {dataPlantsCalcState?.totalPlants === 0
                                          ? '-'
                                          : dataPlantsCalcState &&
                                            numberFormatDecimals(
                                              dataPlantsCalcState?.totalPlants,
                                              0
                                            )}
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Paper
                                      elevation={0}
                                      className={classes.paperContainerStyle}
                                      sx={{
                                        backgroundColor:
                                          theme.palette.boxes.light,
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        gutterBottom
                                        className={classes.subtitleDash}
                                      >
                                        % Propuesta
                                      </Typography>
                                      <Typography variant='h2' gutterBottom>
                                        {dataPlantsCalcState?.totalPlantPorcentage ===
                                        0
                                          ? '-'
                                          : dataPlantsCalcState &&
                                            numberFormatDecimals(
                                              dataPlantsCalcState.totalPlantPorcentage,
                                              2
                                            )}
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Paper
                                      elevation={0}
                                      className={classes.paperContainerStyle}
                                      sx={{
                                        backgroundColor:
                                          theme.palette.boxes.main,
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        gutterBottom
                                        className={classes.subtitleDash}
                                      >
                                        Plantas Propuesta
                                      </Typography>
                                      <Typography variant='h2' gutterBottom>
                                        {infoPlantsState?.length &&
                                        dataPlantsCalcState?.plantsHa !== null
                                          ? infoPlantsState?.length
                                          : '-'}
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid item xs={12} md={4}>
                                    <Skeleton
                                      variant='rounded'
                                      width='100%'
                                      height={85}
                                      animation='wave'
                                      sx={{ mb: 2 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Skeleton
                                      variant='rounded'
                                      width='100%'
                                      animation='wave'
                                      height={85}
                                      sx={{ mb: 2 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Skeleton
                                      variant='rounded'
                                      width='100%'
                                      animation='wave'
                                      height={85}
                                      sx={{ mb: 2 }}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </div>
                          <div>
                            <div
                              className='table-responsive'
                              style={{
                                border: '1px solid',
                                borderColor: theme.palette.grey[300],
                                borderRadius: theme.shape.borderRadius,
                              }}
                            >
                              {!loadingSamplingState ? (
                                <table className='table mb-0'>
                                  <thead className={classes.theadLight}>
                                    <tr>
                                      <th scope='col'>Zona</th>
                                      <th scope='col'>área</th>
                                      <th scope='col'>% cuartel</th>
                                      <th className={classes.bgCellStyle}>
                                        Nº Plantas
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className={classes.tbodyStyle}>
                                    {dataPlantsCalcState?.plantsHa !== null &&
                                      dataPlantsCalcState?.totalPlantPorcentage !==
                                        0 &&
                                      infoPlantsState &&
                                      infoPlantsState.length &&
                                      infoDataZonesState &&
                                      infoDataZonesState.map((zone) => {
                                        const plantsFromZone =
                                          infoPlantsState.filter(
                                            (ele) =>
                                              parseInt(ele.zone) ===
                                              parseInt(zone.zone)
                                          ) || [];
                                        return (
                                          <tr key={`${zone.zone}`}>
                                            <td>
                                              <div
                                                className={classes.colorBox}
                                                style={{
                                                  backgroundColor:
                                                    zone.zoneColor,
                                                }}
                                              ></div>
                                            </td>
                                            <td>{zone.areaHa} ha</td>
                                            <td>{zone.percentArea} %</td>
                                            <td className={classes.bgCellStyle}>
                                              {plantsFromZone.length}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              ) : (
                                <>
                                  <Skeleton
                                    variant='rectangular'
                                    width='100%'
                                    height={45}
                                    animation='wave'
                                    sx={{ mb: 2 }}
                                  />
                                  <Skeleton
                                    variant='rectangular'
                                    width='100%'
                                    height={20}
                                    animation='wave'
                                    sx={{ mb: 2 }}
                                  />
                                  <Skeleton
                                    variant='rectangular'
                                    width='100%'
                                    height={20}
                                    animation='wave'
                                    sx={{ mb: 2 }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <Box
                            sx={{
                              border: '1px solid',
                              borderRadius: theme.shape.borderRadius + 'px',
                              borderColor: theme.palette.grey[300],
                              p: 2,
                              mt: 2,
                              boxShadow: !isInitializatedState
                                ? theme.shadows[3]
                                : '',
                            }}
                          >
                            {!isInitializatedState ? (
                              <Box>
                                <Typography
                                  variant='h6'
                                  sx={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Grilla Recomendada Analytics
                                </Typography>
                                <Typography variant='body2' sx={{ mb: 2 }}>
                                  Al <b>cambiar la grilla</b>, podrá recalcular
                                  la grilla propuesta. Al{' '}
                                  <b>Aceptar la grilla</b>, no podrá
                                  reemplazarla pero si editar las plantas.
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                  }}
                                >
                                  <ButtonAction
                                    labelButton='Cambiar Grilla'
                                    color='primary'
                                    size='large'
                                    disabled={!infoPlantsState}
                                    className='btn-block'
                                    actionOnClick={onDeclineHandler}
                                    variant='outlined'
                                    mutationLoading={loadingSamplingState}
                                    success={false}
                                  />
                                  <ButtonAction
                                    labelButton='Aceptar Grilla'
                                    color='primary'
                                    size='large'
                                    disabled={!infoPlantsState}
                                    className='btn-block'
                                    actionOnClick={onAcceptHandler}
                                    variant='contained'
                                    mutationLoading={loadingSamplingState}
                                    success={false}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <form
                                autoComplete='false'
                                noValidate
                                onSubmit={handleSubmitPerc(onSubmitPerc)}
                              >
                                <Grid
                                  container
                                  spacing={{ xs: 1, lg: 1 }}
                                  alignItems='center'
                                  justifyContent='space-between'
                                  sx={{ marginBottom: 1 }}
                                  columns={{ md: 12, lg: 12 }}
                                >
                                  <Grid item md={12} lg={6}>
                                    <Typography
                                      variant='h6'
                                      sx={{ fontWeight: 700 }}
                                    >
                                      Total Plantas
                                    </Typography>
                                    <Controller
                                      control={controlPerc}
                                      rules={{
                                        required: true,
                                      }}
                                      name='plantsForm'
                                      render={({
                                        field: { onChange, name, value },
                                      }) => (
                                        <NumberFormat
                                          thousandsGroupStyle='thousand'
                                          disabled={blockedFormState}
                                          value={value}
                                          onChange={onChange}
                                          error={Boolean(errorsPerc.plantsForm)}
                                          helperText={
                                            errorsPerc.plantsForm
                                              ? 'Complete cantidad'
                                              : ''
                                          }
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.rootHelper,
                                            },
                                          }}
                                          variant='standard'
                                          InputProps={{
                                            classes: {
                                              input: classes.inputText,
                                            },
                                            endAdornment: (
                                              <>
                                                {value && (
                                                  <IconButton
                                                    color='primary'
                                                    aria-label='clear'
                                                    component='span'
                                                    onClick={() =>
                                                      onResetPerc('plantsForm')
                                                    }
                                                  >
                                                    <ClearIcon
                                                      fontSize='small'
                                                      style={{
                                                        color:
                                                          theme.palette
                                                            .grey[400],
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                                <InputAdornment
                                                  position='end'
                                                  variant='filled'
                                                  disableTypography
                                                  style={{
                                                    color: 'black',
                                                    height: 'auto',
                                                    maxHeight: '40px',
                                                    padding: '14px 16px',
                                                    borderRadius:
                                                      theme.shape.borderRadius,
                                                    backgroundColor:
                                                      theme.palette.grey[50],
                                                  }}
                                                >
                                                  plantas
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          customInput={TextField}
                                          className='flex-grow-1'
                                          // prefix="$"
                                          decimalSeparator=','
                                          displayType='input'
                                          type='text'
                                          thousandSeparator='.'
                                          allowNegative={true}
                                          decimalScale={2}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={12}
                                    lg={6}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: {
                                        md: 'flex-end',
                                        lg: 'space-evenly',
                                      },
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    {blockedFormState ? (
                                      <HttpsIcon
                                        sx={{
                                          color: 'success.dark',
                                        }}
                                      />
                                    ) : null}
                                    <SaveAction
                                      title='Calcular'
                                      color='primary'
                                      size='large'
                                      disabled={
                                        !withInfoPlants ||
                                        savingDataState ||
                                        blockedFormState
                                      }
                                      className='btn-block'
                                      variant='outlined'
                                      messageType='add'
                                      mutationLoading={loadingSamplingState}
                                      success={false}
                                    />
                                    <div>
                                      <ButtonAction
                                        labelButton='Guardar'
                                        color='secondary'
                                        size='large'
                                        disabled={
                                          !withInfoPlants ||
                                          loadingSamplingState ||
                                          !infoPlantsState.length ||
                                          blockedFormState
                                        }
                                        className='btn-block'
                                        actionOnClick={onSaveHandler}
                                        actionClose={onCloseSnack}
                                        variant='contained'
                                        messageType='add'
                                        mutationLoading={savingDataState}
                                        success={successSaveState}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </form>
                            )}
                          </Box>
                          <div>
                            {infoPlantsState && (
                              <BsRow>
                                <BsCol class='col-md-12 mt-3'>
                                  <div
                                    className='table-responsive'
                                    style={{
                                      maxHeight: '280px',
                                      border: '1px solid',
                                      borderColor: theme.palette.grey[300],
                                      borderRadius: theme.shape.borderRadius,
                                    }}
                                    id='ifmcontentstoprint'
                                  >
                                    <table
                                      className={clsx(
                                        'table table-sm',
                                        classes.theadLightSmall
                                      )}
                                    >
                                      <thead>
                                        <tr>
                                          <th scope='col'>Detalle Plantas</th>
                                          <th
                                            colSpan={2}
                                            style={{ textAlign: 'right' }}
                                          >
                                            {/* <SimpleIconButton
                                          actionOnClick={() =>
                                            redirectFunction(
                                              '/sampling-history'
                                            )
                                          }
                                          variant='outlined'
                                          className='no-print'
                                          labelButton='Grillas'
                                          sx={{ marginRight: 1 }}
                                          icon={<HistoryIcon />}
                                          disabled={
                                            infoPlantsState?.length === 0
                                          }
                                        /> */}

                                            <SimpleIconButton
                                              actionOnClick={() =>
                                                printOrder('ifmcontentstoprint')
                                              }
                                              variant='outlined'
                                              className='no-print'
                                              sx={{ marginRight: 1 }}
                                              labelButton='PDF'
                                              icon={<PrintIcon />}
                                              disabled={
                                                infoPlantsState?.length === 0
                                              }
                                            />

                                            <SimpleIconButton
                                              actionOnClick={() =>
                                                redirectFunction(
                                                  '/sampling-edit'
                                                )
                                              }
                                              variant='outlined'
                                              className='no-print'
                                              labelButton='Editar'
                                              icon={<EditIcon />}
                                              disabled={
                                                (infoPlantsState?.length ===
                                                  0 &&
                                                  !blockedFormState) ||
                                                !isInitializatedState ||
                                                !blockedFormState
                                              }
                                            />
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            scope='col'
                                            className='text-center'
                                          >
                                            Zona
                                          </th>
                                          <th
                                            scope='col'
                                            className='text-center'
                                          >
                                            Nº Hilera
                                          </th>
                                          <th
                                            scope='col'
                                            className='text-center'
                                          >
                                            Nº Planta
                                          </th>
                                        </tr>
                                      </thead>
                                      {!loadingSamplingState ? (
                                        <tbody className={classes.tbodyStyle}>
                                          {dataPlantsCalcState?.plantsHa !==
                                            null &&
                                          infoPlantsState &&
                                          infoPlantsState.length ? (
                                            _.sortBy(
                                              sortArrayFunc(infoPlantsState),
                                              ['zone']
                                            ).map((plant, i) => {
                                              return (
                                                <tr key={`${plant.id}_${i}`}>
                                                  <td>
                                                    <div
                                                      style={{
                                                        width: '24px',
                                                        display: 'inline-block',
                                                        height: '24px',
                                                        marginRight: '15px',
                                                        marginBottom: '-4px',
                                                        backgroundColor:
                                                          colorsArrayState[
                                                            plant.zone - 1
                                                          ],
                                                      }}
                                                    ></div>
                                                    {/* {plant.zone} */}
                                                  </td>
                                                  <td>
                                                    {plant.rowNumber || '-'}
                                                  </td>
                                                  <td>
                                                    {plant.plantNumber || '-'}
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          ) : (
                                            <tr>
                                              <td colSpan={3}>
                                                <Typography
                                                  variant='body1'
                                                  gutterBottom
                                                  sx={{
                                                    textAlign: 'center',
                                                    mt: 2,
                                                  }}
                                                >
                                                  No hay plantas
                                                </Typography>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          {[1, 2, 3, 4, 5, 6].map((tr) => (
                                            <tr key={tr}>
                                              <td colSpan={3}>
                                                <Skeleton
                                                  variant='rectangular'
                                                  width='100%'
                                                  height={30}
                                                  animation='wave'
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                </BsCol>
                              </BsRow>
                            )}
                            {loadingSamplingState && (
                              <>
                                <Skeleton
                                  variant='rounded'
                                  width='100%'
                                  height={100}
                                  animation='wave'
                                  sx={{ mt: 3 }}
                                />
                                <Skeleton
                                  variant='rounded'
                                  width='100%'
                                  height={200}
                                  animation='wave'
                                  sx={{ mt: 3 }}
                                />
                              </>
                            )}
                          </div>
                        </>
                      ) : null}
                    </BsCol>
                  </BsRow>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};
export default Sampling;
