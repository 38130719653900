import React from 'react';
import clsx from 'clsx';
import {
  Divider,
  Grid,
  Typography,
  Button,
  Tooltip,
  Radio,
  useTheme,
  Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  numberFormat,
  numberFormatDecimals,
  pruneLevelTranslate,
  scenariesLevels,
  scenariesLevelsFruitsDart,
} from '../../../helpers';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import DartIcon from '../../../assets/icons/Vectordardo.png';
import TwigIcon from '../../../assets/icons/Vectorramilla.png';
import FlowerIcon from '../../../assets/icons/Groupflor.png';
import FruitsetIcon from '../../../assets/icons/Capa2cuaja.png';
import PerformanceIcon from '../../../assets/icons/Grouprendimiento.png';
import CaliberIcon from '../../../assets/icons/Vectorcalibre.png';
import { ButtonAction } from '../../../components';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: theme.palette.grey[600],
    borderColor: '#96a5b7 !important',
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main + '!important',
    fontWeight: 500 + '!important',
  },
  trTableStyle: {
    '& td': {
      verticalAlign: 'middle',
      paddingLeft: '0.20rem',
      paddingRight: '0.20rem',
      textAlign: 'center',
    },
  },
  theadTableStyle: {
    '& th': {
      paddingLeft: '0.20rem',
      paddingRight: '0.20rem',
    },
  },
  bgCellStyle: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontWeight: 700,
  },
}));

const RecommendationBox = ({
  noData,
  expectedInfo,
  withModel,
  closeFunc,
  scenaries,
  selectedScenary,
  changeHandler,
  fruitsDartEstimateState,
  alert,
  withFruitsDart,
  budDartBasal,
  saveFunc,
  saveLoading,
  saveSuccess = false,
  closeSuccessFunc,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  console.log(expectedInfo, '<------llega caja expected');
  // console.log(withModel, '<------llega withModel');
  // console.log(scenariesLevels(scenaries), '<------llega scenaries', scenaries);
  // console.log(selectedScenary, '<------llega selectedScenary');
  // console.log(withFruitsDart, '<<<<<<-----viene o no fruitsDart');

  let enableSave = true;

  if (expectedInfo) {
    const {
      flowersDart,
      flowersHa,
      yield: yieldValue,
      flowersTwig,
    } = expectedInfo;

    if (
      isNaN(flowersDart) ||
      flowersDart === null ||
      flowersDart === undefined ||
      isNaN(flowersHa) ||
      flowersHa === null ||
      flowersHa === undefined ||
      isNaN(yieldValue) ||
      yieldValue === null ||
      yieldValue === undefined
    ) {
      enableSave = false;
    }
  }

  // console.log(enableSave, '<------llega enableSave', expectedInfo);

  return (
    <>
      <div>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h4' gutterBottom className='mb-3'>
              Recomendación de Poda {withModel && 'Analytics'}{' '}
            </Typography>
            {withModel && (
              <Typography
                variant='h5'
                style={{ color: 'grey', marginTop: 10, marginBottom: 10 }}
              >
                Predicho a través de modelamiento
              </Typography>
            )}
            {noData && (
              <Typography variant='h4' color='secondary'>
                <i className='far fa-bell mr-3'></i>
                El módulo de estimación recomienda no realizar poda
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Tooltip title='Cerrar Recomendación'>
              <Button onClick={closeFunc} sx={{ marginTop: -1 }}>
                <CloseIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
      {/* row iconos */}
      <BsContainer>
        {!withModel ? (
          <>
            <BsRow class='mt-2 mb-md-2'>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Dardos
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ minHeight: '39px' }}
                >
                  <Grid item>
                    <img
                      src={DartIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.dart, 2)
                        : '---'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={clsx('mt-3 mb-2', classes.divider)} />
              </BsCol>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Ramillas
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ minHeight: '39px' }}
                >
                  <Grid item>
                    <img
                      src={TwigIcon}
                      className='img-fluid ico-recomendation'
                      alt='twig'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.twig, 2)
                        : '---'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={clsx('mt-3 mb-2', classes.divider)} />
              </BsCol>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Yemas/Dardos
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ minHeight: '39px' }}
                >
                  <Grid item>
                    <img
                      src={FlowerIcon}
                      className='img-fluid ico-recomendation'
                      alt='flower'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo && expectedInfo.budDart
                        ? numberFormatDecimals(expectedInfo.budDart, 2)
                        : expectedInfo && expectedInfo.flower
                        ? numberFormatDecimals(expectedInfo.flower, 2)
                        : '---'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={clsx('mt-3 mb-2', classes.divider)} />
              </BsCol>
            </BsRow>
            <BsRow class='mt-md-2 mb-md-2'>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Cuaja
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ minHeight: '39px' }}
                >
                  <Grid item>
                    <img
                      src={FruitsetIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.fruitSet, 2)
                        : '---'}{' '}
                      <Typography
                        component='span'
                        display='inline'
                        variant='h4'
                      >
                        %
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={clsx('mt-3 mb-2', classes.divider)} />
              </BsCol>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Calibre
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ minHeight: '39px' }}
                >
                  <Grid item>
                    <img
                      src={CaliberIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.caliber, 2)
                        : '---'}{' '}
                      <Typography
                        display='inline'
                        component='span'
                        variant='h4'
                      >
                        gr/fruto
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={clsx('mt-3 mb-2', classes.divider)} />
              </BsCol>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Rendimiento
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ minHeight: '39px' }}
                >
                  <Grid item>
                    <img
                      src={PerformanceIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.yield, 2)
                        : '---'}{' '}
                      <Typography
                        display='inline'
                        component='span'
                        variant='h4'
                      >
                        kg/ha
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={clsx('mt-3 mb-2', classes.divider)} />
              </BsCol>
            </BsRow>
            <BsRow class='mt-md-2 mb-md-4'>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Frutos/dardo
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  <Grid item>
                    <img
                      src={DartIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(fruitsDartEstimateState, 1)
                        : '---'}{' '}
                      {/* <Typography component='span' display='inline' variant='h4'>
                      %
                    </Typography> */}
                    </Typography>
                  </Grid>
                </Grid>
              </BsCol>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Flores/dardo
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  <Grid item>
                    <img
                      src={DartIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.flowersDart, 1)
                        : '---'}{' '}
                      {/* <Typography display='inline' component='span' variant='h4'>
                      gr/fruto
                    </Typography> */}
                    </Typography>
                  </Grid>
                </Grid>
              </BsCol>
              <BsCol class='col-md-6 col-lg-4 mb-4 mb-lg-0 padding-col'>
                <Typography variant='h5' gutterBottom>
                  Flores
                </Typography>
                <Grid
                  direction='row'
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  <Grid item>
                    <img
                      src={FlowerIcon}
                      className='img-fluid ico-recomendation'
                      alt='dart'
                    />
                  </Grid>
                  <Grid item className='ml-1'>
                    <Typography variant='h3' className={classes.root}>
                      {expectedInfo
                        ? numberFormatDecimals(expectedInfo.flowersHa, 0)
                        : '---'}{' '}
                      {/* <Typography display='inline' component='span' variant='h4'>
                      
                    </Typography> */}
                    </Typography>
                  </Grid>
                </Grid>
              </BsCol>
            </BsRow>
          </>
        ) : (
          <>
            <BsRow>
              <BsCol class='col-md-12'>
                {alert && (
                  <Typography
                    variant='h4'
                    className='my-3'
                    color='secondary.main'
                  >
                    <i className='far fa-bell mr-3'></i>
                    {alert}
                  </Typography>
                )}
                <div className='table-responsive'>
                  <table className='table table-striped'>
                    <thead className={classes.theadTableStyle}>
                      <tr>
                        <th></th>
                        {/* <th>Calibre</th> */}
                        <th>Nivel Poda</th>
                        <th>Dardos</th>
                        <th>Ramillas</th>
                        <th>{withFruitsDart ? 'Desyeme' : 'Yemas / dardo'}</th>
                        <th>Flores/dardo</th>
                        <th>Flores/ha</th>
                        <th>Cuaja</th>
                        <th>Frutos/dardo</th>
                        {/* <th>Yemas/dardo</th> */}
                        <th>Rendimiento (kg/ha)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* si hay escenarios, y si es o no con FRuitsDart */}
                      {scenaries && scenaries.length ? (
                        withFruitsDart ? (
                          scenaries.map((scenary, i) => {
                            return (
                              <tr
                                key={`${i}_scenary`}
                                className={clsx(
                                  classes.trTableStyle,
                                  JSON.stringify(selectedScenary) ===
                                    JSON.stringify(scenary)
                                    ? 'table-light'
                                    : ''
                                )}
                              >
                                <td>
                                  <Radio
                                    checked={
                                      JSON.stringify(selectedScenary) ===
                                      JSON.stringify(scenary)
                                    }
                                    onChange={(e) =>
                                      changeHandler(e.target.value)
                                    }
                                    value={JSON.stringify(scenary)}
                                    name='radio-buttons'
                                    inputProps={{
                                      'aria-label': `${scenary.dart}`,
                                    }}
                                  />
                                </td>
                                <td>
                                  {pruneLevelTranslate(scenary.pruneLevel)}
                                </td>
                                {/* <td>{numberFormatDecimals(scenary.caliber, 2)}</td> */}
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(scenary.dart, 0)}
                                </td>
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(scenary.twig, 0)}
                                </td>
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(
                                    scenary.budDartFinal,
                                    1
                                  )}
                                </td>
                                <td>
                                  {numberFormatDecimals(
                                    scenary.flowersDartFinal,
                                    2
                                  )}
                                </td>
                                <td>
                                  {numberFormatDecimals(scenary.flowersHa, 0)}
                                </td>
                                {/* <td>{numberFormatDecimals(scenary.budDart, 1)}</td> */}
                                <td>
                                  {numberFormatDecimals(scenary.fruitSet, 1)}
                                </td>
                                <td>
                                  {numberFormatDecimals(
                                    scenary.fruitsDartEstimate,
                                    1
                                  )}
                                </td>
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(scenary.yield, 0)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          scenaries.map((scenary, i) => {
                            return (
                              <tr
                                key={`${i}_scenary`}
                                className={clsx(
                                  classes.trTableStyle,
                                  JSON.stringify(selectedScenary) ===
                                    JSON.stringify(scenary)
                                    ? 'table-light'
                                    : ''
                                )}
                              >
                                <td>
                                  <Radio
                                    checked={
                                      JSON.stringify(selectedScenary) ===
                                      JSON.stringify(scenary)
                                    }
                                    onChange={(e) =>
                                      changeHandler(e.target.value)
                                    }
                                    value={JSON.stringify(scenary)}
                                    name='radio-buttons'
                                    inputProps={{
                                      'aria-label': `${scenary.dart}`,
                                    }}
                                  />
                                </td>
                                <td>
                                  {pruneLevelTranslate(scenary.pruneLevel)}
                                </td>
                                {/* <td>{numberFormatDecimals(scenary.caliber, 2)}</td> */}
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(scenary.dart, 0)}
                                </td>
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(scenary.twig, 0)}
                                </td>
                                <td>
                                  {numberFormatDecimals(
                                    scenary.budDartFinal,
                                    1
                                  )}
                                </td>
                                <td>
                                  {numberFormatDecimals(
                                    scenary.flowersDartFinal,
                                    2
                                  )}
                                </td>

                                <td>
                                  {numberFormatDecimals(scenary.flowersHa, 0)}
                                </td>
                                {/* <td>{numberFormatDecimals(scenary.budDart, 1)}</td> */}

                                <td>
                                  {numberFormatDecimals(scenary.fruitSet, 1)}
                                </td>
                                <td>
                                  {numberFormatDecimals(
                                    scenary.fruitsDartEstimate,
                                    1
                                  )}
                                </td>
                                <td className={classes.bgCellStyle}>
                                  {numberFormatDecimals(scenary.yield, 0)}
                                </td>
                              </tr>
                            );
                          })
                        )
                      ) : (
                        <tr>
                          <td>
                            <Typography variant='h4' color='secondary'>
                              <i className='far fa-bell mr-3'></i>
                              No hay escenarios.
                            </Typography>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </BsCol>
            </BsRow>
          </>
        )}
        <BsRow class=' justify-content-md-center'>
          <BsCol class='col-md-9'>
            {/* TODO: solo deja guardar si está completo expected */}

            {!enableSave ? (
              <Alert
                variant='outlined'
                severity='warning'
                sx={{
                  backgroundColor: theme.palette.warning.light,
                  borderColor: theme.palette.warning.main,
                  marginX: 5,
                }}
              >
                Información incompleta para Guardar Escenario.{' '}
                <small>(Flores Dardo / Flores Ha / Flores Ramilla)</small>
              </Alert>
            ) : null}

            <ButtonAction
              labelButton='Guardar Escenario'
              color='secondary'
              size='large'
              actionOnClick={saveFunc}
              fullWidth={true}
              // className='roundedButton btn-Intelligence'
              variant='contained'
              messageType='add'
              mutationLoading={saveLoading}
              actionClose={closeSuccessFunc}
              success={saveSuccess}
              disabled={!enableSave}
            />
          </BsCol>
        </BsRow>
      </BsContainer>
    </>
  );
};

export default RecommendationBox;
