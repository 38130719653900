import React, { useContext, useEffect, useReducer } from 'react';
import { OptionsContext } from './OptionsContext';
import { optionsReducer } from './optionsReducer';

const INITIAL_STATE = {
  counting_dashboard: null,
  counting_quarter: null,
  weather_resume: null,
  weather_detail: null,
  library_table_yieldcalculator: [],
};

export const OptionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(optionsReducer, INITIAL_STATE, () => {
    // const localState = localStorage.getItem('@session'); //se mantiene aunque cierre el navegador
    const localState = window.sessionStorage.getItem('@options'); // se limpia cuando cierra nevagador
    return localState ? JSON.parse(localState) : INITIAL_STATE;
  });

  const addOptions = (option) => {
    dispatch({ type: 'add_options', payload: option });
  };

  const clearOptions = () => {
    dispatch({ type: 'clear_options', payload: INITIAL_STATE });
  };

  useEffect(() => {
    // localStorage.setItem('@session', JSON.stringify(state));
    window.sessionStorage.setItem('@options', JSON.stringify(state));
  }, [state]);

  return (
    <OptionsContext.Provider
      value={{
        state,
        addOptions,
        clearOptions,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};
