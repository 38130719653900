// Built-in modules
import { useState, useEffect, useContext } from 'react';

// External modules
import { useErrorHandler } from 'react-error-boundary';
import {
  Card,
  CardContent,
  Typography,
  useTheme,
  Tooltip,
  Grid,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';

// Internal modules
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { AxiosContext } from '../../context/AxiosContext';
import { FiltersContext } from '../../context';
import { Context } from '../../context/Context';
import {
  ButtonAction,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  SelectMultipleWidget2,
  TextFieldWidgetSelectController,
  TextFieldWidgetTextTypeFile,
} from '../../components';
import { MobileFilters } from '../../containers/Library';
import { EditTable } from '../../containers/DormancyAnalysis';
import {
  alphabeticalSort,
  getOnlyValidCcs,
  getOrchardsBySpecie,
  getSeasonActiveQuarters,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
  rootContent: {
    // display: 'flex',
  },
  rightMarginWide: {},
}));

const DataAnalysis = (props) => {
  const { userClient } = useContext(Context);

  const classes = useStyles();

  const {
    state: {
      userOrchard_filter,
      orchard_filter,
      orchard_id_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const [loadingInfoState, setLoadingInfoState] = useState(false);

  const [loadingFiltersState] = useState(false);

  const [infoFilterState, setInfoFilterState] = useState(null);

  const axiosContext = useContext(AxiosContext);

  const moduleOrchardFilter = 'statusAnalysis';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  const errorHandler = useErrorHandler();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useCc = useWatch({
    control,
    name: 'cc',
  });

  // let history = useHistory();

  // const theme = useTheme();

  const onSubmit = async (values) => {
    console.log(values, '<--------', userOrchard_filter);
    // arrayGetStationIds(values.orchard, userOrchard_filter);
    // let data = {
    //   huerto: values.orchard
    //     ? alterArrayGetFields(values.orchard, ['value'])
    //     : '',
    // };

    const data = {
      orchardValue: values.orchard?.value,
      ccValue: values.cc?.value,
      quarterValue: values.quarter?.value,
      specieValue: specie_filter,
    };

    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard?.value
    );

    console.log(resultOrchard, '----');

    if (orchard_id_filter !== resultOrchard[0].id) {
      addFilter({
        cc_filter: '',
        quarter_filter: '',
      });
    }
    addFilter({
      orchard_filter: values.orchard?.value,
      orchard_id_filter: resultOrchard[0].id,
      cc_filter: values.cc?.value,
    });

    setInfoFilterState(data);
  };

  // const onSubmitDoc = async (values) => {
  //   console.log(values, '-----submit');
  //   const body = {
  //     document: values.analysisFile,
  //   };
  //   console.log(body, '---body');
  // };

  // const handleChangeFile = (_event) => {
  //   if (_event.target) {
  //     setValue(`analysisFile.file`, _event.target.files[0]);
  //   }
  // };

  // const onDownloadHandler = () => {};

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group mb-2'>
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req={true}
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={loadingFilterCcState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='cc'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={ccDataState}
                  placeholder='Centro de Costo'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.quarter}
                  loading={loadingFilterCcState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='quarter'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='Cuartel'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  useEffect(() => {
    if (orchard_filter) {
      const orchardFilterFullData = filterDataResponseState.find(
        (ele) => ele.value === orchard_filter
      );

      setValue('orchard', orchardFilterFullData);
    }
  }, []);

  useEffect(() => {
    // cada vez que hay un orchard
    // limpia cc
    // y carga la nueva data de cc
    // console.log(useOrchard, '-----useOrchard');
    if (useOrchard) {
      setValue('cc', '');

      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard.value
      );

      console.log(resultOrchard, filterDataResponseState);

      if (resultOrchard.length) {
        const ccSelect = resultOrchard[0].cc
          ? getOnlyValidCcs(resultOrchard[0].cc, specie_filter)
          : [];
        setCcDataState(alphabeticalSort(ccSelect, 'label'));
      }
    } else {
      // console.log(useOrchard, '---useOrchard NO');
      setValue('cc', '');
      setCcDataState([]);
      setQuarterDataState([]);
    }
  }, [useOrchard]);

  useEffect(() => {
    // console.log(useCc, 'useCc');
    // cada vez que hay un cc
    // limpia quarter
    // y carga la nueva data de quarter
    if (useCc) {
      setValue('quarter', '');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc.value);

      console.log(resultCc[0]?.quarter);

      let quarterArray = [];
      if (resultCc[0]?.quarter) {
        quarterArray = getSeasonActiveQuarters(resultCc[0]?.quarter);
      }

      console.log(quarterArray, 'active quarters');

      setQuarterDataState(quarterArray);
    } else {
      setValue('quarter', '');
      setQuarterDataState([]);
    }
  }, [useCc]);

  // console.log(userClient, '-------userClient');

  return (
    <div className={classes.rootContent}>
      <div className={clsx('form-style', classes.rightMarginWide)}>
        <BsContainer fluid class='px-0 px-md-3'>
          <BsRow class='no-gutters mb-md-2'>
            <BsCol class='col-12 col-md-12'>
              <Card elevation={0} variant='outlined'>
                <CardContent>
                  <BsRow class=''>
                    <BsCol class='col-md-12'>
                      <Typography gutterBottom variant='h4' component='h2'>
                        Análisis Rompedores Dormancia
                      </Typography>
                      {renderFilters()}
                    </BsCol>
                  </BsRow>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
          <BsRow class='no-gutters'>
            <BsCol class='col-md-12'>
              <Card elevation={0}>
                <CardContent>
                  {/* <BsRow class='justify-content-between'>
                  <BsCol class='col-md-3'>
                    <Button
                      color='primary'
                      onClick={onDownloadHandler}
                      disabled={false}
                    >
                      DESCARGAR ARCHIVO
                    </Button>
                  </BsCol>
                  <BsCol class='col-md-7 col-lg-6 col-xl-5'>
                    <form
                      autoComplete='false'
                      noValidate
                      onSubmit={handleSubmit(onSubmitDoc)}
                    >
                      <Grid
                        container
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='flex-start'
                        spacing={0}
                      >
                        <Grid item xs={12} md={9}>
                          <TextFieldWidgetTextTypeFile
                            control={control}
                            name='analysisFile'
                            multiple={false}
                            req={true}
                            onChangeFile={handleChangeFile}
                            labeltext='Subir Archivo'
                            errors={errors.analysisFile}
                          />
                        </Grid>
                        <Grid item xs>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingTop: 8,
                            }}
                          >
                            <SaveAction
                              title='Subir'
                              color='secondary'
                              size='large'
                              startIcon={<AttachFileIcon />}
                              disabled={false}
                              className='btn-block'
                              // variant='outlined'
                              messageType='add'
                              mutationLoading={false}
                              success={false}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </BsCol>
                </BsRow> */}
                  <EditTable
                    filter={infoFilterState}
                    module='DormancyAnalysis'
                  />
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};
export default DataAnalysis;
