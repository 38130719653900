import React, { createContext, useState, useContext } from 'react';
import TagManager from 'react-gtm-module';
// import { initialState, reducer } from '../store/reducers';
export const Context = createContext();

const Provider = ({ children }) => {
  // const [stateProject, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState(window.sessionStorage.getItem('token'));
  const [userId, setUserId] = useState(window.sessionStorage.getItem('userId'));
  const [userName, setUserName] = useState(
    window.sessionStorage.getItem('userName')
  );
  const [userLastname, setUserLastname] = useState(
    window.sessionStorage.getItem('userLastname')
  );
  const [userFullname, setUserFullname] = useState(
    window.sessionStorage.getItem('userFullname')
  );
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem('userProfile'))
  );
  const [userIdProfile, setUserIdProfile] = useState(
    window.sessionStorage.getItem('userIdProfile')
  );
  const [userOrchard, setUserOrchard] = useState(
    JSON.parse(localStorage.getItem('userOrchard'))
  );
  const [moduleUser, setModuleUser] = useState(
    JSON.parse(localStorage.getItem('moduleUser'))
  );
  const [userClient, setUserClient] = useState(
    JSON.parse(localStorage.getItem('userClient'))
  );

  const [initialRoute, setInitialRoute] = useState(
    window.sessionStorage.getItem('initialRoute')
  );

  const [userSpecies, setUserSpecies] = useState(
    JSON.parse(localStorage.getItem('userSpecies'))
  );

  const setUpdateUserFullname = (fullname) => {
    setUserFullname(fullname);
    window.sessionStorage.setItem('userFullname', userFullname);
  };

  const setUserSpeciesHandler = (userSpecies) => {
    setUserSpecies(userSpecies);
    localStorage.setItem('userSpecies', JSON.stringify(userSpecies));
  };

  // const [projectSelectedUser, setProjectSelectedUser] = useState(
  //   JSON.parse(localStorage.getItem('userProjectSelected'))
  // );

  // const [projectsListUser, setProjectListUser] = useState(
  //   JSON.parse(localStorage.getItem('userProjectList'))
  // );

  // console.log(projectSelectedUser, projectsListUser, "en el context");
  const value = {
    token: token,
    userId: userId,
    userName: userName,
    userLastname: userLastname,
    userFullname: userFullname,
    userProfile: userProfile,
    userIdProfile: userIdProfile,
    userOrchard: userOrchard,
    moduleUser: moduleUser,
    userClient: userClient,
    initialRoute: initialRoute,
    userSpecies: userSpecies,
    login: (
      token,
      userId,
      userName,
      userLastname,
      userFullname,
      userProfile,
      userIdProfile,
      userOrchard,
      moduleUser,
      userClient,
      initialRoute,
      userSpecies = []
    ) => {
      setToken(token);
      setUserId(userId);
      setUserName(userName);
      setUserLastname(userLastname);
      setUserFullname(userFullname);
      setUserProfile(userProfile);
      setUserOrchard(userOrchard);
      setModuleUser(moduleUser);
      setUserIdProfile(userIdProfile);
      setUserClient(userClient);
      setInitialRoute(initialRoute);
      setUserSpecies(userSpecies);
      window.sessionStorage.setItem('token', token);
      window.sessionStorage.setItem('userId', userId);
      window.sessionStorage.setItem('userName', userName);
      window.sessionStorage.setItem('userLastname', userLastname);
      window.sessionStorage.setItem('userFullname', userFullname);
      window.sessionStorage.setItem('initialRoute', initialRoute);
      // window.sessionStorage.setItem('userProfile', userProfile);
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
      window.sessionStorage.setItem('userIdProfile', userIdProfile);
      localStorage.setItem('userOrchard', JSON.stringify(userOrchard));
      localStorage.setItem('moduleUser', JSON.stringify(moduleUser));
      localStorage.setItem('userClient', JSON.stringify(userClient));
      localStorage.setItem('userSpecies', JSON.stringify(userSpecies));
    },
    logout: () => {
      setToken(null);
      setUserId(null);
      setUserName(null);
      setUserLastname(null);
      setUserFullname(null);
      setUserProfile(null);
      setUserOrchard(null);
      setUserIdProfile(null);
      setModuleUser(null);
      setUserClient(null);
      setInitialRoute(null);
      setUserSpecies(null);
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('userId');
      window.sessionStorage.removeItem('userName');
      window.sessionStorage.removeItem('userLastname');
      window.sessionStorage.removeItem('userFullname');
      window.sessionStorage.removeItem('initialRoute');
      // window.sessionStorage.removeItem('userProfile');
      localStorage.removeItem('userProfile');
      window.sessionStorage.removeItem('userIdProfile');
      // localStorage.removeItem('userProjectSelected');
      localStorage.removeItem('userOrchard');
      localStorage.removeItem('moduleUser');
      localStorage.removeItem('userClient');
      localStorage.removeItem('userSpecies');

      // const tagManagerArgs = {
      //   gtmId: 'GTM-W87D5MS',
      //   dataLayer: {
      //     userId: null,
      //     clientName: null,
      //     fullName: null,
      //     userName: null,
      //   },
      // };
      // TagManager.initialize(tagManagerArgs);
      if (window.dataLayer) {
        // Clear the entire dataLayer
        window.dataLayer = [];
      }
    },
    setUserFullname: setUpdateUserFullname,
    setUserSpeciesHandler: setUserSpeciesHandler,

    // projectSelected: projectSelectedUser,
    // projectList: projectsListUser,
    // setProjectList: (projects) => {
    // 	setProjectListUser(projects);
    // 	// localStorage.setItem('userProjectList', JSON.stringify(projects));
    // },
    // setProjectSelected: (project) => {
    // 	setProjectSelectedUser(project);
    // 	localStorage.setItem('userProjectSelected', JSON.stringify(project));
    // },
    // dispatch
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export const useStore = () => useContext(Context);
export default {
  Provider,
  Consumer: Context.Consumer,
};
