import React from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { IconButton, Tooltip } from '@mui/material';
import '../style.scss';

const IconActionTableDetail = (props) => {
	const { handleActionDetail } = props;
	//<IconButton aria-label="delete" onClick={() => handleActionDetail(row)}>
	return (
		<Tooltip title="Detalle" className="buttonIconAction">
			<IconButton
				aria-label="detail"
				onClick={handleActionDetail}
				color="info"
				// classes={{
				// 	label: 'buttonContainer buttonPrimary',
				// }}
			>
				<ZoomInIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default IconActionTableDetail;
