import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Card,
  CardContent,
  Typography,
  useTheme,
  Divider,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import { createBreakpoint } from 'react-use';
import { useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import {
  numberFormat,
  isNumber,
  findActualYear,
  IsDesktopHandler,
  addCcQuarterListToData,
  numberFormatDecimals,
  alphabeticalSort,
  getOrchardsBySpecie,
} from '../../helpers';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
import { OrchardHeatmapMap } from 'containers/Counting';
import { FiltersContext } from '../../context';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  CardPaper,
} from '../../components';
import { MobileFilters } from 'containers/Library';
import { dashDelete, getCoords } from 'helpers/functionHelpers';

const useBreakpoint = createBreakpoint({
  xl: 1563,
  lg: 1200,
  md: 900,
  sm: 600,
  xs: 0,
});

const useStyles = makeStyles((theme) => ({
  tableStyles: {
    marginTop: 5,
    '& td': {
      padding: '0.35rem',
      fontSize: 14,
    },
  },
  activeButton: {
    backgroundColor: 'rgba(127,146,167, 0.3) !important',
  },
  infoCol: {
    fontWeight: 500,
  },
  animatedCardStyle: {
    position: 'absolute',
    bottom: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    zIndex: 100,
    transform: 'translateY(250px)',
    transition: 'transform 0.3s ease-in-out',
    '&.active': {
      transform: 'translateY(0px)',
    },
  },
  flexChildsStyle: {
    '& > *': {
      flex: 1,
    },
  },
  buttonAreaStyle: {
    width: '100%',
    '&.active': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.main,
    },
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
}));

const COUNTER_TYPE_MOCK = [
  { value: 'Pre_Poda', label: 'Pre Poda' },
  { value: 'Post_Poda', label: 'Post Poda' },
  { value: 'Pre_Raleo', label: 'Pre Raleo' },
  { value: 'Post_Raleo', label: 'Post Raleo' },
];

const getMinMax = (array) => {
  // Obtener los valores de dart y twig
  const darts = array.map((item) => item.dart);
  const twigs = array.map((item) => item.twig);
  const fruits = array.map((item) => item.totalCounts);

  // Calcular el valor máximo y mínimo de dart
  const maxDart = Math.max(...darts);
  const minDart = Math.min(...darts);

  // Calcular el valor máximo y mínimo de twig
  const maxTwig = Math.max(...twigs);
  const minTwig = Math.min(...twigs);

  // Calcular el valor máximo y mínimo de fruit
  const maxFruit = Math.max(...fruits);
  const minFruit = Math.min(...fruits);

  return {
    maxDart,
    minDart,
    maxTwig,
    minTwig,
    maxFruit,
    minFruit,
  };
};

const getMaxMinPercentage = (data) => {
  let maximo = Number.MIN_VALUE; // Inicializamos el máximo con el valor mínimo posible
  let minimo = Number.MAX_VALUE; // Inicializamos el mínimo con el valor máximo posible

  // Recorremos el array de objetos para encontrar el máximo y mínimo
  data.forEach((obj) => {
    const percentage = obj.percentage;
    if (percentage > maximo) {
      maximo = percentage; // Actualizamos el máximo si encontramos uno mayor
    }
    if (percentage < minimo) {
      minimo = percentage; // Actualizamos el mínimo si encontramos uno menor
    }
  });

  return { maximo, minimo };
};

const moduleOrchardFilter = 'statusCounter';

const OrchardHeatmap = (props) => {
  const { window } = props;

  const breakpoint = useBreakpoint();

  // console.log(window, '-----------------------', width);

  const history = useHistory();

  const errorHandler = useErrorHandler();

  const { state: filterState, addFilter } = useContext(FiltersContext);
  const { userClient } = useContext(Context);
  const axiosContext = useContext(AxiosContext);

  const abortControllerRef = useRef(null);

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    quarter_filter,
    specie_filter,
    idUnitProductive,
    isLoading,
  } = filterState;
  const [buttonClickState, setButtonClickState] = useState(false);

  const [counterTypeDataState, setCounterTypeDataState] =
    useState(COUNTER_TYPE_MOCK);

  const classes = useStyles();

  const [loadingFilterState, setLoadingFilterState] = useState(false);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter])
  );

  const [orchardDataMenuState, setOrchardDataMenuState] = useState(null);

  const [quarterDataState, setQuarterDataState] = useState([]);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);
  const [orchardDataState, setOrchardDataState] = useState(null);
  const [ccSelectedState, setCcSelectedState] = useState(null);
  const [quarterSelectedState, setQuarterSelectedState] = useState(null);
  const [varietySelectedState, setVarietySelectedState] = useState(null);

  const [drawerPolygonSelectedState, setDrawerPolygonSelectedState] =
    useState(null);

  const [activeCardInfoState, setActiveCardInfoState] = useState(false);

  const [loadingSamplingState, setLoadingSamplingState] = useState(false);

  // INFO COMPLETA
  const [infoHeatmapState, setInfoHeatmapState] = useState(null);
  const [infoHeatmapQuarterState, setInfoHeatmapQuarterState] = useState(null);

  // HEAT
  const [positionsHeatState, setPositionsHeatState] = useState(null);

  // PLANTAS
  const [data_layer2State, setData_layer2State] = useState(null);

  const [minMaxState, setMinMaxState] = useState(null);

  const [idsWithDataState, setIdsWithDataState] = useState([]);

  const theme = useTheme();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const watchCounterType = watch('counterType', '');

  useEffect(() => {
    setValue('counterType', COUNTER_TYPE_MOCK[0]);
  }, []);

  useEffect(() => {
    if (orchard_filter) {
      console.log(orchard_filter, '------------------orchard_filter');

      const resultOrchard = userOrchard_filter.find(
        (ele) => ele.value === orchard_filter
      );

      setValue('orchard', resultOrchard);
    }
  }, [orchard_filter]);

  const clickButtonHandler = () => {
    setButtonClickState(!buttonClickState);
  };

  useEffect(() => {
    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele.id === orchard_id_filter
    );

    if (resultOrchard.length) {
      setOrchardSelectedState(resultOrchard[0].id);
    }

    const resultOrchardCopy = resultOrchard[0]
      ? JSON.parse(JSON.stringify(resultOrchard[0]))
      : {};

    let newObjData = {};
    newObjData = addCcQuarterListToData(resultOrchardCopy, specie_filter);

    setOrchardDataMenuState(newObjData);

    // console.log(newObjData, '------------------newObjData');

    // TODO: alimentar el select de cuarteles
    setQuarterDataState(alphabeticalSort(newObjData?.quarterList, 'cc_label'));
  }, [orchard_id_filter, useOrchard]);

  // console.log(searchResultsQuarter, '<--------------searchResultsQuarter');

  const onServerLoading = (type) => {
    // console.log(type, '---', !!type);
    setLoadingSamplingState(!!type);
  };

  const getMinMaxValues = (data) => {
    const mixMaxData = data ? getMinMax(data) : null;

    setMinMaxState(mixMaxData);
  };

  const extractArrayWeightPointsHandler = (data) => {
    const dataPlants = data.map((item) => item.plants);

    // console.log(dataPlants, '------------------dataPlants');

    // recorre cada cuartel-plants y le agrega peso

    const dataPlantsWeightInner = dataPlants.map((arrayTrees) => {
      let totalPercentage = 0;
      return arrayTrees.map((plant) => {
        const coords = plant.geom ? getCoords(plant.geom.coordinates)[0] : null;

        const percentage = plant.percentage;

        totalPercentage += percentage;

        const maxPerc = getMaxMinPercentage(arrayTrees).maximo;
        const minPerc = getMaxMinPercentage(arrayTrees).minimo;

        const minRange = minPerc * 1.1;
        const midRange = (minPerc + maxPerc) / 2;
        const maxRange = maxPerc * 0.95;

        const maxValueHeat = maxPerc + 10;

        let weightPoint = 1;
        if (percentage < minRange) {
          weightPoint = percentage * 1.1;
        } else if (percentage > minRange && percentage < midRange) {
          weightPoint = percentage * 1.15;
        } else if (percentage > midRange && percentage < maxRange) {
          // console.log('entre 5 y 6.2');
          weightPoint = percentage * 2;
        } else if (percentage > maxRange) {
          weightPoint = percentage * 7;
        }

        // return {
        //   ...coords,
        //   weight: weightPoint,
        // };

        if (coords?.lat && coords?.lng) {
          return {
            ...coords,
            weight: weightPoint,
          };
        } else {
          return null;
        }
      });
    });

    // devuelve un array para marcar plantas en el mapa y otro para crear los heatmap
    // eliminar nulls
    const dataPlantsWeight = dataPlantsWeightInner.map((subArray) =>
      subArray.filter((item) => item !== null)
    );

    return {
      dataPlantsWeight,
      dataPlants,
    };
  };

  const searchDataHandler = async (orchard, counterType) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    onServerLoading(1);

    try {
      const params = {
        clientValue: userClient?.value,
        orchardValue: orchard,
        counterType: counterType,
        specieValue: specie_filter,
      };

      const response = await axiosContext.authAxios.get(
        'v1/hotmapcounter/orchard',
        {
          params: params,
          signal: abortControllerRef.current?.signal,
        }
      );

      // console.log(response, '--------------------------response', params);

      const { data, status } = response;

      if (status === 200) {
        // TODO: debe por un lado cargar todos los plants procesados con extractArrayWeightPointsHandler a un estado para heatmap
        // y por otro lado cargar data a otro estado

        // console.log(data, '------------------data');
        if (data.length) {
          const { dataPlantsWeight, dataPlants } =
            extractArrayWeightPointsHandler(data);

          const resultOrchardPlants = dataPlants.length ? dataPlants : [];
          const resultOrchardHeatmaps = dataPlantsWeight.length
            ? dataPlantsWeight
            : [];

          // console.log(
          //   resultOrchardPlants,
          //   '------------------resultOrchardPlants'
          // );

          setPositionsHeatState(resultOrchardHeatmaps);

          setData_layer2State(resultOrchardPlants);

          setInfoHeatmapState(data);

          setIdsWithDataState(data.map((ele) => ele.idUnitProductive));
        } else {
          setPositionsHeatState(null);
          setData_layer2State(null);
          setInfoHeatmapState(null);
          setIdsWithDataState([]);
        }

        onServerLoading(0);
      } else {
        onServerLoading(0);
      }
    } catch (error) {
      if (error.name === 'CanceledError') {
      } else {
        errorHandler(error);
      }
    } finally {
      onServerLoading(0);
    }
  };

  // console.log(idsWithDataState, '------------------idsWithDataState');

  const handleButtonClick = (idOrchard, value, type, counterType) => {
    // console.log(idOrchard, value, type, '<------------------CLICK EN BOTON');
    setCcSelectedState(null);
    setQuarterSelectedState(null);

    setPositionsHeatState(null);
    setData_layer2State(null);

    if (orchard_id_filter !== idOrchard) {
      addFilter({
        cc_filter: '',
        quarter_filter: '',
      });
    }
    addFilter({
      orchard_filter: value,
      orchard_id_filter: idOrchard,
    });
    if (type === 2) {
      setOrchardSelectedState(idOrchard);
      // console.log(widthWindow, '--------------widthWindow');
    }

    const dataOrchard = userOrchard_filter.find((ele) => ele.value === value);

    setOrchardDataState(dataOrchard);

    searchDataHandler(value, counterType);

    clickButtonHandler();
  };

  const handleQuarterClick = (cc, quarter) => {
    // console.log(cc, quarter, '---------------------------handleQuarterClick');

    // TODO: si tiene más de un cuartel buscar el que esté en mapa

    setCcSelectedState(cc);
    setQuarterSelectedState(quarter);
    setDrawerPolygonSelectedState({
      cc,
      quarter,
    });
    addFilter({
      cc_filter: cc?.value,
      quarter_filter: quarter?.value,
    });
    // setActiveCardInfoState(true);

    // TODO: filtrar la data, seleccionar la info a mostrar
    // obtener ID UNITPRODUCTIVE
    setVarietySelectedState(quarter?.variety[0]);

    // find en infoHeatmapState por idUnitProductive
    if (infoHeatmapState && infoHeatmapState.length) {
      const resultInfoHeatmapQuarter = infoHeatmapState.find(
        (ele) => ele.idUnitProductive === quarter?.variety[0]?.idUnitProductive
      );

      getMinMaxValues(resultInfoHeatmapQuarter?.plants);

      setInfoHeatmapQuarterState(resultInfoHeatmapQuarter);
    }
  };

  useEffect(() => {
    if (cc_filter && quarter_filter && orchardDataMenuState) {
      // console.log(
      // 	orchardDataMenuState,
      // 	'-------',
      // 	cc_filter,
      // 	'------',
      // 	quarter_filter
      // );
      const ccSelectedMap = orchardDataMenuState.cc.filter(
        (ele) => ele.value === cc_filter
      );

      let quarterSelectedMap = null;

      if (ccSelectedMap.length) {
        quarterSelectedMap = ccSelectedMap[0].quarter.filter(
          (ele) => ele.value === quarter_filter
        );
      }

      // console.log(
      //   ccSelectedMap,
      //   '--------------------------------------recibido de mapa-----------',
      //   quarterSelectedMap
      // );

      // sino encontró el cuartel por value lo busca por idUnitProductive
      if (quarterSelectedMap.length === 0) {
        // find en ccSelectedMap por el idUnitProductive
        const resultQuarterById = ccSelectedMap[0].quarter.filter(
          (ele) => ele.variety[0].idUnitProductive === idUnitProductive
        );

        quarterSelectedMap = resultQuarterById;
      }

      setOrchardSelectedState(orchard_id_filter);
      setCcSelectedState(ccSelectedMap[0]);
      setQuarterSelectedState(
        quarterSelectedMap ? quarterSelectedMap[0] : null
      );

      handleQuarterClick(ccSelectedMap[0], quarterSelectedMap[0]);
    }
  }, [cc_filter, quarter_filter]);

  const multiVarietalQuarter = (varieties) => {
    const renderVariety = varieties.map((variety, i) => {
      return (
        <React.Fragment key={variety?.label}>
          <tr>
            <td>Variedad {i + 1}</td>
            <td className={classes.infoCol}>{variety?.label}</td>
          </tr>
          <tr>
            <td>Portainjerto {i + 1}</td>
            <td className={classes.infoCol}>{variety.rootStock}</td>
          </tr>
        </React.Fragment>
      );
    });
    return renderVariety;
  };

  const actionHandler = useCallback(
    (orchard, counterType) => {
      handleButtonClick(orchard.id, orchard.value, 2, counterType.value);
    },
    [handleButtonClick, useOrchard]
  );

  useEffect(() => {
    console.log('entra en el useEffect de useOrchard 1', useOrchard);
    if (useOrchard) {
      actionHandler(useOrchard, watchCounterType);
      setValue('quarter', '');
    } else {
      setOrchardSelectedState(null);
      setQuarterDataState([]);
      setValue('quarter', '');
    }
  }, [useOrchard, watchCounterType]);

  // const infoOrchardQuarterBox = (
  //   <div>
  //     {Boolean(orchardSelectedState) ? (
  //       <>
  //         <div>
  //           <Typography variant='h5' component='h2' color='secondary'>
  //             Información Cuartel
  //           </Typography>
  //           {/* {console.log(
  // 									orchardDataMenuState,
  // 									'------------------orchardDataMenuState'
  // 								)} */}
  //           {orchardDataMenuState && (
  //             <table
  //               className={clsx(
  //                 'table table-borderless table-sm',
  //                 classes.tableStyles
  //               )}
  //             >
  //               <tbody>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       width: '35%',
  //                     }}
  //                   >
  //                     Huerto
  //                   </td>
  //                   <td className={classes.infoCol}>
  //                     {orchardDataMenuState.label}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Centro de costo</td>
  //                   <td className={classes.infoCol}>
  //                     {ccSelectedState && ccSelectedState.label}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Cuartel</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState && quarterSelectedState.label}
  //                   </td>
  //                 </tr>
  //                 {quarterSelectedState &&
  //                 quarterSelectedState?.variety.length > 1 ? (
  //                   multiVarietalQuarter(quarterSelectedState?.variety)
  //                 ) : (
  //                   <>
  //                     <tr>
  //                       <td>Variedad</td>
  //                       <td className={classes.infoCol}>
  //                         {quarterSelectedState &&
  //                           quarterSelectedState?.variety[0]?.label}
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Portainjerto</td>
  //                       <td className={classes.infoCol}>
  //                         {quarterSelectedState &&
  //                           quarterSelectedState?.variety[0]?.rootStock}
  //                       </td>
  //                     </tr>
  //                   </>
  //                 )}
  //                 <tr>
  //                   <td>Hectárea</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState &&
  //                       numberFormatDecimals(
  //                         quarterSelectedState?.variety[0]?.hectare,
  //                         2
  //                       )}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Plantas/há</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState &&
  //                       numberFormat(
  //                         quarterSelectedState?.variety[0]?.plantsHa
  //                       )}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Plantas Totales</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState &&
  //                       numberFormatDecimals(
  //                         quarterSelectedState?.variety[0]?.plantsHa *
  //                           quarterSelectedState?.variety[0]?.hectare,
  //                         0
  //                       )}
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           )}
  //         </div>
  //       </>
  //     ) : null}
  //   </div>
  // );

  const isDesktop = IsDesktopHandler('lg');

  const onSubmit = async (values) => {
    handleQuarterClick(values.quarter.cc_data, values.quarter.quarter_data);
  };

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className={clsx('form-inline', classes.flexChildsStyle)}
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFilterState ? (
            <>
              <div className='input-group mb-2'>
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFilterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  containerStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                  isDisabled={loadingSamplingState}
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.quarter}
                  loading={false}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='quarter'
                  display='vertical'
                  containerStyle={{ width: '100%' }}
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='Cuarteles'
                  isDisabled={loadingSamplingState}
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.counterType}
                  loading={loadingFilterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='counterType'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={false}
                  // isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={counterTypeDataState}
                  placeholder='Tipo de Conteo'
                  isDisabled={loadingSamplingState}
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFilterState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='mb-2 mb-md-5'>
          <BsCol class='col-lg-9 col-md-8 mb-2 mb-md-0'>
            <Card elevation={0} variant='outlined'>
              <CardContent
                sx={{
                  paddingBottom: '8 px !important',
                }}
              >
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Mapa
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-12'>{renderFilters()}</BsCol>
                </BsRow>
              </CardContent>
            </Card>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                mt: 2,
                height: {
                  xs: '58vh',
                  sm: '65vh',
                  md: '75vh',
                  lg: '80vh',
                },
                borderRadius: theme.shape.borderRadius + 'px',
                overflow: 'hidden',
              }}
            >
              <OrchardHeatmapMap
                buttonClick={buttonClickState}
                orchardHandler={handleButtonClick}
                quarterSelected={drawerPolygonSelectedState}
                plantsData={data_layer2State}
                positionsHeat={positionsHeatState}
                isLgWindow={isDesktop}
                loadingState={loadingSamplingState}
                idsWithData={idsWithDataState}
              />
              {/* {quarterSelectedState ? (
                <CardPaper
                  className={clsx(
                    classes.animatedCardStyle,
                    activeCardInfoState ? 'active' : ''
                  )}
                  variant='outlined'
                  sx={{
                    ml: 2,
                    mr: 6,
                    width: '-webkit-fill-available',
                  }}
                >
                  <div>
                    <Grid container columnSpacing={2} sx={{ width: '100%' }}>
                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {activeCardInfoState ? (
                          <IconButton
                            aria-label='Abrir'
                            size='small'
                            onClick={() => setActiveCardInfoState(false)}
                          >
                            <KeyboardArrowDownIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label='Cerrar'
                            size='small'
                            onClick={() => setActiveCardInfoState(true)}
                          >
                            <KeyboardArrowUpIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    {infoOrchardQuarterBox}
                  </div>
                </CardPaper>
              ) : null} */}
            </Box>
          </BsCol>
          <BsCol class='col-lg-3 col-md-4'>
            <div>
              <Typography variant='h5' component='h2' color='secondary'>
                Información Cuartel
              </Typography>
              <Divider className={classes.divider} />
              {quarterSelectedState && (
                <table
                  className={clsx(
                    'table table-borderless table-sm',
                    classes.tableStyles
                  )}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: '35%',
                        }}
                      >
                        Huerto
                      </td>
                      <td className={classes.infoCol}>
                        {orchardDataState && orchardDataState.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Centro de costo</td>
                      <td className={classes.infoCol}>
                        {ccSelectedState && ccSelectedState.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Cuartel</td>
                      <td className={classes.infoCol}>
                        {quarterSelectedState && quarterSelectedState.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Variedad</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState && varietySelectedState?.label}
                      </td>
                    </tr>
                    <tr>
                      <td>Portainjerto</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState &&
                          varietySelectedState?.rootStock}
                      </td>
                    </tr>
                    <tr>
                      <td>Hectárea</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState && varietySelectedState?.hectare}
                      </td>
                    </tr>
                    <tr>
                      <td>Plantas/há</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState &&
                          numberFormat(varietySelectedState?.plantsHa)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Plantas Cuartel</td>
                      <td className={classes.infoCol}>
                        {varietySelectedState &&
                          numberFormat(
                            varietySelectedState?.hectare *
                              varietySelectedState?.plantsHa
                          )}
                      </td>
                    </tr>
                    {/* data del endpoint */}
                    <tr>
                      <td>Tipo Conteo</td>
                      <td className={classes.infoCol}>
                        {infoHeatmapQuarterState &&
                          dashDelete(infoHeatmapQuarterState.counterType)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Plantas Contadas</td>
                      <td className={classes.infoCol}>
                        {infoHeatmapQuarterState &&
                          numberFormatDecimals(
                            infoHeatmapQuarterState.plantCounts,
                            0
                          )}
                      </td>
                    </tr>
                    {infoHeatmapQuarterState &&
                    infoHeatmapQuarterState.plants.length === 0 ? (
                      <tr className='table-warning'>
                        <td>AVISO</td>
                        <td className={classes.infoCol}>
                          {infoHeatmapQuarterState &&
                            infoHeatmapQuarterState.message}
                        </td>
                      </tr>
                    ) : null}
                    {infoHeatmapQuarterState &&
                      (infoHeatmapQuarterState.counterType === 'Pre_Poda' ||
                        infoHeatmapQuarterState.counterType ===
                          'Post_Poda') && (
                        <>
                          <tr>
                            <td>Promedio de Dardos Cuartel</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapQuarterState &&
                                numberFormatDecimals(
                                  infoHeatmapQuarterState.promedioDardosCuartel,
                                  0
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Promedio de Ramillas Cuartel</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapQuarterState &&
                                numberFormatDecimals(
                                  infoHeatmapQuarterState.promedioRamillasCuartel,
                                  0
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                    {infoHeatmapQuarterState &&
                      (infoHeatmapQuarterState.counterType === 'Pre_Raleo' ||
                        infoHeatmapQuarterState.counterType ===
                          'Post_Raleo') && (
                        <>
                          <tr>
                            <td>Total Frutos</td>
                            <td className={classes.infoCol}>
                              {infoHeatmapQuarterState &&
                                numberFormatDecimals(
                                  infoHeatmapQuarterState.fruits,
                                  1
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                    {infoHeatmapQuarterState &&
                      infoHeatmapQuarterState.counterType === 'Pre_Raleo' && (
                        <tr>
                          <td>Promedio de Frutos Cuartel Pre Raleo</td>
                          <td className={classes.infoCol}>
                            {infoHeatmapQuarterState &&
                              numberFormatDecimals(
                                infoHeatmapQuarterState.promedioFrutosCuartelPreRaleo,
                                1
                              )}
                          </td>
                        </tr>
                      )}
                    {infoHeatmapQuarterState &&
                      infoHeatmapQuarterState.counterType === 'Post_Raleo' && (
                        <tr>
                          <td>Promedio de Frutos Cuartel Post Raleo</td>
                          <td className={classes.infoCol}>
                            {infoHeatmapQuarterState &&
                              numberFormatDecimals(
                                infoHeatmapQuarterState.promedioFrutosCuartelPostRaleo,
                                1
                              )}
                          </td>
                        </tr>
                      )}
                    {infoHeatmapQuarterState &&
                    infoHeatmapQuarterState.plants.length !== 0 &&
                    (infoHeatmapQuarterState.counterType === 'Pre_Poda' ||
                      infoHeatmapQuarterState.counterType === 'Post_Poda') ? (
                      <>
                        <tr>
                          <td>Mínimo/Máximo de Dardos</td>
                          <td className={classes.infoCol}>
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.minDart, 0)}{' '}
                            -{' '}
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.maxDart, 0)}
                          </td>
                        </tr>
                        <tr>
                          <td>Mínimo/Máximo de Ramillas</td>
                          <td className={classes.infoCol}>
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.minTwig, 0)}{' '}
                            -{' '}
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.maxTwig, 0)}
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {infoHeatmapQuarterState &&
                    infoHeatmapQuarterState.plants.length !== 0 &&
                    (infoHeatmapQuarterState.counterType === 'Pre_Raleo' ||
                      infoHeatmapQuarterState.counterType === 'Post_Raleo') ? (
                      <>
                        <tr>
                          <td>Mínimo/Máximo de Frutos</td>
                          <td className={classes.infoCol}>
                            {minMaxState &&
                              numberFormatDecimals(
                                minMaxState.minFruit,
                                0
                              )}{' '}
                            -{' '}
                            {minMaxState &&
                              numberFormatDecimals(minMaxState.maxFruit, 0)}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </table>
              )}
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default OrchardHeatmap;
