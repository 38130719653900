import { Button } from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
// import CircularProgress from '@mui/material/CircularProgress';
import LoadingWidget from '../LoadingWidget';

const paginationOptions = {
	rowsPerPageText: 'Filas por página',
	rangeSeparatorText: 'de',
};

// de array a csv
// function convertArrayOfObjectsToCSV(array, cols) {
// 	let result;

// 	const columnDelimiter = ',';
// 	const lineDelimiter = '\n';

// 	let exportCols = cols.map((col) => {
// 		return col.sortField;
// 	});

// 	let exportColsHeaders = cols.map((col) => {
// 		return col.name;
// 	});

// 	let newArray = array.map((item) => _.pick(item, exportCols));

// 	let newHeaders = exportColsHeaders.join(columnDelimiter);

// 	const keys = Object.keys(newArray[0]);

// 	result = '';
// 	// result += keys.join(columnDelimiter);
// 	result += newHeaders;
// 	result += lineDelimiter;

// 	newArray.forEach((item) => {
// 		let ctr = 0;
// 		keys.forEach((key) => {
// 			if (ctr > 0) result += columnDelimiter;

// 			result += item[key] !== null ? item[key] : '';
// 			// eslint-disable-next-line no-plusplus
// 			ctr++;
// 		});
// 		result += lineDelimiter;
// 	});

// 	return result;
// }

// función para CSV
// function downloadCSV(array, cols) {
// 	const link = document.createElement('a');
// 	let csv = convertArrayOfObjectsToCSV(array, cols);
// 	if (csv == null) return;

// 	const filename = 'analytics-table-export.csv';

// 	if (!csv.match(/^data:text\/csv/i)) {
// 		csv = `data:text/csv;charset=utf-8,${csv}`;
// 	}

// 	link.setAttribute('href', encodeURI(csv));
// 	link.setAttribute('download', filename);
// 	link.click();
// }

// función para exportar XLSX
function downloadXLS(array, cols, moduleName) {
	console.log(array, '-array---', cols);

	let exportCols = cols.map((col) => {
		return col.sortField;
	});

	let newArray = array.map((item) => _.pick(item, exportCols));

	console.log('----->', newArray, '----newArray');

	// headers
	let exportColsHeaders = cols.map((col) => {
		return col.name;
	});

	// let newHeaders = exportColsHeaders.join(columnDelimiter);

	let newHeadersArray = [exportColsHeaders];

	const ws = XLSX.utils.json_to_sheet(newArray, {
		origin: 'A2',
		skipHeader: true,
	});
	const wb = XLSX.utils.book_new();

	XLSX.utils.sheet_add_aoa(ws, newHeadersArray, { origin: 'A1' });

	XLSX.utils.book_append_sheet(wb, ws, moduleName);
	XLSX.writeFile(wb, 'reports.xlsx');
}

const DataTableWidget = (props) => {
	const {
		columns,
		filteredItems,
		subHeaderComponentMemo = null,
		loading,
		sortField = null,
		handleOnSort,
		sortFunction = null,
		changePagination,
		totalRows,
		rowsChange,
		loadingCount,
		conditionalRowStyles,
		customStyles,
		resetPaginationToggle,
		fixedHeader = false,
		fixedHeaderScrollHeight = '',
		moduleName = 'data',
	} = props;
	const [perPageOptions] = useState([10, 15, 20, 25, 30]);
	const [totalRowState, setTotalRowState] = useState(totalRows);

	useEffect(() => {
		setTotalRowState(totalRows);
	}, [loadingCount, totalRows]);

	const Export = ({ onExport, onExportExcel }) => (
		<>
			{/* <Button
				color="primary"
				variant="contained"
				onClick={(e) => onExport(e.target.value)}
			>
				CSV
			</Button> */}
			<Button
				color="primary"
				variant="contained"
				onClick={(e) => onExportExcel(e.target.value)}
			>
				EXCEL
			</Button>
		</>
	);

	const actionsMemo = React.useMemo(
		() => (
			<Export
				// onExport={() => downloadCSV(filteredItems, columns)}
				onExportExcel={() =>
					downloadXLS(filteredItems, columns, moduleName)
				}
			/>
		),
		[filteredItems, moduleName]
	);

	return (
		<DataTable
			// noHeader
			actions={actionsMemo}
			fixedHeader={fixedHeader}
			fixedHeaderScrollHeight={fixedHeaderScrollHeight}
			columns={columns}
			data={filteredItems}
			defaultSortAsc={false}
			// subHeader
			subHeaderComponent={subHeaderComponentMemo}
			onSort={handleOnSort}
			sortFunction={sortFunction} //es para cambiar la forma de interpretar el cambio de sort
			defaultSortFieldId={sortField}
			pagination
			paginationServer
			sortServer
			conditionalRowStyles={
				conditionalRowStyles ? conditionalRowStyles : []
			}
			paginationComponentOptions={paginationOptions}
			paginationRowsPerPageOptions={perPageOptions}
			onChangeRowsPerPage={rowsChange}
			progressPending={loading}
			// paginationPerPage={1}
			progressComponent={<LoadingWidget />}
			paginationTotalRows={totalRowState}
			onChangePage={changePagination}
			noDataComponent="No hay registros."
			customStyles={customStyles}
			paginationResetDefaultPage={resetPaginationToggle}
		/>
	);
};

export default DataTableWidget;
