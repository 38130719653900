// import _ from 'lodash';
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classesModule from './Params.module.scss';
import { AxiosContext } from '../../context/AxiosContext';
import {
  LoadingWidget,
  SaveAction,
  TextFieldWidgetSelectControllerStyled,
} from '../../components';
import { FiltersContext } from '../../context';
import { Context } from '../../context/Context';
import clsx from 'clsx';
import { numberFormat, numberFormatDecimals } from '../../helpers';
import _ from 'lodash';
import { useErrorHandler } from 'react-error-boundary';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
    marginTop: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  subtitleDash: {
    fontSize: 18,
    fontWeight: 600,
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
}));

const getPropertyNames = (array, property) => {
  const newArray = array.map((item) => {
    return item[property];
  });
  return newArray;
};

// const arrayConf = [
// 	{
// 		name: 'Pre Poda',
// 		data: [3, 5, 1, 13, 32, 15, 35, 18, 32],
// 	},
// 	{
// 		name: 'Post Poda',
// 		data: [14, 28, 8, 32, 1, 13, 32, 15, 15],
// 	},
// 	{
// 		name: 'Pre Raleo',
// 		data: [0, 2, 6, 3, 15, 35, 18, 2, 6],
// 	},
// 	{
// 		name: 'Post Raleo',
// 		data: [0, 16, 32, 28, 15, 35, 18, 16, 32],
// 	},
// ];

const endPointNamePost = 'v1/parameter';

const Params = (props) => {
  // let history = useHistory();

  const theme = useTheme();

  // console.log(theme, '----------------theme');

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { token, userId, userClient } = useContext(Context);

  const errorHandler = useErrorHandler();

  const { closeDrawer, orchardFilter, clearDays } = props;

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const [filterDataResponseState, setFilterDataResponseState] =
    useState(userOrchard_filter);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingParamsDataState, setLoadingParamsDataState] = useState(false);
  const [paramsDataState, setParamsDataState] = useState(null);

  const [loadingSubmitState, setLoadingSubmitState] = useState(false);
  const [successSubmitState, setSuccessSubmitState] = useState(false);

  // console.log(orchardFilter, '----------------orchardFilter');

  const {
    handleSubmit: handleSubmitForm,
    control: controlForm,
    watch: watchForm,
    setValue: setValueForm,
    reset: resetForm,
    getValues: getValuesForm,
    formState: { errors: errorsForm },
  } = useForm();

  const onSubmit = async (values) => {
    setLoadingParamsDataState(true);
    resetForm();

    const dataParams = {
      params: {
        clientValue: userClient.value,
        orchardValue: values.orchard,
        type: 'weekdays',
      },
    };

    try {
      const response = await axiosContext.authAxios.get(
        'v1/parameter',
        dataParams
      );

      const { data, status } = response;

      if (status === 200) {
        setParamsDataState(data[0]);
        setLoadingParamsDataState(false);
      }
    } catch (error) {
      // console.log(error);
      // función del boundary, pasa el error
      // se aplica en los cach
      errorHandler(error);
      setLoadingParamsDataState(false);
    }
  };

  const onSubmitForm = async (values) => {
    setLoadingSubmitState(true);

    const dataObj = {
      client: userClient?.label || '',
      clientValue: userClient?.value || '',
      orchard: paramsDataState?.orchard || '',
      orchardValue: paramsDataState?.orchardValue || '',
      type: 'weekdays',
      ...values,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointNamePost,
        dataObj
      );

      const { status, data } = response;

      if (status === 200) {
        // resetForm();
        setParamsDataState(data.mutationParameter);
        setSuccessSubmitState(true);
        if (orchardFilter) {
          clearDays();
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSubmitState(false);
    }
  };

  const closeSnackHandler = () => {
    setSuccessSubmitState(false);

    setParamsDataState(null);
    if (orchardFilter) {
      closeDrawer();
    }
  };

  useEffect(() => {
    if (orchardFilter) {
      setValue('orchard', orchardFilter.value);
      console.log(orchardFilter, '------------------se ejecuta el submit');
      handleSubmit(onSubmit)();
    }
  }, [orchardFilter]);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-md-3'>
            <Card
              elevation={0}
              // classes={{
              // 	root: 'MainCardRoot',
              // }}
            >
              <CardContent>
                <BsRow class='mb-1 mb-md-4'>
                  <BsCol class='col-md-6'>
                    <Typography gutterBottom variant='h3' component='h2'>
                      Parámetros{' '}
                      {orchardFilter?.label ? orchardFilter.label : ''}
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        <TextFieldWidgetSelectControllerStyled
                          options={filterDataResponseState}
                          control={control}
                          titleOption='label'
                          name='orchard'
                          disabled={!!orchardFilter?.value}
                          labeltext='Seleccione Huerto'
                          errors={errors.orchard}
                          defaultValue=''
                          loading={false}
                          req
                          withDefault
                          variant='standard'
                        />
                      </div>
                      <SaveAction
                        title='Filtrar'
                        color='secondary'
                        disabled={!!orchardFilter?.value}
                        size='large'
                        className='roundedButton ml-md-2'
                        variant='contained'
                        messageType='add'
                        mutationLoading={false}
                        success={false}
                      />
                    </form>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        {!loadingParamsDataState ? (
          paramsDataState ? (
            <>
              <BsRow class='no-gutters'>
                <BsCol class='col-md-12'>
                  <BsRow class='gutter_10'>
                    <BsCol class='col-md-10 col-lg-12'>
                      <Card elevation={0} className={classes.dashCard}>
                        <CardContent>
                          <form
                            autoComplete='false'
                            noValidate
                            onSubmit={handleSubmitForm(onSubmitForm)}
                          >
                            <BsRow>
                              <BsCol class='col-12 table-responsive'>
                                <Typography
                                  variant='subtitle1'
                                  gutterBottom
                                  className={classes.subtitleDash}
                                >
                                  Días de la semana
                                </Typography>
                                <table className='table'>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Typography variant='body1'>
                                          Lunes
                                        </Typography>
                                        <Controller
                                          name={`monday`}
                                          control={controlForm}
                                          defaultValue={paramsDataState.monday}
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Typography variant='body1'>
                                          Martes
                                        </Typography>
                                        <Controller
                                          name={`tuesday`}
                                          control={controlForm}
                                          defaultValue={paramsDataState.tuesday}
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Typography variant='body1'>
                                          Miércoles
                                        </Typography>
                                        <Controller
                                          name={`wednesday`}
                                          control={controlForm}
                                          defaultValue={
                                            paramsDataState.wednesday
                                          }
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Typography variant='body1'>
                                          Jueves
                                        </Typography>
                                        <Controller
                                          name={`thursday`}
                                          control={controlForm}
                                          defaultValue={
                                            paramsDataState.thursday
                                          }
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Typography variant='body1'>
                                          Viernes
                                        </Typography>
                                        <Controller
                                          name={`friday`}
                                          control={controlForm}
                                          defaultValue={paramsDataState.friday}
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Typography variant='body1'>
                                          Sábado
                                        </Typography>
                                        <Controller
                                          name={`saturday`}
                                          control={controlForm}
                                          defaultValue={
                                            paramsDataState.saturday
                                          }
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Typography variant='body1'>
                                          Domingo
                                        </Typography>
                                        <Controller
                                          name={`sunday`}
                                          control={controlForm}
                                          defaultValue={paramsDataState.sunday}
                                          render={({ field }) => (
                                            <Checkbox
                                              onChange={(e) =>
                                                field.onChange(e.target.checked)
                                              }
                                              checked={field.value}
                                            />
                                          )}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </BsCol>
                            </BsRow>
                            <BsRow class='justify-content-center'>
                              <BsCol class='col-12 col-md-3 mt-4'>
                                <SaveAction
                                  title='Guardar Parámetros'
                                  color='primary'
                                  size='large'
                                  fullWidth={true}
                                  actionClose={closeSnackHandler}
                                  disabled={false}
                                  className='btn-block'
                                  messageType='add'
                                  mutationLoading={loadingSubmitState}
                                  success={successSubmitState}
                                />
                              </BsCol>
                            </BsRow>
                          </form>
                        </CardContent>
                      </Card>
                    </BsCol>
                  </BsRow>
                </BsCol>
              </BsRow>
            </>
          ) : (
            <></>
          )
        ) : (
          <LoadingWidget />
        )}
      </BsContainer>
    </div>
  );
};

export default Params;
